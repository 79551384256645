import { FetchMore } from "interfaces/FetchMore";
import React, { Dispatch, SetStateAction } from "react";
import { TableQueryVariables } from "shared-components/Table/Handlers/types";
import { ReactTablePaginationState } from "shared-components/Table/ReactTable";

export const GetPageChangeHandler =
  <TQuery, TVariables extends Partial<TableQueryVariables>>(
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ],
    fetchMore?: FetchMore<TVariables, TQuery>,
    endCursor?: string | null,
    variables?: TVariables
  ): ((
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void) =>
  (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (paginationState.page < newPage && fetchMore) {
      fetchMore({
        variables: {
          ...variables,
          after: endCursor,
        },
      })
        .then(() => setPaginationState({ ...paginationState, page: newPage }))
        .catch((err) => console.log(err));
    } else if (paginationState.page > newPage) {
      setPaginationState({ ...paginationState, page: newPage });
    }
  };
