import { gql } from "@apollo/client/core";
import { TypeNames } from "enums/TypeNames";

export const LookupQueries = {
  remote: {
    GET_SYSTEMS: gql`
      query getSystems {
        systems {
          systemId
          systemName
        }
      }
    `,
    GET_LAB_COMPUTER: gql`
      query getLabComputers {
        labComputers {
          id
          name
        }
      }
    `,
  },
  local: {
    GET_SYSTEM_BY_ID: gql`
      query getSystem($systemId: Int!) {
        system(id: $systemId, typeName: "${TypeNames.LookupArraySystem}", keyField: "systemId") @client {
          systemId
          systemName
        }
      }
    `,
  },
};
