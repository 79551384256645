import { Maybe } from "graphql/jsutils/Maybe";
import * as _ from "lodash";
import {
  CollectionTaskInput,
  IncomingSampleTaskFragment,
} from "../graphql/generated/schema-types";
import { DeepCopy } from "../helpers/object-helpers";
import { UpdateIncomingSampleCollectionTaskInputStub } from "./object-stubs/IncomingSampleCollectionTaskInput";

export function UpdateIncomingSampleTaskInputMapper(
  task: Maybe<IncomingSampleTaskFragment>
) {
  const _task = DeepCopy(task);
  return _.pick(
    _task,
    _.keys(new UpdateIncomingSampleCollectionTaskInputStub())
  ) as CollectionTaskInput;
}
