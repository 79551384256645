import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Tab, Tabs } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { TabPanel } from "shared-components/Tab";
import PersonalTasks from "../components/ManagePersonalTasks/PersonalTasks/PersonalTasks";
import RepeatingTaskSummary from "../components/ManagePersonalTasks/RepeatingTaskSummary/RepeatingTaskSummary";

interface ManagePersonalTasksProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function ManagePersonalTasks({
  isOpen,
  handleClose,
}: ManagePersonalTasksProps) {
  const [value, setValue] = useState(0);
  const handleChange = (_event: ChangeEvent<unknown>, newValue: number) =>
    setValue(newValue);

  return (
    <Dialog fullScreen open={isOpen} onClose={handleClose}>
      <div style={{ display: "flex" }}>
        <IconButton onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          indicatorColor={"secondary"}
        >
          <Tab label="Personal Tasks" />
          <Tab label="Repeating Tasks" />
        </Tabs>
      </div>
      <DialogContent>
        <TabPanel value={value} index={0}>
          <PersonalTasks />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RepeatingTaskSummary />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}
