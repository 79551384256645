import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  CalendarToday,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { Autocomplete } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { RunTaskScheduleDialog } from "features/Runs/components/Forms/Create/RunTask/ScheduleDialog";
import { CreateRunActivityValidationSchema } from "features/Runs/components/Forms/validation-schema";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RepeatedTaskInfo } from "features/RunTemplate/components/Forms/Create/Task/SlideOver";
import { FormikHelpers, useFormik } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  RunTaskInput,
  LookupArraySystem,
  RunStage,
  RunTaskType,
  TaskTypeEnum,
  TaskSelectionTypeEnum
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import produce from "immer";
import { StateProp } from "interfaces/StateProp";
import React, { ChangeEvent, useState } from "react";
import {
  GroupField,
  PriorityField,
  RunTaskTypeField,
  SystemField,
  UserField,
} from "shared-components/FormFields/FormFields";
import { MethodFormReducer } from "shared-components/FormFields/MethodFields/MethodFormReducer";
import {
  FormSubmitButton,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import styled from "styled-components";
import { MethodsWithDetails } from "constants/methodsWithDetails";
import { FormName } from "enums/FormName";
import { useGetRun } from "hooks/cacheAccessHooks";
import { usePersistForm } from "hooks/usePersistForm";
import { RunTaskTypeCompositeKey } from "features/RunTemplate/helpers";

export const Container = styled.div`
  display: grid;
  grid-template: 1fr auto / 1fr auto;
  justify-content: space-around;
`;
export const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 400px;
  grid-column: 1/2;
  grid-row: 1/2;
  z-index: 20;
  margin: 0 10px;
`;
export const CollapseControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 10px 0 20px 0;
`;
export const ExpandDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  &:hover {
    cursor: pointer;
  }
`;
export function CreateRunTaskForm({
  initialValues,
  onSubmit,
  repeatTaskInfoState,
}: {
  initialValues: RunTaskInput;
  onSubmit: (
    values: RunTaskInput,
    actions: FormikHelpers<RunTaskInput>
  ) => void;
  repeatTaskInfoState: StateProp<RepeatedTaskInfo>;
}): JSX.Element {
  const {
    runsModalState: { selectedRunId },
  } = useRunsModalState();
  const run = useGetRun(selectedRunId);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const props = useFormik({
    initialValues,
    onSubmit,
    validationSchema: CreateRunActivityValidationSchema,
  });
  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setValues,
  } = props;
  usePersistForm(FormName.CreateRunTask, props);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <Container>
        <FieldsContainer>
          <StyledFormControl>
            <RunTaskTypeField
              id="taskName-combobox"
              data-testid={"taskName-combobox"}
              value={RunTaskTypeCompositeKey(values.taskTypeDetails)}
              groupBy={(taskType) => taskType?.type ?? ""}
              onChange={(e, newValue) => {
                const taskType = newValue as Maybe<RunTaskType>;
                if (taskType?.type === TaskSelectionTypeEnum.Method) {
                  setValues(
                    produce(values, (draft) => {
                      draft!.taskTypeDetails = {
                        taskType: TaskSelectionTypeEnum.Method,
                        taskTypeId: taskType.id,
                      };
                      draft!.activityName = taskType.name!;
                    })
                  );
                } else if (taskType?.type === TaskSelectionTypeEnum.Manual) {
                  setValues(
                    produce(values, (draft) => {
                      draft!.taskTypeDetails = {
                        taskType: TaskSelectionTypeEnum.Manual,
                        taskTypeId: taskType.id,
                      };
                      draft!.activityName = taskType.name!;
                    })
                  );
                }
              }}
              label="Name"
              variant="outlined"
              helperText={
                errors.activityName &&
                touched.activityName &&
                errors.activityName
              }
              error={
                !!(
                  errors.activityName &&
                  touched.activityName &&
                  errors.activityName
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <Autocomplete
              id={"stages"}
              options={run?.runStages ?? []}
              getOptionLabel={(option) => option!.runStageName!}
              fullWidth
              onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
                const stage: RunStage = newValue as RunStage;
                setFieldValue("runStageId", stage?.runStageId ?? null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Stage"}
                  variant={"outlined"}
                  helperText={
                    errors.runStageId && touched.runStageId && errors.runStageId
                  }
                  error={
                    !!(
                      errors.runStageId &&
                      touched.runStageId &&
                      errors.runStageId
                    )
                  }
                />
              )}
            />
          </StyledFormControl>
          <StyledFormControl>
            <UserField
              id="userAssignedTo-combobox"
              defaultValue={initialValues.runTaskAssignedUsers}
              value={values.runTaskAssignedUsers}
              onChange={(e, newValue) => {
                setFieldValue(
                  "runTaskAssignedUsers",
                  (newValue as Maybe<AppSuiteUser>[])?.map(
                    (entry) => entry?.userId
                  ) ?? null
                );
              }}
              label="Users"
              variant="outlined"
              multiple
              helperText={
                errors.runTaskAssignedUsers &&
                touched.runTaskAssignedUsers &&
                errors.runTaskAssignedUsers
              }
              error={
                !!(
                  errors.runTaskAssignedUsers &&
                  touched.runTaskAssignedUsers &&
                  errors.runTaskAssignedUsers
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <GroupField
              id="groupAssignedTo-combobox"
              value={values.runTaskAssignedGroups}
              onChange={(e, newValue) =>
                setFieldValue(
                  "runTaskAssignedGroups",
                  (newValue as Maybe<AppSuiteGroup>[])?.map(
                    (entry) => entry?.groupId
                  ) ?? null
                )
              }
              label="Groups"
              variant="outlined"
              multiple
              defaultValue={initialValues.runTaskAssignedGroups}
              helperText={
                errors.runTaskAssignedGroups &&
                touched.runTaskAssignedGroups &&
                errors.runTaskAssignedGroups
              }
              error={
                !!(
                  errors.runTaskAssignedGroups &&
                  touched.runTaskAssignedGroups &&
                  errors.runTaskAssignedGroups
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <SystemField
              id="system-combobox"
              onChange={(event, newValue) =>
                setFieldValue(
                  "systemAssignedTo",
                  (newValue as Maybe<LookupArraySystem>)?.systemId ?? null
                )
              }
              label="System"
              variant="outlined"
              helperText={
                errors.systemAssignedTo &&
                touched.systemAssignedTo &&
                errors.systemAssignedTo
              }
              error={
                !!(
                  errors.systemAssignedTo &&
                  touched.systemAssignedTo &&
                  errors.systemAssignedTo
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <PriorityField
              id={"priority"}
              label="Priority"
              onChange={(e, newValue) => setFieldValue("priority", newValue)}
              helperText={
                errors.priority && touched.priority && errors.priority
              }
              error={!!(errors.priority && touched.priority && errors.priority)}
              variant={"outlined"}
            />
          </StyledFormControl>
          <StyledFormControl>
            <DatePicker
              value={values.startTimeScheduled}
              onChange={(date) => setFieldValue("startTimeScheduled", date)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  id="start-date-picker"
                  variant="outlined"
                  label="Start Date"
                  helperText={
                    errors.startTimeScheduled &&
                    touched.startTimeScheduled &&
                    errors.startTimeScheduled
                  }
                  error={
                    !!(
                      errors.startTimeScheduled &&
                      touched.startTimeScheduled &&
                      errors.startTimeScheduled
                    )
                  }
                  fullWidth
                />
              )}
            />
          </StyledFormControl>
          <CollapseControls>
            <IconButton onClick={() => setDialogOpen(true)} size="large">
              <CalendarToday />
            </IconButton>
            {values.taskTypeDetails?.taskType === TaskSelectionTypeEnum.Method &&
            MethodsWithDetails.includes(values.taskTypeDetails!.taskTypeId!) ? (
              <ExpandDiv
                aria-label="expand"
                onClick={(e) => {
                  e.stopPropagation();
                  setCollapseOpen(!collapseOpen);
                }}
              >
                <div>Task Details</div>
                {collapseOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </ExpandDiv>
            ) : (
              <React.Fragment />
            )}
          </CollapseControls>
          <FormSubmitButton
            type="submit"
            data-testid={"submit-button"}
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Task
          </FormSubmitButton>
        </FieldsContainer>
        {values.taskTypeDetails?.taskType === TaskSelectionTypeEnum.Method &&
        MethodsWithDetails.includes(values.taskTypeDetails!.taskTypeId!) ? (
          <MethodFormReducer
            props={props}
            methodId={values.taskTypeDetails!.taskTypeId! ?? 0}
            collapseOpen={collapseOpen}
          />
        ) : null}
      </Container>
      <RunTaskScheduleDialog
        repeatTaskInfoState={repeatTaskInfoState}
        dialogState={{ open: dialogOpen, setOpen: setDialogOpen }}
      />
    </StyledForm>
  );
}
