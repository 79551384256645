import { makeVar } from "@apollo/client";
import { useEffect } from "react";
import { useGetPlateCodesQuery } from "../graphql/generated/schema-types";

export const PlateCodes = makeVar<string[]>([]);

/**
 * Initializes PlateCodes. PlateCodes is a store that contains all valid destination plate codes for an experiment.
 * This store is used for form validation
 */
export function useInitializePlateCodesStore() {
  const { data: plateCodesQuery } = useGetPlateCodesQuery();

  useEffect(() => {
    const plateCodes = (plateCodesQuery?.plateCodes ?? []) as string[];
    PlateCodes(plateCodes);
  }, [plateCodesQuery?.plateCodes]);
}
