/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Typography } from "@mui/material";
import {
  RowLetterMapping,
  SizeMapping,
} from "features/Plates/components/PlateInfo/constants";
import { Plate } from "features/Plates/components/PlateInfo/helpers";
import { Well } from "features/Plates/components/PlateInfo/PlateMap/Well";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React, { useMemo } from "react";
import styled, { DefaultTheme } from "styled-components";
import { PlateMapContextMenu } from "../ContextMenu";

const PlateMapContainer = styled(Paper)<{
  rows: number;
  cols: number;
  width: string;
  height: string;
}>`
  display: grid;
  overflow: auto;
  grid-auto-flow: row;
  position: relative;
  border: ${({ theme }) =>
    theme.palette.mode === "dark" ? "1px solid white" : "1px solid #000"};
  grid-template-rows: auto ${(props) => `repeat(${props.rows}, 1fr)`};
  grid-template-columns: auto ${(props) => `repeat(${props.cols}, 1fr)`};
  justify-items: stretch;
  align-items: stretch;
  margin: 0px 5px;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const PlateHeaderCell = styled(Typography)<{
  rowstart: number;
  colstart: number;
  key: number;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row-start: ${(props) => `${props.rowstart}`};
  grid-column-start: ${(props) => `${props.colstart}`};
  justify-self: stretch;
  align-self: stretch;
  border: solid 0.5px ${GetTableCellBorder};
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "#121212" : "#d6d6d6"};
  margin: 0.5px;
  padding: 3px 3px;
`;

const RowHeaderCell = styled(PlateHeaderCell)`
  border-right: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? "solid 0.5px white"
      : "solid 0.5px black"} !important;
`;

const ColHeaderCell = styled(PlateHeaderCell)`
  border-bottom: ${({ theme, idx }: { theme: DefaultTheme; idx: number }) =>
    idx
      ? theme.palette.mode === "dark"
        ? "solid 0.5px white"
        : "solid 0.5px black"
      : GetTableCellBorder} !important;
`;

export function PlateMap({
  selectedPlate,
}: {
  selectedPlate: Plate;
}): JSX.Element {
  const columnHeaders = useMemo(() => {
    if (selectedPlate) {
      if (selectedPlate.numCols) {
        // console.log(`Generating ${selectedPlate.plateBarcode} col headers.`);
        const arr = ["", ...Array(selectedPlate.numCols).keys()];
        return arr.map((elem, idx) => (
          <ColHeaderCell
            rowstart={1}
            colstart={idx + 1}
            key={idx}
            fontWeight="bold"
            justifySelf={"stretch"}
            alignSelf={"stretch"}
            idx={idx}
          >
            {idx === 0 ? elem : JSON.stringify(idx)}
          </ColHeaderCell>
        ));
      } else console.log("numCols null", selectedPlate);
    }
  }, [selectedPlate.layout]);

  const rowHeaders = useMemo(() => {
    if (selectedPlate) {
      if (selectedPlate.numRows) {
        // console.log(`Generating ${selectedPlate.plateBarcode} row headers.`);
        const arr = [...Array(selectedPlate.numRows).keys()];
        return arr.map((elem, idx) => (
          <RowHeaderCell
            rowstart={idx + 2}
            colstart={1}
            key={idx}
            fontWeight="bold"
            justifySelf={"stretch"}
            alignSelf={"stretch"}
            padding={"0px 5px"}
          >
            {RowLetterMapping[idx]}
          </RowHeaderCell>
        ));
      } else console.log("numRows null", selectedPlate);
    } else return null;
  }, [selectedPlate.layout]);

  const rowWells = useMemo(() => {
    if (selectedPlate && selectedPlate.wells) {
      // console.log(`Generating ${selectedPlate.plateBarcode} wells.`);
      return selectedPlate.wells.map((row, idx) =>
        row.map((well, idx) => (
          <Well well={well} selectedPlate={selectedPlate} key={idx} />
        ))
      );
    } else {
      console.log("wells null");
      return null;
    }
  }, [selectedPlate]);

  const makePlate = useMemo(() => {
    console.log("making plate", selectedPlate.plateBarcode);
    return (
      <React.Fragment>
        {selectedPlate ? (
          <React.Fragment>
            <PlateMapContextMenu />
            <PlateMapContainer
              elevation={3}
              rows={
                selectedPlate && selectedPlate.numRows
                  ? selectedPlate.numRows
                  : 0
              }
              cols={
                selectedPlate && selectedPlate.numCols
                  ? selectedPlate.numCols
                  : 0
              }
              width={
                selectedPlate && selectedPlate.layout
                  ? SizeMapping[selectedPlate.layout].width
                  : ""
              }
              height={
                selectedPlate && selectedPlate.layout
                  ? SizeMapping[selectedPlate.layout].height
                  : ""
              }
            >
              {columnHeaders}
              {rowHeaders}
              {rowWells}
            </PlateMapContainer>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }, [selectedPlate]);

  return <React.Fragment>{makePlate}</React.Fragment>;
}
