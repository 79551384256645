import {
  IncomingSampleActions,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import {
  CollectionTaskInput,
  useUpdateCollectionTasksMutation,
} from "graphql/generated/schema-types";
import { useGetIncomingSampleTask } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import _ from "lodash";
import { UpdateIncomingSampleTaskInputMapper } from "mappings/incomingSampleCollectionTask";
import { StyledCard } from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { UpdateSampleTaskForm } from "./UpdateSampleTaskForm";

export function UpdateSampleTaskModal(): JSX.Element {
  const [updateTasks] = useUpdateCollectionTasksMutation();
  const {
    incomingSampleState: { selectedTasks },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  const task = useGetIncomingSampleTask(selectedTasks![0]);
  const input = UpdateIncomingSampleTaskInputMapper(task);
  const handleSubmit: FormikSubmit<CollectionTaskInput> = (values) => {
    for (const key in values) {
      const _key = key as keyof CollectionTaskInput;
      if (_.isEqual(values[_key], input[_key])) {
        delete values[_key];
      }
    }
    values.incomingSampleTaskId = selectedTasks![0];
    return updateTasks({
      variables: {
        inputs: [values],
      },
    })
      .then(() =>
        incomingSampleDispatch({
          type: IncomingSampleActions.CLOSE_MODAL,
        })
      )
      .catch((err) => console.log(err));
  };
  const windowSize = useWindowSize();
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <UpdateSampleTaskForm initialValues={input} onSubmit={handleSubmit} />
    </StyledCard>
  );
}
