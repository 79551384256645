import React from "react";
import { StyledCard } from "shared-components/styled-components";
import { useSampleState } from "../../state/hooks";
import { ContainerLineageTable } from "./Table";

export function ContainerLineageModal(): JSX.Element {
  const {
    sampleState: { selectedContainers },
  } = useSampleState();
  return (
    <StyledCard>
      <ContainerLineageTable containerId={selectedContainers?.[0] ?? 0} />
    </StyledCard>
  );
}
