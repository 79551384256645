import { RunsFolderTreeActions } from "features/Runs/state/folder-tree/actions";
import {
  IRunsFolderTreeAction,
  IRunsFolderTreeState,
} from "features/Runs/state/folder-tree/initial-state";
import { RunsModalState } from "features/Runs/state/modal/initial-state";
import produce from "immer";

export function runsFolderTreeReducer(
  state: IRunsFolderTreeState,
  action: IRunsFolderTreeAction
): IRunsFolderTreeState {
  const modalState = RunsModalState();
  switch (action.type) {
    case RunsFolderTreeActions.FOLDER_SEARCH:
      return produce(state, (draft) => {
        draft.folderSearch = action.payload!.folderSearch;
      });
    case RunsFolderTreeActions.CLEAR_SELECTED_ROWS:
      RunsModalState(
        produce(modalState, (draft) => {
          draft.selectedTasks = [];
        })
      );
      return state;
    case RunsFolderTreeActions.NODE_SELECTED:
      RunsModalState(
        produce(modalState, (draft) => {
          draft.selectedTasks = [];
        })
      );
      return produce(state, (draft) => {
        draft.currentNode = action.payload?.currentNode;
      });
    default:
      return state;
  }
}
