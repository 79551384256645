import { useReactiveVar } from "@apollo/client";
import { BackdropOpen } from "App";
import { Methods } from "enums/Methods";
import {
  useGetArrayMethodLabwareTypesLazyQuery,
  useGetExistingMethodReservationLazyQuery,
  useGetPlateBarcodePreviewMutation,
  useReserveMethodMutation,
} from "graphql/generated/schema-types";
import { GetUrl } from "helpers/get-url";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NewWorklistPreview } from "../shared/components/NewWorklistPreview";
import { LabwareTypeInfo, Worklist } from "../shared/interfaces";
import {
  extractPlateCode,
  getDestinationPlateBarcodeWithStringArray,
  getDestPlateTypeInfo,
  getPlateCode,
  getSourcePlateTypeInfo,
} from "../shared/WorklistHelpers";
import { DestinationPlatesContainer } from "./plates/DestinationPlatesContainer";
import { SourcePlatesContainer } from "./plates/SourcePlatesContainer";
import { ThawToolSideBar } from "./sidebar/ThawToolSideBar";
import { DestPlateInfoState, ThawToolActions } from "./state";
import { useSetWorkistPreviewOpen, useSetWorklist } from "./state/handlers";
import { ThawToolInternalState } from "./state/initial-state";
import { WarningPopup } from "./WarningPopup";
// import "./ThawWorklistToolStyle";

const StyledSourcePlateContainer = styled.div`
  height: 300px;
  width: 400px;
  box-shadow: 3px 3px #888888;
  // background-color: white;
  border-radius: 5px;
  border: 1px solid #ded7e9;
  margin-bottom: 5px;
`;

export const ThawTool = () => {
  const thawToolInternalState = useReactiveVar(ThawToolInternalState);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [sourcePlateTypes, setSourcePlateTypes] = useState<LabwareTypeInfo[]>();
  const [destPlateTypes, setDestPlateTypes] = useState<LabwareTypeInfo[]>();
  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.sourcePlateInfo
  );
  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.destPlateInfo
  );
  const deadPlateBarcode = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.deadPlateBarcode
  );

  const [getAcceptablePlateTypes, { data: acceptablePlateTypes }] =
    useGetArrayMethodLabwareTypesLazyQuery({ fetchPolicy: "network-only" });
  const [getPlateBarcodePreview] = useGetPlateBarcodePreviewMutation();
  const [reserveMethod, { loading }] = useReserveMethodMutation();

  const setWorklist = useSetWorklist();
  const setWorklistPreviewOpen = useSetWorkistPreviewOpen();
  useEffect(() => {
    if (thawToolInternalState.methodSettings.arraySystemAutomationMethodId) {
      getAcceptablePlateTypes({
        variables: {
          where: {
            arraySystemAutomationMethodId: {
              eq: thawToolInternalState.methodSettings
                .arraySystemAutomationMethodId,
            },
          },
        },
      });
    }
  }, [thawToolInternalState.methodSettings.arraySystemAutomationMethodId]);

  useEffect(() => {
    if (acceptablePlateTypes) {
      setSourcePlateTypes(getSourcePlateTypeInfo(acceptablePlateTypes));
      setDestPlateTypes(getDestPlateTypeInfo(acceptablePlateTypes));
    }
  }, [acceptablePlateTypes]);

  useEffect(() => {
    const templateHasBeenLoaded =
      sourcePlateInfo[0].plateBarcode !== "" &&
      destPlateInfo.some(
        (e) => e.labwareTypeCode !== "" && e.plateBarcode === ""
      );
    if (templateHasBeenLoaded) {
      const usedPlates = destPlateInfo
        .filter((e) => e.labwareTypeCode !== "")
        .sort(
          (a: DestPlateInfoState, b: DestPlateInfoState) => a.index - b.index
        );
      const namedPlates: string[] = [];
      const getDestinationPlateBarcodes = async () => {
        for await (const plate of usedPlates) {
          await getPlateBarcodePreview({
            variables: {
              methodId: Methods.Thaw,
              sourcePlateBarcodes: [sourcePlateInfo[0].plateBarcode],
              destPlateCode: getPlateCode(
                plate.labwareTypeCode,
                thawToolInternalState.methodSettings.destPlateCode
              ),
            },
          }).then((data) => {
            const destPlate = getDestinationPlateBarcodeWithStringArray(
              data!.data!.plateBarcodePreview![2]!.plateBarcode!.toString(),
              namedPlates
            );
            dispatch(
              ThawToolActions.UPDATE_DESTINATION_PLATE_INFO({
                index: plate.index,
                key: "plateBarcode",
                value: destPlate,
              })
            );
            namedPlates.push(destPlate);
          });
        }
      };
      getDestinationPlateBarcodes();
    }
  }, [sourcePlateInfo[0].plateBarcode]);

  useEffect(() => {
    const uniqueDestPlateTypes = [
      ...new Set(destPlateInfo.map((e: any) => e.labwareTypeCode)),
    ];
    if (
      sourcePlateInfo[0].plateBarcode !== "" &&
      destPlateInfo[0].labwareTypeCode !== ""
    ) {
      getPlateBarcodePreview({
        variables: {
          methodId: 102,
          sourcePlateBarcodes: [sourcePlateInfo[0].plateBarcode],
          destPlateCode:
            uniqueDestPlateTypes.length > 2
              ? "Y"
              : extractPlateCode(destPlateInfo[0].plateBarcode) ??
                getPlateCode(
                  destPlateInfo[0].labwareTypeCode,
                  thawToolInternalState.methodSettings.destPlateCode
                ),
        },
      }).then((res) => {
        dispatch(
          ThawToolActions.SET_DEAD_PLATEBARCODE({
            deadPlateBarcode:
              res!.data!.plateBarcodePreview![1]!.plateBarcode!.toString(),
          })
        );
      });
    }
  }, [sourcePlateInfo, destPlateInfo]);

  const dispatch = useAppReduxDispatch();

  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    BackdropOpen({
      open: loading,
      header: "Generating Worklist",
      subText: "Do not reload page",
      size: 60,
    });
  }, [loading]);
  const handleWorklistPreviewClose = (
    generateWorklist: boolean,
    worklist: Worklist[],
    reservationTime: Date
  ) => {
    const methodReservationInput = {
      methodId: 102,
      selectedSystem: thawToolInternalState.methodSettings.selectedSystem,
      runTaskId: thawToolInternalState.methodSettings.runTaskId ?? null,
      sourcePlateInfo: sourcePlateInfo.map((plate: any) => ({
        plateBarcode: plate.plateBarcode,
        plateType: plate.plateType,
      })),
      destPlateInfo: destPlateInfo.map((plate: any) => ({
        plateBarcode: plate.plateBarcode,
        plateType: plate.plateType,
      })),
      intPlateBarcode: "",
      intPlateBarcode2: "",
      deadPlateBarcode: deadPlateBarcode,
      worklist: worklist,
      reservationTime,
    };
    if (generateWorklist) {
      setWarningDialogOpen(true);
      reserveMethod({
        variables: {
          methodReservationInput: methodReservationInput,
        },
      }).then((res) => {
        window.location.href = `${GetUrl()}/api/ThawWorklist/DownloadWorklist?methodReservationID=${
          res.data?.reserveMethod?.methodReservationId
        }`;
        getExistingMethodReservation({
          variables: {
            where: {
              isCompleted: { eq: false },
              methodReservationsPlateBarcodes: {
                some: { plateBarcode: { eq: sourcePlateInfo[0].plateBarcode } },
              },
            },
          },
        });
      });
    }
    setWorklistPreviewOpen(false);
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplate: "auto / 1fr 1fr 2fr",
      }}
    >
      <ThawToolSideBar />
      <SourcePlatesContainer sourcePlateInfo={sourcePlateTypes} />
      <DestinationPlatesContainer destPlateInfo={destPlateTypes} />
      <NewWorklistPreview
        isOpen={thawToolInternalState.worklistPreviewOpen}
        worklist={thawToolInternalState.worklist}
        setWorklist={setWorklist}
        handleClose={handleWorklistPreviewClose}
      />
      <WarningPopup
        isOpen={warningDialogOpen}
        handleClose={() => setWarningDialogOpen(false)}
      />
    </div>
  );
};
