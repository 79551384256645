import {
  ITwelve24TCToolInternalAction,
  Twelve24TCToolInternalAction,
} from "./action";
import { ITwelve24TCToolInternalState } from "./initial-state";

export function Twelve24TCToolInternalReducer(
  state: ITwelve24TCToolInternalState,
  action: ITwelve24TCToolInternalAction
) {
  switch (action.type) {
    case Twelve24TCToolInternalAction.SET_STATE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case Twelve24TCToolInternalAction.SET_DEST_SELECTION_PROCESS:
      return {
        ...state,
        destSelectionProcess: action.payload.destSelectionProcess,
      };
    case Twelve24TCToolInternalAction.SET_SELECTED_SOURCE_WELLS:
      return {
        ...state,
        selectedSourceWells: action.payload.selectedSourceWells,
      };
    case Twelve24TCToolInternalAction.SET_HOVER_WELL_ID:
      return {
        ...state,
        hoverWellId: action.payload.hoverWellId,
      };
    
    case Twelve24TCToolInternalAction.SET_SOURCE_INT_INDEX:
      return {
        ...state,
        sourceIntIndex: action.payload.sourceIntIndex,
      };
    case Twelve24TCToolInternalAction.SET_DESTINATION_INT_INDEX:
      return {
        ...state,
        destinationIntIndex: action.payload.destinationIntIndex,
      };
    case Twelve24TCToolInternalAction.SET_SELECTED_DESTINATION_INDEX:
      return {
        ...state,
        selectedDestinationIndex: action.payload.selectedDestinationIndex,
      };
    case Twelve24TCToolInternalAction.SET_REQUIRED_WARNINGS:
      return {
        ...state,
        requiredWarnings: action.payload.requiredWarnings,
      };
    case Twelve24TCToolInternalAction.SET_OPTIONAL_WARNINGS:
      return {
        ...state,
        optionalWarnings: action.payload.optionalWarnings,
      };
    case Twelve24TCToolInternalAction.SET_WORKLIST:
      return {
        ...state,
        worklist: action.payload.worklist,
        worklistPreviewOpen: action.payload.worklistPreviewOpen,
      };
    case Twelve24TCToolInternalAction.SET_WORKLIST_PREVIEW_OPEN:
      return {
        ...state,
        worklistPreviewOpen: action.payload.worklistPreviewOpen,
      };
    case Twelve24TCToolInternalAction.UPDATE_METHOD_SETTINGS:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          [action.payload.key]: action.payload.value,
        },
      };
    case Twelve24TCToolInternalAction.UPDATE_STAMP_ROWCOLVOL:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          ...action.payload,
        },
      };
    case Twelve24TCToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE:
      return {
        ...state,
        methodSettings: action.payload.methodSettings,
      };
  }
}
