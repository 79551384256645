import { makeVar } from "@apollo/client";
import { DefaultRunTableContextMenu } from "features/Runs/components/ContextMenus/Table/Default";
import { MarkMultipleRunTasksContextMenu } from "features/Runs/components/ContextMenus/Table/MarkMultiple";
import { RunActivityTaskColumns } from "features/Runs/components/Table/Columns";
import { RunActivityTaskFilterReducer } from "features/Runs/components/Table/filter";
import {
  handleContextMenu,
  useHandleRowClick,
} from "features/Runs/components/Table/handlers";
import { defaultRunTaskStatusFilter } from "features/Runs/constants";
import {
  useRunsFolderTreeState,
  useRunsModalState,
  useRunTaskQueryProvider,
} from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  CollectionTask,
  GetRunTasksQuery,
  GetRunTasksQueryVariables,
  RunTask,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { MutateState } from "helpers/object-helpers";
import produce from "immer";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TableState,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetPageChangeHandler } from "shared-components/Table/Handlers/HandlePageChange";
import { GetHandlePageCountChange } from "shared-components/Table/Handlers/HandlePageCountChange";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import {
  SelectedPreset,
  useCommonTableSideEffects,
  useGetTablePreset,
  useInitializeConnectionData,
} from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { TableName } from "enums/TableName";
import { GetHandlePresetChange } from "shared-components/Table/Handlers/HandlePresetChange";
import { Preset } from "shared-components/Table/sub-components/SavePresetDialog";

//Workaround to prevent dialog from opening when the context menu loses focus
export const RunPageContextMenuHidden = makeVar(true);

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedRunTableRows = () =>
  toggleAllRowsSelectedStore()(false);

const DefaultState: Partial<TableState<RunTask>> = {
  filters: [
    {
      id: "status",
      value: defaultRunTaskStatusFilter,
    },
  ],
  sortBy: [{ id: "startTimeScheduled", desc: false }],
};

export function RunsTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//

  const {
    runsFolderTreeState: { currentNode },
  } = useRunsFolderTreeState();
  const { runsModalDispatch } = useRunsModalState();

  const tableSettings = useGetTablePreset<RunTask>(
    TableName.Runs,
    DefaultState
  );

  const defaultPreset = useMemo(
    () =>
      (tableSettings?.presets?.find((p) => p?.default)?.state as
        | TableState<RunTask>
        | undefined) ?? DefaultState,
    [tableSettings?.presets]
  );
  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: defaultPreset.pageSize ?? 10,
      page: 0,
    });

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { refetch, loading, fetchMore, variables, data } =
    useRunTaskQueryProvider();

  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData = useInitializeConnectionData<RunTask>(
    data?.runTasks,
    paginationState
  );

  const totalCount = useMemo(
    () => GetTotalCountFromConnectionType<CollectionTask>(data?.runTasks),
    [data?.runTasks]
  );

  const pageInfo = useMemo(
    () => GetPageInfoFromConnectionType<RunTask>(data?.runTasks),
    [data?.runTasks]
  );

  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const paginationTuple = useMemo(
    () =>
      [paginationState, setPaginationState] as [
        ReactTablePaginationState,
        Dispatch<SetStateAction<ReactTablePaginationState>>
      ],
    [paginationState]
  );
  const sortTable = useMemo(
    () => GetSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const onPageChange = useMemo(
    () =>
      GetPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [fetchMore, pageInfo?.endCursor, paginationState, variables]
  );

  const handlePageCountChange = useMemo(
    () =>
      GetHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, variables]
  );

  const filterTable = useMemo(
    () =>
      GetFilterHandler<GetRunTasksQuery, GetRunTasksQueryVariables, RunTask>(
        variables,
        refetch,
        RunActivityTaskFilterReducer,
        paginationTuple
      ),
    [paginationTuple, refetch, variables]
  );

  const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const skipPageResetRef = React.useRef(false);

  const _pageData = useMemo(() => {
    skipPageResetRef.current = true;
    return pageData.map((entry) =>
      produce(entry, (draft) => {
        draft.startTimeScheduled = moment(draft?.startTimeScheduled)
          .startOf("day")
          .toISOString();
      })
    );
  }, [pageData]);

  const tableInstance = useTable(
    {
      columns: RunActivityTaskColumns,
      data: _pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState: defaultPreset,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  React.useEffect(() => {
    skipPageResetRef.current = false;
  });

  const {
    state: { groupBy },
    selectedFlatRows,
    toggleAllRowsSelected,
    setAllFilters,
    setSortBy,
  } = tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//

  const handlePresetChange = GetHandlePresetChange(
    tableSettings,
    setAllFilters,
    setSortBy,
    handlePageCountChange,
    paginationTuple
  );

  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  //select folder on currentFolder change
  useEffect(() => {
    let _variables: {
      hasChanged: boolean;
      newValue: Maybe<GetRunTasksQueryVariables>;
    } = { hasChanged: false, newValue: null };

    if (currentNode)
      _variables = MutateState(variables, (draft) => {
        return { ...draft, key: currentNode };
      });

    if (_variables.hasChanged && _variables.newValue)
      refetch(_variables.newValue)?.then(() => {
        if (paginationState.page !== 0)
          setPaginationState(
            produce(paginationState, (draft) => {
              draft.page = 0;
            })
          );
      });
  }, [currentNode, paginationState, refetch, variables]);

  useCommonTableSideEffects(
    paginationTuple,
    totalCount,
    variables,
    refetch,
    groupBy
  );

  useEffect(() => {
    runsModalDispatch({
      type: RunsModalActions.SELECT,
      payload: {
        selectedTasks: selectedFlatRows
          .filter((row) => row.original?.runTaskId)
          .map((row) => row.original.runTaskId),
      },
    });
  }, [runsModalDispatch, selectedFlatRows]);

  useEffect(
    () => () => {
      SelectedPreset(null);
    },
    []
  );
  //--------------------------------------------------------------- Markup ------------------------------------------------------------//
  return (
    <div>
      <Preset
        tableSettings={tableSettings}
        handlePresetChange={handlePresetChange}
      />
      <ReactTable
        name={TableName.Runs}
        tableInstance={tableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        onRowClick={handleRowClick}
        loading={loading}
      />
      <DefaultRunTableContextMenu />
      <MarkMultipleRunTasksContextMenu />
    </div>
  );
}
