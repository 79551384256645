import React from "react";
import Spinner from "react-spinkit";
import styled from "styled-components";

type LoadingProp = {
  name:
    | "three-bounce"
    | "double-bounce"
    | "rotating-plane"
    | "folding-cube"
    | "wave"
    | "wandering-cubes"
    | "pulse"
    | "chasing-dots"
    | "circle"
    | "cube-grid"
    | "wordpress"
    | "ball-grid-beat"
    | "ball-grid-pulse"
    | "line-spin-fade-loader"
    | "ball-spin-fade-loader"
    | "ball-pulse-rise"
    | "line-scale"
    | "line-scale-pulse-out"
    | "line-scale-pulse-out-rapid"
    | "line-scale-party"
    | "ball-triangle-path"
    | "ball-scale-ripple-multiple"
    | "ball-pulse-sync"
    | "ball-beat"
    | "ball-scale-multiple"
    | "ball-zig-zag"
    | "ball-zig-zag-deflect"
    | "ball-clip-rotate"
    | "ball-clip-rotate-pulse"
    | "ball-clip-rotate-multiple"
    | "ball-scale-ripple"
    | "triangle-skew-spin"
    | "pacman";
  color: string;
  overlay?: boolean;
};
interface SpinnerContainerProps {
  overlay?: boolean;
}
const SpinnerContainer = styled.div`
  display: ${({ overlay }: SpinnerContainerProps) => (overlay ? "" : "flex")};
  position: ${({ overlay }: SpinnerContainerProps) =>
    overlay ? "absolute" : ""};
  justify-content: ${({ overlay }: SpinnerContainerProps) =>
    overlay ? "" : "center"};
  align-items: ${({ overlay }: SpinnerContainerProps) =>
    overlay ? "" : "center"};
  width: ${({ overlay }: SpinnerContainerProps) => (overlay ? "" : "100%")};
  height: ${({ overlay }: SpinnerContainerProps) => (overlay ? "" : "100%")};
  top: ${({ overlay }: SpinnerContainerProps) => (overlay ? "42.5%" : "")};
  left: ${({ overlay }: SpinnerContainerProps) => (overlay ? "45%" : "")};
  z-index: ${({ overlay }: SpinnerContainerProps) => (overlay ? "100" : "")};
`;
const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
  z-index: 5000;
`;
const Loading = ({ name, color, overlay }: LoadingProp) => {
  return !overlay ? (
    <SpinnerContainer overlay={overlay}>
      <Spinner name={name} color={color} />
    </SpinnerContainer>
  ) : (
    <Overlay>
      <SpinnerContainer overlay={overlay}>
        <Spinner name={name} color={color} />
      </SpinnerContainer>
    </Overlay>
  );
};
export default Loading;
