import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { WorklistTools } from "../interfaces";
import PlateWell from "./PlateWell";

const StyledSourcePlate = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0 4px -1px black;
  // background-color: white;
  border-radius: 5px;
  border: 1px solid #ded7e9;
`;

const StyledDestPlateElements = styled.div<{
  cols: number[];
  rows: number[];
}>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 4px 4px;
  grid-template-columns: repeat(${(props) => props.cols.length + 1}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows.length + 1}, 1fr);
  justify-items: center;
  align-items: center;
`;

const PlateHeaderCell = styled(Typography)<{
  rowStart: number;
  colStart: number;
}>`
  display: flex;
  grid-row-start: ${(props) => `${props.rowStart}`};
  grid-column-start: ${(props) => `${props.colStart}`};
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 5px;
`;

interface SourcePlateProps {
  worklistTool: WorklistTools;
  index: number;
  plateRows: number[];
  plateCols: number[];
  wellInfo: any[];
  borderColor?: string;
}

export function SourcePlate({
  worklistTool,
  index,
  plateRows,
  plateCols,
  wellInfo,
}: SourcePlateProps) {
  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    menuId: string
  ) => {
    e.preventDefault();
    contextMenu.show({
      id: menuId,
      event: e,
    });
  };

  const getWellColor = (row: number, col: number): string => {
    const index = wellInfo.findIndex(
      (e) => e.rowPos === row && e.colPos === col
    );

    if (index > -1) {
      return wellInfo[index].color;
    }
    return "";
  };

  const getSourceSampleBarcodeForTooltip = (rowPos: number, colPos: number) => {
    const index = wellInfo.findIndex(
      (e) => e.rowPos === rowPos && e.colPos === colPos
    );

    if (index > -1) {
      return wellInfo[index].sampleBarcode;
    }
    return "";
  };

  return (
    <StyledSourcePlate
      onContextMenu={(e) => {
        handleContextMenu(e, `${worklistTool}_Source_${index}`);
      }}
    >
      <StyledDestPlateElements rows={plateRows} cols={plateCols}>
        <PlateHeaderCell rowStart={1} colStart={1}></PlateHeaderCell>
        {plateRows.map((row, index) => (
          <PlateHeaderCell rowStart={row + 1} colStart={1}>
            <p
              style={{
                fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
              }}
            >
              {String.fromCharCode(64 + row)}
            </p>
          </PlateHeaderCell>
        ))}
        {plateCols.map((col, index) => (
          <PlateHeaderCell rowStart={1} colStart={col + 1}>
            <p
              style={{
                fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
              }}
            >
              {index + 1}
            </p>
          </PlateHeaderCell>
        ))}
        {plateRows.map((rowItem, rowIndex) =>
          plateCols.map((colItem, colIndex) => (
            <Tooltip
              title={getSourceSampleBarcodeForTooltip(
                rowIndex + 1,
                colIndex + 1
              )}
              placement="top"
            >
              <div style={{ width: "100%", height: "100%" }}>
                <PlateWell
                  plateIndex={index}
                  platePosition="Source"
                  plateWellId={
                    String.fromCharCode(65 + rowIndex) + String(colIndex + 1)
                  }
                  wellColor={getWellColor(rowIndex + 1, colIndex + 1)}
                  sourcePlateInfo={wellInfo}
                />
              </div>
            </Tooltip>
          ))
        )}
      </StyledDestPlateElements>
    </StyledSourcePlate>
  );
}
