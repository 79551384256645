import { makeVar } from "@apollo/client";
import {
  IncomingSampleActions,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import {
  GetIncomingSampleCollectionsQuery,
  GetIncomingSampleCollectionsQueryVariables,
  IncomingSampleCollectionFragment,
  useGetIncomingSampleCollectionsQuery,
} from "graphql/generated/schema-types";
import produce from "immer";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetPageChangeHandler } from "shared-components/Table/Handlers/HandlePageChange";
import { GetHandlePageCountChange } from "shared-components/Table/Handlers/HandlePageCountChange";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import {
  useCommonTableSideEffects,
  useInitializeConnectionData,
} from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { IncomingSampleCollectionContextMenu } from "../../ContextMenu/CollectionContextMenu";
import { IncomingSampleCollectionsTableColumns } from "./Columns";
import { DefaultIncomingCollectionSort } from "./defaults";
import { IncomingSampleCollectionsFilterReducer } from "./filter-factory";
import { handleContextMenu, useHandleRowClick } from "./handlers";

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedIncomingSampleCollectionsTableRows = () =>
  toggleAllRowsSelectedStore()(false);

export function IncomingSampleCollectionsTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//

  const { incomingSampleDispatch } = useIncomingSampleState();

  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
    });

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { refetch, loading, fetchMore, variables, data } =
    useGetIncomingSampleCollectionsQuery({
      variables: {
        where: undefined,
        order: DefaultIncomingCollectionSort,
      },
      fetchPolicy: "cache-and-network",
    });

  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData =
    useInitializeConnectionData<IncomingSampleCollectionFragment>(
      data?.incomingSampleCollections,
      paginationState
    );

  const totalCount = useMemo(
    () =>
      GetTotalCountFromConnectionType<IncomingSampleCollectionFragment>(
        data?.incomingSampleCollections
      ),
    [data?.incomingSampleCollections]
  );

  const pageInfo = useMemo(
    () =>
      GetPageInfoFromConnectionType<IncomingSampleCollectionFragment>(
        data?.incomingSampleCollections
      ),
    [data?.incomingSampleCollections]
  );

  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const paginationTuple = useMemo(
    () =>
      [paginationState, setPaginationState] as [
        ReactTablePaginationState,
        Dispatch<SetStateAction<ReactTablePaginationState>>
      ],
    [paginationState]
  );
  const sortTable = useMemo(
    () => GetSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const onPageChange = useMemo(
    () =>
      GetPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [fetchMore, pageInfo?.endCursor, paginationState, variables]
  );

  const handlePageCountChange = useMemo(
    () =>
      GetHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, variables]
  );

  const filterTable = useMemo(
    () =>
      GetFilterHandler<
        GetIncomingSampleCollectionsQuery,
        GetIncomingSampleCollectionsQueryVariables,
        IncomingSampleCollectionFragment
      >(
        variables,
        refetch,
        IncomingSampleCollectionsFilterReducer,
        paginationTuple
      ),
    [paginationTuple, refetch, variables]
  );

  const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const skipPageResetRef = React.useRef(false);

  const _pageData = useMemo(() => {
    skipPageResetRef.current = true;
    return pageData.map((entry) =>
      produce(entry, (draft) => {
        draft.dateTimeOfArrival = moment(draft?.dateTimeOfArrival)
          .startOf("day")
          .toISOString();
        draft.dateTimeOfCollection = moment(draft?.dateTimeOfCollection)
          .startOf("day")
          .toISOString();
      })
    );
  }, [pageData]);

  const tableInstance = useTable(
    {
      columns: IncomingSampleCollectionsTableColumns,
      data: _pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState: {
        sortBy: [{ id: "dateTimeOfArrival", desc: true }],
      },
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  React.useEffect(() => {
    skipPageResetRef.current = false;
  });

  const {
    state: { groupBy },
    selectedFlatRows,
    toggleAllRowsSelected,
  } = tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//
  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  useCommonTableSideEffects(
    paginationTuple,
    totalCount,
    variables,
    refetch,
    groupBy
  );

  useEffect(() => {
    incomingSampleDispatch({
      type: IncomingSampleActions.SELECT_COLLECTIONS,
      payload: {
        selectedCollections: selectedFlatRows
          .filter((row) => row.original?.collectionId)
          .map((row) => row.original.collectionId),
      },
    });
  }, [incomingSampleDispatch, selectedFlatRows]);
  //--------------------------------------------------------------- Markup ------------------------------------------------------------//

  return (
    <>
      <ReactTable
        tableInstance={tableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        onRowClick={handleRowClick}
        loading={loading}
      />
      <IncomingSampleCollectionContextMenu refetch={refetch} />
    </>
  );
}
