import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";

export function RunsModalContainer({ children }: Wrapper) {
  const {
    runsModalDispatch,
    runsModalState: { modalOpen },
  } = useRunsModalState();
  return (
    <AppModal
      open={modalOpen!}
      onClose={() => runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL })}
    >
      {children}
    </AppModal>
  );
}
