import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  IIncomingSampleActions,
  IIncomingSampleState,
  IncomingSampleState,
} from "./initial-state";
import { incomingStateReducer } from "./reducer";

export function useIncomingSampleState(): {
  incomingSampleState: IIncomingSampleState;
  incomingSampleDispatch: IApolloDispatch<IIncomingSampleActions>;
} {
  const incomingSampleState = useReactiveVar(IncomingSampleState);
  const incomingSampleDispatch = useMemo(
    () => ApolloStateDispatch(IncomingSampleState, incomingStateReducer),
    []
  );
  return useMemo(
    () => ({
      incomingSampleState,
      incomingSampleDispatch,
    }),
    [incomingSampleState, incomingSampleDispatch]
  );
}
