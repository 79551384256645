import { DateRange } from "@mui/lab";
import {
  ManagePersonalTask,
  ManagePersonalTaskFilterInput,
} from "graphql/generated/schema-types";
import { GetDayRange } from "helpers/date-helpers";
import _ from "lodash";
import { Filters } from "react-table";

export const PersonalTaskFilterReducer = (
  tableFilters: Filters<ManagePersonalTask>
) => {
  console.log(tableFilters);
  const where: ManagePersonalTaskFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "taskName":
        where!.and!.push({
          taskName: { contains: tableFilter.value },
        });
        break;
      case "status":
        where!.and!.push({
          status: { eq: tableFilter.value },
        });
        break;
      case "assignedUsers":
        where!.and!.push({
          assignedUsers: { some: { eq: tableFilter.value } },
        });
        break;
      case "assignedGroups":
        where!.and!.push({
          assignedGroups: { some: { eq: tableFilter.value } },
        });
        break;
      case "systemAssignedTo":
        where!.and!.push({
          systemAssignedTo: { eq: tableFilter.value },
        });
        break;
      case "startTimeScheduled":
        let range = tableFilter.value as DateRange<unknown>;
        if (range.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.startTimeScheduled);
          break;
        }
        if (range.some((date) => !date)) {
          break;
        }
        if (_.isEqual(range[0], range[1]))
          range = GetDayRange(tableFilter.value[0]);
        where!.and!.push({
          startTimeScheduled: { gt: range[0] },
        });
        where!.and!.push({
          startTimeScheduled: { lt: range[1] },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
