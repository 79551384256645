import { DatePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { FormName } from "enums/FormName";
import { useFormik } from "formik";
import {
  AppSuiteUser,
  CreateRunInput,
  FolderStructure,
  RunTemplate,
  useGetCreateRunDefaultsLazyQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { usePersistForm } from "hooks/usePersistForm";
import produce from "immer";
import { ChangeEvent, useMemo } from "react";
import {
  InsertableFolderField,
  RunTemplateField,
  UserField,
} from "shared-components/FormFields/FormFields";
import {
  FormSubmitButton,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { CreateRunValidationSchema } from "../../validation-schema";

export function CreateRunForm({
  initialValues,
  onSubmit,
}: {
  initialValues: CreateRunInput;
  onSubmit: FormikSubmit<CreateRunInput>;
}): JSX.Element {
  const [execute, { data, variables }] = useGetCreateRunDefaultsLazyQuery({
    fetchPolicy: "network-only",
  });
  const _initialValues = useMemo(
    () =>
      produce(initialValues, (draft) => {
        if (data?.createRunDefaults) {
          draft.runName = data?.createRunDefaults?.runName;
          draft.runTemplateId = variables!.runTemplateId;
          draft.folderParentId = data?.createRunDefaults?.runFolder;
        }
      }),
    [data?.createRunDefaults, initialValues, variables]
  );

  const props = useFormik({
    initialValues: _initialValues,
    enableReinitialize: true,
    validationSchema: CreateRunValidationSchema,
    onSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = props;
  usePersistForm(FormName.CreateRun, props);
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <RunTemplateField
          id="runTemplate"
          onChange={(e, newValue) => {
            const runTemplateId = (newValue as Maybe<RunTemplate>)
              ?.runTemplateId;
            if (runTemplateId)
              execute({
                variables: {
                  runTemplateId,
                },
              });
            setFieldValue("runTemplateId", runTemplateId);
          }}
          value={values.runTemplateId}
          label="Template"
          variant="outlined"
          helperText={
            errors.runTemplateId &&
            touched.runTemplateId &&
            errors.runTemplateId
          }
          error={
            !!(
              errors.runTemplateId &&
              touched.runTemplateId &&
              errors.runTemplateId
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          id="runName"
          label="Run Name"
          disabled={!data}
          value={values.runName}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.runName && touched.runName && errors.runName}
          error={!!(errors.runName && touched.runName && errors.runName)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <InsertableFolderField
          id="folder-combobox"
          value={values.folderParentId}
          groupBy={(entry) => entry?.folderParent?.folderName ?? ""}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
            setFieldValue(
              "folderParentId",
              (newValue as Maybe<FolderStructure>)?.folderId
            )
          }
          label="Folder Location"
          variant="outlined"
          helperText={
            errors.folderParentId &&
            touched.folderParentId &&
            errors.folderParentId
          }
          error={
            !!(
              errors.folderParentId &&
              touched.folderParentId &&
              errors.folderParentId
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <UserField
          id="user-combobox"
          value={values.assignedUsers}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
            setFieldValue(
              "assignedUsers",
              newValue === null
                ? newValue
                : (newValue as Maybe<AppSuiteUser>[])?.map(
                    (entry) => entry?.userId
                  )
            )
          }
          label="Assign All Tasks To"
          variant="outlined"
          helperText={
            errors.assignedUsers &&
            touched.assignedUsers &&
            errors.assignedUsers
          }
          multiple
          error={
            !!(
              errors.assignedUsers &&
              touched.assignedUsers &&
              errors.assignedUsers
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          id="comments"
          label="Comments"
          value={values.comments}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.comments && touched.comments && errors.comments}
          error={!!(errors.comments && touched.comments && errors.comments)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <DatePicker
          value={values.runDateScheduled}
          onChange={(date) => setFieldValue("runDateScheduled", date)}
          renderInput={(props) => (
            <TextField
              {...props}
              helperText={
                errors.runDateScheduled &&
                touched.runDateScheduled &&
                errors.runDateScheduled
              }
              error={
                !!(
                  errors.runDateScheduled &&
                  touched.runDateScheduled &&
                  errors.runDateScheduled
                )
              }
              fullWidth
              variant={"outlined"}
              label="Start Date"
            />
          )}
        />
      </StyledFormControl>
      <FormSubmitButton
        type="submit"
        data-testid={"submit-button"}
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Create Run
      </FormSubmitButton>
    </StyledForm>
  );
}
