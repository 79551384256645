import { useQuery } from "@apollo/client";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { RunPageContextMenuHidden } from "features/Runs/components/Table/RunsTable";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  GetRunActivitiesByIdsQuery,
  GetRunActivitiesByIdsQueryVariables,
  RunTaskInput,
  TaskStatusEnum,
  useUpdateRunTasksMutation,
} from "graphql/generated/schema-types";
import { RunQueries } from "graphql/queries/run.queries";
import { DeepCopy } from "helpers/object-helpers";
import React, { useMemo } from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { MenuItemEventHandler } from "react-contexify/lib/types";
import { Alert, AlertType } from "shared-components/toast";
import { MethodsWithDetails } from "constants/methodsWithDetails";

export function MarkMultipleRunTasksContextMenu(): JSX.Element {
  const {
    runsModalDispatch,
    runsModalState: { selectedTasks },
  } = useRunsModalState();
  const [updateRunTasks] = useUpdateRunTasksMutation();
  const { data, variables } = useQuery<
    GetRunActivitiesByIdsQuery,
    GetRunActivitiesByIdsQueryVariables
  >(RunQueries.local.GET_RUN_ACTIVITIES_BY_IDs, {
    variables: {
      runActivityIds: selectedTasks!,
    },
  });

  const editMethodDetails = useMemo(
    () => () => {
      const methodIdsEqual =
        data?.runTasksFromCache &&
        data.runTasksFromCache.every(
          (runTask) => runTask?.methodId === data.runTasksFromCache[0]?.methodId
        );
      const methodHasDetails = MethodsWithDetails.includes(
        data?.runTasksFromCache?.[0]?.methodId ?? -1
      );
      if (methodIdsEqual && methodHasDetails)
        runsModalDispatch({
          type: RunsModalActions.OPEN_MODAL,
          payload: {
            currentModal: RunTaskModals.EditMultipleRunActivityDetails,
            selectedTasks: (variables?.runActivityIds as number[]) ?? [],
          },
        });
      else if (
        !(methodHasDetails && methodIdsEqual) &&
        (data?.runTasksFromCache?.length ?? 0) > 0
      ) {
        Alert({
          type: AlertType.INFORMATION,
          message: "Selected tasks must all use the same method",
        });
      }
    },
    [data, runsModalDispatch, variables?.runActivityIds]
  );

  const allTasksManual = (runTasks: RunTaskInput[]) => {
    if (
      runTasks?.every(
        (rt) => rt.systemAssignedTo === 14 || rt.systemAssignedTo === null
      )
    )
      return true;
    return false;
  };

  const updateTasks = useMemo(
    () => (newValue: RunTaskInput) => {
      const inputs: RunTaskInput[] =
        data?.runTasksFromCache?.map((task) => {
          const updatedRunTaskInput = DeepCopy(newValue);
          updatedRunTaskInput.runActivityId = task?.runTaskId;
          updatedRunTaskInput.startTimeScheduled = task?.startTimeScheduled;
          updatedRunTaskInput.endTimeScheduled = task?.endTimeScheduled;
          updatedRunTaskInput.systemAssignedTo = task.systemAssignedTo;
          return updatedRunTaskInput;
        }) ?? [];

      if (
        !allTasksManual(inputs) &&
        (newValue.status === TaskStatusEnum.InProgress ||
          newValue.status === TaskStatusEnum.Completed)
      ) {
        return Alert({
          type: AlertType.ERROR,
          message:
            "You must mark automated tasks as 'inProgress' or 'Completed' individually.",
        });
      }

      return updateRunTasks({
        variables: {
          updateInputs: inputs,
        },
      }).then(() => {
        Alert({ type: AlertType.SUCCESS, message: "Status(es) Changed" });
      });
    },
    [data?.runTasksFromCache, updateRunTasks]
  );
  return (
    <Menu
      id="MarkMultipleRunTasksContextMenu"
      onHidden={() => {
        setTimeout(() => RunPageContextMenuHidden(true), 200);
      }}
    >
      <Submenu label="Mark Selected As">
        <Item onClick={() => updateTasks({ status: TaskStatusEnum.Completed })}>
          Completed
        </Item>
        <Item onClick={() => updateTasks({ status: TaskStatusEnum.Scheduled })}>
          Scheduled
        </Item>
        <Item
          onClick={() => updateTasks({ status: TaskStatusEnum.InProgress })}
        >
          In Progress
        </Item>
        <Item onClick={() => updateTasks({ status: TaskStatusEnum.Overdue })}>
          Overdue
        </Item>
        <Item onClick={() => updateTasks({ status: TaskStatusEnum.Cancelled })}>
          Cancelled
        </Item>
      </Submenu>
      <Item
        onClick={({ props }) => {
          runsModalDispatch({
            type: RunsModalActions.OPEN_MODAL,
            payload: {
              currentModal: RunTaskModals.EditMultipleRunActivity,
              selectedTasks: (props as { selected: number[] }).selected ?? [],
            },
          });
        }}
      >
        Edit All Tasks
      </Item>
      <Item onClick={() => editMethodDetails()}>Edit All Method Details</Item>
      <Item
        onClick={({ props }: MenuItemEventHandler) => {
          runsModalDispatch({
            type: RunsModalActions.OPEN_DELETE_DIALOG,
            payload: {
              selectedTasks: (props as { selected: number[] }).selected ?? [],
              deleteDialog: {
                open: true,
                typeName: "RunTask",
              },
            },
          });
        }}
      >
        Delete All
      </Item>
    </Menu>
  );
}
