/**
 * ArrayMethod values associated with dbo.Lookup_ArrayMethods table.
 * @enum {number} Methods
 */
export enum Methods {
  XTY_CustomPassage = 63,
  CeligoScan = 104,
  AutomatedFeed = 103,
  NormalizedPassage = 86,
  StampNormalizedPassage = 108,
  Thaw = 102,
  PoolingNormalization = 111,
  Twelve24TC = 145,
  EB_Feed = 107,
  Media_Exchange_96w = 112,
  Media_Exchange_12_24w = 114,
  Lynx_384_Variable_Feed = 122,
}

/**
 * AutomationMethod values associated with dbo.AutomationMethods table.
 * @enum {number} AutomationMethods
 */
export enum AutomationMethods {
  Thaw = 27,
  PoolingNormalization = 51,
}
