import { format } from "date-fns";
import { SystemCell } from "features/Runs/shared/TableCells";
import {
  IncomingSampleTaskTableEntryFragment,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import { Column } from "react-table";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { GetGroupFilter } from "shared-components/Table/Filters/GroupFilter";
import { GetMultiSelectFilter } from "shared-components/Table/Filters/MultiCheckboxFilter";
import { SystemFilter } from "shared-components/Table/Filters/SystemFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import {
  GroupCell,
  UserCell,
} from "shared-components/Table/sub-components/CacheLookupCells";

const dropdownOptions = Object.values(TaskStatusEnum).map((value) => ({
  value,
  name: value,
}));

export const IncomingSampleTasksTableColumns: Array<
  Column<IncomingSampleTaskTableEntryFragment>
> = [
  {
    Header: "Project",
    accessor:
      "incomingSampleType.collection.project.projectName" as keyof IncomingSampleTaskTableEntryFragment,
    Filter: TextFilter,
    width: "10%",
  },
  {
    Header: "Sample",
    accessor:
      "incomingSampleType.sampleType.sampleTypeString" as keyof IncomingSampleTaskTableEntryFragment,
    Filter: TextFilter,
    width: "20%",
    disableSortBy: true,
  },
  {
    Header: "Task",
    accessor: "task",
    Filter: TextFilter,
    width: "30%",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: GetMultiSelectFilter(dropdownOptions),
    width: "10%",
  },
  {
    Header: "User",
    accessor: "userAssignedTo",
    Filter: GetUserFilter(true),
    Cell: ({ value }) => (value ? <UserCell userId={value} /> : null),
    width: "10%",
  },
  {
    Header: "Group",
    accessor: "groupAssignedTo",
    Filter: GetGroupFilter(true),
    Cell: ({ value }) => (value ? <GroupCell groupId={value} /> : null),
    width: "10%",
  },
  {
    Header: "System",
    accessor: "systemAssignedTo",
    Filter: SystemFilter,
    Cell: ({ value }) => (value ? <SystemCell systemId={value} /> : null),
    width: "10%",
  },
  {
    Header: "Date",
    accessor: "startTimeScheduled",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? format(new Date(value), "MM-dd-yyyy") : null),
    width: "15%",
  },
];
