import {
  UpdateAppSuiteUserInput,
  UserFragment,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import * as _ from "lodash";
import { AppSuiteUserInputStub } from "mappings/object-stubs/AppSuiteUserInput";

export function AppSuiteUserInputMapper(user: Maybe<UserFragment>) {
  const input = _.pick(
    user,
    _.keys(new AppSuiteUserInputStub())
  ) as UpdateAppSuiteUserInput;
  if (user?.groups)
    input.groups = user.groups.map((group) => group?.groupId) as number[];
  return input;
}
