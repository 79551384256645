import { Box, Typography } from "@mui/material";
import { AppTheme } from "App.theme";
import { FeedGroupInput } from "graphql/generated/schema-types";
import moment from "moment";
import Loading from "shared-components/Loading";
import * as Yup from "yup";

export interface AddFeedTaskProps {
  isOpen: boolean;
  handleClose: () => void;
}

export interface FeedGroupErrors {
  feedGroupArray: { [key in keyof FeedGroupInput]: string }[];
}

export const validators = {
  feedGroupArray: Yup.array().of(
    Yup.object().shape({
      feedGroupName: Yup.string()
        .required("Enter FeedGroup Name")
        .min(3, "Too Short!")
        .max(20, "20 character max"),
      methodId: Yup.number().required("Select Method").min(1, "Select Method"),
      arraySystemId: Yup.number()
        .required("Select System")
        .min(1, "Select System"),
      numberOfPlates: Yup.number().required("Add Plates").min(1, "Add Plates"),
      startTimeScheduled: Yup.date().required("Select Start Time"),
    })
  ),
};

export const roundedCurrentTime = () => {
  //TODO: Refactor to use only moment.js
  const now = new Date();
  now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
  now.setSeconds(0);
  const momentNow = moment(now);
  return momentNow;
};

export const feedDayToday = "today";
export const feedDayTomorrow = "tomorrow";

export const formatAndConvertToEst = (startTimeScheduled: string) => {
  const localDate = new Date(startTimeScheduled);

  const currentMoment = moment();
  const yearMonthDate = {
    year: currentMoment.year(),
    month: currentMoment.month(),
    date: currentMoment.date(),
  };
  const formattedTimeInEst = moment(localDate).set(yearMonthDate).format();

  return formattedTimeInEst;
};

export const renderLoadingOrNoFeedGroups = (
  loading: boolean,
  feedGroupsEmpty: boolean
) => {
  if (loading) {
    return (
      <Box sx={{ my: 2 }}>
        <Loading
          name="ball-spin-fade-loader"
          color={AppTheme.palette.primary.main}
        />
      </Box>
    );
  }

  if (feedGroupsEmpty) {
    return (
      <Box sx={{ my: 2 }}>
        <Typography align={"center"} variant={"h5"}>
          No saved Feed Groups. Add group above.
        </Typography>
      </Box>
    );
  }

  return null;
};

export const blankFeedGroupRow = (groupId: number | null | undefined) => {
  return {
    feedGroupId: null,
    groupId: groupId ?? 0,
    arraySystemAutomationMethodId: 0,
    feedGroupName: "",
    isActive: true,
    startTimeScheduled: roundedCurrentTime().clone().format(),
    numberOfPlates: 0,
    methodId: 0,
    arraySystemId: 0,
    automationMethodId: 0,
  };
};

export const setDatesToTomorrow = (feedGroupsWithNoTasks: FeedGroupInput[]) => {
  return feedGroupsWithNoTasks.map((feedgroup) => {
    const tomorrowDate = moment(feedgroup.startTimeScheduled)
      .clone()
      .add(1, "days")
      .format();

    feedgroup.startTimeScheduled = tomorrowDate;
    return feedgroup;
  });
};
