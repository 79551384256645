import {
  EditMultipleTasksForm,
  MarkMultipleFormFields,
} from "features/Runs/components/Forms/EditMultiple/Task/EditMultipleTasksForm";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { FormikHelpers } from "formik";
import {
  RunTask,
  useGetRunTaskMetaDataQuery,
} from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { StyledCard } from "shared-components/styled-components";
import styled from "styled-components/macro";
import { AppTheme } from "App.theme";
import Loading from "shared-components/Loading";

const Container = styled.div`
  display: flex;
  min-height: 300px;
`;

export function MarkMultipleModal() {
  const windowSize = useWindowSize();
  const { runsModalState, runsModalDispatch } = useRunsModalState();
  const { loading } = useGetRunTaskMetaDataQuery({
    variables: {
      taskIds: runsModalState?.selectedTasks ?? [],
    },
  });
  const handleSubmit = (
    values: MarkMultipleFormFields,
    { setSubmitting, setFieldError }: FormikHelpers<MarkMultipleFormFields>
  ) => {
    const fieldValuePairs = Object.entries(values).filter(
      (pair) => pair[1] !== undefined
    );
    if (fieldValuePairs.length === 0) {
      setFieldError("formError", "Please enter a value");
      setSubmitting(false);
      return;
    }
    runsModalDispatch({
      type: RunsModalActions.SUBMITTED_EDIT_FORM,
      payload: {
        editedFieldValuePairs: fieldValuePairs as Array<[keyof RunTask, any]>,
        selectedTasks: runsModalState.selectedTasks,
      },
    });
  };
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <Container>
        {loading ? (
          <Loading
            name={"rotating-plane"}
            color={AppTheme.palette.primary.main}
          />
        ) : (
          <EditMultipleTasksForm onSubmit={handleSubmit} />
        )}
      </Container>
    </StyledCard>
  );
}
