import {
  IncomingSampleActions,
  IncomingSampleScreensEnum,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import { FormikErrors } from "formik";
import {
  CreateIncomingSampleCollectionInput,
  CreateIncomingSampleTypeInput,
  useCreateCollectionMutation,
} from "graphql/generated/schema-types";
import { ValidateObject } from "helpers/validateObject";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import _ from "lodash";
import { useMemo } from "react";
import { ScheduleIncomingSampleFormActions } from "../../Forms/ScheduleSamples/state";
import { CreateIncomingSampleTypeValidationSchema } from "../../Forms/validation-schema";

async function getErrors(
  sampleStore: Array<{
    key: string;
    sample: CreateIncomingSampleTypeInput;
    error: FormikErrors<CreateIncomingSampleTypeInput>;
  }>
) {
  const errorPromises = sampleStore.map(async (entry) => ({
    key: entry.key,
    error: await ValidateObject(
      entry.sample,
      CreateIncomingSampleTypeValidationSchema
    ),
  }));
  const errors = await Promise.all(errorPromises);

  return errors
    .map((entry) => ({ key: entry.key, error: entry.error ?? {} }))
    .filter((entry) => !_.isEmpty(entry.error));
}
export const useGetHandleCreateCollectionSubmit = () => {
  const [createCollection] = useCreateCollectionMutation();
  const { incomingSampleDispatch } = useIncomingSampleState();
  const sampleStore = useAppReduxSelector(
    (state) => state.ScheduleIncomingSampleForm.store
  );
  const dispatch = useAppReduxDispatch();
  return useMemo(
    () => async (values: CreateIncomingSampleCollectionInput) => {
      values.incomingSamplesCollectionSampleTypes = sampleStore.map(
        (entry) => entry.sample
      );

      const indexedErrors = await getErrors(sampleStore);

      dispatch(ScheduleIncomingSampleFormActions.SET_ERROR(indexedErrors));

      if (indexedErrors.length > 0) return console.log(indexedErrors);

      return createCollection({
        variables: {
          input: values,
        },
      }).then(() => {
        incomingSampleDispatch({
          type: IncomingSampleActions.SELECT_SCREEN,
          payload: {
            activeScreen: IncomingSampleScreensEnum.Collections,
          },
        });
      });
    },
    [createCollection, dispatch, incomingSampleDispatch, sampleStore]
  );
};
