import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { ThawToolState } from "../../state";
import { IThawToolInternalState } from "../../state/initial-state";

export const getSourcePlateInfoForTemplate = (thawState: ThawToolState) =>
  thawState.sourcePlateInfo.map((item, index) => {
    return {
      plateType: PlatePosition.Source,
      plateIndex: index,
      labwareTypeCode: item.labwareTypeCode,
      numberOfRows: item.rows.length,
      numberOfColumns: item.cols.length,
    };
  });

export const getDestPlateInfoForTemplate = (thawState: ThawToolState) =>
  thawState.destPlateInfo
    .filter((plate) => plate.labwareTypeCode !== "")
    .map((plate, index) => {
      return {
        plateType: PlatePosition.Destination,
        plateIndex: index,
        labwareTypeCode: plate.labwareTypeCode,
        numberOfRows: plate.rows.length,
        numberOfColumns: plate.cols.length,
        operatingVol: plate.operatingVol,
      };
    });

export const getThawTask = (thawToolInternalState: IThawToolInternalState) => [
  {
    task: "Thaw",
    details: "",
    dispenseVol: (
      thawToolInternalState.methodSettings.resuspensionVol + 25
    ).toString(),
    transferVol: "",
    sourcePlatePosition: 0,
  },
];

export const getThawDestination = (
  thawState: ThawToolState,
  thawToolInternalState: IThawToolInternalState
) =>
  thawState.destPlateInfo
    .map((plate, index) => ({
      task: "ThawDestination",
      details: `Preprocess|${+plate.preprocessPlate};InCytomat|${+plate.inCytomat};`,
      transferVol: `${thawToolInternalState.methodSettings.topupVol}`,
      destinationPlateType: plate.labwareTypeCode,
      destinationPlatePosition: index,
    }))
    .filter((e) => e.destinationPlateType !== "");

export const getThawTransfer = (thawState: ThawToolState) =>
  thawState.worklistValues.thawTransfer.map((row: any) => ({
    task: "ThawTransfer",
    sourcePlatePosition: row.sourcePlateIndex,
    sourceWellID: row.sourceWellId,
    destinationPlatePosition: row.destPlateIndex,
    destinationWellID: row.destWellId,
    transferVol: row.transferVol.toString(),
  }));

export const getThawStamp = (thawState: ThawToolState) =>
  thawState.worklistValues.thawStamp.map((ts) => {
    const destinationPlateType = thawState.destPlateInfo.filter(
      (dpi) => dpi.index === ts.plateIndex
    )[0].labwareTypeCode;

    return {
      task: "ThawStamp",
      transferVol: ts.stampVol.toString(),
      destinationPlateType: destinationPlateType,
      destinationPlatePosition: ts.plateIndex,
      destinationWellID: ts.wellId,
    };
  });

export const getMatrixRackCentrifugation = (
  thawToolInternalState: IThawToolInternalState
) => {
  const ms = thawToolInternalState.methodSettings;
  return [
    {
      task: "MatrixRackCentrifugation",
      details: `VSpinRPM|${ms.rpm};VSpinTime|${ms.spinTime};VSpinAccel|${ms.spinAccel};VSpinDecel|${ms.spinDecel};`,
    },
  ];
};
