import { Assignment } from "@mui/icons-material";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import TodayIcon from "@mui/icons-material/Today";
import { List, ListItemText } from "@mui/material";
import { FC } from "react";
import {
  StyledListItem,
  StyledListItemIcon,
  StyledMenu,
} from "../../shared-components/styled-components";
import { AccountSettingsScreen } from "./Settings";

export const SideBar: FC<{
  stateTuple: [AccountSettingsScreen, React.Dispatch<AccountSettingsScreen>];
}> = ({ stateTuple: [activeScreen, setScreen] }) => {
  return (
    <StyledMenu>
      <List>
        <StyledListItem
          active={activeScreen === AccountSettingsScreen.Theme}
          onClick={() => setScreen(AccountSettingsScreen.Theme)}
        >
          <StyledListItemIcon
            active={activeScreen === AccountSettingsScreen.Theme}
          >
            <Assignment />
          </StyledListItemIcon>
          <ListItemText primary={"Theme User Settings"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === AccountSettingsScreen.Notifications}
          onClick={() => setScreen(AccountSettingsScreen.Notifications)}
        >
          <StyledListItemIcon
            active={activeScreen === AccountSettingsScreen.Notifications}
          >
            <EditNotificationsIcon />
          </StyledListItemIcon>
          <ListItemText primary={"Notification Settings"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === AccountSettingsScreen.TablePresets}
          onClick={() => setScreen(AccountSettingsScreen.TablePresets)}
        >
          <StyledListItemIcon
            active={activeScreen === AccountSettingsScreen.TablePresets}
          >
            <Assignment />
          </StyledListItemIcon>
          <ListItemText primary={"Manage Tables Presets"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === AccountSettingsScreen.Calendar}
          onClick={() => setScreen(AccountSettingsScreen.Calendar)}
        >
          <StyledListItemIcon
            active={activeScreen === AccountSettingsScreen.Calendar}
          >
            <TodayIcon />
          </StyledListItemIcon>
          <ListItemText primary={"Calendar Settings"} />
        </StyledListItem>
      </List>
    </StyledMenu>
  );
};
