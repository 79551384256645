import {
  Autocomplete,
  Paper,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { AppTheme } from "App.theme";
import { WellAction } from "features/Plates/state/actions";
import { useWellStateProvider } from "features/Plates/state/StateProvider";
import React, { useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import { OverlayField, wellListFieldsMapping } from "../constants";
import { Plate } from "../helpers";

const OverlaysContainer = styled(Paper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  grid-row: 1/2;
  width: 45%;
  min-width: 520px;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "rgba(0,0,0,0)" : "white"} !important;
`;

const ToggleButtonContainer = styled(ToggleButton)`
  border: ${({ selected, theme }: { theme: DefaultTheme; selected: boolean }) =>
    theme.palette.mode === "dark"
      ? selected
        ? "rgba(18,18,18,0.5)"
        : ""
      : selected
      ? "rgba(0, 0, 0, 0.3)"
      : "1px solid rgba(204, 40, 40, 0.3)"} !important;
  background-color: ${({
    selected,
    theme,
  }: {
    theme: DefaultTheme;
    selected: boolean;
  }) =>
    theme.palette.mode === "dark"
      ? selected
        ? "rgba(18,18,18,0.5)"
        : ""
      : selected
      ? "rgba(0,0,0, 0.1)"
      : "white"} !important;
  color: ${({ selected, theme }: { theme: DefaultTheme; selected: boolean }) =>
    theme.palette.mode === "dark"
      ? selected
        ? "rgba(255,255,255,0.4)"
        : "white"
      : selected
      ? "rgba(0,0,0, 0.7)"
      : AppTheme.palette.primary.main} !important;
  box-shadow: ${({ selected }: { selected: boolean }) =>
    selected ? "inset 0px 0px 8px rgba(0,0,0, 0.3)" : "none"} !important;
  height: 40px !important;
  width: 200px !important;
  text-transform: none !important;
`;

const ButtonContent = styled(Typography)`
  text-align: center;
  font-size: 100%;
  font-weight: ${AppTheme.typography.fontWeightMedium} !important;
`;

const AutocompleteContainer = styled(Paper)`
  width: 300px;
  margin-left: 40px;
`;

const InputContainer = styled(TextField)`
  color: ${AppTheme.palette.primary.main} !important;
  text-align: center;
  font-size: 100%;
  font-weight: ${AppTheme.typography.fontWeightMedium} !important;
`;

export const OverlayFilter = ({ selectedPlate }: { selectedPlate: Plate }) => {
  const [overlay, setOverlay] = useState<OverlayField | "">("");
  const [showInactive, setShowInactive] = useState<boolean>(true);
  const { wellDispatch } = useWellStateProvider();

  const overlayOptions: OverlayField[] = [];

  if (selectedPlate.colorOverlayMap) {
    (Object.keys(selectedPlate.colorOverlayMap) as OverlayField[])?.forEach(
      (field) => {
        if (
          selectedPlate.colorOverlayMap &&
          Object.keys(selectedPlate.colorOverlayMap[field]).length > 0
        ) {
          overlayOptions.push(field);
        }
      }
    );
  }

  const handleSelect = (val: string) => {
    const newOverlay = val === "" ? val : (val as OverlayField);
    wellDispatch({
      type: WellAction.TOGGLE_OVERLAY,
      payload: {
        currentOverlay: newOverlay === "" ? undefined : newOverlay,
      },
    });
    setOverlay(newOverlay);
  };

  const handleCheckbox = () => {
    setShowInactive(!showInactive);
    wellDispatch({
      type: WellAction.TOGGLE_SHOW_INACTIVE,
    });
  };

  return (
    <OverlaysContainer elevation={0}>
      <Paper elevation={3}>
        <ToggleButtonContainer
          value="check"
          selected={showInactive}
          onChange={handleCheckbox}
        >
          <ButtonContent>{"Gray-out Inactive Wells"}</ButtonContent>
        </ToggleButtonContainer>
      </Paper>
      <AutocompleteContainer elevation={3}>
        <Autocomplete
          style={{ width: "100%" }}
          options={overlayOptions}
          getOptionLabel={(field) =>
            field === "" ? "" : wellListFieldsMapping[field]
          }
          value={overlay}
          onChange={(e, value) => handleSelect(value as string)}
          renderInput={(params) => (
            <InputContainer
              {...params}
              label="Color Overlay Filter"
              placeholder="Select Overlay Field"
              variant="outlined"
              size="small"
            />
          )}
          loadingText="Loading overlay..."
        />
      </AutocompleteContainer>
    </OverlaysContainer>
  );
};
