import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { PageWithNav } from "features/Navbar/PageWithNav";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import React, { useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { Twelve24TCToolbar } from "../1224TC/toolbar/Twelve24TCToolbar";
import { Twelve24TC } from "../1224TC/Twelve24TC";
import { PoolingNormalization } from "../PoolingNormalization/PoolingNormalization";
import { PoolingNormalizationToolActions } from "../PoolingNormalization/state";
import { PoolingNormalizationToolbar } from "../PoolingNormalization/toolbar/PoolingNormalizationToolbar";
import { useCtrlDown } from "../shared/hooks/hooks";
import { WorklistTools } from "../shared/interfaces";
import { ThawToolActions } from "../ThawWorklistTool/state";
import { ThawTool } from "../ThawWorklistTool/ThawWorklistTool";
import { ThawToolbar } from "../ThawWorklistTool/toolbar/ThawToolbar";

const StyledTopNavBarContainer = styled.div`
  margin: 20px;
  width: 90%;
  display: flex;
`;
export const useGetMethodIdFromURL = () => {
  const params = useParams<{ methodId?: string }>();
  return useMemo(() => {
    const parsed = parseInt(params?.methodId ?? "null");
    return isNaN(parsed) ? null : parsed;
  }, [params?.methodId]);
};

export const WorklistTool = () => {
  const [worklistTool, setWorklistTool] = useState<WorklistTools>(
    WorklistTools.PoolingNormalization
  );
  const dispatch = useAppReduxDispatch();
  const methodId = useGetMethodIdFromURL();
  const history = useHistory();
  useEffect(() => {
    setWorklistTool(methodId ?? WorklistTools.PoolingNormalization);
  }, [methodId]);

  const ctrlDown = useCtrlDown();
  useEffect(() => {
    window.addEventListener("keydown", ctrlDown.ctrlFunction);
    return () => window.removeEventListener("keydown", ctrlDown.ctrlFunction);
  }, [ctrlDown.worklistToolInternalState.setCtrlDown]);

  useEffect(() => {
    window.addEventListener("keyup", ctrlDown.ctrlFunction);
    return () => window.removeEventListener("keyup", ctrlDown.ctrlFunction);
  }, [ctrlDown.worklistToolInternalState.setCtrlDown]);

  const changeWorklistTool = (tool: WorklistTools) => {
    switch (tool) {
      case WorklistTools.Thaw:
        dispatch(ThawToolActions.RESET_STATE());
        history.replace(`/worklist-tool/${WorklistTools.Thaw}`);
        break;
      case WorklistTools.PoolingNormalization:
        dispatch(PoolingNormalizationToolActions.RESET_STATE());
        history.replace(`/worklist-tool/${WorklistTools.PoolingNormalization}`);
        break;
      case WorklistTools.Twelve24TC:
        dispatch(PoolingNormalizationToolActions.RESET_STATE());
        history.replace(`/worklist-tool/${WorklistTools.Twelve24TC}`);
        break;
    }
    setWorklistTool(tool);
  };
  return (
    <PageWithNav>
      <div
        style={{
          height: "100%",
        }}
      >
        <StyledTopNavBarContainer>
          <FormControl>
            <InputLabel id="demo-simple-select-label">
              <Typography>Method</Typography>
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={worklistTool}
              label="Method"
              style={{ width: "280px" }}
              onChange={(e) => {
                changeWorklistTool(e.target.value as WorklistTools);
              }}
            >
              <MenuItem value={WorklistTools.Thaw}>Thaw</MenuItem>
              <MenuItem value={WorklistTools.PoolingNormalization}>
                Pooling Normalization
              </MenuItem>
              <MenuItem value={WorklistTools.Twelve24TC}>1224TC</MenuItem>
            </Select>
          </FormControl>
          <WorklistToolToolbar worklistTool={worklistTool} />
        </StyledTopNavBarContainer>
        <WorklistToolReducer worklistTool={worklistTool} />
        <Prompt
          when={true}
          message={
            "Are you sure you want to go back? You will lose all your changes."
          }
        />
      </div>
    </PageWithNav>
  );
};

const WorklistToolToolbar = ({ worklistTool }: any): JSX.Element => {
  switch (worklistTool) {
    case WorklistTools.Thaw:
      return <ThawToolbar />;
    case WorklistTools.PoolingNormalization:
      return <PoolingNormalizationToolbar />;
    case WorklistTools.Twelve24TC:
      return <Twelve24TCToolbar />;
    default:
      return <React.Fragment></React.Fragment>;
  }
};

const WorklistToolReducer = ({ worklistTool }: any): JSX.Element => {
  switch (worklistTool) {
    case WorklistTools.Thaw:
      return <ThawTool />;
    case WorklistTools.PoolingNormalization:
      return <PoolingNormalization />;
    case WorklistTools.Twelve24TC:
      return <Twelve24TC />;
    default:
      return <React.Fragment></React.Fragment>;
  }
};
