import { useReactiveVar } from "@apollo/client";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { ReadOnlyPlateTableRow } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/ReadOnlyPlateTableRow";
import { CeligoPlateMetadata } from "features/WorklistTools/CeligoScan/Dialog/state";
import React from "react";
import { StyledTableHead, TableContainer } from "./PlatesTable";

export function ReadOnlyPlatesTable({ prefix }: { prefix: string }) {
  const plateMetadata = useReactiveVar(CeligoPlateMetadata);
  return (
    <TableContainer>
      <Table size={"small"}>
        <StyledTableHead>
          <TableRow>
            <TableCell align="center" width={"15%"}>
              Plates
            </TableCell>
            <TableCell align="center" width={"15%"}>
              Plate Pitch
            </TableCell>
            <TableCell align="center" width={"25%"}>
              Experiment Settings
            </TableCell>
            <TableCell align="center" width={"15%"}>
              Img Export Settings
            </TableCell>
            <TableCell align="center" width={"15%"}>
              Img Export Format
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {plateMetadata
            .filter((metadata) => metadata.sourcePlateBarcode.includes(prefix))
            .map((metadata) => (
              <ReadOnlyPlateTableRow
                key={metadata.sourcePlateBarcode}
                metadata={metadata}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
