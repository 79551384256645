import { TextField } from "@mui/material";
import { ButtonGroupSelector } from "features/WorklistTools/shared/components/ButtonGroupSelector";
import { GetUserSettings } from "helpers/user-claim-helpers";
import { useAppReduxSelector } from "hooks/reduxHooks";
import React, { useState } from "react";
import DataGrid, { TextEditor } from "react-data-grid";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { PoolingNormalizationToolActions } from "../../state";

const StyledWellMapping = styled(DataGrid)`
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
`;

export const DestinationWellMapping = () => {
  const dispatch = useDispatch();
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );

  const columns = [
    {
      key: "sourcePlateIndex",
      name: "Source Plate",
      formatter(props: any) {
        return <>{props.row.sourcePlateIndex + 1}</>;
      },
      resizable: true,
    },
    { key: "sourceWellId", name: "Source Pos", resizable: true },
    {
      key: "destPlateIndex",
      name: "Dest Plate",
      formatter(props: any) {
        return <>{props.row.destPlateIndex + 1}</>;
      },
      resizable: true,
    },
    { key: "destWellId", name: "Dest Pos", resizable: true },
    {
      key: "transferVol",
      name: "Cell Number",
      resizable: true,
      editor: TextEditor,
    },
  ];

  const [cellNumber, setCellNumber] = useState<number | undefined>();

  const handleApplyCellNumber = (option: string) => {
    switch (option) {
      case "Apply to All":
        dispatch(
          PoolingNormalizationToolActions.UPDATE_ALL_DEST_STAMP_CELL_NUMBER({
            transferVol: cellNumber,
          })
        );
        break;
      case "Apply to Empty":
        dispatch(
          PoolingNormalizationToolActions.UPDATE_EMPTY_DEST_CELL_NUMBERS({
            transferVol: cellNumber,
          })
        );
        break;
      case "Apply to Selected":
    }
  };

  const handleCellNumberChange = (value: string) => {
    setCellNumber(parseInt(value));
  };

  const setSingleCellNumber = (rows: unknown[]) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_DEST_CELL_NUMBER({
        rows,
      })
    );
  };

  const theme = GetUserSettings()?.theme?.mode ?? "light";
  return (
    <React.Fragment>
      <TextField
        value={cellNumber}
        type="number"
        onChange={(e) => handleCellNumberChange(e.target.value)}
        size="small"
      />
      <ButtonGroupSelector handleApplyCellNumber={handleApplyCellNumber} />
      <StyledWellMapping
        className={theme === "light" ? "rdg-light" : "rdg-dark"}
        columns={columns}
        rows={[
          ...worklistValues.int1ToDest,
          ...worklistValues.int2ToDest,
          ...worklistValues.int3ToDest,
        ]}
        onRowsChange={setSingleCellNumber}
      />
    </React.Fragment>
  );
};
