import { Container } from "graphql/generated/schema-types";
import { OverlayColors, OverlayField, overlayFieldsArray } from "./constants";

export interface ColorMap {
  [key: string]: string;
}

export type ColorOverlayMapAll = {
  [key in OverlayField]: ColorMap;
};

export interface Plate {
  plateBarcode: string | null;
  plateBarcodeAlias: string | null;
  lastScanDate: string | null;
  layout:
    | "1Well"
    | "6Well"
    | "24Well"
    | "12Well"
    | "48Well"
    | "96Well"
    | "384Well"
    | "";
  numRows: number;
  numCols: number;
  run: string | null;
  wells: IWell[][];
  colorOverlayMap?: ColorOverlayMapAll;
  notes?: Note[];
}

export interface Note {
  type: "Plate Note" | "Sample Note";
  rowCol?: string;
  note: string;
  dateCommented: string;
  user?: string;
}

export type IWell = {
  layout: string;
  layoutCols: number;
  empty: boolean;
  isActive: boolean;
  containerId: number | null;
  RowPos: number;
  ColPos: number;
  RowCol: string;
  sampleBarcode: string | null;
  sampleBarcodeAliases: string | null;
  barcode: string | null;
  passageNumber: string | null;
  lotNumber: string | null;
  lastDataPointValue: string | null;
  lastDataPointSummary: string | null;
  markingSummary: string | null;
  contaminatedMarking: string | null;
  differentiatedMarking: string | null;
  noCellsMarking: string | null;
  clonalityMarking: string | null;
  qcPassFailMarking: string | null;
  colonyMarking: string | null;
  sangerSeqMarking: string | null;
  conReviewMarking: string | null;
  nyscfid: string | null;
  collaboratorId: string | null;
  sourceCellLine: string | null;
  fibroblastLotNumber: string | null;
  spitLotNumber: string | null;
  bloodProcessingLotNumber: string | null;
  reprogrammingLotNumber: string | null;
  consolidationLotNumber: string | null;
  monoclonalizationLotNumber: string | null;
  expansionLotNumber: string | null;
  geneEditingLotNumber: string | null;
  dnaExtractionLotNumber: string | null;
  externalLotNumber: string | null;
  otherLotNumber: string | null;
};

//TODO: Write test for these functions (not required but its great experience)
export const createPlateMapLayout = (
  plate: Plate,
  layoutID: number | undefined
) => {
  switch (layoutID) {
    case 6:
      plate.numRows = 1;
      plate.numCols = 1;
      plate.layout = "1Well";
      // console.log("1Well", plate.numRows, plate.numCols);
      return plate;
      break;
    case 1:
      plate.numRows = 2;
      plate.numCols = 3;
      plate.layout = "6Well";
      // console.log("6Well", plate.numRows, plate.numCols);
      return plate;
      break;
    case 2:
      plate.numRows = 3;
      plate.numCols = 4;
      plate.layout = "12Well";
      // console.log("12Well", plate.numRows, plate.numCols);
      return plate;
      break;
    case 3:
      plate.numRows = 4;
      plate.numCols = 6;
      plate.layout = "24Well";
      // console.log("24Well", plate.numRows, plate.numCols);
      return plate;
      break;
    case 5:
      plate.numRows = 6;
      plate.numCols = 8;
      plate.layout = "48Well";
      // console.log("48Well", plate.numRows, plate.numCols);
      return plate;
      break;
    case 4:
      plate.numRows = 8;
      plate.numCols = 12;
      plate.layout = "96Well";
      // console.log("96Well", plate.numRows, plate.numCols);
      return plate;
      break;
    case 7:
      plate.numRows = 16;
      plate.numCols = 24;
      plate.layout = "384Well";
      // console.log("384Well", plate.numRows, plate.numCols);
      return plate;
      break;
    default:
      console.log(" plate type not supported");
  }
};

export const getGradientColors = (vals: Set<string>, type: string) => {
  const colorMap: ColorMap = {};
  const numVals = [...vals].map((val) => parseFloat(val));
  const maxVal = Math.max(...numVals);
  const minVal = Math.min(...numVals);
  const brightness = (valStr: string) => {
    const val = parseFloat(valStr);
    if (type === "Conf") {
      return Math.floor(100 - val * 0.95);
    } else {
      return Math.floor(100 - (95 * (val - minVal)) / (maxVal - minVal));
    }
  };

  vals.forEach((val) => {
    const color = `hsl(209, 100%, ${brightness(val)}%)`;
    colorMap[val] = color;
  });

  return colorMap;
};

export const getColors = (vals: Set<string>) => {
  const paletteSize =
    vals.size > 280
      ? 0
      : vals.size > 96
      ? 280
      : vals.size > 24
      ? 96
      : vals.size > 12
      ? 24
      : 12;

  if (!paletteSize) {
    console.log("need more than 280 colors");
    return {};
  }

  const palette = OverlayColors[paletteSize];

  const getIndices: Set<number> = new Set();

  while (getIndices.size < vals.size) {
    getIndices.add(Math.floor(Math.random() * paletteSize));
  }

  const colorIndices: number[] = Array.from(getIndices);

  const colorMap: ColorMap = {};
  vals.forEach((val) => {
    const idx = colorIndices.pop();
    if (idx !== undefined) {
      colorMap[val] = palette[idx];
    }
  });

  return colorMap;
};

export const getWells = (
  containers: Array<Container>,
  rows: number,
  cols: number,
  layout: string,
  notes: Note[]
): [IWell[][], ColorOverlayMapAll, Note[]] => {
  const distinctFieldVals = overlayFieldsArray.map(() => new Set<string>());
  let lastDataPointType = "";

  const wells: IWell[][] = [];
  containers?.forEach((well) => {
    if (well) {
      well.containerNotes?.forEach((note) =>
        notes.push({
          type: "Sample Note",
          rowCol: well.containerMetadata?.rowCol as string,
          note: note?.note,
          dateCommented: new Date(note?.dateCommented).toLocaleString(),
          user: note?.user?.userName,
        } as Note)
      );
      const r = (well.containerMetadata?.rowPos ?? 0) - 1;
      const c = (well.containerMetadata?.colPos ?? 0) - 1;
      if (!wells[r]) {
        wells[r] = [];
      }

      const wellObj: IWell = {
        RowPos: well.containerMetadata?.rowPos ?? 0,
        ColPos: well.containerMetadata?.colPos ?? 0,
        RowCol: well.containerMetadata?.rowCol as string,
        containerId: well.containerId ?? 0,
        barcode: well.containerMetadata?.barcode
          ? (well.containerMetadata?.barcode as string)
          : null,
        sampleBarcode: well?.containerMetadata?.sampleBarcode as string,
        sampleBarcodeAliases: well?.containerMetadata
          ?.sampleBarcodeAliases as string,
        passageNumber: well.passageNumber ? well.passageNumber + "" : null,
        lotNumber: well?.containerMetadata?.lotNumber as string,
        lastDataPointValue: well?.containerMetadata
          ?.lastDataPointValue as string,
        lastDataPointSummary: well?.containerMetadata
          ?.lastDataPointSummary as string,
        markingSummary: well?.containerMetadata?.markingSummary as string,
        contaminatedMarking: well?.containerMetadata
          ?.contaminatedMarking as string,
        differentiatedMarking: well?.containerMetadata
          ?.differentiatedMarking as string,
        noCellsMarking: well?.containerMetadata?.noCellsMarking as string,
        clonalityMarking: well?.containerMetadata?.clonalityMarking as string,
        qcPassFailMarking: well?.containerMetadata?.qcpassFailMarking as string,
        colonyMarking: well?.containerMetadata?.colonyMarking as string,
        sangerSeqMarking: well?.containerMetadata?.sangerSeqMarking as string,
        conReviewMarking: well?.containerMetadata?.conReviewMarking as string,
        nyscfid: well?.containerMetadata?.nyscfid as string,
        collaboratorId: well?.containerMetadata?.collaboratorId as string,
        sourceCellLine: well?.containerMetadata?.sourceCellLine as string,
        fibroblastLotNumber: well?.containerMetadata
          ?.fibroblastLotNumber as string,
        spitLotNumber: well?.containerMetadata?.spitLotNumber as string,
        bloodProcessingLotNumber: well?.containerMetadata
          ?.bloodProcessingLotNumber as string,
        reprogrammingLotNumber: well?.containerMetadata
          ?.reprogrammingLotNumber as string,
        consolidationLotNumber: well?.containerMetadata
          ?.consolidationLotNumber as string,
        monoclonalizationLotNumber: well?.containerMetadata
          ?.monoclonalizationLotNumber as string,
        expansionLotNumber: well?.containerMetadata
          ?.expansionLotNumber as string,
        geneEditingLotNumber: well?.containerMetadata
          ?.geneEditingLotNumber as string,
        dnaExtractionLotNumber: well?.containerMetadata
          ?.dnaextractionLotNumber as string,
        externalLotNumber: well?.containerMetadata?.externalLotNumber as string,
        otherLotNumber: well?.containerMetadata?.otherLotNumber as string,
        layout: layout,
        layoutCols: cols,
        empty: false,
        isActive: well.containerMetadata?.isActive ?? false,
      };

      if (wellObj.lastDataPointSummary) {
        lastDataPointType = wellObj.lastDataPointSummary.slice(0, 4);
      }

      wells[r][c] = wellObj;

      overlayFieldsArray.forEach((field, idx) =>
        wells[r][c][field]
          ? distinctFieldVals[idx].add(wells[r][c][field] + "")
          : null
      );
    }
  });

  for (let w = 0; w < rows * cols; w++) {
    const c = w % cols;
    const r = Math.floor((w - c) / cols);
    if (!wells[r]) {
      wells[r] = [];
    }
    if (!wells[r][c]) {
      wells[r][c] = {
        RowPos: r + 1,
        ColPos: c + 1,
        RowCol: "",
        containerId: null,
        layout: layout,
        layoutCols: cols,
        empty: true,
        isActive: false,
        barcode: null,
        sampleBarcode: null,
        sampleBarcodeAliases: null,
        passageNumber: null,
        lotNumber: null,
        lastDataPointValue: null,
        lastDataPointSummary: null,
        markingSummary: null,
        contaminatedMarking: null,
        differentiatedMarking: null,
        noCellsMarking: null,
        clonalityMarking: null,
        qcPassFailMarking: null,
        colonyMarking: null,
        sangerSeqMarking: null,
        conReviewMarking: null,
        nyscfid: null,
        collaboratorId: null,
        sourceCellLine: null,
        fibroblastLotNumber: null,
        spitLotNumber: null,
        bloodProcessingLotNumber: null,
        reprogrammingLotNumber: null,
        consolidationLotNumber: null,
        monoclonalizationLotNumber: null,
        expansionLotNumber: null,
        geneEditingLotNumber: null,
        dnaExtractionLotNumber: null,
        externalLotNumber: null,
        otherLotNumber: null,
      };
    }
  }

  const colorMapAll: ColorOverlayMapAll = {
    lotNumber: {},
    sampleBarcode: {},
    contaminatedMarking: {},
    differentiatedMarking: {},
    noCellsMarking: {},
    clonalityMarking: {},
    passageNumber: {},
    nyscfid: {},
    collaboratorId: {},
    sourceCellLine: {},
    lastDataPointValue: {},
    qcPassFailMarking: {},
    colonyMarking: {},
    sangerSeqMarking: {},
    conReviewMarking: {},
    fibroblastLotNumber: {},
    spitLotNumber: {},
    bloodProcessingLotNumber: {},
    reprogrammingLotNumber: {},
    consolidationLotNumber: {},
    monoclonalizationLotNumber: {},
    expansionLotNumber: {},
    geneEditingLotNumber: {},
    dnaExtractionLotNumber: {},
    externalLotNumber: {},
    otherLotNumber: {},
  };

  overlayFieldsArray.forEach((field, idx) =>
    distinctFieldVals[idx].size > 0
      ? (colorMapAll[field] =
          field === "lastDataPointValue"
            ? getGradientColors(distinctFieldVals[idx], lastDataPointType)
            : getColors(distinctFieldVals[idx]))
      : null
  );

  return [wells, colorMapAll, notes];
};

export const getWellAsString = (
  well: IWell | undefined,
  table = false
): string => {
  if (table) {
    console.log("table row", well);
  }
  const wellAsString = well
    ? `${well.sampleBarcode}${well.barcode}${well.lotNumber}${well.markingSummary}Row${well.RowPos}Col${well.ColPos}
  `
    : "";
  return wellAsString;
};
