import {
  RunTaskDetailInput,
  Scalars,
  TaskPriorityEnum,
  TaskStatusEnum,
  TaskTypeDetailInput,
  RunTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class RunActivityInputStub implements RunTaskInput {
  activityName: Scalars["String"];
  completedBy: Maybe<Scalars["Int"]>;
  completedByTime: Maybe<Scalars["DateTime"]>;
  endTimeActual: Maybe<Scalars["DateTime"]>;
  endTimeScheduled: Maybe<Scalars["DateTime"]>;
  lastTimeUpdated: Maybe<Scalars["DateTime"]>;
  taskTypeDetails: Maybe<TaskTypeDetailInput>;
  methodReservationPlateMetadata: [];
  notes: Maybe<Scalars["String"]>;
  parentSubTaskId: Maybe<Scalars["Int"]>;
  priority: Maybe<TaskPriorityEnum>;
  details: Maybe<RunTaskDetailInput>;
  runActivityTypeId: Maybe<Scalars["Int"]>;
  runId: Maybe<Scalars["Int"]>;
  runStageId: Scalars["Int"];
  runTemplateTaskId: Maybe<Scalars["Int"]>;
  seqNo: Maybe<Scalars["Int"]>;
  sourcePlateFilter: Maybe<Scalars["String"]>;
  startTimeActual: Maybe<Scalars["DateTime"]>;
  startTimeScheduled: Maybe<Scalars["DateTime"]>;
  status: Maybe<TaskStatusEnum>;
  systemAssignedTo: Scalars["Int"];
  updateActionDetails: Maybe<Scalars["String"]>;
  updatedBy: Maybe<Scalars["Int"]>;
  runTaskAssignedGroups: Maybe<Array<Scalars["Int"]>>;
  runTaskAssignedUsers: Maybe<Array<Scalars["Int"]>>;
}
