import {
  RunTaskDetailInput,
  ImageExportFormat,
  Scalars,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class RunTaskDetailInputStub implements RunTaskDetailInput {
  createMystplate: Maybe<Scalars["Boolean"]>;
  daughterWellNumber: Maybe<Scalars["Int"]>;
  destPlateCode: Maybe<Scalars["String"]>;
  freezeCellCount: Maybe<Scalars["Int"]>;
  freezeDownRackType: Maybe<Scalars["String"]>;
  imageExportFormat: Maybe<ImageExportFormat>;
  imageExportSetting: Maybe<Scalars["Int"]>;
  maxCryovial: Maybe<Scalars["Int"]>;
  minCryovial: Maybe<Scalars["Int"]>;
  overflowVials: Maybe<Scalars["String"]>;
  seedCellCount: Maybe<Scalars["Int"]>;
  parentId: Scalars["Int"];
  runTaskDetailId: Scalars["Int"];
  worklistTemplateId: Maybe<Scalars["Int"]>;
  intermediateLabwareTypeId?: Maybe<Scalars["Int"]>;
}
