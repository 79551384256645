import {
  Maybe,
  Scalars,
  TaskStatusEnum,
  UpdateRunInput,
} from "graphql/generated/schema-types";

export class RunInputStub implements UpdateRunInput {
  runName?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  runOwner?: Maybe<Scalars["Int"]>;
  status?: Maybe<TaskStatusEnum>;
  runDateScheduled?: Maybe<Scalars["DateTime"]>;
}
