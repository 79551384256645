import { useGetUnavailablePlatesQuery } from "graphql/generated/schema-types";
import { useEffect } from "react";

export const useGetUnavailablePlates = (methodId: number): string[] => {
  const { data, stopPolling, startPolling } = useGetUnavailablePlatesQuery({
    variables: {
      methodId: methodId,
    },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    startPolling(10000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);
  return (data?.unavailablePlates ?? []) as string[];
};
