import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  AppSuiteGroup,
  ManagePersonalTask,
  useGetGroupsQuery,
} from "graphql/generated/schema-types";
import { useGetGroupsById } from "hooks/cacheAccessHooks";
import React, { ChangeEvent, useState } from "react";

interface AssignToGroupsProps {
  isOpen: boolean;
  handleClose: (assignedGroups: number[], assignGroup: boolean) => void;
  selectedTask: ManagePersonalTask;
}

export const AssignToGroupsDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: AssignToGroupsProps) => {
  const [selectedGroups, setSelectedGroups] = useState<AppSuiteGroup[]>([]);
  const assignedGroups = useGetGroupsById(selectedTask!.assignedGroups);
  const groupsQuery = useGetGroupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const handleSubmit = () => {
    const groupIds = selectedGroups.map((group) => {
      return group!.groupId!;
    });
    handleClose(groupIds, true);
  };

  const clearSelectedGroups = () => {
    setSelectedGroups([]);
    handleClose([], false);
  };
  return (
    <>
      <Dialog open={isOpen} onClose={() => clearSelectedGroups()}>
        <DialogTitle>Groups Assigned To Group(s)</DialogTitle>
        <DialogContent>
          <ul>
            <li>{selectedTask.taskName}</li>
          </ul>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={
              groupsQuery.data?.appSuiteGroups?.map((node) => node) ?? []
            }
            getOptionLabel={(option) => option!.groupName!}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              setSelectedGroups(newValue)
            }
            // getOptionSelected={(selected, option) =>
            //   !!(
            //     selected?.groupId &&
            //     option?.groupId &&
            //     selected?.groupId === option?.groupId
            //   )
            // }
            value={selectedGroups.length > 0 ? selectedGroups : assignedGroups!}
          />
          {/* <Autocomplete
            id="userAssignedTo"
            disableClearable
            options={groupsQuery.data?.appSuiteGroups ?? []}
            getOptionLabel={(option) => option!.groupName!}
            style={{ margin: "0px 0", width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Group Assigned To"
                variant="outlined"
              />
            )}
            // value={
            //   values.systemAssignedTo ? values.systemAssignedTo : undefined
            // }
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              setSelectedGroup(newValue)
            }
          /> */}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
