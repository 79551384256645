import { makeVar } from "@apollo/client";
import { MethodManagerModals } from "../components/ModelsAndDialogs/model.enum";
import { MethodManagerActions } from "./actions";

export enum MethodManagerScreen {
  MethodScreen,
  ManualTaskTypeScreen,
}

export interface IMethodManagerState {
  modalOpen?: boolean;
  currentModal?: MethodManagerModals;
  activeScreen?: MethodManagerScreen;
  methodSummaryDialogOpen?: {
    open?: boolean;
    methodId?: number;
  };
  selectedMethods?: number[];
  selectedManualTaskTypes?: number[];
}

export interface IMethodManagerAction {
  type: MethodManagerActions;
  payload?: IMethodManagerState;
}

export const MethodManagerInitialState: IMethodManagerState = {
  modalOpen: false,
  activeScreen: MethodManagerScreen.MethodScreen,
};

export const MethodManagerState = makeVar<IMethodManagerState>(
  MethodManagerInitialState
);
