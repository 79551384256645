import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";
import { IncomingSampleActions, useIncomingSampleState } from "../../state";
import { IncomingSampleModalContent } from "./sub-components/IncomingSampleModalContent";

export function IncomingSampleModalsAndDialogs(): JSX.Element {
  const {
    incomingSampleState: { modalOpen },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  return (
    <AppModal
      open={modalOpen}
      onClose={() =>
        incomingSampleDispatch({
          type: IncomingSampleActions.CLOSE_MODAL,
        })
      }
    >
      <IncomingSampleModalContent />
    </AppModal>
  );
}
