import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DashboardTask } from "graphql/generated/schema-types";
import { useEffect, useState } from "react";
import RunsService from "services/RunsService";
import { SystemOnePassage } from "./SystemOnePassage";

interface SystemOnePassageContainerProps {
  selectedTask: DashboardTask;
}

interface ActivePlates {
  plateCreatedID: number;
  plateBarcode: string;
  plateBarcodeAlias: string;
  samples: string;
  methodReservationID: number;
}

export const SystemOnePassageContainer = ({
  selectedTask,
}: SystemOnePassageContainerProps) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [activePlates, setActivePlates] = useState<ActivePlates[]>([]);
  useEffect(() => {
    RunsService.getActivePlatesForRunActivity(selectedTask.taskId).then(
      (res) => {
        console.log(res.data);
        setActivePlates(res.data);
      }
    );
  }, [selectedTask]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: collapseOpen ? "0px" : "25px",
        }}
      >
        <IconButton
          style={{ outline: 0 }}
          aria-label="expand row"
          size="small"
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          {collapseOpen ? (
            <KeyboardArrowDownIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </IconButton>
        <Typography style={{ position: "relative", top: "3px" }}>
          Active Plates
        </Typography>
      </div>
      <Collapse in={collapseOpen} unmountOnExit>
        {activePlates.length > 0 ? (
          <Table size="small" style={{ margin: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell>PlateBarcode</TableCell>
                <TableCell>Sample</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activePlates.map((item) => (
                <TableRow>
                  <TableCell>{item.plateBarcode}</TableCell>
                  <TableCell>{item.samples}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : null}
      </Collapse>
      <SystemOnePassage
        selectedTask={selectedTask}
        activePlates={activePlates}
      />
    </div>
  );
};
