import { List, ListItemText } from "@mui/material";
import { Assignment, Collections } from "@mui/icons-material";
import React from "react";
import {
  StyledListItem,
  StyledListItemIcon,
  StyledMenu,
} from "shared-components/styled-components";
import { MethodManagerActions } from "../state/actions";
import { useMethodManagerState } from "../state/hooks";
import { MethodManagerScreen } from "../state/initial-state";

export function SideBar(): JSX.Element {
  const {
    methodManagerDispatch,
    methodManagerState: { activeScreen },
  } = useMethodManagerState();
  return (
    <StyledMenu>
      <List>
        <StyledListItem
          active={activeScreen === MethodManagerScreen.MethodScreen}
          onClick={() =>
            methodManagerDispatch({
              type: MethodManagerActions.SELECT_SCREEN,
              payload: { activeScreen: MethodManagerScreen.MethodScreen },
            })
          }
        >
          <StyledListItemIcon
            active={activeScreen === MethodManagerScreen.MethodScreen}
          >
            <Assignment />
          </StyledListItemIcon>
          <ListItemText primary={"Methods"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === MethodManagerScreen.ManualTaskTypeScreen}
          onClick={() =>
            methodManagerDispatch({
              type: MethodManagerActions.SELECT_SCREEN,
              payload: {
                activeScreen: MethodManagerScreen.ManualTaskTypeScreen,
              },
            })
          }
        >
          <StyledListItemIcon
            active={activeScreen === MethodManagerScreen.ManualTaskTypeScreen}
          >
            <Collections />
          </StyledListItemIcon>
          <ListItemText primary={"Manual Tasks"} />
        </StyledListItem>
      </List>
    </StyledMenu>
  );
}
