import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MethodSettingsProps } from "../shared";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export const HarvestWashReagent = ({
  poolingInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const classes = useStyles();

  const hasError =
    poolingInternalState.methodSettings.washAfterDissociation &&
    poolingInternalState.methodSettings.harvestWashRGT === 0;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        sx={{ width: "100%" }}
        classes={{
          label: classes.fullWidth,
        }}
        control={
          <Checkbox
            checked={poolingInternalState.methodSettings.washAfterDissociation}
            onChange={(e) =>
              handleMethodSettingsChanged(
                "washAfterDissociation",
                e.target.checked
              )
            }
            name="checkedB"
            color="primary"
            size="small"
          />
        }
        label={
          <FormControl variant="outlined" fullWidth error={hasError}>
            <InputLabel id="harvest-wash-label">
              Harvest Wash Reagent
            </InputLabel>
            <Select
              labelId="harvest-wash-label"
              id="harvest-wash-select"
              disabled={
                !poolingInternalState.methodSettings.washAfterDissociation
              }
              value={poolingInternalState.methodSettings.harvestWashRGT}
              label="Harvest Wash Reagent"
              size="small"
              onChange={(e) =>
                handleMethodSettingsChanged("harvestWashRGT", e.target.value)
              }
            >
              <MenuItem value={0}></MenuItem>
              <MenuItem value={1}>Media</MenuItem>
              <MenuItem value={2}>DissociationRGT</MenuItem>
              <MenuItem value={3}>PBS</MenuItem>
            </Select>
            <FormHelperText>
              {hasError ? "Reagent must be selected" : ""}
            </FormHelperText>
          </FormControl>
        }
      />

      <Tooltip
        title="Wash After Dissociation - harvested source wells will be rinsed with 
              selected reagent after dissociated cells have completed transfer to int. Dissociation 
              reagent will not be neutralized until after wash of source well is complete!"
      >
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
