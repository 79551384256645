import { Tooltip, Typography } from "@mui/material";
import React from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { PlatePosition, WorklistTools } from "../interfaces";
import PlateWell from "./PlateWell";

const StyledDestinationPlate = styled.div<{
  borderColor: string | undefined;
}>`
  height: 100%;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0 4px -1px black;
  // background-color: white;
  border-radius: 5px;
  border: 1px solid ${(props) => props.borderColor ?? "#ded7e9"};
`;

interface DestinationPlateProps {
  worklistTool: WorklistTools;
  index: number;
  plateRows: number[];
  plateCols: number[];
  sourcePlateInfo: any[];
  wellMapping: any[];
  borderColor?: string;
  sourcePlatePosition: PlatePosition;
}
const StyledDestPlateElements = styled.div<{
  cols: number[];
  rows: number[];
}>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 4px 4px;
  grid-template-columns: repeat(${(props) => props.cols.length + 1}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows.length + 1}, 1fr);
  justify-items: center;
  align-items: center;
`;

const PlateHeaderCell = styled(Typography)<{
  rowStart: number;
  colStart: number;
}>`
  display: flex;
  grid-row-start: ${(props) => `${props.rowStart}`};
  grid-column-start: ${(props) => `${props.colStart}`};
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 5px;
`;

export function DestinationPlate({
  worklistTool,
  index,
  plateRows,
  plateCols,
  sourcePlateInfo,
  wellMapping,
  borderColor,
  sourcePlatePosition,
}: DestinationPlateProps) {
  const getWellColor = (plateIndex: number, row: number, col: number) => {
    const rows = wellMapping.filter(
      (e) =>
        parseInt(e.destPlateIndex) === plateIndex &&
        e.destWellId === `${String.fromCharCode(65 + row - 1)}${col}`
    );
    if (rows.length > 1) {
      return `multi-color ${getMultiColorWell(rows)}`;
    }

    const rowIndex = wellMapping.findIndex(
      (e) =>
        parseInt(e.destPlateIndex) === plateIndex &&
        e.destWellId === `${String.fromCharCode(65 + row - 1)}${col}`
    );

    if (rowIndex > -1) {
      const sourceWell = document.getElementById(
        `${sourcePlatePosition}${wellMapping[rowIndex].sourcePlateIndex}${wellMapping[rowIndex].sourceWellId}`
      );
      if (sourceWell) {
        const wellStyle = sourceWell.style;

        if (wellStyle.backgroundColor === "") {
          const wellColor = `multi-color ${getBackgroundImageColors(
            wellStyle.backgroundImage.toString()
          )}`;

          return wellColor;
        }

        return wellStyle.backgroundColor;
      }
    }
    return "";
  };

  const getMultiColorWell = (rows: any[]) => {
    const colors: string[] = [];
    for (const element of rows) {
      const wellStyle = document.getElementById(
        `${sourcePlatePosition}${element.sourcePlateIndex}${element.sourceWellId}`
      )!.style;

      if (wellStyle.backgroundColor === "") {
        const wellColor = getBackgroundImageColors(
          wellStyle.backgroundImage.toString()
        );

        const split = wellColor.match(/(rgb[(]\d{1,3}, \d{1,3}, \d{1,3}[)])/g);
        if (split == null) return;
        for (const val of split) {
          colors.push(val);
        }
      } else {
        const color = document.getElementById(
          `${sourcePlatePosition}${element.sourcePlateIndex}${element.sourceWellId}`
        )!.style.backgroundColor;
        colors.push(color);
      }
    }
    return colors.join(", ");
  };

  const getBackgroundImageColors = (backgroundImageColors: string) => {
    const removedLinearGradient = backgroundImageColors.substring(
      16,
      backgroundImageColors.length - 1
    );
    return removedLinearGradient;
  };

  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    menuId: string
  ) => {
    e.preventDefault();
    contextMenu.show({
      id: menuId,
      event: e,
    });
  };

  const getSourcePositionForTooltip = (
    plateIndex: number,
    row: number,
    col: number
  ) => {
    const index = wellMapping.findIndex(
      (e) =>
        parseInt(e.destPlateIndex) === plateIndex &&
        e.destWellId === `${String.fromCharCode(65 + row - 1)}${col}`
    );
    let sourceWellIndex = -1;
    if (index > -1) {
      sourceWellIndex = sourcePlateInfo[
        wellMapping[index].sourcePlateIndex
      ].wellInfo.findIndex(
        (e: any) =>
          `${String.fromCharCode(64 + e.rowPos)}${e.colPos}` ===
          wellMapping[index].sourceWellId
      );
    }
    const stampCount = wellMapping.filter(
      (e: any) =>
        e.destPlateIndex === plateIndex &&
        e.destWellId === `${String.fromCharCode(65 + row - 1)}${col}`
    ).length;
    if (index >= 0) {
      return (
        <React.Fragment>
          Source Plate: <b>{wellMapping[index].sourcePlateIndex + 1}</b> <br />{" "}
          Source Pos: <b>{wellMapping[index].sourceWellId}</b> <br /> Sample
          Barcode:{" "}
          <b>
            {sourceWellIndex > -1
              ? sourcePlateInfo[wellMapping[index].sourcePlateIndex].wellInfo[
                  sourceWellIndex
                ].sampleBarcode
              : ""}
          </b>
          <br />
          {stampCount > 1 ? (
            <React.Fragment>
              Stamp Count: <b>{stampCount}</b>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      );
    }
    return "";
  };

  return (
    <>
      <StyledDestinationPlate
        onContextMenu={(e) => {
          handleContextMenu(e, `${worklistTool}_Destination_${index}`);
        }}
        borderColor={borderColor}
      >
        <StyledDestPlateElements rows={plateRows} cols={plateCols}>
          <PlateHeaderCell rowStart={1} colStart={1}></PlateHeaderCell>
          {plateRows.map((row, index) => (
            <PlateHeaderCell rowStart={row + 1} colStart={1}>
              <p
                style={{
                  fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
                }}
              >
                {String.fromCharCode(64 + row)}
              </p>
            </PlateHeaderCell>
          ))}
          {plateCols.map((col, index) => (
            <PlateHeaderCell rowStart={1} colStart={col + 1}>
              <p
                style={{
                  fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
                }}
              >
                {index + 1}
              </p>
            </PlateHeaderCell>
          ))}
          {plateRows.map((rowItem, rowIndex) =>
            plateCols.map((colItem, colIndex) => (
              <Tooltip
                title={getSourcePositionForTooltip(
                  index,
                  rowIndex + 1,
                  colIndex + 1
                )}
                placement="top"
              >
                <div style={{ width: "100%", height: "100%" }}>
                  <PlateWell
                    plateIndex={index}
                    platePosition={PlatePosition.Destination}
                    plateWellId={
                      String.fromCharCode(65 + rowIndex) + String(colIndex + 1)
                    }
                    wellColor={getWellColor(index, rowIndex + 1, colIndex + 1)}
                  ></PlateWell>
                </div>
              </Tooltip>
            ))
          )}
        </StyledDestPlateElements>
      </StyledDestinationPlate>
    </>
  );
}
