import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ReactNode, useState } from "react";

interface AccordionProps {
  id: string;
  panel: string;
  title: string;
  children: ReactNode;
}

export const AccordionPanel = ({
  id,
  panel,
  title,
  children,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState("method-settings");
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={id}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
