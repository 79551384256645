export enum Twelve24TCToolInternalAction {
  SET_STATE,
  SET_DEST_SELECTION_PROCESS,
  SET_SELECTED_SOURCE_WELLS,
  SET_HOVER_WELL_ID,
  SET_SOURCE_INT_INDEX,
  SET_DESTINATION_INT_INDEX,
  SET_SELECTED_DESTINATION_INDEX,
  SET_REQUIRED_WARNINGS,
  SET_OPTIONAL_WARNINGS,
  SET_WORKLIST,
  SET_WORKLIST_PREVIEW_OPEN,
  UPDATE_METHOD_SETTINGS,
  UPDATE_STAMP_ROWCOLVOL,
  UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
}

export interface ITwelve24TCToolInternalAction {
  payload: any;
  type: Twelve24TCToolInternalAction;
}
