import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  IPersonalTaskTemplateState,
  PersonalTaskTemplateState,
} from "./inital-state";
import { PersonalTaskTemplateReducer } from "./reducer";

export function usePersonalTaskTemplateStateProvider(): {
  personalTaskTemplateState: IPersonalTaskTemplateState;
  personalTaskTemplateDispatch: IApolloDispatch<any>;
} {
  const personalTaskTemplateState = useReactiveVar(PersonalTaskTemplateState);
  const personalTaskTemplateDispatch = useMemo(
    () =>
      ApolloStateDispatch(
        PersonalTaskTemplateState,
        PersonalTaskTemplateReducer
      ),
    []
  );
  return {
    personalTaskTemplateState,
    personalTaskTemplateDispatch,
  };
}
