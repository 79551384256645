import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import { Form, Formik, FormikProps } from "formik";
import {
  DashboardTask,
  PersonalTaskAttachment,
  RunTaskAttachment,
  useGetPersonalTaskAttachmentsQuery,
  useGetRunTaskAttachmentsQuery,
} from "graphql/generated/schema-types";
import React, { useEffect, useState } from "react";
import { TaskAttachments } from "./TaskAttachments";

interface EditTaskDialogProp {
  isOpen: boolean;
  handleClose: (selectedTask: DashboardTask, editTask: boolean) => void;
  selectedTask: DashboardTask;
}

export const EditTaskDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: EditTaskDialogProp) => {
  const [showAttachments, setShowAttachments] = useState(false);
  const {
    data: personalTaskAttachments,
    startPolling: pStartPolling,
    stopPolling: pStopPolling,
  } = useGetPersonalTaskAttachmentsQuery({
    variables: {
      taskId: selectedTask.taskId,
    },
  });
  const {
    data: runTaskAttachments,
    startPolling: rStartPolling,
    stopPolling: rStopPolling,
  } = useGetRunTaskAttachmentsQuery({
    variables: {
      taskId: selectedTask.taskId,
    },
  });
  useEffect(() => {
    pStartPolling(2000);
    rStartPolling(2000);
    return () => {
      pStopPolling();
      rStopPolling();
    };
  }, [rStartPolling, pStartPolling, pStopPolling, rStopPolling]);

  useEffect(() => {
    if (!isOpen) {
      pStopPolling();
      rStopPolling();
    }
  }, [pStopPolling, rStopPolling, isOpen]);

  const transition = useTransition(showAttachments, {
    from: {
      maxWidth: "0vw",
      maxHeight: "20vh",
    },
    enter: {
      maxWidth: "60vw",
      maxHeight: "50vh",
    },
    leave: {
      maxWidth: "0vw",
      maxHeight: "0vh",
      overflow: "hidden",
    },
  });

  const handleSubmit = (values: any) => {
    handleClose(values, true);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(selectedTask, false)}
        maxWidth={"xl"}
      >
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <Formik initialValues={selectedTask} onSubmit={handleSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }: FormikProps<DashboardTask>) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  name="taskName"
                  style={{ margin: "10px 0" }}
                  label="Task Name"
                  variant="outlined"
                  value={
                    values!.taskName!.substring(
                      values!.taskName!.length - 7,
                      values!.taskName!.length
                    ) === "*Notes*"
                      ? values.taskName?.substring(
                          0,
                          values.taskName!.length - 7
                        )
                      : values.taskName
                  }
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  name="notes"
                  style={{ margin: "10px 0 0 0" }}
                  label="Notes"
                  defaultValue={values.notes}
                  multiline
                  rows={5}
                  variant="outlined"
                  onChange={handleChange}
                />
                <Box mt={2}>
                  <Typography color={"inherit"}>
                    <Button
                      onClick={() => setShowAttachments((current) => !current)}
                    >
                      {showAttachments ? "Hide" : "View"} Attachments
                    </Button>
                  </Typography>
                </Box>
                {transition(
                  (props, item) =>
                    item && (
                      <animated.div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          ...props,
                        }}
                      >
                        <TaskAttachments
                          runTaskAttachments={
                            runTaskAttachments?.runTaskAttachments as RunTaskAttachment[]
                          }
                          personalTaskAttachments={
                            personalTaskAttachments?.personalTaskAttachments as PersonalTaskAttachment[]
                          }
                          taskId={selectedTask.taskId}
                          taskType={selectedTask.taskType}
                        />
                      </animated.div>
                    )
                )}
                <Button variant="contained" color="primary" type="submit">
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>

          {/* {selectedTask.methodId === 63 ? (
            <StyledMethodVariablesContainer>
              <TextField
                name="daughterWellNumber"
                value={passageSettings.daughterWellNumber}
                onChange={handlePassageSettingsChange}
                type="number"
                variant="outlined"
                label="Daughter Wells"
              />
              <TextField
                name="seedCellCount"
                value={passageSettings.seedCellCount}
                onChange={handlePassageSettingsChange}
                type="number"
                variant="outlined"
                label="Seeding Cell Count"
              />
              <TextField
                name="freezeCellCount"
                value={passageSettings.freezeCellCount}
                onChange={handlePassageSettingsChange}
                type="number"
                variant="outlined"
                label="Freezing Cell Count"
              />
              <TextField
                name="maxCryovial"
                value={passageSettings.maxCryovial}
                onChange={handlePassageSettingsChange}
                type="number"
                variant="outlined"
                label="Max Cryovial"
              />
              <TextField
                name="minCryovial"
                value={passageSettings.minCryovial}
                onChange={handlePassageSettingsChange}
                type="number"
                variant="outlined"
                label="Min Cryovial"
              />
              <Autocomplete
                id="overflowVials"
                options={overflow}
                isOptionEqualToValue={(option, value) =>
                  option === value || value === ""
                }
                getOptionLabel={(option) => option}
                value={passageSettings.overflowVials}
                disableClearable
                onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                  setAutoFieldValue("overflowVials", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Overflow Vials"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                id="destPlateCode"
                options={destPlateCodes}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) =>
                  option === value || value === ""
                }
                value={passageSettings.destPlateCode}
                disableClearable
                onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                  setAutoFieldValue("destPlateCode", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Dest Plate Code"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                id="freezeDownRackType"
                options={freezeDownRacks}
                getOptionLabel={(option) => option}
                isOptionEqualToValue={(option, value) =>
                  option === value || value === ""
                }
                value={passageSettings.freezeDownRackType}
                disableClearable
                onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                  setAutoFieldValue("freezeDownRackType", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Freeze-Down Rack"
                    variant="outlined"
                  />
                )}
              />
              <StyledCreateMYSTPlate
                control={<Checkbox name="Create MYST Plate" />}
                label="Create MYST Plate"
              />
            </StyledMethodVariablesContainer>
          ) : (
            <div />
          )} */}
        </DialogContent>
        {/* <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Save Changes
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
