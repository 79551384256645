import {
  PlatePosition,
  SelectionProcess,
  SelectionType,
  WellSelection,
} from "features/WorklistTools/shared/interfaces";
import {
  applyRandomizeWells,
  arrangeColWise,
  arrangeRowWise,
  checkForDoubleSelectionWithMultipleDestSelection,
  getWellSelectionType,
  is96WellPlate,
  randomizeSelectedWells,
  stampSourceToDestination,
} from "features/WorklistTools/shared/WorklistHelpers";
import { Alert, AlertType } from "shared-components/toast";
import {
  DestPlateInfoState,
  SourcePlateInfoState,
  ThawToolActions,
} from "../../state";
import { IThawToolInternalState } from "../../state/initial-state";
import { isA1Indexed } from "features/WorklistTools/shared/PlateHelpers";

export const selectDestWells = async (
  thawToolState: IThawToolInternalState,
  sourcePlateInfo: SourcePlateInfoState,
  destPlateInfo: DestPlateInfoState[],
  worklistValues: any,
  dispatch: (action: { type: string; payload: any }) => void,
  destSelection: WellSelection[],
  setStampVolOpen: (value: React.SetStateAction<boolean>) => void
) => {
  destSelection = destSelection.map((well: any) => ({
    plateIndex: well.props.plateIndex,
    plateWellId: well.props.plateWellId,
    isSelectable: well.node.classList.contains("not-selectable") ? false : true,
  }));
  if (destSelection.length === 0) return;
  if (
    is96WellPlate(destPlateInfo[destSelection[0].plateIndex].labwareTypeCode) &&
    thawToolState.selectedSourceWells.filter(
      (well) => well.isSelectable === true
    ).length !== sourcePlateInfo.wellInfo.length
  ) {
    Alert({
      type: AlertType.ERROR,
      message: "Please select all active source wells",
    });

    return;
  }
  if (
    is96WellPlate(destPlateInfo[destSelection[0].plateIndex].labwareTypeCode) &&
    !isA1Indexed(sourcePlateInfo.wellInfo)
  ) {
    Alert({
      type: AlertType.ERROR,
      message: "Source plate must be indexed at A1",
    });
    return;
  }
  let srcToDestWellMapping: any[] = [];
  let randomizedWells = [
    ...thawToolState.selectedSourceWells.filter((e) => e.isSelectable === true),
  ];

  const selectionType = getWellSelectionType(
    thawToolState.selectedSourceWells,
    destSelection
  );

  if (thawToolState.randomizeWells) {
    randomizedWells = await randomizeSelectedWells(randomizedWells);
  }

  switch (selectionType) {
    case SelectionType.NoSourceWellSelected:
      Alert({
        type: AlertType.ERROR,
        message: "Please select a source well(s)",
      });
      break;
    case SelectionType.SelectMultipleSourceAndDestWells:
      Alert({
        type: AlertType.ERROR,
        message: "You can only select 1 Destination Well",
      });
      break;
    case SelectionType.SelectMultipleSourceAndOneDestWell:
      switch (thawToolState.destSelectionProcess) {
        case SelectionProcess.Stamp:
          srcToDestWellMapping = stampSourceToDestination(
            worklistValues,
            thawToolState.selectedSourceWells,
            destSelection[0],
            PlatePosition.Destination,
            false
          );
          if (srcToDestWellMapping.length) {
            if (
              is96WellPlate(
                destPlateInfo[destSelection[0].plateIndex].labwareTypeCode
              )
            ) {
              if (thawToolState.methodSettings.resuspensionVol > 900) {
                Alert({
                  type: AlertType.ERROR,
                  message:
                    "Cannot stamp unless cumulative total stamp vol < 900",
                });
              } else {
                setStampVolOpen(true);
                dispatch(
                  ThawToolActions.ADD_THAW_STAMP_AND_MAPPING({
                    selections: srcToDestWellMapping,
                    plateIndex: destSelection[0].plateIndex,
                    plateWellId: destSelection[0].plateWellId,
                  })
                );
              }
            } else {
              dispatch(
                ThawToolActions.WORKLIST_MAPPING_ADDED({
                  selections: thawToolState.randomizeWells
                    ? applyRandomizeWells(srcToDestWellMapping, randomizedWells)
                    : srcToDestWellMapping,
                })
              );
            }
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
        case SelectionProcess.RowWise:
          srcToDestWellMapping = await arrangeRowWise(
            worklistValues,
            randomizedWells ?? thawToolState.selectedSourceWells,
            destSelection[0],
            destPlateInfo[destSelection[0].plateIndex].rows,
            destPlateInfo[destSelection[0].plateIndex].cols,
            PlatePosition.Destination,
            false
          );
          if (srcToDestWellMapping.length) {
            dispatch(
              ThawToolActions.WORKLIST_MAPPING_ADDED({
                selections: thawToolState.randomizeWells
                  ? applyRandomizeWells(srcToDestWellMapping, randomizedWells)
                  : srcToDestWellMapping,
              })
            );
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
        case SelectionProcess.ColWise:
          srcToDestWellMapping = await arrangeColWise(
            worklistValues,
            randomizedWells ?? thawToolState.selectedSourceWells,
            destSelection[0],
            destPlateInfo[destSelection[0].plateIndex].rows,
            destPlateInfo[destSelection[0].plateIndex].cols,
            PlatePosition.Destination,
            false
          );
          if (srcToDestWellMapping.length) {
            dispatch(
              ThawToolActions.WORKLIST_MAPPING_ADDED({
                selections: thawToolState.randomizeWells
                  ? applyRandomizeWells(srcToDestWellMapping, randomizedWells)
                  : srcToDestWellMapping,
                plateIndex: destSelection[0].plateIndex,
                plateWellId: destSelection[0].plateWellId,
              })
            );
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
        default:
          Alert({
            type: AlertType.ERROR,
            message: "Please Select a destination process from the side bar",
          });
          break;
      }
      break;
    case SelectionType.SelectOneSourceAndOneDestWell:
      const error = checkForDoubleSelectionWithMultipleDestSelection(
        worklistValues,
        destSelection
      );
      if (!error) {
        srcToDestWellMapping.push({
          sourcePlateIndex: thawToolState.selectedSourceWells[0].plateIndex,
          sourceWellId: thawToolState.selectedSourceWells[0].plateWellId,
          destPlateIndex: destSelection[0].plateIndex,
          destWellId: destSelection[0].plateWellId,
          transferVol: "",
        });
        dispatch(
          ThawToolActions.WORKLIST_MAPPING_ADDED({
            selections: srcToDestWellMapping,
          })
        );
      } else {
        Alert({
          type: AlertType.ERROR,
          message: "Well has already been selected",
        });
      }
      break;
    case SelectionType.SelectOneSourceAndMultipleDestWells:
      const errors = checkForDoubleSelectionWithMultipleDestSelection(
        worklistValues,
        destSelection
      );
      if (!errors) {
        for (const row of destSelection) {
          srcToDestWellMapping.push({
            sourcePlateIndex: thawToolState.selectedSourceWells[0].plateIndex,
            sourceWellId: thawToolState.selectedSourceWells[0].plateWellId,
            destPlateIndex: row.plateIndex,
            destWellId: row.plateWellId,
            transferVol: "",
          });
        }
        dispatch(
          ThawToolActions.WORKLIST_MAPPING_ADDED({
            selections: srcToDestWellMapping,
          })
        );
      } else {
        Alert({
          type: AlertType.ERROR,
          message: "Well has already been selected",
        });
      }
      break;
  }
};
