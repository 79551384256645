import { TableRow } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import React from "react";
import { ContainerLineageFragment } from "graphql/generated/schema-types";
import { StyledTableCell } from "shared-components/Table/ReactTable";

export function ContainerLineageRow({
  containerLineage,
}: {
  containerLineage: Maybe<ContainerLineageFragment>;
}): JSX.Element {
  return (
    <TableRow>
      <StyledTableCell>{containerLineage?.sample}</StyledTableCell>
      <StyledTableCell>{containerLineage?.containerId}</StyledTableCell>
      <StyledTableCell>{containerLineage?.hierarchy}</StyledTableCell>
      <StyledTableCell>{containerLineage?.plateBarcode}</StyledTableCell>
      <StyledTableCell>{containerLineage?.rowPos}</StyledTableCell>
      <StyledTableCell>{containerLineage?.colPos}</StyledTableCell>
      <StyledTableCell>{containerLineage?.tubeBarcode}</StyledTableCell>
      <StyledTableCell>{containerLineage?.lotNumber}</StyledTableCell>
      <StyledTableCell>{containerLineage?.isActive}</StyledTableCell>
      <StyledTableCell>{containerLineage?.lN2SAMActivity}</StyledTableCell>
      <StyledTableCell>
        {moment(containerLineage?.dateCreated).format("L")}
      </StyledTableCell>
    </TableRow>
  );
}
