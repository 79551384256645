import {
  PersonalTasksRecurringTask,
  PersonalTasksRecurringTaskFilterInput,
} from "graphql/generated/schema-types";
import { Filters } from "react-table";

export const RepeatingTaskSummaryFilterReducer = (
  tableFilters: Filters<PersonalTasksRecurringTask>
) => {
  const where: PersonalTasksRecurringTaskFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "taskName":
        where!.and!.push({
          taskName: { contains: tableFilter.value },
        });
        break;
      case "repeatingDaysOfWeek":
        where!.and!.push({
          repeatingDaysOfWeek: { contains: tableFilter.value },
        });
        break;
      case "endRepeatDate":
        where!.and!.push({
          endRepeatDate: { eq: tableFilter.value },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
