/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip, Typography } from "@mui/material";
import {
  ColorMap,
  getWellAsString,
  IWell,
} from "features/Plates/components/PlateInfo/helpers";
import { ModalAction, WellAction } from "features/Plates/state/actions";
import {
  useModalStateProvider,
  useWellStateProvider,
} from "features/Plates/state/StateProvider";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import { Abbreviate } from "helpers/string-helpers";
import { GetUserSettings } from "helpers/user-claim-helpers";
import React, { useMemo, useState } from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { wellListFieldsMapping } from "../constants";
import { Plate } from "../helpers";

const WellContainer = styled("div")<{
  rowstart: number;
  colstart: number;
  minheight: number;
  $wellSelected: boolean;
  $empty: boolean;
  bColor: string;
  tColor: string;
  defaultColor: string;
}>`
  display: flex;
  justify-content: center;
  text-align: center;
  border: solid 0.5px ${GetTableCellBorder};
  padding: 1%;
  grid-row-start: ${(props) => `${props.rowstart}`};
  grid-column-start: ${(props) => `${props.colstart}`};
  min-height: ${(props) => `${props.minheight}px`};
  background-color: ${(props) =>
    props.$wellSelected ? `rgba(204, 40, 40, .2)` : props.bColor};
  color: ${(props) =>
    props.$wellSelected ? props.defaultColor : props.tColor};

  &:hover {
    background-color: ${(props) =>
      props.$empty ? `rgba(255, 255, 255, 0)` : `rgba(204, 40, 40, .2)`};
    color: ${({ theme }) =>
      theme.palette.mode === "dark" ? "white" : "black"};
    cursor: ${(props) => (props.$empty ? `default` : `pointer`)};
  }
`;

const WellContent = styled(Typography)`
  overflow: hidden;
`;

export const Well = ({
  well,
  selectedPlate,
}: {
  well: IWell;
  selectedPlate: Plate;
}) => {
  const mode = GetUserSettings()?.theme?.mode ?? "light";

  const {
    wellState: { currentWell, fromPlateMap, currentOverlay, showInactive },
    wellDispatch,
  } = useWellStateProvider();

  const { modalDispatch } = useModalStateProvider();

  const [wellColor, setWellColor] = useState<string>("white");

  let colorMap: ColorMap = {};
  if (currentOverlay && selectedPlate.colorOverlayMap) {
    colorMap = selectedPlate.colorOverlayMap[currentOverlay];
  }

  const val = currentOverlay ? well[currentOverlay] : null;

  const defaultColor = mode === "dark" ? "white" : "black";

  const textColor =
    showInactive && !well.isActive
      ? "rgb(89, 89, 89)"
      : mode === "dark" && !val
      ? defaultColor
      : "black";

  const backgroundColor = (() => {
    if (showInactive && !well.isActive && !well.empty) {
      return "rgba(0, 0, 0, .2)";
    } else if (
      currentOverlay &&
      colorMap &&
      val &&
      val in colorMap &&
      !well.empty
    ) {
      return colorMap[val];
    } else return "rgba(255, 255, 255, 0)";
  })();

  if (backgroundColor !== wellColor) {
    setWellColor(backgroundColor);
  }

  const contents = `${well.sampleBarcode ? well.sampleBarcode + "\n" : ""}`;

  const minHeight =
    well.layout === "384Well"
      ? 4
      : well.layout === "96Well"
      ? 50
      : well.layout === "48Well"
      ? 68
      : 85;

  const handleHover = (well: IWell | undefined, enter: boolean) => () => {
    // console.log("hovering");
    if (!well?.empty) {
      if (enter) {
        wellDispatch({
          type: WellAction.TOGGLE_SELECT_WELL,
          payload: {
            currentWell: well,
            fromPlateMap: true,
          },
        });
      } else
        wellDispatch({
          type: WellAction.TOGGLE_SELECT_WELL,
          payload: {
            currentWell: undefined,
            fromPlateMap: undefined,
          },
        });
    }
  };

  const handleContextMenu = (well: IWell) => (e: React.MouseEvent) => {
    // console.log("menu");
    e.preventDefault();
    modalDispatch({
      type: ModalAction.SELECT_CONTAINER,
      payload: {
        selectedContainer: well.containerId ?? undefined,
      },
    });
    contextMenu.show({
      id: "PlateMapContextMenu",
      event: e,
    });
  };

  const formatWellContents = useMemo(() => {
    // console.log("formatting wellcontents");
    switch (well.layout) {
      case "48Well":
        return (
          <WellContent variant="subtitle1" fontSize="87%">
            {contents}
          </WellContent>
        );
      case "96Well":
        return (
          <WellContent variant="subtitle1" fontSize="68%">
            {Abbreviate(contents, 20)}
          </WellContent>
        );
      case "384Well":
        return (
          <WellContent variant="subtitle1" fontSize="50%">
            {Abbreviate(contents, 12)}
          </WellContent>
        );
      default:
        return (
          <WellContent variant="subtitle1" fontSize="100%">
            {contents}
          </WellContent>
        );
    }
  }, [selectedPlate]);

  const makeWell = useMemo(() => {
    // if (currentWell === undefined) {
    //   console.log(
    //     "initial rendering of well",
    //     `row${well.RowPos} col${well.ColPos}`
    //   );
    // } else {
    //   console.log(
    //     "rerender of well",
    //     `row${well.RowPos} col${well.ColPos}`,
    //     `chosen well is row${currentWell?.RowPos} col${currentWell?.ColPos}`
    //   );
    // }

    // console.log("rendering well", well);

    return (
      <Tooltip
        placement="top"
        title={
          currentOverlay &&
          !well.empty &&
          backgroundColor !== "rgba(255, 255, 255, 0)" ? (
            <Typography fontSize="120%">
              {`${wellListFieldsMapping[currentOverlay]}: ${val}`}
            </Typography>
          ) : (
            ""
          )
        }
      >
        <WellContainer
          onMouseEnter={handleHover(well, true)}
          onMouseLeave={handleHover(well, false)}
          onContextMenu={handleContextMenu(well)}
          rowstart={well.RowPos + 1}
          colstart={well.ColPos + 1}
          minheight={minHeight}
          bColor={backgroundColor}
          tColor={textColor}
          defaultColor={defaultColor}
          $wellSelected={
            currentWell !== undefined &&
            getWellAsString(currentWell) === getWellAsString(well)
          }
          $empty={well.empty}
        >
          {formatWellContents}
        </WellContainer>
      </Tooltip>
    );
  }, [
    selectedPlate,
    currentWell !== undefined &&
      getWellAsString(currentWell) === getWellAsString(well) &&
      !fromPlateMap,
    wellColor,
  ]);

  return <React.Fragment>{makeWell}</React.Fragment>;
};
