import { Autocomplete, TextField } from "@mui/material";
import { useGetPlatesForManualSelectorQuery } from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import { AddRackOptions } from "../../constants";
interface PlateSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const PlateSelector = ({
  values,
  setFieldValue,
}: PlateSelectorProps) => {
  const { data: manualSelectionPlates } = useGetPlatesForManualSelectorQuery();
  return (
    <Autocomplete
      id="tags-outlined"
      options={
        manualSelectionPlates?.platesForManualSelector?.map((node) => node) ??
        []
      }
      getOptionLabel={(option) => option!.plateBarcode!}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Plate" />
      )}
      isOptionEqualToValue={(option, value) =>
        option!.plateBarcode === value!.plateBarcode
      }
      onChange={(event: ChangeEvent<unknown>, newValue: any) =>
        setFieldValue("selectedPlate", newValue.plateBarcode)
      }
    />
  );
};
