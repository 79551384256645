import styled from "styled-components/macro";
import { IncomingSampleCollectionsTable } from "../Tables/Collections/IncomingSampleCollections";

const Layout = styled.div`
  padding: 56px 0;
`;
export function CollectionTableScreen(): JSX.Element {
  return (
    <Layout>
      <IncomingSampleCollectionsTable />
    </Layout>
  );
}
