import {
  CreateTicketForm,
  TicketAttachments,
} from "features/Tickets/components/Forms/Create/Ticket/Form";
import { useTicketQueryProvider } from "features/Tickets/Context";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import {
  CreateTicketAttachmentInput,
  CreateTicketInput,
  TicketPriority,
  useCreateTicketMutation,
} from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";

const initialValues: CreateTicketInput = {
  assetId: 0,
  dateEncountered: new Date(),
  description: "",
  isActive: false,
  priority: TicketPriority.Low,
  ticketTypeId: 0,
  ticketAssigneeIds: [],
  ticketFollowerIds: [],
};
export function CreateTicketModal(): JSX.Element {
  const windowSize = useWindowSize();
  const { ticketDispatch } = useTicketState();
  const [createTicket] = useCreateTicketMutation();
  const { refetch } = useTicketQueryProvider()!;
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <CreateTicketForm
        initialValues={initialValues}
        onSubmit={async (values) => {
          const twentyMB = 1024 * 1024 * 20;
          for (const file of TicketAttachments()) {
            if (file.size > twentyMB)
              return Alert({
                type: AlertType.ERROR,
                message: `${file.name} is too large. (20MB max)`,
              });
          }
          values.ticketAttachments = await Promise.all(
            TicketAttachments().map(
              async (file) =>
                ({
                  name: file.name,
                  file: file,
                } as CreateTicketAttachmentInput)
            )
          );
          TicketAttachments([]);
          return createTicket({
            variables: {
              input: values,
            },
          })
            .then(() => refetch())
            .then(() => {
              Alert({ type: AlertType.SUCCESS, message: "Ticket Submitted" });
              ticketDispatch({ type: TicketActions.CLOSE_MODAL });
            });
        }}
      />
    </StyledCard>
  );
}
