import { Box, Skeleton } from "@mui/material";
import _ from "lodash";
import React, { useMemo } from "react";
import { TableInstance } from "react-table";
import { GetSelectedDataFromSelectedFlatRows } from "selectors/react-table";
import {
  ReactTableContextMenuHandler,
  ReactTablePaginationState,
  ReactTableRowClickHandler,
  StyledTableCell,
  StyledTableRow,
} from "shared-components/Table/ReactTable";
import { RTRow } from "shared-components/Table/sub-components/Cell";

interface TableBodyProps<TData extends Record<string, unknown>> {
  loading?: boolean;
  onRowContextMenu?: ReactTableContextMenuHandler<TData>;
  onRowClick?: ReactTableRowClickHandler<TData>;
  paginationState?: ReactTablePaginationState;
  tableInstance: TableInstance<TData>;
}

function Body<TData extends Record<string, unknown>>({
  loading,
  onRowClick,
  onRowContextMenu,
  paginationState,
  tableInstance,
}: TableBodyProps<TData>): JSX.Element {
  const { rows, prepareRow, selectedFlatRows } = tableInstance;
  const selected = useMemo(
    () => GetSelectedDataFromSelectedFlatRows(selectedFlatRows),
    [selectedFlatRows]
  );
  return (
    <>
      {loading
        ? Array.from(
            Array(_.min([paginationState?.rowsPerPage ?? 10, 20])).keys()
          ).map((_, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {tableInstance.allColumns
                .filter(
                  (column) =>
                    !tableInstance.state.hiddenColumns?.includes(column.id)
                )
                .map((_, columnIndex) => (
                  <StyledTableCell key={`${rowIndex}${columnIndex}`}>
                    <Box m={"9px"}>
                      <Skeleton variant={"text"} />
                    </Box>
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          ))
        : // Loop over the table rows
          rows.map((row, _idx) => {
            // Prepare the row for display
            prepareRow(row);
            const key = row.getRowProps().key;
            return (
              <RTRow
                key={key}
                row={row}
                selected={selected}
                onRowClick={onRowClick}
                onRowContextMenu={onRowContextMenu}
              />
            );
          })}
    </>
  );
}

export const RTBody = Body;
