import { createSlice } from "@reduxjs/toolkit";
import undoable, { excludeAction } from "redux-undo";

export interface ThawToolState {
  sourcePlateInfo: SourcePlateInfoState[];
  destPlateInfo: any[];
  deadPlateBarcode: string;
  deadPlateType: string;
  worklistValues: WorklistValuesState;
}

export interface SourcePlateInfoState {
  plateBarcode: string;
  labwareTypeCode: string;
  rows: number[];
  cols: number[];
  wellInfo: any[];
}

export interface DestPlateInfoState {
  index: number;
  plateBarcode: string;
  labwareTypeCode: string;
  rows: number[];
  cols: number[];
  preprocessPlate: boolean;
  inCytomat: boolean;
  operatingVol?: number;
}

export interface WorklistValuesState {
  thawTransfer: any[];
  thawStamp: any[];
}

const ThawToolInitialState: ThawToolState = {
  sourcePlateInfo: [
    {
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      wellInfo: [],
    },
  ],
  destPlateInfo: [
    {
      index: 0,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 1,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 2,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 3,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 4,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 5,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 6,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
    {
      index: 7,
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      preprocessPlate: false,
      inCytomat: false,
    },
  ],
  deadPlateBarcode: "",
  deadPlateType: "",
  worklistValues: {
    thawTransfer: [],
    thawStamp: [],
  },
};

export const ThawToolStoreSlice = createSlice({
  name: "thaw-tool-info",
  initialState: ThawToolInitialState,
  reducers: {
    RESET_STATE: (state) => {
      state.sourcePlateInfo = ThawToolInitialState.sourcePlateInfo;
      state.destPlateInfo = ThawToolInitialState.destPlateInfo;
      state.worklistValues = ThawToolInitialState.worklistValues;
      state.deadPlateBarcode = ThawToolInitialState.deadPlateBarcode;
    },
    UPLOAD_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[0].plateBarcode = action.payload.plateBarcode;
      state.sourcePlateInfo[0].labwareTypeCode = action.payload.labwareTypeCode;
      state.sourcePlateInfo[0].rows = action.payload.rows;
      state.sourcePlateInfo[0].cols = action.payload.cols;
      state.sourcePlateInfo[0].wellInfo = action.payload.wellInfo;
    },
    SET_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo[action.payload.index].plateBarcode =
        action.payload.plateBarcode;
      state.destPlateInfo[action.payload.index].labwareTypeCode =
        action.payload.labwareTypeCode;
      state.destPlateInfo[action.payload.index].operatingVol =
        action.payload.operatingVol;
      state.destPlateInfo[action.payload.index].rows = action.payload.rows;
      state.destPlateInfo[action.payload.index].cols = action.payload.cols;
    },
    CLEAR_SOURCE_PLATE: (state, action) => {
      state.sourcePlateInfo[0].plateBarcode = "";
      state.sourcePlateInfo[0].labwareTypeCode = "";
      state.sourcePlateInfo[0].rows = [];
      state.sourcePlateInfo[0].cols = [];
      state.sourcePlateInfo[0].wellInfo = [];
      state.worklistValues.thawTransfer =
        state.worklistValues.thawTransfer.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );
    },
    CLEAR_DESTINATION_PLATE: (state, action) => {
      state.destPlateInfo[action.payload.index].plateBarcode = "";
      state.destPlateInfo[action.payload.index].preprocessPlate = false;
      state.destPlateInfo[action.payload.index].inCytomat = false;
      state.destPlateInfo[action.payload.index].labwareTypeCode = "";
      state.destPlateInfo[action.payload.index].rows = [];
      state.destPlateInfo[action.payload.index].cols = [];
      state.worklistValues.thawTransfer =
        state.worklistValues.thawTransfer.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );
      state.worklistValues.thawStamp = state.worklistValues.thawStamp.filter(
        (e) => e.plateIndex !== action.payload.index
      );
    },
    CLEAR_ALL_DESTINATION_PLATES: (state) => {
      state.destPlateInfo = ThawToolInitialState.destPlateInfo;
    },
    SET_DEAD_PLATEBARCODE: (state, action) => {
      state.deadPlateBarcode = action.payload.deadPlateBarcode;
    },
    WORKLIST_MAPPING_ADDED: (state, action) => {
      state.worklistValues.thawTransfer.push(...action.payload.selections);
    },
    UPDATE_TRANSFER_VOL: (state, action) => {
      state.worklistValues.thawTransfer[action.payload.index].transferVol =
        action.payload.vol;
    },
    ADD_THAW_STAMP_AND_MAPPING: (state, action) => {
      state.worklistValues.thawTransfer.push(...action.payload.selections);
      state.worklistValues.thawStamp.push({
        plateIndex: action.payload.plateIndex,
        wellId: action.payload.plateWellId,
        stampVol: 0,
      });
    },
    UPDATE_THAW_STAMP_WORKLIST: (state, action) => {
      state.worklistValues.thawStamp[action.payload.index].stampVol =
        action.payload.stampVol;
    },
    UPDATE_STAMP_VOL: (state, action) => {
      state.worklistValues.thawStamp[action.payload.index].stampVol =
        action.payload.stampVol;
    },
    STAMP_96_WELL_PLATE: (state, action) => {
      state.worklistValues.thawTransfer.push(...action.payload.selections);
    },
    CLEAR_DESTINATION_WORKLIST_VALUES: (state, action) => {
      state.worklistValues.thawTransfer =
        state.worklistValues.thawTransfer.filter(
          (e) => e.destPlateIndex !== action.payload.index
        );

      state.worklistValues.thawStamp = state.worklistValues.thawStamp.filter(
        (e) => e.plateIndex !== action.payload.index
      );
    },
    UPDATE_DEAD_PLATEBARCODE: (state, action) => {
      state.deadPlateBarcode = action.payload.value;
    },
    UPDATE_DEAD_PLATETYPE: (state, action) => {
      state.deadPlateType = action.payload.value;
    },
    UPDATE_DESTINATION_PLATE_INFO: (state, action) => {
      state.destPlateInfo[action.payload.index][action.payload.key] =
        action.payload.value;
    },
    UPLOAD_DESTINATION_PLATE_INFO: (state, action) => {
      state.destPlateInfo = action.payload.destPlateInfo;
    },
    UPLOAD_WORKLIST_MAPPING: (state, action) => {
      state.worklistValues.thawTransfer = action.payload.thawTransfer;
    },
    UPLOAD_WORKLIST_THAW_STAMP: (state, action) => {
      state.worklistValues.thawStamp = action.payload.thawStamp;
    },
    UPLOAD_SOURCE_AND_DEST_PLATE_INFO: (state, action) => {
      state.sourcePlateInfo = action.payload.sourcePlateInfo;
      state.destPlateInfo = action.payload.destPlateInfo;
    },
  },
});

export const ThawToolReducer = ThawToolStoreSlice.reducer;

export const ThawToolActions = ThawToolStoreSlice.actions;

export const ThawTool = undoable(ThawToolReducer, {
  filter: excludeAction([
    ThawToolActions.CLEAR_DESTINATION_WORKLIST_VALUES.toString(),
    ThawToolActions.UPDATE_TRANSFER_VOL.toString(),
    ThawToolActions.UPDATE_THAW_STAMP_WORKLIST.toString(),
  ]),
});
