import { Description, FiberManualRecord, Folder } from "@mui/icons-material";
import TreeItem from "@mui/lab/TreeItem";
import { RunsFolderTreeActions } from "features/Runs/state/folder-tree/actions";
import { useRunsFolderTreeState } from "features/Runs/state/hooks";
import { DirectoryFolder, NodeType } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { FC } from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { FolderTreeContextMenuState } from "../ContextMenus/FolderTree/RunFolder";
import { GetTreeNodeKey } from "./helpers";
import { RenderTree } from "./Tree";

const StyledTreeItem = styled(TreeItem)`
  margin: 7px 0;
  .MuiTreeItem-group {
    margin-left: 7px;
    padding-left: 18px;
    border-left: 1px dashed
      ${({
        theme: {
          palette: { mode },
        },
      }) => (mode === "light" ? "black" : "white")};
  }
`;
const NodeLabel = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize * 1.3}px;
  font-weight: bold;
  margin-left: 10px;
  user-select: none;
`;
const FolderIcon = styled(Folder)`
  color: deepskyblue;
  font-size: 28px !important;
`;
const RunIcon = styled(Description)`
  font-size: 25px !important;
`;
const StageIcon = styled(FiberManualRecord)`
  font-size: 25px !important;
`;

const GetIcon: FC<{ type: NodeType }> = ({ type }) => {
  switch (type) {
    case NodeType.Folder:
      return <FolderIcon />;
    case NodeType.Run:
      return <RunIcon />;
    default:
      return <StageIcon />;
  }
};

export function FolderTreeNode({
  node,
}: {
  node: Maybe<DirectoryFolder>;
}): JSX.Element {
  const { runsFolderTreeDispatch } = useRunsFolderTreeState();
  return (
    <StyledTreeItem
      key={GetTreeNodeKey(node)}
      nodeId={GetTreeNodeKey(node)}
      label={<NodeLabel>{node?.name}</NodeLabel>}
      icon={GetIcon({ type: node!.type })}
      TransitionProps={{ timeout: 200, unmountOnExit: true }}
      onDoubleClickCapture={() => {
        runsFolderTreeDispatch({
          type: RunsFolderTreeActions.NODE_SELECTED,
          payload: { currentNode: { type: node!.type, id: node!.id } },
        });
      }}
      onContextMenu={(e) => {
        if (node?.type === NodeType.Run || node?.type === NodeType.Folder) {
          e.preventDefault();
          e.stopPropagation();
          FolderTreeContextMenuState(node);
          contextMenu.show({
            id: "folder-context-menu",
            event: e,
            props: node,
          });
        }
      }}
      data-testid={GetTreeNodeKey(node)}
    >
      <>
        {node?.subFolders?.map((node) => (
          <RenderTree key={GetTreeNodeKey(node)} node={node} />
        ))}
      </>
    </StyledTreeItem>
  );
}
