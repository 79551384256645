import { PersonalTaskTemplate } from "graphql/generated/schema-types";
import React from "react";
import { Column } from "react-table";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import { UserCell } from "shared-components/Table/sub-components/CacheLookupCells";

export const PersonalTaskTemplateColumns: Array<Column<PersonalTaskTemplate>> =
  [
    {
      Header: "Template Name",
      accessor: "personalTaskTemplateName",
      Filter: TextFilter,
      width: "40%",
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      Filter: GetUserFilter(),
      Cell: ({ value }) => (value ? <UserCell userId={value} /> : null),
      width: "20%",
    },
  ];
