import { Button } from "@mui/material";
import React, { FC } from "react";
import styled from "styled-components/macro";

const ToastMessage = styled.div`
  width: 270px;
  position: relative;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledButton = styled(Button)`
  height: 20px !important;
  color: white !important;
  background-color: #888888 !important;
`;
export const ErrorToastContent: FC<{
  shortErrorMessage: string;
  longErrorMessage: string;
}> = ({ shortErrorMessage, longErrorMessage }) => {
  // const alertStore = useReactiveVar(AlertStore);
  return (
    <ToastMessage>
      <div>{shortErrorMessage}</div>
      <ButtonContainer>
        <StyledButton
          size={"small"}
          onClick={async () => {
            await navigator.clipboard.writeText(longErrorMessage);
            // const allErrors = alertStore?.map((a) => a[0].longMessage);
            // await navigator.clipboard.writeText(
            //   JSON.stringify(allErrors, null, 4)
            // );
            // AlertStore(undefined);
          }}
        >
          Copy
        </StyledButton>
      </ButtonContainer>
    </ToastMessage>
  );
};
