import { CeligoPlateMetadata } from "features/WorklistTools/CeligoScan/Dialog/state";
import { FormikHelpers } from "formik";
import {
  MethodReservation,
  RunTaskInput,
  useReserveCeligoRunTaskMethodMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { ValidatePlateMetadata } from "helpers/validatePlateMetadata";
import RunsService from "services/RunsService";
import { Alert, AlertType } from "shared-components/toast";

export const useGetRunActivityMethodReservationHandleSubmit = (
  methodReservation: Maybe<MethodReservation>
): ((values: RunTaskInput, actions: FormikHelpers<RunTaskInput>) => void) => {
  const [reserveRunActivityPlates] = useReserveCeligoRunTaskMethodMutation();

  return async (
    values: RunTaskInput,
    { setSubmitting, setFieldError }: FormikHelpers<RunTaskInput>
  ) => {
    if (methodReservation?.methodReservationId) {
      RunsService.downloadWorklist(methodReservation.methodReservationId);
      setSubmitting(false);
      return;
    }
    values.methodReservationPlateMetadata = CeligoPlateMetadata().filter(
      (metadata) => metadata.experimentSetting
    );
    const errorMessage = await ValidatePlateMetadata(
      values.methodReservationPlateMetadata
    );
    if (errorMessage) {
      return setFieldError("methodReservationPlateMetadata", errorMessage);
    }

    return reserveRunActivityPlates({
      variables: {
        input: values,
      },
    })
      .then(() =>
        Alert({ type: AlertType.SUCCESS, message: "Method Reserved" })
      )
      .finally(() => {
        setSubmitting(false);
      });
  };
};
