import React from "react";
import { UserManagerModalContainer } from "./Modals/UserManagerModalContainer";
import { UserManagerModalContent } from "./Modals/UserManagerModalContent";
import { UserTable } from "./Table/UserTable";

export function UserManager(): JSX.Element {
  return (
    <>
      <UserManagerModalContainer>
        <UserManagerModalContent />
      </UserManagerModalContainer>
      <UserTable />
    </>
  );
}
