import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AddRackOptions, Process } from "../../constants";

interface ProcessSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ProcessSelector = ({
  values,
  setFieldValue,
}: ProcessSelectorProps) => {
  const setSelectedProcess = (process: string) => {
    setFieldValue("process", process);
    setFieldValue("resuspensionVol", 0);
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Process</InputLabel>
      <Select
        labelId="process-label"
        id="process-label"
        label="Process"
        value={values.process}
        onChange={(e) => setSelectedProcess(e.target.value)}
      >
        <MenuItem value={Process.Fibroblast}>Fibroblast</MenuItem>
        <MenuItem value={Process.Monoclonalization}>Monoclonalization</MenuItem>
        <MenuItem value={Process.BloodProcessing}>Blood Processing</MenuItem>
        <MenuItem value={Process.Saliva}>Saliva</MenuItem>
        <MenuItem value={Process.Consolidation}>Consolidation</MenuItem>
        <MenuItem value={Process.iPSCExpansion}>iPSC Expansion</MenuItem>
        <MenuItem value={Process.EBCollection}>EB Collection</MenuItem>
        <MenuItem value={Process.DNARNAQC}>DNA/RNA QC</MenuItem>
        <MenuItem value={Process.QC}>QC</MenuItem>
        <MenuItem value={Process.PoolingOr1224TC}>Pooling/1224TC</MenuItem>
        <MenuItem value={Process.ManualPlate}>Manual Plate</MenuItem>
      </Select>
    </FormControl>
  );
};
