import { useRunsModalState } from "features/Runs/state/hooks";
import { CreateRunFolderInput } from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import React, { useMemo } from "react";
import { StyledCard } from "shared-components/styled-components";
import { useCreateRunFolder } from "../hooks";
import { CreateRunFolderForm } from "./Form";

export function CreateRunFolderModal(): JSX.Element {
  const {
    runsModalState: { selectedFolderId },
  } = useRunsModalState();
  const initialValues: CreateRunFolderInput = useMemo(
    () => ({
      folderName: "",
      isInsertable: false,
      folderParentId: selectedFolderId,
    }),
    [selectedFolderId]
  );
  const handleSubmit = useCreateRunFolder();
  const windowSize = useWindowSize();

  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <CreateRunFolderForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
      />
    </StyledCard>
  );
}
