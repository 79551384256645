import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { AddRackOptions } from "../../constants";
import { DateSelector } from "../selectors/DateSelector";
import { RunSelector } from "../selectors/RunSelector";

interface BloodProcessingProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const BloodProcessing = ({
  values,
  setFieldValue,
}: BloodProcessingProps) => {
  const getFormControlLabel = (label: string, value: boolean) => (
    <FormControlLabel
      name="isAutomated"
      style={{ margin: 0, padding: 0 }}
      onChange={() => setFieldValue("isAutomated", value)}
      value={value}
      control={<Radio color="primary" />}
      label={label}
    />
  );
  return (
    <React.Fragment>
      <RunSelector values={values} setFieldValue={setFieldValue} />
      <RadioGroup defaultValue={false} value={values.isAutomated}>
        {getFormControlLabel("Manual 2 Tubes", false)}
        {getFormControlLabel("Automated/Manual 1 Tube", true)}
      </RadioGroup>
      <DateSelector values={values} setFieldValue={setFieldValue} />
    </React.Fragment>
  );
};
