import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { MethodSettingsProps } from "../shared";

export const SelectedSystem = ({
  twelve24TCInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const hasError = twelve24TCInternalState.methodSettings!.selectedSystem === 0;

  return (
    <FormControl variant="outlined" error={hasError}>
      <InputLabel id="system-label">System</InputLabel>
      <Select
        labelId="system-label"
        id="system-select"
        defaultValue={4}
        label="System"
        size="small"
        value={twelve24TCInternalState.methodSettings.selectedSystem}
        onChange={(e) =>
          handleMethodSettingsChanged("selectedSystem", e.target.value)
        }
      > 
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={10}>10</MenuItem>
      </Select>
      <FormHelperText>
        {hasError ? "System must be selected" : ""}
      </FormHelperText>
    </FormControl>
  );
};
