import { PageWithNav } from "features/Navbar/PageWithNav";
import React from "react";
import { UserManager } from "../components/UserManager";

function AdminDashboard(): JSX.Element {
  return (
    <PageWithNav>
      <UserManager />
    </PageWithNav>
  );
}
export default AdminDashboard;
