import {
  ManagePersonalTask,
  OperationNames,
  SortEnumType,
  TaskPriorityEnum,
  TaskStatusEnum,
  TaskTypeEnum,
  useDeleteTasksMutation,
  useGetMeQuery,
  useGetMyPersonalTasksQuery,
  useUpdatePersonalTasksMutation,
} from "graphql/generated/schema-types";
import produce from "immer";
import _ from "lodash";
import { ManagePersonalTaskInputMapper } from "mappings/managePersonalTaskInput";
import React, { useEffect, useMemo, useState } from "react";
import { contextMenu, Item, Menu, Submenu } from "react-contexify";
import {
  Row,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { useInitializeConnectionData } from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { Alert, AlertType } from "shared-components/toast";
import { ManagePersonalTasksColumns } from "./Columns";
import {
  ContextMenuDialogs,
  ContextMenuDialogSelector,
} from "./Dialogs/ContextMenuDialogSelector";
import { TaskDetailsDialog } from "./Dialogs/TaskDetails";
import {
  getFilterHandler,
  getHandlePageCountChange,
  getPageChangeHandler,
  getSortHandler,
} from "./handlers";

export default function PersonalTasks() {
  const { data: meQuery } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  const [selectedTasks, setSelectedTasks] = useState<ManagePersonalTask[]>([
    {
      taskId: 0,
      taskName: "",
      notes: "",
      status: TaskStatusEnum.Scheduled,
      priority: TaskPriorityEnum.Low,
      startTimeScheduled: null,
      taskOwnerId: 0,
    },
  ]);

  const paginationTuple = useState<ReactTablePaginationState>({
    totalCount: 0,
    rowsPerPage: 50,
    page: 0,
  });
  const [paginationState, setPaginationState] = paginationTuple;

  const { refetch, fetchMore, data, variables, loading } =
    useGetMyPersonalTasksQuery({
      variables: {
        order_by: { startTimeScheduled: SortEnumType.Desc },
      },
      notifyOnNetworkStatusChange: true,
    });

  const pageData = useInitializeConnectionData<ManagePersonalTask>(
    data?.myPersonalTasks,
    paginationState
  );

  const totalCount = useMemo(
    () =>
      GetTotalCountFromConnectionType<ManagePersonalTask>(
        data?.myPersonalTasks
      ),
    [data?.myPersonalTasks]
  );

  const pageInfo = useMemo(
    () =>
      GetPageInfoFromConnectionType<ManagePersonalTask>(data?.myPersonalTasks),
    [data?.myPersonalTasks]
  );

  const handleContextMenu =
    (selected: ManagePersonalTask[], row?: Row<ManagePersonalTask>) =>
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      row?.toggleRowSelected(true);
      console.log(selected);
      contextMenu.show({
        id: "personaltask-context-menu",
        event: e,
        props: {
          selected: selected.includes(row!.original)
            ? selected
            : [...selected, row?.original],
        },
      });
      e.preventDefault();
    };

  const handlePageCountChange = useMemo(
    () =>
      getHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, setPaginationState, variables]
  );

  const [taskDetailsOpen, setTaskDetailsOpen] = useState(false);
  const handleRowClick = (rowClicked: any) => {
    if (!rowClicked?.isGrouped) {
      setSelectedTasks([rowClicked.original]);
      setTaskDetailsOpen(true);
    }
  };

  const markAs = (status: TaskStatusEnum, args: any) => {
    const tasks: ManagePersonalTask[] = args?.props?.selected ?? [];
    setSelectedTasks(tasks);

    const updatedTasks = _.cloneDeep(tasks);
    updatedTasks.forEach((mpt) => {
      mpt.status = status;
    });
    callUpdatePersonalTask(updatedTasks);
  };

  const setPriority = (priority: TaskPriorityEnum, args: any) => {
    const tasks: ManagePersonalTask[] = args?.props?.selected ?? [];
    setSelectedTasks(tasks);

    const updatedTasks = _.cloneDeep(tasks);
    updatedTasks.forEach((mpt) => {
      mpt.priority = priority;
    });
    callUpdatePersonalTask(updatedTasks);
  };

  const [updatePersonalTask] = useUpdatePersonalTasksMutation();
  const callUpdatePersonalTask = (tasks: ManagePersonalTask[]) => {
    updatePersonalTask({
      variables: {
        inputs: tasks.map((task) => ManagePersonalTaskInputMapper(task)),
      },
    }).then(() => refetch(variables));
  };

  const [deleteTasks] = useDeleteTasksMutation();
  const handleDeleteTask = (selectedTasks: {
    selected: ManagePersonalTask[];
  }) => {
    if (
      selectedTasks.selected.some(
        (item: ManagePersonalTask) => item.taskOwnerId !== meQuery?.me?.userId
      )
    ) {
      Alert({
        type: AlertType.ERROR,
        message: "You can only delete tasks that you have created",
      });
      return;
    }
    deleteTasks({
      variables: {
        taskIDs: selectedTasks?.selected?.map((task: ManagePersonalTask) => {
          return task!.taskId!;
        }),
        taskType: TaskTypeEnum.Personal,
      },
      refetchQueries: [OperationNames.Query.getMyPersonalTasks],
    });
  };

  const [contextMenuDialogState, setContextMenuDialogState] = useState(false);
  const [selectedDialog, setSelectedDialog] = useState<ContextMenuDialogs>(
    ContextMenuDialogs.AssignToUser
  );
  const openDialog = (dialog: ContextMenuDialogs, args: any) => {
    setSelectedTasks(args.props.selected);
    setContextMenuDialogState(true);
    setSelectedDialog(dialog);
  };

  const onPageChange = useMemo(
    () =>
      getPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [
      fetchMore,
      pageInfo?.endCursor,
      paginationState,
      setPaginationState,
      variables,
    ]
  );

  const sortTable = useMemo(
    () => getSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const filterTable = useMemo(
    () => getFilterHandler(variables, refetch),
    [refetch, variables]
  );

  const personalTaskTableInstance = useTable(
    {
      columns: ManagePersonalTasksColumns,
      data: pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState: {
        sortBy: [{ id: "startTimeScheduled", desc: true }],
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  useEffect(() => {
    if (paginationState.page !== 0)
      setPaginationState(
        produce(paginationState, (draft) => {
          draft.page = 0;
        })
      );
  }, [paginationState, setPaginationState]);

  useEffect(() => {
    console.log(data);
    const _paginationState = produce(paginationState, (draft) => {
      draft.totalCount = totalCount ?? 0;
    });
    setPaginationState(_paginationState);
  }, [paginationState, setPaginationState, totalCount]);

  return (
    <React.Fragment>
      <ReactTable
        tableInstance={personalTaskTableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        onRowClick={(rowClicked) => () => handleRowClick(rowClicked)}
        loading={loading}
      />
      <ContextMenuDialogSelector
        dialog={selectedDialog}
        isOpen={contextMenuDialogState}
        setState={() => setContextMenuDialogState(false)}
        refetch={refetch}
        variables={variables}
        selectedTasks={selectedTasks}
        updatePersonalTask={updatePersonalTask}
        setPaginationState={setPaginationState}
      />
      <TaskDetailsDialog
        isOpen={taskDetailsOpen}
        handleClose={() => setTaskDetailsOpen(false)}
        selectedTask={selectedTasks[0]}
      />
      <Menu id={"personaltask-context-menu"} style={{ zIndex: 10000 }}>
        <Submenu label="Mark As" disabled>
          <Item onClick={(args) => markAs(TaskStatusEnum.Scheduled, args)}>
            Scheduled
          </Item>
          <Item onClick={(args) => markAs(TaskStatusEnum.Completed, args)}>
            Completed
          </Item>
          <Item onClick={(args) => markAs(TaskStatusEnum.Cancelled, args)}>
            Cancelled
          </Item>
        </Submenu>
        <Submenu label="Set Priority">
          <Item
            onClick={(args) => setPriority(TaskPriorityEnum.Immediate, args)}
          >
            Immediate
          </Item>
          <Item onClick={(args) => setPriority(TaskPriorityEnum.High, args)}>
            High
          </Item>
          <Item onClick={(args) => setPriority(TaskPriorityEnum.Medium, args)}>
            Medium
          </Item>
          <Item onClick={(args) => setPriority(TaskPriorityEnum.Low, args)}>
            Low
          </Item>
        </Submenu>
        <Submenu label="Assign To">
          <Item
            onClick={(args) =>
              openDialog(ContextMenuDialogs.AssignToUser, args)
            }
          >
            User
          </Item>
          <Item
            onClick={(args) =>
              openDialog(ContextMenuDialogs.AssignToGroup, args)
            }
          >
            Group
          </Item>
          <Item
            onClick={(args) =>
              openDialog(ContextMenuDialogs.AssignToSystem, args)
            }
          >
            System
          </Item>
        </Submenu>
        <Item
          onClick={(args) =>
            openDialog(ContextMenuDialogs.RescheduleTask, args)
          }
          disabled
        >
          Reschedule Task
        </Item>
        <Item onClick={(args) => openDialog(ContextMenuDialogs.EditTask, args)}>
          Edit Task
        </Item>
        <Item
          onClick={({ props }) =>
            handleDeleteTask(props as { selected: ManagePersonalTask[] })
          }
        >
          Delete
        </Item>
      </Menu>
    </React.Fragment>
  );
}
