import { MarkMultipleFormFields } from "features/Runs/components/Forms/EditMultiple/Task/EditMultipleTasksForm";
import {
  RunTaskInput,
  CreateRunFolderInput,
  CreateRunInput,
  RunTaskDetailInput,
  TaskPriorityEnum,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import * as Yup from "yup";
import { PlateCodes } from "hooks/useInitializePlateCodesStore";

export const RunTaskDetailsInputSchema =
  Yup.object().shape<RunTaskDetailInput>({
    parentId: Yup.number().required("Required"),
    runTaskDetailId: Yup.number().required("Required"),
    daughterWellNumber: Yup.number()
      .moreThan(-1, "Cannot be less than 0")
      .nullable()
      .notRequired(),
    maxCryovial: Yup.number()
      .moreThan(-1, "Cannot be less than 0")
      .nullable()
      .notRequired(),
    minCryovial: Yup.number()
      .moreThan(-1, "Cannot be less than 0")
      .nullable()
      .notRequired(),
    seedCellCount: Yup.number()
      .moreThan(-1, "Cannot be less than 0")
      .nullable()
      .notRequired(),
    overflowVials: Yup.string().nullable().notRequired(),
    destPlateCode: Yup.lazy(() =>
      Yup.string()
        .oneOf(PlateCodes(), "Not a valid destination plate")
        .nullable()
        .notRequired()
    ),
  });

export const UpdateRunActivityValidationSchema =
  Yup.object().shape<RunTaskInput>({
    runStageId: Yup.number()
      .moreThan(0, "Required")
      .required("Required")
      .required(),
    activityName: Yup.string()
      .notOneOf([""], "Required")
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    startTimeScheduled: Yup.date().required("Required").nullable(),
    systemAssignedTo: Yup.number().moreThan(0, "Required").required("Required"),
    status: Yup.mixed<TaskStatusEnum>().oneOf(Object.values(TaskStatusEnum)),
    details: RunTaskDetailsInputSchema.notRequired().nullable().default(null),
  });
export const CreateRunActivityValidationSchema =
  Yup.object().shape<RunTaskInput>({
    runActivityId: Yup.number().required("Required"),
    runStageId: Yup.number()
      .moreThan(0, "Required")
      .required("Required")
      .required(),
    activityName: Yup.string()
      .notOneOf([""], "Required")
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    startTimeScheduled: Yup.date().required("Required").nullable(),
    systemAssignedTo: Yup.number().moreThan(0, "Required").required("Required"),
    status: Yup.mixed<TaskStatusEnum>().oneOf(Object.values(TaskStatusEnum)),
    priority: Yup.mixed<TaskPriorityEnum>().oneOf(
      Object.values(TaskPriorityEnum)
    ),
    details: RunTaskDetailsInputSchema.notRequired().nullable().default(null),
  });
export const MarkMultipleRunActivityValidationSchema =
  Yup.object().shape<MarkMultipleFormFields>({
    systemAssignedTo: Yup.number().notRequired(),
    status: Yup.string().notRequired(),
    startTimeScheduled: Yup.date().nullable().default(null).notRequired(),
  });

const startDateSchema = Yup.date()
  .typeError("Invalid Date")
  .required("Required")
  .nullable();

export const CreateRunValidationSchema = Yup.object().shape<CreateRunInput>({
  runId: Yup.number().nullable().required(),
  runTemplateId: Yup.number()
    .moreThan(0, "Required")
    .nullable()
    .required("Required"),
  folderParentId: Yup.number()
    .moreThan(0, "Required")
    .nullable()
    .required("Required"),
  runDateScheduled: startDateSchema,
  runName: Yup.string()
    .notOneOf([""], "Required")
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  comments: Yup.string().notRequired(),
  status: Yup.mixed<TaskStatusEnum>().oneOf(Object.values(TaskStatusEnum)),
});

export const CreateRunFolderValidationSchema =
  Yup.object().shape<CreateRunFolderInput>({
    folderParentId: Yup.number().nullable().notRequired(),
    folderName: Yup.string()
      .notOneOf([""], "Required")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    isInsertable: Yup.boolean().required(),
  });
