import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  AppSuiteGroup,
  DashboardTask,
  useGetGroupsQuery,
} from "graphql/generated/schema-types";
import { useGetGroupsById } from "hooks/cacheAccessHooks";
import React, { ChangeEvent, useEffect, useState } from "react";

interface AssignToGroupsProps {
  isOpen: boolean;
  handleClose: (assignedGroup: number[], assignGroup: boolean) => void;
  selectedTasks: DashboardTask[];
}

export const AssignToGroupsDialog = ({
  isOpen,
  handleClose,
  selectedTasks,
}: AssignToGroupsProps) => {
  const groupsQuery = useGetGroupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  const assignedGroups = useGetGroupsById(selectedTasks[0]!.assignedGroups!);
  console.log(assignedGroups);

  const [selectedGroups, setSelectedGroups] = useState<AppSuiteGroup[]>([]);
  const [selectedGroupsChanged, setSelectedGroupsChanged] = useState(false);

  useEffect(() => {
    setSelectedGroupsChanged(false);
  }, [selectedTasks]);

  const handleSubmit = () => {
    const groupIds = selectedGroups.map((group) => {
      return group!.groupId!;
    });
    console.log(groupIds);
    handleClose(groupIds, true);
  };

  const handleGroupsChanged = (e: ChangeEvent<unknown>, value: any) => {
    setSelectedGroups(value);
    setSelectedGroupsChanged(true);
  };

  const clearSelectedGroups = () => {
    setSelectedGroups([]);
    handleClose([], false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => clearSelectedGroups()}>
        <DialogTitle>Assign Tasks to Group(s)</DialogTitle>
        <DialogContent>
          <ul>
            {selectedTasks.map((task) => (
              <li>{task.taskName}</li>
            ))}
          </ul>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={
              groupsQuery.data?.appSuiteGroups?.map((node) => node) ?? []
            }
            getOptionLabel={(option) => option!.groupName!}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              handleGroupsChanged(event, newValue)
            }
            // getOptionSelected={(selected, option) =>
            //   !!(
            //     selected?.groupId &&
            //     option?.groupId &&
            //     selected?.groupId === option?.groupId
            //   )
            // }
            value={selectedGroupsChanged ? selectedGroups : assignedGroups!}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
