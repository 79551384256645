export const NORMALIZED_PASSAGE_SOURCE_PLATEINFO_UPDATED =
  "Updated Normalized Passage Source Plate Info";
// export const NORMALIZED_PASSAGE_WORKLIST_ROW_ADDED =
//   "Added Normalized Passage Worklist Rows";
// export const NORMALIZED_PASSAGE_WORKLIST_SRC_TO_INT_ADDED =
//   "Added Normalized Passage Src to Int Rows";
// export const NORMALIZED_PASSAGE_WORKLIST_INT1_TO_INT2_ADDED =
//   "Added Normalized Passage Int1 to Int2 Rows";
// export const THAW_STAMPED_96W_PLATE =
//   "Stamped Normalized Passage MRACK to 96W Dest Plate";
// export const NORMALIZED_PASSAGE_DESTINATION_PLATEINFO_UPDATED =
//   "Updated Normalized Passage Destination Plate Info";
// export const NORMALIZED_PASSAGE_DESINTATION_PLATEBARCODE_UPDATES =
//   "Updated Normalized Passage Destination Plate Barcode";
// export const NORMALIZED_PASSAGE_CLEARED_DEST_WORKLIST_VALUES =
//   "Cleared Normalized Passage Destination Worklist Values";
// export const NORMALIZED_PASSAGE_DESTINATION_PLATE_SETTINGS_UPDATED =
//   "Updated Normalized Passage Destination Plate UserSettings";
// export const NORMALIZED_PASSAGE_WORKLIST_METHOD_SETTINGS_UPDATED =
//   "Updated Normalized Passage Method UserSettings";
// export const NORMALIZED_PASSAGE_WORKLIST_SRC_STAMP_UPDATED =
//   "Updated Normalized Passage Source Stamp Selections";
// export const NORMALIZED_PASSAGE_WORKLIST_SRC_TO_INT_ADDED_MANUALLY =
//   "Manually Added Normalized Passage Src to Int Rows";
// export const NORMALIZED_PASSAGE_WORKLIST_INT1_TO_INT2_ADDED_MANUALLY =
//   "Manually Added Normalized Passage Int1 to Int2 Rows";
// export const NORMALIZED_PASSAGE_WORKLIST_UPDATE_PLATEBARCODES =
//   "Updated INT, INT2 and Dead PlateBarcode";
// export const NORMALIZED_PASSAGE_WORKLIST_SET_INITIAL_STAMP =
//   "Set Normalized Passage Initial Stamp Values";
// export const NORMALIZED_PASSAGE_WORKLIST_CLEAR_INT_WELLS =
//   "Cleared Normalized Passage Int Wells";
// export const NORMALIZED_PASSAGE_WORKLIST_UPLOAD_SOURCE_AND_DEST_INFO =
//   "Uploaded Normalized Passage Worklist Source and Dest Info";
// export const NORMALIZED_PASSAGE_WORKLIST_UPLOAD_PASSAGE_WELLS =
//   "Uploaded Normalized Passage Wells values";
// export const NORMALIZED_PASSAGE_WORKLIST_DESTINATION_PLATE_FIELD_UPDATED =
//   "Updated Destination Plate Field";
// export const NORMALIZED_PASSAGE_WORKLIST_UPDATE_CELL_NUMBER = "Updated Cell Number for Passage Wells"
// export const NORMALIZED_PASSAGE_WORKLIST_STAMP_UPDATE_ALL_CELL_NUMBER = "Updated Stamp Cell Number for all Wells";
// export const NORMALIZED_PASSAGE_WORKLIST_UPLOAD_METHOD_SETTINGS = "Uploaded Parsed Method UserSettings";
