import { MutationUpdaterFn } from "@apollo/client";
import { RunsFolderTreeActions } from "features/Runs/state/folder-tree/actions";
import {
  useRunsFolderTreeState,
  useRunsModalState,
} from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  DeleteRunFolderMutation,
  DeleteRunMutation,
  DeleteTasksMutation,
  NodeType,
  TaskTypeEnum,
  useDeleteRunFolderMutation,
  useDeleteRunMutation,
  useDeleteTasksMutation,
} from "graphql/generated/schema-types";
import { Dispatch, SetStateAction } from "react";
import { Alert, AlertType } from "shared-components/toast";
const getDeleteRunTaskUpdateFunction =
  (taskIds: number[]): MutationUpdaterFn<DeleteTasksMutation> =>
  (cache) => {
    const tasks: {
      __typename: string;
      runTaskId: number;
    }[] = taskIds.map((id) => ({
      __typename: "RunTask",
      runTaskId: id,
    }));

    const cacheReferences = tasks!.map((task) => cache.identify(task!));

    for (const ref of cacheReferences) {
      cache.evict({ id: ref });
    }
  };

export const useGetDeleteRunTask = (
  setSubmitting: Dispatch<SetStateAction<boolean>>
) => {
  const { runsModalDispatch } = useRunsModalState();
  const [deleteTasks] = useDeleteTasksMutation();

  return async (taskIds: number[]) => {
    setSubmitting(true);
    await deleteTasks({
      variables: {
        taskIDs: taskIds,
        taskType: TaskTypeEnum.RunTask,
      },
      update: getDeleteRunTaskUpdateFunction(taskIds),
    })
      .then(() => {
        runsModalDispatch({
          type: RunsModalActions.CLOSE_DIALOG,
        });
        Alert({ type: AlertType.WARNING, message: "Task(s) Deleted" });
      })
      .finally(() => setSubmitting(false));
  };
};

const getDeleteRunCacheUpdateFunction =
  (runId: number): MutationUpdaterFn<DeleteRunMutation> =>
  (cache) => {
    const folderKey = cache.identify({
      __typename: "Run",
      runId: runId,
    });
    cache.evict({ id: folderKey });

    const directoryKey = cache.identify({
      __typename: "DirectoryFolder",
      type: NodeType.Run,
      id: runId,
    });
    cache.evict({ id: directoryKey });
  };

const getDeleteRunFolderCacheUpdateFunction =
  (folderId: number): MutationUpdaterFn<DeleteRunFolderMutation> =>
  (cache) => {
    const folderKey = cache.identify({
      __typename: "FolderStructure",
      folderId: folderId,
    });
    cache.evict({ id: folderKey });

    const directoryKey = cache.identify({
      __typename: "DirectoryFolder",
      type: NodeType.Folder,
      id: folderId,
    });
    cache.evict({ id: directoryKey });
  };

export const useGetDeleteRun = (
  setSubmitting: Dispatch<SetStateAction<boolean>>
) => {
  const { runsModalDispatch } = useRunsModalState();
  const { runsFolderTreeDispatch } = useRunsFolderTreeState();
  const [deleteRunMutation] = useDeleteRunMutation();
  return async (runId: number) => {
    setSubmitting(true);
    await deleteRunMutation({
      variables: { input: { primaryKey: runId } },
      update: getDeleteRunCacheUpdateFunction(runId),
    })
      .then((response) => {
        runsFolderTreeDispatch({
          type: RunsFolderTreeActions.NODE_SELECTED,
          payload: {
            currentNode: {
              id: response.data!.deleteRun!.folderId,
              type: NodeType.Folder,
            },
          },
        });
        runsModalDispatch({
          type: RunsModalActions.CLOSE_DIALOG,
        });
        Alert({ type: AlertType.WARNING, message: "Run Deleted" });
      })
      .finally(() => setSubmitting(false));
  };
};

export const useGetDeleteRunFolder = (
  setSubmitting: Dispatch<SetStateAction<boolean>>
) => {
  const { runsModalDispatch } = useRunsModalState();
  const [deleteRunFolderMutation] = useDeleteRunFolderMutation();
  return async (folderId: number) => {
    setSubmitting(true);
    await deleteRunFolderMutation({
      variables: { input: { primaryKey: folderId } },
      update: getDeleteRunFolderCacheUpdateFunction(folderId),
    })
      .then(() => {
        runsModalDispatch({
          type: RunsModalActions.CLOSE_DIALOG,
        });
        Alert({ type: AlertType.WARNING, message: "Folder Deleted" });
      })
      .finally(() => setSubmitting(false));
  };
};
