import { ErrorResponse } from "@apollo/client/link/error";
import { LogGraphQLError } from "error/loggers/graphql-error-logger";
import { LogNetworkError } from "error/loggers/network-error-logger";
import { GroupMemberLoginDialogOpen } from "features/Authentication/GroupAuth/GroupMemberLoginForm";
import { GraphQLError } from "graphql";
import { Alert, AlertType } from "shared-components/toast";

export const GetErrorHandler =
  () =>
  ({ graphQLErrors, networkError, response }: ErrorResponse) => {
    let errors: GraphQLError | null | undefined;
    if (response?.errors) errors = response.errors[0];

    if (errors?.extensions?.code === "UNSPECIFIED_GROUP_MEMBER") {
      GroupMemberLoginDialogOpen(true);
      Alert({ type: AlertType.WARNING, message: "Unspecified Group Member" });
      return;
    }

    if (graphQLErrors)
      graphQLErrors.forEach(({ extensions, message, locations, path }) => {
        LogGraphQLError(message, extensions, locations, path);
      });

    if (networkError) {
      LogNetworkError(networkError);
    }
  };
