import { Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MethodSettingsProps } from "../shared";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export const DefaultVSpinSettings = ({
  twelve24TCInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        sx={{ width: "100%" }}
        classes={{
          label: classes.fullWidth,
        }}
        control={
          <Checkbox
            checked={
              twelve24TCInternalState.methodSettings.defaultVSpinSettings
            }
            onChange={(e) =>
              handleMethodSettingsChanged(
                "defaultVSpinSettings",
                e.target.checked
              )
            }
            name="checkedB"
            color="primary"
            size="small"
          />
        }
        label={"Enable Default VSpin Settings?"}
      />
    </div>
  );
};

export const DefaultVSpinSettingsDict = new Map<string, number>([
  ["spinParamGForce", 161],
  ["spinParamPercent", 40],
  ["spinTime", 4],
  ["spinAccel", 10],
  ["spinDecel", 10],
]);
