export const THEMES = {
  DARK: "dark",
  LIGHT: "light",
};

export const COLORS = {
  BLACK: "black",
  WHITE: "white",
  GRAY: "gray",
};

export const COLOR_TYPE = {
  BACKGROUND: "background",
  TEXT: "text",
};
