import {
  ImageExportFormat,
  PlateMetadataInput,
} from "graphql/generated/schema-types";
import * as Yup from "yup";

export const PlateMetaDataValidationSchema =
  Yup.object().shape<PlateMetadataInput>({
    sourcePlateBarcode: Yup.string().required(),
    experimentSetting: Yup.string().notRequired(),
    imageExportSetting: Yup.number()
      .min(1, "Must be greater than 0")
      .max(16, "Must be less than 17")
      .nullable()
      .notRequired(),
    imageExportFormat: Yup.string<ImageExportFormat>().nullable().notRequired(),
    analysisSetting: Yup.string().nullable().notRequired(),
  });
