import {
  DirectoryFolder,
  DirectoryFolderKeyInput,
  NodeType,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export const GetTreeNodeKey = (node: Maybe<{ type: NodeType; id: number }>) => {
  return JSON.stringify({
    type: node?.type,
    id: node?.id,
  });
};
export const searchFolderTree = (
  search: string,
  graph: Array<DirectoryFolder>,
  result: Array<DirectoryFolder>
) => {
  for (const node of graph) {
    const folderFound = node?.name
      ?.toLowerCase()
      .includes(search.toLowerCase());
    if (folderFound) {
      result.push(node);
    } else
      searchFolderTree(
        search,
        (node?.subFolders ?? []) as DirectoryFolder[],
        result
      );
  }
};

export const BuildBreadCrumbs = (
  key: DirectoryFolderKeyInput,
  graph: Array<DirectoryFolder>,
  result: Array<DirectoryFolder>
) => {
  for (const directoryFolder of graph) {
    if (GetTreeNodeKey(directoryFolder) === GetTreeNodeKey(key)) {
      result.push(directoryFolder);
      return true;
    } else {
      const found = BuildBreadCrumbs(
        key,
        (directoryFolder?.subFolders ?? []) as DirectoryFolder[],
        result
      );
      if (found) {
        result.push(directoryFolder);
        return true;
      }
    }
  }
  return result.length === 0 ? false : result;
};
