import { useReactiveVar } from "@apollo/client";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { TubeLabellerState } from "../state/initial-state";

const StyledRack = styled.div`
  height: 250px;
  width: 350px;
  padding: 15px;
  box-shadow: 0px 0 4px -1px black;
  // background-color: white;
  border-radius: 5px;
  border: 1px solid #ded7e9;
`;

const StyledRackElements = styled.div<{
  cols: number[];
  rows: number[];
}>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 4px 4px;
  grid-template-columns: repeat(${(props) => props.cols.length + 1}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows.length + 1}, 1fr);
  justify-items: center;
  align-items: center;
`;

const RackHeaderCell = styled(Typography)<{
  rowStart: number;
  colStart: number;
}>`
  display: flex;
  grid-row-start: ${(props) => `${props.rowStart}`};
  grid-column-start: ${(props) => `${props.colStart}`};
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 5px;
`;

const RackWell = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid black;
`;

interface RackProps {
  rackIndex: number;
}

export const Rack = ({ rackIndex }: RackProps) => {
  const tubeLabellerState = useReactiveVar(TubeLabellerState);
  const plateRows = [1, 2, 3, 4, 5, 6, 7, 8];
  const plateCols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  console.log(tubeLabellerState);
  const checkIfWellIsSelected = (rowIndex: number, colIndex: number) => {
    if (
      tubeLabellerState.worklist.findIndex(
        (e) =>
          e.dSTRack === rackIndex &&
          e.dSTPosition ===
            getWellPositionFromRowAndColIndex(rowIndex, colIndex)
      ) > -1
    ) {
      return "red";
    } else {
      return "";
    }
  };
  const getWellPositionFromRowAndColIndex = (
    rowIndex: number,
    colIndex: number
  ) => {
    return String.fromCharCode(65 + rowIndex) + String(colIndex + 1);
  };

  return (
    <>
      <StyledRack>
        <StyledRackElements rows={plateRows} cols={plateCols}>
          <RackHeaderCell rowStart={1} colStart={1}></RackHeaderCell>
          {plateRows.map((row, index) => (
            <RackHeaderCell key={row} rowStart={row + 1} colStart={1}>
              <p
                style={{
                  fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
                }}
              >
                {String.fromCharCode(64 + row)}
              </p>
            </RackHeaderCell>
          ))}
          {plateCols.map((col, index) => (
            <RackHeaderCell key={col} rowStart={1} colStart={col + 1}>
              <p
                style={{
                  fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
                }}
              >
                {index + 1}
              </p>
            </RackHeaderCell>
          ))}
          {plateRows.map((rowItem, rowIndex) =>
            plateCols.map((colItem, colIndex) => (
              <div key={rowItem + "" + colItem}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  border: `1px solid ${
                    localStorage.getItem("theme")?.includes("light")
                      ? "black"
                      : "#454545"
                  }`,
                  backgroundColor: `${checkIfWellIsSelected(
                    rowIndex,
                    colIndex
                  )}`,
                }}
              ></div>
            ))
          )}
        </StyledRackElements>
      </StyledRack>
    </>
  );
};
