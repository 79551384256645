import {
  TicketFragment,
  TicketPriority,
  TicketStatus,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import { Column } from "react-table";
import { Pill } from "shared-components/styled-components";
import { GetAssetTypeFilter } from "shared-components/Table/Filters/AssetTypeFilter";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { GetGroupFilter } from "shared-components/Table/Filters/GroupFilter";
import { GetMultiSelectFilter } from "shared-components/Table/Filters/MultiCheckboxFilter";
import { NumberFilter } from "shared-components/Table/Filters/NumberFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import { UserCell } from "shared-components/Table/sub-components/CacheLookupCells";
import { MultipleUserCell } from "shared-components/Table/sub-components/MultipleUserCell";

export const TicketPriorityReducer = (
  priority: Maybe<TicketPriority> | undefined
) => {
  switch (priority) {
    case TicketPriority.Immediate:
      return <Pill color={"red"} label={"IMMEDIATE"} />;
    case TicketPriority.High:
      return <Pill color={"orange"} label={"HIGH"} />;
    case TicketPriority.Medium:
      return <Pill color={"green"} label={"MEDIUM"} />;
    case TicketPriority.Low:
      return <Pill color={"gray"} label={"LOW"} />;
    default:
      return null;
  }
};

export const TicketColumns: Array<Column<TicketFragment>> = [
  {
    Header: "ID",
    accessor: "ticketId",
    Filter: NumberFilter,
    width: "5%",
    disableGroupBy: true,
  },
  {
    Header: "Feature",
    accessor: "asset.asset" as keyof TicketFragment,
    Filter: TextFilter,
    width: "10%",
  },
  {
    Header: "Type",
    accessor: "ticketType.ticketType" as keyof TicketFragment,
    Filter: TextFilter,
    width: "10%",
  },
  {
    Header: "Priority",
    accessor: "priority",
    Filter: GetMultiSelectFilter(
      Object.entries(TicketPriority).map(([name, value]) => ({
        value,
        name,
      }))
    ),
    Cell: ({ value }) => TicketPriorityReducer(value),
    width: "7%",
  },
  {
    Header: "Platform",
    accessor: "asset.assetType.assetType" as keyof TicketFragment,
    Filter: GetAssetTypeFilter(),
    width: "10%",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: GetMultiSelectFilter(
      Object.entries(TicketStatus).map(([name, value]) => ({
        value,
        name,
      }))
    ),
    width: "7%",
    disableGroupBy: true,
  },
  {
    Header: "Created",
    accessor: "dateCreated",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? moment(value).format("ddd M/DD/YY") : null),
    width: "10%",
    disableGroupBy: true,
  },
  {
    Header: "Last Updated",
    accessor: "lastUpdated",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? moment(value).format("ddd M/DD/YY") : null),
    width: "10%",

    disableGroupBy: true,
  },
  {
    Header: "Created By",
    accessor: "createdByUserId",
    Filter: GetUserFilter(true),
    Cell: ({ value }) => (value ? <UserCell userId={value} /> : null),
    width: "10%",
    disableGroupBy: true,
  },
  {
    Header: "Group",
    accessor: "ticketType.userGroup.groupName" as keyof TicketFragment,
    Filter: GetGroupFilter(true),
    width: "10%",
    disableGroupBy: true,
  },
  {
    Header: "Assignees",
    accessor: "ticketAssignees",
    Filter: GetUserFilter(true),
    Cell: ({ value }) => (
      <MultipleUserCell userIds={value?.map((user) => user?.userId)} />
    ),
    width: "15%",
  },
];
