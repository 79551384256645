import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  SourcePlateInfoState,
  Twelve24TCToolActions,
} from "features/WorklistTools/1224TC/state";
import { useAppReduxDispatch } from "hooks/reduxHooks";
interface DiscardSourcePlateProps {
  index: number;
  item: SourcePlateInfoState;
}
export const DiscardSourcePlate = ({
  item,
  index,
}: DiscardSourcePlateProps) => {
  const dispatch = useAppReduxDispatch();
  const discardSrcPlateAndsrcPlateDoesNotExist = 
    item.plateBarcode == "";
  const srcPlateExistsAndNoDiscardSrcPlate =
    item.labwareTypeCode != "" && item.discardSourcePlate === -1;
  return (
    <FormControl
      variant="outlined"
      error={srcPlateExistsAndNoDiscardSrcPlate}
      size="small"
    >
      <InputLabel id="discard-source-plate-label">
        Discard Source Plate
      </InputLabel>
      <Select
        labelId="discard-source-plate-label"
        id="discard-source-plate-select"
        label="Discard Source Plate"
        size="small"
        value={item.discardSourcePlate}
        error={srcPlateExistsAndNoDiscardSrcPlate}
        disabled={discardSrcPlateAndsrcPlateDoesNotExist}
        onChange={(e) => {
          dispatch(
            Twelve24TCToolActions.UPDATE_DISCARD_SOURCE_PLATE({
              index,
              value: e.target.value,
            })
          );
        }}
      >
        <MenuItem value={-1}></MenuItem>
        <MenuItem value={1}>YES</MenuItem>
        <MenuItem value={0}>NO</MenuItem>
      </Select>
      <FormHelperText>
        {srcPlateExistsAndNoDiscardSrcPlate ? "Value can't be undefined" : ""}
      </FormHelperText>
    </FormControl>
  );
};
