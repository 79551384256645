import { Button, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormikHelpers, useFormik } from "formik";
import moment from "moment";
import { ReactNode, useMemo, useState } from "react";
import styled from "styled-components";
import { shouldDisableTime } from "../DateTimePicker";

export type DateShift = {
  days: number;
  hours: number;
  minutes: number;
};
const InputContainer = styled.div`
  margin: 0 10px;
`;
const Form = styled.form`
  display: flex;
  align-items: center;
  margin: 20px;
`;
export const ShiftDate = (date: Date, shift: DateShift, dateOnly = false) => {
  return dateOnly
    ? moment(date).add(shift.days, "days")
    : moment(date)
        .add(shift.days, "days")
        .add(shift.hours, "hours")
        .add(shift.minutes, "minutes");
};
export function Rescheduler({
  onSubmit,
  children,
  currentDate,
}: {
  onSubmit: (values: DateShift, helpers: FormikHelpers<DateShift>) => void;
  currentDate?: Date;
  children?: (values: DateShift) => ReactNode;
}): JSX.Element {
  const initialValues: DateShift = {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  const [datePickerValue, setDatePickerValue] = useState<
    Date | null | undefined
  >(currentDate);

  useMemo(() => setDatePickerValue(currentDate), [currentDate]);

  const { values, setFieldValue, handleSubmit, isSubmitting } =
    useFormik<DateShift>({
      initialValues: initialValues,
      onSubmit,
    });

  const onChange = (newValue: Date | null | undefined) => {
    if (!newValue || isNaN(newValue.valueOf())) return;

    let oldDate = moment(currentDate);
    const date = moment(newValue.toISOString());

    const stripTime = (date: moment.Moment) => {
      return date.clone().startOf("date");
    };

    const oldDateStripped = stripTime(oldDate);
    const dateStripped = stripTime(date);

    const days = dateStripped.diff(oldDateStripped, "days");

    oldDate = oldDate.add(days, "days");
    setFieldValue("days", days);

    const hours = date.diff(oldDate, "hours");
    oldDate = oldDate.add(hours, "hours");
    setFieldValue("hours", hours);

    const minutes = date.diff(oldDate, "minutes");
    setFieldValue("minutes", minutes);

    setDatePickerValue(newValue);
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <InputContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Date"
              value={datePickerValue}
              minutesStep={15}
              onChange={onChange}
              disablePast={true}
              shouldDisableTime={shouldDisableTime}
            />
          </LocalizationProvider>
        </InputContainer>
        <Button
          disabled={isSubmitting}
          type={"submit"}
          color={"secondary"}
          variant={"contained"}
        >
          Reschedule
        </Button>
      </Form>
      {children && children(values)}
    </>
  );
}
