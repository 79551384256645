import { useReactiveVar } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { Process } from "../constants";
import { TubeLabellerState } from "../state/initial-state";
import styled from "styled-components";

const StyledRackHeader = styled.div`
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 1rem;
`;

interface RackHeaderInfoProps {
  rackNumber: number;
}

export const RackHeaderInfo = ({ rackNumber }: RackHeaderInfoProps) => {
  const tubeLabellerState = useReactiveVar(TubeLabellerState);
  const isFibroblastOrBloodProcessing = (process: string) => {
    if (process === Process.BloodProcessing || process === Process.Fibroblast) {
      return true;
    }
    return false;
  };
  const isSalivaProcess = (process: string) => {
    if (process === Process.Saliva) {
      return true;
    }
    return false;
  };
  return (
    <StyledRackHeader>
      <Typography fontWeight={600}>Rack {rackNumber}</Typography>
      {tubeLabellerState.currentRackIndex > rackNumber ? (
        <div>
          <Typography>
            <Box display="inline" fontWeight={600}>Process:</Box>{" "}
            {tubeLabellerState.tubeLabellerInfo[rackNumber - 1]?.process ?? ""}
          </Typography>
          {isFibroblastOrBloodProcessing(
            tubeLabellerState.tubeLabellerInfo[rackNumber - 1]?.process
          ) ? (
            <Typography>
              <Box display="inline" fontWeight={600}>Step:</Box>{" "}
              {tubeLabellerState.tubeLabellerInfo[rackNumber - 1]?.step ?? ""}
            </Typography>
          ) : null}

          {/* {!isSalivaProcess(
            tubeLabellerState.tubeLabellerInfo[rackNumber - 1]?.process
          ) ? ( */}
          <Typography>
            <Box display="inline" fontWeight={600}>Run:</Box> {tubeLabellerState.tubeLabellerInfo[rackNumber - 1]?.run ?? ""}
          </Typography>
          {/* ) : null} */}
        </div>
      ) : null}
    </StyledRackHeader>
  );
};
