import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import axios from "axios";
import { setGoogleSignInTokenInfo } from "features/Authentication/helpers";
import moment from "moment";
import { decodeJwt } from "../helpers/decode-jwt";
import { GetUrl } from "../helpers/get-url";
import { OmitDeep } from "../helpers/object-helpers";

export const CleanTypeNameMiddleWare = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = OmitDeep(operation.variables, ["__typename"]);
  }
  return forward(operation).map((data) => {
    return data;
  });
});
const uri = `${GetUrl()}/graphql`;
export const HttpMiddleWare = createUploadLink({
  uri,
});
export const AuthMiddleWare = setContext(async (_, { headers }) => {
  let token = localStorage.getItem("idToken");
  if (token) {
    const expiresAt = decodeJwt(token).exp;
    const expired = moment.unix(expiresAt).diff(moment(), "milliseconds") <= 0;
    if (expired) {
      const refreshToken = localStorage.getItem("refreshToken");
      await axios
        .post(`${GetUrl()}/auth/refresh-token`, {
          refreshToken: refreshToken,
        })
        .then((response) => {
          console.log("REFRESHED_TOKEN");
          const tokens = response.data;
          setGoogleSignInTokenInfo(tokens);
          token = tokens.id_token;
        });
    }
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const GroupLoginMiddleWare = new ApolloLink((operation, forward) => {
  const labComputerId = localStorage.getItem("labComputerId");
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      "lab-computer-id": labComputerId || null,
    },
  }));

  return forward(operation);
});
