import { TableCell } from "@mui/material";
import {
  useGetGroupsById,
  useGetSystem,
  useGetUsersById,
} from "hooks/cacheAccessHooks";
import React from "react";
import { Pill } from "shared-components/styled-components";

export const UsersCell = ({ userIds }: { userIds?: number[] }) => {
  const users = useGetUsersById(userIds);
  return (
    <TableCell>
      {users?.map((user) => (
        <Pill key={user?.userId} color={"grey"} label={user?.userName ?? ""} />
      ))}
    </TableCell>
  );
};
export const GroupsCell = ({ groupIds }: { groupIds?: number[] }) => {
  const groups = useGetGroupsById(groupIds);
  return (
    <TableCell>
      {groups?.map((group) => (
        <Pill
          key={group?.groupId}
          color={"grey"}
          label={group?.groupName ?? ""}
        />
      ))}
    </TableCell>
  );
};
export const SystemCell = ({ systemId }: { systemId?: number | null }) => {
  const system = useGetSystem(systemId);
  return <TableCell>{system?.systemName}</TableCell>;
};
