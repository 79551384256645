import { PageWithNav } from "features/Navbar/PageWithNav";
import { useIncomingSamplesBackgroundQuery } from "graphql/generated/schema-types";
import styled from "styled-components/macro";
import { IncomingSampleModalsAndDialogs } from "../components/ModelsAndDialogs/IncomingSampleModalsAndDialogs";
import { IncomingSampleScreens } from "../components/Screens/IncomingSampleScreens";
import { IncomingSampleSideBar } from "../components/SideBar";

const PageLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

export function IncomingSamples(): JSX.Element {
  useIncomingSamplesBackgroundQuery();
  return (
    <PageWithNav disableScroll>
      <PageLayout>
        <IncomingSampleSideBar />
        <IncomingSampleScreens />
      </PageLayout>
      <IncomingSampleModalsAndDialogs />
    </PageWithNav>
  );
}
