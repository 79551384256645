import { RunTask, TaskStatusEnum } from "graphql/generated/schema-types";
import _ from "lodash";
import moment from "moment";
import { Column } from "react-table";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { GetGroupFilter } from "shared-components/Table/Filters/GroupFilter";
import { GetMultiSelectFilter } from "shared-components/Table/Filters/MultiCheckboxFilter";
import { SystemFilter } from "shared-components/Table/Filters/SystemFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import { SystemCell } from "shared-components/Table/sub-components/CacheLookupCells";
import { MultipleGroupCell } from "shared-components/Table/sub-components/MultipleGroupCell";
import { MultipleUserCell } from "shared-components/Table/sub-components/MultipleUserCell";

const dropdownOptions = Object.values(TaskStatusEnum).map((value) => ({
  value: value.toUpperCase(),
  name: value,
}));

export const RunActivityTaskColumns: Array<Column<RunTask>> = [
  {
    Header: "Day",
    accessor: "daysFromRunStart",
    width: "5%",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Run Name",
    accessor: (row) => {
      return row.run?.runName ?? "";
    },
    width: "10%",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Task",
    accessor: "activityName",
    Filter: TextFilter,
    width: "24%",
  },
  {
    Header: "Date",
    accessor: "startTimeScheduled",
    Filter: DateRangeFilter,
    Cell: ({ value }) =>
      value ? moment(value).format("dddd, MMMM Do YYYY") : null,
    width: "15%",
  },
  {
    Header: "Group",
    accessor: "groups",
    Filter: GetGroupFilter(true),
    Cell: ({ value }) => (
      <MultipleGroupCell
        groupIds={value?.map((entry) => entry?.groupId) ?? []}
      />
    ),
    width: "14%",
    disableSortBy: true,
  },
  {
    Header: "Assignees",
    accessor: "users",
    Filter: GetUserFilter(true),
    Cell: ({ value }) => (
      <MultipleUserCell userIds={value?.map((entry) => entry?.userId) ?? []} />
    ),
    width: "14%",
    disableSortBy: true,
  },
  {
    Header: "System",
    accessor: "systemAssignedTo",
    Filter: SystemFilter,
    Cell: ({ value }) => (value ? <SystemCell systemId={value} /> : null),
    width: "8%",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => _.startCase(value as string),
    Filter: GetMultiSelectFilter(dropdownOptions),
    width: "10%",
  },
];
