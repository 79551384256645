export enum ThawToolInternalAction {
  SET_DEST_SELECTION_PROCESS,
  SET_RANDOMIZE_WELLS,
  SET_SELECTED_SOURCE_WELLS,
  SET_REQUIRED_WARNINGS,
  SET_OPTIONAL_WARNINGS,
  SET_WORKLIST,
  SET_WORKLIST_PREVIEW_OPEN,
  UPDATE_METHOD_SETTINGS,
  UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
  SET_PLATE_HAS_TUBE_BARCODE_SCAN,
}

export interface IThawToolInternalAction {
  payload: any;
  type: ThawToolInternalAction;
}
