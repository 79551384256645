import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { AddRackOptions } from "../../constants";

//TODO: Remove redundant tube labeler interfaces
interface ResuspensionVolSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isDisabled: boolean;
}

export const ResuspensionVolSelector = ({
  values,
  setFieldValue,
  isDisabled,
}: ResuspensionVolSelectorProps) => {
  return (
    <NumberField
      id="resuspension-vol"
      style={{ width: "100%" }}
      label="Resuspension Vol"
      value={values.resuspensionVol ?? 0}
      onChange={(vol) => setFieldValue("resuspensionVol", parseInt(vol))}
      disabled={isDisabled}
    />
  );
};
