import { UserFragment } from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";
import { UserManagerTableContextMenuHidden } from "./UserTable";

export const handleContextMenu =
  (selected: UserFragment[], row?: Row<UserFragment>) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    const _selected = selected.includes(row!.original)
      ? selected
      : [...selected, row?.original];
    contextMenu.show({
      id: "UserManagerTableContextMenu",
      event: e,
      props: {
        selected: _selected.map((s) => s?.userId),
      },
    });
    UserManagerTableContextMenuHidden(false);
  };

export const useHandleRowClick = (): ((
  rowClicked?: Row<UserFragment>
) => (e: React.MouseEvent) => void) => {
  return useMemo(
    () => (rowClicked?: Row<UserFragment>) => () => {
      if (!rowClicked?.isGrouped && UserManagerTableContextMenuHidden()) {
        console.log("Method Tables Action: ROW_CLICKED");
        // ticketDispatch({
        //   type: TicketActions.OPEN_TICKET_DETAILS,
        //   payload: {
        //     ticketSummaryDialogOpen: {
        //       ticketId: rowClicked?.original.userId,
        //     },
        //   },
        // });
      }
    },
    []
  );
};
