import { GetSortCompare } from "helpers/object-helpers";
import React, { useEffect, useRef } from "react";
import { DefaultTheme } from "styled-components";
import styled from "styled-components/macro";
import { ChatBubble } from "./ChatBubble";

const StyledChatWindow = styled.div`
  width: 100%;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "#ded7e9" : "rgba(0,0,0,0.05)"};
  height: ${({ height }: { theme: DefaultTheme; height?: string }) => height};
  overflow-y: auto;
  padding: 20px 10px;
  border-radius: 10px;
`;

export type ChatEntry = {
  __type: "OriginalPerson" | "Reply";
  message: string;
  timestamp: Date;
  userId: number;
};

export function ChatWindow({
  chat,
  height,
}: {
  chat: ChatEntry[];
  height?: string;
}): JSX.Element {
  const chatOrderedByDate = chat.sort(GetSortCompare("timestamp"));
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [ref, chat.length]);
  return (
    <StyledChatWindow height={height}>
      {chatOrderedByDate.map((entry, idx) => (
        <ChatBubble
          key={idx}
          entry={entry}
          ref={idx === chatOrderedByDate.length - 1 ? ref : undefined}
        />
      ))}
    </StyledChatWindow>
  );
}
