import { FormControlLabel, Switch } from "@mui/material";
import { AddRackOptions } from "features/TubeLabeller/constants";

interface ShowCompletedTasksSwitchProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const ShowCompletedTasksSwitch = ({
  values,
  setFieldValue,
}: ShowCompletedTasksSwitchProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={values.showCompleted}
          onChange={(event) =>
            setFieldValue("showCompleted", event.target.checked)
          }
        />
      }
      label="Show Completed"
    />
  );
};

export default ShowCompletedTasksSwitch;
