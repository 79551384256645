import { GetVisToolUrl } from "../../helpers/get-url";

export interface INavItem {
  displayName: string;
  path?: string;
  target?: string;
  subItems?: INavItem[];
}
export const NavItems: INavItem[] = [
  { displayName: "Dashboard", path: "/" },
  {
    displayName: "Methods",
    subItems: [
      {
        displayName: "Method Dashboard",
        path: "https://nyscf-dashboard.org/",
        target: "_blank",
      },
      { displayName: "Method Manager", path: "/method-manager" },
    ],
  },
  { displayName: " Tickets", path: "/tickets" },
  {
    displayName: "Runs",
    subItems: [
      { displayName: "Run Tasks", path: "/runs" },
      { displayName: "Run Templates", path: "/run-templates" },
    ],
  },
  {
    displayName: "Query",
    subItems: [
      { displayName: "Plates", path: "/plates" },
      { displayName: "Samples", path: "/samples" },
    ],
  },
  { displayName: "Samples", path: "/incoming-samples" },
  { displayName: "Calendar", path: "/calendar" },
  { displayName: "Worklist Tools", path: "/worklist-tool/1" },
  { displayName: "Tube Labeller", path: "/tube-labeller" },
  { displayName: "VisTool", path: GetVisToolUrl(), target: "_blank" },
];
