import {
  PlateMetadata,
  PlateMetadataInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { PlateMetadataInputStub } from "mappings/object-stubs/PlateMetadataInput";

export function PlateMetaDataInputMapper(plateMetadata: Maybe<PlateMetadata>) {
  const _plateMetadata = DeepCopy(plateMetadata);
  return _.pick(
    _plateMetadata,
    _.keys(new PlateMetadataInputStub())
  ) as PlateMetadataInput;
}
