import { AxiosResponse } from "axios";
import {
  TaskPriorityEnum,
  TaskStatusEnum,
  TaskTypeEnum,
} from "graphql/generated/schema-types";
import ax from "./AxiosService";

interface DashboardService {
  getTaskHistory(taskType: string, taskID: number): Promise<AxiosResponse>;
}

export const InitialDashboardTaskState = {
  taskOwnerId: 0,
  taskOwnerName: "",
  taskId: 0,
  taskName: "",
  taskType: TaskTypeEnum.RunTask,
  startDate: "",
  startTime: "",
  endTime: "",
  priority: TaskPriorityEnum.Low,
  status: TaskStatusEnum.Scheduled,
  userAssignedTo: 0,
  groupAssignedTo: 0,
  systemId: 0,
  notes: "",
  methodId: 0,
  startTimeScheduled: "",
  endTimeScheduled: "",
  startTimeActual: "",
  endTimeActual: "",
};

export default {
  getTaskHistory(taskType: string, taskID: number) {
    const params = new URLSearchParams();
    params.append("taskType", taskType);
    params.append("taskID", taskID.toString());

    return ax.get("api/Dashboards/GetTaskHistory", {
      params,
    });
  },
} as DashboardService;
