import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { shouldDisableTime } from "shared-components/DateTimePicker";
import { GenericFieldProps } from "./FeedGroupFormRow";

export interface SelectStartTimeProps extends GenericFieldProps {
  setFieldValue: (field: string, value: string | undefined) => void;
  value: string | undefined | null;
}

export const SelectStartTime = (props: SelectStartTimeProps) => {
  const updateField = (field: string, value: string | undefined) =>
    props.setFieldValue(field, value);

  const onTimeChange = (value: any) => {
    if (value === null || !value.isValid()) {
      return;
    }

    const utcString = value?.toISOString();
    const localDate = new Date(utcString as string);
    const localDateString = moment(localDate).local().format();
    updateField(
      `feedGroupArray[${props.index}].startTimeScheduled`,
      localDateString
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        disabled={props.toggleDisabled}
        label="Start Time"
        onChange={onTimeChange}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={props.handleBlur}
            error={!!props.formikErrors}
            helperText={props.formikErrors}
          />
        )}
        shouldDisableTime={shouldDisableTime}
        value={props.value}
      />
    </LocalizationProvider>
  );
};
