import {
  AppSuiteGroup,
  RunTaskInput,
  FolderStructure,
  RunTemplate,
  TaskPriorityEnum,
  TaskStatusEnum,
  useAddRunTaskMutation,
} from "graphql/generated/schema-types";
import { useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import { StyledCard } from "shared-components/styled-components";
import { RepeatedTaskInfo } from "features/RunTemplate/components/Forms/Create/Task/SlideOver";
import {
  useRunsModalState,
  useRunTaskQueryProvider,
} from "features/Runs/state/hooks";
import { CreateRunTaskForm } from "./Form";
import { getCacheUpdaterFunction, getHandleSubmit } from "./handleSubmit";

export interface CreateRunFormValues {
  runTemplate: RunTemplate | null;
  runName: string;
  folderLocation: FolderStructure | null;
  group: AppSuiteGroup | null;
  startDate: Date | null;
  comments?: string;
}
export const runTaskInitialValues: RunTaskInput = {
  runActivityId: 0,
  runStageId: 0,
  startTimeScheduled: null,
  activityName: "",
  priority: TaskPriorityEnum.Low,
  runTaskAssignedGroups: [],
  runTaskAssignedUsers: [],
  systemAssignedTo: 0,
  status: TaskStatusEnum.Scheduled,
  runId: 0,
  details: { runTaskDetailId: 0, parentId: 0 },
};

export function AddRunTaskModal(): JSX.Element {
  const {
    runsModalState: { selectedRunId },
    runsModalDispatch,
  } = useRunsModalState();
  const { variables } = useRunTaskQueryProvider();
  runTaskInitialValues.runId = selectedRunId;
  const [addTasks] = useAddRunTaskMutation({
    update: getCacheUpdaterFunction(variables),
  });
  const [repeatTaskInfo, setRepeatTaskInfo] = useState<RepeatedTaskInfo>({});
  const handleSubmit = getHandleSubmit(
    repeatTaskInfo,
    addTasks,
    runsModalDispatch
  );
  const windowSize = useWindowSize();
  return (
    <StyledCard
      min_width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "30vw"
          : "40vw"
      }
    >
      <CreateRunTaskForm
        repeatTaskInfoState={{
          state: repeatTaskInfo,
          setState: setRepeatTaskInfo,
        }}
        initialValues={runTaskInitialValues}
        onSubmit={handleSubmit}
      />
    </StyledCard>
  );
}
