import { animated } from "@react-spring/web";
import { useIncomingSampleState } from "features/IncomingSamples/state";
import { useModalTransition } from "shared-components/ModalsAndDialogs/hooks";
import { UpdateSampleCollectionModal } from "../../Forms/Update/SampleCollection/UpdateSampleCollectionModal";
import { UpdateMultipleSampleTaskModal } from "../../Forms/Update/SampleTask/Multiple/UpdateMultipleSampleTaskModal";
import { UpdateSampleTaskModal } from "../../Forms/Update/SampleTask/Single/UpdateSampleTaskModal";
import { CollectionSamples } from "../CollectionSamplesModal/CollectionSamples";
import { IncomingSampleModals } from "../modal.enum";

export function IncomingSampleModalContent(): JSX.Element {
  const {
    incomingSampleState: { currentModal },
  } = useIncomingSampleState();
  const transition = useModalTransition(currentModal);
  return (
    <>
      {transition((props, currentModal) => {
        switch (currentModal) {
          case IncomingSampleModals.CollectionSamples:
            return (
              <animated.div style={props}>
                <CollectionSamples />
              </animated.div>
            );
          case IncomingSampleModals.UpdateTask:
            return (
              <animated.div style={props}>
                <UpdateSampleTaskModal />
              </animated.div>
            );
          case IncomingSampleModals.UpdateMultipleTasks:
            return (
              <animated.div style={props}>
                <UpdateMultipleSampleTaskModal />
              </animated.div>
            );
          case IncomingSampleModals.UpdateCollection:
            return (
              <animated.div style={props}>
                <UpdateSampleCollectionModal />
              </animated.div>
            );
        }
      })}
    </>
  );
}
