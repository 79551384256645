import {
  CalendarStateAction,
  ICalendarStateAction,
  IInstrumentAction,
  InstrumentAction,
} from "./actions";
import { ICalendarState, IInstrumentState } from "./initial-state";

export function InstrumentReducer(
  state: IInstrumentState,
  action: IInstrumentAction
): IInstrumentState {
  switch (action.type) {
    case InstrumentAction.SELECT_INSTRUMENT:
      return {
        ...state,
        instrumentId: action.payload?.instrumentId ?? 0,
        instrumentName: action.payload?.instrumentName ?? "",
      };
    default:
      return state;
  }
}

export function CalendarStateReducer(
  state: ICalendarState,
  action: ICalendarStateAction
): ICalendarState {
  switch(action.type) {
    case CalendarStateAction.SET_STATE:
      return {
        day: action.payload?.day ?? new Date(),
        view: action.payload?.view ?? 'resourceTimelineDay'
      }
      default:
        return state;
  }
}