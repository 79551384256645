import { useReactiveVar } from "@apollo/client";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { PoolingNormalizationToolInternalAction } from "../../state/action";
import { PoolingNormalizationToolInternalState } from "../../state/initial-state";
import { usePoolingNormalizationStateProvider } from "../../state/StateProvider";
import { SeedingPriority_12_24_LabwareTypes } from "./SeedingPriority_12_24_LabwareTypes";
import { SeedingPriority_96_384_LabwareTypes } from "./SeedingPriority_96_384_LabwareTypes";

const DragItem = styled.div`
  padding: 10px;
  width: 98%;
  border-radius: 6px;
  background: ${({ color, theme }) =>
    color ?? theme.palette.primary.main} !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 8px;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

export const SeedingPriorityGroup = () => {
  const poolingInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );

  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();

  const groupedSeedingPriority =
    poolingInternalState.methodSettings.groupedSeedingPriority;
  const seedingPriority_12_24_Well =
    poolingInternalState.methodSettings.seedingPriority_12_24_Well;
  const seedingPriority_96_384_Well =
    poolingInternalState.methodSettings.seedingPriority_96_384_Well;

  const [seedingGroups, setSeedingGroups] = useState<
    { id: string; content: string }[]
  >([]);

  useEffect(() => {
    setSeedingGroups(
      groupedSeedingPriority.map((item, index) => {
        return { id: item.id, content: item.content };
      })
    );
  }, [groupedSeedingPriority]);

  const onGroupDragEnd = (result: any) => {
    const newItems = Array.from(seedingGroups);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setSeedingGroups(newItems);

    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_GROUPED_SEEDING_PRIORITY,
      payload: {
        groupedSeedingPriority: newItems.map((item) => {
          return {
            id: item.id,
            content: item.content,
          };
        }),
      },
    });
  };

  const has12or24WellSeeds = seedingPriority_12_24_Well.length > 0;
  const has96or384WellSeeds = seedingPriority_96_384_Well.length > 0;

  return (
    <DragDropContext onDragEnd={onGroupDragEnd}>
      <Droppable droppableId="groupDroppable">
        {(groupDroppableProvided) => (
          <div
            {...groupDroppableProvided.droppableProps}
            ref={groupDroppableProvided.innerRef}
          >
            {seedingGroups.map((groupItem, index) => (
              <Draggable
                key={groupItem.id}
                draggableId={groupItem.id}
                index={index}
                isDragDisabled={!has12or24WellSeeds || !has96or384WellSeeds}
              >
                {(groupProvided, snapshot) => (
                  <DragItem
                    ref={groupProvided.innerRef}
                    {...groupProvided.draggableProps}
                    {...groupProvided.dragHandleProps}
                  >
                    <Typography>{groupItem.content}</Typography>
                    {groupItem.id === "12/24W" && has12or24WellSeeds ? (
                      <SeedingPriority_12_24_LabwareTypes />
                    ) : groupItem.id === "96/384W" && has96or384WellSeeds ? (
                      <SeedingPriority_96_384_LabwareTypes />
                    ) : null}
                  </DragItem>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
