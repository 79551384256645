import { Type } from "typescript";

export const ArrayContainsDuplicateValue = (values: Array<Type>) => {
  const set = new Set();
  for (const value of values) {
    if (set.has(value)) return true;
    set.add(value);
  }

  return false;
};

export const allElementsAreEqual = <T>(values: T[]) => {
  const set = new Set(values);
  if (set.size === 1) return true;
  return false;
};
