import { GetArrayMethodLabwareTypesQuery } from "graphql/generated/schema-types";
import * as _ from "lodash";
import ThawWorklistToolService from "services/ThawWorklistToolService";
import { SourcePlateInfoState } from "../PoolingNormalization/state";
import { wellColors } from "./constants";
import {
  BaseDestPlateInfo,
  PlatePosition,
  SelectionType,
  SourceWellInfo,
  WellSelection,
  WorklistValues,
} from "./interfaces";

export const applyRandomizeWells = (
  wellMapping: WorklistValues[],
  randomizedWells: WellSelection[]
): WorklistValues[] => {
  for (let i = 0; i < wellMapping.length; i++) {
    wellMapping[i].sourceWellId = randomizedWells[i].plateWellId;
  }

  return wellMapping;
};

export const getDestinationPlateBarcode = (
  plateBarcode: string,
  destPlateInfo: any[]
) => {
  let plateIndex = parseInt(
    plateBarcode.substring(plateBarcode.length - 1, plateBarcode.length)
  );
  while (destPlateInfo.findIndex((e) => e.plateBarcode === plateBarcode) >= 0) {
    plateBarcode = `${plateBarcode.substring(
      0,
      plateBarcode.length - 1
    )}${plateIndex}`;
    plateIndex++;
  }
  return plateBarcode;
};

export const setDestPlateType = (plateTypeInfo: any) => {
  // const plateTypeInfo = {
  //   destRows: [1],
  //   destCols: [1],
  //   labwarePlateType: "",
  //   operatingVol: 0,
  // };
  switch (plateTypeInfo.labwareTypeCode) {
    case "Cos_12_FL_L_3513":
      plateTypeInfo.labwarePlateType = "C12W";
      break;
    case "Cos_24_FL_L_3526":
      plateTypeInfo.labwarePlateType = "C24W";
      break;
    case "Cos_48_FL_L_3548":
      plateTypeInfo.labwarePlateType = "C48W";
      break;
    case "Cos_96_FL_L_3599":
      plateTypeInfo.labwarePlateType = "C96W";
      break;
    case "PE_96_FL_L_6005182":
      plateTypeInfo.labwarePlateType = "C96W";
      break;
    case "PE_96_FL_L_6055300":
      plateTypeInfo.labwarePlateType = "CCU";
      break;
    case "Cos_96_Rb_L_3879":
      plateTypeInfo.destRows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateTypeInfo.destCols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateTypeInfo.labwarePlateType = "C96W";
      plateTypeInfo.operatingVol = 200;
      break;
    case "Matrix_96_Cryo_L_3740":
      plateTypeInfo.destRows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateTypeInfo.destCols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateTypeInfo.labwarePlateType = "1TC";
      plateTypeInfo.operatingVol = 500;
      break;
    case "Cos_96_RB_L_3598":
      plateTypeInfo.labwarePlateType = "C96W";
      break;
    case "Cos_96_DW_RB_L_3958":
      plateTypeInfo.labwarePlateType = "C96W";
      break;
    case "EK_24_Vb_L_2053":
      plateTypeInfo.labwarePlateType = "C24W";
      break;
    case "Cellvis_384_FL_L_P38415HN":
      plateTypeInfo.labwarePlateType = "C384W";
      break;
    case "Cos_384_RB_L_3830":
      plateTypeInfo.labwarePlateType = "C384W";
      break;
    case "PE_384_FL_L_6057300":
      plateTypeInfo.labwarePlateType = "CCU384";
      break;
    default:
      plateTypeInfo.labwarePlateType = "C96W";
      break;
  }
  return plateTypeInfo;
};

export const getPlateCode = (labwareTypeCode: string, destPlateCode = "") => {
  if (destPlateCode !== "") {
    return destPlateCode;
  }
  switch (labwareTypeCode) {
    case "Cos_12_FL_L_3513":
      return "C12W";
    case "Cos_24_FL_L_3526":
      return "C24W";
    case "Cos_48_FL_L_3548":
      return "C48W";
    case "Cos_96_FL_L_3599":
      return "C96W";
    case "PE_96_FL_L_6005182":
      return "C96W";
    case "PE_96_FL_L_6055300":
      return "CCU";
    case "Cos_96_Rb_L_3879":
      return "C96W";
    case "Matrix_96_Cryo_L_3740":
      return "1TC";
    case "Cos_96_RB_L_3598":
      return "C96W";
    case "Cos_96_DW_RB_L_3958":
      return "C96W";
    case "EK_24_Vb_L_2053":
      return "C24W";
    case "Cellvis_384_FL_L_P38415HN":
      return "C384W";
    case "Cos_384_RB_L_3830":
      return "C384W";
    case "PE_384_FL_L_6057300":
      return "CCU384";
    default:
      return "C96W";
  }
};

export const getDestinationPlateBarcodeWithStringArray = (
  plateBarcode: string,
  existingPlateBarcodes: string[]
) => {
  let plateIndex = 1;
  while (existingPlateBarcodes.findIndex((e) => e == plateBarcode) >= 0) {
    plateBarcode = `${plateBarcode.substring(
      0,
      plateBarcode.length - 1
    )}${plateIndex}`;
    plateIndex++;
  }
  return plateBarcode;
};

export const getDestinationPlateBarcodeSeries = (
  plateBarcode: string,
  numberOfDestPlates: number
) => {
  const plateBarcodes = [];
  let index = 1;
  while (index <= numberOfDestPlates) {
    plateBarcodes.push(
      `${plateBarcode.substring(0, plateBarcode.length - 1)}${index}`
    );
    index++;
  }
  return plateBarcodes;
};
//TODO: Break these functions out into seperate helper files after pooled samples tool is merged
export const removeUnusedSourceWells = (
  templateMapping: any[],
  existingSourceWellIds: string[]
) => {
  templateMapping = templateMapping.filter((e) =>
    existingSourceWellIds.includes(e.sourceWellId)
  );
  return templateMapping;
};

export const getSourcePlateBarcodeFromIntermediatePlateBarcode = (
  intermediatePlateBarcode: string
) => {
  const splitPlateBarcode = intermediatePlateBarcode.split("_");
  const runName = splitPlateBarcode[0];
  const plateCodeSplit = splitPlateBarcode[1].split("-");
  const plateCode = plateCodeSplit[0].substring(3);
  const runSeries = splitPlateBarcode[2];
  return `${runName}_${plateCode}_${runSeries}`;
};

export const extractPlateCode = (plateBarcode: string) => {
  let plateCode = "";
  const split = plateBarcode.split("_");
  if (split[1]) {
    plateCode = split[1];
  }
  return plateCode;
};

export const getLargestPlateBarcodeSeries = (
  sourcePlateInfo: SourcePlateInfoState[] | SourcePlateInfoState[]
) => {
  const usedPlatesBarcodes = sourcePlateInfo.filter(
    (e) => e.plateBarcode !== ""
  );
  usedPlatesBarcodes.sort((a, b) => {
    // EDEV097_C12W_101 > 101
    const getSeries = (plateBarcode: string) =>
      parseInt(plateBarcode.split("_")[2]);

    const aPlateSeries = getSeries(a.plateBarcode);
    const bPlateSeries = getSeries(b.plateBarcode);

    return bPlateSeries - aPlateSeries;
  });
  return usedPlatesBarcodes[0].plateBarcode;
};

export const getPlateCodeForSameLabwareType = (
  labwareType: string,
  destPlateInfo: BaseDestPlateInfo[]
) => {
  const item = destPlateInfo.find((e) => e.labwareTypeCode === labwareType);
  if (item) {
    return extractPlateCode(item.plateBarcode);
  }
  return null;
};

export const getSourcePlateTypeInfo = (
  plateTypeInfo: GetArrayMethodLabwareTypesQuery
) => {
  const sourcePlateTypes = plateTypeInfo.arrayMethodLabwareTypes?.filter(
    (e) => e?.arrayMethodPlateRoleId === 1
  );
  const sourcePlateTypeInfo = sourcePlateTypes?.map((item) => {
    return {
      labwareTypeId: item?.labwareType?.labwareTypeId,
      labwareTypeCode: item?.labwareType?.labwareTypeCode,
      labwareTypeName: item?.labwareType?.labwareTypeName,
      labwareTypeSummary: item?.labwareType?.labwareTypeSummary,
      defaultLabwareVolume: item?.labwareType?.defaultLabwareVolume,
      plateRows: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateRows!
      ),
      plateCols: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateCols!
      ),
    };
  });
  return sourcePlateTypeInfo;
};

export const getDestPlateTypeInfo = (
  plateTypeInfo: GetArrayMethodLabwareTypesQuery
) => {
  const destPlateTypes = plateTypeInfo!.arrayMethodLabwareTypes?.filter(
    (e) => e?.arrayMethodPlateRoleId === 2
  );
  const destPlateTypeInfo = destPlateTypes?.map((item) => {
    return {
      labwareTypeCode: item?.labwareType?.labwareTypeCode,
      labwareTypeName: item?.labwareType?.labwareTypeName,
      labwareTypeSummary: item?.labwareType?.labwareTypeSummary,
      defaultLabwareVolume: item?.labwareType?.defaultLabwareVolume,
      maxLabwareVolume: item?.labwareType?.maxLabwareVolume,
      plateRows: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateRows!
      ),
      plateCols: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateCols!
      ),
      minLabwareVolume: item?.labwareType?.minLabwareVolume,
      defaultArrayPlateCode:
        item?.labwareType?.defaultArrayPlateCodeNavigation?.arrayPlateCode,
    };
  });
  return destPlateTypeInfo;
};

export const getIntPlateTypeInfo = (
  plateTypeInfo: GetArrayMethodLabwareTypesQuery
) => {
  const intPlateTypes = plateTypeInfo.arrayMethodLabwareTypes?.filter(
    (e) => e?.arrayMethodPlateRoleId === 3
  );
  const intPlateTypeInfo = intPlateTypes?.map((item) => {
    return {
      labwareTypeId: item?.labwareType?.labwareTypeId,
      labwareTypeCode: item?.labwareType?.labwareTypeCode,
      labwareTypeName: item?.labwareType?.labwareTypeName,
      labwareTypeSummary: item?.labwareType?.labwareTypeSummary,
      defaultLabwareVolume: item?.labwareType?.defaultLabwareVolume,
      plateRows: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateRows!
      ),
      plateCols: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateCols!
      ),
    };
  });
  return intPlateTypeInfo;
};

export const getDeadPlateTypeInfo = (
  plateTypeInfo: GetArrayMethodLabwareTypesQuery
) => {
  const deadPlateTypes = plateTypeInfo.arrayMethodLabwareTypes?.filter(
    (e) => e?.arrayMethodPlateRoleId === 4
  );
  const deadPlateTypeInfo = deadPlateTypes?.map((item) => {
    return {
      labwareTypeCode: item?.labwareType?.labwareTypeCode,
      labwareTypeName: item?.labwareType?.labwareTypeName,
      labwareTypeSummary: item?.labwareType?.labwareTypeSummary,
      defaultLabwareVolume: item?.labwareType?.defaultLabwareVolume,
      plateRows: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateRows!
      ),
      plateCols: getArrayFromCount(
        item!.labwareType!.plateLayoutType!.plateCols!
      ),
    };
  });
  return deadPlateTypeInfo;
};

export const getArrayFromCount = (count: number) => {
  const arr = [];
  for (let i = 1; i <= count; i++) {
    arr.push(i);
  }
  return arr;
};

export const getRowAndColCountByLabwareType = (labwareTypeCode: string) => {
  const plateRowCol = {
    rows: [1],
    cols: [1],
    operatingVol: 0,
  };
  switch (labwareTypeCode) {
    case "Cos_12_FL_L_3513":
      plateRowCol.rows = [1, 2, 3];
      plateRowCol.cols = [1, 2, 3, 4];
      plateRowCol.operatingVol = 1000;
      break;
    case "Cos_24_FL_L_3526":
      plateRowCol.rows = [1, 2, 3, 4];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6];
      plateRowCol.operatingVol = 200;
      break;
    case "Cos_48_FL_L_3548":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.operatingVol = 200;
      break;
    case "Cos_96_FL_L_3599":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "Cos_96_VB_L_3960":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "EK_96_Rb_L_2721":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "Cellvis_96_FL_L_P9615HN":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "PE_96_FL_L_6005182":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "PE_96_FL_L_6055300":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "Cos_96_Rb_L_3879":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "Matrix_96_Cryo_L_3740":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "Cos_96_RB_L_3598":
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
      break;
    case "EK_24_Vb_L_2053":
      plateRowCol.rows = [1, 2, 3, 4];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6];
      plateRowCol.operatingVol = 250;
      break;
    case "Cos_384_RB_L_3830":
      plateRowCol.rows = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
      ];
      plateRowCol.cols = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ];
      break;
    case "Cellvis_384_FL_L_P38415HN":
      plateRowCol.rows = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
      ];
      plateRowCol.cols = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ];
      break;
    case "PE_384_FL_L_6057300":
      plateRowCol.rows = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
      ];
      plateRowCol.cols = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24,
      ];
      break;
    default:
      plateRowCol.rows = [1, 2, 3, 4, 5, 6, 7, 8];
      plateRowCol.cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      plateRowCol.operatingVol = 200;
  }
  return plateRowCol;
};

export const checkIfWellIsSelectable = (
  platePosition: PlatePosition,
  plateIndex: number,
  rowIndex: number,
  colIndex: number
) => {
  const well = document.getElementById(
    `${platePosition}${plateIndex}${getPlateWellIdFromRowAndColIndex(
      rowIndex,
      colIndex
    )}`
  );

  return well?.style.backgroundColor === "" && well.style.backgroundImage === ""
    ? false
    : true;
};

export const checkForDoubleSelectionWithMultipleDestSelection = (
  currentWorklistValues: WorklistValues[],
  destSelection: WellSelection[]
): boolean => {
  let hasDoubleSelection = false;
  for (const row of destSelection) {
    if (
      currentWorklistValues.some(
        (e) =>
          e.destPlateIndex === row.plateIndex &&
          e.destWellId === row.plateWellId
      )
    ) {
      hasDoubleSelection = true;
      return hasDoubleSelection;
    }
  }
  return hasDoubleSelection;
};

export const checkForDoubleSelectionWithSingleDestSelection = (
  currentWorklistValues: WorklistValues[],
  destWellPlateIndex: number,
  destWellSelection: string
): boolean => {
  let hasDoubleSelection = false;
  if (
    currentWorklistValues.some(
      (e) =>
        e.destPlateIndex === destWellPlateIndex &&
        e.destWellId === destWellSelection
    )
  ) {
    hasDoubleSelection = true;
    return hasDoubleSelection;
  }
  return hasDoubleSelection;
};

export const checkForStampFit = (
  platePosition: PlatePosition,
  plateIndex: number,
  wellSelection: string
): boolean => {
  if (
    document.getElementById(`${platePosition}${plateIndex}${wellSelection}`)
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkForDuplicateBarcodes = (plateInfo: any) => {
  const seen: any = {};
  const hasDuplicates = plateInfo.some(function (currentObject: any) {
    if (
      seen.hasOwnProperty(currentObject.plateBarcode) &&
      currentObject.plateBarcode !== ""
    ) {
      // Current name is already seen
      return true;
    }

    // Current name is being seen for the first time
    return (seen[currentObject.plateBarcode] = false);
  });
  return hasDuplicates;
};

export const stampSourceToDestination = (
  currentWorklistValues: WorklistValues[],
  selectedSourceWells: WellSelection[],
  selectedDestWell: WellSelection,
  destPlatePosition: PlatePosition,
  allowWellPooling: boolean,
  ignoreStampFitCheck = false
): WorklistValues[] => {
  const wellMapping: WorklistValues[] = [];
  selectedSourceWells = sortByCol(selectedSourceWells);
  let destRowPos = getRowIndexFromSelectedWell(selectedDestWell.plateWellId);
  let destColPos = getColIndexFromSelectedWell(selectedDestWell.plateWellId);

  const startingRowPos = destRowPos;
  const startingColPos = destColPos;

  for (let i = 0; i < selectedSourceWells.length; i++) {
    const hasDoubleSelection = allowWellPooling
      ? false
      : checkForDoubleSelectionWithSingleDestSelection(
          currentWorklistValues,
          selectedDestWell.plateIndex,
          getPlateWellIdFromRowAndColIndex(destRowPos, destColPos)
        );
    const canFitStamp =
      ignoreStampFitCheck ||
      checkForStampFit(
        destPlatePosition,
        selectedDestWell.plateIndex,
        getPlateWellIdFromRowAndColIndex(destRowPos, destColPos)
      );

    if (hasDoubleSelection || !canFitStamp) {
      wellMapping.length = 0;
      break;
    }

    if (selectedSourceWells[i].isSelectable) {
      wellMapping.push({
        sourcePlateIndex: selectedSourceWells[i].plateIndex,
        sourceWellId: selectedSourceWells[i].plateWellId,
        destPlateIndex: selectedDestWell.plateIndex,
        destWellId: getPlateWellIdFromRowAndColIndex(destRowPos, destColPos),
        transferVol: "",
      });
    }
    if (i + 1 <= selectedSourceWells.length - 1) {
      destRowPos =
        startingRowPos +
        getRowIndexFromSelectedWell(selectedSourceWells[i + 1].plateWellId) -
        getRowIndexFromSelectedWell(selectedSourceWells[0].plateWellId);
      destColPos =
        startingColPos +
        getColIndexFromSelectedWell(selectedSourceWells[i + 1].plateWellId) -
        getColIndexFromSelectedWell(selectedSourceWells[0].plateWellId);
    }
  }
  return wellMapping;
};

export const applyStampShapeToSource = (
  selectedSourceWell: WellSelection,
  stampShape: string[],
  ignoreStampFitCheck = false
) => {
  const srcRowPos = getRowIndexFromSelectedWell(selectedSourceWell.plateWellId);
  const srcColPos = getColIndexFromSelectedWell(selectedSourceWell.plateWellId);
  const startingRowPos = srcRowPos;
  const startingColPos = srcColPos;

  const stampedSrcWells: WellSelection[] = [];

  stampedSrcWells.push({
    plateIndex: selectedSourceWell.plateIndex,
    plateWellId: selectedSourceWell.plateWellId,
    isSelectable: true,
  });
  for (let i = 1; i < stampShape.length; i++) {
    const rowDiff =
      startingRowPos +
      getRowIndexFromSelectedWell(stampShape[i]) -
      getRowIndexFromSelectedWell(stampShape[0]);
    const colDiff =
      startingColPos +
      getColIndexFromSelectedWell(stampShape[i]) -
      getColIndexFromSelectedWell(stampShape[0]);
    const wellId = getPlateWellIdFromRowAndColIndex(rowDiff, colDiff);
    const canFitStamp =
      ignoreStampFitCheck ||
      checkForStampFit(
        PlatePosition.Intermediate,
        selectedSourceWell.plateIndex,
        wellId
      );

    if (!canFitStamp) {
      stampedSrcWells.length = 0;
      break;
    }
    stampedSrcWells.push({
      plateIndex: selectedSourceWell.plateIndex,
      plateWellId: getPlateWellIdFromRowAndColIndex(rowDiff, colDiff),
      isSelectable: checkIfWellIsSelectable(
        PlatePosition.Intermediate,
        selectedSourceWell.plateIndex,
        rowDiff,
        colDiff
      ),
    });
  }
  return stampedSrcWells;
};

export const stampTo384WDestination = (
  currentWorklistValues: WorklistValues[],
  selectedSourceWells: WellSelection[],
  selectedDestWell: WellSelection,
  destPlatePosition: PlatePosition,
  allowWellPooling: boolean,
  ignoreStampFitCheck = false
) => {
  const wellMapping: WorklistValues[] = [];
  selectedSourceWells = sortByCol(selectedSourceWells);
  let destRowPos = getRowIndexFromSelectedWell(selectedDestWell.plateWellId);
  let destColPos = getColIndexFromSelectedWell(selectedDestWell.plateWellId);

  const startingRowPos = destRowPos;
  const startingColPos = destColPos;

  let rowIterator = 0;
  let colIterator = 0;

  for (let i = 0; i < selectedSourceWells.length; i++) {
    const hasDoubleSelection = allowWellPooling
      ? false
      : checkForDoubleSelectionWithSingleDestSelection(
          currentWorklistValues,
          selectedDestWell.plateIndex,
          getPlateWellIdFromRowAndColIndex(destRowPos, destColPos)
        );
    const canFitStamp =
      ignoreStampFitCheck ||
      checkForStampFit(
        destPlatePosition,
        selectedDestWell.plateIndex,
        getPlateWellIdFromRowAndColIndex(destRowPos, destColPos)
      );

    if (hasDoubleSelection || !canFitStamp) {
      wellMapping.length = 0;
      break;
    }

    if (
      selectedSourceWells[i].isSelectable ||
      destPlatePosition === PlatePosition.Intermediate
    ) {
      wellMapping.push({
        sourcePlateIndex: selectedSourceWells[i].plateIndex,
        sourceWellId: selectedSourceWells[i].plateWellId,
        destPlateIndex: selectedDestWell.plateIndex,
        destWellId: getPlateWellIdFromRowAndColIndex(destRowPos, destColPos),
        transferVol: "",
      });
    }
    if (i + 1 <= selectedSourceWells.length - 1) {
      const startingRowIndex = getRowIndexFromSelectedWell(
        selectedSourceWells[0].plateWellId
      );
      const currentRowIndex = getRowIndexFromSelectedWell(
        selectedSourceWells[i].plateWellId
      );
      const nextRowIndex = getRowIndexFromSelectedWell(
        selectedSourceWells[i + 1].plateWellId
      );

      const startingColIndex = getColIndexFromSelectedWell(
        selectedSourceWells[0].plateWellId
      );
      const currentColIndex = getColIndexFromSelectedWell(
        selectedSourceWells[i].plateWellId
      );
      const nextColIndex = getColIndexFromSelectedWell(
        selectedSourceWells[i + 1].plateWellId
      );
      if (startingRowIndex === nextRowIndex) {
        rowIterator = 0;
      } else if (nextRowIndex > startingRowIndex) {
        rowIterator = nextRowIndex - startingRowIndex;
      } else if (currentRowIndex !== nextRowIndex) {
        rowIterator++;
      }
      if (startingColIndex === nextColIndex) {
        colIterator = 0;
      } else if (nextColIndex > startingColIndex) {
        colIterator = nextColIndex - startingColIndex;
      } else if (currentColIndex !== nextColIndex) {
        colIterator++;
      }
      destRowPos =
        rowIterator + startingRowPos + nextRowIndex - startingRowIndex;
      destColPos =
        colIterator + startingColPos + nextColIndex - startingColIndex;
    }
  }
  return wellMapping;
};

export const arrangeColWise = async (
  currentWorklistValues: WorklistValues[],
  selectedSourceWells: WellSelection[],
  selectedDestWell: WellSelection,
  destRows: number[],
  destCols: number[],
  destPlatePosition: PlatePosition,
  allowWellPooling: boolean
) => {
  selectedSourceWells = sortByCol(selectedSourceWells);
  const wellMapping: WorklistValues[] = [];
  let selectedDestRowPos = getRowIndexFromSelectedWell(
    selectedDestWell.plateWellId
  );
  let selectedDestColPos = getColIndexFromSelectedWell(
    selectedDestWell.plateWellId
  );

  for (const well of selectedSourceWells) {
    if (selectedDestRowPos >= destRows.length) {
      selectedDestRowPos = 0;
      selectedDestColPos++;
    }
    if (selectedDestRowPos > destRows.length) {
      selectedDestRowPos = 0;
    }

    const hasDoubleSelection = allowWellPooling
      ? false
      : checkForDoubleSelectionWithSingleDestSelection(
          currentWorklistValues,
          selectedDestWell.plateIndex,
          getPlateWellIdFromRowAndColIndex(
            selectedDestRowPos,
            selectedDestColPos
          )
        );
    const canFitStamp = checkForStampFit(
      destPlatePosition,
      selectedDestWell.plateIndex,
      getPlateWellIdFromRowAndColIndex(selectedDestRowPos, selectedDestColPos)
    );

    if (hasDoubleSelection || !canFitStamp) {
      wellMapping.length = 0;
      break;
    }
    if (well.isSelectable) {
      wellMapping.push({
        sourcePlateIndex: well.plateIndex,
        sourceWellId: well.plateWellId,
        destPlateIndex: selectedDestWell.plateIndex,
        destWellId: getPlateWellIdFromRowAndColIndex(
          selectedDestRowPos,
          selectedDestColPos
        ),
        transferVol: "",
      });
      selectedDestRowPos++;
    }
  }
  return wellMapping;
};

export const arrangeRowWise = async (
  currentWorklistValues: WorklistValues[],
  selectedSourceWells: WellSelection[],
  selectedDestWell: WellSelection,
  destRows: number[],
  destCols: number[],
  destPlatePosition: PlatePosition,
  allowWellPooling: boolean
) => {
  selectedSourceWells = sortByRow(selectedSourceWells);
  const wellMapping: WorklistValues[] = [];
  let selectedDestRowPos = getRowIndexFromSelectedWell(
    selectedDestWell.plateWellId
  );
  let selectedDestColPos = getColIndexFromSelectedWell(
    selectedDestWell.plateWellId
  );

  for (const well of selectedSourceWells) {
    if (selectedDestColPos >= destCols.length) {
      selectedDestColPos = 0;
      selectedDestRowPos++;
    }
    if (selectedDestRowPos > destRows.length) {
      selectedDestRowPos = 0;
    }
    const hasDoubleSelection = allowWellPooling
      ? false
      : await checkForDoubleSelectionWithSingleDestSelection(
          currentWorklistValues,
          selectedDestWell.plateIndex,
          getPlateWellIdFromRowAndColIndex(
            selectedDestRowPos,
            selectedDestColPos
          )
        );
    const canFitStamp = checkForStampFit(
      destPlatePosition,
      selectedDestWell.plateIndex,
      getPlateWellIdFromRowAndColIndex(selectedDestRowPos, selectedDestColPos)
    );

    if (hasDoubleSelection || !canFitStamp) {
      wellMapping.length = 0;
      break;
    }
    if (well.isSelectable) {
      wellMapping.push({
        sourcePlateIndex: well.plateIndex,
        sourceWellId: well.plateWellId,
        destPlateIndex: selectedDestWell.plateIndex,
        destWellId: getPlateWellIdFromRowAndColIndex(
          selectedDestRowPos,
          selectedDestColPos
        ),
        transferVol: "",
      });
      selectedDestColPos++;
    }
  }
  return wellMapping;
};

export const randomizeSelectedWells = async (
  selectedWells: WellSelection[]
) => {
  let wells = [...selectedWells];
  wells = _.shuffle(wells);
  return wells;
};

export const sortByRow = (selectedSourceWells: WellSelection[]) => {
  return selectedSourceWells.sort((a, b) => {
    const aRowVal = a["plateWellId"].charCodeAt(0);
    const bRowVal = b["plateWellId"].charCodeAt(0);

    const aColVal = parseInt(a["plateWellId"].substring(1));
    const bColVal = parseInt(b["plateWellId"].substring(1));

    return aRowVal - bRowVal || aColVal - bColVal;
  });
};

export const sortByCol = (selectedSourceWells: WellSelection[]) => {
  return selectedSourceWells.sort((a, b) => {
    const aColVal = parseInt(a["plateWellId"].substring(1));
    const bColVal = parseInt(b["plateWellId"].substring(1));

    const aRowVal = a["plateWellId"].charCodeAt(0);
    const bRowVal = b["plateWellId"].charCodeAt(0);

    return aColVal - bColVal || aRowVal - bRowVal;
  });
};

export const getPlateWellIdFromRowAndColIndex = (row: number, col: number) => {
  return String.fromCharCode(65 + row) + String(col + 1);
};

export const getRowIndexFromSelectedWell = (plateWellId: string) => {
  return plateWellId.substring(0, 1).charCodeAt(0) - 65;
};

export const getColIndexFromSelectedWell = (plateWellId: string) => {
  return parseInt(plateWellId.substring(1)) - 1;
};

export const is96WellPlate = (labwareTypeCode: string): boolean => {
  const acceptable96WPlateTypes = [
    "Cos_96_FL_L_3599",
    "Cellvis_96_FL_L_P9615HN",
    "PE_96_FL_L_6005182",
    "Matrix_96_Cryo_L_3740",
    "Cos_96_Rb_L_3879",
    "Kuraray_96_Micro_L_RB500400",
    "Cos_96_FL_L_3912",
    "BioRad_96_VB_L_HSP9601",
    "PE_96_FL_L_6055300",
    "Cos_96_DW_RB_L_3958",
    "Porv_96_DW_RB_L_219012",
    "Cos_96_VB_L_3960",
  ];
  if (acceptable96WPlateTypes.includes(labwareTypeCode)) {
    return true;
  }
  return false;
};

export const getWellSelectionType = (
  selectedSourceWells: WellSelection[],
  selectedDestWells: WellSelection[]
) => {
  if (selectedSourceWells.length < 1 && selectedDestWells.length > 0) {
    return SelectionType.NoSourceWellSelected;
  } else if (selectedSourceWells.length > 1 && selectedDestWells.length > 1) {
    return SelectionType.SelectMultipleSourceAndDestWells;
  } else if (selectedSourceWells.length > 1 && selectedDestWells.length === 1) {
    return SelectionType.SelectMultipleSourceAndOneDestWell;
  } else if (
    selectedSourceWells.length === 1 &&
    selectedDestWells.length === 1
  ) {
    return SelectionType.SelectOneSourceAndOneDestWell;
  } else if (selectedSourceWells.length === 1 && selectedDestWells.length > 1) {
    return SelectionType.SelectOneSourceAndMultipleDestWells;
  }
};

export const getSourcePlateInfo = async (
  plateId: number,
  plateBarcode: string,
  onlyActiveWells = true
) => {
  let iterator = 0;
  let wellData: any[] = [];
  await ThawWorklistToolService.getRackInfo(
    plateId,
    plateBarcode,
    onlyActiveWells
  ).then((res) => {
    const sourcePlateInfo = res.data;
    for (const [index, position] of sourcePlateInfo.entries()) {
      let selectedWellColor = "";
      const currentSampleIndex = res.data.findIndex(
        (e: any) => e.sampleID === position.sampleID
      );
      if (currentSampleIndex > -1 && currentSampleIndex !== index) {
        selectedWellColor = res.data[currentSampleIndex].color;
        position.color = selectedWellColor;
      } else {
        iterator++;
        selectedWellColor = wellColors[iterator];
        position.color = selectedWellColor;
      }
    }
    wellData = sourcePlateInfo;
  });
  return wellData;
};

export const setWellColorBySample = (
  containers: any[],
  existingPlateInfo: SourceWellInfo[],
  colorIndex: number
) => {
  const newWellInfo: SourceWellInfo[] = [];
  for (let i = 0; i < containers.length; i++) {
    const current = {
      barcode: containers[i].barcode,
      rowPos: containers[i].rowPos,
      colPos: containers[i].colPos,
      sampleId: containers[i].sample.sampleId,
      sampleBarcode: containers[i].sample.sampleBarcode,
      color: "",
    };
    if (colorIndex > 96) {
      colorIndex = 0;
    }
    let selectedWellColor = "";
    const existingSampleIndex =
      existingPlateInfo.length > 0
        ? existingPlateInfo.findIndex(
            (e) => e.sampleId == containers[i].sample.sampleId
          )
        : -1;
    if (existingSampleIndex > -1) {
      selectedWellColor = existingPlateInfo[existingSampleIndex].color;
      current.color = selectedWellColor;
    } else {
      const currentSampleIndex = containers.findIndex(
        (e) => e.sample.sampleId === containers[i].sample.sampleId
      );
      if (currentSampleIndex > -1 && currentSampleIndex !== i) {
        selectedWellColor = newWellInfo[currentSampleIndex].color;
        current.color = selectedWellColor;
      } else {
        colorIndex++;
        selectedWellColor = wellColors[colorIndex];
        current.color = selectedWellColor;
      }
    }
    newWellInfo.push(current);
  }
  const tuple = {
    colorIndex,
    newWellInfo,
  };

  return tuple;
};

export const fillSourcePlateWithColors = (rows: number[], cols: number[]) => {
  const wellData = [];
  let rowIndex = 0;
  let colIndex = 0;
  for (let i = 0; i < rows.length * cols.length; i++) {
    wellData.push({
      rowPos: rows[rowIndex],
      colPos: cols[colIndex],
      color: wellColors[i],
    });
    rowIndex++;
    if (rowIndex === rows.length) {
      rowIndex = 0;
      colIndex++;
    }
  }
  return wellData;
};
