import styled from "styled-components";

export const Carousel = styled.div`
  margin: 20px 0;
  height: 60vh;
  min-width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`;
