import { useReactiveVar } from "@apollo/client";
import { Button } from "@mui/material";
import { CloudDownload } from "@mui/icons-material";
import { DeleteButton } from "features/Dashboard/MethodDetails/CeligoScan/Buttons/DeleteButton";
import {
  CeligoMethodAlreadyReserved,
  CeligoPlateMetadata,
} from "features/WorklistTools/CeligoScan/Dialog/state";
import React, { useState } from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;
export const Buttons = ({
  isSubmitting,
  methodReservationId,
}: {
  isSubmitting: boolean;
  methodReservationId?: number;
}): JSX.Element => {
  const deleteStateTuple = useState(false);
  const [deleting] = deleteStateTuple;
  const metadata = useReactiveVar(CeligoPlateMetadata);
  const isReserved = useReactiveVar(CeligoMethodAlreadyReserved);
  return (
    <>
      {metadata.length > 0 ? (
        <ButtonContainer>
          {isReserved ? (
            <DeleteButton
              methodReservationId={methodReservationId}
              deleteStateTuple={deleteStateTuple}
            />
          ) : (
            <React.Fragment />
          )}
          <Button
            variant={"contained"}
            color={"secondary"}
            type={"submit"}
            disabled={isSubmitting || deleting}
            endIcon={
              isReserved ? <CloudDownload fontSize={"large"} /> : undefined
            }
          >
            {isReserved ? "Download Worklist" : "Create Reservation"}
          </Button>
        </ButtonContainer>
      ) : (
        <React.Fragment />
      )}
    </>
  );
};
