import { QueryResult } from "@apollo/client";
import { PlateAction } from "features/Plates/state/actions";
import { usePlateStateProvider } from "features/Plates/state/StateProvider";
import {
  GetPlatesQuery,
  GetPlatesQueryVariables,
  useGetPlatesQuery,
} from "graphql/generated/schema-types";
import { Wrapper } from "interfaces/Wrapper";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

const PlateQueryContext = createContext<
  QueryResult<GetPlatesQuery, GetPlatesQueryVariables> | undefined
>(undefined);

export function PlateContext({ children }: Wrapper): JSX.Element {
  const query = useGetPlatesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      pageSize: 50,
    },
  });

  const params = useParams<{ plateBarcode?: string }>();

  const plate = useMemo(() => {
    return params.plateBarcode ?? "";
  }, [params?.plateBarcode]);

  const { plateDispatch } = usePlateStateProvider();

  useEffect(() => {
    if (plate) {
      console.log("routing by platebarcode", plate);
      plateDispatch({
        type: PlateAction.SELECT_PLATE,
        payload: {
          selectedPlateBarcode: plate,
        },
      });
    }
  }, [plate, plateDispatch]);

  return (
    <PlateQueryContext.Provider value={query}>
      {children}
    </PlateQueryContext.Provider>
  );
}

export const usePlateQueryProvider = () => {
  return useContext(PlateQueryContext);
};
