import { ApolloError } from "@apollo/client";
import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useFormik } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  ArraySystemAutomationMethod,
  LookupArraySystem,
  OperationNames,
  PersonalTaskInput,
  TaskAttachmentInput,
  TaskPriorityEnum,
  TaskStatusEnum,
  useAddPersonalTaskMutation,
  useAddRecurringPersonalTaskMutation,
  useGetArraySystemAutomationMethodsQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import { useMemo, useState } from "react";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/colors/red.css";
import { Alert, AlertType } from "shared-components/toast";
import * as Yup from "yup";
import { DropZone } from "../../../shared-components/DropZone";
import {
  StyledButton,
  StyledContainer,
  StyledForm,
  StyledFormControl,
} from "./AddPersonalTask/AddPersonalTask.styled";
import { AssignedGroups } from "./AddPersonalTask/AssignedGroups";
import { AssignedSystem } from "./AddPersonalTask/AssignedSystem";
import { AssignedUsers } from "./AddPersonalTask/AssignedUsers";
import { IsSystemMethod } from "./AddPersonalTask/IsSystemMethod";
import { MethodTask } from "./AddPersonalTask/MethodTask";
import { SelectOrRepeatDate } from "./AddPersonalTask/SelectOrRepeatDate";

interface AddPersonalTaskProps {
  isOpen: boolean;
  handleClose: () => void;
  calendarDayOfMonth: Maybe<number>;
  calendarMonth: Maybe<number>;
}

export function AddPersonalTask({
  isOpen,
  handleClose,
  calendarDayOfMonth,
  calendarMonth,
}: AddPersonalTaskProps) {
  const [snackbarInfo, setSnackbarInfo] = useState({
    isOpen: false,
    message: "",
    severity: "",
    duration: 0,
  });

  const [addPersonalTask, { error, loading }] = useAddPersonalTaskMutation({
    onError(error: ApolloError) {
      console.log(error);
      setSnackbarInfo({
        isOpen: true,
        message: "Error",
        severity: "error",
        duration: 3000,
      });
    },
  });

  const [addRecurringPersonalTasks] = useAddRecurringPersonalTaskMutation({
    onError(error: ApolloError) {
      setSnackbarInfo({
        isOpen: true,
        message: "Error",
        severity: "error",
        duration: 3000,
      });
    },
  });

  const [attachments, setAttachments] = useState<File[]>([]);

  const handleSubmit = async (values: any) => {
    values.assignedUsers = values!.assignedUsers!.map(
      (user: AppSuiteUser) => user!.userId!
    );

    values.assignedGroups = values!.assignedGroups!.map(
      (group: AppSuiteGroup) => group!.groupId!
    );

    values.selectedDates = values.selectedDates.map(
      (item: any) => new Date(item)
    );

    for (const file of attachments) {
      const twentyMB = 1024 * 1024 * 20;
      if (file.size <= twentyMB) continue;

      return Alert({
        type: AlertType.ERROR,
        message: `${file.name} is too large. (20MB max)`,
      });
    }

    values.personalTaskAttachments = await Promise.all(
      attachments.map(
        async (file) =>
          ({
            name: file.name,
            file: file,
          } as TaskAttachmentInput)
      )
    );

    const refetchQueries = [
      OperationNames.Query.getDashboardTasks,
      OperationNames.Query.getCalendarNodes,
    ];

    const cleanupForm = () => {
      Alert({ type: AlertType.SUCCESS, message: "Personal Task Created" });
      resetForm();
      handleClose();
    };

    switch (values.dateSelection) {
      case 0: // Single Date
        addPersonalTask({
          variables: {
            model: values,
          },
          refetchQueries,
        }).then((res) => {
          if (res.data?.addPersonalTask?.taskId) {
            cleanupForm();
          }
        });
        break;
      case 1: // Repeat Date
        addRecurringPersonalTasks({
          variables: {
            model: values,
          },
          refetchQueries,
        }).then((res) => {
          if (res.data?.addRecurringPersonalTask) {
            cleanupForm();
          }
        });
        break;
      case 2: // Multiple Dates
        addPersonalTask({
          variables: {
            model: values,
          },
          refetchQueries,
        }).then((res) => {
          if (res.data?.addPersonalTask?.taskId) {
            cleanupForm();
          }
        });
        break;
    }
  };

  // TODO: trim whitespace https://stackoverflow.com/questions/60423402/automatically-trim-white-spaces-with-yup-and-formik
  const validators = {
    taskName: Yup.string()
      .required("Task Name required")
      .min(2, "Too Short!")
      .max(75, "75 character max (Add details to notes)"),
    usersAssignedTo: Yup.array(),
    groupsAssignedTo: Yup.array(),
    systemAssignedTo: Yup.number().when("taskTypeSelection", {
      is: 0,
      then: (schema: Yup.NumberSchema<number>) =>
        schema.required("Assigned System required"),
    }),
    dateSelection: Yup.number(),
    startTimeScheduled: Yup.string()
      .nullable()
      .when("dateSelection", {
        is: 0,
        then: (schema: Yup.DateSchema<Date>) =>
          schema.required("Start Time required"),
      }),
    repeatDaysOfWeek: Yup.string()
      .nullable()
      .when("dateSelection", {
        is: 1,
        then: (schema: Yup.StringSchema<string>) =>
          schema.required("Day of Week required"),
      }),
    endRepeatDate: Yup.string()
      .nullable()
      .when("dateSelection", {
        is: 1,
        then: (schema: Yup.StringSchema<string>) =>
          schema.required("End Repeat Date required"),
      }),
    notes: Yup.string(),
    methodId: Yup.number().when("taskTypeSelection", {
      is: 0,
      then: (schema: Yup.NumberSchema<number>) =>
        schema.required("Method Task required"),
    }),
  };

  const now = new Date();
  const momentNow = moment(now);
  momentNow.set("month", calendarMonth ?? now.getMonth());
  momentNow.set("date", calendarDayOfMonth ?? now.getDate());
  momentNow.set("minute", Math.ceil(now.getMinutes() / 15) * 15);
  momentNow.set("second", 0);

  const initialValues: PersonalTaskInput = {
    taskTypeSelection: 1,
    methodId: undefined,
    manualTaskTypeId: null,
    taskName: "",
    taskOwnerId: parseInt(localStorage.userID),
    assignedUsers: [],
    assignedGroups: [],
    systemAssignedTo: undefined,
    priority: TaskPriorityEnum.Low,
    status: TaskStatusEnum.Scheduled,
    dateSelection: 0,
    startTimeScheduled: momentNow.format(),
    endTimeScheduled: momentNow.clone().add(30, "minutes").format(),
    selectedDates: [],
    repeatDaysOfWeek: [],
    endRepeatDate: momentNow.format(),
    notes: "",
  };

  const f = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(validators),
  });
  const values = f.values;
  const setFieldValue = f.setFieldValue;
  const handleChange = f.handleChange;
  const resetForm = f.resetForm;
  const touched = f.touched;
  const errors = f.errors;

  const [automationMethodId, setAutomationMethodId] = useState<number>(0);
  const asamQuery = useGetArraySystemAutomationMethodsQuery();
  const asams = asamQuery?.data?.arraySystemAutomationMethods;

  const systems = useMemo(
    () =>
      asams && automationMethodId !== 0
        ? asams
            ?.filter((asam) => asam?.automationMethodId === automationMethodId)
            .map(
              (asam) =>
                ({
                  systemId: asam?.arraySystemId,
                  systemName: `${asam?.arraySystem?.systemName} (${asam?.automationMethodCode})`,
                } as LookupArraySystem)
            )
        : [],
    [asams, automationMethodId]
  );
  const averageRunTime = useMemo(() => {
    if (!asams?.length || !values.systemAssignedTo) return 0;
    const filteredAsams = asams
      .map((asam) => asam as ArraySystemAutomationMethod)
      .filter(
        (asam) =>
          asam?.automationMethodId === automationMethodId &&
          asam.arraySystemId === values.systemAssignedTo &&
          asam.averageRunTimeSeconds
      );
    return filteredAsams.length
      ? Math.ceil(filteredAsams[0].averageRunTimeSeconds! / 60)
      : 0;
  }, [asams, automationMethodId, values.systemAssignedTo]);

  const isSystemMethod = useMemo(
    () => values.taskTypeSelection === 0,
    [values.taskTypeSelection]
  );

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Add Personal Task</DialogTitle>
      <DialogContent>
        <StyledForm onSubmit={f.handleSubmit}>
          <StyledFormControl>
            <StyledContainer>
              <IsSystemMethod
                setFieldValue={setFieldValue}
                taskTypeSelection={values.taskTypeSelection}
              />
              {isSystemMethod ? (
                <MethodTask
                  setFieldValue={setFieldValue}
                  isSystemMethod={isSystemMethod}
                  setAutomationMethodId={setAutomationMethodId}
                  touched={touched.methodId}
                  errors={errors.methodId}
                />
              ) : null}
              <TextField
                name="taskName"
                label="Task Name"
                variant="outlined"
                autoComplete="off"
                fullWidth
                value={values.taskName}
                onChange={handleChange}
                error={touched.taskName && errors.taskName !== ""}
                helperText={errors.taskName}
              />

              <AssignedUsers setFieldValue={setFieldValue} />
              <AssignedGroups setFieldValue={setFieldValue} />
              {isSystemMethod ? (
                <AssignedSystem
                  setFieldValue={setFieldValue}
                  systems={systems}
                  touched={touched.systemAssignedTo}
                  errors={errors.systemAssignedTo}
                  averageRunTime={averageRunTime}
                />
              ) : null}
              <SelectOrRepeatDate
                setFieldValue={setFieldValue}
                personalTaskInput={values}
                averageRunTime={averageRunTime}
                touched={touched}
                errors={errors}
              />
              <TextField
                id="notes"
                label="Notes"
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                value={values.notes}
                onChange={handleChange}
              />
              {values.dateSelection === 0 ? (
                <DropZone
                  attachments={attachments}
                  setAttachmentsState={setAttachments}
                />
              ) : null}

              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={f.isSubmitting}
              >
                Add Task
              </StyledButton>
            </StyledContainer>
          </StyledFormControl>
        </StyledForm>
      </DialogContent>
    </Dialog>
  );
}

export default AddPersonalTask;
