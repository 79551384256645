import {
  LookupArrayMethodFilterInput,
  MethodFragment,
} from "graphql/generated/schema-types";
import { Filters } from "react-table";

export const MethodFilterReducer = (tableFilters: Filters<MethodFragment>) => {
  const where: LookupArrayMethodFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "methodName":
        where!.and!.push({
          methodName: { contains: tableFilter.value },
        });
        break;
      case "isActive":
        where!.and!.push({
          isActive: { eq: tableFilter.value === "true" },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
