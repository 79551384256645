import { TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import React, { ChangeEvent } from "react";
import { ComboboxFieldProps } from "./types";

type PropType = Omit<
  ComboboxFieldProps<string | number>,
  "defaultValue" | "value"
> & {
  defaultValue?: unknown;
  value?: unknown;
};
export function GetEnumCombobox<T extends { [key: number]: string | number }>(
  e: T
) {
  return ({
    id,
    defaultValue,
    onChange,
    helperText,
    error,
    label,
    variant,
    value,
    multiple,
  }: PropType) => (
    <Autocomplete
      id={id}
      defaultValue={
        multiple
          ? []
          : Object.values(e).filter((status) => status === defaultValue)[0]
      }
      options={Object.values(e) as Array<string | number>}
      value={value}
      fullWidth
      multiple={multiple}
      onChange={
        onChange as (event: ChangeEvent<unknown>, newValue: unknown) => void
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
}
