import { Button, FormControlLabel, Switch } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import {
  AppSuiteGroup,
  UpdateAppSuiteUserInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React from "react";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import {
  GroupField,
  RoleField,
} from "shared-components/FormFields/FormFields";
import { UpdateUserValidationSchema } from "../../validation-schema";

export function EditUserForm({
  initialValues,
  onSubmit,
}: {
  initialValues: UpdateAppSuiteUserInput;
  onSubmit: (
    values: UpdateAppSuiteUserInput,
    helpers: FormikHelpers<UpdateAppSuiteUserInput>
  ) => void;
}): JSX.Element {
  const props = useFormik<UpdateAppSuiteUserInput>({
    initialValues,
    onSubmit,
    validationSchema: UpdateUserValidationSchema,
  });
  const { handleSubmit, isSubmitting, values, errors, touched, setFieldValue } =
    props;
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <FormControlLabel
          label={"Active"}
          labelPlacement={"start"}
          control={
            <Switch
              checked={values.isActive ?? false}
              onChange={(e) => setFieldValue("isActive", e.target.checked)}
            />
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <GroupField
          id={"groups"}
          multiple
          label={"Groups"}
          defaultValue={initialValues.groups ?? []}
          value={values.groups ?? []}
          onChange={(e, newValue) => {
            setFieldValue(
              "groups",
              (newValue as Array<Maybe<AppSuiteGroup>>)?.map(
                (group: Maybe<AppSuiteGroup>) => group?.groupId
              )
            );
          }}
          variant={"outlined"}
          helperText={errors.groups && touched.groups && errors.groups}
          error={!!(errors.groups && touched.groups && errors.groups)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <RoleField
          id={"roles"}
          multiple
          defaultValue={initialValues.roles ?? []}
          value={values.roles ?? []}
          label={"Roles"}
          onChange={(e, newValue) => {
            setFieldValue("roles", newValue);
          }}
          variant={"outlined"}
          helperText={errors.roles && touched.roles && errors.roles}
          error={!!(errors.roles && touched.roles && errors.roles)}
        />
      </StyledFormControl>
      <Button variant={"contained"} type={"submit"} disabled={isSubmitting}>
        Save Changes
      </Button>
    </StyledForm>
  );
}
