import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AddRackOptions, LotNumber } from "../../constants";

interface LotNumberSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const LotNumberSelector = ({
  values,
  setFieldValue,
}: LotNumberSelectorProps) => {
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Lot Number</InputLabel>
      <Select
        labelId="lotnumber-label"
        label="Lot Number"
        id="lotnumber"
        value={values.lotNumberType}
        onChange={(e) => setFieldValue("lotNumberType", e.target.value)}
      >
        <MenuItem value={LotNumber.CurrentActive}>Current Active</MenuItem>
        <MenuItem value={LotNumber.Blank}>Blank</MenuItem>
        <MenuItem value={LotNumber.ExpansionLotNumber}>
          Expansion Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.FibroblastLotNumber}>
          Fibroblast Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.SpitLotNumber}>Spit Lot Number</MenuItem>
        <MenuItem value={LotNumber.ReprogrammingLotNumber}>
          Reprogramming Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.ConsolidationLotNumber}>
          Consolidation Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.MonoclonalizationLotNumber}>
          Monoclonalization Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.GeneEditingLotNumber}>
          Gene Editing Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.BloodProcessingLotNumber}>
          Blood Processing Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.DNAExtractionLotNumber}>
          DNA Extraction Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.ExternalLotNumber}>
          External Lot Number
        </MenuItem>
        <MenuItem value={LotNumber.RPELotNumber}>RPE Lot Number</MenuItem>
        <MenuItem value={LotNumber.OtherLotNumber}>Other Lot Number</MenuItem>
      </Select>
    </FormControl>
  );
};
