import { useReactiveVar } from "@apollo/client";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useAppReduxSelector } from "hooks/reduxHooks";
import { useEffect } from "react";
import { containsStampAndSingleChannelSeeding } from "../../PoolingNormalizationToolHelpers";
import { PoolingNormalizationToolInternalAction } from "../../state/action";
import {
  PoolingNormalizationToolInternalState,
  StampSingleChannelPriority,
} from "../../state/initial-state";
import { usePoolingNormalizationStateProvider } from "../../state/StateProvider";
import { SeedingPriorityGroup } from "./SeedingPriorityGroup";

export const SeedingPriority = () => {
  const poolingNormalizationToolInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );

  const poolingState = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present
  );
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();

  const handleSelectionChange = (value: StampSingleChannelPriority) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_STAMP_SINGLE_CHANNEL_PRIORITY,
      payload: {
        stampSingleChannelPriority: value,
      },
    });
  };

  useEffect(() => {
    if (
      poolingState.destPlateInfo.some((e) => e.preprocess === true) &&
      containsStampAndSingleChannelSeeding(poolingState.worklistValues)
    ) {
      handleSelectionChange(StampSingleChannelPriority.StampFirst);
    }
  }, [
    poolingState.worklistValues,
    poolingState.destPlateInfo,
    poolingNormalizationToolInternalState.stampSingleChannelPriority,
  ]);

  return (
    <>
      {containsStampAndSingleChannelSeeding(poolingState.worklistValues) ? (
        <RadioGroup
          row
          value={
            poolingNormalizationToolInternalState.stampSingleChannelPriority ??
            ""
          }
        >
          <FormControlLabel
            name="StampSingleChannelPriority"
            onChange={() =>
              handleSelectionChange(StampSingleChannelPriority.StampFirst)
            }
            value={StampSingleChannelPriority.StampFirst}
            control={
              <Radio
                color="primary"
                value={StampSingleChannelPriority.StampFirst}
              />
            }
            label="Stamp First"
            disabled={poolingState.destPlateInfo.some(
              (e) => e.preprocess === true
            )}
          />
          <FormControlLabel
            name="taskTypeSelection"
            onChange={() =>
              handleSelectionChange(
                StampSingleChannelPriority.SingleChannelFirst
              )
            }
            value={StampSingleChannelPriority.SingleChannelFirst}
            control={<Radio color="primary" />}
            label="Single Channel Seed First"
            disabled={poolingState.destPlateInfo.some(
              (e) => e.preprocess === true
            )}
          />
        </RadioGroup>
      ) : null}
      {poolingNormalizationToolInternalState.methodSettings
        .seedingPriority_12_24_Well.length > 0 ||
      poolingNormalizationToolInternalState.methodSettings
        .seedingPriority_96_384_Well.length > 0 ? (
        <>
          <Divider sx={{ margin: "20px 0" }} />
          <Typography sx={{ margin: "20px 0" }}>
            Single Channel Seeding Priority
          </Typography>
          <SeedingPriorityGroup />{" "}
        </>
      ) : null}
    </>
  );
};
