import { RouteWithErrorBoundary } from "App.routes";
import { PageWithNav } from "features/Navbar/PageWithNav";
import { TicketModalContainer } from "features/Tickets/components/ModelsAndDialogs/TicketModalContainer";
import { TicketModalContent } from "features/Tickets/components/ModelsAndDialogs/TicketModalContent";
import { TicketTable } from "features/Tickets/components/Table/TicketTable";
import { TicketContext } from "features/Tickets/Context";
import { useTicketBackgroundQuery } from "graphql/generated/schema-types";
import React, { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import { TicketSummaryDialog } from "../components/ModelsAndDialogs/Summary/TicketSummaryDialog";
import { useTicketState } from "../state/hooks";

export const TicketRouteContext: FC = ({ children }) => {
  const match = useRouteMatch();
  const path = `${match.url}/:ticketId?`;
  return (
    <RouteWithErrorBoundary path={path}>{children}</RouteWithErrorBoundary>
  );
};

export function Tickets(): JSX.Element {
  const {
    ticketState: { ticketSummaryDialogOpen },
  } = useTicketState();
  useTicketBackgroundQuery();
  return (
    <TicketRouteContext>
      <TicketContext>
        <PageWithNav>
          <TicketTable />
          {ticketSummaryDialogOpen && <TicketSummaryDialog />}
          <TicketModalContainer>
            <TicketModalContent />
          </TicketModalContainer>
        </PageWithNav>
      </TicketContext>
    </TicketRouteContext>
  );
}
