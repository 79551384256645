import axios from "axios";
import { GetUrl } from "../helpers/get-url";

const ax = axios.create();
ax.defaults.baseURL = GetUrl();
ax.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("idToken")}`,
};
export function setDefaultHeaders(token: string): void {
  ax.defaults.headers.common = {
    Authorization: `Bearer ${token}`,
  };
}
export default ax;
