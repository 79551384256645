import _ from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Filters } from "react-table";
import { RunTask, SortEnumType } from "graphql/generated/schema-types";
import { TablePreset } from "interfaces/AppUserSettings";
import { RunActivityTaskFilterReducer } from "../components/Table/filter";
import { defaultRunTaskFilter, defaultSort } from "../constants";

export const getWhereClause = (defaultPreset?: TablePreset) => {
  let output = defaultRunTaskFilter;
  if (defaultPreset?.state?.filters) {
    output =
      RunActivityTaskFilterReducer(
        (defaultPreset?.state?.filters as Filters<RunTask>) ?? []
      ) ?? defaultRunTaskFilter;
  }
  return output;
};
export const getOrderByClause = (defaultPreset?: TablePreset) => {
  let output = defaultSort;
  if (defaultPreset?.state?.sortBy && defaultPreset.state.sortBy.length > 0)
    output = defaultPreset.state.sortBy.map((sortRule) => {
      const sortObject = {};
      _.set(
        sortObject,
        sortRule.id,
        sortRule.desc ? SortEnumType.Desc : SortEnumType.Asc
      );
      return sortObject;
    });
  return output;
};

export const useGetRunIdFromURL = () => {
  const params = useParams<{ runId?: string }>();
  return useMemo(() => {
    const parsed = parseInt(params?.runId ?? "null");
    return isNaN(parsed) ? null : parsed;
  }, [params?.runId]);
};
