import { Button } from "@mui/material";
import { Delete } from "@mui/icons-material";
import {
  OperationNames,
  useDeleteTaskMethodReservationMutation,
} from "graphql/generated/schema-types";
import React from "react";

export function DeleteButton({
  methodReservationId,
  deleteStateTuple: [deleting, setDeleting],
}: {
  methodReservationId?: number;
  deleteStateTuple: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}): JSX.Element {
  const [deleteReservation] = useDeleteTaskMethodReservationMutation({
    refetchQueries: [
      OperationNames.Query.getMethodReservationByPersonalTaskId,
      OperationNames.Query.getMethodReservationByRunActivityId,
      OperationNames.Query.getCeligoPlateMetadata,
    ],
    awaitRefetchQueries: true,
  });
  return (
    <Button
      onClick={() => {
        setDeleting(true);
        deleteReservation({
          variables: {
            deleteInput: {
              primaryKey: methodReservationId ?? 0,
            },
          },
        }).finally(() => setDeleting(false));
      }}
      disabled={deleting}
      variant={"contained"}
      color={"primary"}
      endIcon={<Delete />}
    >
      Delete Reservation
    </Button>
  );
}
