import {
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Methods } from "enums/Methods";
import { PlatesCreated, RunTask } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useGetUnavailablePlates } from "hooks/useGetUnavailablePlates";
import React, { useState } from "react";
import styled from "styled-components";

const StyledTableHead = styled(TableHead)`
  background-color: rgba(0, 0, 0, 0.2);
`;
const PlateExpandButton = styled(Button)`
  margin: 10px 15px 0 15px !important;
`;
const TableContainer = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export function XTYCustomPassagePlatesTable({
  runActivity,
}: {
  runActivity?: Maybe<RunTask> | null;
}): JSX.Element {
  const unAvailablePlates = useGetUnavailablePlates(Methods.XTY_CustomPassage);

  const [collapse, setCollapse] = useState(true);

  if (!runActivity?.run?.productionRun?.platesCreateds)
    return <React.Fragment />;

  const reserved = runActivity.methodReservations?.[0]?.methodReservationId;
  const _plates = reserved
    ? runActivity?.run?.productionRun?.platesCreateds
    : runActivity?.run?.productionRun?.platesCreateds?.filter(
        (plate) => !unAvailablePlates.includes(plate?.plateBarcode ?? "")
      );

  const getSamples = (plate: Maybe<PlatesCreated>) =>
    plate?.containers?.map((container) => container?.sample?.sampleBarcode);

  const hasActivePlates =
    (_plates
      ?.filter((plate) => plate?.isActive)
      .map((plate) => plate?.plateBarcode).length ?? 0) > 0;

  return (
    <>
      <PlateExpandButton
        color={"inherit"}
        onClick={() => setCollapse((collapse) => !collapse)}
        endIcon={collapse ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
      >
        Active Plates
      </PlateExpandButton>
      <Collapse in={!collapse}>
        <TableContainer>
          <Table size={"small"} style={{ width: "35vw" }}>
            <StyledTableHead>
              {hasActivePlates ? (
                <TableRow>
                  <TableCell align="center">Plates</TableCell>
                  <TableCell align="center">Samples</TableCell>
                </TableRow>
              ) : (
                <React.Fragment />
              )}
            </StyledTableHead>
            <TableBody>
              {_plates?.map((plate, idx) =>
                plate?.isActive ? (
                  <TableRow key={idx}>
                    <TableCell align={"center"}>
                      {plate?.plateBarcode}
                    </TableCell>
                    <TableCell align={"center"}>
                      {[...new Set(getSamples(plate))].join(", ")}
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
}
