import {
  IPoolingNormalizationToolInternalAction,
  PoolingNormalizationToolInternalAction,
} from "./action";
import { IPoolingNormalizationToolInternalState } from "./initial-state";

export function PoolingNormalizationToolInternalReducer(
  state: IPoolingNormalizationToolInternalState,
  action: IPoolingNormalizationToolInternalAction
) {
  switch (action.type) {
    case PoolingNormalizationToolInternalAction.SET_STATE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case PoolingNormalizationToolInternalAction.SET_UPLOADED_INTERMEDIATE_PLATE:
      return {
        ...state,
        uploadedIntermediatePlate: action.payload.uploadedIntermediatePlate,
      };
    case PoolingNormalizationToolInternalAction.SET_DEST_SELECTION_PROCESS:
      return {
        ...state,
        destSelectionProcess: action.payload.destSelectionProcess,
      };
    case PoolingNormalizationToolInternalAction.SET_RANDOMIZE_WELLS:
      return {
        ...state,
        randomizeWells: action.payload.randomizeWells,
      };
    case PoolingNormalizationToolInternalAction.SET_ENABLE_POOLING:
      return {
        ...state,
        enablePooling: action.payload.enablePooling,
      };
    case PoolingNormalizationToolInternalAction.SET__SELECTED_INTERMEDIATE_STAMP_POSITION:
      return {
        ...state,
        selectedIntermediateStampPosition:
          state.selectedIntermediateStampPosition ===
          action.payload.selectedIntermediateStampPosition
            ? -1
            : action.payload.selectedIntermediateStampPosition,
      };
    case PoolingNormalizationToolInternalAction.SET_SELECTED_SOURCE_WELLS:
      return {
        ...state,
        selectedSourceWells: action.payload.selectedSourceWells,
      };
    case PoolingNormalizationToolInternalAction.SET_SELECTED_INTERMEDIATE_WELLS:
      return {
        ...state,
        selectedIntToDestWells: action.payload.selectedIntermediateWells,
      };
    case PoolingNormalizationToolInternalAction.SET_SELECTED_INT1_WELLS:
      return {
        ...state,
        selectedInt1Wells: action.payload.selectedInt1Wells,
      };
    case PoolingNormalizationToolInternalAction.SET_SELECTED_INT2_WELLS:
      return {
        ...state,
        selectedInt2Wells: action.payload.selectedInt2Wells,
      };
    case PoolingNormalizationToolInternalAction.SET_SELECTED_INT3_WELLS:
      return {
        ...state,
        selectedInt3Wells: action.payload.selectedInt3Wells,
      };
    case PoolingNormalizationToolInternalAction.SET_HOVER_WELL_ID:
      return {
        ...state,
        hoverWellId: action.payload.hoverWellId,
      };
    case PoolingNormalizationToolInternalAction.SET_NEXT_STEP:
      return {
        ...state,
        step: state.step + 1,
      };
    case PoolingNormalizationToolInternalAction.SET_PREV_STEP:
      return {
        ...state,
        step: state.step - 1,
      };
    case PoolingNormalizationToolInternalAction.SET_SOURCE_INT_INDEX:
      return {
        ...state,
        sourceIntIndex: action.payload.sourceIntIndex,
      };
    case PoolingNormalizationToolInternalAction.SET_DESTINATION_INT_INDEX:
      return {
        ...state,
        destinationIntIndex: action.payload.destinationIntIndex,
      };
    case PoolingNormalizationToolInternalAction.SET_SELECTED_DESTINATION_INDEX:
      return {
        ...state,
        selectedDestinationIndex: action.payload.selectedDestinationIndex,
      };
    case PoolingNormalizationToolInternalAction.SET_GROUPED_SEEDING_PRIORITY:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          groupedSeedingPriority: action.payload.groupedSeedingPriority,
        },
      };
    case PoolingNormalizationToolInternalAction.SET_12_24_SEEDING_PRIORITY:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          seedingPriority_12_24_Well: action.payload.seedingPriority,
        },
      };
    case PoolingNormalizationToolInternalAction.SET_96_384_SEEDING_PRIORITY:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          seedingPriority_96_384_Well: action.payload.seedingPriority,
        },
      };
    case PoolingNormalizationToolInternalAction.SET_STAMP_SINGLE_CHANNEL_PRIORITY:
      return {
        ...state,
        stampSingleChannelPriority: action.payload.stampSingleChannelPriority,
      };
    case PoolingNormalizationToolInternalAction.SET_REQUIRED_WARNINGS:
      return {
        ...state,
        requiredWarnings: action.payload.requiredWarnings,
      };
    case PoolingNormalizationToolInternalAction.SET_OPTIONAL_WARNINGS:
      return {
        ...state,
        optionalWarnings: action.payload.optionalWarnings,
      };
    case PoolingNormalizationToolInternalAction.SET_WORKLIST:
      return {
        ...state,
        worklist: action.payload.worklist,
        worklistPreviewOpen: action.payload.worklistPreviewOpen,
      };
    case PoolingNormalizationToolInternalAction.SET_WORKLIST_PREVIEW_OPEN:
      return {
        ...state,
        worklistPreviewOpen: action.payload.worklistPreviewOpen,
      };
    case PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          [action.payload.key]: action.payload.value,
        },
      };
    case PoolingNormalizationToolInternalAction.UPDATE_STAMP_ROWCOLVOL:
      return {
        ...state,
        methodSettings: {
          ...state.methodSettings,
          ...action.payload,
        },
      };
    case PoolingNormalizationToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE:
      return {
        ...state,
        methodSettings: action.payload.methodSettings,
      };
  }
}
