import { useAxiosInterceptor } from "error/handlers/axios-error-handler";
import { useGetMeQuery } from "graphql/generated/schema-types";
import { useEffect, useRef, useState } from "react";
import { Action } from "state-provider/actions";
import { useStateProvider } from "state-provider/hooks";
import LoginService from "./services/AuthService";

export function useAuthCheck(
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
): void {
  const { dispatch } = useStateProvider();
  useEffect(() => {
    if (localStorage.idToken) {
      LoginService.authCheck()
        .then((response) => {
          const claims = response.data.me;
          if (claims?.userId) {
            localStorage.setItem("userID", claims.userId.toString());
            dispatch({
              type: Action.AUTHENTICATE,
              payload: {
                loggedIn: true,
              },
            });
          } else
            dispatch({
              type: Action.AUTHENTICATE,
              payload: { loggedIn: false },
            });
          setLoading(false);
        })
        .catch((ex) => {
          console.log(ex);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [dispatch, setLoading]);
}

export function useSession(): [boolean, boolean | undefined] {
  useAxiosInterceptor();
  const [loading, setLoading] = useState(true);
  const {
    state: { loggedIn },
  } = useStateProvider();
  const { data } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  useAuthCheck(setLoading);
  sessionStorage.setItem("userID", data?.me?.userId.toString() ?? "");
  sessionStorage.setItem("userName", data?.me?.userName?.toString() ?? "");
  return [loading, loggedIn];
}

export function useIdleTimer(
  onIdle: () => void,
  timeout: number,
  timeRemainingStart: number
) {
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);

  const startWarningTimer = () => {
    setTimeRemaining(timeRemainingStart);

    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === null) {
          clearInterval(interval);
          return null;
        } else if (prevTime <= 1) {
          clearInterval(interval);
          onIdle();
          return null;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      setTimeRemaining(null);
    }
    timer.current = setTimeout(startWarningTimer, timeout);
  };

  const isSharedComputer = Boolean(localStorage.getItem("isSharedComputer"));
  const containsIdToken = Boolean(localStorage.getItem("idToken"));

  useEffect(() => {
    if (isSharedComputer && containsIdToken) {
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);
      window.addEventListener("scroll", resetTimer);

      resetTimer();
    }

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
      window.removeEventListener("scroll", resetTimer);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [containsIdToken]);

  return { timeRemaining };
}
