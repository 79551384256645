import { SortEnumType } from "graphql/generated/schema-types";
import produce from "immer";
import _ from "lodash";
import moment from "moment";

type IDistinct = <T>(array: T[], key: keyof T) => T[];

export const DeepCopy = <T>(objectInstance: T): T =>
  _.cloneDeep(objectInstance);
export const ShallowCopy = <T>(objectInstance: T): T => _.clone(objectInstance);
export const ShallowFalsy = <T>(object: T) => {
  let truthy = false;
  for (const key in object) {
    truthy = truthy || !!object[key];
  }
  return !truthy;
};

export const Distinct: IDistinct = <T>(array: T[], key: keyof T) =>
  Array.from(new Set(array.map((entry: T) => entry[key])))
    .map((keyValue: unknown) => array.find((entry) => entry[key] === keyValue))
    .filter((e) => e) as Array<T>;

export const DistinctPrimitive = <T>(array: T[]) =>
  array.filter((item, index) => array.indexOf(item) === index);

export const GetSortCompare =
  <T>(key: keyof T, order?: SortEnumType.Desc | never) =>
  (x: T, y: T) => {
    if (order === undefined) {
      if (x[key] > y[key]) return 1;
      else if (x[key] < y[key]) return -1;
    }
    if (order === SortEnumType.Desc) {
      if (x[key] < y[key]) return 1;
      else if (x[key] > y[key]) return -1;
    }
    return 0;
  };

export function MutateState<TObject>(
  obj: TObject,
  mutate: (draft: TObject) => void | TObject
) {
  const newObj = produce(obj, mutate);
  return {
    hasChanged: !_.isEqual(newObj, obj),
    newValue: _.isEqual(newObj, obj) ? undefined : newObj,
  };
}

export const DeleteObjectPropertiesAndTypeName = <T>(
  entry: T | T[],
  exclude?: (keyof T)[]
): unknown => {
  const _entry = DeepCopy(entry);
  if (_entry instanceof Array) {
    for (let i = 0; i < _entry.length; i++) {
      _entry[i] = DeleteObjectPropertiesAndTypeName(_entry[i], exclude) as T;
    }
    return _entry;
  }
  for (const key in _entry) {
    if ((exclude ?? []).includes(key)) continue;
    if (_.isObject(_entry[key]) || key === "__typename") {
      delete _entry[key];
    }
  }
  for (const excludedKey of exclude ?? []) {
    _entry[excludedKey] = DeleteObjectPropertiesAndTypeName(
      _entry[excludedKey]
    ) as T[keyof T];
  }
  return _entry as unknown;
};

export const FlattenObject = <T>(obj: T, prefix = "") => {
  return Object.keys(obj).reduce((acc: Record<string, unknown>, k) => {
    const pre = prefix.length ? prefix + "." : "";
    const value = obj[k as keyof T];
    if (
      value &&
      typeof value === "object" &&
      !(value instanceof Date) &&
      !(value instanceof Array)
    ) {
      Object.assign(acc, FlattenObject(obj[k as keyof T], pre + k));
    } else acc[pre + k] = value;
    return acc;
  }, {});
};

export const OmitDeep = <T>(
  _obj: Record<keyof T, unknown>,
  _keys: (keyof T)[]
) => {
  const __obj = DeepCopy(_obj);
  const OmitFunction = <T>(
    obj: Record<keyof T, unknown>,
    keys: (keyof T)[]
  ) => {
    let index;
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        switch (typeof obj[prop]) {
          case "string":
          case "number":
          case "boolean":
            index = keys.indexOf(prop);
            if (index > -1) {
              delete obj[prop];
            }
            break;
          case "object":
            index = keys.indexOf(prop);
            if (index > -1) {
              delete obj[prop];
            } else {
              OmitFunction(obj[prop] as Record<keyof T, unknown>, keys);
            }
            break;
        }
      }
    }
    return obj;
  };
  return OmitFunction(__obj, _keys);
};

export const DateDiff = (start: Date, end: Date) =>
  moment(start).diff(moment(end), "days");
