import { makeVar, useReactiveVar } from "@apollo/client";
import { ExpandMore, GitHub } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import { Commit } from "@octokit/graphql-schema";
import { GraphQLClient } from "graphql-request";
import { GithubQueries } from "graphql/external/github.queries";
import { GetReleaseNotesResponse } from "graphql/generated/github-types";
import { SortEnumType } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { GetSortCompare } from "helpers/object-helpers";
import _ from "lodash";
import moment from "moment";
import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import packageJson from "../../package.json";

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
`;

const CommitContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => (theme.palette.mode === "dark" ? "white" : "black")};
`;

const ReleaseDescription = styled.div`
  color: ${({ theme }) => (theme.palette.mode === "dark" ? "white" : "black")};
`;

const client = new GraphQLClient("https://api.github.com/graphql", {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`,
  },
});

export const ShowReleaseNotes = makeVar(false);

export const ReleaseNotes: FC = () => {
  const [releaseNotes, setReleaseNotes] = useState<
    Maybe<GetReleaseNotesResponse> | undefined
  >();
  const [idx, setIdx] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const showReleaseNotes = useReactiveVar(ShowReleaseNotes);
  if (!loading && !releaseNotes) {
    setLoading(true);
    client
      .request(GithubQueries.GET_RELEASE_NOTES)
      .then((response: Maybe<GetReleaseNotesResponse>) => {
        setReleaseNotes(response);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }
  const backendCommit = releaseNotes?.backend?.ref?.target as Maybe<Commit>;
  return (
    <Dialog open={showReleaseNotes} onClose={() => ShowReleaseNotes(false)}>
      {!loading ? (
        <DialogContent>
          <DialogTitle>
            <HeaderContainer>
              <Header>
                {releaseNotes?.frontend?.releases?.nodes?.[idx]?.tagName}
              </Header>
              <div>
                <FormControl>
                  <InputLabel>Release Date</InputLabel>
                  <Select
                    label={"Release Date"}
                    labelId="release-date-label"
                    id="release-date-select"
                    value={idx ?? null}
                    onChange={(e) => {
                      const _idx = parseInt(e.target.value.toString());
                      if (!_.isNaN(_idx)) setIdx(_idx);
                    }}
                    inputProps={{
                      name: "releaseDate",
                      id: "releaseDate",
                    }}
                  >
                    {releaseNotes?.frontend?.releases?.nodes?.map(
                      (node, idx) => (
                        <MenuItem key={node?.tagName} value={idx}>
                          {moment(node?.createdAt).format("L")}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </div>
            </HeaderContainer>
          </DialogTitle>
          <Typography>
            <ReleaseDescription
              dangerouslySetInnerHTML={{
                __html: releaseNotes?.frontend?.releases?.nodes?.[idx]
                  ?.descriptionHTML ?? <div />,
              }}
            />
          </Typography>
          <Accordion TransitionProps={{ timeout: 250 }} id={"frontend-commits"}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Frontend Commits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CommitContainer>
                <List>
                  {releaseNotes?.frontend?.releases?.nodes?.[
                    idx
                  ]?.tagCommit?.associatedPullRequests?.nodes
                    ?.map((pr) =>
                      pr!
                        .commits!.nodes!.map((node) => node!.commit)
                        .sort(GetSortCompare("authoredDate", SortEnumType.Desc))
                        .map((commit) => (
                          <ListItem key={commit.id}>
                            <ListItemIcon>
                              <GitHub />
                            </ListItemIcon>
                            {`${commit.message}  (${commit?.committer?.name})`}
                          </ListItem>
                        ))
                    )
                    .flat()}
                </List>
              </CommitContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ timeout: 250 }} id={"backend-commits"}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>Backend Commits</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CommitContainer>
                <List>
                  {backendCommit?.history?.nodes?.map((commit) => (
                    <ListItem key={commit!.id!}>
                      <ListItemIcon>
                        <GitHub />
                      </ListItemIcon>
                      {`${commit?.message}  (${commit?.committer?.name})`}
                    </ListItem>
                  ))}
                </List>
              </CommitContainer>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogTitle>
            <Skeleton width={"100px"} />
          </DialogTitle>
          <DialogContentText marginBottom={3}>
            <Skeleton width={"400px"} />
          </DialogContentText>
        </DialogContent>
      )}
      <small>WebSuite Version: {packageJson.version}</small>
    </Dialog>
  );
};
