import { useGetMeQuery } from "graphql/generated/schema-types";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { Redirect } from "react-router-dom";
import { useStateProvider } from "state-provider/hooks";
import UnauthorizedPage from "./UnauthorizedPage";

interface AuthorizeProps {
  redirect?: boolean;
}

export function Authorize({
  children,
  redirect,
}: Wrapper & AuthorizeProps): JSX.Element {
  const {
    state: { loggedIn },
  } = useStateProvider();
  if (redirect === true)
    return (loggedIn ? children : <Redirect to={"/login"} />) as JSX.Element;
  return (loggedIn ? children : <UnauthorizedPage />) as JSX.Element;
}

export function AuthorizeAdmin({ children }: Wrapper): JSX.Element {
  const {
    state: { loggedIn },
  } = useStateProvider();
  const { data } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  return (
    loggedIn && data?.me?.isAdmin ? (
      children
    ) : (
      <UnauthorizedPage adminRestriction={loggedIn && !data?.me?.isAdmin} />
    )
  ) as JSX.Element;
}
