import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { GetExperimentSettingsOptions } from "features/Runs/components/Forms/MethodDetails/helpers";
import { PlateTableRow } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/TableRow";
import { GetCeligoPlateMetadataByBarcode } from "features/WorklistTools/CeligoScan/Dialog/state";
import {
  GroupedPlateMetadata,
  LookupExperimentSetting,
  MethodReservation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React from "react";
import styled from "styled-components";

export const TableContainer = styled.div`
  margin: 20px;
  max-width: 48vw;
`;
export const StyledTableHead = styled(TableHead)`
  background-color: rgba(0, 0, 0, 0.2);
`;
export function CeligoPlatesTable({
  group,
  experimentSettings,
  methodReservation,
}: {
  group: GroupedPlateMetadata & { expanded: boolean };
  experimentSettings?: Maybe<LookupExperimentSetting>[];
  methodReservation?: Maybe<MethodReservation>;
}): JSX.Element {
  return (
    <TableContainer>
      <Table size={"small"}>
        <StyledTableHead>
          <TableRow>
            <TableCell align="center" width={"15%"}>
              Plates
            </TableCell>
            <TableCell align="center" width={"15%"}>
              Samples
            </TableCell>
            <TableCell align="center" width={"5%"}>
              Active Wells
            </TableCell>
            <TableCell align="center" width={"25%"}>
              Experiment Settings
            </TableCell>
            <TableCell align="center" width={"15%"}>
              Img Export Settings
            </TableCell>
            <TableCell align="center" width={"15%"}>
              Img Export Format
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {group?.plates?.map((plateEntry, idx) => {
            const experimentSettingOptions = GetExperimentSettingsOptions(
              experimentSettings,
              plateEntry?.platesCreated
            );
            const plateMetadata = GetCeligoPlateMetadataByBarcode(
              plateEntry?.platesCreated?.plateBarcode ?? ""
            );
            return plateMetadata ? (
              <PlateTableRow
                key={idx}
                plateToMetadata={plateEntry}
                experimentSettingOptions={experimentSettingOptions}
                worklistContent={
                  methodReservation?.worklist?.worklistContentImagings
                }
              />
            ) : null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
