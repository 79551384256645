import { animated } from "@react-spring/web";
import React from "react";
import { useModalTransition } from "shared-components/ModalsAndDialogs/hooks";
import { useUserManagerState } from "../../state/hooks";
import { EditUserModal } from "../Forms/Edit/User/Modal";
import { UserManagerModals } from "./modals.enum";

export function UserManagerModalContent(): JSX.Element {
  const {
    userManagerState: { currentModal },
  } = useUserManagerState();
  const transition = useModalTransition(currentModal);
  return transition((props, currentModal) => {
    switch (currentModal) {
      case UserManagerModals.EditUser:
        return (
          <animated.div style={props}>
            <EditUserModal />
          </animated.div>
        );
      default:
        return <React.Fragment />;
    }
  });
}
