import { gql } from "@apollo/client/core";
import { TypeNames } from "enums/TypeNames";
import { Group, User, UserClaims } from "../fragments/user.fragment";

export const UserQueries = {
  remote: {
    GET_ME: gql`
      query GetMe {
        me {
          ...UserClaims
        }
      }
      ${UserClaims}
    `,
    GET_GROUPS: gql`
      query getGroups {
        appSuiteGroups(order: { groupName: ASC }) {
          ...Group
        }
      }
      ${Group}
    `,
    GET_GROUP_BY_ID: gql`
      query getGroupById($id: Int!) {
        appSuiteGroups(where: { groupId: { eq: $id } }) {
          ...Group
        }
      }
      ${Group}
    `,
    GET_USER_MANAGER_USERS: gql`
      query UserManager(
        $where: AppSuiteUserFilterInput
        $order: [AppSuiteUserSortInput!]
      ) {
        appSuiteUsers(where: $where, order: $order) {
          ...User
        }
      }
      ${User}
    `,
    GET_USERS: gql`
      query getUsers {
        appSuiteUsers(
          order: { userName: ASC }
          where: { isActive: { eq: true }, userId: { neq: 70 } }
        ) {
          ...User
        }
      }
      ${User}
    `,
  },
  local: {
    GET_USER_BY_ID: gql`
      query getUser($userId: Int!) {
        user(id: $userId, typeName: "${TypeNames.AppSuiteUser}", keyField: "userId") @client {
          ...User
        }
      }
      ${User}
    `,
    GET_USERS_BY_IDS: gql`
      query getUsersFromCache($userIds: [Int!]!) {
        usersFromCache(ids: $userIds, typeName: "${TypeNames.AppSuiteUser}", keyField: "userId") @client {
          ...User
        }
      }
      ${User}
    `,
    GET_GROUP_BY_ID: gql`
      query getGroup($groupId: Int!) {
        group(id: $groupId typeName: "${TypeNames.AppSuiteGroup}", keyField: "groupId") @client {
          ...Group
        }
      }
      ${Group}
    `,
    GET_GROUPS_BY_IDS: gql`
      query getGroupsFromCache($groupIds: [Int!]) {
        groupsFromCache(ids: $groupIds, typeName: "${TypeNames.AppSuiteGroup}", keyField: "groupId") @client {
          groupId
          groupName
        }
      }
    `,
  },
};
