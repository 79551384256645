import { gql } from "@apollo/client/core";

export const PlateCreated = gql`
  fragment PlateCreated on PlatesCreated {
    plateCreatedId
    plateBarcode
    isActive
    labwareType {
      plateLayoutTypeId
      platePitch
    }
    arrayPlateType {
      defaultLabwareType {
        plateLayoutTypeId
        platePitch
      }
    }
    containers {
      containerId
      sample {
        sampleId
        sampleBarcode
      }
    }
  }
`;

export const Plate = gql`
  fragment Plate on PlatesCreated {
    plateCreatedId
    plateBarcode
    plateBarcodeAlias
    comments
    dateCreated
    createdByUserId
    isDead
    isIntermediate
    isActive
    lastScanDate
    plateNotes {
      plateNoteId
      __typename
      user {
        userId
        userName
      }
      note
      dateCommented
    }
    productionRun {
      runName
      runId
    }
    arrayPlateType {
      plateLayoutTypeId
      plateLayoutType {
        plateTypeId
      }
      defaultLabwareType {
        plateLayoutTypeId
      }
    }
  }
`;
export const ContainerMetadata = gql`
  fragment ContainerMetadata on ContainerMetaData {
    rowCol
    rowPos
    colPos
    barcode
    isActive
    passageNumber
    lastDataPointSummary
    lastDataPointValue
    markingSummary
    contaminatedMarking
    differentiatedMarking
    noCellsMarking
    clonalityMarking
    qcpassFailMarking
    colonyMarking
    sangerSeqMarking
    conReviewMarking
    fibroblastLotNumber
    bloodProcessingLotNumber
    consolidationLotNumber
    reprogrammingLotNumber
    spitLotNumber
    monoclonalizationLotNumber
    expansionLotNumber
    geneEditingLotNumber
    dnaextractionLotNumber
    externalLotNumber
    otherLotNumber
    collaboratorId
    nyscfid
    sourceCellLine
    lotNumber
    sampleBarcode
    sampleBarcodeAliases
  }
`;

export const PlateInfo = gql`
  fragment PlateInfo on PlatesCreated {
    ...Plate
    containers {
      containerId
      containerNotes {
        containerNoteId
        __typename
        user {
          userId
          userName
        }
        note
        dateCommented
      }
      containerMetadata {
        ...ContainerMetadata
      }
    }
  }
  ${Plate}
  ${ContainerMetadata}
`;

export const ContainerLineage = gql`
  fragment ContainerLineage on ContainerLineage {
    containerId
    hierarchy
    plateBarcode
    rowPos
    colPos
    sample
    tubeBarcode
    dateCreated
    passageNumber
    lotNumber
    isActive
    finalConfluence
    lN2SAMActivity
    comments
  }
`;

export const Sample = gql`
  fragment Sample on Sample {
    sampleId
    sampleBarcode
    sampleBarcodeAliases
    description
    dateAdded
    registeredByUserId
  }
`;
export const ContainerTableEntry = gql`
  fragment ContainerTableEntry on Container {
    containerId
    barcode
    dateCreated
    colPos
    rowPos
    rowCol
    dateInactivated
    isActive
    inactivationReason {
      reason
    }
    sample {
      ...Sample
    }
    plate {
      plateCreatedId
      plateBarcode
    }
    lotNumber {
      lotNumberId
      lotNumber1
    }
  }
  ${Sample}
`;

export const LabwareTypes = gql`
  fragment LabwareType on LookupLabwareType {
    plateLayoutTypeId
    labwareTypeId
    labwareTypeSummary
    labwareTypeName
    labwareTypeCode
    manufacturer
    description
    defaultLabwareVolume
    minLabwareVolume
    maxLabwareVolume
    defaultArrayPlateCodeNavigation {
      arrayPlateCode
    }
    plateLayoutType {
      plateRows
      plateCols
    }
  }
`;
