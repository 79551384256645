import { Box, IconButton, Popover } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React from "react";
import styled from "styled-components";

interface StyledIconButtonProps {
  active: string | undefined | null;
}
const StyledIconButton = styled(IconButton)`
  background-color: ${({ active }: StyledIconButtonProps) =>
    active === "true" ? "rgba(0,0,0,0.3) !important" : ""};
`;
export function OpenFilterButton({
  id,
  active,
  children,
}: {
  id: number | string;
  active: "true" | "false";
  children: React.ReactElement;
}): JSX.Element {
  return (
    <PopupState variant="popover" popupId={`${id}-popup`}>
      {(popupState) => (
        <div>
          <StyledIconButton
            size={"small"}
            active={active}
            {...bindTrigger(popupState)}
          >
            <FilterListIcon fontSize={"small"} />
          </StyledIconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box p={2}>{children}</Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
