import { gql } from "@apollo/client";

export const User = gql`
  fragment User on AppSuiteUser {
    userId
    userName
    email
    roles
    isActive
    isAdmin
    groups {
      groupId
      groupName
    }
  }
`;
export const Group = gql`
  fragment Group on AppSuiteGroup {
    groupId
    groupName
    users {
      userId
      userName
    }
  }
`;
export const UserClaims = gql`
  fragment UserClaims on UserClaims {
    userId
    userName
    isAdmin
    isGroupAccount
    setting {
      id
      userId
      settingsJson
    }
    groupAccount {
      ...Group
    }
    loggedInGroupMember {
      labComputer {
        id
        name
      }
      user {
        userId
        userName
        isAdmin
        email
      }
    }
  }
  ${Group}
`;
