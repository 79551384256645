import React, { FC } from "react";
import { useRouteMatch } from "react-router-dom";
import { RouteWithErrorBoundary } from "App.routes";
import { PageWithNav } from "../../Navbar/PageWithNav";
import { TeamGanttIFrame } from "../components/TeamGanttIFrame";

export const TeamGattRouteContext: FC = ({ children }) => {
  const match = useRouteMatch();
  const path = `${match.url}/:runId?`;
  return (
    <RouteWithErrorBoundary path={path}>{children}</RouteWithErrorBoundary>
  );
};
export function TeamGantt(): JSX.Element {
  return (
    <PageWithNav>
      <TeamGattRouteContext>
        <TeamGanttIFrame />
      </TeamGattRouteContext>
    </PageWithNav>
  );
}
