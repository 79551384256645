import { Scalars } from "@octokit/graphql-schema";
import { Maybe } from "graphql/jsutils/Maybe";
import { AssetInput } from "../../graphql/generated/schema-types";

export class AssetInputStub implements AssetInput {
  asset: Maybe<Scalars["String"]>;
  assetTypeId: Scalars["Int"];
  contractId: Maybe<Scalars["Int"]>;
  designName: Maybe<Scalars["String"]>;
  documentationUrl: Maybe<Scalars["String"]>;
  manufacturer: Maybe<Scalars["String"]>;
  serialNumber: Maybe<Scalars["String"]>;
  vendor: Maybe<Scalars["String"]>;
}
