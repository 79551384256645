import { CreateRunActivityValidationSchema } from "features/Runs/components/Forms/validation-schema";
import { Formik } from "formik";
import { RunTask } from "graphql/generated/schema-types";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import React from "react";
import { XTYCustomPassageFields } from "shared-components/FormFields/MethodFields/XTYCustomPassageFields";
import { StyledForm } from "shared-components/styled-components";

export function XTYCustomPassageForm({
  runActivity,
}: {
  runActivity: RunTask;
  hasActivePlates: boolean;
}): JSX.Element {
  const runActivityInput = RunActivityInputMapper(runActivity);
  return (
    <React.Fragment>
      <Formik
        onSubmit={() => void 0}
        initialValues={runActivityInput}
        validationSchema={CreateRunActivityValidationSchema}
      >
        {(props) => {
          const { handleSubmit } = props;
          return (
            <StyledForm onSubmit={handleSubmit}>
              <XTYCustomPassageFields
                props={props}
                controlled
                disabled={true}
              />
            </StyledForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}
