import { useReactiveVar } from "@apollo/client";
import { ToggleButton, Tooltip } from "@mui/material";
import { IntermediatePlate } from "features/WorklistTools/shared/components/IntermediatePlate";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { useCtrlDown } from "features/WorklistTools/shared/hooks/hooks";
import {
  PlatePosition,
  ReactSelectableFast,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import { SelectableGroup } from "react-selectable-fast";
import styled from "styled-components";
import {
  IntermediatePlateInfoState,
  PoolingNormalizationToolActions,
} from "../state";
import { PoolingNormalizationToolInternalAction } from "../state/action";
import { PoolingNormalizationToolInternalState } from "../state/initial-state";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlateSettingsContainer = styled.div`
  margin-top: 10px;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr;
`;

export const StampAndIntPlatesContainer = (intPlateTypeInfo: any) => {
  const poolingInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );
  const sourcePlateIndex = poolingInternalState.sourceIntIndex;
  const destinationPlateIndex = poolingInternalState.destinationIntIndex;

  const dispatch = useAppReduxDispatch();
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  const sourcePlateInfo = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.sourcePlateInfo
  );
  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );
  const intPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present.intPlateInfo
  );
  const handleIntermediateWellSelection = (
    selection: ReactSelectableFast[]
  ) => {
    const intSelection = selection.map((well) => ({
      plateIndex: well.props.plateIndex,
      plateWellId: well.props.plateWellId,
      isSelectable: well.props.wellColor === "" ? false : true,
    }));
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_SELECTED_INTERMEDIATE_WELLS,
      payload: {
        selectedIntermediateWells: intSelection,
      },
    });
  };

  const handleIntPlateInfoChanged = (
    index: number,
    key: string,
    value: any
  ) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_INT_PLATE_INFO({
        index,
        key,
        value,
      })
    );
  };

  const handleDoubleClick = (index: number) => {
    if (sourcePlateIndex === index) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_SOURCE_INT_INDEX,
        payload: {
          sourceIntIndex: undefined,
        },
      });
    } else if (destinationPlateIndex === index) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_DESTINATION_INT_INDEX,
        payload: {
          destinationIntIndex: undefined,
        },
      });
    } else if (sourcePlateIndex === undefined) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_SOURCE_INT_INDEX,
        payload: {
          sourceIntIndex: index,
        },
      });
    } else if (destinationPlateIndex === undefined) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_DESTINATION_INT_INDEX,
        payload: {
          destinationIntIndex: index,
        },
      });
    }
  };

  const handleWellHover = (wellId: string) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_HOVER_WELL_ID,
      payload: {
        hoverWellId: wellId,
      },
    });
  };

  const handleSelectStampPatternChange = (value: number) => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET__SELECTED_INTERMEDIATE_STAMP_POSITION,
      payload: {
        selectedIntermediateStampPosition: value,
      },
    });
  };

  const ctrlDown = useCtrlDown();

  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "600px",
        // width: "50%",
        overflow: "auto",
      }}
    >
      {intPlateInfo.map((item: IntermediatePlateInfoState, index: number) => (
        <div style={{ padding: "20px" }}>
          <PlateBarcodeField
            index={index}
            label={`Intermediate Plate Barcode ${index + 1}`}
            plateBarcode={item.plateBarcode}
            handlePlateBarcodeChange={handleIntPlateInfoChanged}
            disabled={item.plateBarcode === ""}
            ignoreError={poolingInternalState.uploadedIntermediatePlate}
          />
          <div onDoubleClick={() => handleDoubleClick(index)}>
            <StyledSelectableGroup
              onSelectionFinish={handleIntermediateWellSelection}
              resetOnStart={
                ctrlDown.worklistToolInternalState.setCtrlDown ? false : true
              }
              allowCtrlClick={true}
            >
              <IntermediatePlate
                worklistTool={WorklistTools.PoolingNormalization}
                index={index}
                plateRows={item.rows}
                plateCols={item.cols}
                wellMapping={
                  index === 0
                    ? worklistValues.harvestWells
                    : index === 1
                    ? worklistValues.int1ToInt2
                    : [
                        ...worklistValues.int2ToInt3,
                        ...worklistValues.int1ToInt3,
                      ]
                }
                sourcePlateInfo={sourcePlateInfo}
                sourcePlatePosition={
                  index === 0
                    ? PlatePosition.Source
                    : PlatePosition.Intermediate
                }
                handleWellHover={handleWellHover}
              />
            </StyledSelectableGroup>
          </div>
          <StyledPlateActions>
            <Tooltip
              title={
                worklistValues.referenceStampShape.length === 0
                  ? "Stamp Plate is Empty. Please create a pattern in the stamp plate"
                  : ""
              }
            >
              <div>
                <ToggleButton
                  color="primary"
                  sx={{ width: "100%" }}
                  value="Use Stamp Pattern"
                  selected={
                    poolingInternalState.selectedIntermediateStampPosition ===
                    index + 1
                  }
                  onChange={() => handleSelectStampPatternChange(index + 1)}
                  disabled={worklistValues.referenceStampShape.length === 0}
                >
                  Use Stamp Pattern
                </ToggleButton>
              </div>
            </Tooltip>
          </StyledPlateActions>
          <StyledPlateSettingsContainer>
            <PlateType
              platePosition={PlatePosition.Intermediate}
              isDisabled={
                poolingInternalState.methodSettings!.selectedSystem === 0
              }
              acceptedPlateTypes={intPlateTypeInfo.intPlateInfo}
              plateType={item.labwareTypeCode}
              setPlateType={(plateTypeInfo) =>
                handleIntPlateInfoChanged(
                  index,
                  "labwareTypeCode",
                  plateTypeInfo
                )
              }
            />
            <div style={{ display: "flex", width: "100%" }}>
              <NumberField
                style={{ width: "50%", marginTop: "10px", marginRight: "5px" }}
                id="operating-vol"
                label="Operating Vol (µL)"
                value={item.operatingVol}
                onChange={(value) =>
                  handleIntPlateInfoChanged(
                    index,
                    "operatingVol",
                    parseInt(value)
                  )
                }
              />
              <NumberField
                style={{ width: "50%", marginTop: "10px" }}
                id="resuspension-vol"
                label="Resuspension Vol (µL)"
                value={item.resuspensionVol}
                onChange={(value) =>
                  handleIntPlateInfoChanged(
                    index,
                    "resuspensionVol",
                    parseInt(value)
                  )
                }
                hasError={
                  item.labwareTypeCode !== "" && item.resuspensionVol <= 0
                }
                errorMessage="Required"
              />
            </div>
          </StyledPlateSettingsContainer>
        </div>
      ))}
    </div>
  );
};
