import { makeVar } from "@apollo/client";
import { Confirm_Action } from "./actions";

export interface IConfirmState {
  prompt: string;
  isOpen: boolean;
  proceed?: (val: any) => void;
  cancel?: () => void;
  bullets?: string[];
}

export interface IConfirmAction {
  type: Confirm_Action;
  payload?: IConfirmState;
}

export const ConfirmState = makeVar<IConfirmState>({
  prompt: "",
  isOpen: false,
  bullets: [],
});
