import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import { TaskAttachments } from "features/Dashboard/components/Dialogs/TaskAttachments";
import { MethodReservationForm } from "features/Runs/components/Forms/MethodDetails/MethodReservationForm";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { Maybe } from "graphql/jsutils/Maybe";
import { useGetGroupsById, useGetSystem } from "hooks/cacheAccessHooks";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components/macro";
import {
  PersonalTaskAttachment,
  RunTask,
  RunTaskAttachment,
  RunTaskFragment,
  RunTaskMetaDataFragment,
  TaskSelectionTypeEnum,
  TaskTypeEnum,
  UpdateMethodDocumentationInput,
  useGetRunTaskAttachmentsQuery,
  useGetRunTaskMetaDataQuery,
} from "graphql/generated/schema-types";
import { Pill } from "shared-components/styled-components";
import { EditDocumentation } from "./sub-components/EditDocumentation";

const StyledSpan = styled.span`
  font-weight: bold;
`;
const DetailDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
`;
const StyledDialog = styled(Dialog).attrs(({ width }: { width?: string }) => ({
  width,
}))`
  div.MuiDialog-container.MuiDialog-scrollPaper > div {
    width: ${({ width }: { width?: string }) => width};
  }
`;
const StyledDialogContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const getMethodName = (
  runTask: (RunTaskFragment & RunTaskMetaDataFragment) | undefined
): string | null | undefined => {
  if (runTask?.method) return runTask.method?.methodName;
  if (runTask?.manualTaskType) return runTask.manualTaskType?.name;
  return null;
};
const getTaskType = (
  runTask: (RunTaskFragment & RunTaskMetaDataFragment) | undefined
): Maybe<UpdateMethodDocumentationInput> => {
  if (runTask?.method)
    return {
      id: runTask.method.methodId,
      documentationUrl: runTask.method.documentationUrl ?? "",
      type: TaskSelectionTypeEnum.Method,
    };
  if (runTask?.manualTaskType)
    return {
      id: runTask.manualTaskType.id,
      documentationUrl: runTask.manualTaskType.documentationUrl ?? "",
      type: TaskSelectionTypeEnum.Manual,
    };

  return null;
};
export function RunActivityDialog(): JSX.Element {
  const {
    runsModalState: { taskSummaryDialogOpen },
    runsModalDispatch,
  } = useRunsModalState();
  const { data, loading } = useGetRunTaskMetaDataQuery({
    variables: {
      taskIds: [taskSummaryDialogOpen?.runActivityId ?? 0],
    },
  });
  const _runActivity = data?.runTaskMetadata
    ? (data?.runTaskMetadata[0] as RunTaskFragment & RunTaskMetaDataFragment)
    : undefined;
  const groups = useGetGroupsById(
    _runActivity?.groups?.map((entry) => entry!.groupId)
  );

  const system = useGetSystem(_runActivity?.systemAssignedTo);

  const [showAttachments, setShowAttachments] = useState(false);
  const { data: runTaskAttachments } = useGetRunTaskAttachmentsQuery({
    variables: {
      taskId: taskSummaryDialogOpen?.runActivityId ?? 0,
    },
  });
  const transition = useTransition(showAttachments, {
    from: {
      maxWidth: "0vw",
      maxHeight: "20vh",
    },
    enter: {
      maxWidth: "60vw",
      maxHeight: "50vh",
    },
    leave: {
      maxWidth: "0vw",
      maxHeight: "0vh",
      overflow: "hidden",
    },
  });
  return (
    <React.Fragment>
      <StyledDialog
        open={taskSummaryDialogOpen?.open ?? false}
        maxWidth={"xl"}
        onClose={() =>
          runsModalDispatch({
            type: RunsModalActions.OPEN_TASK_SUMMARY_DIALOG,
            payload: {
              taskSummaryDialogOpen: {
                open: false,
                runActivityId: taskSummaryDialogOpen?.runActivityId,
              },
            },
          })
        }
      >
        <StyledDialogContent>
          <DialogTitle>
            {loading ? (
              <Skeleton variant={"text"} width={"200px"} />
            ) : (
              _runActivity?.activityName
            )}
          </DialogTitle>
          <DialogContent dividers>
            <DetailDiv style={{ marginTop: "0px" }}>
              {loading ? (
                <Skeleton variant={"text"} width={"300px"} />
              ) : (
                <>
                  <StyledSpan>Groups: </StyledSpan>

                  {groups?.map((group) => (
                    <Pill
                      key={group!.groupId}
                      color={"grey"}
                      label={group!.groupName!}
                    />
                  ))}
                </>
              )}
            </DetailDiv>
            <DetailDiv>
              {loading ? (
                <Skeleton variant={"text"} width={"300px"} />
              ) : (
                <>
                  <StyledSpan>System: </StyledSpan>
                  {system?.systemName}
                </>
              )}
            </DetailDiv>
            <DetailDiv>
              {loading ? (
                <Skeleton variant={"text"} width={"300px"} />
              ) : (
                <>
                  <StyledSpan>Scheduled: </StyledSpan>
                  {moment(_runActivity?.startTimeScheduled).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </>
              )}
            </DetailDiv>
            <DetailDiv>
              {loading ? (
                <Skeleton variant={"text"} width={"300px"} />
              ) : (
                <>
                  <StyledSpan>Task: </StyledSpan>
                  {getMethodName(_runActivity)}
                </>
              )}
            </DetailDiv>
            <DetailDiv>
              <StyledSpan>Documentation: </StyledSpan>
              {!!getTaskType(_runActivity) &&
                (loading ? (
                  <Skeleton variant={"text"} width={"300px"} />
                ) : (
                  <EditDocumentation taskType={getTaskType(_runActivity)!} />
                ))}
            </DetailDiv>
            <DetailDiv>
              <StyledSpan>Run Notes:</StyledSpan>
            </DetailDiv>
            <TextField
              disabled
              value={_runActivity?.run?.comments ?? ""}
              variant={"outlined"}
              fullWidth
              multiline
            />
            <DetailDiv>
              <StyledSpan>Task Notes:</StyledSpan>
            </DetailDiv>
            <TextField
              disabled
              value={_runActivity?.notes ?? ""}
              variant={"outlined"}
              fullWidth
              multiline
            />
            <MethodReservationForm
              runActivity={_runActivity as Maybe<RunTask>}
              methodId={_runActivity?.methodId ?? 0}
            />
            <Box mt={2}>
              <Typography color={"inherit"}>
                <Button
                  onClick={() => setShowAttachments((current) => !current)}
                >
                  {showAttachments ? "Hide" : "View"} Attachments
                </Button>
              </Typography>
            </Box>
            {transition(
              (props, item) =>
                item && (
                  <animated.div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      ...props,
                    }}
                  >
                    <TaskAttachments
                      runTaskAttachments={
                        runTaskAttachments?.runTaskAttachments as RunTaskAttachment[]
                      }
                      personalTaskAttachments={[] as PersonalTaskAttachment[]}
                      taskId={taskSummaryDialogOpen?.runActivityId ?? 0}
                      taskType={TaskTypeEnum.RunTask}
                    />
                  </animated.div>
                )
            )}
          </DialogContent>
        </StyledDialogContent>
      </StyledDialog>
    </React.Fragment>
  );
}
