import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Methods } from "enums/Methods";
import { WorklistTools } from "features/WorklistTools/shared/interfaces";
import {
  getArrayFromCount,
  getPlateWellIdFromRowAndColIndex,
  removeUnusedSourceWells,
  setWellColorBySample,
} from "features/WorklistTools/shared/WorklistHelpers";
import { ThawToolActions } from "features/WorklistTools/ThawWorklistTool/state";
import { useHandleMethodSettingsUpdatedByTemplate } from "features/WorklistTools/ThawWorklistTool/state/handlers";
import {
  parseMethodSettings,
  parseThawDestinationTask,
  parseThawStampTask,
  parseThawTransferTask,
} from "features/WorklistTools/ThawWorklistTool/toolbar/handlers/HandleOpenTemplate";
import {
  DashboardTask,
  useGetPlatesInfoLazyQuery,
  useGetSourcePlatesLazyQuery,
  useGetTaskDetailsForWorklistToolLazyQuery,
  useGetWorklistToolTemplateQuery,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components";

interface ThawReservationProps {
  selectedTask: DashboardTask;
}

const StyledTaskDetails = styled.div`
  display: flex;
  column-gap: 5px;
  margin-bottom: 10px;
`;

const NoPlatesAvailable = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  color: ${({ theme }) => (theme.palette.mode === "dark" ? "white" : "black")};
`;
export const ThawReservation = ({ selectedTask }: ThawReservationProps) => {
  const { data: worklistTemplates } = useGetWorklistToolTemplateQuery({
    variables: {
      where: { methodId: { eq: Methods.Thaw } },
    },
  });
  const [getSourcePlates, { data: sourcePlates }] =
    useGetSourcePlatesLazyQuery();
  const [getTaskDetails, { data: taskDetails }] =
    useGetTaskDetailsForWorklistToolLazyQuery({
      onCompleted: (data) => {
        const worklsitTemplateId =
          worklistTemplates?.worklistToolTemplate?.filter(
            (e) =>
              e?.worklistToolTemplateId ===
              data.taskDetailsForWorklistTool?.worklistTemplateId
          );
        setSelectedTemplate(worklsitTemplateId);
        console.log(worklsitTemplateId);
      },
    });
  useEffect(() => {
    getSourcePlates({
      variables: {
        where: {
          and: [
            { isActive: { eq: true } },
            { plateBarcode: { endsWith: selectedTask.taskOwnerName } },
          ],
        },
      },
    });

    getTaskDetails({
      variables: {
        runTaskId: selectedTask.taskId,
      },
    });
  }, [selectedTask]);
  const history = useHistory();

  const dispatch = useAppReduxDispatch();
  const setMethodSettingsByTemplate =
    useHandleMethodSettingsUpdatedByTemplate();
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  // const [getTemplateInfo, { data: templateInfoQuery }] =
  //   useGetWorklistToolTemplateLazyQuery({
  //     onCompleted: (data) => {

  //     },
  //   });
  const [getPlateInfo, { data: plateInfo }] = useGetPlatesInfoLazyQuery({
    onCompleted: (data) => {
      console.log(data);
      if (data.platesInfo == null) {
        Alert({
          type: AlertType.ERROR,
          message: "This plate doesn't have any active wells",
        });
      } else if (plateInfo?.platesInfo?.labwareType) {
        const selectedWorklistTemplate: any =
          worklistTemplates?.worklistToolTemplate?.filter(
            (e) =>
              e?.worklistToolTemplateId ===
              taskDetails!.taskDetailsForWorklistTool?.worklistTemplateId
          )[0];
        const sourceWellIds = plateInfo?.platesInfo?.containers?.map((e) => {
          return getPlateWellIdFromRowAndColIndex(
            e!.rowPos! - 1,
            e!.colPos - 1
          )!;
        });

        const parsedThawDestination = parseThawDestinationTask(
          selectedWorklistTemplate!.worklistToolTemplateContents!.filter(
            (e: any) => e!.task! === "ThawDestination"
          ) ?? []
        );
        const parsedThawTransfer = parseThawTransferTask(
          selectedWorklistTemplate.worklistToolTemplateContents?.filter(
            (e: any) => e!.task! === "ThawTransfer"
          ) ?? []
        );
        const parsedThawStamp = parseThawStampTask(
          selectedWorklistTemplate.worklistToolTemplateContents?.filter(
            (e: any) => e!.task! === "ThawStamp"
          ) ?? []
        );
        const resuspensionVol =
          parseInt(
            selectedWorklistTemplate!.worklistToolTemplateContents!.filter(
              (e: any) => e!.task === "Thaw"
            )![0]!.dispenseVol!
          ) - 25;
        const parsedMethodSettings = parseMethodSettings(
          resuspensionVol > -1 ? resuspensionVol : 0,
          parseInt(
            selectedWorklistTemplate.worklistToolTemplateContents?.filter(
              (e: any) => e!.task === "ThawDestination"
            )[0]?.transferVol ?? "0"
          ),
          selectedWorklistTemplate.worklistToolTemplateContents?.filter(
            (e: any) => e!.task === "MatrixRackCentrifugation"
          ) ?? [],
          taskDetails?.taskDetailsForWorklistTool?.destPlateCode ?? "",
          selectedTask.taskId
        );
        setMethodSettingsByTemplate(parsedMethodSettings);
        const matchedMapping = removeUnusedSourceWells(
          parsedThawTransfer,
          sourceWellIds!
        );
        dispatch(
          ThawToolActions.UPLOAD_WORKLIST_MAPPING({
            thawTransfer: matchedMapping,
          })
        );
        if (parsedThawStamp.length > 0) {
          dispatch(
            ThawToolActions.UPLOAD_WORKLIST_THAW_STAMP({
              thawStamp: parsedThawStamp,
            })
          );
        }

        dispatch(
          ThawToolActions.UPLOAD_DESTINATION_PLATE_INFO({
            destPlateInfo: parsedThawDestination,
          })
        );
        dispatch(
          ThawToolActions.UPLOAD_SOURCE_PLATE({
            index: 0,
            plateBarcode: plateInfo?.platesInfo?.plateBarcode,
            labwareTypeCode:
              plateInfo?.platesInfo?.labwareType?.labwareTypeCode,
            operatingVol:
              plateInfo?.platesInfo?.labwareType?.defaultLabwareVolume,
            rows: getArrayFromCount(
              plateInfo?.platesInfo?.labwareType?.plateLayoutType?.plateRows ??
                0
            ),
            cols: getArrayFromCount(
              plateInfo?.platesInfo?.labwareType?.plateLayoutType?.plateCols ??
                0
            ),
            wellInfo: setWellColorBySample(
              plateInfo?.platesInfo?.containers?.filter(
                (e) => e?.isActive === true
              ) ?? [],
              [],
              0
            ).newWellInfo,
          })
        );
        history.push(`worklist-tool/${WorklistTools.Thaw}`);
      } else {
        Alert({
          type: AlertType.ERROR,
          message: "This plate doesn't have a Labware Type",
        });
      }
    },
  });

  const handleTemplateChanged = (newValue: any) => {
    setSelectedTemplate(newValue);
  };

  const handleViewMappingClick = () => {
    getPlateInfo({
      variables: {
        where: {
          plateCreatedId: {
            eq: sourcePlates!.sourcePlates![0]!.plateCreatedId,
          },
          containers: {
            some: {
              isActive: { eq: true },
            },
          },
        },
      },
    });
  };

  return (
    <React.Fragment>
      {sourcePlates && sourcePlates!.sourcePlates!.length > 0 ? (
        <React.Fragment>
          <Table size="small" style={{ margin: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell>Source Plate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sourcePlates?.sourcePlates?.map((item) => (
                <TableRow>
                  <TableCell>{item!.plateBarcode}</TableCell>
                </TableRow>
              )) ?? <></>}
            </TableBody>
          </Table>

          <StyledTaskDetails>
            <FormControl variant="outlined">
              <InputLabel htmlFor="worklistTemplateInput">
                Worklist Template
              </InputLabel>
              <Select
                disabled
                native
                value={
                  taskDetails?.taskDetailsForWorklistTool?.worklistTemplateId ??
                  -1
                }
                onChange={(e) => handleTemplateChanged(e.target.value)}
                label="Worklist Template"
              >
                <option aria-label="None" value="" />
                {worklistTemplates?.worklistToolTemplate?.map((type) => (
                  <option
                    key={type?.worklistToolTemplateId}
                    value={type?.worklistToolTemplateId}
                  >
                    {type?.templateName}
                  </option>
                ))}
              </Select>
            </FormControl>

            <TextField
              disabled
              label="Dest Plate Code"
              value={
                taskDetails?.taskDetailsForWorklistTool?.destPlateCode ?? ""
              }
            />
          </StyledTaskDetails>

          <Button
            disabled={
              sourcePlates?.sourcePlates?.length === 0 ||
              taskDetails?.taskDetailsForWorklistTool?.worklistTemplateId ===
                null
            }
            variant="outlined"
            onClick={() => handleViewMappingClick()}
          >
            View Worklist Mapping
          </Button>
        </React.Fragment>
      ) : (
        <NoPlatesAvailable>No Plates Available</NoPlatesAvailable>
      )}
    </React.Fragment>
  );
};
