export enum DashboardTypeTabs {
  OverdueTasks,
  MyTasks,
  GroupTasks,
  LaterThisWeekTasks,
}

export enum ContextMenuDialogs {
  AssignToUser,
  AssignToGroup,
  AssignToSystem,
  RescheduleTask,
  EditTask,
  ViewTaskHistory,
  CancelTask,
}
