import { QueryHookOptions, QueryResult } from "@apollo/client";
import {
  Autocomplete,
  CircularProgress,
  debounce,
  TextField,
} from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { ChangeEvent, FC, useMemo } from "react";
import { Exact, Scalars } from "../../graphql/generated/schema-types";
import { getDefaultValue, getValue } from "./helpers";
import { ComboboxFieldProps } from "./types";

export const GetServerSideCombobox = <
  TOption,
  TQuery,
  TVariables extends Exact<{ search: Scalars["String"] }>
>(
  queryFn: (
    baseOptions: QueryHookOptions<TQuery, TVariables>
  ) => QueryResult<TQuery, TVariables>,
  optionsGetter: (result: Maybe<TQuery>) => TOption[],
  idGetter: (option: TOption) => number | string,
  labelGetter: (option: TOption) => string
): FC<ComboboxFieldProps<TOption>> =>
  React.memo(
    ({
      id,
      variant,
      onChange,
      helperText,
      error,
      label,
      onBlur,
      defaultValue,
      value,
      autoFocus,
      size,
      disabled,
      multiple,
      freeSolo,
      filter,
      groupBy,
    }: ComboboxFieldProps<TOption>) => {
      const {
        refetch,
        data: queryResult,
        loading,
        variables: currentQueryVariables,
      } = queryFn({
        notifyOnNetworkStatusChange: true,
        variables: { search: "" } as unknown as TVariables,
      });
      const refetchDebounced = debounce(refetch, 400);

      const entries = useMemo(() => optionsGetter(queryResult), [queryResult]);

      const val = getValue(value, entries, idGetter, multiple);
      const defaultVal = getDefaultValue(
        defaultValue,
        value,
        entries,
        idGetter,
        multiple
      );

      return (
        <Autocomplete
          id={id}
          options={optionsGetter(queryResult).filter(filter ?? ((x) => x))}
          size={size}
          value={val}
          defaultValue={defaultVal}
          disabled={disabled}
          multiple={multiple}
          getOptionLabel={labelGetter}
          groupBy={groupBy}
          fullWidth
          loading={loading}
          filterOptions={(_, { inputValue }) => {
            const variables = {
              search: inputValue ?? "",
            } as unknown as TVariables;
            if (inputValue && inputValue !== currentQueryVariables?.search)
              refetchDebounced(variables);
            return _;
          }}
          freeSolo={freeSolo}
          onChange={
            onChange as (event: ChangeEvent<unknown>, newValue: unknown) => void
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              disabled={disabled}
              autoFocus={autoFocus}
              onBlur={onBlur}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              variant={variant}
              helperText={helperText}
              error={error}
              data-testid={"form-field"}
            />
          )}
        />
      );
    }
  );
