import EditIcon from "@mui/icons-material/Edit";
import { TextField } from "@mui/material";
import { DestPlateStringKeys } from "features/WorklistTools/PoolingNormalization/state";
import {
  useGetExistingMethodReservationLazyQuery,
  useGetPlateInfoLazyQuery,
} from "graphql/generated/schema-types";
import { useMemo } from "react";

interface PlateBarcodeFieldProps {
  index: number;
  label: string;
  plateBarcode: string;
  handlePlateBarcodeChange: (
    index: number,
    field: any,
    value: string
  ) => void;
  disabled?: boolean;
  ignoreError?: boolean;
}

export const PlateBarcodeField = ({
  index,
  label,
  plateBarcode,
  handlePlateBarcodeChange,
  disabled,
  ignoreError,
}: PlateBarcodeFieldProps) => {
  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
    });

  const [getPlateInfo, { data: plateInfo }] = useGetPlateInfoLazyQuery({
    fetchPolicy: "network-only",
  });

  useMemo(() => {
    if (plateBarcode) {
      getExistingMethodReservation({
        variables: {
          where: {
            methodReservationsPlateBarcodes: {
              some: {
                plateBarcode: {
                  eq: plateBarcode,
                },
              },
            },
          },
        },
      });

      getPlateInfo({
        variables: {
          plateBarcode,
        },
      });
    }
  }, [plateBarcode]);

  const containsError = () => {
    if (plateBarcode === "") return false;
    if (ignoreError) return false;
    if (plateExists() || plateExistsInMethodReservation()) return true;
  };

  const plateExists = () => {
    return plateInfo?.plateInfo && plateInfo.plateInfo.plateBarcode !== "";
  };

  const plateExistsInMethodReservation = () => {
    return (
      methodReservation?.existingMethodReservation &&
      methodReservation?.existingMethodReservation?.length > 0
    );
  };

  return (
    <TextField
      variant="outlined"
      style={{ width: "100%", marginBottom: "10px" }}
      label={label}
      value={plateBarcode}
      disabled={disabled}
      InputProps={{
        startAdornment: <b>{index + 1}| </b>,
        endAdornment: <EditIcon></EditIcon>,
      }}
      onChange={(e) =>
        handlePlateBarcodeChange(index, "plateBarcode", e.target.value)
      }
      error={containsError()}
      helperText={containsError() ? "Plate Barcode Already exists" : null}
    />
  );
};
