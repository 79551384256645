import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  ITwelve24TCToolInternalState,
  Twelve24TCToolInternalState,
} from "./initial-state";
import { Twelve24TCToolInternalReducer } from "./reducer";

export function useTwelve24TCStateProvider(): {
  twelve24TCToolInternalState: ITwelve24TCToolInternalState;
  twelve24TCToolDispatch: IApolloDispatch<any>;
} {
  const twelve24TCToolInternalState = useReactiveVar(
    Twelve24TCToolInternalState
  );
  const twelve24TCToolDispatch = useMemo(
    () =>
      ApolloStateDispatch(
        Twelve24TCToolInternalState,
        Twelve24TCToolInternalReducer
      ),
    []
  );
  return {
    twelve24TCToolInternalState,
    twelve24TCToolDispatch,
  };
}
