import { Add } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TicketModals } from "features/Tickets/components/ModelsAndDialogs/modal.enum";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import styled, { useTheme } from "styled-components/macro";
import { SearchEverywhere } from "./SearchEverywhere";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.5)" : "white"};
  padding: 10px;
  max-width: 100vw;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
export function TicketPageHeader({ children }: Wrapper): JSX.Element {
  const { ticketDispatch } = useTicketState();
  const theme = useTheme();
  return (
    <StyledHeader>
      <SearchEverywhere />
      <ButtonContainer>
        {children}
        <IconButton
          size={"large"}
          color={theme.palette.mode === "dark" ? undefined : "secondary"}
          onClick={() =>
            ticketDispatch({
              type: TicketActions.OPEN_MODAL,
              payload: { currentModal: TicketModals.CreateTicket },
            })
          }
        >
          <Add />
        </IconButton>
      </ButtonContainer>
    </StyledHeader>
  );
}
