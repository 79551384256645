import { FormControlLabel, Switch, Typography } from "@mui/material";
import { useRunsModalState } from "features/Runs/state/hooks";
import {
  RunTask,
  RunTaskInput,
  useGetRunAndTasksForRescheduleLazyQuery,
  useGetRunTaskMetaDataQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useGetRunActivitiesById } from "hooks/cacheAccessHooks";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import { useEffect, useMemo, useState } from "react";
import { Rescheduler } from "../Reschedule/Rescheduler";
import { StyledCard } from "../styled-components";
import { useGetRescheduleHandleSubmit } from "./handleSubmit";
import { PreviewScheduleChanges } from "./PreviewChanges";

export function RescheduleTasksForm(): JSX.Element {
  const {
    runsModalState: { selectedTasks },
  } = useRunsModalState();
  const [rescheduleSubsequent, setRescheduleSubsequent] = useState(true);

  const cachedRunTasks = useGetRunActivitiesById(selectedTasks ?? []);
  const [runTask, setRunTask] = useState<any>(
    cachedRunTasks && cachedRunTasks.length ? cachedRunTasks[0] : null
  );

  useGetRunTaskMetaDataQuery({
    variables: {
      taskIds: selectedTasks ?? [],
    },
    onCompleted: (data) => {
      if (runTask || !data || !data!.runTaskMetadata) return;
      setRunTask(data.runTaskMetadata[0]);
    },
  });

  const runId = useMemo(() => runTask?.runId ?? 0, [runTask]);
  const startTimeScheduled = useMemo(
    () => runTask?.startTimeScheduled ?? new Date(),
    [runTask]
  );

  const [execute, { data, called, loading }] =
    useGetRunAndTasksForRescheduleLazyQuery({
      variables: {
        baseDateTime: startTimeScheduled,
        runId: runId,
      },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (!called && !data) execute();
  }, [called, data, execute]);

  const _inputs: RunTaskInput[] = useMemo(() => {
    if (loading || !data?.runs) return [];

    const mapped: RunTaskInput[] =
      data?.runs?.[0]?.runTasks?.map((task) =>
        RunActivityInputMapper(task as Maybe<RunTask>)
      ) ?? [];
    if (rescheduleSubsequent) return mapped;

    return [mapped.find((task) => task.runActivityId === selectedTasks![0])!];
  }, [loading, data?.runs, rescheduleSubsequent, selectedTasks]);

  return (
    <StyledCard>
      <FormControlLabel
        label={"Reschedule Subsequent Tasks"}
        labelPlacement={"start"}
        control={
          <Switch
            checked={rescheduleSubsequent}
            onChange={(e) => setRescheduleSubsequent(e.target.checked)}
          />
        }
      />
      <Rescheduler
        currentDate={startTimeScheduled}
        onSubmit={useGetRescheduleHandleSubmit(_inputs)}
      >
        {(values) => (
          <>
            <Typography variant={"h6"} m={3}>
              {data?.runs?.[0]?.runName}
            </Typography>
            <PreviewScheduleChanges shift={values} tasks={_inputs} />
          </>
        )}
      </Rescheduler>
    </StyledCard>
  );
}
