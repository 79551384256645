import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";

interface ConfirmDialog {
  leftButtonText?: string;
  rightButtonText?: string;
}

export const ConfirmDialog = ({
  leftButtonText,
  rightButtonText,
}: ConfirmDialog) => {
  const {
    prompt = "",
    isOpen = false,
    proceed,
    cancel,
    bullets = [],
  } = useConfirm();

  return (
    <Dialog open={isOpen} onClose={cancel}>
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>
        {prompt}
        {bullets.length ? (
          <ul>
            {bullets.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{leftButtonText ?? "Cancel"}</Button>
        <Button onClick={proceed}>{rightButtonText ?? "Ok"}</Button>
      </DialogActions>
    </Dialog>
  );
};
