import {
  TicketFragment,
  UpdateTicketInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { TicketInputStub } from "mappings/object-stubs/TicketInput";

export function TicketInputMapper(ticket: Maybe<TicketFragment>) {
  const _ticket = DeepCopy(ticket);
  const final = _.pick(
    _ticket,
    _.keys(new TicketInputStub())
  ) as UpdateTicketInput;
  if ((_ticket?.ticketAssignees?.length ?? 0) > 0)
    final.ticketAssigneeIds =
      (_ticket?.ticketAssignees?.map((assignee) => assignee?.userId) as
        | number[]
        | undefined) ?? [];
  if ((_ticket?.ticketFollowers?.length ?? 0) > 0)
    final.ticketFollowerIds =
      (_ticket?.ticketFollowers?.map((follower) => follower?.userId) as
        | number[]
        | undefined) ?? [];
  final.ticketTypeId = _ticket?.ticketType?.ticketTypeId;
  final.assetTypeId = _ticket?.asset?.assetType?.assetTypeId;
  return final;
}
