import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { animated, useTransition } from "@react-spring/web";
import { useFormik } from "formik";
import {
  CreateIncomingSampleCollectionInput,
  Project,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import { useEffect } from "react";
import { ProjectsField } from "shared-components/FormFields/FormFields";
import styled from "styled-components/macro";
import { FormikSubmit } from "types/types";
import { CreateIncomingSampleValidationSchema } from "../validation-schema";
import { CreateSampleTypeForm } from "./CreateSampleTypeForm";
import { ScheduleIncomingSampleFormActions } from "./state";

const StyledFormControl = styled(FormControl)`
  width: 300px;
  margin: 20px 10px !important;
`;
const SampleContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
`;
const SampleCount = styled.div`
  font-weight: bold;
  margin: 20px 10px;
`;
export const FormSection = styled.div`
  display: flex;
  width: 100%;
  margin: 10px;
  justify-content: ${({ justifyContent }: { justifyContent?: string }) =>
    justifyContent ?? ""};
  align-items: center;
  flex-wrap: wrap;
`;
const StyledForm = styled.form`
  display: flex;
  padding: 30px;
  width: 80%;
  flex-direction: column;
`;

export function CreateCollectionForm({
  onSubmit,
}: {
  onSubmit: FormikSubmit<CreateIncomingSampleCollectionInput>;
}): JSX.Element {
  const sampleStore = useAppReduxSelector(
    (state) => state.ScheduleIncomingSampleForm
  );
  const { handleSubmit, errors, touched, values, setFieldValue, isSubmitting } =
    useFormik<CreateIncomingSampleCollectionInput>({
      initialValues: {
        collectionId: 0,
        projectId: 0,
        incomingSamplesCollectionSampleTypes: [],
        dateTimeOfArrival: new Date().setHours(10, 0),
        dateTimeOfCollection: new Date().setHours(10, 0),
      },
      onSubmit,
      validationSchema: CreateIncomingSampleValidationSchema,
    });

  const dispatch = useAppReduxDispatch();

  useEffect(() => {
    dispatch(ScheduleIncomingSampleFormActions.INIT());
  }, [dispatch]);

  const addSample = () => {
    dispatch(ScheduleIncomingSampleFormActions.ADDED_SAMPLE());
  };

  useEffect(() => {
    if (sampleStore.dateOfArrival !== values.dateTimeOfArrival)
      dispatch(
        ScheduleIncomingSampleFormActions.SET_DATE_OF_ARRIVAL(
          values.dateTimeOfArrival
        )
      );
  }, [dispatch, sampleStore, values.dateTimeOfArrival]);

  const transition = useTransition(sampleStore.store, {
    keys: (entry) => entry.key,
    from: {
      opacity: 0,
      maxHeight: "0px",
    },
    enter: { opacity: 1, maxHeight: "300px" },
    leave: {
      opacity: 0,
      maxHeight: "0px",
    },
  });
  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormSection justifyContent={"center"}>
        <StyledFormControl>
          <ProjectsField
            id={"projects-combobox"}
            value={values.projectId}
            onChange={(event, newValue) =>
              setFieldValue(
                "projectId",
                (newValue as Maybe<Project>)?.projectId
              )
            }
            variant="outlined"
            label={"Select Project"}
            helperText={
              errors.projectId && touched.projectId && errors.projectId
            }
            error={
              !!(errors.projectId && touched.projectId && errors.projectId)
            }
          />
        </StyledFormControl>
        <StyledFormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={values.dateTimeOfArrival}
              onChange={(date) => setFieldValue("dateTimeOfArrival", date)}
              label={"Date of Arrival"}
              minutesStep={15}
              renderInput={(props) => (
                <TextField
                  {...props}
                  helperText={
                    errors.dateTimeOfArrival &&
                    touched.dateTimeOfArrival &&
                    errors.dateTimeOfArrival
                  }
                  error={
                    !!(
                      errors.dateTimeOfArrival &&
                      touched.dateTimeOfArrival &&
                      errors.dateTimeOfArrival
                    )
                  }
                  fullWidth
                  variant={"outlined"}
                />
              )}
            />
          </LocalizationProvider>
        </StyledFormControl>
        <StyledFormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={values.dateTimeOfCollection}
              onChange={(date) => setFieldValue("dateTimeOfCollection", date)}
              label={"Date of Collection"}
              minutesStep={15}
              renderInput={(props) => (
                <TextField
                  {...props}
                  helperText={
                    errors.dateTimeOfCollection &&
                    touched.dateTimeOfCollection &&
                    errors.dateTimeOfCollection
                  }
                  error={
                    !!(
                      errors.dateTimeOfCollection &&
                      touched.dateTimeOfCollection &&
                      errors.dateTimeOfCollection
                    )
                  }
                  fullWidth
                  variant={"outlined"}
                />
              )}
            />
          </LocalizationProvider>
        </StyledFormControl>
      </FormSection>
      <Divider />
      <FormSection>
        <SampleCount>Samples ({sampleStore.store.length})</SampleCount>
        <IconButton onClick={() => addSample()} size="large">
          <Add />
        </IconButton>
      </FormSection>
      <SampleContainer>
        {transition((props, { key }) => (
          <animated.div style={{ overflow: "hidden", ...props }} key={key}>
            <CreateSampleTypeForm indexer={key} />
          </animated.div>
        ))}
      </SampleContainer>
      <Box m={"20px"}>
        <Button
          disabled={isSubmitting || !sampleStore?.store?.length}
          color={"secondary"}
          variant={"contained"}
          type={"submit"}
        >
          Schedule
        </Button>
      </Box>
    </StyledForm>
  );
}
