import { CreateRunActivityValidationSchema } from "features/Runs/components/Forms/validation-schema";
import { Formik, FormikHelpers } from "formik";
import {
  RunTask,
  RunTaskInput,
} from "graphql/generated/schema-types";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import React from "react";
import { ThawFields } from "shared-components/FormFields/MethodFields/ThawFields";
import { StyledForm } from "shared-components/styled-components";

export function ThawForm({
  runActivity,
  handleSubmit,
}: {
  runActivity: RunTask;
  handleSubmit: (
    values: RunTaskInput,
    { setSubmitting }: FormikHelpers<RunTaskInput>
  ) => Promise<void> | void;
}): JSX.Element {
  const runActivityInput = RunActivityInputMapper(runActivity);
  return (
    <React.Fragment>
      <Formik
        onSubmit={handleSubmit}
        initialValues={runActivityInput}
        validationSchema={CreateRunActivityValidationSchema}
      >
        {(props) => {
          const { handleSubmit } = props;
          return (
            <StyledForm onSubmit={handleSubmit}>
              <ThawFields props={props} controlled disabled={true} />
            </StyledForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}
