import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  IPoolingNormalizationToolInternalState,
  PoolingNormalizationToolInternalState,
} from "./initial-state";
import { PoolingNormalizationToolInternalReducer } from "./reducer";

export function usePoolingNormalizationStateProvider(): {
  poolingNormalizationToolInternalState: IPoolingNormalizationToolInternalState;
  poolingNormalizationToolDispatch: IApolloDispatch<any>;
} {
  const poolingNormalizationToolInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );
  const poolingNormalizationToolDispatch = useMemo(
    () =>
      ApolloStateDispatch(
        PoolingNormalizationToolInternalState,
        PoolingNormalizationToolInternalReducer
      ),
    []
  );
  return {
    poolingNormalizationToolInternalState,
    poolingNormalizationToolDispatch,
  };
}
