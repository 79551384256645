import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  PaperProps,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { RepeatedTaskInfo } from "features/RunTemplate/components/Forms/Create/Task/SlideOver";
import { StateProp } from "interfaces/StateProp";
import React, { Dispatch } from "react";
import Draggable from "react-draggable";
import { StyledFormControl } from "shared-components/styled-components";
import styled from "styled-components";

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
export function RunTaskScheduleDialog({
  dialogState: { setOpen, open },
  repeatTaskInfoState,
}: {
  dialogState: { open: boolean; setOpen: Dispatch<boolean> };
  repeatTaskInfoState: StateProp<RepeatedTaskInfo>;
}): JSX.Element {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent}>
        <DayInterval repeatTaskInfoState={repeatTaskInfoState} />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const TextWrapper = styled.div`
  margin: 10px 0;
  padding: 5px;
`;
const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
function DayInterval({
  repeatTaskInfoState,
}: {
  repeatTaskInfoState: StateProp<RepeatedTaskInfo>;
}) {
  const { state: repeatedTaskInfo, setState: setRepeatedTaskInfo } =
    repeatTaskInfoState;
  return (
    <StyledDialogContent>
      <TextWrapper>Repeat task every</TextWrapper>
      <StyledFormControl width="60px">
        <TextField
          margin="none"
          id="interval"
          type="number"
          value={repeatedTaskInfo.day?.dayInterval}
          onChange={(e) =>
            setRepeatedTaskInfo({
              ...repeatedTaskInfo,
              day: {
                ...repeatedTaskInfo.day,
                dayInterval: parseInt(e.target.value),
              },
            })
          }
        />
      </StyledFormControl>
      <TextWrapper>days until day</TextWrapper>
      <StyledFormControl width="60px">
        <TextField
          margin="none"
          id="duration"
          type="number"
          value={repeatedTaskInfo.day?.endDay}
          onChange={(e) =>
            setRepeatedTaskInfo({
              ...repeatedTaskInfo,
              day: {
                ...repeatedTaskInfo.day,
                endDay: parseInt(e.target.value),
              },
            })
          }
        />
      </StyledFormControl>
    </StyledDialogContent>
  );
}
