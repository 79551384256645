import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLayoutStateProvider } from "features/Plates/state/StateProvider";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React from "react";
import styled from "styled-components";
import { Plate } from "./helpers";

const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${GetTableCellBorder};
  padding: 7px 10px !important;
  white-space: pre-line;
`;

const HeaderCell = styled(StyledTableCell)`
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "black" : "#d6d6d6"} !important;
  border-bottom: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? "solid 1px white"
      : "solid 1px #000"} !important;
  text-align: center;
`;

const PlateNotes = styled(TableContainer)`
  border: 1px solid rgba(0, 0, 0, 0.3);
`;

export function PlateNotesTable({
  selectedPlate,
}: {
  selectedPlate: Plate;
}): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const {
    layoutState: { wellListOpen },
  } = useLayoutStateProvider();

  //--------------------------------------------------------------- Initialize WellListTable ------------------------------------------------------------//
  const data = selectedPlate ? selectedPlate.notes?.flat() : [];

  const fields = ["Note Type", "Descriptor", "Note", "Date Commented", "User"];

  return (
    <React.Fragment>
      <Paper elevation={3} style={{ margin: "10px 20px 20px 20px" }}>
        {selectedPlate && wellListOpen ? (
          <PlateNotes>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {fields.map((field, idx) => (
                    <HeaderCell align="center" key={idx}>
                      {field}
                    </HeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((note, idx) => (
                  <TableRow key={idx}>
                    <StyledTableCell align="center">
                      {note.type}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {selectedPlate.plateBarcode +
                        (note.rowCol ? "\n(" + note.rowCol + ")" : "")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {note.note}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {note.dateCommented}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {note.user}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PlateNotes>
        ) : (
          <React.Fragment />
        )}
      </Paper>
    </React.Fragment>
  );
}
