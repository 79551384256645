import { Methods } from "enums/Methods";
import { CeligoScan } from "features/Dashboard/MethodDetails/CeligoScan/CeligoScan";
import {
  DashboardTask,
  Maybe,
  TaskTypeEnum,
} from "graphql/generated/schema-types";
import React from "react";
import { SystemOneFeed } from "./SystemOneFeed";
import { SystemOnePassageContainer } from "./SystemOnePassage/SystemOnePassageContainer";
import { ThawReservation } from "./ThawReservation";

interface MethodReservationFormProps {
  selectedTask: Maybe<DashboardTask>;
}

export function MethodReservationForm({
  selectedTask,
}: MethodReservationFormProps): JSX.Element {
  return <>{MethodReducer(selectedTask)}</>;
}

function MethodReducer(selectedTask: Maybe<DashboardTask>): JSX.Element {
  switch (selectedTask?.methodId ?? 0) {
    case Methods.XTY_CustomPassage:
      return selectedTask?.taskType === TaskTypeEnum.RunTask ? (
        <SystemOnePassageContainer selectedTask={selectedTask!} />
      ) : (
        <></>
      );
    case Methods.AutomatedFeed:
      return <SystemOneFeed selectedTask={selectedTask!} />;
    case Methods.CeligoScan:
      return <CeligoScan personalTask={selectedTask!} />;
    case Methods.Thaw:
      return <ThawReservation selectedTask={selectedTask!} />;
    default:
      return <React.Fragment />;
  }
}
