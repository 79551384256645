import { useMemo } from "react";
import {
  UpdateRunInput,
  useGetRunsQuery,
  useUpdateRunMutation,
} from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import { RunInputMapper } from "mappings/runInput";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { EditRunForm } from "./Form";

export function EditRunModal(): JSX.Element {
  const windowSize = useWindowSize();
  const {
    runsModalDispatch,
    runsModalState: { selectedRunId },
  } = useRunsModalState();
  const { loading, data } = useGetRunsQuery({
    variables: { where: { runId: { eq: selectedRunId } } },
  });
  const [updateRun] = useUpdateRunMutation();
  const handleSubmit = (updateRunInput: UpdateRunInput) => {
    if (selectedRunId)
      return updateRun({
        variables: {
          input: {
            primaryKey: selectedRunId ?? 0,
            set: updateRunInput,
          },
        },
      })
        .then(() => runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL }))
        .then(() =>
          Alert({ type: AlertType.SUCCESS, message: "Changes Saved" })
        );
  };

  const run = useMemo(() => data?.runs?.[0], [data]);
  const initialValue = RunInputMapper(run);
  initialValue.parentFolderId = run?.folderParentId;
  return (
    <>
      {!loading && (
        <StyledCard
          min_width={
            windowSize.width! > 2000
              ? "20vw"
              : windowSize.width! > 1500
              ? "35vw"
              : "50vw"
          }
        >
          <EditRunForm initialValues={initialValue} onSubmit={handleSubmit} />
        </StyledCard>
      )}
    </>
  );
}
