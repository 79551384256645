import { css } from "@emotion/react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  Maybe,
  RunTaskInput,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import moment from "moment";
import { useMemo } from "react";
import { useTheme } from "styled-components/macro";

const StyledCell = ({ ...others }: TableCellProps) => {
  const theme = useTheme();
  const border = useMemo(() => GetTableCellBorder({ theme }), [theme]);
  return (
    <TableCell
      {...others}
      // @ts-ignore
      css={css`
        border-right: 1px solid ${border};
        color: ${theme.palette.mode === "dark" ? "pink" : "blue"}!important;
      `}
    />
  );
};

const getTableBody = (tasks: (Maybe<RunTaskInput> | undefined)[]) =>
  !tasks?.length || tasks.length === 0 ? (
    <div>No Tasks to Cancel</div>
  ) : (
    <TableBody>
      {tasks.map((task, index) => (
        <TableRow key={index}>
          <TableCell>{task?.activityName}</TableCell>
          <TableCell>
            {moment(task?.startTimeScheduled).format("LLLL")}
          </TableCell>
          <StyledCell>{TaskStatusEnum.Cancelled}</StyledCell>
        </TableRow>
      ))}
    </TableBody>
  );

export function PreviewCancelledTasks({
  tasks,
}: {
  tasks: (Maybe<RunTaskInput> | undefined)[];
}): JSX.Element {
  return (
    <TableContainer style={{ maxHeight: "50vh" }}>
      <Table component={Paper} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Task Name</TableCell>
            <TableCell>Date Scheduled</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        {getTableBody(tasks)}
      </Table>
    </TableContainer>
  );
}
