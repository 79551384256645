import { DatePicker } from "@mui/lab";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  CollectionTaskInput,
  LookupArraySystem,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  GroupField,
  SystemField,
  UserField,
} from "shared-components/FormFields/FormFields";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { FormikSubmit } from "types/types";

export function UpdateSampleTaskForm({
  onSubmit,
  initialValues,
}: {
  initialValues: CollectionTaskInput;
  onSubmit: FormikSubmit<CollectionTaskInput>;
}): JSX.Element {
  const { values, isSubmitting, errors, touched, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
    });
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <TextField
          variant={"outlined"}
          label={"Task Name"}
          value={values.task}
          onChange={(e) => {
            setFieldValue("task", e.target.value);
          }}
          helperText={errors.task && touched.task && errors.task}
          error={!!(errors.task && touched.task && errors.task)}
          fullWidth
        />
      </StyledFormControl>
      <StyledFormControl>
        <DatePicker
          value={values.startTimeScheduled ?? null}
          onChange={(date) => setFieldValue("startTimeScheduled", date)}
          label={"Start Time Scheduled"}
          renderInput={(props) => (
            <TextField
              {...props}
              helperText={
                errors.startTimeScheduled &&
                touched.startTimeScheduled &&
                errors.startTimeScheduled
              }
              error={
                !!(
                  errors.startTimeScheduled &&
                  touched.startTimeScheduled &&
                  errors.startTimeScheduled
                )
              }
              fullWidth
              variant={"outlined"}
            />
          )}
        />
      </StyledFormControl>
      <StyledFormControl>
        <UserField
          id={"user-field"}
          value={values.userAssignedTo}
          onChange={(event, newValue) => {
            const userId = (newValue as Maybe<AppSuiteUser>)?.userId;
            setFieldValue("userAssignedTo", userId);
          }}
          variant={"outlined"}
          label={"User"}
          helperText={
            errors.userAssignedTo &&
            touched.userAssignedTo &&
            errors.userAssignedTo
          }
          error={
            !!(
              errors.userAssignedTo &&
              touched.userAssignedTo &&
              errors.userAssignedTo
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <GroupField
          id={"group-field"}
          variant={"outlined"}
          value={values.groupAssignedTo}
          label={"Group"}
          onChange={(event, newValue) => {
            const groupId = (newValue as Maybe<AppSuiteGroup>)?.groupId;
            setFieldValue("groupAssignedTo", groupId);
          }}
          helperText={
            errors.groupAssignedTo &&
            touched.groupAssignedTo &&
            errors.groupAssignedTo
          }
          error={
            !!(
              errors.groupAssignedTo &&
              touched.groupAssignedTo &&
              errors.groupAssignedTo
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <SystemField
          id={"system-field"}
          variant={"outlined"}
          value={values.systemAssignedTo}
          label={"System"}
          onChange={(event, newValue) => {
            const systemId = (newValue as Maybe<LookupArraySystem>)?.systemId;
            setFieldValue("systemAssignedTo", systemId);
          }}
          helperText={
            errors.systemAssignedTo &&
            touched.systemAssignedTo &&
            errors.systemAssignedTo
          }
          error={
            !!(
              errors.systemAssignedTo &&
              touched.systemAssignedTo &&
              errors.systemAssignedTo
            )
          }
        />
      </StyledFormControl>
      <Button disabled={isSubmitting} variant={"contained"} type={"submit"}>
        Save Changes
      </Button>
    </StyledForm>
  );
}
