import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { contextMenu } from "react-contexify";
import styled, { DefaultTheme } from "styled-components";
import { RunTemplateStage } from "graphql/generated/schema-types";
import { RunTemplateAction } from "../../state/actions";
import { useRunTemplateStateProvider } from "../../state/StateProvider";
import { TimelineCard } from "./Node";

export type RunTemplateTreeNode = NonNullable<
  NonNullable<RunTemplateStage["runTemplateTreeNodes"]>[number]
>;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-left: 5px;
`;
const StyledTypography = styled(Typography)`
  color: ${({
    selected,
    theme,
  }: {
    selected?: boolean;
    theme?: DefaultTheme;
  }) => (selected ? theme!.palette.secondary.main : "white")};
`;
export function ChildTemplateCard({
  node,
}: {
  node: RunTemplateTreeNode;
}): JSX.Element {
  const {
    runTemplateState: { selectedTemplateId, templateSubTree },
    runTemplateDispatch,
  } = useRunTemplateStateProvider();
  return (
    <TimelineCard
      elevation={3}
      color={"green"}
      onContextMenu={(e) => {
        e.preventDefault();
        contextMenu.show({
          id: "child-template-card-context-menu",
          event: e,
          props: node,
        });
      }}
    >
      <Container>
        <StyledTypography
          variant={"h5"}
          selected={node?.child?.runTemplateId === selectedTemplateId}
        >
          <Box fontWeight="fontWeightBold">{`${node?.child?.runTemplateName}`}</Box>
          <StyledTypography>{`${node?.child?.numberOfTasks} Task(s)`}</StyledTypography>
          {!!node.child?.numberOfSubTemplates && (
            <StyledTypography>{`${node.child?.numberOfSubTemplates} Sub-Template(s)`}</StyledTypography>
          )}
        </StyledTypography>
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={() => {
            if (node?.child?.runTemplateId)
              runTemplateDispatch({
                type: RunTemplateAction.TRAVERSE_TEMPLATE_TREE,
                payload: {
                  templateSubTree: [...(templateSubTree ?? []), node],
                },
              });
          }}
        >
          View
        </Button>
      </Container>
    </TimelineCard>
  );
}
