import { gql } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import { filterDirectoryByText } from "cache/fields/searchRunFolders";
import { DocumentNode } from "graphql";
import { TypedTypePolicies } from "graphql/generated/schema-types";
import { getByIdFields } from "./fields/get-by-id";
import { keyFields } from "./key-fields";

interface ITypePoliciesSchema {
  typeDefs?: DocumentNode;
  typePolicies: TypedTypePolicies;
}

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      ...getByIdFields,
      runTasks: relayStylePagination(),
      tickets: relayStylePagination(),
      incomingSampleTasks: relayStylePagination(),
      incomingSampleCollections: relayStylePagination(),
      runTemplates: relayStylePagination(),
      plates: relayStylePagination(),
      samples: relayStylePagination(),
      containers: relayStylePagination(),
      allUserAndGroupTasks: {
        merge(existing, incoming) {
          return incoming;
        },
      },
      searchDirectory: {
        read: filterDirectoryByText,
      },
    },
  },
  ...keyFields,
};
export const TypePoliciesSchema: ITypePoliciesSchema = {
  typeDefs: gql`
    extend type Query {
      user(id: Int!, keyField: String!, typeName: String!): AppSuiteUser
      usersFromCache(
        ids: [Int!]!
        keyField: String!
        typeName: String!
      ): [AppSuiteUser]!
      group(id: Int!, keyField: String!, typeName: String!): AppSuiteGroup
      serverGroupState(
        id: Int!
        keyField: String!
        typeName: String!
      ): GroupLoginState
      groupsFromCache(
        ids: [Int!]
        keyField: String!
        typeName: String!
      ): [AppSuiteGroup]
      system(id: Int!, keyField: String!, typeName: String!): LookupArraySystem
      ticket(id: Int!, keyField: String!, typeName: String!): Ticket
      run(id: Int!, keyField: String!, typeName: String!): Run
      runTask(id: Int!, keyField: String!, typeName: String!): RunTask
      runTasksFromCache(
        ids: [Int!]!
        keyField: String!
        typeName: String!
      ): [RunTask!]!
      runTemplate(id: Int!, keyField: String!, typeName: String!): RunTemplate
      productionRunType(
        id: Int!
        keyField: String!
        typeName: String!
      ): ProductionRunType
      runTemplateStage(
        id: Int!
        keyField: String!
        typeName: String!
      ): RunTemplateStage
      runTemplateTask(
        id: Int!
        keyField: String!
        typeName: String!
      ): RunTemplateTask
      method(id: Int!, keyField: String!, typeName: String!): LookupArrayMethod
      incomingSamplesCollection(
        id: Int!
        keyField: String!
        typeName: String!
      ): Collection
      incomingSamplesTask(
        id: Int!
        keyField: String!
        typeName: String!
      ): CollectionTask
      incomingSamplesTasksFromCache(
        ids: [Int!]!
        keyField: String!
        typeName: String!
      ): [CollectionTask!]!
      folder(id: Int!, keyField: String!, typeName: String!): FolderStructure
      manualTaskType(
        id: Int!
        keyField: String!
        typeName: String!
      ): ManualTaskType
      searchDirectory(search: String!): [DirectoryFolder!]!
      plate(id: String!, keyField: String!, typeName: String!): PlatesCreated
      sample(id: Int!, keyField: String!, typeName: String!): Sample
      container(id: Int!, keyField: String!, typeName: String!): Container
    }
  `,
  typePolicies,
};
