import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { FilterState } from "features/Plates/components/Table/Table";
import _ from "lodash";
import React from "react";
import styled, { DefaultTheme } from "styled-components";

interface FilterProps {
  filter: FilterState;
  handler: (filterField: string, filterVal: string) => void;
}

const TextFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  padding: 0 0 5% 0;
  justify-self: center;
`;

const CheckboxLabel = styled(Typography)`
  background-color: ${({
    $inactive,
    theme,
  }: {
    theme: DefaultTheme;
    $inactive: boolean;
  }) =>
    theme.palette.mode === "dark"
      ? $inactive
        ? "rgb(10, 10, 10)"
        : "rgba(255, 255, 255, 0)"
      : $inactive
      ? "rgba(0, 0, 0, .1)"
      : "rgba(255, 255, 255, 0)"};
  color: ${({
    $inactive,
    theme,
  }: {
    theme: DefaultTheme;
    $inactive: boolean;
  }) =>
    theme.palette.mode === "dark"
      ? $inactive
        ? "rgb(150, 150, 150)"
        : "white"
      : "black"};
`;

export function TextFilter({
  filter: { field, value },
  handler,
}: FilterProps): JSX.Element {
  return (
    <TextFilterContainer>
      <Typography padding={"2%"} variant="h6" fontSize="130%" fontWeight="500">
        Plate Barcode:{" "}
      </Typography>
      <TextField
        size={"small"}
        label={"Contains"}
        autoFocus={true}
        value={value || ""}
        onChange={(e) => handler(field, e.target.value)}
      />
    </TextFilterContainer>
  );
}

const CheckboxFilterContainer = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  flex-direction: column;
  justify-self: center;
  width: 70%;
  padding: 0% 3% 3% 3%;
`;

const options = ["active", "inactive"];

export function MultiSelectFilter({
  filter: { field, value },
  handler,
}: FilterProps): JSX.Element {
  const valArray: Array<string> = [];
  switch (value) {
    case "all":
      valArray.push("active", "inactive");
      break;
    case "active":
      valArray.push("active");
      break;
    case "inactive":
      valArray.push("inactive");
      break;
    default:
      break;
  }
  // console.log(value);
  return (
    <CheckboxFilterContainer>
      {options.map((option, idx) => (
        <FormControlLabel
          style={{ marginRight: "0", marginLeft: "0" }}
          key={idx}
          control={
            <Checkbox
              style={{ padding: "0", marginRight: "5px" }}
              size="small"
              checked={valArray.includes(option)}
              onChange={(e) => {
                if (e.target.checked) {
                  valArray.push(option);
                  const filterval = valArray.length === 2 ? "all" : option;
                  console.log("Status", valArray, filterval);
                  handler(field, filterval);
                } else {
                  const valArrFiltered = valArray.filter(
                    (elem) => elem !== option
                  );
                  const filterval =
                    valArrFiltered.length === 1 ? valArrFiltered[0] : "none";
                  console.log("Status", valArrFiltered, filterval);
                  handler(field, filterval);
                }
              }}
            />
          }
          label={
            <CheckboxLabel
              noWrap
              textAlign="center"
              fontSize="95%"
              fontWeight="400"
              $inactive={option === "inactive"}
            >
              {"Show " + _.startCase(option)}
            </CheckboxLabel>
          }
        />
      ))}
    </CheckboxFilterContainer>
  );
}
