import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import {
  DashboardTask,
  TaskTypeEnum,
  useGetTaskHistoryQuery,
  WebSuiteTaskLog,
} from "graphql/generated/schema-types";
import { useGetUser } from "hooks/cacheAccessHooks";
import moment from "moment";
import React from "react";

interface TaskHistoryDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedTask: DashboardTask;
}

interface TaskHistoryRowProps {
  taskHistory: WebSuiteTaskLog;
}

export const TaskHistoryDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: TaskHistoryDialogProps) => {
  const getTaskTypeId = (taskType: TaskTypeEnum) => {
    switch (taskType) {
      case TaskTypeEnum.IncomingSample:
        return 1;
      case TaskTypeEnum.RunTask:
        return 2;
      case TaskTypeEnum.Personal:
        return 3;
    }
  };

  const { data: taskHistory } = useGetTaskHistoryQuery({
    variables: {
      where: {
        taskId: { eq: selectedTask.taskId },
        taskTypeId: { eq: getTaskTypeId(selectedTask.taskType) },
      },
    },
  });

  const onHandleClose = () => {
    handleClose();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onHandleClose}>
        <DialogTitle>Task History</DialogTitle>
        <DialogContent>
          <ul>
            {taskHistory?.taskHistory?.map((item, i) => (
              <TaskHistoryRow taskHistory={item as WebSuiteTaskLog} />
            ))}
          </ul>
        </DialogContent>
      </Dialog>
    </>
  );
};

const TaskHistoryRow = ({ taskHistory }: TaskHistoryRowProps) => {
  const timestamp = moment(taskHistory.actionPerformedTime).format(
    "MM/DD hh:mm A"
  );
  const performedBy = useGetUser(taskHistory.actionPerformedBy)?.userName ?? "System";
  return (
    <li>
      {`${timestamp} [${performedBy}]`}
      <br />
      {taskHistory.actionPerformed}
    </li>
  );
};
