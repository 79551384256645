import {
  CreateRunTemplateStageInput,
  Scalars,
  UpdateRunTemplateStageInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class CreateRunTemplateStageInputStub
  implements CreateRunTemplateStageInput
{
  runTemplateId: Scalars["Int"];
  runTemplateStageId: Scalars["Int"];
  stageSeqNo: Maybe<Scalars["Int"]>;
  templateStageName: Scalars["String"];
}
export class UpdateRunTemplateStageInputStub
  implements UpdateRunTemplateStageInput
{
  runTemplateId: Scalars["Int"];
  runTemplateStageId: Scalars["Int"];
  stageSeqNo: Maybe<Scalars["Int"]>;
  templateStageName: Scalars["String"];
}
