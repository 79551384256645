import {
  IRunTemplateAction,
  RunTemplateAction,
} from "features/RunTemplate/state/actions";
import { IRunTemplateState } from "features/RunTemplate/state/initial-state";
import produce from "immer";

export function RunTemplateReducer(
  state: IRunTemplateState,
  action: IRunTemplateAction
): IRunTemplateState {
  switch (action.type) {
    case RunTemplateAction.TEMPLATE_ADDED:
      return {
        ...state,
        selectedTemplateId:
          action.payload?.selectedTemplateId ?? state.selectedTemplateId,
        selectedStageId:
          action.payload?.selectedStageId ?? state.selectedStageId,
        selectedTaskId: action.payload?.selectedTaskId ?? state.selectedTaskId,
        slideOverOpen: action.payload?.selectedTemplateId
          ? false
          : state.slideOverOpen,
        modalOpen: false,
      };
    case RunTemplateAction.TEMPLATE_DELETED:
      return {
        ...state,
        selectedTemplateId: undefined,
        dialogOpen: false,
      };
    case RunTemplateAction.SELECT:
      return {
        ...state,
        selectedTemplateId:
          action.payload?.selectedTemplateId ?? state.selectedTemplateId,
        selectedStageId:
          action.payload?.selectedStageId ?? state.selectedStageId,
        selectedTaskId: action.payload?.selectedTaskId ?? state.selectedTaskId,
        slideOverOpen: action.payload?.selectedTemplateId
          ? false
          : state.slideOverOpen,
        inlineEditOpen: false,
      };
    case RunTemplateAction.DELETE:
      return {
        ...state,
        dialogOpen: true,
        selectedTimelineNodeType: action.payload?.selectedTimelineNodeType,
        selectedTemplateId:
          action.payload?.selectedTemplateId ?? state.selectedTemplateId,
        selectedStageId:
          action.payload?.selectedStageId ?? state.selectedStageId,
        selectedTaskId: action.payload?.selectedTaskId ?? state.selectedTaskId,
        selectedChildTemplateNodeId:
          action.payload?.selectedChildTemplateNodeId ??
          state.selectedChildTemplateNodeId,
        inlineEditOpen: false,
      };
    case RunTemplateAction.OPEN_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = true;
        draft.currentModal = action.payload!.currentModal;
        draft.selectedTemplateId =
          action.payload!.selectedTemplateId ?? state.selectedTemplateId;
        draft.cloneTemplate = action.payload?.cloneTemplate ?? false;
      });
    case RunTemplateAction.CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = false;
      });
    case RunTemplateAction.CLOSE_CHILD_TEMPLATES:
      return produce(state, (draft) => {
        draft.templateSubTree = [];
      });
    case RunTemplateAction.TRAVERSE_TEMPLATE_TREE:
      return produce(state, (draft) => {
        draft.templateSubTree = action.payload?.templateSubTree;
      });
    case RunTemplateAction.CLOSE_DIALOG:
      return {
        ...state,
        dialogOpen: false,
      };
    case RunTemplateAction.OPEN_SLIDE_OVER:
      return {
        ...state,
        slideOverOpen: true,
        currentSlideOver: action.payload?.currentSlideOver,
        selectedTemplateId:
          action.payload?.selectedTemplateId ?? state.selectedTemplateId,
        selectedStageId:
          action.payload?.selectedStageId ?? state.selectedStageId,
        selectedTaskId: action.payload?.selectedTaskId ?? state.selectedTaskId,
        inlineEditOpen: false,
      };
    case RunTemplateAction.OPEN_INLINE_EDIT:
      return {
        ...state,
        inlineEditOpen: true,
        currentInlineEdit: action.payload?.currentInlineEdit,
        selectedTemplateId:
          action.payload?.selectedTemplateId ?? state.selectedTemplateId,
        selectedStageId:
          action.payload?.selectedStageId ?? state.selectedStageId,
        selectedTaskId: action.payload?.selectedTaskId ?? state.selectedTaskId,
        slideOverOpen: false,
      };
    case RunTemplateAction.CLOSE_SLIDE_OVER:
      return {
        ...state,
        slideOverOpen: false,
      };
    case RunTemplateAction.CLOSE_INLINE_EDIT:
      return {
        ...state,
        inlineEditOpen: false,
      };
    default:
      return state;
  }
}
