import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { MethodSettingsProps } from "../shared";

export const ReducedVolumeWellWashing = ({
  poolingInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={poolingInternalState.methodSettings.reducedVolWellWash}
            onChange={(e) =>
              handleMethodSettingsChanged(
                "reducedVolWellWash",
                e.target.checked
              )
            }
            name="checkedB"
            color="primary"
            size="small"
          />
        }
        label="Reduced Volume Well Washing"
      />
      <Tooltip
        title=" [recommended for bubbly media] selected washes will proceed 
            with a reduced percentage of the source well operating volume"
      >
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
