import { css } from "@emotion/react";
import {
  ArrowDownward,
  ArrowUpward,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import {
  Filters,
  Row,
  SortingRule,
  TableInstance,
  useAsyncDebounce,
} from "react-table";
import { GetSelectedDataFromSelectedFlatRows } from "selectors/react-table";
import { OpenFilterButton } from "shared-components/Table/Filters/OpenFilterButton";
import { RTBody } from "shared-components/Table/sub-components/Body";
import styled, { useTheme } from "styled-components/macro";
import { TableName } from "../../enums/TableName";
import {
  SavePresetDialog,
  TablePresetDialogOpen,
} from "./sub-components/SavePresetDialog";
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableFooter = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  max-width: 100vw;
`;

export const StyledTableRow = styled(TableRow).attrs(
  (props: { clickable?: "true" | "false" }) => props
)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: ${({ clickable }) => (clickable === "true" ? "pointer" : "")};
  }
`;

export const StyledTableCell = ({
  width,
  ...others
}: TableCellProps & { width?: string; border?: string }) => {
  const theme = useTheme();
  const border = useMemo(() => GetTableCellBorder({ theme }), [theme]);
  return (
    <TableCell
      {...others}
      // @ts-ignore
      css={css`
        border-right: 1px solid ${border};
        width: ${width};
      `}
    />
  );
};

export interface ReactTablePaginationState {
  page: number;
  rowsPerPage: number;
  totalCount: number;
}

export type ReactTableRowClickHandler<TData extends Record<string, unknown>> = (
  rowClicked?: Row<TData>
) => (e: React.MouseEvent<HTMLTableRowElement>) => void;

export type ReactTableContextMenuHandler<
  TData extends Record<string, unknown>
> = (
  selectedRows: TData[],
  rowClicked?: Row<TData>
) => (e: React.MouseEvent<HTMLTableRowElement>) => void;

export const PageSizeOptions = [10, 25, 50, 100, 250];

interface ReactTableProps<TData extends Record<string, unknown>> {
  loading?: boolean;
  tableInstance: TableInstance<TData>;
  onFilter?: (filters: Filters<TData>) => void;
  onSort?: (sortingRules: SortingRule<TData>[]) => void;
  onSelect?: (selected: TData[]) => void;
  stateChangeDebounceDuration: number;
  paginationState?: ReactTablePaginationState;
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void;
  onPageCountChange?: (newPageCount: number) => void;
  onRowContextMenu?: ReactTableContextMenuHandler<TData>;
  onRowClick?: ReactTableRowClickHandler<TData>;
  name?: TableName;
  size?: "small" | "medium";
}

export function ReactTable<TData extends Record<string, unknown>>({
  tableInstance,
  onFilter,
  onSort,
  onSelect,
  stateChangeDebounceDuration,
  onPageChange,
  paginationState,
  onPageCountChange,
  onRowClick,
  onRowContextMenu,
  loading,
  size,
  name,
}: ReactTableProps<TData>): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    selectedFlatRows,
    state: { filters, sortBy },
    toggleAllRowsSelected,
  } = tableInstance;
  const enablePaging =
    !!paginationState && !!onPageChange && !!onPageCountChange;
  const filterOnDebounce = useAsyncDebounce(
    onFilter ?? (() => void 0),
    stateChangeDebounceDuration
  );
  const sortOnDebounce = useAsyncDebounce(
    onSort ?? (() => void 0),
    stateChangeDebounceDuration
  );
  useEffect(() => {
    filterOnDebounce(filters);
  }, [filterOnDebounce, filters]);

  useEffect(() => {
    sortOnDebounce(sortBy);
  }, [sortOnDebounce, sortBy]);

  const selected = useMemo(
    () => GetSelectedDataFromSelectedFlatRows(selectedFlatRows),
    [selectedFlatRows]
  );

  useEffect(() => {
    if (onSelect) onSelect(selected);
  }, [onSelect, selected, selectedFlatRows]);

  useEffect(() => {
    if (toggleAllRowsSelected) toggleAllRowsSelected(false);
  }, [paginationState, toggleAllRowsSelected]);

  useEffect(() => {
    const eventTriggers: ["keyup", "keydown"] = ["keyup", "keydown"];
    eventTriggers.forEach((event) => {
      window.addEventListener(event, (e: KeyboardEvent) => {
        document.onselectstart = function () {
          return !(e.key === "Shift" && e.shiftKey);
        };
      });
    });
    return () => {
      eventTriggers.forEach((event) => {
        window.removeEventListener(event, (e: KeyboardEvent) => {
          document.onselectstart = function () {
            return !(e.key === "Shift" && e.shiftKey);
          };
        });
      });
    };
  }, []);

  return (
    <>
      {name && (
        <SavePresetDialog
          tableName={name}
          state={tableInstance.state}
          pageSize={paginationState?.rowsPerPage ?? 10}
        />
      )}
      <Paper style={{ position: "relative" }}>
        <TableContainer
          style={{
            maxHeight: "80vh",
            minHeight: "30vh",
          }}
        >
          <Table {...getTableProps()} size={size ?? "small"} stickyHeader>
            <TableHead>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column, idx) => (
                        // Apply the header cell props
                        <StyledTableCell
                          width={column?.width?.toString()}
                          key={idx}
                        >
                          <HeaderContainer>
                            {column.canGroupBy ? (
                              // If the column can be grouped, let's add a toggle
                              <HeaderItem {...column?.getGroupByToggleProps()}>
                                {column.isGrouped ? (
                                  <RadioButtonChecked fontSize={"small"} />
                                ) : (
                                  <RadioButtonUnchecked fontSize={"small"} />
                                )}
                              </HeaderItem>
                            ) : (
                              <div />
                            )}
                            <HeaderItem
                              {...column.getHeaderProps(
                                column.canSort
                                  ? column.getSortByToggleProps()
                                  : undefined
                              )}
                            >
                              {
                                // Render the header
                                column.render("Header")
                              }
                              {column?.isSorted ? (
                                column?.isSortedDesc ? (
                                  <ArrowDownward fontSize={"small"} />
                                ) : (
                                  <ArrowUpward fontSize={"small"} />
                                )
                              ) : (
                                ""
                              )}
                            </HeaderItem>
                            <HeaderItem>
                              {column?.canFilter && (
                                <OpenFilterButton
                                  id={idx}
                                  active={
                                    column?.filterValue ? "true" : "false"
                                  }
                                >
                                  <>{column.render("Filter")}</>
                                </OpenFilterButton>
                              )}
                            </HeaderItem>
                          </HeaderContainer>
                        </StyledTableCell>
                      ))
                    }
                  </TableRow>
                ))
              }
            </TableHead>
            {/* Apply the table body props */}
            <TableBody {...getTableBodyProps()}>
              <RTBody
                tableInstance={tableInstance}
                onRowContextMenu={onRowContextMenu}
                onRowClick={onRowClick}
                paginationState={paginationState}
                loading={loading}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <TableFooter>
          {name ? (
            <Box p={1}>
              <Button
                variant={"contained"}
                color={"secondary"}
                onClick={() => TablePresetDialogOpen(true)}
              >
                Save Preset
              </Button>
            </Box>
          ) : (
            <div />
          )}
          {enablePaging ? (
            <TablePagination
              rowsPerPageOptions={PageSizeOptions}
              component="div"
              count={paginationState!.totalCount}
              page={paginationState!.page}
              onPageChange={onPageChange!}
              rowsPerPage={paginationState!.rowsPerPage}
              onRowsPerPageChange={(e: ChangeEvent<{ value: string }>) => {
                const newPageCount = parseInt(e.target.value, 10);
                onPageCountChange!(newPageCount);
              }}
            />
          ) : (
            <div />
          )}
        </TableFooter>
      </Paper>
    </>
  );
}
