import { format } from "date-fns";
import {
  ManagePersonalTask,
  Maybe,
  TaskPriorityEnum,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import * as _ from "lodash";
import moment from "moment";
import React from "react";
import { Column } from "react-table";
import { Pill } from "shared-components/styled-components";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { GetDropdownFilter } from "shared-components/Table/Filters/DropdownFilter";
import { GetGroupFilter } from "shared-components/Table/Filters/GroupFilter";
import { GetMultiSelectFilter } from "shared-components/Table/Filters/MultiCheckboxFilter";
import { SystemFilter } from "shared-components/Table/Filters/SystemFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import {
  GroupsCell,
  SystemCell,
  UsersCell,
} from "shared-components/Table/sub-components/CacheLookupCells";

export const TaskPriorityReducer = (
  priority: Maybe<TaskPriorityEnum> | undefined
) => {
  switch (priority) {
    case TaskPriorityEnum.Immediate:
      return <Pill color={"red"} label={"IMMEDIATE"} />;
    case TaskPriorityEnum.High:
      return <Pill color={"orange"} label={"HIGH"} />;
    case TaskPriorityEnum.Medium:
      return <Pill color={"green"} label={"MEDIUM"} />;
    case TaskPriorityEnum.Low:
      return <Pill color={"gray"} label={"LOW"} />;
    default:
      return null;
  }
};

export const TaskStatusReducer = (
  status: Maybe<TaskStatusEnum> | undefined
) => {
  switch (status) {
    case TaskStatusEnum.Scheduled:
      return <div style={{ color: "#0073f7" }}>Scheduled</div>;
    case TaskStatusEnum.InProgress:
      return <div style={{ color: "orange" }}>In Progress</div>;
    case TaskStatusEnum.Completed:
      return <div style={{ color: "green" }}>Completed</div>;
    case TaskStatusEnum.Overdue:
      return <div style={{ color: "red" }}>Overdue</div>;
    case TaskStatusEnum.Cancelled:
      return <div style={{ color: "gray" }}>Cancelled</div>;
    default:
      return <div></div>;
  }
};

const dropdownOptions = Object.values(TaskStatusEnum).map((value) => ({
  value,
  name: value,
}));
export const ManagePersonalTasksColumns: Array<Column<ManagePersonalTask>> = [
  {
    Header: "Task",
    accessor: "taskName",
    Filter: TextFilter,
    width: "50%",
  },
  {
    Header: "Priority",
    accessor: "priority",
    Filter: GetMultiSelectFilter(
      Object.entries(TaskPriorityEnum).map(([name, value]) => ({
        value,
        name,
      }))
    ),
    Cell: ({ value }) => TaskPriorityReducer(value),
    width: "10%",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: GetDropdownFilter(dropdownOptions),
    Cell: ({ value }) => TaskStatusReducer(value),
    width: "10%",
  },
  {
    Header: "User",
    accessor: "assignedUsers",
    Filter: GetUserFilter(),
    Cell: ({ value }) => (value ? <UsersCell userIds={value} /> : null),
    width: "10%",
  },
  {
    Header: "Group",
    accessor: "assignedGroups",
    Filter: GetGroupFilter(),
    Cell: ({ value }) => (value ? <GroupsCell groupIds={value} /> : null),
    width: "15%",
  },
  {
    Header: "System",
    accessor: "systemAssignedTo",
    Filter: SystemFilter,
    Cell: ({ value }) => (value ? <SystemCell systemId={value} /> : null),
    width: "15%",
  },
  {
    Header: "Date",
    accessor: "startTimeScheduled",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? moment(value).format("MM-DD-YYYY") : null),
    width: "20%",
    Aggregated: ({ row }) => {
      const dates = row.leafRows.map(
        (leaf) => new Date(leaf.original.startTimeScheduled)
      );
      const min = format(_.min(dates)!, "MM-dd-yyyy");
      const max = format(_.max(dates)!, "MM-dd-yyyy");
      return `${min} - ${max}`;
    },
  },
];
