import {
  ManualTaskTypeFilterInput,
  ManualTaskTypeFragment,
} from "graphql/generated/schema-types";
import { Filters } from "react-table";

export const ManualTaskFilterReducer = (
  tableFilters: Filters<ManualTaskTypeFragment>
) => {
  const where: ManualTaskTypeFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "name":
        where!.and!.push({
          name: { contains: tableFilter.value },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
