import { format } from "date-fns";
import { IncomingSampleCollectionFragment } from "graphql/generated/schema-types";
import { DistinctPrimitive } from "helpers/object-helpers";
import { Column } from "react-table";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import { UserCell } from "shared-components/Table/sub-components/CacheLookupCells";

export const IncomingSampleCollectionsTableColumns: Array<
  Column<IncomingSampleCollectionFragment>
> = [
  {
    Header: "Prefix",
    accessor: "project.nyscfprefix" as keyof IncomingSampleCollectionFragment,
    Filter: TextFilter,
    width: "7%",
  },
  {
    Header: "Project",
    accessor: "project.projectName" as keyof IncomingSampleCollectionFragment,
    Filter: TextFilter,
    width: "20%",
  },
  {
    Header: "Samples",
    accessor: "collectionSampleTypes",
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
    Cell: ({ value }) => {
      const samples =
        value?.map((sampleType) => sampleType?.sampleType?.sampleTypeString) ??
        [];
      return (
        DistinctPrimitive(samples)
          .map((x) => `${x} (${samples.filter((s) => s === x).length})`)
          .join(", ") ?? null
      );
    },
    width: "27%",
  },
  {
    Header: "Date of Arrival",
    accessor: "dateTimeOfArrival",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? format(new Date(value), "MM-dd-yyyy") : null),
    width: "12%",
  },
  {
    Header: "Date of Collection",
    accessor: "dateTimeOfCollection",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? format(new Date(value), "MM-dd-yyyy") : null),
    width: "12%",
  },
  {
    Header: "Date to Process",
    accessor: "collectionSampleTypes",
    id: "dateToProcess", //id lets us use same accessor on mult columns
    Filter: DateRangeFilter,
    Cell: ({ value }) => {
      const singleDateToProcess = value?.[0]?.dateToProcess;
      return singleDateToProcess
        ? format(new Date(singleDateToProcess), "MM-dd-yyyy")
        : null;
    },
    width: "12%",
  },

  {
    Header: "Created By",
    accessor: "createdBy",
    Filter: GetUserFilter(true),
    Cell: ({ value }) => (value ? <UserCell userId={value} /> : null),
    width: "10%",
  },
];
