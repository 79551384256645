import { Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { GetInitials } from "helpers/string-helpers";
import {
  useGetGroup,
  useGetGroupsById,
  useGetRunType,
  useGetSystem,
  useGetUser,
  useGetUsersById,
} from "hooks/cacheAccessHooks";
import React, { memo } from "react";

export const UserCell = memo(
  ({ userId }: { userId?: number | null }) => {
    const user = useGetUser(userId);
    return <div>{user?.userName}</div>;
  },
  (prev, current) => prev.userId === current.userId
);
export const UsersCell = memo(({ userIds }: { userIds?: number[] | null }) => {
  const users = useGetUsersById(userIds);
  return (
    <AvatarGroup max={2}>
      {users?.map((user) => (
        <Tooltip title={user?.userName ?? ""}>
          <Avatar>{GetInitials(user?.userName ?? "")}</Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
});
export const GroupsCell = memo(
  ({ groupIds }: { groupIds?: number[] | null }) => {
    const groups = useGetGroupsById(groupIds);
    return (
      <AvatarGroup max={2}>
        {groups?.map((group) => (
          <Tooltip title={group?.groupName ?? ""}>
            <Avatar>{group?.groupName?.substring(0, 1)}</Avatar>
          </Tooltip>
        ))}
      </AvatarGroup>
    );
  }
);
export const GroupCell = memo(
  ({ groupId }: { groupId?: number | null }) => {
    const group = useGetGroup(groupId);
    return <div>{group?.groupName}</div>;
  },
  (prev, current) => prev.groupId === current.groupId
);
export const SystemCell = memo(
  ({ systemId }: { systemId?: number | null }) => {
    const system = useGetSystem(systemId);
    return <div>{system?.systemName}</div>;
  },
  (prev, current) => prev.systemId === current.systemId
);
export const RunTypeCell = memo(
  ({ runTypeId }: { runTypeId?: number | null }) => {
    const runType = useGetRunType(runTypeId);
    return <div>{runType?.typeName}</div>;
  },
  (prev, current) => prev.runTypeId === current.runTypeId
);
