import { makeVar, useReactiveVar } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useFormik } from "formik";
import { useState } from "react";
import { TableState } from "react-table";
import styled, { useTheme } from "styled-components/macro";
import { useGetSaveTableSettings } from "hooks/useSaveUserSettings";
import { TableSetting } from "interfaces/AppUserSettings";
import { StyledFormControl } from "../../styled-components";
import { SelectedPreset } from "../hooks";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 300px;
`;
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;
export const TablePresetDialogOpen = makeVar<boolean>(false);
export function SavePresetDialog({
  tableName,
  state,
  pageSize,
}: {
  tableName: string;
  state: TableState;
  pageSize: number;
}) {
  const open = useReactiveVar(TablePresetDialogOpen);
  const [savePreset, loading] = useGetSaveTableSettings();
  const { handleSubmit, handleChange, values, setFieldValue } = useFormik<{
    name: string;
    default?: boolean;
  }>({
    initialValues: { name: "" },
    onSubmit(presetInfo) {
      const preset: TableSetting["presets"][number] = {
        name: presetInfo.name,
        default: presetInfo.default,
        state,
        pageSize,
      };
      savePreset(tableName, preset).then(() => TablePresetDialogOpen(false));
    },
  });
  return (
    <Dialog open={open} onClose={() => TablePresetDialogOpen(false)}>
      <Center>
        <DialogTitle>Save Preset</DialogTitle>
      </Center>
      <DialogContent>
        <StyledForm onSubmit={handleSubmit}>
          <StyledFormControl>
            <TextField
              name={"name"}
              variant={"outlined"}
              value={values.name}
              onChange={handleChange}
              label={"Name"}
              fullWidth
            />
          </StyledFormControl>
          <StyledFormControl>
            <FormControlLabel
              control={
                <Switch
                  name="defaultPreset"
                  onChange={(e) => {
                    setFieldValue("default", e.target.checked);
                  }}
                />
              }
              checked={!!values?.default}
              label={"Set as Default"}
            />
          </StyledFormControl>
          <Center>
            <Button variant={"contained"} type={"submit"} disabled={loading}>
              Save Preset
            </Button>
          </Center>
        </StyledForm>
      </DialogContent>
    </Dialog>
  );
}

const PresetSection = styled.div`
  width: 350px;
  height: 150px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function Preset({
  tableSettings,
  handlePresetChange,
}: {
  tableSettings: TableSetting;
  handlePresetChange: (preset: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const selectedPreset = useReactiveVar(SelectedPreset);

  return (
    <div>
      <IconButton
        size={"large"}
        color={theme.palette.mode === "dark" ? undefined : "secondary"}
        onClick={handleClick}
      >
        <Settings />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PresetSection>
          <div style={{ width: "300px" }}>
            <FormControl variant={"outlined"} fullWidth>
              <InputLabel>Table Preset</InputLabel>
              <Select
                label={"Tables Preset"}
                defaultValue={
                  selectedPreset ??
                  tableSettings?.presets?.find((p) => p?.default)?.name ??
                  "General"
                }
                onChange={(event) => {
                  handlePresetChange(event.target.value);
                  SelectedPreset(event.target.value);
                }}
                inputProps={{
                  name: "preset",
                  id: "preset",
                }}
              >
                {tableSettings?.presets?.map((preset) => (
                  <MenuItem key={preset.name} value={preset.name}>
                    {preset.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </PresetSection>
      </Popover>
    </div>
  );
}
