import React from "react";
import { AddRackOptions } from "../../constants";
import { DateSelector } from "../selectors/DateSelector";
import { FillRackSelector } from "../selectors/FillRackSelector";
import { NumberOfDuplicatesSelector } from "../selectors/NumberOfDuplicatesSelector";
import { PassageNumberSelector } from "../selectors/PassageNumberSelector";
import { RunSelector } from "../selectors/RunSelector";

interface iPSCExpansionProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const IPSCExpansion = ({
  values,
  setFieldValue,
}: iPSCExpansionProps) => {
  return (
    <React.Fragment>
      <RunSelector values={values} setFieldValue={setFieldValue} />
      <NumberOfDuplicatesSelector
        numberOfDuplicates={[1, 2, 3, 4, 5, 6]}
        values={values}
        setFieldValue={setFieldValue}
      />
      {values.run.includes("1T5") ? (
        <FillRackSelector values={values} setFieldValue={setFieldValue} />
      ) : null}
      <PassageNumberSelector values={values} setFieldValue={setFieldValue} />
      <DateSelector values={values} setFieldValue={setFieldValue} />
    </React.Fragment>
  );
};
