/* eslint-disable @typescript-eslint/ban-types */
import { FormikErrors, yupToFormErrors } from "formik";
import { Maybe } from "graphql/jsutils/Maybe";
import * as Yup from "yup";

export const ValidateObject = async <
  TFormValue extends Record<string, unknown> | {}
>(
  value: Maybe<TFormValue>,
  validationSchema: Yup.ObjectSchema<Yup.Shape<object | undefined, TFormValue>>
): Promise<FormikErrors<TFormValue> | undefined> => {
  let error: FormikErrors<TFormValue> | undefined;
  await validationSchema
    .validate(value, {
      abortEarly: false,
    })
    .catch((err) => (error = yupToFormErrors(err)));
  if (error) return error;
};
