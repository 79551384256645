import { Checkbox } from "@mui/material";
import React, { ForwardedRef } from "react";
interface Props {
  indeterminate?: boolean;
}

export const SelectRowCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = ref ?? defaultRef;

    React.useEffect(() => {
      if (typeof resolvedRef !== "function" && resolvedRef.current)
        resolvedRef.current.indeterminate = !!indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        inputRef={resolvedRef}
        {...rest}
        size={"small"}
        onClick={(e) => e.stopPropagation()}
      />
    );
  }
);
