import { Box, Popover } from "@mui/material";
import { Wrapper } from "interfaces/Wrapper";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import React from "react";

export function NavItemPopup({
  id,
  children,
  Button,
}: {
  id: number | string;
  Button: React.FC;
} & Wrapper): JSX.Element {
  return (
    <PopupState variant="popover" popupId={`${id}-popup`}>
      {(popupState) => (
        <div>
          <Button {...bindTrigger(popupState)} />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box p={1}>{children}</Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
