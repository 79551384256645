import { makeVar } from "@apollo/client";
import { UserManagerModals } from "../components/Modals/modals.enum";
import { UserManagerActions } from "./actions";

export interface IUserManagerState {
  modalOpen?: boolean;
  currentModal?: UserManagerModals;
  selectedUsers?: number[];
}

export interface IUserManagerAction {
  type: UserManagerActions;
  payload?: IUserManagerState;
}

export const UserManagerInitialState: IUserManagerState = {
  modalOpen: false,
};

export const UserManagerState = makeVar<IUserManagerState>(
  UserManagerInitialState
);
