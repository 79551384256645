import { ApolloQueryResult } from "@apollo/client/core/types";
import { FilterState } from "features/Plates/components/Table/Table";
import {
  GetPlatesQuery,
  GetPlatesQueryVariables,
  SortEnumType,
} from "graphql/generated/schema-types";
import { MutateState } from "helpers/object-helpers";

export const GetPlatesFilterHandler =
  (
    variables: GetPlatesQueryVariables | undefined,
    refetch: (
      variables?: Partial<GetPlatesQueryVariables>
    ) => Promise<ApolloQueryResult<GetPlatesQuery>>,
    filterReducer: (
      filters: Array<FilterState>
    ) => GetPlatesQueryVariables["where"]
  ) =>
  async (filters: Array<FilterState>): Promise<void> => {
    const _state = MutateState(variables, (draft) => {
      const filter = filterReducer(filters);
      if (draft) {
        draft.where = filter;
        draft.order = { plateCreatedId: SortEnumType.Desc };
      }
    });
    if (refetch && _state.hasChanged) {
      await refetch(_state.newValue as GetPlatesQueryVariables);
      console.log("PlatesTable Action: FILTER");
    }
  };
