import {
  AssetInput,
  CreateTicketInput,
  TicketPriority,
  TicketStatus,
  UpdateTicketInput,
} from "graphql/generated/schema-types";
import * as Yup from "yup";

export const CreateTicketValidationSchema =
  Yup.object().shape<CreateTicketInput>({
    assetId: Yup.number()
      .notOneOf([0], "Required")
      .nullable()
      .required("Required"),
    assetTypeId: Yup.number()
      .notOneOf([0], "Required")
      .nullable()
      .required("Required"),
    dateEncountered: Yup.date()
      .typeError("Invalid Date")
      .required("Required")
      .nullable(),
    description: Yup.string()
      .notOneOf([""], "Required")
      .min(10, "Too Short!")
      .required("Required"),
    priority: Yup.mixed<TicketPriority>()
      .oneOf(Object.values(TicketPriority))
      .required("Required"),
    isActive: Yup.boolean().required(),
    ticketTypeId: Yup.number()
      .moreThan(0, "Required")
      .nullable()
      .required("Required"),
  });

export const CreateAssetValidationSchema = Yup.object().shape<AssetInput>({
  asset: Yup.string().required("Required").min(3, "Too Short!"),
  assetTypeId: Yup.number().required("Required").moreThan(0, "Required"),
});

export const UpdateTicketValidationSchema =
  Yup.object().shape<UpdateTicketInput>({
    status: Yup.mixed<TicketStatus>()
      .oneOf(Object.values(TicketStatus))
      .required("Required"),
    assetId: Yup.number()
      .moreThan(0, "Required")
      .nullable()
      .required("Required"),
    dateEncountered: Yup.date()
      .typeError("Invalid Date")
      .required("Required")
      .nullable(),
    description: Yup.string()
      .notOneOf([""], "Required")
      .min(10, "Too Short!")
      .required("Required"),
    priority: Yup.mixed<TicketPriority>()
      .oneOf(Object.values(TicketPriority))
      .required("Required"),
    ticketTypeId: Yup.number()
      .moreThan(0, "Required")
      .nullable()
      .required("Required"),
  });

export const MergeTicketValidationSchema = Yup.object().shape<{
  mergeInto: number;
}>({
  mergeInto: Yup.number()
    .moreThan(0, "Required")
    .nullable()
    .required("Required"),
});
