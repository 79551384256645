import { Divider, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface NormalizedPassageWarningProps {
  requiredWarnings: string[];
  optionalWarnings: string[];
}

const StyledListItem = styled.li`
  font-size: 14px;
`;

export const WorklistToolWarnings = ({
  requiredWarnings,
  optionalWarnings,
}: NormalizedPassageWarningProps) => {
  const RequiredWarnings = () => (
    <ul>
      <Typography color="red">Required</Typography>
      {requiredWarnings.map((item: string) => (
        <StyledListItem key={item}>{item}</StyledListItem>
      ))}
    </ul>
  );

  const OptionalWarnings = () => (
    <ul>
      <Typography color="yellow">Optional</Typography>
      {optionalWarnings.map((item: string) => (
        <StyledListItem key={item}>{item}</StyledListItem>
      ))}
    </ul>
  );
  return (
    <React.Fragment>
      {requiredWarnings.length > 0 ? <RequiredWarnings /> : null}
      <Divider />
      {optionalWarnings.length > 0 ? <OptionalWarnings /> : null}
      {requiredWarnings.length === 0 && optionalWarnings.length === 0
        ? "Generate Worklist"
        : null}
    </React.Fragment>
  );
};
