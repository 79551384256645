import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  LookupArraySystem,
  useGetSystemsQuery,
} from "graphql/generated/schema-types";

export interface AssignedSystemProps {
  setFieldValue: (field: string, value: any) => void;
  systems: LookupArraySystem[];
  touched: boolean | undefined;
  errors: string | undefined;
  averageRunTime: number;
}

export const AssignedSystem = (props: AssignedSystemProps) => {
  const systemsQuery = useGetSystemsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const bySystemId = (a: LookupArraySystem, b: LookupArraySystem) => {
    if (!a?.systemId && !b?.systemId) return 0;
    if (!b?.systemId || (a?.systemId && a.systemId > b.systemId)) return 1;
    if (!a?.systemId || (b?.systemId && a.systemId < b.systemId)) return -1;
    return 0;
  };

  const options =
    (props.systems.length
      ? props.systems
      : systemsQuery.data?.systems?.map((s) => s as LookupArraySystem)
    )?.sort(bySystemId) ?? [];

  return (
    <>
      <Autocomplete
        id="systemAssignedTo"
        disableClearable
        options={options}
        getOptionLabel={(option) => option!.systemName!}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assigned System"
            variant="outlined"
            error={props.touched && props.errors !== ""}
            helperText={props.errors}
          />
        )}
        isOptionEqualToValue={(option, value) =>
          option!.systemId === value!.systemId
        }
        onChange={(_e, value) =>
          props.setFieldValue("systemAssignedTo", value.systemId)
        }
      />
      {props.averageRunTime > 0
        ? `Average run time: ${props.averageRunTime} minute(s)`
        : ""}
    </>
  );
};
