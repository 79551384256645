import { ApolloQueryResult } from "@apollo/client";
import { client } from "cache/cache";
import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
} from "graphql/generated/schema-types";

interface AuthService {
  authCheck(): Promise<ApolloQueryResult<GetMeQuery>>;
  getGoogleClientId(): string;
}

export default {
  authCheck() {
    return client.query<GetMeQuery, GetMeQueryVariables>({
      query: GetMeDocument,
    });
  },
  getGoogleClientId() {
    return "715047902419-k784rb71r185qab7eo5sk03lh6v7fksk.apps.googleusercontent.com";
  },
} as AuthService;
