import { animated, useTransition } from "@react-spring/web";
import { FC } from "react";
import styled from "styled-components/macro";
import {
  PersonalTaskAttachment,
  RunTaskAttachment,
  TicketAttachment,
} from "graphql/generated/schema-types";
import { GetUrl } from "helpers/get-url";
import { fileIsImage } from "./AttachmentCarousel";

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
export const CarouselTransition: FC<{
  attachments:
    | TicketAttachment[]
    | PersonalTaskAttachment[]
    | RunTaskAttachment[];
  index: number;
  reverse: boolean;
}> = ({ attachments, index, reverse }) => {
  const transitions = useTransition(index, {
    keys: null,
    from: {
      opacity: 0,
      transform: !reverse ? "translate3d(100%,0,0)" : "translate3d(-100%,0,0)",
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: {
      opacity: 0,
      display: "none",
      transform: reverse ? "translate3d(100%,0,0)" : "translate3d(-100%,0,0)",
    },
  });

  const backendUrl = GetUrl();
  return transitions((style, index) => (
    <animated.div
      style={{
        width: "40vw",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      {fileIsImage(attachments[index]?.attachmentName) ? (
        <StyledImage
          src={`${backendUrl}${attachments[index]?.attachmentUrl}` ?? ""}
          alt={attachments[index]?.attachmentName ?? ""}
        />
      ) : (
        <a
          download={`${backendUrl}${attachments[index]?.attachmentUrl}` ?? ""}
          href={`${backendUrl}${attachments[index]?.attachmentUrl}` ?? ""}
        >
          <h2>{attachments[index]?.attachmentName ?? ""}</h2>
        </a>
      )}
    </animated.div>
  ));
};
