import {
  RunTaskDetailInput,
  DashboardTaskInput,
  Scalars,
  TaskPriorityEnum,
  TaskStatusEnum,
  TaskTypeEnum,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class PersonalTaskInputStub implements DashboardTaskInput {
  endTime: Scalars["DateTime"];
  endTimeScheduled: Scalars["DateTime"];
  groupAssignedTo: Scalars["Int"];
  methodId: Maybe<Scalars["Int"]>;
  methodReservationPlateMetadata: [];
  notes: Maybe<Scalars["String"]>;
  startTime: Scalars["DateTime"];
  startTimeScheduled: Scalars["DateTime"];
  systemId: Scalars["Int"];
  taskId: Scalars["Int"];
  taskName: Maybe<Scalars["String"]>;
  taskOwnerId: Scalars["Int"];
  taskOwnerName: Maybe<Scalars["String"]>;
  taskSettings: Maybe<RunTaskDetailInput>;
  taskType: TaskTypeEnum;
  userAssignedTo: Scalars["Int"];
  systemAssignedTo: Scalars["Int"];
  priority: TaskPriorityEnum;
  status: TaskStatusEnum;
  assignedUsers: Maybe<Array<Scalars["Int"]>>;
  assignedGroups: Maybe<Array<Scalars["Int"]>>;
}
