import { useQuery } from "@apollo/client";
import { EventHoveringArg, EventInput } from "@fullcalendar/common";
import { DatesSetArg, EventMountArg } from "@fullcalendar/core";
import { EventResizeDoneArg } from "@fullcalendar/interaction";
import FullCalendar, { EventApi, EventDropArg } from "@fullcalendar/react";
import { ContextMenuDialogSelector } from "features/Dashboard/components/Dialogs/ContextMenuDialogs";
import { DashboardContextMenu } from "features/Dashboard/components/Table/ContextMenu";
import {
  AppSuiteUser,
  DashboardTask,
  GetDashboardTasksQuery,
  GetDashboardTasksQueryVariables,
  OperationNames,
  RescheduleTaskInput,
  TaskStatusEnum,
  TaskTypeEnum,
  useGetDashboardTaskLazyQuery,
  useRescheduleTasksMutation,
} from "graphql/generated/schema-types";
import { DashboardQueries } from "graphql/queries/dashboards.queries";
import { useGetUserSettings } from "hooks/useGetUserSettings";
import { useEffect, useMemo, useState } from "react";
import { contextMenu } from "react-contexify";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import { TaskIdAndType } from "../pages/Calendar";
import { ICalendarState } from "../state/initial-state";
import {
  eventContent,
  getButtonText,
  getDropPrompt,
  getEventApiKey,
  getEventInputKey,
  getResizePrompt,
  getSlotLabelFormat,
  headerToolbar,
  isShortenedFeedTask,
  plugins,
  resources,
  titleFormat,
} from "./Calendar.functions";

export interface CalendarProps {
  fullCalendarRef: React.RefObject<FullCalendar>;
  events: EventInput[];
  // FullCalendar event handlers
  datesSet: (arg: DatesSetArg) => void;
  eventClick: () => void;
  // state management
  bulkReschedule: boolean;
  date: Date | undefined;
  calendarState: ICalendarState;
  initialDate: Date | undefined;
  reset: () => void;
  saveClicked: boolean;
  setTaskIdAndType: React.Dispatch<
    React.SetStateAction<TaskIdAndType | undefined>
  >;
  setTotalEdits: React.Dispatch<React.SetStateAction<number>>;
  dashboardTaskId: number | undefined;
  loggedInUser: AppSuiteUser | null | undefined;
  extendedToggleChecked: boolean;
}

export const Calendar = (cp: CalendarProps) => {
  const userId = parseInt(localStorage.userID);
  const { refetch } = useQuery<
    GetDashboardTasksQuery,
    GetDashboardTasksQueryVariables
  >(DashboardQueries.GET_DASHBOARD_TASKS, {
    variables: {
      loggedInUser: userId,
      status: Object.values(TaskStatusEnum),
    },
  });

  const [pendingEdits, setPendingEdits] = useState({
    drop: new Map<string, EventDropArg>(),
    resize: new Map<string, EventResizeDoneArg>(),
  });

  const events = useMemo(() => {
    let events = cp.events;

    const filterByDate = () => {
      const today = cp.date!.getDate();
      const weekView = cp.calendarState.view.endsWith("Week");

      return cp.events.filter((e) => {
        const startDay = new Date(e.start!.toString()).getDate();
        const endDay = new Date(e.end!.toString()).getDate();

        return weekView
          ? startDay >= today - cp.date!.getDay() && startDay <= today + 7
          : startDay === today || endDay === today;
      });
    };
    if (cp.date) events = filterByDate();

    const applyEdits = () => {
      pendingEdits.drop.forEach((value, key) => {
        const idx = events.findIndex((e) => getEventInputKey(e) === key);
        if (idx === -1) return;

        events[idx].start = value.event.start ?? undefined;
        events[idx].end = value.event.end ?? undefined;
        if (value.newResource) events[idx].resourceId = value.newResource!.id;
      });

      pendingEdits.resize.forEach((value, key) => {
        const idx = events.findIndex((e) => getEventInputKey(e) === key);
        if (idx === -1) return;

        events[idx].start = value.event.start ?? undefined;
        events[idx].end = value.event.end ?? undefined;
      });
    };
    if (pendingEdits.drop.size > 0 || pendingEdits.resize.size > 0)
      applyEdits();

    return events;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cp.date, cp.events, pendingEdits]);

  const [rescheduleTasks] = useRescheduleTasksMutation({});
  const rescheduleTask = (
    event: EventApi,
    systemAssignedTo: number | null,
    start: Date | null = null,
    end: Date | null = null
  ) => {
    if (!start) start = event.start;
    if (!end) end = event.end ?? event.start;

    rescheduleTasks({
      variables: {
        models: {
          taskId: parseInt(event.id),
          taskType: event.extendedProps.taskType,
          startTimeScheduled: start,
          endTimeScheduled: end,
          systemAssignedTo: systemAssignedTo,
        } as RescheduleTaskInput,
      },
      refetchQueries: [OperationNames.Query.getCalendarNodes],
    });
  };

  const { isConfirmed } = useConfirm();
  const call_isConfirmed = async (
    prompt: string,
    bullets?: string[] | undefined
  ) => await isConfirmed(prompt, bullets);

  const reset = () => {
    setPendingEdits({
      drop: new Map<string, EventDropArg>(),
      resize: new Map<string, EventResizeDoneArg>(),
    });
    cp.reset();
  };

  useEffect(() => {
    const keys = getKeys(); // TODO: check !keys.length ?

    // proceed only when bulkReschedule & saveClicked true, totalEdits > 0
    if (!cp.saveClicked || !cp.bulkReschedule) {
      if (!cp.bulkReschedule && keys.length > 0) reset();
      return;
    }

    let bullets: string[] | undefined = [];
    const drop = pendingEdits.drop;
    const resize = pendingEdits.resize;

    const getBullet = (key: string) => {
      let bullet = drop.has(key) ? getDropPrompt(drop.get(key)!) : "";
      if (!resize.has(key)) return bullet;

      const resizePrompt = getResizePrompt(resize.get(key)!);
      if (!bullet.length) bullet = resizePrompt;
      else {
        // strips out event title (may break if unexepected ['] found)
        const split = resizePrompt.split("'");
        bullet = bullet.concat(`, ${split[split.length - 1]}`);
      }
      return bullet;
    };

    keys.forEach((key) => {
      bullets?.push(getBullet(key));
    });
    bullets = bullets.sort();

    call_isConfirmed("Please confirm the following changes:", bullets).then(
      (confirmed: boolean) => {
        if (!confirmed) {
          reset();
          return;
        }

        const getParams = (key: string) => {
          let event: EventApi | null = null;
          let systemAssignedTo: number | null = null;
          let start: Date | null = null;
          let end: Date | null = null;

          if (drop.has(key)) {
            const arg = drop.get(key)!;
            event = arg.event;
            systemAssignedTo = arg.newResource
              ? Number(arg.newResource!.id)
              : null;
          }

          if (resize.has(key)) {
            const resizeEvent = resize.get(key)!.event;
            if (event === null) event = resizeEvent;
            else {
              // Edge case: drag & drop rescheduling lost when an event is resized first?
              start = resizeEvent.start;
              end = resizeEvent.end;
            }
          }
          return { event, systemAssignedTo, start, end };
        };

        keys.forEach((key) => {
          const params = getParams(key);
          rescheduleTask(
            params.event as EventApi,
            params.systemAssignedTo,
            params.start,
            params.end
          );
        });

        window.location.reload();
      }
    );
    // adding dependencies breaks the hook!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cp.bulkReschedule, cp.saveClicked]);

  const getKeys = () => {
    const allKeys = Array.from(pendingEdits.drop.keys()).concat(
      Array.from(pendingEdits.resize.keys())
    );
    return [...new Set(allKeys)]; // removes duplicates
  };

  const manualToSystem = (arg: EventDropArg) => {
    const manualSystemId = "14";
    if (
      arg.oldResource?.id === manualSystemId &&
      arg.newResource?.id !== manualSystemId
    ) {
      return true;
    }
    return false;
  };

  const eventDrop = (arg: EventDropArg) => {
    if (cp.loggedInUser && !cp.loggedInUser.isAdmin && manualToSystem(arg)) {
      arg.revert();
      return Alert({
        type: AlertType.ERROR,
        message: `You cannot move a manual task to a system.`,
      });
    }

    if (cp.bulkReschedule) {
      pendingEdits.drop.set(getEventApiKey(arg.event), arg);
      setPendingEdits(pendingEdits);
      cp.setTotalEdits(getKeys().length);
      return;
    }

    call_isConfirmed(`Update ${getDropPrompt(arg)}`)
      .then((confirmed: boolean) => {
        if (confirmed)
          rescheduleTask(
            arg.event,
            arg.newResource
              ? Number(arg.newResource!.id)
              : Number(arg.event.getResources()[0]._resource.id)
          );
        else arg.revert();
      })
      .catch(() => arg.revert());
  };

  const eventResize = (arg: EventResizeDoneArg) => {
    if (cp.bulkReschedule) {
      pendingEdits.resize.set(getEventApiKey(arg.event), arg);
      setPendingEdits(pendingEdits);
      cp.setTotalEdits(getKeys().length);
      return;
    }

    if ((!cp.loggedInUser?.isAdmin ?? false) && isShortenedFeedTask(arg)) {
      arg.revert();
      return Alert({
        type: AlertType.ERROR,
        message: `🙅‍♂️ You cannot shorten a feed task.`,
      });
    }

    call_isConfirmed(`Update ${getResizePrompt(arg)}`)
      .then((isConfirmed: boolean) => {
        if (isConfirmed)
          rescheduleTask(
            arg.event,
            Number(arg.event.getResources()[0]._resource.id) ?? null
          );
        else arg.revert();
      })
      .catch(() => arg.revert());
  };

  const eventOverlap = (stillEvent: EventApi) => {
    return (
      stillEvent.extendedProps.systemId === undefined ||
      parseInt(stillEvent.extendedProps.systemId) === 14
    ); // 14 = Manual
  };

  const [getDashboardTask, { loading, data }] = useGetDashboardTaskLazyQuery();
  const dashboardTasks: DashboardTask[] = useMemo(
    () =>
      loading || !data || !data?.dashboardTask
        ? []
        : [data!.dashboardTask as DashboardTask],
    [loading, data]
  );
  const getTasks = (_args: unknown) => dashboardTasks;

  const eventDidMount = (arg: EventMountArg) =>
    (arg.el.oncontextmenu = (e: MouseEvent) => {
      if (arg.event)
        getDashboardTask({
          variables: {
            taskID: parseInt(arg.event.id),
            taskType: arg.event.extendedProps.taskType,
          },
        });

      e.preventDefault();
      contextMenu.show({
        id: "dashboard-context-menu",
        event: e,
      });
    });

  const eventMouseEnter = (arg: EventHoveringArg) => {
    const id = parseInt(arg.event.id);
    const type = arg.event.extendedProps.taskType as TaskTypeEnum;
    cp.setTaskIdAndType({ id, type });
  };

  const settings = useGetUserSettings();
  const timeFormat = settings?.calendar?.timeFormat ?? "standard";

  const systemUserSettings = settings?.calendar?.systemsSettings ?? {};
  const userFilteredResources = resources.filter((res) => {
    return !(
      systemUserSettings.hasOwnProperty(res.id) &&
      systemUserSettings[res.id] === false
    );
  });

  const slotMinTime = cp.extendedToggleChecked ? "00:00" : "08:00";
  const slotMaxTime = cp.extendedToggleChecked ? "24:00" : "20:00";

  return (
    <>
      <FullCalendar
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        ref={cp.fullCalendarRef}
        plugins={plugins}
        resourceAreaHeaderContent="Systems"
        resources={userFilteredResources}
        resourceGroupField="cluster"
        resourceAreaWidth="10%"
        resourceOrder="cluster"
        initialView={cp.calendarState.view}
        headerToolbar={headerToolbar}
        buttonText={getButtonText(cp.calendarState.view)}
        datesSet={cp.datesSet}
        initialDate={cp.initialDate}
        events={events}
        eventContent={eventContent}
        eventDrop={eventDrop}
        eventResize={eventResize}
        eventOverlap={eventOverlap}
        eventClick={cp.eventClick}
        eventMinWidth={5}
        eventMouseEnter={eventMouseEnter}
        fixedWeekCount={false}
        timeZone="local"
        height="auto" //scrollbar, remove for page scroll
        scrollTime="08:00:00"
        showNonCurrentDates={false}
        scrollTimeReset={true}
        expandRows={false}
        slotMinTime={slotMinTime}
        slotMaxTime={slotMaxTime}
        slotDuration="00:15:00"
        slotLabelFormat={getSlotLabelFormat(timeFormat, cp.calendarState.view)}
        dayMaxEvents={2}
        nowIndicator={true}
        titleFormat={titleFormat}
        eventDidMount={eventDidMount}
      />
      <ConfirmDialog />
      <DashboardContextMenu
        loggedInUserId={userId}
        refetch={refetch}
        getTasks={getTasks}
      />
      <ContextMenuDialogSelector refetch={refetch} />
    </>
  );
};
