import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { ManagePersonalTask } from "graphql/generated/schema-types";
import React, { ChangeEvent, useState } from "react";

interface EditTaskDialogProp {
  isOpen: boolean;
  handleClose: (selectedTask: ManagePersonalTask, editTask: boolean) => void;
  selectedTask: ManagePersonalTask;
}

export const EditTaskDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: EditTaskDialogProp) => {
  const [passageSettings, setPassageSettings] = useState({
    createMystplate: false,
    daughterWellNumber: 0,
    destPlateCode: "",
    freezeCellCount: 0,
    freezeDownRackType: "",
    maxCryovial: 0,
    methodReservationID: 0,
    minCryovial: 0,
    overflowVials: "",
    plateBarcode: "",
    plateBarcodeAlias: "",
    plateCreatedID: 0,
    samples: "",
    seedCellCount: 0,
  });

  // const handleTaskChange = (
  //   e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = e.target;
  //   setSelectedEditTask({ ...selectedEditTask, [name]: value });
  // };

  // const getPassageInfo = () => {
  //   setSelectedEditTask(selectedTask);

  //   if (selectedTask.methodId === 63) {
  //     RunsService.getXTYPassageMethodSettings(selectedTask.taskID).then(
  //       (res) => {
  //         setPassageSettings(res.data[0]);
  //       }
  //     );
  //   }
  // };

  const handlePassageSettingsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPassageSettings({ ...passageSettings, [name]: value });
  };

  const setAutoFieldValue = (name: string, value: any) => {
    setPassageSettings({ ...passageSettings, [name]: value });
  };

  // const saveChanges = () => {
  //   const taskArr = [];
  //   taskArr.push(selectedEditTask);
  //   DashboardService.updateTask(
  //     taskArr,
  //     parseInt(localStorage.getItem("userID")!),
  //     "Updated Task in Edit Task Menu"
  //   ).then((res) => {
  //     console.log(res.data);
  //     handleClose(true);
  //   });
  // };

  const handleSubmit = (values: any) => {
    handleClose(values, true);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => handleClose(selectedTask, false)}>
        <DialogTitle>Edit Task</DialogTitle>
        <DialogContent>
          <Formik initialValues={selectedTask} onSubmit={handleSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }: FormikProps<ManagePersonalTask>) => (
              <Form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  name="taskName"
                  style={{ margin: "10px 0" }}
                  label="Task Name"
                  variant="outlined"
                  value={values.taskName}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  name="notes"
                  style={{ margin: "10px 0" }}
                  label="Notes"
                  defaultValue={values.notes}
                  multiline
                  rows={5}
                  variant="outlined"
                  onChange={handleChange}
                />

                <Button variant="contained" color="primary" type="submit">
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>

          {/* {selectedTask.methodId === 63 ? (
              <StyledMethodVariablesContainer>
                <TextField
                  name="daughterWellNumber"
                  value={passageSettings.daughterWellNumber}
                  onChange={handlePassageSettingsChange}
                  type="number"
                  variant="outlined"
                  label="Daughter Wells"
                />
                <TextField
                  name="seedCellCount"
                  value={passageSettings.seedCellCount}
                  onChange={handlePassageSettingsChange}
                  type="number"
                  variant="outlined"
                  label="Seeding Cell Count"
                />
                <TextField
                  name="freezeCellCount"
                  value={passageSettings.freezeCellCount}
                  onChange={handlePassageSettingsChange}
                  type="number"
                  variant="outlined"
                  label="Freezing Cell Count"
                />
                <TextField
                  name="maxCryovial"
                  value={passageSettings.maxCryovial}
                  onChange={handlePassageSettingsChange}
                  type="number"
                  variant="outlined"
                  label="Max Cryovial"
                />
                <TextField
                  name="minCryovial"
                  value={passageSettings.minCryovial}
                  onChange={handlePassageSettingsChange}
                  type="number"
                  variant="outlined"
                  label="Min Cryovial"
                />
                <Autocomplete
                  id="overflowVials"
                  options={overflow}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  getOptionLabel={(option) => option}
                  value={passageSettings.overflowVials}
                  disableClearable
                  onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                    setAutoFieldValue("overflowVials", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Overflow Vials"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  id="destPlateCode"
                  options={destPlateCodes}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  value={passageSettings.destPlateCode}
                  disableClearable
                  onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                    setAutoFieldValue("destPlateCode", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Dest Plate Code"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  id="freezeDownRackType"
                  options={freezeDownRacks}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) =>
                    option === value || value === ""
                  }
                  value={passageSettings.freezeDownRackType}
                  disableClearable
                  onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                    setAutoFieldValue("freezeDownRackType", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Freeze-Down Rack"
                      variant="outlined"
                    />
                  )}
                />
                <StyledCreateMYSTPlate
                  control={<Checkbox name="Create MYST Plate" />}
                  label="Create MYST Plate"
                />
              </StyledMethodVariablesContainer>
            ) : (
              <div />
            )} */}
        </DialogContent>
        {/* <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Save Changes
            </Button>
          </DialogActions> */}
      </Dialog>
    </>
  );
};
