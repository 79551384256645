import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useGetGanttIFrameUrlLazyQuery } from "graphql/generated/schema-types";

const IFrameContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #eeeeee;
  border: 1px #cbcbcb solid;
`;
const StyledIFrame = styled.iframe`
  width: 100%;
  height: 90vh;
  border-bottom: 1px #cbcbcb solid;
`;
export function TeamGanttIFrame(): JSX.Element {
  const params = useParams<{ runId?: string }>();
  const [getUrl, { data: urlQuery }] = useGetGanttIFrameUrlLazyQuery();

  useEffect(() => {
    if (params?.runId)
      getUrl({
        variables: {
          runId: Number(params.runId),
        },
      });
  }, [getUrl, params.runId]);
  return (
    <>
      {urlQuery?.ganttIFrameUrl && (
        <IFrameContainer>
          <StyledIFrame src={`${urlQuery?.ganttIFrameUrl}`} />
        </IFrameContainer>
      )}
    </>
  );
}
