import { gql } from "@apollo/client/core";
import { addDays, format } from "date-fns";
import { DashboardTask } from "graphql/fragments/dashboard.fragments";

export const DashboardQueries = {
  GET_DASHBOARD_TASKS: gql`
    query getDashboardTasks(
      $loggedInUser: Int,
      $status: [TaskStatusEnum!]
    ) {
      getOverdueTasks: allUserAndGroupTasks(
        where: {
          or: [
            { status: { in: [OVERDUE] } },
            {
              and: [
                {
                  startTime: {
                    gte: "${format(
                      addDays(new Date(), -7),
                      "yyyy-MM-dd"
                    )}T04:01:00.000",
                  },
                },
                {
                  startTime: {
                    lt: "${format(new Date(), "yyyy-MM-dd")}T04:01:00.000"
                  },
                },
                { status: { eq: CANCELLED } }
              ]
            }
          ]
        }
      ) {
        ...DashboardTask
      }
      getMyTasks: allUserAndGroupTasks(where: {
        or: [
          {
            and: [
              {
                startTime: {
                  gte: "${format(new Date(), "yyyy-MM-dd")}T04:01:00.000",
                },
              },
              {
                startTime: {
                  lt: "${format(
                    addDays(new Date(), 1),
                    "yyyy-MM-dd"
                  )}T03:59:00.000",
                },
              },
              { assignedUsers: { some: { eq: $loggedInUser } } },
              { status: { in: $status } },
            ],
          },
          {
            and: [
              { status: { in: [IN_PROGRESS] } },
              { assignedUsers: { some: { eq: $loggedInUser } } },
            ],
          },
        ],
      }) {
        ...DashboardTask
      }
      getGroupTasks: allUserAndGroupTasks(where: {
        or: [
          {
            and: [
              {
                startTime: {
                  gte: "${format(new Date(), "yyyy-MM-dd")}T04:01:00.00",
                },
              },
              {
                startTime: {
                  lt: "${format(
                    addDays(new Date(), 1),
                    "yyyy-MM-dd"
                  )}T03:59:00.000",
                },
              },
              { assignedUsers: { none: { eq: $loggedInUser } } },
              { status: { in: $status } },
            ],
          },
          {
            and: [
              { status: { in: [IN_PROGRESS] } },
              { assignedUsers: { none: { eq: $loggedInUser } } },
            ],
          },
        ],
      }) {
        ...DashboardTask
      }
      getLaterThisWeekTasks: allUserAndGroupTasks(where: {
        and: [                                  
          {
            startTime: {
              gt: "${format(
                addDays(new Date(), 1),
                "yyyy-MM-dd"
              )}T04:01:00.000",
            },
          },
          {
            startTime: {
              lt: "${format(addDays(new Date(), 8), "yyyy-MM-dd")}",
            },
          },
          { status: { in:  $status } },                          
        ],
      }, order: { startTime: ASC }) {
        ...DashboardTask
      }
    }
    ${DashboardTask}
  `,
  GET_TASK_HISTORY: gql`
    query getTaskHistory($where: WebSuiteTaskLogFilterInput) {
      taskHistory(where: $where) {
        taskParentId
        taskId
        taskType
        actionPerformed
        actionPerformedTime
        actionPerformedBy
      }
    }
  `,
  GET_INCLUDED_FEED_PLATES: gql`
    query getIncludedFeedPlates($dateScheduled: DateTime!) {
      includedFeedPlates(dateScheduled: $dateScheduled) {
        runName
        reason
        plates {
          plateID
          plateBarcode
          activeWellCount
        }
      }
    }
  `,
  GET_EXCLUDED_FEED_PLATES: gql`
    query getExcludedFeedPlates($dateScheduled: DateTime!) {
      excludedFeedPlates(dateScheduled: $dateScheduled) {
        runName
        reason
        plates {
          plateID
          plateBarcode
          activeWellCount
        }
      }
    }
  `,
  GET_PLATEMAP: gql`
    query getPlateMap($plateID: Int!) {
      plateMap(plateID: $plateID) {
        plateBarcode
        arrayPlateType {
          plateLayoutType {
            plateName
          }
        }
        containers {
          containerId
          rowPos
          colPos
          isActive
        }
      }
    }
  `,
  GET_DASHBOARD_TASK: gql`
    query getDashboardTask($taskID: Int!, $taskType: TaskTypeEnum!) {
      dashboardTask(taskID: $taskID, taskType: $taskType) {
        ...DashboardTask
      }
    }
  `,
};
