import { ErrorToastContent } from "error/ErrorToastContent";
import { Abbreviate } from "helpers/string-helpers";
import React from "react";
import { Alert, AlertType } from "shared-components/toast";

export function LogNetworkError(
  networkError:
    | Error
    | (Error & {
        response: Response;
        result: Record<string, unknown>;
        statusCode: number;
      })
    | (Error & { response: Response; statusCode: number; bodyText: string })
) {
  const shortErrorMessage = `[Network error]: ${Abbreviate(
    networkError.message,
    100
  )}`;
  const longErrorMessage = `[Network error]: ${networkError.message}`;

  Alert({
    hoc: () => (
      <ErrorToastContent
        shortErrorMessage={shortErrorMessage}
        longErrorMessage={longErrorMessage}
      />
    ),
    type: AlertType.ERROR,
    longMessage: longErrorMessage,
  });
}
