import { Select } from "@mui/material";
import { CollectionTask } from "graphql/generated/schema-types";
import React from "react";
import { FilterProps } from "react-table";

export const GetDropdownFilter = (
  options: Array<{ value: number | string; name: string }>,
  multiSelect = false
) => {
  return ({
    column: { filterValue, setFilter, id },
  }: FilterProps<CollectionTask>): JSX.Element => {
    return (
      <Select
        value={filterValue}
        native
        multiple={multiSelect}
        onChange={(e) => setFilter(e.target.value)}
        inputProps={{
          name: "select",
          id: `select-${id}`,
        }}
      >
        <option aria-label="None" value="" />
        {options.map(({ value, name }) => (
          <option value={value}>{name}</option>
        ))}
      </Select>
    );
  };
};
