import { makeVar, useReactiveVar } from "@apollo/client";
import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import { useIdleTimer, useSession } from "App.hooks";
import { AppRoutes } from "App.routes";
import { AutoSignoutWarningDialog } from "features/Authentication/AutoSignoutWarningDialog";
import { GroupMemberLoginForm } from "features/Authentication/GroupAuth/GroupMemberLoginForm";
import {
  ComputerId,
  SelectComputerForm,
} from "features/Authentication/GroupAuth/SelectComputerForm";
import { useGetMeQuery } from "graphql/generated/schema-types";
import { useAppBackgroundQueries } from "hooks/useAppBackgroundQueries";
import React from "react";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { useServiceWorkerWrapper } from "./ServiceWorkerWrapper";
import Loading from "./shared-components/Loading";
import { ReleaseNotes } from "./shared-components/ReleaseNotes";

const BlankPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const StyledBackdrop = styled(Backdrop)`
  z-index: 10000;
  color: #fff;
`;
const BackdropContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;
export const BackdropOpen = makeVar<{
  header?: string;
  subText?: string;
  size?: number;
  open: boolean;
}>({
  open: false,
});
function App(): JSX.Element {
  const [authLoading] = useSession();
  useAppBackgroundQueries();
  const computerId = useReactiveVar(ComputerId);
  useServiceWorkerWrapper();
  const { data } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });

  const signOutOfGoogle = () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("userID");
    localStorage.setItem("staySignedIn", false.toString());
    googleLogout();
  };

  const autoSignOut = () => {
    signOutOfGoogle();
    window.location.href = "https://mail.google.com/mail/u/0/?logout&hl=en";
  };
  const WARNING_PROMPT_TIMER_START = 16;
  const numberOfMinutes = 3;
  const signOutTime = 1000 * 60 * numberOfMinutes;
  const { timeRemaining } = useIdleTimer(
    autoSignOut,
    signOutTime,
    WARNING_PROMPT_TIMER_START
  );

  const { header, open, size, subText } = useReactiveVar(BackdropOpen);
  return (
    <>
      <StyledBackdrop
        open={open}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <BackdropContent>
          <CircularProgress size={size} color="inherit" />
          <Typography variant={"h4"} component={"h4"}>
            {header}
          </Typography>
          <Typography variant={"h6"} component={"h6"}>
            {subText}
          </Typography>
        </BackdropContent>
      </StyledBackdrop>
      <ToastContainer />
      <AutoSignoutWarningDialog
        isOpen={
          (timeRemaining && timeRemaining < WARNING_PROMPT_TIMER_START) || false
        }
        timeRemaining={timeRemaining ?? 0}
      />
      {authLoading ? (
        <Loading name="rotating-plane" color="red" />
      ) : !computerId && data?.me?.isGroupAccount ? (
        <BlankPage>
          <SelectComputerForm />
        </BlankPage>
      ) : (
        <>
          <AppRoutes />
          <GroupMemberLoginForm />
          <ReleaseNotes />
        </>
      )}
    </>
  );
}

export default App;
