import { makeVar } from "@apollo/client";
import { PersonalTaskTemplateTask } from "graphql/generated/schema-types";

export interface IPersonalTaskTemplateState {
  selectedPersonalTaskTemplateId?: number;
  selectedPersonalTaskTemplateName: string;
  selectedPersonalTasksTemplateTask?: PersonalTaskTemplateTask[];
  selectedPersonalTask: any;
}

export const PersonalTaskTemplateState = makeVar<IPersonalTaskTemplateState>({
  selectedPersonalTaskTemplateId: 0,
  selectedPersonalTaskTemplateName: "",
  selectedPersonalTasksTemplateTask: [],
  selectedPersonalTask: {},
});
