import {
  RunTask,
  RunTaskDetail,
  RunTaskDetailInput,
  RunTaskInput,
  TaskSelectionTypeEnum,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { RunTaskDetailInputStub } from "mappings/object-stubs/RunActivityDetailInput";
import { RunActivityInputStub } from "mappings/object-stubs/RunActivityInput";
import { MethodsWithDetails } from "../constants/methodsWithDetails";

export function RunActivityInputMapper(runActivity: Maybe<RunTask>) {
  const _runActivity = DeepCopy(runActivity);
  if (
    !MethodsWithDetails.includes(_runActivity?.methodId ?? 0) &&
    _runActivity?.runTaskDetail
  )
    delete _runActivity.runTaskDetail;

  if (_runActivity?.method)
    (_runActivity as RunTaskInput).taskTypeDetails = {
      taskTypeId: _runActivity.method.methodId,
      taskType: TaskSelectionTypeEnum.Method,
      documentationUrl: _runActivity.method?.documentationUrl,
    };
  if (_runActivity?.manualTaskType)
    (_runActivity as RunTaskInput).taskTypeDetails = {
      taskTypeId: _runActivity.manualTaskType.id,
      taskType: TaskSelectionTypeEnum.Manual,
      documentationUrl: _runActivity.manualTaskType?.documentationUrl,
    };
  if (_runActivity?.runTaskDetail)
    (_runActivity as RunTaskInput).details = runActivityDetailInputMapper(
      _runActivity.runTaskDetail
    );
  if (_runActivity?.users)
    (_runActivity as RunTaskInput).runTaskAssignedUsers =
      _runActivity.users!.map((entry) => entry!.userId) as number[];
  if (_runActivity?.groups)
    (_runActivity as RunTaskInput).runTaskAssignedGroups =
      _runActivity.groups!.map((entry) => entry!.groupId) as number[];

  const retVal = _.pick(
    _runActivity,
    _.keys(new RunActivityInputStub())
  ) as RunTaskInput;
  retVal.runActivityId = runActivity?.runTaskId;
  return retVal;
}

export function runActivityDetailInputMapper(runActivityDetail: RunTaskDetail) {
  const _runActivityDetail = DeepCopy(runActivityDetail);
  const final = _.pick(
    _runActivityDetail,
    _.keys(new RunTaskDetailInputStub())
  ) as RunTaskDetailInput;
  final.parentId = runActivityDetail.runTaskId;
  final.runTaskDetailId = runActivityDetail.runTaskDetailsId;
  return final;
}
