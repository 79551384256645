import { Button, TextField } from "@mui/material";
import { useGetDeleteRun } from "features/Runs/components/Forms/Delete/hooks";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { Formik } from "formik";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import styled from "styled-components";
import { useGetRunsQuery } from "graphql/generated/schema-types";

const StyledButton = styled(Button)``;
const StyledForm = styled.form`
  margin: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
`;
export function DeleteRunForm({
  submitStateTuple,
}: {
  submitStateTuple: [boolean, Dispatch<SetStateAction<boolean>>];
}): JSX.Element {
  const { runsModalState, runsModalDispatch } = useRunsModalState();
  const [submitting, setSubmitting] = submitStateTuple;
  const deleteRun = useGetDeleteRun(setSubmitting);
  const { loading, data } = useGetRunsQuery({
    variables: { where: { runId: { eq: runsModalState.deleteDialog?.runId } } },
  });
  const run = useMemo(() => data?.runs?.[0], [data]);
  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        if (values.name !== run?.runName || values.name.length === 0) {
          setFieldError("name", "Name does not match");
          return;
        }
        if (run?.runId)
          deleteRun(run.runId).finally(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, handleChange, touched, errors }) => {
        return (
          !loading && (
            <StyledForm onSubmit={handleSubmit}>
              <TextField
                id={"name"}
                onChange={handleChange}
                variant={"outlined"}
                size={"small"}
                label={"Enter Run Name"}
                helperText={errors.name && touched.name && errors.name}
                error={!!(errors.name && touched.name && errors.name)}
              />
              <ButtonContainer>
                <div>
                  <Button
                    onClick={() =>
                      runsModalDispatch({
                        type: RunsModalActions.CLOSE_DIALOG,
                      })
                    }
                    color="secondary"
                    variant={"contained"}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <StyledButton
                    type={"submit"}
                    variant={"contained"}
                    color={"primary"}
                    disabled={submitting}
                  >
                    Delete
                  </StyledButton>
                </div>
              </ButtonContainer>
            </StyledForm>
          )
        );
      }}
    </Formik>
  );
}
