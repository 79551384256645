import { ErrorBoundary } from "error/ErrorBoundary";
import AdminDashboard from "features/AdminDashboard/pages/AdminDashboard";
import { Authorize, AuthorizeAdmin } from "features/Authentication/Authorize";
import { SelectComputerForm } from "features/Authentication/GroupAuth/SelectComputerForm";
import { LoginWithGoogle } from "features/Authentication/LoginWithGoogle";
import Calendar from "features/Calendar/pages/Calendar";
import Dashboard from "features/Dashboard/pages/Dashboard";
import { IncomingSamples } from "features/IncomingSamples/pages/IncomingSamples";
import { MethodManager } from "features/MethodManager/pages/MethodManager";
import { PageWithNav } from "features/Navbar/PageWithNav";
import { PersonalTaskTemplatePage } from "features/PersonalTasks/components/PersonalTaskTemplate/pages/PersonalTaskTemplate";
import { Plates } from "features/Plates/pages/Plates";
import { Samples } from "features/Samples/pages/Samples";
import { TeamGantt } from "features/TeamGantt/pages/TeamGantt";
import { Tickets } from "features/Tickets/pages/Tickets";
import { TubeLabeller } from "features/TubeLabeller/pages/TubeLabeller";
import { AccountSettings } from "features/UserSettings/Settings";
import { WorklistTool } from "features/WorklistTools/pages/WorklistTool";
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, RouteProps, Switch } from "react-router-dom";
import Loading from "shared-components/Loading";

const RunTemplate = lazy(() =>
  import("features/RunTemplate/pages/RunTemplate").then((module) => ({
    default: module.RunTemplate,
  }))
);

const Runs = lazy(() =>
  import("features/Runs/pages/Runs").then((module) => ({
    default: module.Runs,
  }))
);

export function RouteWithErrorBoundary(props: RouteProps) {
  return (
    <ErrorBoundary>
      <Route {...props} />
    </ErrorBoundary>
  );
}
export function AppRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <PageWithNav>
            <Loading name="rotating-plane" color="red" />
          </PageWithNav>
        }
      >
        <Switch>
          <RouteWithErrorBoundary path="/admin-dashboard">
            <AuthorizeAdmin>
              <AdminDashboard />
            </AuthorizeAdmin>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/account-settings">
            <Authorize>
              <AccountSettings />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/login">
            <LoginWithGoogle />
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary exact path="/">
            <Authorize redirect>
              <Dashboard />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/personal-task-template">
            <Authorize>
              <PersonalTaskTemplatePage />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/tickets">
            <Authorize>
              <Tickets />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/incoming-samples">
            <Authorize>
              <IncomingSamples />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/worklist-tool/:methodId">
            <Authorize>
              <WorklistTool />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/tube-labeller">
            <Authorize>
              <TubeLabeller />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/runs">
            <Authorize>
              <Runs />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/run-templates">
            <Authorize>
              <RunTemplate />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/method-manager">
            <Authorize>
              <MethodManager />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/calendar">
            <Authorize>
              <Calendar />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/gantt">
            <Authorize>
              <TeamGantt />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/samples">
            <Authorize>
              <Samples />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/plates">
            <Authorize>
              <Plates />
            </Authorize>
          </RouteWithErrorBoundary>
          <RouteWithErrorBoundary path="/change-computer-name">
            <PageWithNav>
              <SelectComputerForm />
            </PageWithNav>
          </RouteWithErrorBoundary>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}
