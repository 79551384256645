import { Dialog, DialogContent, Grid } from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  FeedGroupInput,
  OperationNames,
  useAddPersonalFeedTasksMutation,
  useGetArraySystemAutomationMethodsQuery,
  useGetFeedGroupsLazyQuery,
  useGetGroupPersonalTasksLazyQuery,
  useSaveFeedGroupsMutation,
} from "graphql/generated/schema-types";
import { DeepCopy } from "helpers/object-helpers";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import * as Yup from "yup";
import {
  AddFeedTaskProps,
  blankFeedGroupRow,
  feedDayToday,
  feedDayTomorrow,
  FeedGroupErrors,
  formatAndConvertToEst,
  renderLoadingOrNoFeedGroups,
  roundedCurrentTime,
  setDatesToTomorrow,
  validators,
} from "./AddFeedTask.Functions";
import {
  StyledForm,
  StyledFormControl,
} from "./AddFeedTask/AddFeedTask.Styled";
import { FeedGroupFooter } from "./AddFeedTask/FeedGroupFooter";
import { FeedGroupFormRow } from "./AddFeedTask/FeedGroupFormRow";
import { FeedGroupHeader } from "./AddFeedTask/FeedGroupHeader";

export const AddFeedTask = ({ isOpen, handleClose }: AddFeedTaskProps) => {
  const [getFeedGroups, { loading, data: feedGroupData }] =
    useGetFeedGroupsLazyQuery();
  const [getGroupPersonalTasks, { data: personalTaskData }] =
    useGetGroupPersonalTasksLazyQuery();
  const [saveFeedGroups] = useSaveFeedGroupsMutation();
  const [addPersonalFeedTasks] = useAddPersonalFeedTasksMutation();
  const asamQuery = useGetArraySystemAutomationMethodsQuery();
  const [groupId, setGroupId] = useState<number | undefined | null>(0);
  const [feedDay, setFeedDay] = useState(feedDayToday);
  const [todayTasks, setTodayTasks] = useState(new Array<number>());
  const [tomorrowTasks, setTomorrowTasks] = useState(new Array<number>());
  const [feedGroupsToDelete, setFeedGroupsToDelete] = useState(
    new Array<FeedGroupInput>()
  );

  useEffect(() => {
    if (!groupId) return;

    getFeedGroups({ variables: { groupId: groupId! } });

    getGroupPersonalTasks({
      variables: { groupId: groupId!, today: roundedCurrentTime() },
    });
    setFeedGroupsToDelete(new Array<FeedGroupInput>());
  }, [groupId]);

  const { isConfirmed } = useConfirm();

  const asams = asamQuery?.data?.arraySystemAutomationMethods;

  const allFeedGroups = useMemo(
    () => feedGroupData?.feedGroups,
    [feedGroupData]
  );
  const feedGroupArray: FeedGroupInput[] =
    allFeedGroups?.map((fg) => {
      const fgMethod =
        fg?.arraySystemAutomationMethod?.automationMethod?.lookupArrayMethods;
      const fgMethodId = fgMethod && fgMethod[0]?.methodId;

      return {
        feedGroupId: fg?.feedGroupId ?? 0,
        groupId: fg?.groupId,
        arraySystemAutomationMethodId: fg?.arraySystemAutomationMethodId,
        feedGroupName: fg?.feedGroupName,
        isActive: fg?.isActive ?? true,
        startTimeScheduled:
          formatAndConvertToEst(fg?.startTimeScheduled) ??
          roundedCurrentTime().clone().format(),
        numberOfPlates: fg?.numberOfPlates,
        methodId: fgMethodId,
        arraySystemId: fg?.arraySystemAutomationMethod?.arraySystemId,
        automationMethodId: fg?.arraySystemAutomationMethod?.automationMethodId,
      };
    }) ?? [];

  const createdFeedTasks = useMemo(
    () => personalTaskData?.groupPersonalTasks,
    [personalTaskData]
  );

  const saveCreatedTasksToState = () => {
    const feedGroupIdsToday: number[] = [];
    const feedGroupIdsTomorrow: number[] = [];

    const dayOfMonthToday = moment().date();
    const dayOfMonthTomorrow = moment().add(1, "days").date();

    if (!createdFeedTasks) return;

    createdFeedTasks.forEach((persTask) => {
      const localStartTimeScheduled = new Date(
        persTask?.startTimeScheduled as string
      );
      const dayOfStartTimeScheduled = moment(localStartTimeScheduled).date();

      if (dayOfStartTimeScheduled === dayOfMonthToday) {
        feedGroupIdsToday.push(persTask?.feedGroupId ?? -1);
      } else if (dayOfStartTimeScheduled === dayOfMonthTomorrow) {
        feedGroupIdsTomorrow.push(persTask?.feedGroupId ?? -1);
      }
    });

    setTodayTasks(feedGroupIdsToday);
    setTomorrowTasks(feedGroupIdsTomorrow);
  };

  useEffect(() => {
    saveCreatedTasksToState();
  }, [createdFeedTasks]);

  const isTaskCreated = (feedGroupId: number) => {
    if (
      feedDay === feedDayToday &&
      (todayTasks.includes(feedGroupId) || tomorrowTasks.includes(feedGroupId))
    ) {
      return true;
    }

    if (feedDay === feedDayTomorrow && tomorrowTasks.includes(feedGroupId)) {
      return true;
    }

    return false;
  };

  const allFeedGroupsDisabled = () => {
    return formValues.feedGroupArray.every((feedGroup) =>
      isTaskCreated(feedGroup.feedGroupId!)
    );
  };

  const cleanupForm = () => {
    Alert({ type: AlertType.SUCCESS, message: "Feed Task(s) Created" });
    setFeedDay(feedDayToday);
    handleClose();
  };

  const createPersonalFeedTasks = async () => {
    const allFeedGroups = DeepCopy(formValues.feedGroupArray);
    const feedGroupsWithNoTasks = DeepCopy(formValues.feedGroupArray).filter(
      (feedgroup) => {
        return !isTaskCreated(feedgroup.feedGroupId!);
      }
    );

    if (
      feedDay === feedDayToday &&
      allFeedGroups.length !== feedGroupsWithNoTasks.length
    ) {
      const confirmed = await isConfirmed(
        "Are you sure you want to create additional Feed Task(s) for today?"
      );

      if (!confirmed) {
        return;
      }
    }
    addPersonalFeedTasks({
      variables: {
        inputs:
          feedDay === feedDayToday
            ? feedGroupsWithNoTasks
            : setDatesToTomorrow(feedGroupsWithNoTasks),
      },
      refetchQueries: [
        OperationNames.Query.getCalendarNodes,
        OperationNames.Query.getGroupPersonalTasks,
      ],
    }).then((res) => {
      if (res.data?.addPersonalFeedTasks === true) {
        cleanupForm();
      }
    });
  };

  const formik = useFormik({
    initialValues: { feedGroupArray },
    enableReinitialize: true,
    onSubmit: createPersonalFeedTasks,
    validationSchema: Yup.object().shape(validators),
  });

  const errors = formik.errors;
  const formValues = formik.values;
  const isDirty = formik.dirty;
  const isSubmitting = formik.isSubmitting;
  const handleBlur = formik.handleBlur;
  const handleChange = formik.handleChange;
  const setFieldValue = formik.setFieldValue;
  const setValues = formik.setValues;

  const createFeedGroupRow = () => {
    const currentFeedGroups = DeepCopy(formValues.feedGroupArray);

    currentFeedGroups.push(blankFeedGroupRow(groupId));

    setValues({ feedGroupArray: currentFeedGroups });
  };

  const saveFeedGroupsHandler = () => {
    const activeFeedGroups = DeepCopy(formValues.feedGroupArray);
    const deletedFeedGroups = DeepCopy(feedGroupsToDelete);
    const activeAndDeletedFeedGroups = [
      ...activeFeedGroups,
      ...deletedFeedGroups,
    ];
    setFeedGroupsToDelete(new Array<FeedGroupInput>());
    saveFeedGroups({
      variables: {
        inputs: activeAndDeletedFeedGroups,
      },
      refetchQueries: [OperationNames.Query.getFeedGroups],
    });
  };

  const errorMessageObj = (index: number) => {
    return (errors as FeedGroupErrors)?.feedGroupArray?.[index];
  };

  const renderFeedGroupFormRows = (
    deleteRowHelper: (index: number) => void
  ) => {
    return formValues.feedGroupArray.map((fg, index) => {
      const toggleDisabled = isTaskCreated(fg.feedGroupId!);

      return (
        <FeedGroupFormRow
          asams={asams}
          deleteRowHelper={deleteRowHelper}
          errorMessageObj={errorMessageObj}
          feedGroupsToDelete={feedGroupsToDelete}
          formValues={formValues}
          handleBlur={handleBlur}
          handleChange={handleChange}
          index={index}
          setFeedGroupsToDelete={setFeedGroupsToDelete}
          setFieldValue={setFieldValue}
          toggleDisabled={toggleDisabled}
        />
      );
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth="lg">
      <DialogContent>
        <FeedGroupHeader
          createFeedGroupRow={createFeedGroupRow}
          feedDay={feedDay}
          groupId={groupId}
          setFeedDay={setFeedDay}
          setFieldValue={setFieldValue}
          setGroupId={setGroupId}
        />
        {renderLoadingOrNoFeedGroups(
          loading,
          isEmpty(formValues.feedGroupArray)
        )}
        <FormikProvider value={formik}>
          <StyledForm onSubmit={formik.handleSubmit}>
            <StyledFormControl>
              <FieldArray
                name="feedGroupArray"
                render={({ remove: deleteRowHelper }) => (
                  <Grid container rowGap={2} sx={{ ml: 1, mb: 1 }}>
                    {renderFeedGroupFormRows(deleteRowHelper)}
                  </Grid>
                )}
              />
              <FeedGroupFooter
                allFeedGroupsDisabled={allFeedGroupsDisabled()}
                errorsPresent={!isEmpty(errors)}
                handleClose={handleClose}
                isDirty={isDirty}
                isSubmitting={isSubmitting}
                saveFeedGroupsHandler={saveFeedGroupsHandler}
              />
            </StyledFormControl>
          </StyledForm>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};
