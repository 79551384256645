import produce from "immer";
import { ResetSelectedUserManagerTableRows } from "../components/Table/UserTable";
import { UserManagerActions } from "./actions";
import { IUserManagerAction, IUserManagerState } from "./initial-state";

export function userManagerStateReducer(
  state: IUserManagerState,
  action: IUserManagerAction
): IUserManagerState {
  switch (action.type) {
    case UserManagerActions.OPEN_MODAL:
      return produce(state, (draft) => {
        draft.currentModal = action.payload?.currentModal;
        draft.modalOpen = true;
      });
    case UserManagerActions.CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = false;
        if (draft!.selectedUsers!.length > 0)
          ResetSelectedUserManagerTableRows();
      });
    case UserManagerActions.SELECT:
      return produce(state, (draft) => {
        draft.selectedUsers = action.payload?.selectedUsers;
      });
    default:
      return state;
  }
}
