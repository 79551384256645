import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { AppSuiteUser, useGetUsersQuery } from "graphql/generated/schema-types";

export interface AssignedUsersProps {
  setFieldValue: (field: string, value: any) => void;
}

export const AssignedUsers = (props: AssignedUsersProps) => {
  const usersQuery = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  const options =
    usersQuery.data?.appSuiteUsers
      ?.filter((u) => u?.userId !== 70) // 70: ProTeam
      .map((u) => u as AppSuiteUser) ?? [];
  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(u) => u!.userName!}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Assigned Users" />
      )}
      onChange={(_e, value) => props.setFieldValue("assignedUsers", value)}
    />
  );
};
