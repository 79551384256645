import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import { Maybe } from "graphql-tools";
import { useState } from "react";
import styled from "styled-components/macro";
import {
  CreateTicketAttachmentInput,
  TicketAttachment,
  useAddTicketAttachmentMutation,
  useDeleteTicketAttachmentMutation,
} from "graphql/generated/schema-types";
import { Alert, AlertType } from "shared-components/toast";
import {
  AttachmentAreaText,
  UploadFilesArea,
} from "shared-components/UploadFilesArea";
import { AttachmentActions } from "./AttachmentActions";
import { CarouselTransition } from "./CarouselTransition";

const getFileExtension = (fileName: string) => {
  const splitString = fileName.split(".");
  return splitString[splitString.length - 1].toLowerCase();
};

export const fileIsImage = (fileName?: string | null) => {
  if (!fileName) return false;
  const imageExtensions = ["jpeg", "png", "jpg"];
  const extension = getFileExtension(fileName);
  return imageExtensions.includes(extension);
};
const Carousel = styled.div`
  margin: 20px 0;
  height: 60vh;
  min-width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`;

export function AttachmentCarousel({
  attachments,
  ticketId,
}: {
  attachments: Maybe<TicketAttachment>[];
  ticketId: number;
}): JSX.Element {
  const indexTuple = useState(0);
  const [index, setIndex] = indexTuple;
  const [reverse, setReverse] = useState(false);

  const [addAttachment, { loading: addAttachmentLoading }] =
    useAddTicketAttachmentMutation();
  const [deleteAttachment, { loading: deleteAttachmentLoading }] =
    useDeleteTicketAttachmentMutation();

  const handleUpload = async (files: File[]) => {
    const input: CreateTicketAttachmentInput[] = [];
    const twentyMB = 1024 * 1024 * 20;
    for (const file of files) {
      if (file.size > twentyMB)
        return Alert({
          type: AlertType.ERROR,
          message: `${file.name} is too large (20MB max)`,
        });
      input.push({ name: file.name, file: file });
    }
    await addAttachment({
      variables: {
        attachments: input,
        ticketId,
      },
    }).then(() => {
      if (attachments.length > 0) setIndex((current) => current + 1);
    });
  };

  const handleDelete = () => {
    if (attachments[index]?.ticketAttachmentId)
      deleteAttachment({
        variables: {
          input: {
            primaryKey: attachments[index]!.ticketAttachmentId,
          },
        },
      })
        .then(() => {
          setIndex(0);
        })
        .catch((error) => console.log(error));
  };

  return (
    <Container>
      <ButtonContainer>
        <IconButton
          onClick={() => {
            setReverse(true);
            setIndex((current) => current - 1);
          }}
          disabled={index === 0}
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
      </ButtonContainer>
      <div>
        <Carousel>
          <UploadFilesArea onDrop={handleUpload}>
            {addAttachmentLoading ? (
              <CircularProgress size={100} />
            ) : attachments.length === 0 ? (
              <AttachmentAreaText>Drop attachments here</AttachmentAreaText>
            ) : (
              <CarouselTransition
                attachments={attachments as TicketAttachment[]}
                index={index}
                reverse={reverse}
              />
            )}
          </UploadFilesArea>
        </Carousel>
        <AttachmentActions
          loading={addAttachmentLoading}
          deleteAttachmentLoading={deleteAttachmentLoading}
          indexTuple={indexTuple}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
          attachments={attachments}
        />
      </div>
      <ButtonContainer>
        <IconButton
          onClick={() => {
            setReverse(false);
            setIndex((current) => current + 1);
          }}
          disabled={
            index === attachments.length - 1 || attachments.length === 0
          }
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonContainer>
    </Container>
  );
}
