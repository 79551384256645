import { Scalars } from "@octokit/graphql-schema";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  DayOfWeek,
  RunTemplatesRepeatDaysOfWeekInput,
} from "../../graphql/generated/schema-types";

export class RunTemplatesRepeatDaysOfWeekInputStub
  implements RunTemplatesRepeatDaysOfWeekInput
{
  runTemplateRepeatDayOfWeekId: Scalars["Int"];
  runTemplateTaskId?: Maybe<Scalars["Int"]>;
  dayOfWeek?: Maybe<DayOfWeek>;
  numberOfWeeks: Scalars["Int"];
}
