import { ReactiveVar } from "@apollo/client";

export function debounce<T extends Array<unknown>>(
  func: (...args: T) => void | unknown,
  wait = 166
) {
  let timeout: NodeJS.Timeout;
  function debounced(...args: T) {
    const later = () => {
      // @ts-ignore
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}
export function debounceWithStore<T extends Array<unknown>>(
  func: (...args: T) => void | unknown,
  wait = 166,
  store: ReactiveVar<T[] | undefined>
) {
  let timeout: NodeJS.Timeout;
  function debounced(...args: T) {
    const later = () => {
      // @ts-ignore
      func.apply(this, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    const storeValue = store();
    if (storeValue) {
      store([...storeValue, args]);
    } else store([args]);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return {
    debounced,
  };
}
