import { Checkbox, FormControlLabel } from "@mui/material";
import { FormikErrors } from "formik";
import _ from "lodash";
import { StyledFormControl } from "shared-components/styled-components";
import { resources } from "../Calendar/components/Calendar.functions";
import { CalendarSettings, SystemSettings } from "./Settings";

export interface SystemCheckboxesProps {
  values: CalendarSettings;
  setFieldValue: (
    field: string,
    value: SystemSettings,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CalendarSettings>>;
}

export const SystemCheckboxes = ({
  values,
  setFieldValue,
}: SystemCheckboxesProps) => {
  const handleCheckboxChange = (systemId: string, checked: boolean) => {
    const updatedSystemsSettings = values.systemsSettings
      ? _.cloneDeep(values.systemsSettings)
      : {};
    updatedSystemsSettings[systemId] = checked;
    setFieldValue("systemsSettings", updatedSystemsSettings);
  };
  const generateCheckboxes = () =>
    resources.map((res) => (
      <FormControlLabel
        key={parseInt(res.id)}
        control={
          <Checkbox
            checked={values.systemsSettings?.[res.id] ?? true}
            onChange={(e) => handleCheckboxChange(res.id, e.target.checked)}
          />
        }
        label={res.title}
      />
    ));

  return (
    <StyledFormControl
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "10px",
      }}
    >
      {generateCheckboxes()}
    </StyledFormControl>
  );
};
