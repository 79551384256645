import { SourceWellInfo } from "./interfaces";
import { getPlateWellIdFromRowAndColIndex } from "./WorklistHelpers";

/**
 *
 * @param i number of array elements to create
 * @returns an array of incremented numbers up to i
 */
export const getNumberArray = (i: number) =>
  Array.from({ length: i }, (_, index) => index + 1);
export const is96WPlate = (numberOfRows: number, numberOfCols: number) =>
  numberOfRows === 8 && numberOfCols === 12;
export const is24WPlate = (numberOfRows: number, numberOfCols: number) =>
  numberOfRows === 4 && numberOfCols === 6;
export const is384WPlate = (numberOfRows: number, numberOfCols: number) =>
  numberOfRows === 16 && numberOfCols === 24;
export const isMatrixRack = (labwareTypeCode: string) =>
  labwareTypeCode === "Matrix_96_Cryo_L_3740";
export const is384W_CCU_Plate = (labwareTypeCode: string) =>
  labwareTypeCode === "PE_384_FL_L_6057300";
export const isA1Indexed = (sourceWellInfo: SourceWellInfo[]) => {
  if (
    sourceWellInfo.filter(
      (e) =>
        getPlateWellIdFromRowAndColIndex(e.rowPos - 1, e.colPos - 1) === "A1"
    ).length > 0
  ) {
    return true;
  }
  return false;
};
