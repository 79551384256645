import { BackdropOpen } from "App";
import { OpenFolder } from "features/Runs/helpers";
import { RunsFolderTreeActions } from "features/Runs/state/folder-tree/actions";
import {
  useRunFolderQueryProvider,
  useRunsFolderTreeState,
  useRunsModalState,
} from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  CreateRunInput,
  DirectoryFolder,
  NodeType,
  OperationNames,
  TaskStatusEnum,
  useCreateRunMutation,
} from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import React, { useEffect, useMemo } from "react";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { CreateRunForm } from "./Form";

export const initialValues: CreateRunInput = {
  status: TaskStatusEnum.Scheduled,
  runId: 0,
  runTemplateId: null,
  runName: "",
  folderParentId: 0,
  comments: "",
  runDateScheduled: null,

  isActive: true,
};
export function CreateRunModal(): JSX.Element {
  const foldersQuery = useRunFolderQueryProvider();
  const { runsModalDispatch } = useRunsModalState();
  const { runsFolderTreeDispatch } = useRunsFolderTreeState();
  const [createRun, { loading }] = useCreateRunMutation();

  const handleSubmit = useMemo(
    () => (values: CreateRunInput) => {
      const createRunCleanup = (response: any) => {
        if (foldersQuery) {
          foldersQuery.refetch().then((folderQueryResponse) => {
            runsFolderTreeDispatch({
              type: RunsFolderTreeActions.NODE_SELECTED,
              payload: {
                currentNode: {
                  id: response.data!.createRun!.runId,
                  type: NodeType.Run,
                },
              },
            });
            OpenFolder(
              (folderQueryResponse.data.directory as DirectoryFolder[]) ?? [],
              {
                id: response.data?.createRun?.folderParentId ?? 0,
                type: NodeType.Folder,
              }
            );
          });
        }
        runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
        Alert({ type: AlertType.SUCCESS, message: "🏃‍♀️ Run Created" });
      };

      return createRun({
        variables: {
          run: values,
        },
        refetchQueries: [OperationNames.Query.getCalendarNodes],
      }).then((response) => createRunCleanup(response));
    },
    [createRun, foldersQuery, runsFolderTreeDispatch, runsModalDispatch]
  );
  const windowSize = useWindowSize();

  useEffect(() => {
    BackdropOpen({
      open: loading,
      header: "Creating Run",
      subText: "Do not reload page",
      size: 60,
    });
  }, [loading]);
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <CreateRunForm initialValues={initialValues} onSubmit={handleSubmit} />
    </StyledCard>
  );
}
