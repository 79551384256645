import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import { ConfirmState, IConfirmState } from "./initial-state";
import { ConfirmDialogReducer } from "./reducer";

export function useConfirmationDialogStateProvider(): {
  confirmState: IConfirmState;
  confirmStateDispatch: IApolloDispatch<any>;
} {
  const confirmState = useReactiveVar(ConfirmState);
  const confirmStateDispatch = useMemo(
    () => ApolloStateDispatch(ConfirmState, ConfirmDialogReducer),
    []
  );
  return {
    confirmState,
    confirmStateDispatch,
  };
}
