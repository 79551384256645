import React from "react";
import { AddRackOptions } from "../../constants";
import { CellNumberSelector } from "../selectors/CellNumberSelector";
import { DateSelector } from "../selectors/DateSelector";
import { PassageNumberSelector } from "../selectors/PassageNumberSelector";
import { Pooling1224TCPlateSelector } from "../selectors/Pooling1224TCPlateSelector";
import { ResuspensionVolSelector } from "../selectors/ResuspensionVolSelector";

interface Pooling1224TCProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const Pooling1224TC = ({
  values,
  setFieldValue,
}: Pooling1224TCProps) => {
  return (
    <React.Fragment>
      <Pooling1224TCPlateSelector
        values={values}
        setFieldValue={setFieldValue}
      />
      <PassageNumberSelector values={values} setFieldValue={setFieldValue} />
      <CellNumberSelector values={values} setFieldValue={setFieldValue} />
      <ResuspensionVolSelector
        values={values}
        setFieldValue={setFieldValue}
        isDisabled={true}
      />
      <DateSelector values={values} setFieldValue={setFieldValue} />
    </React.Fragment>
  );
};
