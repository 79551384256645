import { animated } from "@react-spring/web";
import React from "react";
import { useModalTransition } from "shared-components/ModalsAndDialogs/hooks";
import { useMethodManagerState } from "../../state/hooks";
import { EditManualTaskModal } from "../Forms/Edit/ManualTask/Modal";
import { EditMethodModal } from "../Forms/Edit/Method/Modal";
import { MethodManagerModals } from "./model.enum";

export function MethodManagerModalContent(): JSX.Element {
  const {
    methodManagerState: { currentModal },
  } = useMethodManagerState();
  const transition = useModalTransition(currentModal);
  return transition((props, currentModal) => {
    switch (currentModal) {
      case MethodManagerModals.EditManualTask:
        return (
          <animated.div style={props}>
            <EditManualTaskModal />
          </animated.div>
        );
      case MethodManagerModals.EditMethod:
        return (
          <animated.div style={props}>
            <EditMethodModal />
          </animated.div>
        );
      default:
        return <React.Fragment />;
    }
  });
}
