import { Button, FormControl } from "@mui/material";
import styled from "styled-components";

export const StyledAddFeedGroupButton = styled(Button)`
  margin-top: 13px !important;
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
