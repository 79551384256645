import { Button } from "@mui/material";
import { RunTaskDetailsInputSchema } from "features/Runs/components/Forms/validation-schema";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { RunTaskDetailInput } from "graphql/generated/schema-types";
import React from "react";
import { XTYCustomPassageFields } from "shared-components/FormFields/MethodFields/XTYCustomPassageFields";
import { StyledForm } from "shared-components/styled-components";

export function EditMultipleTaskDetailsForm({
  handleSubmit,
}: {
  handleSubmit: (
    values: RunTaskDetailInput,
    actions: FormikHelpers<RunTaskDetailInput>
  ) => void;
}): JSX.Element {
  const initialValues: RunTaskDetailInput = {
    parentId: 0,
    runTaskDetailId: 0,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RunTaskDetailsInputSchema}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<RunTaskDetailInput>) => {
        const { handleSubmit } = props;
        return (
          <StyledForm onSubmit={handleSubmit}>
            <XTYCustomPassageFields props={props} />
            <Button
              data-testid={"submit-button"}
              type={"submit"}
              variant={"contained"}
            >
              Preview
            </Button>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
