import {
  ILayoutAction,
  IModalAction,
  IPlateAction,
  IWellAction,
  LayoutAction,
  ModalAction,
  PlateAction,
  WellAction,
} from "./actions";
import {
  ILayoutState,
  IModalState,
  IPlateState,
  IWellState,
} from "./initial-state";

export function LayoutReducer(
  state: ILayoutState,
  action: ILayoutAction
): ILayoutState {
  switch (action.type) {
    case LayoutAction.TOGGLE_WELL_LIST:
      return {
        ...state,
        wellListOpen: action.payload?.wellListOpen ?? state.wellListOpen,
      };
    default:
      return state;
  }
}

export function PlateReducer(
  state: IPlateState,
  action: IPlateAction
): IPlateState {
  switch (action.type) {
    case PlateAction.SELECT_PLATE:
      return {
        ...state,
        selectedPlateBarcode:
          action.payload?.selectedPlateBarcode ?? state.selectedPlateBarcode,
      };
    default:
      return state;
  }
}

export function WellReducer(
  state: IWellState,
  action: IWellAction
): IWellState {
  switch (action.type) {
    case WellAction.TOGGLE_SELECT_WELL:
      return {
        ...state,
        currentWell: action.payload?.currentWell,
        fromPlateMap: action.payload?.fromPlateMap,
      };
    case WellAction.TOGGLE_OVERLAY:
      return {
        ...state,
        currentOverlay: action.payload?.currentOverlay,
      };
    case WellAction.TOGGLE_SHOW_INACTIVE:
      return {
        ...state,
        showInactive: action.payload?.showInactive ?? !state.showInactive,
      };
    default:
      return state;
  }
}

export function ModalReducer(
  state: IModalState,
  action: IModalAction
): IModalState {
  switch (action.type) {
    case ModalAction.SELECT_CONTAINER:
      return {
        ...state,
        selectedContainer: action.payload?.selectedContainer,
      };
    case ModalAction.OPEN_MODAL:
      return {
        modalOpen: true,
        selectedContainer:
          action.payload?.selectedContainer ?? state.selectedContainer,
        currentModal: action.payload?.currentModal,
      };
    case ModalAction.CLOSE_MODAL:
      return {
        modalOpen: false,
        selectedContainer:
          action.payload?.selectedContainer ?? state.selectedContainer,
        currentModal: undefined,
      };
    default:
      return state;
  }
}
