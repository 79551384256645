import { gql } from "@apollo/client/core";
import { TypeNames } from "enums/TypeNames";
import {
  RunTemplate,
  RunTemplateStage,
  RunTemplateTask,
} from "../fragments/runtemplates.fragments";

export const RunTemplateQueries = {
  remote: {
    GET_RUN_TEMPLATES: gql`
      query GetRunTemplates(
        $pageSize: Int
        $after: String
        $order: [RunTemplateSortInput!]
        $where: RunTemplateFilterInput
      ) {
        runTemplates(
          first: $pageSize
          after: $after
          order: $order
          where: $where
        ) {
          edges {
            node {
              ...RunTemplate
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
      ${RunTemplate}
    `,
    GET_RUN_TEMPLATES_FOR_COMBOBOX: gql`
      query GetRunTemplatesForCombobox {
        runTemplatesForCombobox(order: { runTemplateId: DESC }) {
          runTemplateId
          runTemplateName
        }
      }
    `,
    GET_RUN_TEMPLATE_TASKS: gql`
      query GetRunTemplateTasks($where: RunTemplateTaskFilterInput) {
        runTemplateTasks(where: $where) {
          ...RunTemplateTask
        }
      }
      ${RunTemplateTask}
    `,
    GET_RUN_TASK_TYPES: gql`
      query GetRunTaskTypes {
        runTaskTypes(order: { name: ASC }) {
          type
          id
          name
          compositeKey
          automationMethodId
        }
      }
    `,
    GET_PLATE_CODES: gql`
      query GetPlateCodes {
        plateCodes
      }
    `,
  },
  local: {
    GET_RUN_TEMPLATE_BY_ID: gql`
      query getRunTemplateById($runTemplateId: Int!) {
        runTemplate(id: $runTemplateId, typeName: "${TypeNames.RunTemplate}", keyField: "runTemplateId") @client {
          ...RunTemplate
        }
      }
      ${RunTemplate}
    `,
    GET_RUN_TEMPLATE_STAGE_BY_ID: gql`
      query getRunTemplateStageById($runTemplateStageId: Int!) {
        runTemplateStage(id: $runTemplateStageId, typeName: "${TypeNames.RunTemplateStage}", keyField: "runTemplateStageId") @client {
          ...RunTemplateStage
        }
      }
      ${RunTemplateStage}
    `,
    GET_RUN_TEMPLATE_TASK_BY_ID: gql`
      query getRunTemplateTaskById($runTemplateTaskId: Int!) {
        runTemplateTask(id: $runTemplateTaskId, typeName: "${TypeNames.RunTemplateTask}", keyField: "runTemplateTaskId") @client {
          ...RunTemplateTask
        }
      }
      ${RunTemplateTask}
    `,
  },
};
