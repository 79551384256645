import { useReactiveVar } from "@apollo/client";
import { WatchQueryFetchPolicy } from "@apollo/client/core/watchQueryOptions";
import { getComponent } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/PlatesTableReducer";
import {
  CeligoMethodAlreadyReserved,
  CeligoPlateMetadata,
} from "features/WorklistTools/CeligoScan/Dialog/state";
import {
  GroupedPlateMetadata,
  MethodReservation,
  PlateMetadataInput,
  useGetCeligoPlateMetadataQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useIsInitialRender } from "hooks/useIsInitialRender";
import _ from "lodash";
import { PlateMetaDataInputMapper } from "mappings/plateMetadataInput";
import React, { useEffect, useMemo, useState } from "react";
import { GetPlateMetaDataFromMethodReservation } from "selectors/methodReservation";
import { GetPlateMetadataInputFromGroupedPlateMetadata } from "selectors/platesCreated";
import styled from "styled-components";

export enum PossibleComponents {
  LoadingComponent,
  ReadOnlyPlateGroupAccordionComponent,
  PlateGroupAccordionComponent,
  NoPlatesAvailableComponent,
}

const Container = styled.div`
  height: 50vh;
  width: 50vw;
  margin: 40px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export function PlateGroupAccordionContainer({
  methodReservation,
  platesQueryFetchPolicy,
}: {
  methodReservation?: Maybe<MethodReservation>;
  platesQueryFetchPolicy: WatchQueryFetchPolicy;
}): JSX.Element {
  const alreadyReserved = useReactiveVar(CeligoMethodAlreadyReserved);
  const accordionDataTuple = useState<
    (GroupedPlateMetadata & { expanded: boolean })[]
  >([]);

  const [, setAccordionData] = accordionDataTuple;
  const initialRender = useIsInitialRender();

  const { data, loading: groupedPlateMetadataLoading } =
    useGetCeligoPlateMetadataQuery({
      fetchPolicy: platesQueryFetchPolicy,
    });
  const groupedPlateMetadata = useMemo(
    () => (data?.celigoScanPlateMetadata ?? []) as GroupedPlateMetadata[],
    [data]
  );
  useEffect(() => {
    setAccordionData(
      groupedPlateMetadata.map((gMetadata) => ({
        ...gMetadata,
        expanded: false,
      }))
    );
    if (initialRender) {
      CeligoPlateMetadata([]);
    }
  }, [initialRender, groupedPlateMetadata, setAccordionData]);

  useEffect(() => {
    let plateMetadata: Array<PlateMetadataInput>;
    if (!alreadyReserved && groupedPlateMetadata.length) {
      plateMetadata =
        GetPlateMetadataInputFromGroupedPlateMetadata(groupedPlateMetadata);
    } else {
      plateMetadata = GetPlateMetaDataFromMethodReservation(methodReservation);
    }
    const plateMetadataInput = plateMetadata.map((data) =>
      PlateMetaDataInputMapper(data)
    );
    if (!_.isEqual(CeligoPlateMetadata(), plateMetadataInput)) {
      CeligoPlateMetadata(plateMetadataInput);
    }
  }, [methodReservation, alreadyReserved, groupedPlateMetadata]);

  const { component, Reducer } = getComponent(
    groupedPlateMetadataLoading,
    alreadyReserved,
    accordionDataTuple,
    methodReservation,
    data
  );
  return <Container>{Reducer(component)}</Container>;
}
