import { Maybe } from "graphql-tools";

export enum WorklistTools {
  // NormalizedPassage,
  // StampNormalizedPassage,
  Thaw,
  PoolingNormalization,
  Twelve24TC,
}

export enum PlatePosition {
  Source = "Source",
  Destination = "Destination",
  Intermediate = "Intermediate",
  Dead = "Dead",
}

export enum SelectionProcess {
  Stamp,
  RowWise,
  ColWise,
  Pool,
  Random,
}

export enum PoolingNormalizationStep {
  SelectSourceToIntermediate,
  SelectStampMapping,
  SelectIntermediateToDestination,
}

export enum SelectionType {
  NoSourceWellSelected,
  SelectMultipleSourceAndDestWells,
  SelectMultipleSourceAndOneDestWell,
  SelectOneSourceAndOneDestWell,
  SelectOneSourceAndMultipleDestWells,
}

export interface BaseDestPlateInfo {
  plateBarcode: string;
  labwareTypeCode: string;
}

export interface WorklistValues {
  sourcePlateIndex: number;
  sourceWellId: string;
  destPlateIndex: number;
  destWellId: string;
  transferVol?: string;
}

export interface ReactSelectableFast {
  props: {
    isSelectable: boolean;
    plateIndex: number;
    platePosition: PlatePosition;
    plateWellId: string;
    sourcePlateInfo?: SourceWellInfo[];
    wellColor?: string;
  };
  node: {
    classList: DOMTokenList;
  };
}

export interface WellSelection {
  plateIndex: number;
  plateWellId: string;
  isSelectable: boolean;
}

export interface SourceWellInfo {
  barcode: string;
  rowPos: number;
  colPos: number;
  sampleId: number;
  sampleBarcode: string;
  color: string;
}

export interface LabwareTypeInfo {
  labwareTypeId?: number;
  labwareTypeCode?: Maybe<string>;
  labwareTypeName?: Maybe<string>;
  labwareTypeSummary?: Maybe<string>;
  labwarePlateType?: Maybe<string>;
  defaultLabwareVolume?: Maybe<number>;
  plateRows?: number[];
  plateCols?: number[];
  defaultArrayPlateCode?: Maybe<string>;
  minLabwareVolume?: Maybe<number>;
  maxLabwareVolume?: Maybe<number>;
}

export interface Worklist {
  task?: string;
  details?: string;
  aspirateVol?: string;
  dispenseVol?: string;
  transferVol?: string;
  sourcePlateType?: string;
  sourcePlateBarcode?: string;
  sourceWellID?: string;
  destinationPlateType?: string;
  destinationPlateBarcode?: string;
  destinationWellID?: string;
}

export interface vSpinSettings {
  vSpinRPM: number;
  vSpinTime: number;
  vSpinAccel: number;
  vSpinDecel: number;
}
