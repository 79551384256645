import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";
import { MethodManagerActions } from "../../state/actions";
import { useMethodManagerState } from "../../state/hooks";

export function MethodManagerModalContainer({
  children,
}: Wrapper): JSX.Element {
  const {
    methodManagerState: { modalOpen },
    methodManagerDispatch,
  } = useMethodManagerState();
  return (
    <AppModal
      open={modalOpen ?? false}
      onClose={() =>
        methodManagerDispatch({ type: MethodManagerActions.CLOSE_MODAL })
      }
    >
      {children}
    </AppModal>
  );
}
