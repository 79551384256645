import { makeVar } from "@apollo/client";

export interface IInstrumentState {
  instrumentName: string | undefined | null;
  instrumentId: number;
}

export interface ICalendarState {
  day: Date;
  view: string;
}

export const InstrumentState = makeVar<IInstrumentState>({
  instrumentName: undefined,
  instrumentId: 0,
});

export const CalendarState = makeVar<ICalendarState>({
  day: new Date(),
  view: 'resourceTimelineDay'
});
