import {
  PersonalTaskTemplate,
  PersonalTaskTemplateFilterInput,
} from "graphql/generated/schema-types";
import { Filters } from "react-table";

export const PersonalTaskTemplateFilterReducer = (
  tableFilters: Filters<PersonalTaskTemplate>
) => {
  console.log(tableFilters);
  const where: PersonalTaskTemplateFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "personalTaskTemplateName":
        where!.and!.push({
          personalTaskTemplateName: { contains: tableFilter.value },
        });
        break;
      case "createdBy":
        where!.and!.push({
          createdBy: { eq: tableFilter.value },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
