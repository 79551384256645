import React from "react";
import { DestPlateInfoState } from "../../state";
import { ITwelve24TCToolInternalState } from "../../state/initial-state";

export const prvUploadCond = (
  twelve24TCInternalState: ITwelve24TCToolInternalState
): boolean[] => {
  const needPRVDropInput =
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! > 49 &&
    twelve24TCInternalState.methodSettings!.pelletResuspensionRGT === 0;

  const prvBtwn1And50 =
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! > 0 &&
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! < 50;
  const prvOutside0Or500 =
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! < 0 ||
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! > 500;
  const prvEmpty =
    twelve24TCInternalState.methodSettings!.pelletResuspensionVol! === null;

  const noSystemSelected =
    twelve24TCInternalState.methodSettings!.selectedSystem === 0;
  return [
    noSystemSelected,
    prvBtwn1And50,
    prvOutside0Or500,
    prvEmpty,
    needPRVDropInput,
  ];
};

export const disableSourcePlateUploadBool = (
  twelve24TCInternalState: ITwelve24TCToolInternalState
) => {
  const uploadCond = prvUploadCond(twelve24TCInternalState);
  return (
    uploadCond[0] ||
    uploadCond[1] ||
    uploadCond[2] ||
    uploadCond[3] ||
    uploadCond[4]
  );
};

export const disableSourcePlateUploadButtonMsg = (
  twelve24TCInternalState: ITwelve24TCToolInternalState
): string[] | [] => {
  const uploadCond = prvUploadCond(twelve24TCInternalState);
  const warning = [];
  if (uploadCond[0]) warning.push("Need System Selected");
  if (uploadCond[1] || uploadCond[2] || uploadCond[3])
    warning.push("Pellet Resuspension Volume is a required field");
  if (uploadCond[4])
    warning.push("Pellet Resuspension Reagent is a required field");
  return warning;
};

export const disableDestPlateTypeSelectionMsg = (
  twelve24TCInternalState: ITwelve24TCToolInternalState,
  destPlateInfo: DestPlateInfoState
): string[] | [] => {
  const prvUploadWarnings = disableSourcePlateUploadButtonMsg(
    twelve24TCInternalState
  );
  const barcodeWarnings = [];
  for (let i = 0; i < prvUploadWarnings.length; i++) {
    barcodeWarnings.push(prvUploadWarnings[i]);
  }
  if (destPlateInfo.plateCode === "")
    barcodeWarnings.push("Destination Plate Code is a required field");
  return barcodeWarnings;
};

export const UploadASrcPlateB4YouDownloadAWorklist = (
  sourcePlateInfo: any[]
) => {
  if (sourcePlateInfo.every((s) => s.labwareTypeCode === "")) {
    return true;
  }
  return false;
};

export const disablePlateUploadTooltipComponent = (
  twelve24TCInternalState: ITwelve24TCToolInternalState,
  disabledMsgs: string[]
) => {
  return (
    <>
      <p style={{ color: "red", fontSize: 15, margin: 0 }}>Required</p>
      {disabledMsgs.map((str: string, ind: number) => (
        <li key={ind} style={{ fontSize: 12.5, margin: 0 }}>
          {str}
        </li>
      ))}
    </>
  );
};
