import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { useEffect } from "react";
import styled from "styled-components";

interface AliquotSettingsProps {
  numberOfAliquots: number;
  aliquotVolume: number;
  handleMethodSettingsChanged: (key: string, value: any) => void;
  is96WellPlate: boolean;
}

export const AliquotSettings = ({
  numberOfAliquots,
  aliquotVolume,
  handleMethodSettingsChanged,
  is96WellPlate,
}: AliquotSettingsProps) => {
  const StyledAliquotSettings = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
  `;

  useEffect(() => {
    if (is96WellPlate) {
      handleMethodSettingsChanged("numberOfAliquots", 1);
      handleMethodSettingsChanged("aliquotVolume", 10);
    }
  }, [is96WellPlate]);

  return (
    <StyledAliquotSettings>
      <NumberField
        style={{ flexGrow: 1 }}
        size={"small"}
        id="number-of-aliquots"
        label="Number of Dead Total Aliquots"
        value={numberOfAliquots}
        minValue={1}
        maxValue={4}
        onChange={(value) =>
          handleMethodSettingsChanged("numberOfAliquots", value)
        }
        disabled={is96WellPlate}
      />
      <FormControl
        sx={{ flexGrow: 1 }}
        variant="outlined"
        error={aliquotVolume === -1}
      >
        <InputLabel id="aliquot-vol-label">Dead Total Aliquot Vol</InputLabel>
        <Select
          labelId="aliquot-vol-label"
          id="aliquot-vol-select"
          value={aliquotVolume}
          label="Dead Total Aliquot Vol"
          size="small"
          onChange={(e) =>
            handleMethodSettingsChanged("aliquotVolume", e.target.value)
          }
          disabled={is96WellPlate}
        >
          <MenuItem value={-1}></MenuItem>
          <MenuItem value={10}>10 (µL)</MenuItem>
          <MenuItem value={25}>25 (µL)</MenuItem>
        </Select>
        {aliquotVolume === -1 ? (
          <FormHelperText>Required</FormHelperText>
        ) : null}
      </FormControl>
    </StyledAliquotSettings>
  );
};
