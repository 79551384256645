import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  AppSuiteUser,
  DashboardTask,
  useGetUsersQuery,
} from "graphql/generated/schema-types";
import { useGetUsersById } from "hooks/cacheAccessHooks";
import React, { ChangeEvent, useEffect, useState } from "react";

interface AssignToUsersProps {
  isOpen: boolean;
  handleClose: (assignedUsers: number[], assignUsers: boolean) => void;
  selectedTasks: DashboardTask[];
}

export const AssignToUsersDialog = ({
  isOpen,
  handleClose,
  selectedTasks,
}: AssignToUsersProps) => {
  const usersQuery = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  const assignedUsers = useGetUsersById(selectedTasks[0].assignedUsers ?? null);

  const [selectedUsers, setSelectedUsers] = useState<AppSuiteUser[]>([]);
  const [selectedUsersChanged, setSelectedUsersChanged] = useState(false);

  useEffect(() => {
    setSelectedUsersChanged(false);
  }, [selectedTasks]);

  const handleUsersChanged = (e: ChangeEvent<unknown>, value: any) => {
    setSelectedUsers(value);
    setSelectedUsersChanged(true);
  };

  const handleSubmit = () => {
    const userIds = selectedUsers.map((user) => {
      return user!.userId!;
    });
    handleClose(userIds, true);
    setSelectedUsers([]);
  };

  const clearSelectedUsers = () => {
    setSelectedUsers([]);
    handleClose([], false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => clearSelectedUsers()}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Assign Tasks to User(s)</DialogTitle>
        <DialogContent>
          <ul>
            {selectedTasks.map((task) => (
              <li>{task.taskName}</li>
            ))}
          </ul>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={usersQuery.data?.appSuiteUsers?.map((node) => node) ?? []}
            getOptionLabel={(option) => option!.userName!}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              handleUsersChanged(event, newValue)
            }
            // getOptionSelected={(selected, option) =>
            //   !!(
            //     selected?.userId &&
            //     option?.userId &&
            //     selected?.userId === option?.userId
            //   )
            // }
            value={selectedUsersChanged ? selectedUsers : assignedUsers}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
