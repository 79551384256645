import React from "react";
import { createSelectable } from "react-selectable-fast";
import { PlatePosition } from "../interfaces";

interface PlateWellProps {
  plateIndex?: number;
  platePosition: string;
  plateWellId: string;
  sourcePlateInfo?: any[] | undefined;
  // rowIndex: number;
  // colIndex: number;
}

function PlateWell({
  selectableRef,
  isSelected,
  isSelecting,
  wellColor,
  plateIndex,
  platePosition,
  plateWellId,
  sourcePlateInfo,
}: any) {
  const classNames = [
    "plate-well",
    platePosition === PlatePosition.Intermediate && isSelected && "selected",
    sourcePlateInfo && isSelected && "selected",
    isSelecting && "selecting",
    wellColor === "" && "not-selectable",
    // platePosition === "Int" && "not-selectable",
  ]
    .filter(Boolean)
    .join(" ");

  // https://stackoverflow.com/questions/10028182/how-to-make-a-pie-chart-in-css
  return wellColor.includes("multi-color") ? (
    <MultiColorPlateWell
      selectableRef={selectableRef}
      wellColor={wellColor.substring(12)}
      plateIndex={plateIndex}
      platePosition={platePosition}
      plateWellId={plateWellId}
      classNames={classNames}
    />
  ) : (
    <SingleColorPlateWell
      selectableRef={selectableRef}
      wellColor={wellColor}
      plateIndex={plateIndex}
      platePosition={platePosition}
      plateWellId={plateWellId}
      classNames={classNames}
    />
  );
}

function SingleColorPlateWell({
  selectableRef,
  wellColor,
  plateIndex,
  platePosition,
  plateWellId,
  classNames,
}: any) {
  return (
    <div
      id={`${platePosition}${plateIndex}${plateWellId}`}
      ref={selectableRef}
      className={classNames}
      style={{
        backgroundColor: wellColor,
        border: `1px solid ${
          localStorage.getItem("theme")?.includes("light") ? "black" : "#454545"
        }`,
        height: "100%",
        width: "100%",
        borderRadius: "50%",
      }}
    ></div>
  );
}

function MultiColorPlateWell({
  selectableRef,
  wellColor,
  plateIndex,
  platePosition,
  plateWellId,
  classNames,
}: any) {
  return (
    <div
      id={`${platePosition}${plateIndex}${plateWellId}`}
      ref={selectableRef}
      className={classNames}
      style={{
        backgroundImage: `linear-gradient(${wellColor})`,
        border: `1px solid ${
          localStorage.getItem("theme")?.includes("light") ? "black" : "#454545"
        }`,
        height: "100%",
        width: "100%",
        borderRadius: "50%",
      }}
    ></div>
  );
}

export default createSelectable(PlateWell);
