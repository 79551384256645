import { DateRange } from "@mui/lab";
import { RunTask, RunTaskFilterInput } from "graphql/generated/schema-types";
import { GetDayRange } from "helpers/date-helpers";
import _ from "lodash";
import { Filters } from "react-table";

export const RunActivityTaskFilterReducer = (
  tableFilters: Filters<RunTask>
) => {
  const where: RunTaskFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "activityName":
        where!.and!.push({
          activityName: { contains: tableFilter.value },
        });
        break;
      case "status":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            status: { in: tableFilter.value },
          });
        else where!.and!.filter((entry) => !entry.status);
        break;

      case "users":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            users: {
              some: {
                userId: { in: tableFilter.value },
              },
            },
          });
        else where!.and!.filter((entry) => !entry.users);
        break;
      case "groups":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            groups: {
              some: {
                groupId: { in: tableFilter.value },
              },
            },
          });
        else where!.and!.filter((entry) => !entry.groups);
        break;
      case "systemAssignedTo":
        where!.and!.push({
          systemAssignedTo: { eq: tableFilter.value },
        });
        break;
      case "startTimeScheduled":
        let range = tableFilter.value as DateRange<unknown>;
        if (range.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.startTimeScheduled);
          break;
        }
        if (range.some((date) => !date)) {
          break;
        }
        if (_.isEqual(range[0], range[1]))
          range = GetDayRange(tableFilter.value[0]);
        where!.and!.push({
          startTimeScheduled: { gt: range[0] },
        });
        where!.and!.push({
          startTimeScheduled: { lt: range[1] },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
