import { ErrorToastContent } from "error/ErrorToastContent";
import { SourceLocation } from "graphql";
import { Abbreviate } from "helpers/string-helpers";
import React from "react";
import { Alert, AlertType } from "shared-components/toast";

export function LogGraphQLError(
  message: string,
  extensions?: { [p: string]: unknown },
  locations?: ReadonlyArray<SourceLocation>,
  path?: ReadonlyArray<string | number>
) {
  const shortErrorMessage = `[${
    extensions?.code ?? "Server Error"
  }]: Message: ${Abbreviate(message, 100)}`;

  const longErrorMessage = `[${
    extensions?.code ?? "Server Error"
  }]: Message: ${message}`;

  Alert({
    hoc: () => (
      <ErrorToastContent
        shortErrorMessage={shortErrorMessage}
        longErrorMessage={longErrorMessage}
      />
    ),
    type: AlertType.ERROR,
    longMessage: longErrorMessage,
  });
}
