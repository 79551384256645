import { format } from "date-fns";
import { PersonalTasksRecurringTask } from "graphql/generated/schema-types";
import * as _ from "lodash";
import moment from "moment";
import { Column } from "react-table";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";

// const dropdownOptions = Object.values(Status).map((value) => ({
//   value,
//   name: value,
// }));
export const RepeatingTasksSummaryColumns: Array<
  Column<PersonalTasksRecurringTask>
> = [
  {
    Header: "Task",
    accessor: "taskName",
    Filter: TextFilter,
    width: "50%",
  },
  {
    Header: "Days of Week",
    accessor: "repeatingDaysOfWeek",
    Filter: TextFilter,
    width: "30%",
  },
  {
    Header: "End Repeat Date",
    accessor: "endRepeatDate",
    Filter: DateRangeFilter,
    Cell: ({ value }) => (value ? moment(value).format("MM-DD-YYYY") : null),
    width: "20%",
    Aggregated: ({ row }) => {
      const dates = row.leafRows.map(
        (leaf) => new Date(leaf.original.endRepeatDate)
      );
      const min = format(_.min(dates)!, "MM-dd-yyyy");
      const max = format(_.max(dates)!, "MM-dd-yyyy");
      return `${min} - ${max}`;
    },
  },
];
