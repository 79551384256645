import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { CeligoPlatesTable } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/PlatesTable";
import { SummaryText } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/ReadOnlyPlateGroupAccordion";
import {
  GroupedPlateMetadata,
  LookupExperimentSetting,
  MethodReservation,
  useGetExperimentSettingsQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import produce from "immer";
import React from "react";

export function PlateGroupAccordion({
  accordionDataTuple: [accordionData, setAccordionData],
  methodReservation,
}: {
  accordionDataTuple: [
    (GroupedPlateMetadata & { expanded: boolean })[],
    React.Dispatch<
      React.SetStateAction<(GroupedPlateMetadata & { expanded: boolean })[]>
    >
  ];
  methodReservation: Maybe<MethodReservation>;
}): JSX.Element {
  const { data: experimentSettingsQuery } = useGetExperimentSettingsQuery();
  return (
    <>
      {accordionData.map((group, idx) => (
        <Accordion
          key={idx}
          id={`group ${idx}`}
          expanded={group.expanded}
          TransitionProps={{ timeout: 150, unmountOnExit: true }}
          onChange={() => {
            const copy = produce(accordionData, (draft) => {
              for (let i = 0; i < draft.length; i++) {
                if (i === idx) {
                  draft[i].expanded = !draft[i].expanded;
                }
              }
            });
            setAccordionData(copy);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <SummaryText>{group.prefix}</SummaryText>
          </AccordionSummary>
          <AccordionDetails>
            <CeligoPlatesTable
              group={group}
              experimentSettings={
                experimentSettingsQuery?.experimentSettings as Maybe<LookupExperimentSetting>[]
              }
              methodReservation={methodReservation}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
