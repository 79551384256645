import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Tooltip } from "@mui/material";
import { Methods } from "enums/Methods";
import { StyledIconButton } from "features/WorklistTools/shared/components/styles/styled-components";
import { ViewWorklistTemplate } from "features/WorklistTools/shared/components/WorklistTemplates/ViewWorklistTemplates";
import { getNumberArray } from "features/WorklistTools/shared/PlateHelpers";
import { fillSourcePlateWithColors } from "features/WorklistTools/shared/WorklistHelpers";
import { WorklistToolTemplate } from "graphql/generated/schema-types";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import { useState } from "react";
import { ThawToolActions } from "../../state";
import { useHandleMethodSettingsUpdatedByTemplate } from "../../state/handlers";
import {
  parseMethodSettings,
  parseThawDestinationTask,
  parseThawStampTask,
  parseThawTransferTask,
} from "../handlers/HandleOpenTemplate";

export const OpenTemplateButton = () => {
  const dispatch = useAppReduxDispatch();
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);

  const setMethodSettingsByTemplate =
    useHandleMethodSettingsUpdatedByTemplate();

  const handleTemplateClose = async (
    loadTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => {
    console.log(templateInfo);
    setTemplateDialogOpen(false);
    if (templateInfo === undefined || !loadTemplate) return;

    const worklistToolTemplateContents =
      templateInfo.worklistToolTemplateContents;

    const getContentsByTask = (task: string) =>
      worklistToolTemplateContents?.filter((e) => e!.task! === task) ?? [];

    const parsedThawDestination = parseThawDestinationTask(
      getContentsByTask("ThawDestination")
    );
    const parsedThawTransfer = parseThawTransferTask(
      getContentsByTask("ThawTransfer")
    );
    const parsedThawStamp = parseThawStampTask(getContentsByTask("ThawStamp"));

    let resuspensionVol =
      parseInt(
        worklistToolTemplateContents!.filter((e) => e!.task === "Thaw")![0]!
          .dispenseVol!
      ) - 25;
    resuspensionVol = resuspensionVol > -1 ? resuspensionVol : 0;
    const topupVol = parseInt(
      worklistToolTemplateContents?.filter(
        (e) => e!.task === "ThawDestination"
      )[0]?.transferVol ?? "0"
    );
    const parsedMethodSettings = parseMethodSettings(
      resuspensionVol,
      topupVol,
      getContentsByTask("MatrixRackCentrifugation")
    );

    console.log(parsedMethodSettings);
    console.log(parsedThawDestination);
    console.log(parsedThawTransfer);
    setMethodSettingsByTemplate(parsedMethodSettings);
    dispatch(
      ThawToolActions.UPLOAD_SOURCE_PLATE({
        plateBarcode: "",
        labwareTypeCode: "Matrix_96_Cryo_L_3740",
        rows: getNumberArray(8),
        cols: getNumberArray(12),
        wellInfo: fillSourcePlateWithColors(
          getNumberArray(8),
          getNumberArray(12)
        ),
      })
    );
    dispatch(
      ThawToolActions.UPLOAD_WORKLIST_MAPPING({
        thawTransfer: parsedThawTransfer,
      })
    );
    if (parsedThawStamp.length > 0) {
      dispatch(
        ThawToolActions.UPLOAD_WORKLIST_THAW_STAMP({
          thawStamp: parsedThawStamp,
        })
      );
    }

    dispatch(
      ThawToolActions.UPLOAD_DESTINATION_PLATE_INFO({
        destPlateInfo: parsedThawDestination,
      })
    );
  };

  return (
    <>
      <StyledIconButton onClick={() => setTemplateDialogOpen(true)}>
        <Tooltip title="Open Template" placement="right">
          <FolderOpenIcon />
        </Tooltip>
      </StyledIconButton>

      <ViewWorklistTemplate
        isOpen={templateDialogOpen}
        handleClose={(loadTemplate, templateInfo) =>
          handleTemplateClose(loadTemplate, templateInfo)
        }
        overwriteTemplateMode={false}
        methodId={Methods.Thaw}
        // Update this date whenever outputted worklist is changed
        dateWorklistLastUpdated={new Date("07/28/2021 05:00:00 PM")}
      />
    </>
  );
};
