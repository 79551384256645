import { Button } from "@mui/material";
import { TicketModals } from "features/Tickets/components/ModelsAndDialogs/modal.enum";
import { TicketTableContextMenuHidden } from "features/Tickets/components/Table/TicketTable";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import {
  TicketStatus,
  useUpdateTicketsMutation,
} from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { Alert, AlertType } from "shared-components/toast";

export function DefaultTicketTableContextMenu(): JSX.Element {
  const {
    ticketDispatch,
    ticketState: { selectedTickets, selectedSlackMessageTimestamp },
  } = useTicketState();

  const [updateMutation, { error }] = useUpdateTicketsMutation({
    onCompleted: () => {
      if (!error) Alert({ type: AlertType.SUCCESS, message: "Changes Saved" });
    },
  });

  const updateStatus = useMemo(
    () => (status: TicketStatus) =>
      updateMutation({
        variables: {
          inputs: selectedTickets!.map((ticketId) => ({
            primaryKey: ticketId,
            set: { status },
          })),
        },
      }),
    [selectedTickets, updateMutation]
  );
  return (
    <Menu
      id="TicketTableContextMenu"
      onHidden={() => {
        setTimeout(() => TicketTableContextMenuHidden(true), 200);
      }}
    >
      <Submenu label="Mark Selected As">
        <Item onClick={() => updateStatus(TicketStatus.Resolved)}>
          Resolved
        </Item>
        <Item onClick={() => updateStatus(TicketStatus.Open)}>Open</Item>
        <Item onClick={() => updateStatus(TicketStatus.InProgress)}>
          In Progress
        </Item>
        <Item onClick={() => updateStatus(TicketStatus.OnHold)}>On Hold</Item>
        <Item onClick={() => updateStatus(TicketStatus.Pending)}>Pending</Item>
        <Item onClick={() => updateStatus(TicketStatus.Escalated)}>
          Escalated
        </Item>
        <Item onClick={() => updateStatus(TicketStatus.Closed)}>Closed</Item>
      </Submenu>
      {selectedTickets?.length === 1 && (
        <Item
          onClick={() => {
            ticketDispatch({
              type: TicketActions.OPEN_MODAL,
              payload: {
                currentModal: TicketModals.EditTicket,
              },
            });
          }}
        >
          Edit
        </Item>
      )}
      {selectedTickets?.length === 1 && (
        <Item
          onClick={() => {
            ticketDispatch({
              type: TicketActions.OPEN_MODAL,
              payload: {
                currentModal: TicketModals.MergeTicket,
              },
            });
          }}
        >
          Merge
        </Item>
      )}
      {selectedTickets?.length === 1 && selectedSlackMessageTimestamp && (
        <Item>
          <Button
            href={`https://nyscf.slack.com/archives/CGAJFS9LZ/p${selectedSlackMessageTimestamp}`}
            target="_blank"
            color="inherit"
            sx={{
              p: 0,
              fontFamily: "inherit",
              textTransform: "Capitalize",
              fontSize: "inherit",
            }}
          >
            View Slack Thread
          </Button>
        </Item>
      )}
    </Menu>
  );
}
