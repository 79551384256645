import { makeVar } from "@apollo/client";
import {
  IncomingSampleActions,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import { IncomingSampleCollectionFragment } from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";

//Workaround to prevent dialog from opening when the context menu loses focus
export const IncomingSampleCollectionContextMenuHidden = makeVar(true);

export const handleContextMenu =
  (
    selected: IncomingSampleCollectionFragment[],
    row?: Row<IncomingSampleCollectionFragment>
  ) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    contextMenu.show({
      id: "IncomingSampleCollectionTableContextMenu",
      event: e,
    });
    IncomingSampleCollectionContextMenuHidden(false);
  };

export const useHandleRowClick = (): ((
  rowClicked?: Row<IncomingSampleCollectionFragment>
) => (e: React.MouseEvent) => void) => {
  const { incomingSampleDispatch } = useIncomingSampleState();
  return useMemo(
    () => (rowClicked?: Row<IncomingSampleCollectionFragment>) => () => {
      if (
        !rowClicked?.isGrouped &&
        rowClicked?.original.collectionId &&
        IncomingSampleCollectionContextMenuHidden()
      )
        incomingSampleDispatch({
          type: IncomingSampleActions.OPEN_COLLECTION_SAMPLES,
          payload: {
            collectionSamplesModalState: {
              collectionId: rowClicked.original.collectionId,
            },
          },
        });
    },
    [incomingSampleDispatch]
  );
};
