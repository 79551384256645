import {
  MethodReservation,
  PlateMetadataInput,
  Worklist,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { Distinct, GetSortCompare } from "helpers/object-helpers";
import { createSelector } from "reselect";

export const GetWorklistsFromMethodReservation = (
  reservation: Maybe<MethodReservation>
): Maybe<Worklist> => reservation?.worklist ?? null;

export const GetImagingWorklistContentFromMethodReservation = createSelector(
  GetWorklistsFromMethodReservation,
  (worklists) => worklists?.worklistContentImagings
);

export const GetPlateMetaDataFromMethodReservation: (
  methodReservation: Maybe<MethodReservation>
) => PlateMetadataInput[] = (methodReservation: Maybe<MethodReservation>) => {
  const output =
    GetImagingWorklistContentFromMethodReservation(methodReservation)
      ?.map(
        (x) =>
          ({
            experimentSetting: x?.experimentSetting,
            analysisSetting: x?.analysisSetting,
            platePitch: x?.platePitch,
            sourcePlateBarcode: x?.barcode,
            imageExportSetting: x?.imageExportSetting,
            imageExportFormat: x?.imageExportFormat,
          } as PlateMetadataInput)
      )
      .sort(GetSortCompare<PlateMetadataInput>("sourcePlateBarcode")) ?? [];
  return Distinct(output, "sourcePlateBarcode");
};
