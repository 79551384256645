import { PageInfo } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { GraphQLConnection } from "interfaces/GraphQLConnection";

export const GetNodesFromConnectionType = <T>(connection?: unknown): [T] => {
  const _connection = connection as Maybe<GraphQLConnection<T>> | null;
  if (!_connection?.edges) return [] as unknown as [T];
  return (
    (_connection.edges
      .map((edge) => edge?.node ?? null)
      .filter((x) => x != null) as unknown as [T]) ?? ([] as unknown as [T])
  );
};

export const GetPageInfoFromConnectionType = <T>(
  connection?: unknown
): PageInfo | undefined | null => {
  const _connection = connection as Maybe<GraphQLConnection<T>> | null;
  if (_connection?.pageInfo) return _connection.pageInfo;
};

export const GetTotalCountFromConnectionType = <T>(
  connection?: unknown
): number | undefined | null => {
  const _connection = connection as Maybe<GraphQLConnection<T>> | null;
  if (_connection?.totalCount) return _connection.totalCount;
};
