import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { getIntPlateTypeInfo } from "features/WorklistTools/shared/WorklistHelpers";
import { Form, Formik } from "formik";
import {
  DashboardTask,
  useGetArrayMethodLabwareTypesQuery,
} from "graphql/generated/schema-types";
import { GetUrl } from "helpers/get-url";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import RunsService from "services/RunsService";
import styled from "styled-components";
import * as Yup from "yup";

const StyledMethodVariablesContainer = styled.div`
  display: grid;
  grid-template: 1fr 1fr 1fr 1fr / 1fr 1fr 1fr;
  grid-gap: 10px 10px;
`;

const StyledCreateMYSTPlate = styled(FormControlLabel)`
  grid-column-start: 1;
  grid-column-end: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const NoPlatesAvailable = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  color: ${({ theme }) => (theme.palette.mode === "dark" ? "white" : "black")};
`;

interface SystemOnePassageProp {
  selectedTask: DashboardTask;
  activePlates: any;
}

export const SystemOnePassage = ({
  selectedTask,
  activePlates,
}: SystemOnePassageProp) => {
  const overflow = [
    "OVERFLOW",
    "NO_OVERFLOW",
    "OVERFLOW_WELLS",
    "NO_OVERFLOW_WELLS",
  ];
  const freezeDownRacks = ["", "REPR", "REPO", "HTC", "TRGT", "SPLT"];
  const destPlateCodes = [
    "C6W",
    "1T1",
    "1T2",
    "1T3",
    "1T4",
    "1T5",
    "1T6",
    "2T1",
    "2T2",
    "2T3",
    "2T4",
    "2T5",
    "2T6",
    "3T1",
    "3T2",
    "3T3",
    "3T4",
    "3T5",
    "3T6",
    "4T1",
    "4T2",
    "4T3",
    "4T4",
    "4T5",
    "4T6",
    "5T1",
    "5T2",
    "5T3",
    "5T4",
    "5T5",
    "5T6",
    "6T1",
    "6T2",
    "6T3",
    "6T4",
    "6T5",
    "6T6",
  ];
  const [passageSettings, setPassageSettings] = useState({
    createMystplate: false,
    daughterWellNumber: 0,
    destPlateCode: "",
    freezeCellCount: 0,
    freezeDownRackType: "",
    maxCryovial: 0,
    minCryovial: 0,
    overflowVials: "",
    plateCreatedID: 0,
    seedCellCount: 0,
    intermediateLabwareTypeId: 0,
  });
  const [methodReservation, setMethodReservation] = useState(0);
  const [disableCreateReservation, setDisableCreateReservation] =
    useState(false);

  //TODO: Move this query to the <PlateType> component. Will reduce the amount of copy paste code
  const { data: acceptablePlateTypes } = useGetArrayMethodLabwareTypesQuery({
    fetchPolicy: "network-only",
    variables: {
      where: {
        arraySystemAutomationMethodId: {
          eq: 131, //XTY Passage ASAM ID
        },
      },
    },
  });

  const acceptableIntPlates =
    acceptablePlateTypes && getIntPlateTypeInfo(acceptablePlateTypes);

  useEffect(() => {
    RunsService.getXTYPassageMethodSettings(selectedTask.taskId).then((res) => {
      console.log(res.data[0]);
      setPassageSettings(res.data[0]);
    });

    RunsService.getMethodReservationByRunActivityId(selectedTask.taskId).then(
      (res) => {
        // console.log(res.data[0].methodReservationID);
        if (res.data.length > 0) {
          setMethodReservation(res.data[0].methodReservationID);
          setDisableCreateReservation(true);
        }
      }
    );
  }, [selectedTask.taskId]);

  const createDelimitedStringFromObject = (
    arrOfObjects: any,
    delimeter: string,
    objectValue: string
  ): string => {
    let returnString = "";
    for (let i = 0; i <= arrOfObjects.length - 1; i++) {
      if (arrOfObjects.length - 1 === i) {
        returnString += arrOfObjects[i][objectValue];
      } else {
        returnString += arrOfObjects[i][objectValue] + delimeter;
      }
    }
    return returnString;
  };

  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    setDisableCreateReservation(true);
    console.log(values);
    console.log("reserving");
    const sourcePlateBarcodes = activePlates.map((plate: any) => {
      return { sourcePlateBarcode: plate.plateBarcode };
    });

    const selectedIntLabwareType =
      acceptableIntPlates?.find(
        (e) => e.labwareTypeId === values.intermediateLabwareTypeId
      )?.labwareTypeCode ?? "";

    const data = {
      MethodID: selectedTask.methodId,
      MethodReservedFor: sessionStorage.getItem("userID"),
      plateMetaData: sourcePlateBarcodes,
      DateTimeOfMethod: moment(new Date()),
      SystemID: selectedTask.systemId,
      RunActivityID: selectedTask.taskId,
      DaughterWellNumber: values.daughterWellNumber,
      DestPlateCode: values.destPlateCode,
      CreateMYSTPlate: values.createMystplate,
      FreezeDownRackType: values.freezeDownRackType,
      SeedCellCount: values.seedCellCount,
      FreezeCellCount: values.freezeCellCount,
      MaxCryovial: values.maxCryovial,
      MinCryovial: values.minCryovial,
      OverflowVials: values.overflowVials,
      IntermediateLabwareTypeCode: selectedIntLabwareType,
    };
    RunsService.reserveMethodAndPlates(data).then((res) => {
      console.log(res);
      if (res.data > 1) {
        window.location.href =
          `${GetUrl()}/api/Runs/DownloadWorklist?methodReservationID=` +
          res.data;
        setMethodReservation(res.data);
      }
    });
    actions.setSubmitting(false);
  };

  const downloadWorklist = () => {
    window.location.href = `${GetUrl()}/api/Runs/DownloadWorklist?methodReservationID=${methodReservation}`;
  };

  const CreateReservationSchema = Yup.object().shape({
    daughterWells: Yup.number(),
    seedCellCount: Yup.number(),
    freezeCellCount: Yup.number(),
    maxCryovial: Yup.number(),
    minCryovial: Yup.number(),
    overflowVials: Yup.string(),
    destPlateCode: Yup.string(),
    freezeDownRackType: Yup.string(),
    createMystplate: Yup.boolean(),
  });

  const handleSettingChange = (e: any) => {
    const { name, value } = e.target;
    setPassageSettings({ ...passageSettings, [name]: value });
  };

  const setAutoFieldValue = (name: string, value: any) => {
    setPassageSettings({ ...passageSettings, [name]: value });
  };

  return activePlates.length > 0 ? (
    <Formik
      initialValues={passageSettings}
      enableReinitialize={true}
      // validationSchema={CreateReservationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            {/* <FormControl>
            {passageSettings ? (
              <div style={{ display: "flex", alignItems: "center" }}></div>
            ) : null} */}

            <StyledMethodVariablesContainer>
              <TextField
                disabled={disableCreateReservation}
                name="daughterWellNumber"
                type="number"
                variant="outlined"
                label="Daughter Wells"
                value={values.daughterWellNumber}
                onChange={handleSettingChange}
              />
              <TextField
                disabled={disableCreateReservation}
                name="seedCellCount"
                type="number"
                variant="outlined"
                label="Seeding Cell Count"
                value={values.seedCellCount}
                onChange={handleSettingChange}
              />
              <TextField
                disabled={disableCreateReservation}
                name="freezeCellCount"
                type="number"
                variant="outlined"
                label="Freezing Cell Count"
                value={values.freezeCellCount}
                onChange={handleSettingChange}
              />
              <TextField
                error={passageSettings.maxCryovial > 12}
                helperText={
                  passageSettings.maxCryovial > 12 ? "Can't be over 12" : null
                }
                disabled={disableCreateReservation}
                name="maxCryovial"
                type="number"
                variant="outlined"
                label="Max Cryovial"
                value={values.maxCryovial}
                onChange={handleSettingChange}
                onBlur={handleBlur}
              />
              <TextField
                // error={passageSettings.minCryovial < 1}
                // helperText={
                //   passageSettings.minCryovial < 1 ? "Must be at least 1" : null
                // }
                disabled={disableCreateReservation}
                name="minCryovial"
                type="number"
                variant="outlined"
                label="Min Cryovial"
                onBlur={handleBlur}
                value={values.minCryovial}
                onChange={handleSettingChange}
              />
              <Autocomplete
                id="overflowVials"
                disabled={disableCreateReservation}
                options={overflow}
                isOptionEqualToValue={(option, value) =>
                  option === value || "" === value
                }
                getOptionDisabled={(option) =>
                  option === "No Overflow" &&
                  values.freezeDownRackType === "REPO"
                }
                getOptionLabel={(option: any) => option}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Overflow Vials"
                    variant="outlined"
                  />
                )}
                value={values.overflowVials}
                // onChange={handleSettingChange}
                onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                  setAutoFieldValue("overflowVials", newValue)
                }
              />
              <Autocomplete
                id="destPlateCodes"
                disabled={disableCreateReservation}
                options={destPlateCodes}
                getOptionLabel={(priority: any) => priority}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Dest Plate Code"
                    variant="outlined"
                  />
                )}
                value={values.destPlateCode}
                onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                  setAutoFieldValue("destPlateCode", newValue)
                }
              />
              <Autocomplete
                id="freezeDownRacks"
                disabled={disableCreateReservation}
                options={freezeDownRacks}
                isOptionEqualToValue={(option, value) =>
                  option === value || "" === value
                }
                getOptionLabel={(priority: any) => priority}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Freeze-Down Rack"
                    variant="outlined"
                  />
                )}
                value={values.freezeDownRackType}
                onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                  setAutoFieldValue("freezeDownRackType", newValue)
                }
              />
              <div style={{ width: "210px" }}>
                <PlateType
                  platePosition={PlatePosition.Intermediate}
                  isDisabled={disableCreateReservation}
                  acceptedPlateTypes={
                    (acceptablePlateTypes &&
                      getIntPlateTypeInfo(acceptablePlateTypes)) ??
                    []
                  }
                  plateType={
                    acceptableIntPlates?.find(
                      (e) =>
                        e.labwareTypeId === values.intermediateLabwareTypeId
                    )?.labwareTypeCode ?? ""
                  }
                  setPlateType={(plateTypeInfo) =>
                    setAutoFieldValue(
                      "intermediateLabwareTypeId",
                      plateTypeInfo.labwareTypeId
                    )
                  }
                  isRequired={true}
                />
              </div>
              {/* <TextField
                type="number"
                variant="outlined"
                label="Total Tubes"
                disabled
                value={totalTubes}
              /> */}
              <StyledCreateMYSTPlate
                control={
                  <Checkbox
                    name="createMystplate"
                    checked={values.createMystplate}
                    disabled={disableCreateReservation}
                    onChange={(e) =>
                      setAutoFieldValue("createMystplate", e.target.checked)
                    }
                  />
                }
                label="Create MYST Plate"
              />
            </StyledMethodVariablesContainer>

            {/* <Button variant="outlined" onClick={generateWorklist} color="primary">
                    Generate Worklist
                  </Button> */}
            <ButtonContainer>
              <Button
                variant="outlined"
                type="submit"
                disabled={
                  disableCreateReservation || !values.intermediateLabwareTypeId
                }
                color="primary"
              >
                Create Reservation
              </Button>
            </ButtonContainer>
            {/* </FormControl> */}
          </Form>
          <div style={{ margin: "10px 0" }}>
            <Button
              variant="outlined"
              onClick={downloadWorklist}
              disabled={methodReservation === 0}
              color="primary"
            >
              Generate Worklist
            </Button>
          </div>
        </>
      )}
    </Formik>
  ) : (
    <NoPlatesAvailable>No Plates Available</NoPlatesAvailable>
  );
};
