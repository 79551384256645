import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import CSVReader from "react-csv-reader";

interface UploadWorklistProps {
  isOpen: boolean;
  handleClose: (uploadWorklist: boolean, worklistRows: any[]) => void;
}

export const UploadWorklist = ({
  isOpen,
  handleClose,
}: UploadWorklistProps) => {
  const [worklistRows, setWorklistRows] = useState([]);
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleForce = (data: any, fileInfo: any) => {
    setWorklistRows(data);
  };
  return (
    <Dialog open={isOpen} onClose={() => handleClose(false, worklistRows)}>
      <DialogTitle>Upload Worklist</DialogTitle>
      <DialogContent>
        <CSVReader
          inputStyle={{
            border: "1px solid black",
            backgroundColor: "#c0c0c0",
            borderRadius: "5px",
            marginTop: "10px",
          }}
          onFileLoaded={handleForce}
          parserOptions={papaparseOptions}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true, worklistRows)}>Upload</Button>
      </DialogActions>
    </Dialog>
  );
};
