import {
  GetTicketsQueryVariables,
  RunTask,
  SortEnumType,
  TicketFilterInput,
  TicketSortInput,
  TicketStatus,
} from "graphql/generated/schema-types";
import _ from "lodash";
import { useMemo } from "react";
import { Filters } from "react-table";
import { TableName } from "enums/TableName";
import { useGetDefaultTablePreset } from "hooks/useGetDefaultTablePreset";
import { TicketFilterReducer } from "./filter";

export const defaultStatusFilters = [
  TicketStatus.Open,
  TicketStatus.InProgress,
  TicketStatus.Pending,
  TicketStatus.Escalated,
  TicketStatus.Merged,
];

const defaultFilter: TicketFilterInput = {
  and: [{ status: { in: defaultStatusFilters } }],
};

export const useGetDefaultTicketQueryVariables =
  (): GetTicketsQueryVariables => {
    const defaultPreset = useGetDefaultTablePreset(TableName.Tickets);

    const where = useMemo(() => {
      let output = defaultFilter;
      if (defaultPreset?.state?.filters) {
        output =
          TicketFilterReducer(
            (defaultPreset?.state?.filters as Filters<RunTask>) ?? []
          ) ?? defaultFilter;
      }
      return output;
    }, [defaultPreset?.state?.filters]);

    const order = useMemo(() => {
      let output: Array<TicketSortInput> = [{ dateCreated: SortEnumType.Desc }];
      if (defaultPreset?.state?.sortBy && defaultPreset.state.sortBy.length > 0)
        output = defaultPreset.state.sortBy.map((sortRule) => {
          const sortObject = {};
          _.set(
            sortObject,
            sortRule.id,
            sortRule.desc ? SortEnumType.Desc : SortEnumType.Asc
          );
          return sortObject;
        });
      return output;
    }, [defaultPreset?.state.sortBy]);

    return {
      where,
      order,
      pageSize: defaultPreset?.pageSize ?? 10,
    };
  };
