import { IThawToolInternalAction, ThawToolInternalAction } from "./action";
import { IThawToolInternalState } from "./initial-state";

export function ThawToolInternalReducer(
  state: IThawToolInternalState,
  action: IThawToolInternalAction
) {
  switch (action.type) {
    case ThawToolInternalAction.SET_DEST_SELECTION_PROCESS:
      return {
        ...state,
        destSelectionProcess: action.payload.destSelectionProcess,
      };
    case ThawToolInternalAction.SET_RANDOMIZE_WELLS:
      return {
        ...state,
        randomizeWells: action.payload.randomizeWells,
      };
    case ThawToolInternalAction.SET_SELECTED_SOURCE_WELLS:
      return {
        ...state,
        selectedSourceWells: action.payload.selectedSourceWells,
      };
    case ThawToolInternalAction.SET_REQUIRED_WARNINGS:
      return {
        ...state,
        requiredWarnings: action.payload.requiredWarnings,
      };
    case ThawToolInternalAction.SET_OPTIONAL_WARNINGS:
      return {
        ...state,
        optionalWarnings: action.payload.optionalWarnings,
      };
    case ThawToolInternalAction.SET_WORKLIST:
      return {
        ...state,
        worklist: action.payload.worklist,
        worklistPreviewOpen: action.payload.worklistPreviewOpen,
      };
    case ThawToolInternalAction.SET_WORKLIST_PREVIEW_OPEN:
      return {
        ...state,
        worklistPreviewOpen: action.payload.worklistPreviewOpen,
      };
    case ThawToolInternalAction.SET_PLATE_HAS_TUBE_BARCODE_SCAN:
      return {
        ...state,
        sourcePlateHasTubeBarcodeScan:
          action.payload.sourcePlateHasTubeBarcodeScan,
      };
    case ThawToolInternalAction.UPDATE_METHOD_SETTINGS:
      return {
        ...state,
        methodSettings: {
          selectedSystem:
            action.payload.key === "selectedSystem"
              ? action.payload.value
              : state.methodSettings.selectedSystem,
          runTaskId:
            action.payload.key === "runTaskId"
              ? action.payload.value
              : state.methodSettings.runTaskId,
          arraySystemAutomationMethodId:
            action.payload.key === "arraySystemAutomationMethodId"
              ? action.payload.value
              : state.methodSettings.arraySystemAutomationMethodId,
          destPlateCode:
            action.payload.key === "destPlateCode"
              ? action.payload.value
              : state.methodSettings.destPlateCode,
          includeTopUpVol:
            action.payload.key === "includeTopUpVol"
              ? action.payload.value
              : state.methodSettings.includeTopUpVol,
          resuspensionVol:
            action.payload.key === "resuspensionVol"
              ? parseInt(action.payload.value)
              : state.methodSettings.resuspensionVol,
          topupVol:
            action.payload.key === "topupVol"
              ? parseInt(action.payload.value)
              : state.methodSettings.topupVol,
          rpm:
            action.payload.key === "rpm"
              ? action.payload.value
              : state.methodSettings.rpm,
          gForce:
            action.payload.key === "gForce"
              ? action.payload.value
              : state.methodSettings.gForce,
          spinTime:
            action.payload.key === "spinTime"
              ? action.payload.value
              : state.methodSettings.spinTime,
          spinAccel:
            action.payload.key === "spinAccel"
              ? action.payload.value
              : state.methodSettings.spinAccel,
          spinDecel:
            action.payload.key === "spinDecel"
              ? action.payload.value
              : state.methodSettings.spinDecel,
        },
      };
    case ThawToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE:
      return {
        ...state,
        methodSettings: {
          selectedSystem: 0,
          runTaskId: action.payload.runTaskId ?? undefined,
          arraySystemAutomationMethodId: 105,
          destPlateCode: action.payload.destPlateCode,
          includeTopUpVol: false,
          resuspensionVol: action.payload.resuspensionVol,
          topupVol: action.payload.topupVol,
          rpm: action.payload.rpm,
          gForce: action.payload.gForce,
          spinTime: action.payload.spinTime,
          spinAccel: action.payload.spinAccel,
          spinDecel: action.payload.spinDecel,
        },
      };
  }
}
