import {
  Maybe,
  Scalars,
  TicketPriority,
  TicketStatus,
  UpdateTicketInput,
} from "graphql/generated/schema-types";

export class TicketInputStub implements UpdateTicketInput {
  title?: Maybe<Scalars["String"]>;
  dateCreated?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  assetId?: Maybe<Scalars["Int"]>;
  assetTypeId?: Maybe<Scalars["Int"]>;
  priority?: Maybe<TicketPriority>;
  dateEncountered?: Maybe<Scalars["DateTime"]>;
  percentComplete?: Maybe<Scalars["Int"]>;
  requestedCompletionDate?: Maybe<Scalars["DateTime"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  ticketStatus?: Maybe<TicketStatus>;
  ticketTypeId?: Maybe<Scalars["Int"]>;
  ticketAssigneeIds?: Maybe<Array<Scalars["Int"]>>;
  status: TicketStatus;
}
