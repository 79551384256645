import { ModalAction } from "features/Plates/state/actions";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";
import { useModalStateProvider } from "../../state/StateProvider";

export function SampleModalContainer({ children }: Wrapper): JSX.Element {
  const {
    modalState: { modalOpen },
    modalDispatch,
  } = useModalStateProvider();
  return (
    <AppModal
      open={modalOpen ?? false}
      onClose={() => modalDispatch({ type: ModalAction.CLOSE_MODAL })}
    >
      {children}
    </AppModal>
  );
}
