import { useReactiveVar } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { CeligoPlateMetadata } from "features/WorklistTools/CeligoScan/Dialog/state";
import { Distinct } from "helpers/object-helpers";
import React, { useMemo } from "react";
import styled from "styled-components";
import { ReadOnlyPlatesTable } from "./ReadOnlyPlatesTable";

export const SummaryText = styled.h4`
  width: 40vw;
`;
export function ReadOnlyPlateGroupAccordion() {
  const plateMetadata = useReactiveVar(CeligoPlateMetadata);
  const groups = useMemo(
    () =>
      Distinct(
        plateMetadata.map((metadata) => ({
          ...metadata,
          sourcePlateBarcode: metadata.sourcePlateBarcode.split("_")[0],
        })),
        "sourcePlateBarcode"
      ).map((metadata) => metadata.sourcePlateBarcode),
    [plateMetadata]
  );
  return (
    <>
      {groups.map((group) => (
        <Accordion
          key={group}
          TransitionProps={{ timeout: 150, unmountOnExit: true }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <SummaryText>{group}</SummaryText>
          </AccordionSummary>
          <AccordionDetails>
            <ReadOnlyPlatesTable prefix={group} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
