import { useAppReduxSelector } from "hooks/reduxHooks";
import { WellMapping } from "../WellMapping";

export const HarvestWellsMapping = () => {
  const worklistValues = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.worklistValues
  );
  const columns = [
    {
      key: "sourcePlateIndex",
      name: "Source Plate",
      formatter(props: any) {
        return <>{props.row.sourcePlateIndex + 1 ?? ""}</>;
      },
      resizable: true,
    },
    { key: "sourceWellId", name: "Source Pos", resizable: true },
    {
      key: "destPlateIndex",
      name: "Dest Plate",
      formatter(props: any) {
        return <>{props.row.destPlateIndex + 1}</>;
      },
      resizable: true,
    },
    { key: "destWellId", name: "Dest Pos", resizable: true },
  ];

  return (
    <WellMapping
      columns={columns}
      worklistValues={worklistValues.harvestWells}
    />
  );
};
