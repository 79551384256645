import { useQuery } from "@apollo/client";
import {
  AppSuiteGroup,
  GetContainerByIdQuery,
  GetContainerByIdQueryVariables,
  GetFolderByIdQuery,
  GetFolderByIdQueryVariables,
  GetGroupsFromCacheQuery,
  GetGroupsFromCacheQueryVariables,
  GetIncomingSampleCollectionByIdQuery,
  GetIncomingSampleCollectionByIdQueryVariables,
  GetIncomingSampleTaskByIdQuery,
  GetIncomingSampleTaskByIdQueryVariables,
  GetIncomingSampleTasksByIdQuery,
  GetIncomingSampleTasksByIdQueryVariables,
  GetManualTaskTypeByIdQuery,
  GetManualTaskTypeByIdQueryVariables,
  GetMethodByIdQuery,
  GetMethodByIdQueryVariables,
  GetPlateByIdQuery,
  GetPlateByIdQueryVariables,
  GetRunActivitiesByIdsQuery,
  GetRunActivitiesByIdsQueryVariables,
  GetRunByIdQuery,
  GetRunByIdQueryVariables,
  GetRunTaskByIdQuery,
  GetRunTaskByIdQueryVariables,
  GetRunTemplateByIdQuery,
  GetRunTemplateByIdQueryVariables,
  GetRunTemplateStageByIdQuery,
  GetRunTemplateStageByIdQueryVariables,
  GetRunTemplateTaskByIdQuery,
  GetRunTemplateTaskByIdQueryVariables,
  GetRunTypeByIdQuery,
  GetRunTypeByIdQueryVariables,
  GetSampleQuery,
  GetSampleQueryVariables,
  GetTicketByIdQuery,
  GetTicketByIdQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  GetUsersFromCacheQuery,
  GetUsersFromCacheQueryVariables,
  LookupArraySystem,
  RunTask,
  RunTemplate,
  RunTemplateStage,
  RunTemplateTask,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { FolderQueries } from "graphql/queries/folder.queries";
import { IncomingSampleQueries } from "graphql/queries/incoming-samples.queries";
import { LookupQueries } from "graphql/queries/lookup.queries";
import { RunQueries } from "graphql/queries/run.queries";
import { RunTemplateQueries } from "graphql/queries/runTemplates.queries";
import { TicketQueries } from "graphql/queries/ticket.queries";
import { UserQueries } from "graphql/queries/user.queries";
import { MethodQueries } from "../graphql/queries/method.queries";
import { PlateQueries } from "../graphql/queries/plate.queries";

export const useGetUser = (userId: number | null | undefined) => {
  const { data } = useQuery<GetUserQuery, GetUserQueryVariables>(
    UserQueries.local.GET_USER_BY_ID,
    {
      variables: {
        userId: userId ?? 0,
      },
    }
  );
  return data?.user;
};

export const useGetUsersById = (userIds: number[] | null | undefined) => {
  const { data } = useQuery<
    GetUsersFromCacheQuery,
    GetUsersFromCacheQueryVariables
  >(UserQueries.local.GET_USERS_BY_IDS, {
    variables: {
      userIds: userIds ?? [],
    },
  });
  return data?.usersFromCache;
};

export const useGetGroup = (groupId: number | null | undefined) => {
  const { data } = useQuery<{ group: AppSuiteGroup }, { groupId: number }>(
    UserQueries.local.GET_GROUP_BY_ID,
    {
      variables: {
        groupId: groupId ?? 0,
      },
    }
  );
  return data?.group;
};

export const useGetGroupsById = (groupIds: number[] | null | undefined) => {
  const { data } = useQuery<
    GetGroupsFromCacheQuery,
    GetGroupsFromCacheQueryVariables
  >(UserQueries.local.GET_GROUPS_BY_IDS, {
    variables: {
      groupIds: groupIds ?? [],
    },
  });
  return data?.groupsFromCache;
};

export const useGetSystem = (systemId: number | null | undefined) => {
  const { data } = useQuery<
    { system: LookupArraySystem },
    { systemId: number }
  >(LookupQueries.local.GET_SYSTEM_BY_ID, {
    variables: {
      systemId: systemId ?? 0,
    },
  });
  return data?.system;
};
export const useGetTemplate = (runTemplateId: number | null | undefined) => {
  const { data } = useQuery<
    { runTemplate: RunTemplate },
    { runTemplateId: number }
  >(RunTemplateQueries.local.GET_RUN_TEMPLATE_BY_ID, {
    variables: {
      runTemplateId: runTemplateId ?? 0,
    },
  });
  return data?.runTemplate;
};
export const useGetTemplateStage = (
  runTemplateStageId: number | null | undefined
) => {
  const { data } = useQuery<
    { runTemplateStage: RunTemplateStage },
    { runTemplateStageId: number }
  >(RunTemplateQueries.local.GET_RUN_TEMPLATE_STAGE_BY_ID, {
    variables: {
      runTemplateStageId: runTemplateStageId ?? 0,
    },
  });
  return data?.runTemplateStage;
};
export const useGetTemplateTask = (
  runTemplateTaskId: number | null | undefined
) => {
  const { data } = useQuery<
    { runTemplateTask: RunTemplateTask },
    { runTemplateTaskId: number }
  >(RunTemplateQueries.local.GET_RUN_TEMPLATE_TASK_BY_ID, {
    variables: {
      runTemplateTaskId: runTemplateTaskId ?? 0,
    },
  });
  return data?.runTemplateTask;
};
export const useGetFolder = (folderId: number | null | undefined) => {
  const { data } = useQuery<GetFolderByIdQuery, GetFolderByIdQueryVariables>(
    FolderQueries.local.GET_FOLDER_BY_ID,
    {
      variables: {
        folderId: folderId ?? 0,
      },
    }
  );
  return data?.folder;
};

export const useGetRunType = (
  productionRunTypeId: number | null | undefined
) => {
  const { data } = useQuery<GetRunTypeByIdQuery, GetRunTypeByIdQueryVariables>(
    RunQueries.local.GET_RUN_TYPE_BY_ID,
    {
      variables: {
        productionRunTypeId: productionRunTypeId ?? 0,
      },
    }
  );
  return data?.productionRunType;
};
export const useGetRunTemplate = (runTemplateId: number | null | undefined) => {
  const { data } = useQuery<
    GetRunTemplateByIdQuery,
    GetRunTemplateByIdQueryVariables
  >(RunTemplateQueries.local.GET_RUN_TEMPLATE_BY_ID, {
    variables: {
      runTemplateId: runTemplateId ?? 0,
    },
  });
  return data?.runTemplate;
};
export const useGetRunTask = (
  runActivityId: number | null | undefined
): Maybe<RunTask> => {
  const { data } = useQuery<GetRunTaskByIdQuery, GetRunTaskByIdQueryVariables>(
    RunQueries.local.GET_RUN_TASK_BY_ID,
    {
      variables: {
        runActivityId: runActivityId ?? 0,
      },
    }
  );
  return data?.runTask as Maybe<RunTask>;
};
export const useGetRunActivitiesById = (
  runActivityIds: number[] | null | undefined
) => {
  const { data } = useQuery<
    GetRunActivitiesByIdsQuery,
    GetRunActivitiesByIdsQueryVariables
  >(RunQueries.local.GET_RUN_ACTIVITIES_BY_IDs, {
    variables: {
      runActivityIds: runActivityIds ?? [],
    },
  });
  return data?.runTasksFromCache;
};
export const useGetRunTemplateStage = (
  runTemplateStageId: number | null | undefined
) => {
  const { data } = useQuery<
    GetRunTemplateStageByIdQuery,
    GetRunTemplateStageByIdQueryVariables
  >(RunTemplateQueries.local.GET_RUN_TEMPLATE_STAGE_BY_ID, {
    variables: {
      runTemplateStageId: runTemplateStageId ?? 0,
    },
  });
  return data?.runTemplateStage;
};
export const useGetRunTemplateTask = (
  runTemplateTaskId: number | null | undefined
) => {
  const { data } = useQuery<
    GetRunTemplateTaskByIdQuery,
    GetRunTemplateTaskByIdQueryVariables
  >(RunTemplateQueries.local.GET_RUN_TEMPLATE_TASK_BY_ID, {
    variables: {
      runTemplateTaskId: runTemplateTaskId ?? 0,
    },
  });
  return data?.runTemplateTask;
};

export const useGetRun = (runId: number | null | undefined) => {
  const { data } = useQuery<GetRunByIdQuery, GetRunByIdQueryVariables>(
    RunQueries.local.GET_RUN_BY_ID,
    {
      variables: {
        runId: runId ?? 0,
      },
    }
  );
  if (!runId) return;
  return data?.run;
};

export const useGetTicket = (ticketId: number | null | undefined) => {
  const { data } = useQuery<GetTicketByIdQuery, GetTicketByIdQueryVariables>(
    TicketQueries.local.GET_TICKET_BY_ID,
    {
      variables: {
        ticketId: ticketId ?? 0,
      },
    }
  );
  if (!ticketId) return;
  return data?.ticket;
};

export const useGetIncomingSampleTask = (taskId: number | null | undefined) => {
  const { data } = useQuery<
    GetIncomingSampleTaskByIdQuery,
    GetIncomingSampleTaskByIdQueryVariables
  >(IncomingSampleQueries.local.GET_INCOMING_SAMPLE_TASK_BY_ID, {
    variables: {
      taskId: taskId ?? 0,
    },
  });
  if (!taskId) return;
  return data?.incomingSamplesTask;
};

export const useGetIncomingSampleTasks = (taskIds: number[]) => {
  const { data } = useQuery<
    GetIncomingSampleTasksByIdQuery,
    GetIncomingSampleTasksByIdQueryVariables
  >(IncomingSampleQueries.local.GET_INCOMING_SAMPLE_TASK_BY_ID, {
    variables: {
      taskIds: taskIds ?? 0,
    },
  });
  if (!taskIds) return [];
  return data?.incomingSamplesTasksFromCache ?? [];
};

export const useGetIncomingSampleCollection = (
  collectionId: number | null | undefined
) => {
  const { data } = useQuery<
    GetIncomingSampleCollectionByIdQuery,
    GetIncomingSampleCollectionByIdQueryVariables
  >(IncomingSampleQueries.local.GET_INCOMING_SAMPLE_COLLECTION_BY_ID, {
    variables: {
      collectionId: collectionId ?? 0,
    },
  });
  if (!collectionId) return;
  return data?.incomingSamplesCollection;
};

export const useGetMethod = (methodId: number | null | undefined) => {
  const { data } = useQuery<GetMethodByIdQuery, GetMethodByIdQueryVariables>(
    MethodQueries.local.GET_METHOD_BY_ID,
    {
      variables: {
        methodId: methodId ?? 0,
      },
    }
  );
  if (!methodId) return;
  return data?.method;
};
export const useGetManualTask = (id: number | null | undefined) => {
  const { data } = useQuery<
    GetManualTaskTypeByIdQuery,
    GetManualTaskTypeByIdQueryVariables
  >(MethodQueries.local.GET_MANUAL_TASK_TYPE_BY_ID, {
    variables: {
      manualTaskTypeId: id ?? 0,
    },
  });
  if (!id) return;
  return data?.manualTaskType;
};
export const useGetSample = (id: number | null | undefined) => {
  const { data } = useQuery<GetSampleQuery, GetSampleQueryVariables>(
    PlateQueries.local.GET_SAMPLE,
    {
      variables: {
        sampleId: id ?? 0,
      },
    }
  );
  if (!id) return;
  return data?.sample;
};
export const useGetPlate = (plateBarcode: string | null | undefined) => {
  const { data } = useQuery<GetPlateByIdQuery, GetPlateByIdQueryVariables>(
    PlateQueries.local.GET_PlATE_BY_ID,
    {
      variables: {
        plateBarcode: plateBarcode ?? "",
      },
    }
  );
  if (!plateBarcode) return;
  return data?.plate;
};
export const useGetContainer = (id: number | null | undefined) => {
  const { data } = useQuery<
    GetContainerByIdQuery,
    GetContainerByIdQueryVariables
  >(PlateQueries.local.GET_CONTAINER_BY_ID, {
    variables: {
      containerId: id ?? 0,
    },
  });
  if (!id) return;
  return data?.container;
};
