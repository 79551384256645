import { Button } from "@mui/material";
import { PageWithNav } from "features/Navbar/PageWithNav";
import { PersonalTaskTemplate } from "graphql/generated/schema-types";
import { useState } from "react";
import { AddNewPersonalTaskTemplate } from "../components/dialogs/AddNewPersonalTaskTemplate";
import { AddTemplateTask } from "../components/dialogs/AddTemplateTask";
import { StartTaskTemplate } from "../components/dialogs/StartTaskTemplate";
import { PersonalTaskTemplateTable } from "../components/table/PersonalTaskTemplateTable";
import { TaskInfo } from "../components/taskInfo/TaskInfo";
import { PersonalTaskTemplateTimeline } from "../components/timeline/Timeline";

export const PersonalTaskTemplatePage = () => {
  const [addNewTemplateOpen, setAddNewTemplateOpen] = useState(false);
  const [addTemplateTask, setAddTemplateTaskOpen] = useState(false);
  const [startTaskTemplateOpen, setStartTaskTemplateOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<PersonalTaskTemplate>();
  return (
    <PageWithNav>
      <div>
        <Button
          style={{ margin: "10px" }}
          variant="outlined"
          onClick={() => setAddNewTemplateOpen(true)}
        >
          Add Template
        </Button>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PersonalTaskTemplateTable
            setStartTaskTemplateOpen={setStartTaskTemplateOpen}
            setSelectedTemplate={setSelectedTemplate}
          />
          <PersonalTaskTemplateTimeline
            setAddTemplateTaskOpen={setAddTemplateTaskOpen}
          />
          <TaskInfo />
        </div>
        <AddNewPersonalTaskTemplate
          isOpen={addNewTemplateOpen}
          handleClose={() => setAddNewTemplateOpen(false)}
        />
        <StartTaskTemplate
          isOpen={startTaskTemplateOpen}
          selectedTemplate={selectedTemplate}
          handleClose={() => setStartTaskTemplateOpen(false)}
        />
        <AddTemplateTask
          isOpen={addTemplateTask}
          handleClose={() => setAddTemplateTaskOpen(false)}
        />
      </div>
    </PageWithNav>
  );
};
