import { PoolingNormalizationToolActions } from "../../state";

export const selectStampWells = (
  dispatch: (action: { type: string; payload: any }) => void,
  referenceStampShape: any,
  selectedStampWells: any
) => {
  selectedStampWells = selectedStampWells.map((well: any) => {
    return well.props.plateWellId;
  });

  const duplicates = referenceStampShape.filter((item: any) =>
    selectedStampWells.includes(item)
  );
  if (duplicates.length > 0) return;

  dispatch(
    PoolingNormalizationToolActions.REFERENCE_STAMP_WELL_ADDED({
      selections: selectedStampWells,
    })
  );
};
