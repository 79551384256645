import { Button } from "@mui/material";
import { useGetDeleteRunTask } from "features/Runs/components/Forms/Delete/hooks";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import React, { Dispatch, SetStateAction } from "react";

export function DeleteRunTaskForm({
  submitStateTuple,
}: {
  submitStateTuple: [boolean, Dispatch<SetStateAction<boolean>>];
}): JSX.Element {
  const { runsModalState, runsModalDispatch } = useRunsModalState();
  const [submitting, setSubmitting] = submitStateTuple;
  const deleteTask = useGetDeleteRunTask(setSubmitting);
  return (
    <>
      <Button
        onClick={() =>
          runsModalDispatch({
            type: RunsModalActions.CLOSE_DIALOG,
          })
        }
        color="secondary"
        variant={"contained"}
      >
        Cancel
      </Button>
      <Button
        onClick={() => deleteTask(runsModalState.selectedTasks ?? [])}
        disabled={submitting}
        color="primary"
        variant={"contained"}
      >
        Delete
      </Button>
    </>
  );
}
