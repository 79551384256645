import { ApolloQueryResult } from "@apollo/client";
import { ContextMenuDialogs } from "features/Dashboard/shared/interfaces";
import { DashboardActions } from "features/Dashboard/state/action";
import { useDashboardState } from "features/Dashboard/state/hooks";
import {
  DashboardTask,
  Exact,
  GetDashboardTasksQuery,
  Maybe,
  OperationNames,
  RescheduleTaskInput,
  TaskStatusEnum,
  useAssignTaskMutation,
  useRescheduleTasksMutation,
  useUpdateDashboardTasksMutation,
} from "graphql/generated/schema-types";
import { useGetUser } from "hooks/cacheAccessHooks";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { RescheduleTaskDialog } from "shared-components/Reschedule/RescheduleTaskDialog";
import { Alert, AlertType } from "shared-components/toast";
import { AssignToGroupsDialog } from "./AssignToGroupsDialog";
import { AssignToSystemDialog } from "./AssignToSystemDialog";
import { AssignToUsersDialog } from "./AssignToUsersDialog";
import { EditTaskDialog } from "./EditTaskDialog";
import { TaskHistoryDialog } from "./TaskHistoryDialog";

interface ContextMenuDialogProps {
  refetch(
    variables?:
      | Partial<
          Exact<{
            loggedInUser?: Maybe<number> | undefined;
            status?: Maybe<TaskStatusEnum | TaskStatusEnum[]> | undefined;
          }>
        >
      | undefined
  ): Promise<ApolloQueryResult<GetDashboardTasksQuery>>;
}

export function ContextMenuDialogSelector({
  refetch,
}: ContextMenuDialogProps): JSX.Element {
  return <>{ContextMenus(refetch)}</>;
}

function ContextMenus(
  refetch: (
    variables?:
      | Partial<
          Exact<{
            loggedInUser?: Maybe<number> | undefined;
            status?: Maybe<TaskStatusEnum | TaskStatusEnum[]> | undefined;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetDashboardTasksQuery>>
): JSX.Element {
  const [updateDashboardTask] = useUpdateDashboardTasksMutation();
  const [assignTask] = useAssignTaskMutation();

  const {
    dashboardDispatch,
    dashboardState: {
      queryVariables,
      dialogOpen,
      selectedDialog,
      selectedTasks,
    },
  } = useDashboardState();

  const closeDialog = () =>
    dashboardDispatch({
      type: DashboardActions.SET_SELECTED_DIALOG,
      payload: {
        dialogOpen: false,
      },
    });

  const updateTask_closeDialog = (data: any) => {
    dashboardDispatch({
      type: DashboardActions.UPDATE_DASHBOARD_TASKS,
      payload: {
        dashboardTasks: data!.data[queryVariables!]!,
      },
    });
    closeDialog();
  };

  const shouldQuitEarly = (doAction: boolean) => {
    const quitEarly = !doAction || !selectedTasks || selectedTasks.length === 0;
    if (quitEarly) closeDialog();
    return quitEarly;
  };

  const userId = parseInt(localStorage.getItem("userID") ?? "");
  const user = useGetUser(userId);

  const tasksCompletedOrInProgressAlert = () => {
    const noTasksWithStatus = (status: TaskStatusEnum) =>
      selectedTasks?.every((dt) => dt.status !== status);

    const noTasksCompleted = noTasksWithStatus(TaskStatusEnum.Completed);
    const noTasksInProgress = noTasksWithStatus(TaskStatusEnum.InProgress);

    if (noTasksCompleted && noTasksInProgress) return false;

    const alertStatus = !noTasksCompleted ? "Completed" : "In Progress";

    Alert({
      type: AlertType.ERROR,
      message: `You cannot reschedule a task marked '${alertStatus}'.`,
    });
    closeDialog();
    return true;
  };

  const [rescheduleTasks] = useRescheduleTasksMutation();
  const rescheduleTask_handleClose = (date: Date, updateTasks: boolean) => {
    if (shouldQuitEarly(updateTasks) || tasksCompletedOrInProgressAlert())
      return;

    date = new Date(date.toISOString());
    const startTime = moment(date);

    const toRescheduleTaskInput = (dt: DashboardTask): RescheduleTaskInput => {
      const start = moment(dt.startTime);
      const end = moment(dt.endTime);
      const duration = end.diff(start, "minutes");
      return {
        taskId: dt.taskId,
        taskType: dt.taskType,
        startTimeScheduled: startTime,
        endTimeScheduled: startTime.clone().add(duration, "minutes"), // clone() creates copy and prevents mutating initial moment object
        systemAssignedTo: dt.systemId,
      };
    };

    rescheduleTasks({
      variables: {
        models: selectedTasks!.map(toRescheduleTaskInput),
      },
      refetchQueries: [OperationNames.Query.getCalendarNodes],
    }).then(() => {
      Alert({ type: AlertType.SUCCESS, message: "Task Rescheduled" });
      refetch().then((data: any) => {
        updateTask_closeDialog(data);
      });
    });
  };

  const editTask_handleClose = (
    selectedTask: DashboardTask,
    editTask: boolean
  ) => {
    if (!editTask) {
      closeDialog();
      return;
    }

    const updatedTask = _.cloneDeep(selectedTask);
    delete updatedTask.assignedUsers;
    delete updatedTask.assignedGroups;

    updateDashboardTask({
      variables: {
        dashboardTasks: [updatedTask],
      },
    }).then(() => {
      refetch().then((data: any) => {
        updateTask_closeDialog(data);
      });
    });
  };

  // references AssignDashboardTaskInput
  const toSelectedTask = (task: DashboardTask) => {
    return { taskId: task.taskId, taskType: task.taskType };
  };

  const assignToUsers_handleClose = (
    assignedUsers: number[],
    assignUsers: boolean
  ) => {
    if (shouldQuitEarly(assignUsers)) return;

    assignTask({
      variables: {
        taskInput: {
          selectedTasks: selectedTasks!.map(toSelectedTask),
          assignedUsers,
        },
      },
    }).then(() => {
      refetch().then((data: any) => {
        updateTask_closeDialog(data);
      });
    });
  };

  const assignToGroups_handleClose = (
    assignedGroups: number[],
    assignGroups: boolean
  ) => {
    if (shouldQuitEarly(assignGroups)) return;

    assignTask({
      variables: {
        taskInput: {
          selectedTasks: selectedTasks!.map(toSelectedTask),
          assignedGroups,
        },
      },
    }).then(() => {
      refetch().then((data: any) => {
        updateTask_closeDialog(data);
      });
    });
  };

  const assignToSystem_handleClose = (
    systemId: number,
    assignSystem: boolean
  ) => {
    if (shouldQuitEarly(assignSystem)) return;

    const updatedTasks = _.cloneDeep(selectedTasks!);
    updatedTasks.forEach((task) => {
      delete task.assignedUsers;
      delete task.assignedGroups;
      task.systemId = systemId;
    });

    updateDashboardTask({
      variables: {
        dashboardTasks: updatedTasks,
      },
      refetchQueries: [OperationNames.Query.getCalendarNodes],
    }).then(() => {
      Alert({ type: AlertType.SUCCESS, message: "System Updated" });
      refetch().then((data: any) => {
        updateTask_closeDialog(data);
      });
    });
  };

  const isOpen = dialogOpen ?? false;
  const selectedTask = selectedTasks![0];
  const selectedTasks_ = selectedTasks ?? [];

  switch (selectedDialog) {
    case ContextMenuDialogs.AssignToUser:
      return (
        <AssignToUsersDialog
          isOpen={isOpen}
          selectedTasks={selectedTasks_}
          handleClose={(assignedUsers, assignUsers) =>
            assignToUsers_handleClose(assignedUsers, assignUsers)
          }
        />
      );
    case ContextMenuDialogs.AssignToGroup:
      return (
        <AssignToGroupsDialog
          isOpen={isOpen}
          selectedTasks={selectedTasks_}
          handleClose={(assignedGroup, assignGroup) =>
            assignToGroups_handleClose(assignedGroup, assignGroup)
          }
        />
      );
    case ContextMenuDialogs.AssignToSystem:
      return (
        <AssignToSystemDialog
          isOpen={isOpen}
          selectedTasks={selectedTasks_}
          handleClose={(systemId, assignSystem) =>
            assignToSystem_handleClose(systemId, assignSystem)
          }
        />
      );
    case ContextMenuDialogs.RescheduleTask:
      return (
        <RescheduleTaskDialog
          isOpen={isOpen}
          selectedTasks={selectedTasks_}
          handleClose={(date, updateTasks) =>
            rescheduleTask_handleClose(date, updateTasks)
          }
          dateInput={{
            dialogTitle: "Reschedule Task",
            dateInput: selectedTask.startTime,
          }}
        />
      );
    case ContextMenuDialogs.EditTask:
      return (
        <EditTaskDialog
          isOpen={isOpen}
          selectedTask={selectedTask}
          handleClose={(task, editTask) => editTask_handleClose(task, editTask)}
        />
      );
    case ContextMenuDialogs.ViewTaskHistory:
      return (
        <TaskHistoryDialog
          isOpen={isOpen}
          selectedTask={selectedTask}
          handleClose={() => closeDialog()}
        />
      );
    default:
      return <React.Fragment />;
  }
}
