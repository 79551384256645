import { Button, TextField } from "@mui/material";
import { StaticDateRangePicker } from "@mui/lab";
import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import { CollectionTask } from "graphql/generated/schema-types";
import * as React from "react";
import { FilterProps } from "react-table";

export function DateRangeFilter({
  column: { filterValue, setFilter },
}: FilterProps<CollectionTask>): JSX.Element {
  return (
    <>
      <StaticDateRangePicker
        displayStaticWrapperAs="mobile"
        value={filterValue ?? [null, null]}
        onChange={(newValue: DateRange<unknown>) => setFilter(newValue)}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <div> to </div>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
      <Button onClick={() => setFilter(undefined)}>Clear</Button>
    </>
  );
}
