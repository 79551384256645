import { CeligoScanForm } from "features/Dashboard/MethodDetails/CeligoScan/CeligoScanForm";
import { useGetDashboardTaskHandleSubmit } from "features/Dashboard/MethodDetails/CeligoScan/handleSubmit";
import { PlateGroupAccordionContainer } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/PlateGroupAccordionContainer";
import { CeligoMethodAlreadyReserved } from "features/WorklistTools/CeligoScan/Dialog/state";
import {
  DashboardTask,
  MethodReservation,
  TaskTypeEnum,
  useGetMethodReservationByPersonalTaskIdLazyQuery,
  useGetMethodReservationByRunActivityIdLazyQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { PersonalTaskInputMapper } from "mappings/personalTaskInput";
import React, { useEffect, useState } from "react";

export function CeligoScan({
  personalTask,
}: {
  personalTask: DashboardTask;
}): JSX.Element {
  const [
    getMethodReservationByPersonalTaskId,
    { data: methodReservationByPersonalTask },
  ] = useGetMethodReservationByPersonalTaskIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [
    getMethodReservationByRunActivityTaskId,
    { data: methodReservationByRunActivity },
  ] = useGetMethodReservationByRunActivityIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [methodReservation, setMethodReservation] = useState<
    Maybe<MethodReservation> | undefined
  >();

  useEffect(() => {
    if (personalTask.taskType === TaskTypeEnum.Personal)
      setMethodReservation(
        methodReservationByPersonalTask
          ?.methodReservation?.[0] as Maybe<MethodReservation>
      );
    else if (personalTask.taskType === TaskTypeEnum.RunTask)
      setMethodReservation(
        methodReservationByRunActivity
          ?.methodReservation?.[0] as Maybe<MethodReservation>
      );
  }, [
    methodReservationByPersonalTask?.methodReservation,
    methodReservationByRunActivity?.methodReservation,
    personalTask.taskType,
  ]);

  useEffect(() => {
    if (personalTask.taskType === TaskTypeEnum.Personal)
      getMethodReservationByPersonalTaskId({
        variables: {
          personalTaskId: personalTask.taskId,
        },
      });
    else if (personalTask.taskType === TaskTypeEnum.RunTask)
      getMethodReservationByRunActivityTaskId({
        variables: {
          runActivityId: personalTask.taskId,
        },
      });
  }, [
    getMethodReservationByPersonalTaskId,
    getMethodReservationByRunActivityTaskId,
    personalTask.taskId,
    personalTask.taskType,
  ]);

  const personalTaskInput = PersonalTaskInputMapper(personalTask);
  const handleSubmit = useGetDashboardTaskHandleSubmit(
    methodReservation,
    personalTaskInput
  );

  useEffect(() => {
    CeligoMethodAlreadyReserved(!!methodReservation);
  }, [methodReservation]);

  return (
    <>
      <PlateGroupAccordionContainer
        methodReservation={methodReservation}
        platesQueryFetchPolicy={"cache-and-network"}
      />
      <CeligoScanForm
        handleSubmit={handleSubmit}
        initialValue={personalTaskInput}
        methodReservationId={methodReservation?.methodReservationId}
      />
    </>
  );
}
