import { AppTheme } from "App.theme";
import { Buttons } from "features/Dashboard/MethodDetails/CeligoScan/Buttons/Buttons";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import {
  DashboardTaskInput,
  PlateMetadataInput,
} from "graphql/generated/schema-types";
import * as _ from "lodash";
import React from "react";
import styled from "styled-components";

const ErrorEntryTitle = styled.h6`
  color: ${({ theme }) => theme.palette.primary.light};
`;
const ErrorEntry = styled.li`
  color: ${({ theme }) => theme.palette.primary.light};
`;

export function CeligoScanForm({
  handleSubmit,
  initialValue,
  methodReservationId,
}: {
  handleSubmit: (
    values: DashboardTaskInput,
    actions: FormikHelpers<DashboardTaskInput>
  ) => void;
  initialValue: DashboardTaskInput;
  methodReservationId?: number;
}): JSX.Element {
  if (!initialValue) return <React.Fragment />;
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValue}>
      {({ handleSubmit, errors, isSubmitting }) => {
        let plateErrors: Array<
          { barcode: string } & FormikErrors<PlateMetadataInput>
        > = [];
        if (errors?.methodReservationPlateMetadata)
          plateErrors = JSON.parse(
            errors.methodReservationPlateMetadata as string
          );
        return (
          <form onSubmit={handleSubmit}>
            <Buttons
              isSubmitting={isSubmitting}
              methodReservationId={methodReservationId}
            />
            {plateErrors.map((plateError) => (
              <>
                <ErrorEntryTitle>{plateError.barcode}</ErrorEntryTitle>
                <ul>
                  {Object.entries(plateError).map((entry, idx) => {
                    if (entry[0] !== "barcode")
                      return (
                        <ErrorEntry
                          key={idx}
                          color={AppTheme.palette.secondary.light}
                        >
                          {_.startCase(entry[0])}: {entry[1]}
                        </ErrorEntry>
                      );
                    else return null;
                  })}
                </ul>
              </>
            ))}
          </form>
        );
      }}
    </Formik>
  );
}
