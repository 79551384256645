export enum IncomingSampleActions {
  OPEN_COLLECTION_SAMPLES,
  CLOSE_MODAL,
  CLOSE_DIALOG,
  OPEN_MODAL,
  SELECT_TASKS,
  SELECT_SCREEN,
  SELECT_COLLECTIONS,
  OPEN_TASK_SUMMARY,
  OPEN_CONFIRM_DIALOG,
}
