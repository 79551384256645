import { ApolloQueryResult } from "@apollo/client";
import { RunsModalsAndDialogs } from "features/Runs/components/ModalsAndDialogs/RunModalsAndDialogs";
import {
  DashboardTask,
  Exact,
  GetDashboardTasksQuery,
  Maybe,
  TaskPriorityEnum,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import React, { useEffect, useMemo, useState } from "react";
import { contextMenu } from "react-contexify";
import {
  Row,
  SortingRule,
  TableOptions,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { InitialDashboardTaskState } from "services/DashboardService";
import { ReactTable } from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { DashboardActions } from "../../state/action";
import { useDashboardState } from "../../state/hooks";
import { ContextMenuDialogSelector } from "../Dialogs/ContextMenuDialogs";
import { TaskDetailsDialog } from "../Dialogs/TaskDetailsDialog";
import { DashboardTasksTableColumns } from "./Columns";
import { DashboardContextMenu } from "./ContextMenu";

interface DashboardTaskTableProps {
  loggedInUserId: number;
  refetch(
    variables?:
      | Partial<
          Exact<{
            loggedInUser?: Maybe<number> | undefined;
            status?: Maybe<TaskStatusEnum | TaskStatusEnum[]> | undefined;
          }>
        >
      | undefined
  ): Promise<ApolloQueryResult<GetDashboardTasksQuery>>;
  loading: boolean;
}
export function DashboardTaskTable({
  loggedInUserId,
  refetch,
  loading,
}: DashboardTaskTableProps): JSX.Element {
  const [pageData, setPageData] = useState<DashboardTask[]>([]);
  const [detailsDialogState, setDetailsDialogState] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<DashboardTask[]>([
    InitialDashboardTaskState,
  ]);

  const {
    dashboardDispatch,
    dashboardState: { tabIndex, dashboardTasks },
  } = useDashboardState();

  interface Filter {
    id: string;
    value: TaskStatusEnum[];
  }

  const filterStatusFromTab = () => {
    switch (tabIndex) {
      case 0: //overdue
        return [TaskStatusEnum.Overdue];
      case 1: //mytasks
        return [TaskStatusEnum.InProgress, TaskStatusEnum.Scheduled];
      case 2: //grouptasks
        return [TaskStatusEnum.InProgress, TaskStatusEnum.Scheduled];
      default:
        return [
          TaskStatusEnum.InProgress,
          TaskStatusEnum.Scheduled,
          TaskStatusEnum.Overdue,
        ];
    }
  };

  const [filters, setFilters] = useState<Filter[]>([
    {
      id: "status",
      value: [
        TaskStatusEnum.InProgress,
        TaskStatusEnum.Scheduled,
        TaskStatusEnum.Overdue,
      ],
    },
  ]);

  useMemo(() => {
    setFilters([
      {
        id: "status",
        value: filterStatusFromTab(),
      },
    ]);
  }, [tabIndex]);

  useEffect(() => {
    handleFilter(filters);
  }, [dashboardTasks]);

  const handleFilter = (filters: Filter[]) => {
    setFilters(filters);

    const byStartTime = (a: DashboardTask, b: DashboardTask) => {
      const aValue = a.startTime;
      const bValue = b.startTime;
      if (aValue === bValue) return 0;
      return aValue > bValue ? 1 : -1;
    };

    let tasks = [...(dashboardTasks ?? [])];
    if (!filters.length) {
      if (tasks.length) setPageData(tasks.sort(byStartTime));
      return;
    }

    filters.forEach((filter: Filter, index: number) => {
      const id = filters[index].id;
      tasks = tasks.filter((d: any) => {
        if (["status", "priority"].includes(id)) {
          return filters[index].value.includes(d[id]);
        } else if (["assignedUsers", "assignedGroups"].includes(id)) {
          return filters[index].value.some((r: any) => d[id].includes(r));
        } else {
          return d[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase());
        }
      });
    });

    setPageData(tasks.sort(byStartTime));
  };

  const handleContextMenu =
    (selected: DashboardTask[], row?: Row<DashboardTask>) =>
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      e.preventDefault();
      row?.toggleRowSelected(true);
      contextMenu.show({
        id: "dashboard-context-menu",
        event: e,
        props: {
          selected: selected.includes(row!.original)
            ? selected
            : [...selected, row?.original],
        },
      });
    };

  const handleRowClick = (rowClicked: any) => {
    if (!rowClicked?.isGrouped) {
      setDetailsDialogState(true);
      setSelectedTasks([rowClicked.original]);
    }
  };

  const options: TableOptions<DashboardTask> = {
    columns: DashboardTasksTableColumns,
    data: pageData,
    manualFilters: true,
    manualSortBy: true,
    initialState: {
      filters: [
        filters[0],
        {
          id: "priority",
          value: Object.values(TaskPriorityEnum),
        },
      ],
      sortBy: [
        { id: "startDate", desc: false },
        { id: "startTime", desc: false },
      ],
    },
    autoResetFilters: false,
    autoResetGroupBy: false,
  };

  const tableInstance = useTable(
    options,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  const onSort = (sortingRules: SortingRule<DashboardTask>[]) => {
    if (!dashboardTasks?.length || !sortingRules.length) return;
    const id = sortingRules[0].id;

    // can't use bracket notation w/ typed object?
    const compareFn = (a: any, b: any) => {
      let aValue = a[id];
      let bValue = b[id];

      if (id === "priority") {
        const getPriorityMapping = (priority: TaskPriorityEnum) => {
          switch (priority) {
            case "IMMEDIATE":
              return 1;
            case "HIGH":
              return 2;
            case "MEDIUM":
              return 3;
            case "LOW":
              return 4;
          }
        };
        aValue = getPriorityMapping(aValue);
        bValue = getPriorityMapping(bValue);
      } else if (id === "startDate") {
        aValue = a["startTime"];
        bValue = b["startTime"];
      }
      if (aValue === bValue) return 0;

      let retVal = aValue > bValue ? 1 : -1;
      if (sortingRules[0].desc) retVal *= -1;
      return retVal;
    };

    const tasks = [...(dashboardTasks ?? [])];
    tasks.sort(compareFn);

    dashboardDispatch({
      type: DashboardActions.UPDATE_DASHBOARD_TASKS,
      payload: {
        dashboardTasks: tasks,
      },
    });
  };

  const getTasks = (args: any) => args.props?.selected ?? [];

  return (
    <>
      <ReactTable
        tableInstance={tableInstance}
        onSort={onSort}
        onFilter={handleFilter}
        stateChangeDebounceDuration={500}
        onRowContextMenu={handleContextMenu}
        onRowClick={(rowClicked) => () => handleRowClick(rowClicked)}
        loading={loading}
      />
      <DashboardContextMenu
        loggedInUserId={loggedInUserId}
        refetch={refetch}
        getTasks={getTasks}
      />

      <TaskDetailsDialog
        isOpen={detailsDialogState}
        handleClose={() => setDetailsDialogState(false)}
        selectedTask={selectedTasks[0]}
      />

      <ContextMenuDialogSelector refetch={refetch} />
      <RunsModalsAndDialogs />
    </>
  );
}
