import { Delete, GetApp, Upload } from "@mui/icons-material";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { AppTheme } from "App.theme";
import { Maybe } from "graphql-tools";
import {
  PersonalTaskAttachment,
  RunTaskAttachment,
  TicketAttachment,
  useGetMeQuery,
} from "graphql/generated/schema-types";
import { GetUrl } from "helpers/get-url";
import React, { Dispatch, SetStateAction, useMemo, useRef } from "react";
import Loading from "shared-components/Loading";
import styled from "styled-components/macro";

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20%;
  margin: 10px 0;
`;

export function AttachmentActions({
  attachments,
  indexTuple,
  handleUpload,
  handleDelete,
  deleteAttachmentLoading,
  loading,
}: {
  attachments: Maybe<
    TicketAttachment | PersonalTaskAttachment | RunTaskAttachment
  >[];
  handleUpload: (files: File[]) => Promise<void>;
  handleDelete: () => void;
  deleteAttachmentLoading: boolean;
  loading: boolean;
  indexTuple: [number, Dispatch<SetStateAction<number>>];
}): JSX.Element {
  const backendUrl = GetUrl();

  const { data: loggedInUserQuery } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  const [index, setIndex] = indexTuple;

  const handleDownload = useMemo(
    () => () => {
      const url = `${backendUrl}${attachments[index]?.attachmentUrl}` ?? "";
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      downloadLink.href = url;
      downloadLink.target = "_self";
      downloadLink.download = attachments[index]?.attachmentName ?? "";
      downloadLink.click();
    },
    [attachments, backendUrl, index]
  );
  const currentUserNameUploaded = useMemo(() => {
    if (loggedInUserQuery?.me?.loggedInGroupMember?.user?.userName)
      return (
        loggedInUserQuery.me.loggedInGroupMember.user!.userName ===
        attachments[index]?.uploadedBy
      );
    return loggedInUserQuery!.me!.userName === attachments[index]?.uploadedBy;
  }, [attachments, index, loggedInUserQuery]);

  const currentUserIdUploaded = useMemo(() => {
    if (loggedInUserQuery?.me?.loggedInGroupMember?.user?.userId)
      return (
        loggedInUserQuery.me.loggedInGroupMember.user!.userId ===
        attachments[index]?.uploadedBy
      );
    return loggedInUserQuery!.me!.userName === attachments[index]?.uploadedBy;
  }, [attachments, index, loggedInUserQuery]);
  const uploadButtonRef = useRef<HTMLInputElement | null>(null);
  return (
    <ActionsContainer>
      <Tooltip title="Delete" aria-label="delete">
        <IconButton
          color={"inherit"}
          disabled={attachments.length === 0}
          onClick={handleDelete}
          size="large"
        >
          {!deleteAttachmentLoading ? (
            <Delete />
          ) : (
            <Loading name={"circle"} color={AppTheme.palette.primary.main} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Download" aria-label="download">
        <IconButton
          color={"inherit"}
          onClick={handleDownload}
          disabled={attachments.length === 0}
          size="large"
        >
          <GetApp />
        </IconButton>
      </Tooltip>
      <input
        ref={uploadButtonRef}
        type="file"
        id="upload-button"
        onChange={async (e) => {
          if (e.target.files) await handleUpload(Array.from(e.target.files));
        }}
        style={{ display: "none" }}
      />
      <Tooltip title={"Upload"}>
        <IconButton
          id="OpenImgUpload"
          disabled={loading}
          onClick={() => uploadButtonRef.current?.click()}
          size="large"
        >
          {loading ? <CircularProgress /> : <Upload />}
        </IconButton>
      </Tooltip>
    </ActionsContainer>
  );
}
