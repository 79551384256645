import { useReactiveVar } from "@apollo/client";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import TabUnselectedIcon from "@mui/icons-material/TabUnselected";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import {
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { StyledToggleButtonGroup } from "features/WorklistTools/shared/components/styles/styled-components";
import { SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { is96WellPlate } from "features/WorklistTools/shared/WorklistHelpers";
import { useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import {
  useHandleDestSelectionProcessClick,
  useHandleRandomizeClick,
} from "../state/handlers";
import { ThawToolInternalState } from "../state/initial-state";

export const SelectionView = () => {
  const thawToolState = useReactiveVar(ThawToolInternalState);
  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );
  const handleRandomizeClick = useHandleRandomizeClick();
  const handleDestSelectionClick = useHandleDestSelectionProcessClick();
  return (
    <React.Fragment>
      {!is96WellPlate(thawWorklistToolInfo.destPlateInfo[0].labwareTypeCode) ? (
        <React.Fragment>
          <StyledToggleButtonGroup
            // style={{ border: "none", marginRight: "20px" }}
            // orientation="horizontal"
            value={thawToolState.destSelectionProcess}
            exclusive
            onChange={(e, value) => handleDestSelectionClick(value)}
          >
            <ToggleButton
              value={SelectionProcess.Stamp}
              aria-label="sortSampleColor"
            >
              <Tooltip title="Stamp" placement="right">
                <TabUnselectedIcon style={{ transform: "scaleX(-1)" }} />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value={SelectionProcess.RowWise}
              aria-label="sortMarkingColor"
            >
              <Tooltip title="Row" placement="right">
                <ViewColumnIcon style={{ transform: "rotate(90deg)" }} />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value={SelectionProcess.ColWise}
              aria-label="sortMarkingColor"
            >
              <Tooltip title="Column" placement="right">
                <ViewColumnIcon />
              </Tooltip>
            </ToggleButton>
          </StyledToggleButtonGroup>
          <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "5px",
            }}
          >
            <ToggleButton
              sx={{ border: "none", marginRight: "3px" }}
              value="check"
              selected={thawToolState.randomizeWells}
              onChange={handleRandomizeClick(!thawToolState.randomizeWells)}
            >
              <Tooltip title="Randomize" placement="right">
                <ShuffleIcon />
              </Tooltip>
            </ToggleButton>
          </div>
        </React.Fragment>
      ) : (
        <ToggleButtonGroup
          style={{ border: "none", marginRight: "20px" }}
          orientation="horizontal"
          value={thawToolState.destSelectionProcess}
          exclusive
          onChange={(e, value) => handleDestSelectionClick(value)}
        >
          <ToggleButton
            value={SelectionProcess.Stamp}
            aria-label="sortSampleColor"
          >
            <Tooltip title="Stamp" placement="right">
              <TabUnselectedIcon style={{ transform: "scaleX(-1)" }} />
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </React.Fragment>
  );
};
