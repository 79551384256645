import { Checkbox, FormControlLabel } from "@mui/material";
import produce from "immer";
import _ from "lodash";
import React from "react";
import { FilterProps } from "react-table";
import styled from "styled-components";

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const GetMultiSelectFilter = (
  options: Array<{ value: number | string; name: string }>
) => {
  return ({
    column: { filterValue, setFilter },
  }: FilterProps<Record<string, unknown>>): JSX.Element => {
    console.log(filterValue);
    return (
      <FilterContainer>
        {options.map(({ value, name }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                checked={filterValue?.indexOf(value) > -1}
                onChange={(e) => {
                  const filter = produce(
                    (filterValue as (string | number)[]) ?? [],
                    (draft) => {
                      if (e.target.checked && draft.indexOf(value) <= 0)
                        draft.push(value);
                      else draft.splice(draft.indexOf(value), 1);
                    }
                  );
                  if (filter.length) setFilter(filter);
                  else setFilter(null);
                }}
              />
            }
            label={_.startCase(name)}
          />
        ))}
      </FilterContainer>
    );
  };
};
