import {
  AppSuiteUserFilterInput,
  UserFragment,
} from "graphql/generated/schema-types";
import { Filters } from "react-table";

export const UserManagerFilterReducer = (
  tableFilters: Filters<UserFragment>
) => {
  const where: AppSuiteUserFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "userId":
        where!.and!.push({
          userId: { eq: tableFilter.value },
        });
        break;
      case "userName":
        where!.and!.push({
          userName: { contains: tableFilter.value },
        });
        break;
      case "email":
        where!.and!.push({
          email: { contains: tableFilter.value },
        });
        break;
      case "isActive":
        where!.and!.push({
          isActive: { eq: tableFilter.value === "true" },
        });
        break;
      case "groups":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            appSuiteGroupUsers: {
              some: { groupId: { in: tableFilter.value } },
            },
          });
        else where!.and!.filter((f) => !f.appSuiteGroupUsers);
        break;
      case "roles":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            userToRoles: { some: { roleId: { in: tableFilter.value } } },
          });
        else where!.and!.filter((f) => !f.userToRoles);
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
