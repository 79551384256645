import { SourcePlateInfoState } from "./state";

export const getSourcePlateInfoState = (): SourcePlateInfoState => {
  return {
    plateBarcode: "",
    labwareTypeCode: "",
    operatingVol: 0,
    rows: [],
    cols: [],
    wellInfo: [],
    discardSourcePlate: -1,
  };
};
