import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useTicketQueryProvider } from "features/Tickets/Context";
import {
  TicketStatus,
  useAcceptTicketMutation,
  useGetMeQuery,
  useGetTicketInfoQuery,
  useUpdateTicketsMutation,
} from "graphql/generated/schema-types";
import React, { useState } from "react";
import { Alert, AlertType } from "shared-components/toast";

export function TicketSummaryActionsMenu({
  ticketId,
}: {
  ticketId: number;
}): JSX.Element {
  const [buttonRef, setButtonRef] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);

  const handleClose = (e: React.MouseEvent<HTMLDivElement | HTMLLIElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setButtonRef(null);
  };
  const { refetch } = useTicketQueryProvider()!;
  const [updateTicket] = useUpdateTicketsMutation({
    onCompleted: () => refetch(),
  });
  const [acceptTicket] = useAcceptTicketMutation();
  const { data: ticketQuery } = useGetTicketInfoQuery({
    variables: {
      ticketId,
    },
    fetchPolicy: "cache-only",
  });

  const { data: userClaimsQuery } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });

  const userNotAssignedToTicket =
    !ticketQuery?.ticketInfo?.ticketAssignees?.some(
      (assignee) => assignee?.userId === userClaimsQuery?.me?.userId
    );
  return (
    <IconButton
      aria-label="ticket-actions"
      onClick={(e) => {
        setButtonRef(e.currentTarget);
      }}
      size="large"
    >
      <MoreVert />
      <Menu
        id="simple-menu"
        anchorEl={buttonRef}
        keepMounted
        open={!!buttonRef}
        onClose={handleClose}
      >
        {userNotAssignedToTicket && (
          <MenuItem
            onClick={(e) => {
              acceptTicket({
                variables: {
                  ticketId,
                },
              })
                .then(() =>
                  Alert({ type: AlertType.SUCCESS, message: "Ticket Accepted" })
                )
                .catch((e) => console.log(e));
              handleClose(e);
            }}
          >
            Accept Ticket
          </MenuItem>
        )}
        <MenuItem
          onClick={(e) => {
            updateTicket({
              variables: {
                inputs: [
                  {
                    primaryKey: ticketId,
                    set: { status: TicketStatus.InProgress },
                  },
                ],
              },
            })
              .then(() =>
                Alert({
                  type: AlertType.SUCCESS,
                  message: "Ticket Status Changed",
                })
              )
              .catch((e) => console.log(e));
            handleClose(e);
          }}
        >
          In Progress
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            updateTicket({
              variables: {
                inputs: [
                  {
                    primaryKey: ticketId,
                    set: { status: TicketStatus.Pending },
                  },
                ],
              },
            })
              .then(() =>
                Alert({
                  type: AlertType.SUCCESS,
                  message: "Ticket Status Changed",
                })
              )
              .catch((e) => console.log(e));
            handleClose(e);
          }}
        >
          Pending
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            updateTicket({
              variables: {
                inputs: [
                  {
                    primaryKey: ticketId,
                    set: { status: TicketStatus.Resolved },
                  },
                ],
              },
            })
              .then(() =>
                Alert({
                  type: AlertType.SUCCESS,
                  message: "Ticket Status Changed",
                })
              )
              .catch((e) => console.log(e));
            handleClose(e);
          }}
        >
          Resolved
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            updateTicket({
              variables: {
                inputs: [
                  {
                    primaryKey: ticketId,
                    set: { status: TicketStatus.Open },
                  },
                ],
              },
            })
              .then(() =>
                Alert({
                  type: AlertType.SUCCESS,
                  message: "Ticket Status Changed",
                })
              )
              .catch((e) => console.log(e));
            handleClose(e);
          }}
        >
          Open
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            updateTicket({
              variables: {
                inputs: [
                  {
                    primaryKey: ticketId,
                    set: { status: TicketStatus.Closed },
                  },
                ],
              },
            })
              .then(() =>
                Alert({
                  type: AlertType.SUCCESS,
                  message: "Ticket Status Changed",
                })
              )
              .catch((e) => console.log(e));
            handleClose(e);
          }}
        >
          Closed
        </MenuItem>
      </Menu>
    </IconButton>
  );
}
