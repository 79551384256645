import { Methods } from "enums/Methods";
import { XTYCustomPassagePlatesTable } from "features/Runs/components/Forms/MethodDetails/XTYCustomPassage/PlatesTable";
import { XTYCustomPassageForm } from "features/Runs/components/Forms/MethodDetails/XTYCustomPassage/XTYCustomPassageForm";
import { RunTask } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useGetUnavailablePlates } from "hooks/useGetUnavailablePlates";
import React, { useMemo } from "react";

export function XTYCustomPassage({
  runActivity,
}: {
  runActivity: Maybe<RunTask>;
}): JSX.Element {
  const unavailablePlates = useGetUnavailablePlates(Methods.XTY_CustomPassage);
  const reserved = runActivity?.methodReservations?.[0]?.methodReservationId;
  const plates = useMemo(
    () =>
      reserved
        ? runActivity?.run?.productionRun?.platesCreateds
        : runActivity?.run?.productionRun?.platesCreateds?.filter(
            (plate) => !unavailablePlates.includes(plate?.plateBarcode ?? "")
          ),
    [
      reserved,
      runActivity?.run?.productionRun?.platesCreateds,
      unavailablePlates,
    ]
  );
  if (!runActivity?.run?.productionRun?.platesCreateds)
    return <React.Fragment />;
  const hasActivePlates =
    (plates
      ?.filter((plate) => plate?.isActive)
      .map((plate) => plate?.plateBarcode).length ?? 0) > 0;
  return (
    <div>
      <XTYCustomPassagePlatesTable runActivity={runActivity} />
      <XTYCustomPassageForm
        runActivity={runActivity}
        hasActivePlates={hasActivePlates}
      />
    </div>
  );
}
