import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  AppSuiteUser,
  ManagePersonalTask,
  useGetUsersQuery,
} from "graphql/generated/schema-types";
import { useGetUsersById } from "hooks/cacheAccessHooks";
import React, { ChangeEvent, useState } from "react";

interface AssignToUsersProps {
  isOpen: boolean;
  handleClose: (assignedUsers: number[], assignUsers: boolean) => void;
  selectedTask: ManagePersonalTask;
}

export const AssignToUsersDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: AssignToUsersProps) => {
  const usersQuery = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const assignedUsers = useGetUsersById(selectedTask!.assignedUsers!);
  const [selectedUsers, setSelectedUsers] = useState<AppSuiteUser[]>([]);

  const handleUsersChanged = (e: ChangeEvent<unknown>, value: any) => {
    setSelectedUsers(value);
  };

  const handleSubmit = () => {
    const userIds = selectedUsers!.map((user) => {
      return user!.userId!;
    });
    handleClose(userIds, true);
  };

  const clearSelectedUsers = () => {
    setSelectedUsers([]);
    handleClose([], false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => clearSelectedUsers()}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Assign Tasks to User(s)</DialogTitle>
        <DialogContent>
          <ul>
            <li>{selectedTask.taskName}</li>
          </ul>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={usersQuery.data?.appSuiteUsers?.map((node) => node) ?? []}
            getOptionLabel={(option) => option!.userName!}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              handleUsersChanged(event, newValue)
            }
            // getOptionSelected={(selected, option) =>
            //   !!(
            //     selected?.userId &&
            //     option?.userId &&
            //     selected?.userId === option?.userId
            //   )
            // }
            value={selectedUsers.length > 0 ? selectedUsers : assignedUsers}
          />
          {/* <Autocomplete
            id="userAssignedTo"
            disableClearable
            options={usersQuery.data?.appSuiteUsers?.map((node) => node) ?? []}
            getOptionLabel={(option) => option!.userName!}
            style={{ margin: "0px 0", width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User Assigned To"
                variant="outlined"
              />
            )}
            // value={
            //   values.systemAssignedTo ? values.systemAssignedTo : undefined
            // }
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              handleUsersChanged(event, newValue)
            }
          />
          <Autocomplete
        multiple
        id="tags-outlined"
        options={usersQuery.data?.appSuiteUsers?.map((node) => node) ?? []}
        getOptionLabel={(option) => option!.userName!}
        // defaultValue={[top100Films[13]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="filterSelectedOptions"
            placeholder="Favorites"
          />
        )}
      /> */}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
