import { Maybe } from "graphql/jsutils/Maybe";
import { cache } from "../cache/cache";
import { TableName } from "../enums/TableName";
import { GetMeDocument, GetMeQuery } from "../graphql/generated/schema-types";
import { AppUserSettings, TablePreset } from "../interfaces/AppUserSettings";

export const GetUserClaims = () => {
  const claims = cache.readQuery<GetMeQuery>({
    query: GetMeDocument,
  })?.me;
  return claims;
};

export const GetUserSettings = (): Maybe<AppUserSettings> =>
  JSON.parse(GetUserClaims()?.setting?.settingsJson ?? "null");

export const GetTableDefaultPreset = (
  tableName: TableName
): Maybe<TablePreset> =>
  GetUserSettings()
    ?.tables?.find((table) => table?.name === tableName)
    ?.presets.find((preset) => preset?.default);
