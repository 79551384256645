import {
  PersonalTasksRecurringTask,
  PersonalTasksRecurringTaskSortInput,
  SortEnumType,
  useCancelRemainingReccuringTasksMutation,
  useDeleteRecurringPersonalTaskMutation,
  useGetRepeatingTasksSummaryQuery,
} from "graphql/generated/schema-types";
import produce from "immer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { contextMenu, Item, Menu } from "react-contexify";
import {
  Row,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { useInitializeConnectionData } from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { Alert, AlertType } from "shared-components/toast";
import { OverwriteRecurringTasks } from "./Dialogs/OverwriteRecurringTasks";
import UpdateRecurringTaskInfo from "./Dialogs/UpdateRecurringTaskInfo";
import {
  getFilterHandler,
  getPageChangeHandler,
  getSortHandler,
} from "./handlers";
import { RepeatingTasksSummaryColumns } from "./RepeatingTaskSummaryColumns";

export const DefaultPersonalTaskSummarySort: PersonalTasksRecurringTaskSortInput[] =
  [
    {
      recurringTaskId: SortEnumType.Desc,
    },
  ];

export default function RepeatingTaskSummary() {
  const [cancelRemainingRecurringTasks, { error }] =
    useCancelRemainingReccuringTasksMutation({});

  const [deleteRecurringPersonalTask] =
    useDeleteRecurringPersonalTaskMutation();

  const paginationTuple = useState<ReactTablePaginationState>({
    totalCount: 0,
    rowsPerPage: 25,
    page: 0,
  });
  const [paginationState, setPaginationState] = paginationTuple;

  const { data, refetch, loading, fetchMore, variables } =
    useGetRepeatingTasksSummaryQuery({
      variables: {
        order_by: DefaultPersonalTaskSummarySort,
      },
      notifyOnNetworkStatusChange: true,
    });

  const pageData = useInitializeConnectionData<PersonalTasksRecurringTask>(
    data?.repeatingTasksSummary,
    paginationState
  );

  const totalCount = useMemo(
    () =>
      GetTotalCountFromConnectionType<PersonalTasksRecurringTask>(
        data?.repeatingTasksSummary
      ),
    [data?.repeatingTasksSummary]
  );

  const pageInfo = useMemo(
    () =>
      GetPageInfoFromConnectionType<PersonalTasksRecurringTask>(
        data?.repeatingTasksSummary
      ),
    [data?.repeatingTasksSummary]
  );

  const handlePageCountChange = useCallback(
    (newPageCount: number) => {
      refetch({
        ...variables,
        pageSize: newPageCount,
      })
        .then(() =>
          setPaginationState({
            ...paginationState,
            rowsPerPage: newPageCount,
            page: 0,
          })
        )
        .catch((error) => console.log(error));
    },
    [paginationState, refetch]
  );

  const handleContextMenu =
    (
      selected: PersonalTasksRecurringTask[],
      row?: Row<PersonalTasksRecurringTask>
    ) =>
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      row?.toggleRowSelected(true);
      console.log(row);
      setSelectedTask(row?.original);
      contextMenu.show({
        id: "repeatingtask-context-menu",
        event: e,
        props: {
          selected: selected.includes(row!.original)
            ? selected
            : [...selected, row?.original],
        },
      });
      e.preventDefault();
    };

  const openUpdateRecurringTaskDialog = () => {
    setUpdateRecurringTaskOpen(true);
  };

  const cancelRecurringTasks = () => {
    cancelRemainingRecurringTasks({
      variables: {
        recurringTaskId: selectedTask!.recurringTaskId,
      },
    }).then((res) => {
      console.log("success");
    });
  };

  const deleteReccurringTasks = () => {
    deleteRecurringPersonalTask({
      variables: {
        recurringTaskId: selectedTask!.recurringTaskId,
      },
    }).then((res) => {
      Alert({
        type: AlertType.SUCCESS,
        message: "Deleted Remaining Recurring Tasks",
      });
    });
  };

  const [selectedTask, setSelectedTask] =
    useState<PersonalTasksRecurringTask>();
  const [updateRecurringTaskOpen, setUpdateRecurringTaskOpen] = useState(false);
  const [overwriteRecurringTasksOpen, setOverwriteRecurringTasksOpen] =
    useState(false);
  const handleRowClick = (row: any) => {
    console.log(row);
    setSelectedTask(row.original);
    setUpdateRecurringTaskOpen(true);
  };

  const onPageChange = useMemo(
    () =>
      getPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [
      fetchMore,
      pageInfo?.endCursor,
      paginationState,
      setPaginationState,
      variables,
    ]
  );

  const sortTable = useMemo(
    () => getSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const filterTable = useMemo(
    () => getFilterHandler(variables, refetch),
    [refetch, variables]
  );

  const repeatingTaskSummaryTableInstance = useTable(
    {
      columns: RepeatingTasksSummaryColumns,
      data: pageData,
      manualFilters: true,
      manualSortBy: true,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  useEffect(() => {
    if (paginationState.page !== 0)
      setPaginationState(
        produce(paginationState, (draft) => {
          draft.page = 0;
        })
      );
  }, [paginationState, setPaginationState]);

  useEffect(() => {
    const _paginationState = produce(paginationState, (draft) => {
      draft.totalCount = totalCount ?? 0;
    });
    setPaginationState(_paginationState);
  }, [paginationState, setPaginationState, totalCount]);

  return (
    <React.Fragment>
      <ReactTable
        tableInstance={repeatingTaskSummaryTableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        loading={loading}
      />
      <UpdateRecurringTaskInfo
        isOpen={updateRecurringTaskOpen}
        handleClose={() => setUpdateRecurringTaskOpen(false)}
        selectedTask={selectedTask}
      />
      <OverwriteRecurringTasks
        isOpen={overwriteRecurringTasksOpen}
        handleClose={() => setOverwriteRecurringTasksOpen(false)}
        selectedTask={selectedTask}
      />
      <Menu id={"repeatingtask-context-menu"} style={{ zIndex: 10000 }}>
        <Item onClick={() => openUpdateRecurringTaskDialog()}>
          Update Recurring Task Info
        </Item>
        <Item onClick={() => setOverwriteRecurringTasksOpen(true)}>
          Overwrite Recurring Tasks
        </Item>
        <Item onClick={() => cancelRecurringTasks()}>
          Cancel Remaining Recurring Tasks
        </Item>
        <Item onClick={() => deleteReccurringTasks()}>
          Delete Remaining Recurring Tasks
        </Item>
      </Menu>
    </React.Fragment>
  );
}
