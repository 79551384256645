import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MethodSettingsProps } from "../shared";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export const RefeedWellsReagent = ({
  poolingInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const classes = useStyles();

  const hasError =
    poolingInternalState.methodSettings.reFeedSourceWells &&
    poolingInternalState.methodSettings.reFeedWellsRGT === 0;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        sx={{ width: "100%" }}
        classes={{
          label: classes.fullWidth,
        }}
        control={
          <Checkbox
            checked={poolingInternalState.methodSettings.reFeedSourceWells}
            onChange={(e) =>
              handleMethodSettingsChanged("reFeedSourceWells", e.target.checked)
            }
            name="checkedB"
            color="primary"
            size="small"
          />
        }
        label={
          <FormControl variant="outlined" fullWidth error={hasError}>
            <InputLabel id="refeed-wells-label">
              ReFeed Wells Reagent
            </InputLabel>
            <Select
              labelId="refeed-wells-label"
              id="refeed-wells-select"
              disabled={!poolingInternalState.methodSettings.reFeedSourceWells}
              value={poolingInternalState.methodSettings.reFeedWellsRGT}
              label="ReFeed Wells Reagent"
              size="small"
              onChange={(e) =>
                handleMethodSettingsChanged("reFeedWellsRGT", e.target.value)
              }
            >
              <MenuItem value={1}>Media</MenuItem>
              <MenuItem value={2}>DissociationRGT</MenuItem>
              <MenuItem value={3}>PBS</MenuItem>
            </Select>
            <FormHelperText>
              {hasError ? "Reagent must be selected" : ""}
            </FormHelperText>
          </FormControl>
        }
      />

      <Tooltip title="Re Feed Source Wells - source wells will be refed with selected reagent up to operating volume of labware">
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
