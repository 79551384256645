import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { useState } from "react";
import {
  GetTicketInfoQuery,
  UserFragment,
} from "graphql/generated/schema-types";
import { UpdateChatWindow } from "./sub-components/UpdateChatWindow";

export function TicketUpdates({
  ticketsQuery,
  user,
}: {
  ticketsQuery: Maybe<GetTicketInfoQuery>;
  user: Maybe<UserFragment>;
}) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      aria-label="show more"
      aria-expanded={expanded}
      TransitionProps={{ timeout: 150, unmountOnExit: true }}
      onChange={() => setExpanded((current) => !current)}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>Updates</AccordionSummary>
      <AccordionDetails>
        <UpdateChatWindow
          ticket={ticketsQuery?.ticketInfo}
          originalPersonUserId={user?.userId ?? 0}
        />
      </AccordionDetails>
    </Accordion>
  );
}
