import { FormikErrors, FormikProps, FormikTouched } from "formik";
import {
  RunTaskInput,
  RunTaskDetail,
  RunTaskDetailInput,
  RunTemplateTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export const RunActivityDetailFormikTypeParser = (
  props:
    | FormikProps<RunTaskInput>
    | FormikProps<RunTemplateTaskInput>
    | FormikProps<RunTaskDetailInput>
) => {
  const { values, errors, touched } = props;
  const isDetailInputProp = "parentId" in values;
  const detailInputValue = values as RunTaskDetailInput;
  const detailInputTouched = touched as FormikTouched<RunTaskDetailInput>;
  const detailInputError = errors as FormikErrors<RunTaskDetailInput>;

  const assertedErrors = isDetailInputProp
    ? detailInputError
    : ((errors as FormikErrors<RunTaskInput>)
        .details as FormikErrors<RunTaskDetail>);
  const assertedTouched = isDetailInputProp
    ? detailInputTouched
    : ((touched as FormikTouched<RunTaskInput>)
        .details as FormikTouched<RunTaskDetail>);
  const setFieldValuePrefix = isDetailInputProp ? "" : "details.";

  const assertedValues: Maybe<RunTaskDetailInput> = isDetailInputProp
    ? detailInputValue
    : (values as RunTaskInput | RunTemplateTaskInput).details;
  return {
    assertedValues,
    assertedTouched,
    assertedErrors,
    setFieldValuePrefix,
  };
};
