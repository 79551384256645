import { MethodFragment } from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";
import { MethodManagerActions } from "../../../state/actions";
import { useMethodManagerState } from "../../../state/hooks";
import { MethodTableContextMenuHidden } from "./MethodTable";

export const handleContextMenu =
  (selected: MethodFragment[], row?: Row<MethodFragment>) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    const _selected = selected.includes(row!.original)
      ? selected
      : [...selected, row?.original];
    contextMenu.show({
      id: "MethodTableContextMenu",
      event: e,
      props: {
        selected: _selected.map((s) => s?.methodId),
      },
    });
    MethodTableContextMenuHidden(false);
  };

export const useHandleRowClick = (): ((
  rowClicked?: Row<MethodFragment>
) => (e: React.MouseEvent) => void) => {
  const { methodManagerDispatch } = useMethodManagerState();
  return useMemo(
    () => (rowClicked?: Row<MethodFragment>) => () => {
      if (!rowClicked?.isGrouped && MethodTableContextMenuHidden()) {
        console.log("Method Tables Action: ROW_CLICKED");
        methodManagerDispatch({
          type: MethodManagerActions.OPEN_METHOD_DETAILS,
          payload: {
            methodSummaryDialogOpen: {
              methodId: rowClicked?.original.methodId,
            },
          },
        });
      }
    },
    [methodManagerDispatch]
  );
};
