import { IconButton, styled as mStyled, ToggleButtonGroup } from "@mui/material";
import styled from "styled-components";

export const StyledToggleButtonGroup = mStyled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(0.5),
      border: 0,
      "&.Mui-disabled": {
        border: 0,
      },
      "&:not(:first-of-type)": {
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        borderRadius: theme.shape.borderRadius,
      },
    },
  })
);

export const StyledToolbarSection = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;

export const StyledIconButton = styled(IconButton)`
  margin: 2px;
`;
