import { gql } from "@apollo/client/core";

export const ImagingWorklist = gql`
  fragment ImagingWorklist on WorklistContentImaging {
    barcode
    platePitch
    experimentSetting
    analysisSetting
    imageExportFormat
    imageExportSetting
  }
`;
