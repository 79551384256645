import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  AppSuiteGroup,
  AppSuiteUser,
  PersonalTasksRecurringTask,
  useGetGroupsQuery,
  useGetUsersQuery,
  useOverwriteRecurringTasksMutation,
} from "graphql/generated/schema-types";
import { ChangeEvent, useState } from "react";
import { Alert, AlertType } from "shared-components/toast";

interface OverwriteRecurringTasksProps {
  selectedTask: PersonalTasksRecurringTask | undefined;
  isOpen: boolean;
  handleClose: () => void;
}

export const OverwriteRecurringTasks = ({
  isOpen,
  handleClose,
  selectedTask,
}: OverwriteRecurringTasksProps) => {
  const usersQuery = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  const groupsQuery = useGetGroupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
  const [overwriteRecurringTasks] = useOverwriteRecurringTasksMutation({});
  const [selectedUsers, setSelectedUsers] = useState<AppSuiteUser[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<AppSuiteGroup[]>([]);

  const handleSubmit = () => {
    console.log(selectedUsers);
    console.log(selectedGroups);
    const userIds = selectedUsers.map((user) => {
      return user!.userId!;
    });

    const groupIds = selectedGroups.map((group) => {
      return group!.groupId!;
    });
    const input = {
      recurringTaskId: selectedTask!.recurringTaskId!,
      selectedUsers: userIds,
      selectedGroups: groupIds,
    };

    overwriteRecurringTasks({
      variables: {
        input: input,
      },
    }).then((data) => {
      handleClose();
      Alert({
        type: AlertType.SUCCESS,
        message: "Tasks Saved",
      });
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Overwite {selectedTask?.taskName}</DialogTitle>
      <DialogContent>
        <Autocomplete
          style={{ margin: "20px 0" }}
          multiple
          id="tags-outlined"
          options={usersQuery.data?.appSuiteUsers?.map((node) => node) ?? []}
          getOptionLabel={(option) => option!.userName!}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Assigned User(s)"
              variant="outlined"
            />
          )}
          onChange={(event: ChangeEvent<unknown>, newValue: any) =>
            setSelectedUsers(newValue)
          }
          value={selectedUsers}
        />
        <Autocomplete
          multiple
          id="tags-outlined"
          options={groupsQuery.data?.appSuiteGroups?.map((node) => node) ?? []}
          getOptionLabel={(option) => option!.groupName!}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Assigned Group(s)"
              variant="outlined"
            />
          )}
          onChange={(event: ChangeEvent<unknown>, newValue: any) =>
            setSelectedGroups(newValue)
          }
          value={selectedGroups}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
