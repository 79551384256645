import { ApolloQueryResult } from "@apollo/client";
import {
  GetMyPersonalTasksQuery,
  GetMyPersonalTasksQueryVariables,
  ManagePersonalTask,
  SortEnumType,
} from "graphql/generated/schema-types";
import { MutateState } from "helpers/object-helpers";
import { FetchMore } from "interfaces/FetchMore";
import React, { Dispatch, SetStateAction } from "react";
import { Filters, SortingRule } from "react-table";
import { ReactTablePaginationState } from "shared-components/Table/ReactTable";
import { PersonalTaskFilterReducer } from "./filter";

export const getHandlePageCountChange =
  (
    variables: GetMyPersonalTasksQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetMyPersonalTasksQueryVariables>
        ) => Promise<ApolloQueryResult<GetMyPersonalTasksQuery>>)
      | undefined,
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ): ((newPageCount: number) => void) =>
  (newPageCount: number) => {
    if (refetch)
      refetch({
        ...variables,
        pageSize: newPageCount,
      })
        .then(() =>
          setPaginationState({
            ...paginationState,
            rowsPerPage: newPageCount,
            page: 0,
          })
        )
        .catch((error) => console.log(error));
  };

export const getPageChangeHandler =
  (
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ],
    fetchMore?: FetchMore<
      GetMyPersonalTasksQueryVariables,
      GetMyPersonalTasksQuery
    >,
    endCursor?: string | null,
    variables?: GetMyPersonalTasksQueryVariables
  ): ((
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void) =>
  (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (paginationState.page < newPage && fetchMore) {
      fetchMore({
        variables: {
          ...variables,
          after: endCursor,
        },
      })
        .then(() => setPaginationState({ ...paginationState, page: newPage }))
        .catch((err) => console.log(err));
    } else if (paginationState.page > newPage) {
      setPaginationState({ ...paginationState, page: newPage });
    }
  };

export const getFilterHandler =
  (
    variables: GetMyPersonalTasksQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetMyPersonalTasksQueryVariables>
        ) => Promise<ApolloQueryResult<GetMyPersonalTasksQuery>>)
      | undefined
  ) =>
  async (filters: Filters<ManagePersonalTask>): Promise<void> => {
    const _state = MutateState(variables, (draft) => {
      draft!.where = PersonalTaskFilterReducer(filters);
    });
    console.log(_state);
    if (refetch && _state.hasChanged) {
      await refetch(_state.newValue);
    }
  };

export const getSortHandler =
  (
    variables: GetMyPersonalTasksQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetMyPersonalTasksQueryVariables>
        ) => Promise<ApolloQueryResult<GetMyPersonalTasksQuery>>)
      | undefined,
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ) =>
  async (sorts: SortingRule<ManagePersonalTask>[]): Promise<void> => {
    if (sorts.length > 0 && refetch) {
      await refetch({
        ...variables,
        order_by: sorts.map((sortRule) => ({
          [sortRule.id]: sortRule.desc ? SortEnumType.Desc : SortEnumType.Asc,
        })),
      });
    } else if (refetch) {
      const _state = MutateState(variables, (draft) => {
        draft!.order_by = undefined;
      });
      if (_state.hasChanged) await refetch(_state.newValue);
    }
    if (paginationState.page !== 0)
      setPaginationState({ ...paginationState, page: 0 });
  };
