import { PageWithNav } from "features/Navbar/PageWithNav";
import React from "react";
import styled from "styled-components/macro";
import { MethodManagerModalContainer } from "../components/ModelsAndDialogs/MethodManagerModalContainer";
import { MethodManagerModalContent } from "../components/ModelsAndDialogs/MethodManagerModalContent";
import { Screens } from "../components/Screens";
import { SideBar } from "../components/SideBar";

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
export function MethodManager(): JSX.Element {
  return (
    <PageWithNav>
      <MethodManagerModalContainer>
        <MethodManagerModalContent />
      </MethodManagerModalContainer>
      <Layout>
        <SideBar />
        <Screens />
      </Layout>
    </PageWithNav>
  );
}
