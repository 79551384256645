import { Checkbox, FormControlLabel } from "@mui/material";
import { AddRackOptions } from "../../constants";

interface IncludeInactiveWellsCheckboxProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const IncludeInactiveWellsCheckbox = ({
  values,
  setFieldValue,
}: IncludeInactiveWellsCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={values.includeInactiveWells}
          onChange={(e) =>
            setFieldValue("includeInactiveWells", e.target.checked)
          }
          name="checkedB"
          color="primary"
          size="small"
        />
      }
      label="Include Inactive Wells"
    />
  );
};
