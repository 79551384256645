import jwtDecode from "jwt-decode";
export interface GoogleTokenClaims {
  iss: string;
  azp: string;
  aud: string;
  sub: string;
  hd: string;
  email: string;
  email_verified: boolean;
  at_hash: string;
  name: string;
  picture: string;
  given_name: string;
  family_name: string;
  locale: string;
  iat: number;
  exp: number;
  jti: string;
}
export const decodeJwt = (jwt: string): GoogleTokenClaims => {
  return jwtDecode(jwt);
};
