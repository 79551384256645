import { useReactiveVar } from "@apollo/client";
import { CeligoScanForm } from "features/Runs/components/Forms/MethodDetails/CeligoScan/CeligoScanForm";
import { useGetRunActivityMethodReservationHandleSubmit } from "features/Runs/components/Forms/MethodDetails/CeligoScan/handleSubmit";
import { PlateGroupAccordionContainer } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/PlateGroupAccordionContainer";
import {
  CeligoMethodAlreadyReserved,
  CeligoPlateMetadata,
} from "features/WorklistTools/CeligoScan/Dialog/state";
import { MethodReservation, RunTask } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import React, { useEffect, useMemo } from "react";

export function CeligoScan({
  runActivity,
}: {
  runActivity: Maybe<RunTask>;
}): JSX.Element {
  const handleSubmit = useGetRunActivityMethodReservationHandleSubmit(
    runActivity?.methodReservations?.[0] as Maybe<MethodReservation>
  );
  const activePlateMetadata = useReactiveVar(CeligoPlateMetadata);

  const input = RunActivityInputMapper(runActivity as RunTask);

  const methodReservationId = useMemo(
    () => runActivity?.methodReservations?.[0]?.methodReservationId,
    [runActivity?.methodReservations]
  );

  useEffect(() => {
    CeligoMethodAlreadyReserved(!!methodReservationId);
  }, [methodReservationId]);

  return (
    <>
      <PlateGroupAccordionContainer
        methodReservation={runActivity?.methodReservations?.[0]}
        platesQueryFetchPolicy={"cache-and-network"}
      />
      <CeligoScanForm
        initialValue={input}
        handleSubmit={handleSubmit}
        isReserved={!!runActivity?.methodReservations?.[0]?.methodReservationId}
        hasActivePlates={activePlateMetadata.length > 0}
      />
    </>
  );
}
