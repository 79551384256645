import { IAction, IState } from "state-provider/initial-state";
import { Action } from "./actions";

export function Reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case Action.AUTHENTICATE:
      return {
        ...state,
        loggingIn: false,
        loggedIn: action.payload?.loggedIn,
      };
    case Action.ATTEMPTING_GOOGLE_AUTH:
      return {
        ...state,
        loggingIn: true,
      };
    default:
      return state;
  }
}
