import { Nav } from "features/Navbar/Nav";
import { Wrapper } from "interfaces/Wrapper";
import React, { ReactElement } from "react";
import { StyledPageLayout } from "shared-components/styled-components";

export function PageWithNav({
  children,
  appBarContent,
  disableScroll,
}: Wrapper & {
  appBarContent?: ReactElement;
  disableScroll?: true | never;
}): JSX.Element {
  return (
    <StyledPageLayout disableScroll={disableScroll}>
      <Nav>{appBarContent}</Nav>
      {children}
    </StyledPageLayout>
  );
}
