import { TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { GenericFieldProps } from "./FeedGroupFormRow";

export interface FeedGroupNameProps extends GenericFieldProps {
  handleChange: (e: ChangeEvent<any>) => void;
  value: string | undefined | null;
}

export const FeedGroupName = (props: FeedGroupNameProps) => {
  return (
    <TextField
      autoComplete="off"
      disabled={props.toggleDisabled}
      error={!!props.formikErrors}
      helperText={props.formikErrors}
      label="Feed Group"
      name={`feedGroupArray[${props.index}].feedGroupName`}
      onBlur={props.handleBlur}
      onChange={props.handleChange}
      value={props.value}
      variant="outlined"
    />
  );
};
