import React from "react";
import { AddRackOptions } from "../../constants";
import { DateSelector } from "../selectors/DateSelector";
import { FibroblastRunSelector } from "../selectors/FibroblastRunSelector";
import { PassageNumberSelector } from "../selectors/PassageNumberSelector";
import { RunTaskSelector } from "../selectors/RunTaskSelector";

interface FibroblastProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const Fibroblast = ({ values, setFieldValue }: FibroblastProps) => {
  return (
    <React.Fragment>
      <FibroblastRunSelector values={values} setFieldValue={setFieldValue} />
      {values.run && values.step != "Refreeze" ? (
        <RunTaskSelector values={values} setFieldValue={setFieldValue} />
      ) : null}
      <PassageNumberSelector values={values} setFieldValue={setFieldValue} />
      <DateSelector values={values} setFieldValue={setFieldValue} />
    </React.Fragment>
  );
};
