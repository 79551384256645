import { gql } from "@apollo/client";
import { TypeNames } from "enums/TypeNames";
import {
  IncomingSamplesCollection,
  IncomingSamplesTask,
} from "graphql/fragments/incoming-sample.fragments";

export const IncomingSampleQueries = {
  remote: {
    GET_INCOMING_SAMPLE_TASKS: gql`
      query GetIncomingSampleTasks(
        $where: CollectionTaskFilterInput
        $order: [CollectionTaskSortInput!]
        $after: String
        $pageSize: Int
      ) {
        incomingSampleTasks(
          where: $where
          order: $order
          first: $pageSize
          after: $after
        ) {
          edges {
            node {
              ...IncomingSampleTask
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
    `,
    GET_INCOMING_SAMPLE_COLLECTIONS: gql`
      query GetIncomingSampleCollections(
        $where: CollectionFilterInput
        $order: [CollectionSortInput!]
        $after: String
        $pageSize: Int
      ) {
        incomingSampleCollections(
          where: $where
          order: $order
          first: $pageSize
          after: $after
        ) {
          edges {
            node {
              ...IncomingSampleCollection
            }
          }
          totalCount
          pageInfo {
            hasNextPage
            hasPreviousPage
            endCursor
            startCursor
          }
        }
      }
    `,
    GET_SOURCE_SAMPLE_TYPES: gql`
      query GetSourceSampleTypes {
        sampleTypes(where: { isSourceSampleType: { eq: true } }) {
          sampleTypeId
          sampleTypeString
        }
      }
    `,
    GET_PROJECTS: gql`
      query GetProjects {
        projects(where: { isActive: { eq: true } }) {
          projectId
          nyscfprefix
          projectName
        }
      }
    `,
  },
  local: {
    GET_INCOMING_SAMPLE_COLLECTION_BY_ID: gql`
    query GetIncomingSampleCollectionById($collectionId:Int!){
      incomingSamplesCollection(id: $collectionId, typeName: "${TypeNames.Collection}", keyField: "collectionId") @client{
        ...IncomingSampleCollection
      }
      }
    ${IncomingSamplesCollection}
    `,
    GET_INCOMING_SAMPLE_TASK_BY_ID: gql`
      query GetIncomingSampleTaskById($taskId:Int!){
        incomingSamplesTask(id: $taskId, typeName: "${TypeNames.CollectionTask}", keyField: "incomingSampleTaskId") @client{
          ...IncomingSampleTask
        }
      }
      ${IncomingSamplesTask}
    `,
    GET_INCOMING_SAMPLE_TASKS_BY_ID: gql`
      query GetIncomingSampleTasksById($taskIds:[Int!]!){
        incomingSamplesTasksFromCache(ids: $taskIds, typeName: "${TypeNames.CollectionTask}", keyField: "incomingSampleTaskId") @client{
          ...IncomingSampleTask
        }
      }
      ${IncomingSamplesTask}
    `,
  },
};
