import { TextField } from "@mui/material";
import { AddRackOptions } from "../../constants";

interface PassageNumberSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const PassageNumberSelector = ({
  values,
  setFieldValue,
}: PassageNumberSelectorProps) => {
  return (
    <TextField
      style={{ width: "100%" }}
      label="Increment Passage Number By"
      type="number"
      value={values.incrementPassageNumber}
      onChange={(e) =>
        setFieldValue("incrementPassageNumber", parseInt(e.target.value))
      }
    />
  );
};
