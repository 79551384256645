import React from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { useUpdateMethodMutation } from "graphql/generated/schema-types";
import { TicketTableContextMenuHidden } from "../../Tickets/components/Table/TicketTable";
import { MethodManagerActions } from "../state/actions";
import { useMethodManagerState } from "../state/hooks";
import { MethodManagerModals } from "./ModelsAndDialogs/model.enum";

export function MethodTableContextMenu(): JSX.Element {
  const {
    methodManagerDispatch,
    methodManagerState: { selectedMethods },
  } = useMethodManagerState();

  const [updateMethods] = useUpdateMethodMutation();

  return (
    <Menu
      id="MethodTableContextMenu"
      onHidden={() => {
        setTimeout(() => TicketTableContextMenuHidden(true), 200);
      }}
    >
      <Submenu label={"Mark As"}>
        <Item
          onClick={async () => {
            await updateMethods({
              variables: {
                inputs: selectedMethods?.map((id) => ({
                  primaryKey: id,
                  set: { isActive: true },
                })),
              },
            });
          }}
        >
          Active
        </Item>
        <Item
          onClick={async () => {
            await updateMethods({
              variables: {
                inputs: selectedMethods?.map((id) => ({
                  primaryKey: id,
                  set: { isActive: false },
                })),
              },
            });
          }}
        >
          Inactive
        </Item>
      </Submenu>
      {selectedMethods?.length === 1 && (
        <Item
          onClick={() => {
            methodManagerDispatch({
              type: MethodManagerActions.OPEN_MODAL,
              payload: {
                currentModal: MethodManagerModals.EditMethod,
              },
            });
          }}
        >
          Edit
        </Item>
      )}
    </Menu>
  );
}
