import { EventInput, FormatterInput } from "@fullcalendar/common";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import { EventApi, EventContentArg, EventDropArg } from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Tooltip } from "@mui/material";
import { FeedMethods } from "constants/feedMethods";
import moment from "moment";
import styled from "styled-components";

export const StyledDiv = styled.div`
  margin-top: 10px;
  height: 100%;
  margin-bottom: 20px;
  .fc {
    margin-bottom: 0px;
  }
  .fc-toolbar {
    margin-bottom: 0px;
  }
  .fc-event-main {
    color: inherit;
  }
  .fc-scrollgrid-section-body {
  }
  .fc-daygrid-event-harness {
    overflow: hidden;
  }
  .fc-timegrid-event {
    border-style: none;
  }
  .fc-event-title {
    overflow: hidden;
  }
  .fc-event-main-frame {
    display: inline-block;
    overflow: hidden;
    // color: black;
    min-height: 30px;
  }
  .fc-daygrid-block-event {
  }
  .fc-daygrid-event {
    color: red;
  }
`;

export function eventContent(eventContentArg: EventContentArg): JSX.Element {
  const event = eventContentArg.event;

  const getInnerText = (event: EventApi) => {
    const systemName = event.extendedProps.systemName.replace("System", "Sys");
    const showTime = event.start!.getMinutes() % 30 !== 0;
    const showSystemName = eventContentArg.view.type.startsWith("timeGrid");
    return (
      <div>
        {showTime ? eventContentArg.timeText + " " : ""}
        {showSystemName ? <b>[{systemName}]</b> : ""}
        {" " + event.title}
      </div>
    );
  };

  const getTitle = (event: EventApi) => {
    const getTime = (event: EventApi) => {
      const parseDate = (d: Date | null) =>
        d?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }) ?? "";
      return `> ${parseDate(event.start)} - ${parseDate(event.end)}`;
    };
    const plural = (strings: string[]) => (strings.length > 1 ? "s" : "");

    const getRunOrPersonalTask = (ep: Record<string, string[]>) => {
      const users: string[] = ep.users;
      const groups: string[] = ep.groups;
      const barcodes: string[] = ep.barcodes;
      return (
        <div>
          <div>
            {users.length ? `User${plural(users)}: ${users.join(", ")}` : ""}
          </div>
          <div>
            {groups.length
              ? `Group${plural(groups)}: ${groups.join(", ")}`
              : ""}
          </div>
          {barcodes.length ? (
            <div>
              Barcode{plural(barcodes)}:
              {barcodes.length > 1 ? ` [${barcodes.length}]` : ""}
              <br />
              {barcodes.length > 10
                ? barcodes.slice(0, 10).join(", ") +
                  ` & ${barcodes.length - 10} more`
                : barcodes.join(", ")}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    };

    const ep = event.extendedProps;
    return (
      <div color={"white"}>
        {event.title}
        <br />
        {event.extendedProps.altTitle}
        <br />
        {getTime(event)}
        <br />
        {ep.lastTimeUpdated ? (
          <>
            Last Updated: {moment(ep.lastTimeUpdated).calendar()} by{" "}
            {ep.updatedByName ?? "?"}
          </>
        ) : null}
        <br /> <br />
        <div>Task: {ep.taskType}</div>
        <div>Method: {ep.methodName}</div>
        <div>Status: {ep.status}</div>
        <div>Priority: {ep.priority}</div>
        {
          true ? getRunOrPersonalTask(ep) : "" // automation
          // return eventContentArg.event.classNames[0] === "automation" ? (
          //     <p>Status: {eventContentArg.event.classNames[1]}</p>
          //     <p>Method Code: {eventContentArg.event.classNames[2]}</p>
          //     <p>System: {eventContentArg.event.classNames[3]}</p>
          //     <p>Cluster: {eventContentArg.event.classNames[4]}</p>
          //     <p>Instruments: {eventContentArg.event.classNames[5]}</p>
          // ) : (
          // );
        }
      </div>
    );
  };

  const userName = sessionStorage.getItem("userName") ?? "";
  const includesUserName = event.extendedProps.users.includes(userName);
  const innerText = getInnerText(event);

  return (
    <Tooltip title={getTitle(event)} arrow placement="left">
      {includesUserName ? <u>{innerText}</u> : <div>{innerText}</div>}
    </Tooltip>
  );
}

export const resources = [
  { id: "1", title: "System 1", cluster: "1: Whiskey" },
  { id: "2", title: "System 2", cluster: "1: Whiskey" },
  { id: "3", title: "System 3", cluster: "2: Tango" },
  { id: "4", title: "System 4", cluster: "2: Tango" },
  { id: "5", title: "System 5", cluster: "2: Tango" },
  { id: "6", title: "System 6", cluster: "3: Foxtrot" },
  { id: "7", title: "System 7", cluster: "3: Foxtrot" },
  { id: "8", title: "System 8", cluster: "3: Foxtrot" },
  { id: "9", title: "System 9", cluster: "5: Screening Room" },
  { id: "10", title: "System 10", cluster: "4: X-Ray" },
  { id: "11", title: "System 11", cluster: "5: Screening Room" },
  { id: "12", title: "System 12", cluster: "6: QC" },
  { id: "14", title: "Manual" },
  { id: "21", title: "OT-2 (A02 - OpenTrontamus Prime)", cluster: "6: QC" },
  { id: "22", title: "Lynx", cluster: "4: X-Ray" },
  { id: "23", title: "Aria" },
  { id: "24", title: "Wolf" },
  { id: "25", title: "OT-2 (A04 - Megatrons)", cluster: "6: QC" },
  { id: "26", title: "Bravo", cluster: "5: Screening Room" },
];

export const plugins = [
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
];

export const headerToolbar = {
  left: "title",
  right: "prev,today,next resourceTimelineDay,resourceTimelineWeek",
};

export const getButtonText = (calendarView: string) => {
  return {
    today: calendarView.endsWith("Week") ? "Current Week" : "Today",
    week: "Week",
    day: "Day",
  };
};

export const titleFormat: FormatterInput = {
  month: "long",
  year: "numeric",
  day: "numeric",
  weekday: "long",
};

export const getResizePrompt = (arg: EventResizeDoneArg) => {
  const getDuration = (e: EventApi) =>
    (e.start && e.end
      ? `${Math.abs(e.end?.getTime() - e.start?.getTime()) / 36e5}`
      : 0) + "hrs";
  return `'${arg.event.title}' duration (${getDuration(
    arg.oldEvent
  )} > ${getDuration(arg.event)})`;
};

export const getDropPrompt = (arg: EventDropArg) => {
  const start = arg.oldEvent.start!;
  const newStart = arg.event.start!;

  const isSameDay =
    start.getFullYear() === newStart.getFullYear() &&
    start.getMonth() === newStart.getMonth() &&
    start.getDate() === newStart.getDate();

  const getTime = (d: Date) =>
    isSameDay
      ? d.toLocaleTimeString([], { timeStyle: "short" })
      : d.toLocaleString([], {
          dateStyle: "short",
          timeStyle: "short",
        });

  const timeDifference =
    start.getTime() !== newStart.getTime()
      ? `start time (${getTime(start)} > ${getTime(newStart)})`
      : "";

  const didSystemChange =
    arg.oldResource?.title &&
    arg.newResource?.title &&
    arg.oldResource!.title !== arg.newResource!.title;

  const systemDifference = didSystemChange
    ? `system (${arg.oldResource?.title} > ${arg.newResource?.title})`
    : "";

  const changes = [timeDifference, systemDifference]
    .filter((s) => s.length)
    .join(", ");

  return `'${arg.event.title}' - ${changes}`;
};

export const getEventApiKey = (e: EventApi) =>
  `${e.id}-${e.extendedProps.taskType}`;

export const getEventInputKey = (e: EventInput) =>
  `${e.id}-${e.taskType ?? ""}`;

export const getSlotLabelFormat = (
  timeFormat: string,
  calendarView: string
): FormatterInput[] => {
  const timeLabel: FormatterInput =
    timeFormat === "military"
      ? {
          //military time
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }
      : {
          //standard time
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: true,
          meridiem: "short",
        };
  // In dayview show only time
  if (!calendarView.endsWith("Week")) return [timeLabel];

  const weekViewDateLabel: FormatterInput = {
    weekday: "long",
    month: "numeric",
    day: "numeric",
    omitCommas: true,
  } as FormatterInput;

  // In weekview show date and time
  return [weekViewDateLabel, timeLabel];
};

const isShortened = (arg: EventResizeDoneArg) => {
  const startTimeDif = arg.startDelta.milliseconds;
  const endTimeDif = arg.endDelta.milliseconds;
  return startTimeDif > 0 || endTimeDif < 0;
};

const isFeedTask = (methodId: number) => {
  return FeedMethods.includes(methodId);
};

export const isShortenedFeedTask = (arg: EventResizeDoneArg) => {
  return isShortened(arg) && isFeedTask(arg.event.extendedProps.methodId);
};
