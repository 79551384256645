import { Maybe } from "graphql/jsutils/Maybe";
import * as _ from "lodash";
import {
  ManualTaskTypeFragment,
  UpdateManualTaskInput,
} from "../graphql/generated/schema-types";
import { ManualTaskInputStub } from "./object-stubs/ManualTaskInput";

export function ManualTaskInputMapper(method: Maybe<ManualTaskTypeFragment>) {
  return _.pick(
    method,
    _.keys(new ManualTaskInputStub())
  ) as UpdateManualTaskInput;
}
