import { EventInput } from "@fullcalendar/common";
import { DatesSetArg } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import { AppTheme } from "App.theme";
import { TaskDetailsDialog } from "features/Dashboard/components/Dialogs/TaskDetailsDialog";
import { PageWithNav } from "features/Navbar/PageWithNav";
import {
  AppSuiteUser,
  CalendarNodeFragment,
  DashboardTask,
  TaskTypeEnum,
  useGetCalendarNodesQuery,
  useGetDashboardTaskLazyQuery,
} from "graphql/generated/schema-types";
import { useGetUser } from "hooks/cacheAccessHooks";
import { useGetUserSettings } from "hooks/useGetUserSettings";
import moment from "moment";
import { createRef, useMemo, useState } from "react";
import Loading from "shared-components/Loading";
import "styled-components";
import { Calendar as CalendarComponent } from "../components/Calendar";
import { Header } from "../components/Header";
import { CalendarStateAction } from "../state/actions";
import { useCalendarStateProvider } from "../state/StateProvider";
import { CalendarContainer, getEventObject } from "./Calendar.functions";

export interface TaskIdAndType {
  id: number;
  type: TaskTypeEnum;
}

function Calendar(): JSX.Element {
  const userId = parseInt(localStorage.getItem("userID") ?? "");
  const user = useGetUser(userId);

  const [runId, setRunId] = useState<number | undefined>();
  const [isTaskDetailsDialogOpen, setIsTaskDetailsDialogOpen] =
    useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  const [totalEdits, setTotalEdits] = useState<number>(0);

  const [bulkReschedule, setBulkReschedule] = useState(false);
  const [myEventsOnly, setMyEventsOnly] = useState(false);
  const [taskIdAndType, setTaskIdAndType] = useState<TaskIdAndType>();
  const [extendedToggleChecked, setExtendedToggleChecked] = useState(false);

  const { calendarState, calendarStateDispatch } = useCalendarStateProvider();

  const startDate = useMemo(() => {
    const day = calendarState.day.getDate() - calendarState.day.getDay();
    return new Date(
      calendarState.day.getFullYear(),
      calendarState.day.getMonth(),
      day
    );
  }, [calendarState]);
  const endDate = useMemo(
    () => moment(startDate).add(7, "days").toDate(),
    [startDate]
  );

  const { loading: loadingCalendarNodes, data: calendarNodesData } =
    useGetCalendarNodesQuery({
      variables: {
        startDate: startDate,
        endDate: endDate,
      },
      fetchPolicy: "cache-first",
      pollInterval: 30000,
    });
  const [getDashboardTask, { data: dashboardTaskData }] =
    useGetDashboardTaskLazyQuery();

  const currentUserSettings = useGetUserSettings();
  const currentTheme = currentUserSettings?.theme?.mode ?? "";
  const currentTaskTitleMode = currentUserSettings?.calendar?.taskTitles ?? "";

  const allEvents = useMemo(
    () =>
      calendarNodesData?.calendarNodes
        ?.filter((cnf) => cnf?.status !== "CANCELLED")
        .map((cnf) =>
          getEventObject(
            cnf as CalendarNodeFragment,
            user as AppSuiteUser,
            myEventsOnly,
            currentTheme,
            currentTaskTitleMode
          )
        ) ?? [],
    [calendarNodesData?.calendarNodes, user, myEventsOnly, currentTheme]
  );

  const selectedTask = useMemo(() => {
    if (!taskIdAndType) return null;

    getDashboardTask({
      variables: { taskID: taskIdAndType.id, taskType: taskIdAndType.type },
    });

    return (
      dashboardTaskData ? dashboardTaskData!.dashboardTask : null
    ) as DashboardTask;
  }, [getDashboardTask, dashboardTaskData, taskIdAndType]);

  const events: EventInput[] = useMemo(() => {
    const runEvents = allEvents.filter((e) => e.runId && e.runId === runId);

    const notUserAssigned = (e: EventInput) =>
      user && e.users && user.userName && !e.users!.includes(user!.userName);

    const notGroupAssigned = (e: EventInput) =>
      user &&
      e.groupId &&
      !user.groups?.map((g) => g?.groupId).includes(parseInt(e.groupId));

    return runEvents.length
      ? runEvents
      : allEvents.filter(
          (e) =>
            !(
              e.systemId === 14 && // 14 = Manual
              e.taskType === "PERSONAL" &&
              notUserAssigned(e) &&
              notGroupAssigned(e)
            ) // TODO: should admin override the not[User/Group]Assigned() check?
        );
  }, [allEvents, runId, user]);

  const getTaskDetailsDialog = () =>
    !(selectedTask && isTaskDetailsDialogOpen) ? (
      <></>
    ) : (
      <TaskDetailsDialog
        isOpen={isTaskDetailsDialogOpen}
        handleClose={() => setIsTaskDetailsDialogOpen(false)}
        selectedTask={selectedTask!}
      />
    );

  const getCalendarContainer = () => {
    const fullCalendarRef = createRef<FullCalendar>();
    const reset = () => {
      setTotalEdits(0);
      setBulkReschedule(false);
      setSaveClicked(false);
    };

    return (
      <CalendarContainer>
        <Header
          fullCalendarRef={fullCalendarRef}
          events={allEvents}
          date={calendarState.day}
          runId={runId}
          myEventsOnly={myEventsOnly}
          extendedToggleChecked={extendedToggleChecked}
          bulkReschedule={bulkReschedule}
          setRunId={setRunId}
          setMyEventsOnly={setMyEventsOnly}
          setExtendedToggleChecked={setExtendedToggleChecked}
          setBulkReschedule={setBulkReschedule}
          setSaveClicked={setSaveClicked}
          totalEdits={totalEdits}
        />
        <CalendarComponent
          fullCalendarRef={fullCalendarRef}
          events={events}
          date={calendarState.day}
          calendarState={calendarState}
          initialDate={calendarState.day}
          datesSet={(arg: DatesSetArg) => {
            calendarStateDispatch({
              type: CalendarStateAction.SET_STATE,
              payload: {
                day: arg.start,
                view: arg.view.type,
              },
            });
          }}
          eventClick={() => setIsTaskDetailsDialogOpen(true)}
          bulkReschedule={bulkReschedule}
          saveClicked={saveClicked}
          setTotalEdits={setTotalEdits}
          setTaskIdAndType={setTaskIdAndType}
          extendedToggleChecked={extendedToggleChecked}
          reset={reset}
          dashboardTaskId={dashboardTaskData?.dashboardTask?.taskId}
          loggedInUser={user}
        />
      </CalendarContainer>
    );
  };

  return (
    <PageWithNav>
      {loadingCalendarNodes ? (
        <Loading
          color={AppTheme.palette.secondary.main}
          name={"rotating-plane"}
        />
      ) : (
        <>
          {getCalendarContainer()}
          {getTaskDetailsDialog()}
        </>
      )}
    </PageWithNav>
  );
}

export default Calendar;
