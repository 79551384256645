import { RunDeleteConfirmDialog } from "features/Runs/components/ModalsAndDialogs/DeleteConfirmDialog";
import { RunsModalContainer } from "features/Runs/components/ModalsAndDialogs/ModelContainer";
import { RunsModalContent } from "features/Runs/components/ModalsAndDialogs/RunsModalContent";
import { RunActivityDialog } from "features/Runs/components/ModalsAndDialogs/TaskSummaryDialog";
import React from "react";

export function RunsModalsAndDialogs(): JSX.Element {
  return (
    <>
      <RunDeleteConfirmDialog />
      <RunActivityDialog />
      <RunsModalContainer>
        <RunsModalContent />
      </RunsModalContainer>
    </>
  );
}
