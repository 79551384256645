import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledTable,
  StyledTHDiv,
} from "features/Runs/components/Forms/Confirm/EditRunsConfirmModal";
import {
  RunTaskInput,
  RunTaskDetailInput,
} from "graphql/generated/schema-types";
import _ from "lodash";
import React from "react";

export function DetailsPreviewTable({
  runActivityInputs,
}: {
  runActivityInputs: RunTaskInput[];
}): JSX.Element {
  const keys: (keyof RunTaskDetailInput)[] = [
    "daughterWellNumber",
    "seedCellCount",
    "freezeCellCount",
    "freezeDownRackType",
    "createMystplate",
    "destPlateCode",
    "maxCryovial",
    "minCryovial",
    "overflowVials",
  ];
  return (
    <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
      <StyledTable size="medium">
        <TableHead>
          <TableRow>
            <TableCell>Task</TableCell>
            {keys.map((key, idx) => (
              <TableCell key={idx}>
                <StyledTHDiv>{_.startCase(key)}</StyledTHDiv>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {runActivityInputs.map((task, idx) => (
            <TableRow key={idx}>
              <TableCell align={"center"}>{task?.activityName}</TableCell>
              {keys.map((key) => (
                <TableCell key={key} align={"center"}>
                  {task?.details?.[key]?.toString()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
