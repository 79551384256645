import { Button } from "@mui/material";
import React, { FC, useEffect } from "react";
import { toast } from "react-toastify";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import styled from "styled-components/macro";

const ToastMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const useServiceWorkerWrapper = () => {
  const reloadPage = async () => {
    window.location.reload();
  };

  const ReloadPageToast: FC = () => (
    <ToastMessage>
      <div>Update available! Page will reload in 10 seconds</div>
      <div>
        <Button
          color={"secondary"}
          variant={"contained"}
          size={"small"}
          onClick={reloadPage}
        >
          Reload
        </Button>
      </div>
    </ToastMessage>
  );

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
    toast(() => <ReloadPageToast />, {
      onClose: reloadPage,
      autoClose: 10000,
      closeButton: false,
    });
  };

  useEffect(() => {
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
