import * as Yup from "yup";
import {
  UpdateManualTaskInput,
  UpdateMethodInput,
} from "graphql/generated/schema-types";

export const UpdateMethodValidationSchema =
  Yup.object().shape<UpdateMethodInput>({
    isActive: Yup.boolean().required("Required"),
    documentationUrl: Yup.string()
      .url("Must be valid url")
      .nullable()
      .notRequired(),
  });
export const UpdateManualTaskValidationSchema =
  Yup.object().shape<UpdateManualTaskInput>({
    documentationUrl: Yup.string()
      .url("Must be valid url")
      .nullable()
      .notRequired(),
  });
