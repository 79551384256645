import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { MethodSettingsProps } from "../shared";

export const ExtraPelletWash = ({
  poolingInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={poolingInternalState.methodSettings.extraPelletWash}
            onChange={(e) =>
              handleMethodSettingsChanged("extraPelletWash", e.target.checked)
            }
            name="checkedB"
            color="primary"
            size="small"
          />
        }
        label="Extra Pellet Wash"
      />
      <Tooltip
        title="[no additional resuspension] an additional wash of Int1
             pellet - 300µL of media added to Int1 pellet post centrifugation 
             and supernatant removal followed by centrifugation and supernatant removal steps"
      >
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
