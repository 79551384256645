import { ExpandedFolders } from "features/Runs/components/FolderTree/Tree";
import {
  useRunFolderQueryProvider,
  useRunsFolderTreeState,
} from "features/Runs/state/hooks";
import {
  DirectoryFolder,
  DirectoryFolderKeyInput,
  GetFolderDirectoryQuery,
  NodeType,
  useGetFolderDirectoryQuery,
  useRunsBackgroundLazyQuery,
} from "graphql/generated/schema-types";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetRun } from "../../hooks/cacheAccessHooks";
import {
  BuildBreadCrumbs,
  GetTreeNodeKey,
} from "./components/FolderTree/helpers";

export function useGetBreadcrumbs(): DirectoryFolder[] {
  const {
    runsFolderTreeState: { currentNode },
  } = useRunsFolderTreeState();
  const { data: directoryQuery } = useRunFolderQueryProvider();
  const [breadcrumbs, setBreadcrumbs] = useState<DirectoryFolder[]>([]);
  const run = useGetRun(
    currentNode?.type === NodeType.Run ? currentNode?.id : undefined
  );
  useEffect(() => {
    const crumbs: DirectoryFolder[] = [];
    if (currentNode)
      BuildBreadCrumbs(
        currentNode,
        (directoryQuery?.directory as DirectoryFolder[]) ?? [],
        crumbs
      );
    crumbs.reverse();
    const breadCrumbs: DirectoryFolder[] = [
      {
        id: -1,
        name: "F :",
        parentType: NodeType.Folder,
        type: NodeType.Folder,
        subFolders: [],
      },
      ...crumbs,
    ];

    setBreadcrumbs(breadCrumbs);
  }, [currentNode, directoryQuery, run]);

  useOpenFoldersProgrammatically(breadcrumbs);

  return breadcrumbs;
}
export const UseRunsBackgroundQuery = (): boolean => {
  const [execute, { error, loading }] = useRunsBackgroundLazyQuery();
  useEffect(() => {
    execute();
  }, [execute]);
  return loading && !!error;
};

const useOpenFoldersProgrammatically = (breadcrumbs: DirectoryFolder[]) => {
  const param = useParams<{ runId?: string }>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const breadcrumbFolderIds = breadcrumbs.map((crumb) =>
      GetTreeNodeKey(crumb)
    ) as string[];
    if (initialized) return;

    //Open specifc subfolder when url contains runid
    if (param?.runId) {
      ExpandedFolders(breadcrumbFolderIds);
      setInitialized(
        breadcrumbs.some(
          (crumb) =>
            crumb.id.toString() === param?.runId && crumb.type === NodeType.Run
        )
      );
      return;
    }

    //Open "All Runs" subfolders
    if (
      !param?.runId &&
      breadcrumbFolderIds.length === 2 // F: and All Runs
    ) {
      ExpandedFolders(breadcrumbFolderIds);
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param?.runId, breadcrumbs]);
};

type DirectoryFolderArray = NonNullable<GetFolderDirectoryQuery["directory"]>;

const DFS = (
  key: DirectoryFolderKeyInput,
  folderStructure: DirectoryFolderArray,
  ref: DirectoryFolder[]
) => {
  for (const dir of folderStructure) {
    if (GetTreeNodeKey(dir) === GetTreeNodeKey(key)) ref.push(dir!);
    if (!_.isEmpty(dir?.subFolders)) DFS(key, dir?.subFolders ?? [], ref);
  }
};

export const useGetDirectoryFolder = (key: DirectoryFolderKeyInput) => {
  const { data } = useGetFolderDirectoryQuery({ fetchPolicy: "cache-only" });
  const directory = useMemo(() => data?.directory ?? [], [data?.directory]);

  return useMemo(() => {
    const ref: DirectoryFolder[] = [];
    DFS(key, directory, ref);
    return ref[0];
  }, [directory, key]);
};
