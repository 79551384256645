import { makeVar } from "@apollo/client";
import { ThemeSettings } from "features/UserSettings/Settings";
import { Action } from "./actions";

export interface IState {
  loggedIn?: boolean;
  loggingIn?: boolean;
}
export interface IAction {
  type: Action;
  payload?: IState;
}

export const State = makeVar<IState>({
  loggedIn: false,
});
