import { WorklistValues } from "../shared/interfaces";
import { is96WellPlate } from "../shared/WorklistHelpers";
import { ThawToolActions } from "./state";

export const checkIfAllPlatesAre96Well = (destPlateInfo: any[]) => {
  const plateTypes: boolean[] = [];

  for (let i = 0; i < destPlateInfo.length; i++) {
    if (destPlateInfo[i].labwareTypeCode !== "") {
      plateTypes.push(is96WellPlate(destPlateInfo[i].labwareTypeCode));
    }
  }

  return plateTypes.every((v) => v === plateTypes[0]);
};

const addAllVolumesForPlate = (worklistValues: any[], plateIndex: number) => {
  let volume = 0;
  const values = worklistValues.filter((e: any) => e.plateIndex == plateIndex);

  if (values.length > 0) {
    for (const element of values) {
      volume += parseInt(element.stampVol);
    }
  }
  return volume;
};

export const addAllStampVolumes = (
  worklistValues: any[],
  newStampVol: number
) => {
  let volume = 0;
  const stampVols = worklistValues.map((item) => {
    return item.stampVol;
  });
  stampVols.push(newStampVol);
  if (stampVols.length > 0) {
    for (const element of stampVols) {
      volume += element;
    }
  }
  return volume;
};

export const getMaxStampVolForPlate = (
  worklistValues: any[],
  plateIndex: number
) => {
  const values = worklistValues.filter((e: any) => e.plateIndex == plateIndex);
  if (values.length > 0) {
    const maxStamp = values.reduce((p, c) => (p.stampVol > c.stampVol ? p : c));

    return maxStamp.stampVol;
  }
  return 0;
};

export const getMaxStampVol = (worklistValues: any[], newStampVol: number) => {
  const stampVols = worklistValues.map((item) => {
    return item.stampVol;
  });
  stampVols.push(newStampVol);
  if (stampVols.length > 0) {
    const maxStamp = stampVols.reduce((p, c) => (p > c ? p : c));

    return maxStamp;
  }
  return 0;
};

export const calculateTransferVolumes = (
  thawTransfer: any[],
  resuspensionVol: number,
  dispatch: (action: { type: string; payload: any }) => void
) => {
  const duplicates: any = {};
  for (let i = 0; i < thawTransfer.length; i++) {
    if (duplicates.hasOwnProperty(thawTransfer[i].sourceWellId)) {
      duplicates[thawTransfer[i].sourceWellId].push(i);
    } else if (thawTransfer.lastIndexOf(thawTransfer[i].sourceWellId) !== i) {
      duplicates[thawTransfer[i].sourceWellId] = [i];
    }
  }
  for (const key in duplicates) {
    if (duplicates.hasOwnProperty(key)) {
      if (duplicates[key].length > 1) {
        const vol = Math.floor(resuspensionVol / duplicates[key].length);
        for (let i = 0; i < duplicates[key].length; i++) {
          dispatch(
            ThawToolActions.UPDATE_TRANSFER_VOL({
              index: duplicates[key][i],
              vol,
            })
          );
        }
      }
    }
  }
};

export const plateContainsPartiallyFilledColumn = (wellCount: number) => {
  if (wellCount % 8 > 0) {
    return true;
  }
  return false;
};

export const buildThawMappingFile = (
  sourcePlateInfo: any,
  destPlateInfo: any[],
  deadPlateBarcode: string
) => {
  const worklist: any[] = [];
  const uniqueDestPlateTypes = [
    ...new Set(destPlateInfo.map((e: any) => e.labwareTypeCode)),
  ];
  if (sourcePlateInfo[0].plateBarcode != "") {
    // const pattern = /[^0-9]/g;
    // const srcPlateCode = sourcePlateInfo[0].plateBarcode
    //   .match(pattern)
    //   .join("");
    const srcPlateCode = sourcePlateInfo[0].plateBarcode.split(/[0-9]/)[0];
    const destPlateCode =
      uniqueDestPlateTypes.length > 2
        ? "Y"
        : destPlateInfo[0].plateBarcode.split("_")[1];
    const deadPlateCode = deadPlateBarcode.split("_")[1];

    worklist.push({
      task: "SrcToDeadMappingFileKeyword",
      sourcePlateBarcode: `${srcPlateCode}_to_${deadPlateCode}`,
    });
    worklist.push({
      task: "SrcToDestMappingFileKeyword",
      sourcePlateBarcode: `${srcPlateCode}_to_${destPlateCode}`,
    });
  }

  return worklist;
};

export const staggerWells = (wells: WorklistValues[]) => {
  const currentWell = wells.shift();
  let destPlateIndex = 0;
  const staggeredWells = [currentWell];
  let staggeredWellLookup = [currentWell!.sourceWellId];
  while (wells.length > 0) {
    if (wells.findIndex((x) => x.destPlateIndex == destPlateIndex) < 0) {
      destPlateIndex++;
    }
    const index = wells.findIndex(
      (e) =>
        !staggeredWellLookup.includes(e.sourceWellId) &&
        e.destPlateIndex == destPlateIndex
    );
    if (index > -1) {
      staggeredWellLookup.push(wells[index].sourceWellId);
      staggeredWells.push(wells[index]);
      wells.splice(index, 1);
    } else {
      const well = wells.shift();
      staggeredWellLookup = [well!.sourceWellId];
      staggeredWells.push(well);
    }
  }

  return staggeredWells;
};
