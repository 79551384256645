import { TextField } from "@mui/material";
import { FilterProps } from "react-table";

export function TextFilter<T extends Record<string, unknown>>({
  column: { filterValue, setFilter },
}: FilterProps<T>): JSX.Element {
  return (
    <TextField
      size={"small"}
      label={"Contains"}
      autoFocus={true}
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}
export function EqualsTextFilter<T extends Record<string, unknown>>({
  column: { filterValue, setFilter },
}: FilterProps<T>): JSX.Element {
  return (
    <TextField
      size={"small"}
      label={"Equals"}
      autoFocus={true}
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}
