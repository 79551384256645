import React from "react";
import { IncomingSampleScreensEnum, useIncomingSampleState } from "../../state";
import { CollectionTableScreen } from "./CollectionTableScreen";
import { ScheduleSamplesScreen } from "./ScheduleSamplesScreen/ScheduleSamplesScreen";
import { TaskTableScreen } from "./TaskTableScreen";
import IDFFormScreen from "./IDFFormScreen";

export function IncomingSampleScreens(): JSX.Element {
  const {
    incomingSampleState: { activeScreen },
  } = useIncomingSampleState();
  switch (activeScreen) {
    case IncomingSampleScreensEnum.Tasks:
      return <TaskTableScreen />;
    case IncomingSampleScreensEnum.Collections:
      return <CollectionTableScreen />;
    case IncomingSampleScreensEnum.CreateCollection:
      return <ScheduleSamplesScreen />;
    case IncomingSampleScreensEnum.IDF:
      return <IDFFormScreen />;
    default:
      return <React.Fragment />;
  }
}
