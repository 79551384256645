import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { TableInstance } from "react-table";
import styled from "styled-components/macro";
import { ContainerTableEntryFragment } from "graphql/generated/schema-types";
import {
  LotNumberField,
  PlateField,
} from "shared-components/FormFields/FormFields";
import { StyledFormControl } from "shared-components/styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledForm = styled.form`
  display: flex;
  margin: 10px;
  gap: 10px;
`;

export type HeaderFilters = {
  plateBarcode?: { plateCreatedId: number; plateBarcode: string };
  lotNumber?: { lotNumberId: number; lotNumber1: string };
  tubeBarcode?: string;
  isActive?: "true" | null;
};
export function SampleTableHeader({
  tableInstance,
  loading,
}: {
  tableInstance: TableInstance<ContainerTableEntryFragment>;
  loading: boolean;
}): JSX.Element {
  const {
    state: { filters },
    setAllFilters,
    setFilter,
  } = tableInstance;
  const {
    values: { plateBarcode, lotNumber, tubeBarcode, isActive },
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik<HeaderFilters>({
    initialValues: {},
    onSubmit: (values, { setSubmitting }) => {
      const newFilters =
        filters?.filter(
          (entry) =>
            !["plate.plateBarcode", "lotNumber.lotNumber1", "barcode"].includes(
              entry.id
            )
        ) ?? [];
      newFilters.push(
        { id: "plate.plateBarcode", value: values.plateBarcode?.plateBarcode },
        { id: "lotNumber.lotNumber1", value: values.lotNumber?.lotNumber1 },
        { id: "barcode", value: values.tubeBarcode },
        { id: "isActive", value: values.isActive }
      );
      setAllFilters(newFilters);
      if (isActive === null) setFilter("isActive", () => null);
      setSubmitting(false);
    },
  });
  return (
    <Container>
      <StyledForm onSubmit={handleSubmit}>
        <StyledFormControl width={"200px"} margin={"5px !important"}>
          <PlateField
            label={"Plate Barcode"}
            size={"small"}
            value={plateBarcode?.plateCreatedId}
            onChange={(e, newValue) => {
              setFieldValue("plateBarcode", newValue ?? null);
            }}
            id={"plateBarcode"}
            variant={"outlined"}
          />
        </StyledFormControl>
        <StyledFormControl width={"200px"} margin={"5px !important"}>
          <TextField
            label={"Tube Barcode"}
            size={"small"}
            value={tubeBarcode}
            onChange={handleChange}
            id={"tubeBarcode"}
            variant={"outlined"}
          />
        </StyledFormControl>
        <StyledFormControl width={"200px"} margin={"5px !important"}>
          <LotNumberField
            label={"Lot Number"}
            size={"small"}
            onChange={(e, newValue) => {
              setFieldValue("lotNumber", newValue ?? null);
            }}
            value={lotNumber?.lotNumberId ?? null}
            id={"lotNumber"}
            variant={"outlined"}
          />
        </StyledFormControl>
        <StyledFormControl width={"200px"} margin={"5px !important"}>
          <FormControlLabel
            control={
              <Switch
                id={"isActive"}
                checked={!!isActive}
                onChange={(e) =>
                  setFieldValue("isActive", e.target.checked ? "true" : null)
                }
              />
            }
            label={"Only Active"}
          />
        </StyledFormControl>
        <ButtonContainer>
          <Button
            type={"submit"}
            color={"secondary"}
            variant={"contained"}
            disabled={loading}
          >
            Query
          </Button>
        </ButtonContainer>
      </StyledForm>
      <Box m={1}>
        <Button
          variant={"outlined"}
          onClick={() => {
            setAllFilters([]);
            setValues({});
          }}
        >
          Clear Filters
        </Button>
      </Box>
    </Container>
  );
}
