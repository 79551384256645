import { ExpandMore } from "@mui/icons-material";
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import { SlideOverForms } from "features/RunTemplate/components/SlideOver/slideover.enum";
import { StyledAccordion } from "features/RunTemplate/components/Timeline/Node";
import { CardTitle } from "features/RunTemplate/components/Timeline/TaskCard/TaskCard";
import { useRunTemplateStateProvider } from "features/RunTemplate/state/StateProvider";
import {
  LookupLabwareType,
  RunTemplateTask,
} from "graphql/generated/schema-types";
import * as lodash from "lodash";
import React from "react";
import styled from "styled-components";

const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 10px;
`;
const DetailCardContainer = styled(Paper)`
  display: flex;
  min-height: 100px;
  min-width: 120px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
`;
const DetailCardTitle = styled.div`
  font-weight: bold;
  width: 100px;
  text-align: center;
`;
const DetailCard: React.FC<{
  objectKey: string;
  value?: string | number | boolean | null;
}> = ({ objectKey, value }) => {
  const title = lodash.startCase(objectKey);
  return (
    <>
      {value ? (
        <DetailCardContainer elevation={0}>
          <DetailCardTitle>{title}</DetailCardTitle>
          <Box>{value?.toString()}</Box>
        </DetailCardContainer>
      ) : (
        <React.Fragment />
      )}
    </>
  );
};
export function TaskAccordion({
  task,
  importantDetailsKeyValuePairs,
  onContextMenu,
}: {
  task: RunTemplateTask;
  importantDetailsKeyValuePairs: [
    string,
    (
      | string
      | number
      | boolean
      | RunTemplateTask
      | null
      | undefined
      | LookupLabwareType
    )
  ][];
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
}): JSX.Element {
  const {
    runTemplateState: { selectedTaskId, slideOverOpen, currentSlideOver },
  } = useRunTemplateStateProvider();
  return (
    <StyledAccordion elevation={3} onContextMenu={onContextMenu}>
      <AccordionSummary
        expandIcon={
          importantDetailsKeyValuePairs.length > 0 || task.notes ? (
            <ExpandMore />
          ) : (
            <div />
          )
        }
        aria-controls="panel1a-content"
        id={`task-${task.runTemplateTaskId}`}
      >
        <CardTitle>
          <Typography
            color={
              selectedTaskId === task.runTemplateTaskId &&
              slideOverOpen &&
              currentSlideOver === SlideOverForms.EditRunTemplateTasks
                ? "primary"
                : undefined
            }
          >
            <Box fontWeight={"fontWeightBold"}>
              {!!task.customTaskName
                ? task.customTaskName
                : task?.method?.methodName ?? task?.manualTaskType?.name}
            </Box>
          </Typography>
        </CardTitle>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Box marginBottom={"10px"}>
            <Typography>{task.notes}</Typography>
          </Box>
          <Box marginTop={"10px"}>
            {importantDetailsKeyValuePairs.length > 0 ? (
              <DetailsContainer>
                {importantDetailsKeyValuePairs.map((x, idx) => (
                  <DetailCard
                    key={idx}
                    objectKey={x[0]}
                    value={x[1] as string | number | boolean | undefined | null}
                  />
                ))}
              </DetailsContainer>
            ) : (
              <React.Fragment />
            )}
          </Box>
        </div>
      </AccordionDetails>
    </StyledAccordion>
  );
}
