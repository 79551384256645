import { QueryResult, useReactiveVar } from "@apollo/client";
import {
  IRunsModalAction,
  IRunsModalState,
  RunsModalState,
} from "features/Runs/state/modal/initial-state";
import { runsModalReducer } from "features/Runs/state/modal/reducer";
import {
  GetFolderDirectoryQuery,
  GetFolderDirectoryQueryVariables,
  GetRunTasksQuery,
  GetRunTasksQueryVariables,
  NodeType,
} from "graphql/generated/schema-types";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import produce from "immer";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useContext, useEffect, useMemo } from "react";
import { ALLRUNS_FOLDER_ID } from "../constants";
import {
  IRunsFolderTreeAction,
  IRunsFolderTreeState,
  RunsFolderTreeState,
} from "./folder-tree/initial-state";
import { runsFolderTreeReducer } from "./folder-tree/reducer";
import { useGetRunIdFromURL } from "./helpers";
import {
  FolderQueryContext,
  RunActivityQueryContext,
} from "./RunContextProvider";

export function useRunsModalState(): {
  runsModalState: IRunsModalState;
  runsModalDispatch: IApolloDispatch<IRunsModalAction>;
} {
  const runsModalState = useReactiveVar(RunsModalState);
  const runsModalDispatch = useMemo(
    () => ApolloStateDispatch(RunsModalState, runsModalReducer),
    []
  );
  return {
    runsModalState,
    runsModalDispatch,
  };
}

export function useRunsFolderTreeState(): {
  runsFolderTreeState: IRunsFolderTreeState;
  runsFolderTreeDispatch: IApolloDispatch<IRunsFolderTreeAction>;
} {
  const runsFolderTreeState = useReactiveVar(RunsFolderTreeState);
  const runsFolderTreeDispatch = useMemo(
    () => ApolloStateDispatch(RunsFolderTreeState, runsFolderTreeReducer),
    []
  );
  return useMemo(
    () => ({
      runsFolderTreeState,
      runsFolderTreeDispatch,
    }),
    [runsFolderTreeDispatch, runsFolderTreeState]
  );
}

export function useRunTaskQueryProvider(): QueryResult<
  GetRunTasksQuery,
  GetRunTasksQueryVariables
> {
  return useContext(RunActivityQueryContext)!;
}

export function useRunFolderQueryProvider(): QueryResult<
  GetFolderDirectoryQuery,
  GetFolderDirectoryQueryVariables
> {
  return useContext(FolderQueryContext)!;
}

export const useLoadFolderFromRoute = () => {
  const folderTreeState = useMemo(() => RunsFolderTreeState(), []);
  const runId = useGetRunIdFromURL();
  useEffect(() => {
    RunsFolderTreeState(
      produce(folderTreeState, (draft) => {
        draft.currentNode = runId
          ? { type: NodeType.Run, id: runId }
          : {
              type: NodeType.Folder,
              id: ALLRUNS_FOLDER_ID,
            };
        draft.folderSearch = undefined;
      })
    );
  }, [runId, folderTreeState]);
};
