import React, { PropsWithChildren } from "react";
import { CellProps, Hooks } from "react-table";
import { SelectRowCheckbox } from "shared-components/Table/sub-components/SelectRowCheckbox";

export function SelectHookCallback() {
  return function <TData extends Record<string, unknown>>(
    hooks: Hooks<TData>
  ): void {
    hooks.visibleColumns.push((columns) => [
      {
        id: "selection",
        Header: (props) => (
          <SelectRowCheckbox {...props.getToggleAllRowsSelectedProps()} />
        ),
        Cell: (props: PropsWithChildren<CellProps<TData>>) => (
          <SelectRowCheckbox {...props.row.getToggleRowSelectedProps()} />
        ),
      },
      ...columns,
    ]);
  };
}
