import { TextField } from "@mui/material";

interface NumberFieldProps {
  id: string;
  label: string;
  value: number | null;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  size?: string;
  minValue?: number;
  maxValue?: number;
  hasError?: boolean;
  errorMessage?: string;
  disabled?: boolean;
}

export const NumberField = ({
  id,
  label,
  value,
  onChange,
  style,
  size,
  minValue,
  maxValue,
  hasError,
  errorMessage,
  disabled,
}: NumberFieldProps) => {
  const isNegativeValue = value !== null && value < 0;
  const isLessThanMinValue = value !== null && value < (minValue ?? 0);
  const isGreaterThanMaxValue =
    value !== null && maxValue ? value > maxValue : false;

  const getErrorMessage = () => {
    return hasError
      ? errorMessage
      : isLessThanMinValue
      ? `Value can't be less than ${minValue}`
      : isGreaterThanMaxValue
      ? `Value can't be greater than ${maxValue}`
      : isNegativeValue
      ? "Value can't be less than 0"
      : null;
  };

  return (
    <TextField
      style={style}
      id={id}
      label={label}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      InputProps={{
        inputProps: { min: minValue ?? 0, max: maxValue },
      }}
      error={
        hasError ||
        isNegativeValue ||
        isLessThanMinValue ||
        isGreaterThanMaxValue
      }
      helperText={getErrorMessage()}
      variant="outlined"
      size={(size as "small" | "medium") ?? "medium"}
      type="number"
      onFocus={(event) => {
        event.target.addEventListener("wheel", (e) => e.preventDefault(), {
          passive: false,
        });
        event.target.select();
      }}
      InputLabelProps={{
        shrink: value !== null,
      }}
      disabled={disabled}
    />
  );
};
