import { ICalendarState, IInstrumentState } from "./initial-state";

export enum InstrumentAction {
  SELECT_INSTRUMENT,
}

export enum CalendarStateAction {
  SET_STATE,
}

export interface IInstrumentAction {
  payload?: IInstrumentState | null;
  type: InstrumentAction;
}

export interface ICalendarStateAction {
  payload?: ICalendarState | null;
  type: CalendarStateAction
}
