import {
  RunTaskDetailInput,
  Scalars,
  TaskTypeDetailInput,
  RunTemplateTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class RunTemplateTaskInputStub implements RunTemplateTaskInput {
  customTaskName: Maybe<Scalars["String"]>;
  dayScheduled: Scalars["Int"];
  userIds?: Maybe<Array<Scalars["Int"]>>;
  groupIds?: Maybe<Array<Scalars["Int"]>>;
  notes: Maybe<Scalars["String"]>;
  runTemplateId: Maybe<Scalars["Int"]>;
  runTemplateParentTaskId: Maybe<Scalars["Int"]>;
  runTemplateStageId: Maybe<Scalars["Int"]>;
  details: Maybe<RunTaskDetailInput>;
  runTemplateTaskId: Scalars["Int"];
  runTemplatesRepeatDaysOfWeeks: [];
  systemAssignedTo: Maybe<Scalars["Int"]>;
  templateTaskId: Scalars["Int"];
  templateTaskSeqNo: Maybe<Scalars["Int"]>;
  userAssignedTo: Maybe<Scalars["Int"]>;
  taskTypeDetails: Maybe<TaskTypeDetailInput>;
}
