import {
  CreateIncomingSampleCollectionInput,
  CreateIncomingSampleTypeInput,
} from "graphql/generated/schema-types";
import * as Yup from "yup";

export const CreateIncomingSampleValidationSchema =
  Yup.object().shape<CreateIncomingSampleCollectionInput>({
    collectionId: Yup.number().nullable().default(0).required(),
    incomingSamplesCollectionSampleTypes:
      Yup.array<CreateIncomingSampleTypeInput>().defined(),
    projectId: Yup.number()
      .moreThan(0, "Required")
      .required("Required")
      .typeError("Required"),
    dateTimeOfCollection: Yup.date()
      .nullable()
      .required("Required")
      .typeError("Required"),
    dateTimeOfArrival: Yup.date()
      .nullable()
      .required("Required")
      .typeError("Required"),
  });
export const CreateIncomingSampleTypeValidationSchema =
  Yup.object().shape<CreateIncomingSampleTypeInput>({
    collectionId: Yup.number().nullable().default(0).required(),
    dateToProcess: Yup.date()
      .nullable()
      .required("Required")
      .typeError("Required"),
    group: Yup.number().nullable().notRequired(),
    quantity: Yup.number()
      .nullable()
      .required("Required")
      .typeError("Required")
      .min(1, "1 minimum"),
    sampleTypeId: Yup.number()
      .nullable()
      .default(0)
      .min(1, "Required")
      .required(),
  });
