import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MethodSettingsProps } from "../shared";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export const PelletResuspensionReagent = ({
  twelve24TCInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const classes = useStyles();
  const hasError =
    twelve24TCInternalState.methodSettings.pelletResuspensionVol! > 0 &&
    twelve24TCInternalState.methodSettings.pelletResuspensionVol! < 501 &&
    twelve24TCInternalState.methodSettings.pelletResuspensionRGT === 0;
  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: 15 }}>
      <FormControl variant="outlined" fullWidth error={hasError}>
        <InputLabel id="pellet-resuspension-label">
          Pellet Resuspension / TopUp Reagent
        </InputLabel>
        <Select
          fullWidth={true}
          labelId="pellet-resuspension-label"
          id="pellet-resuspension-select"
          value={twelve24TCInternalState.methodSettings.pelletResuspensionRGT}
          label="Pellet Resuspension / TopUp Reagent"
          size="small"
          onChange={(e) => {
            handleMethodSettingsChanged(
              "pelletResuspensionRGT",
              e.target.value as number
            );
          }}
        >
          <MenuItem value={0}></MenuItem>
          <MenuItem value={1}>Media</MenuItem>
          <MenuItem value={2}>CryopreservationRGT</MenuItem>
          <MenuItem value={3}>PBS</MenuItem>
        </Select>

        <FormHelperText>
          {hasError ? "Reagent must be selected to upload plates" : ""}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
