import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  IUserManagerAction,
  IUserManagerState,
  UserManagerState,
} from "./initial-state";
import { userManagerStateReducer } from "./reducer";

export function useUserManagerState(): {
  userManagerState: IUserManagerState;
  userManagerDispatch: IApolloDispatch<IUserManagerAction>;
} {
  const userManagerState = useReactiveVar(UserManagerState);
  const userManagerDispatch = useMemo(
    () => ApolloStateDispatch(UserManagerState, userManagerStateReducer),
    []
  );
  return useMemo(
    () => ({
      userManagerState,
      userManagerDispatch,
    }),
    [userManagerState, userManagerDispatch]
  );
}
