import { ApolloError, useQuery } from "@apollo/client";
import { AppTheme } from "App.theme";
import { PageWithNav } from "features/Navbar/PageWithNav";
import AddPersonalTask from "features/PersonalTasks/components/AddPersonalTask";
import ManagePersonalTasks from "features/PersonalTasks/pages/ManagePersonalTasks";
import {
  GetDashboardTasksQuery,
  GetDashboardTasksQueryVariables,
  TaskStatusEnum,
  useGetMeQuery,
} from "graphql/generated/schema-types";
import { DashboardQueries } from "graphql/queries/dashboards.queries";
import { useMemo, useState } from "react";
import "react-contexify/dist/ReactContexify.min.css";
import Loading from "shared-components/Loading";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import DashboardAppBar from "../components/DashboardAppBar";
import { DashboardTabs } from "../components/DashboardTabs";
import { DashboardTaskTable } from "../components/Table/Table";
import { useDashboardBackgroundQueries } from "../hooks";
import { DashboardActions } from "../state/action";
import { useDashboardState } from "../state/hooks";

export default function TaskDashboards() {
  const [addPersonalTaskState, setAddPersonalTaskState] = useState(false);
  const [managePersonalTaskState, setManagePersonalTaskState] = useState(false);
  const { dashboardDispatch } = useDashboardState();
  const { data: userQuery } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });

  const loggedInUserId = useMemo(
    () =>
      userQuery?.me?.isGroupAccount
        ? userQuery?.me.loggedInGroupMember?.user?.userId
        : userQuery?.me?.userId,
    [
      userQuery?.me?.isGroupAccount,
      userQuery?.me?.loggedInGroupMember?.user?.userId,
      userQuery?.me?.userId,
    ]
  );

  // TODO: call useGetDashboardTasksQuery(), instead
  const { data, refetch, loading } = useQuery<
    GetDashboardTasksQuery,
    GetDashboardTasksQueryVariables
  >(DashboardQueries.GET_DASHBOARD_TASKS, {
    variables: {
      loggedInUser: loggedInUserId,
      status: Object.values(TaskStatusEnum),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted(data: any) {
      dashboardDispatch({
        type: DashboardActions.UPDATE_DASHBOARD_TASKS,
        payload: {
          dashboardTasks: data?.getOverdueTasks ?? [],
        },
      });
    },
    onError(error: ApolloError) {
      console.log(JSON.stringify(error));
    },
  });

  return (
    <PageWithNav>
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ marginBottom: "5px" }}>
          <DashboardAppBar
            setAddPersonalTaskState={setAddPersonalTaskState}
            setManagePersonalTaskState={setManagePersonalTaskState}
          />
        </div>
        <div>
          <DashboardTabs data={data} refetch={refetch} />
          <DashboardTaskTable
            loggedInUserId={loggedInUserId!}
            refetch={refetch}
            loading={loading}
          />
        </div>

        <AddPersonalTask
          isOpen={addPersonalTaskState}
          handleClose={() => setAddPersonalTaskState(false)}
          calendarDayOfMonth={null}
          calendarMonth={null}
        />

        <ManagePersonalTasks
          isOpen={managePersonalTaskState}
          handleClose={() => setManagePersonalTaskState(false)}
        />
      </div>
      <ConfirmDialog />
    </PageWithNav>
  );
}

export function Dashboard() {
  const loading = useDashboardBackgroundQueries();
  return loading ? (
    <Loading color={AppTheme.palette.secondary.main} name={"rotating-plane"} />
  ) : (
    <TaskDashboards />
  );
}
