import { makeVar } from "@apollo/client";
import { Maybe } from "graphql/generated/schema-types";
import { SampleModals } from "../components/ModalsAndDialogs/model.enum";
import { OverlayField } from "../components/PlateInfo/constants";
import { IWell } from "../components/PlateInfo/helpers";

export interface ILayoutState {
  wellListOpen?: boolean;
}

export interface IPlateState {
  selectedPlateBarcode?: string | Maybe<string>;
}

export interface IWellState {
  currentWell?: IWell;
  fromPlateMap?: boolean;
  currentOverlay?: OverlayField;
  showInactive?: boolean;
}

export interface IModalState {
  modalOpen?: boolean;
  currentModal?: SampleModals;
  selectedContainer?: number;
}

export const LayoutState = makeVar<ILayoutState>({
  wellListOpen: false,
});

export const PlateState = makeVar<IPlateState>({
  selectedPlateBarcode: undefined,
});

export const WellState = makeVar<IWellState>({
  currentWell: undefined,
  fromPlateMap: undefined,
  currentOverlay: undefined,
  showInactive: true,
});

export const ModalState = makeVar<IModalState>({
  modalOpen: false,
  selectedContainer: undefined,
});
