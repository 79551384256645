import { ApolloQueryResult } from "@apollo/client/core/types";
import { Dispatch, SetStateAction } from "react";
import { TableQueryVariables } from "shared-components/Table/Handlers/types";
import { ReactTablePaginationState } from "shared-components/Table/ReactTable";

export const GetHandlePageCountChange =
  <TQuery, TVariables extends Partial<TableQueryVariables>>(
    variables: TVariables | undefined,
    refetch: (
      variables?: Partial<TVariables>
    ) => Promise<ApolloQueryResult<TQuery>>,
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ): ((newPageCount: number) => void) =>
  (newPageCount: number) => {
    refetch({
      ...variables,
      pageSize: newPageCount,
    } as TVariables)
      .then(() =>
        setPaginationState({
          ...paginationState,
          rowsPerPage: newPageCount,
          page: 0,
        })
      )
      .catch((error) => console.log(error));
  };
