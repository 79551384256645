import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useGetActiveAsamsQuery } from "graphql/generated/schema-types";

interface SelectedSystemProps {
  automationMethodId: number;
  selectedSystem: number;
  handleSystemChanged: (system: number) => void;
}

export const SelectedSystem = ({
  automationMethodId,
  selectedSystem,
  handleSystemChanged,
}: SelectedSystemProps) => {
  const { data: activeASAMs } = useGetActiveAsamsQuery({
    variables: {
      where: {
        automationMethodId: { eq: automationMethodId },
      },
    },
  });

  const hasError = selectedSystem === 0;
  return (
    <FormControl variant="outlined" error={selectedSystem === 0}>
      <InputLabel id="system-label">System</InputLabel>
      <Select
        value={selectedSystem}
        label="System"
        variant="outlined"
        size="small"
        onChange={(e) => handleSystemChanged(e.target.value as number)}
      >
        {activeASAMs?.activeASAMs?.map((item) => (
          <MenuItem value={item?.arraySystemId}>{item?.arraySystemId}</MenuItem>
        ))}
      </Select>
      {hasError ? (
        <FormHelperText>System must be selected</FormHelperText>
      ) : null}
    </FormControl>
  );
};
