import { useReactiveVar } from "@apollo/client";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import { AutomationMethods } from "enums/Methods";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { SelectedSystem } from "features/WorklistTools/shared/components/SelectedSystem";
import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { is96WellPlate } from "features/WorklistTools/shared/WorklistHelpers";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ThawToolActions } from "../state";
import { useHandleMethodSettingsUpdated } from "../state/handlers";
import { ThawToolInternalState } from "../state/initial-state";

const StyledMethodSettingsContainer = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 15px;
`;

export const MethodSettings = ({ deadPlateTypeInfo }: any) => {
  const dispatch = useAppReduxDispatch();
  const thawToolInternalState = useReactiveVar(ThawToolInternalState);
  const methodSettings = thawToolInternalState.methodSettings;
  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );
  const updateMethodSettings = useHandleMethodSettingsUpdated();
  useEffect(() => {
    if (
      !is96WellPlate(thawWorklistToolInfo.destPlateInfo[0].labwareTypeCode) &&
      thawWorklistToolInfo.destPlateInfo[0].operatingVol
    ) {
      const topupVol =
        thawWorklistToolInfo.destPlateInfo[0].operatingVol -
        thawToolInternalState.methodSettings.resuspensionVol;
      updateMethodSettings("topupVol", topupVol >= 0 ? topupVol : 0);
    }
  }, [
    thawWorklistToolInfo.destPlateInfo[0],
    thawToolInternalState.methodSettings.resuspensionVol,
  ]);
  const handleMethodSettingsChanged = (key: string, value: any) => {
    if (key === "rpm") {
      handleSpinParamChange(value);
    } else if (key === "gForce") {
      handleGForceChange(value);
    } else if (key === "selectedSystem") {
      handleSelectedSystemChange(value);
    } else if (key === "resuspensionVol" && value === "") return;
    updateMethodSettings(key, value);
  };

  const handleSpinParamChange = (value: string) => {
    const GForceValue = Math.round(0.101 * Math.pow(parseInt(value), 1.999));
    updateMethodSettings("gForce", GForceValue);
  };

  const handleGForceChange = (value: string) => {
    const quotient = parseInt(value) / 0.101;
    const spinRPM = Math.round(Math.pow(quotient, 1 / 1.999));
    updateMethodSettings("rpm", spinRPM);
  };

  const handleSelectedSystemChange = (value: number) => {
    let automationMethodId = 0;
    switch (value) {
      case 3:
        automationMethodId = 105;
        break;
      case 6:
        automationMethodId = 288;
        break;
      case 10:
        automationMethodId = 128;
        break;
    }
    updateMethodSettings("arraySystemAutomationMethodId", automationMethodId);
  };

  const handleDeadPlateBarcodeChange = (value: string) => {
    dispatch(
      ThawToolActions.UPDATE_DEAD_PLATEBARCODE({
        value,
      })
    );
  };

  const handleDeadPlateTypeChange = (value: string) => {
    dispatch(
      ThawToolActions.UPDATE_DEAD_PLATETYPE({
        value,
      })
    );
  };

  return (
    <StyledMethodSettingsContainer>
      <SelectedSystem
        automationMethodId={AutomationMethods.Thaw}
        selectedSystem={methodSettings.selectedSystem}
        handleSystemChanged={(system) =>
          handleMethodSettingsChanged("selectedSystem", system)
        }
      />
      <TextField
        label="Dead PlateBarcode"
        variant="outlined"
        size="small"
        value={thawWorklistToolInfo.deadPlateBarcode}
        onChange={(e) =>
          handleDeadPlateBarcodeChange(e.target.value.toString())
        }
      />
      <PlateType
        platePosition={PlatePosition.Dead}
        isDisabled={thawToolInternalState.methodSettings!.selectedSystem === 0}
        acceptedPlateTypes={deadPlateTypeInfo}
        plateType={thawWorklistToolInfo.deadPlateType}
        setPlateType={(plateTypeInfo) =>
          handleDeadPlateTypeChange(plateTypeInfo.labwareTypeCode)
        }
      />
      <TextField
        label="Resuspension Vol"
        type="number"
        variant="outlined"
        size="small"
        defaultValue={200}
        onFocus={(event) => {
          event.target.select();
        }}
        value={thawToolInternalState.methodSettings.resuspensionVol}
        onChange={(e) =>
          handleMethodSettingsChanged("resuspensionVol", e.target.value)
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={thawToolInternalState.methodSettings.includeTopUpVol}
            onChange={(e) =>
              handleMethodSettingsChanged("includeTopUpVol", e.target.checked)
            }
            name="checkedB"
            color="primary"
            size="small"
            style={{ left: "4px" }}
          />
        }
        label="Override Topup Volume"
      />
      <TextField
        label="TopUp Vol"
        type="number"
        variant="outlined"
        size="small"
        onFocus={(event) => {
          event.target.select();
        }}
        disabled={!thawToolInternalState.methodSettings.includeTopUpVol}
        value={thawToolInternalState.methodSettings.topupVol}
        onChange={(e) =>
          handleMethodSettingsChanged("topupVol", e.target.value)
        }
      />
      <TextField
        name="GForce"
        type="number"
        variant="outlined"
        label="Spin Params G Force (RCF)"
        size="small"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(e) => handleMethodSettingsChanged("gForce", e.target.value)}
        value={thawToolInternalState.methodSettings.gForce}
      />
      <TextField
        name="RPM"
        type="number"
        variant="outlined"
        label="Spin Params (%)"
        size="small"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(e) => handleMethodSettingsChanged("rpm", e.target.value)}
        value={thawToolInternalState.methodSettings.rpm}
      />
      <TextField
        name="Time"
        type="number"
        variant="outlined"
        label="Spin Time (Minutes)"
        size="small"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(e) =>
          handleMethodSettingsChanged("spinTime", e.target.value)
        }
        value={thawToolInternalState.methodSettings.spinTime}
      />
      <TextField
        name="Accel"
        type="number"
        variant="outlined"
        label="Acceleration Settings (%)"
        size="small"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(e) =>
          handleMethodSettingsChanged("spinAccel", e.target.value)
        }
        value={thawToolInternalState.methodSettings.spinAccel}
      />
      <TextField
        name="Decel"
        type="number"
        variant="outlined"
        label="Deceleration Settings (%)"
        size="small"
        onFocus={(event) => {
          event.target.select();
        }}
        onChange={(e) =>
          handleMethodSettingsChanged("spinDecel", e.target.value)
        }
        value={thawToolInternalState.methodSettings.spinDecel}
      />
    </StyledMethodSettingsContainer>
  );
};
