import React from "react";
import styled from "styled-components/macro";
import { ManualTaskTable } from "../Tables/ManualTaskTable/ManualTaskTable";

const TableContainer = styled.div`
  margin: auto;
  width: 50vw;
`;
export function ManualTaskScreen(): JSX.Element {
  return (
    <TableContainer>
      <ManualTaskTable />
    </TableContainer>
  );
}
