import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AddRackOptions } from "../../constants";

interface NumberOfDuplicatesSelectorProps {
  numberOfDuplicates: number[];
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const NumberOfDuplicatesSelector = ({
  numberOfDuplicates,
  values,
  setFieldValue,
}: NumberOfDuplicatesSelectorProps) => {
  return (
    <FormControl>
      <InputLabel>Number of Duplicates</InputLabel>
      <Select
        label="Number of Duplicates"
        value={values.numberOfDuplicates}
        onChange={(e) => setFieldValue("numberOfDuplicates", e.target.value)}
      >
        {numberOfDuplicates.map((item) => (
          <MenuItem value={item}>{item}</MenuItem>
        ))}
      </Select>
    </FormControl>
    // <TextField
    //   style={{ width: "100%" }}
    //   label="Number of Duplicates"
    //   type="number"
    //   value={values.numberOfDuplicates}
    //   onChange={(e) =>
    //     setFieldValue("numberOfDuplicates", parseInt(e.target.value))
    //   }
    // />
  );
};
