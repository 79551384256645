import { useReactiveVar } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { SetLabwareType } from "features/WorklistTools/shared/components/SetLabwareType";
import { SourcePlate } from "features/WorklistTools/shared/components/SourcePlate";
import "features/WorklistTools/shared/components/styles/WorklistGenerator.scss";
import {
  UploadedSourcePlate,
  UploadSourcePlateDialog,
} from "features/WorklistTools/shared/components/UploadSourcePlateDialog";
import { useCtrlDown } from "features/WorklistTools/shared/hooks/hooks";
import {
  PlatePosition,
  ReactSelectableFast,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import { sortByCol } from "features/WorklistTools/shared/WorklistHelpers";
import {
  GetLabwareTypesQuery,
  LookupLabwareType,
  useGetPlatesInfoLazyQuery,
  useSetLabwareTypeMutation,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useState } from "react";
import { SelectableGroup } from "react-selectable-fast";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import styled from "styled-components";
import {
  PoolingNormalizationToolActions,
  SourcePlateInfoState,
} from "../state";
import { PoolingNormalizationToolInternalAction } from "../state/action";
import { PoolingNormalizationToolInternalState } from "../state/initial-state";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";
import { handleUploadPlate } from "./handlers/HandleUploadPlate";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlateTypeContainer = styled.div`
  margin-top: 15px;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const SourcePlatesContainer = (sourcePlateTypeInfo: any) => {
  const { isConfirmed } = useConfirm();
  const [selectedPlateIndex, setSelectedPlateIndex] = useState(0);
  const [uploadPlateDialogOpen, setUploadPlateDialogOpen] = useState(false);
  const [labwareTypeDialogOpen, setLabwareTypeDialogOpen] = useState(false);
  const poolingInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );

  const dispatch = useAppReduxDispatch();
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  const [colorIndex, setColorIndex] = useState(0);
  const [getPlateInfo, { data: plateInfo, refetch }] =
    useGetPlatesInfoLazyQuery({
      onCompleted: (data) => {
        handleUploadPlate({
          data,
          setLabwareTypeDialogOpen,
          uploadIntermediatePlate: false,
          sourcePlateInfo,
          dispatch,
          colorIndex,
          setColorIndex,
          selectedPlateIndex,
        });
      },
    });
  const [setLabwareType] = useSetLabwareTypeMutation();
  const sourcePlateInfo = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.sourcePlateInfo
  );

  const handleUploadSourcePlateClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    plate: UploadedSourcePlate | undefined,
    data: GetLabwareTypesQuery | undefined
  ) => {
    setUploadPlateDialogOpen(false);
    getPlateInfo({
      variables: {
        where: {
          plateCreatedId: { eq: plate!.plateCreatedId },
          containers: {
            some: {
              isActive: { eq: true },
            },
          },
        },
      },
    });
  };

  const handleSourceWellSelection = (value: ReactSelectableFast[]) => {
    const wellSelection = value?.map((well) => ({
      plateIndex: well.props.plateIndex,
      plateWellId: well.props.plateWellId,
      isSelectable: well.node.classList.contains("not-selectable")
        ? false
        : true,
    }));
    sortByCol(wellSelection);
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_SELECTED_SOURCE_WELLS,
      payload: {
        selectedSourceWells: wellSelection,
      },
    });
  };

  const handleSetLabwareTypeClose = (
    plateId: number,
    labwareType: LookupLabwareType | undefined,
    saveChanges: boolean
  ) => {
    setLabwareTypeDialogOpen(false);
    if (saveChanges) {
      const labwareTypeId = labwareType!.labwareTypeId;
      setLabwareType({
        variables: {
          plateId,
          labwareTypeId,
        },
      }).then(() => {
        refetch!().then((refetchData) => {
          handleUploadPlate({
            data: refetchData.data,
            setLabwareTypeDialogOpen,
            uploadIntermediatePlate: false,
            sourcePlateInfo,
            dispatch,
            colorIndex,
            setColorIndex,
            selectedPlateIndex,
          });
        });
        // const items = sourcePlateInfo
        //   .map((item) => {
        //     return item.wellInfo;
        //   })
        //   .filter((e) => e.length > 0);
        // //TODO: use color index to match colors of other plates
        // const tuple = setWellColorBySample(
        //   plateInfo?.platesInfo?.containers?.filter(
        //     (e) => e?.isActive === true
        //   ) ?? [],
        //   items[0] ?? [],
        //   colorIndex
        // );
        // setColorIndex(tuple.colorIndex);
        // dispatch(
        //   PoolingNormalizationToolActions.UPLOAD_SOURCE_PLATE({
        //     index: selectedPlateIndex,
        //     plateBarcode: plateInfo?.platesInfo?.plateBarcode,
        //     labwareTypeCode: labwareType?.labwareTypeCode,
        //     operatingVol: labwareType?.defaultLabwareVolume,
        //     rows: getArrayFromCount(
        //       labwareType!.plateLayoutType!.plateRows ?? 0
        //     ),
        //     cols: getArrayFromCount(
        //       labwareType!.plateLayoutType!.plateCols ?? 0
        //     ),
        //     wellInfo: tuple.newWellInfo,
        //   })
        // );
      });
    }
  };

  const handleUploadPlateClick = (index: number) => {
    setUploadPlateDialogOpen(true);
    setSelectedPlateIndex(index);
  };

  const handleRemovePlateClick = async (index: number) => {
    const confirmed = await isConfirmed(
      "Are you sure you want to remove this plate? NOTE: You can't add the plate back"
    );

    if (!confirmed) {
      return;
    }
    dispatch(
      PoolingNormalizationToolActions.CLEAR_SOURCE_PLATE({
        index,
      })
    );
  };

  const disableUploadButton = (plateIndex: number) => {
    return (
      sourcePlateInfo[plateIndex].plateBarcode !== "" ||
      poolingInternalState.methodSettings!.selectedSystem === 0
    );
  };

  const ctrlDown = useCtrlDown();
  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "600px",
        overflow: "auto",
      }}
    >
      {sourcePlateInfo.map((item: SourcePlateInfoState, index: number) => (
        <div style={{ padding: "20px" }}>
          <React.Fragment>
            <TextField
              variant="outlined"
              style={{ width: "100%", marginBottom: "10px" }}
              disabled
              label="Source Plate Barcode"
              value={item.plateBarcode}
              InputProps={{
                startAdornment: <b>{index + 1}| </b>,
              }}
            />
            <React.Fragment>
              <div>
                <StyledSelectableGroup
                  onSelectionFinish={handleSourceWellSelection}
                  resetOnStart={
                    ctrlDown.worklistToolInternalState.setCtrlDown
                      ? false
                      : true
                  }
                  allowCtrlClick={true}
                >
                  <SourcePlate
                    worklistTool={WorklistTools.PoolingNormalization}
                    index={index}
                    wellInfo={item.wellInfo}
                    plateRows={item.rows}
                    plateCols={item.cols}
                  />
                </StyledSelectableGroup>
              </div>

              <StyledPlateActions>
                <Button
                  variant="outlined"
                  onClick={() => handleUploadPlateClick(index)}
                  disabled={disableUploadButton(index)}
                >
                  Upload Plate
                </Button>
                <Button
                  variant="outlined"
                  disabled={item.rows.length < 1}
                  onClick={() => handleRemovePlateClick(index)}
                >
                  Remove Plate
                </Button>
              </StyledPlateActions>
              {item.rows.length > 0 && item.cols.length > 0 ? (
                <StyledPlateTypeContainer>
                  <PlateType
                    platePosition={PlatePosition.Source}
                    isDisabled={
                      index > 0 && sourcePlateInfo[index - 1].rows.length === 0
                    }
                    plateType={item.labwareTypeCode}
                    acceptedPlateTypes={sourcePlateTypeInfo.sourcePlateInfo}
                  />
                </StyledPlateTypeContainer>
              ) : null}
            </React.Fragment>
          </React.Fragment>
        </div>
      ))}

      <UploadSourcePlateDialog
        isOpen={uploadPlateDialogOpen}
        handleClose={() => setUploadPlateDialogOpen(false)}
        handleUpload={handleUploadSourcePlateClose}
        queryVariables={
          sourcePlateTypeInfo.sourcePlateInfo
            ? {
                where: {
                  labwareTypeId: {
                    in: sourcePlateTypeInfo.sourcePlateInfo.map((e: any) => {
                      return e.labwareTypeId;
                    }),
                  },
                  plateBarcode: {
                    nin: sourcePlateInfo.map((item) => item.plateBarcode),
                  },
                },
              }
            : {}
        }
        title="Select a Source Plate to Upload"
      ></UploadSourcePlateDialog>

      <SetLabwareType
        isOpen={labwareTypeDialogOpen}
        handleClose={(plateId, labwareType, saveChanges) =>
          handleSetLabwareTypeClose(plateId, labwareType, saveChanges)
        }
        plateId={plateInfo?.platesInfo?.plateCreatedId ?? 0}
        plateBarcode={plateInfo?.platesInfo?.plateBarcode ?? ""}
        plateLayoutTypeId={
          plateInfo?.platesInfo?.arrayPlateType?.plateLayoutTypeId ?? 0
        }
      ></SetLabwareType>

      <ConfirmDialog />
    </div>
  );
};
