import React from "react";
import styled from "styled-components/macro";
import { MethodTable } from "../Tables/MethodTable/MethodTable";

const TableContainer = styled.div`
  margin: auto;
  width: 50vw;
`;
export function MethodScreen(): JSX.Element {
  return (
    <TableContainer>
      <MethodTable />
    </TableContainer>
  );
}
