import { EventInput } from "@fullcalendar/common";
import {
  AppSuiteUser,
  CalendarNodeFragment,
  MethodReservation,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import styled from "styled-components";
import { COLORS, COLOR_TYPE, THEMES } from "../constants";
import { groupColors } from "../styles/Colors";

export const CalendarContainer = styled.div`
  width: 96%;
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-top: 10px;
  .fc: {
    background-color: red;
    margin: 100;
    display: none;
  }
  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0px;
  }
  .fc-scrollgrid thead tr {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark" ? "#121212" : "#ffffff"};
  }
  .fc-event {
    white-space: nowrap;
    overflow: hidden;
  }
  .overdue {
    border-width: 2px;
    border-image: repeating-linear-gradient(
        -45deg,
        #000000,
        #000000 2px,
        #ffff00 3px,
        #ffff00 4px
      )
      4/4px;
  }
  .inprogress {
    --angle: 0deg;
    border: 3px solid;
    border-image: conic-gradient(
        from var(--angle),
        #d9706c,
        #e4f29b,
        #9ad9ab,
        #99c5c9,
        #9399bf,
        #bf93bc,
        #d9706c
      )
      1;
    animation: 10s rotate linear infinite;
  }
  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }
`;

export const getEventObject = (
  cnf: CalendarNodeFragment,
  user: AppSuiteUser,
  myEventsOnly: boolean,
  currentTheme: string,
  currentTaskTitleMode: string
) => {
  const systemId = cnf.systemAssignedToNavigation?.systemId;
  const calNodeGroupName = cnf?.groups?.length
    ? cnf.groups[0]!.groupName
    : "No Team Associated";

  const userGroupIds =
    user?.groups?.filter((g) => g).map((g) => g!.groupId) ?? [];

  const isOwner =
    (user &&
      (cnf.groups?.some((g) => g && userGroupIds.includes(g.groupId)) ||
        cnf.users?.some((u) => u && u.userId === user!.userId))) ??
    false;

  const canEdit =
    (user &&
      (user.isAdmin === true || isOwner) &&
      cnf.status !== TaskStatusEnum.Completed &&
      cnf.status !== TaskStatusEnum.InProgress) ??
    false;

  const methodReservation =
    cnf.methodReservations && cnf.methodReservations.length
      ? (cnf.methodReservations![0] as MethodReservation)
      : null;
  const barcodes =
    methodReservation?.methodReservationsPlateBarcodes
      ?.filter((mrpb) => mrpb && mrpb.plateBarcode)
      ?.map((mrpb) => mrpb!.plateBarcode!.toString()) ?? [];

  const generateColor = (colorType: string): string => {
    if (myEventsOnly && !isOwner) {
      return COLORS.GRAY;
    }
    if (currentTheme === THEMES.DARK) {
      return colorType === COLOR_TYPE.BACKGROUND
        ? groupColors.find(
            (groupObj) => groupObj.groupName === calNodeGroupName
          )?.dark ?? ""
        : COLORS.WHITE;
    }
    return colorType === COLOR_TYPE.BACKGROUND
      ? groupColors.find((groupObj) => groupObj.groupName === calNodeGroupName)
          ?.light ?? ""
      : COLORS.BLACK;
  };

  const generateEngineerTitle = () => `${
    cnf.method?.methodName ?? cnf.name ?? cnf.manualTaskType?.name ?? ""
  } - ${cnf.updatedByName ?? ""}
  `;

  const generateTitle = (): string => {
    const persTaskTitle =
      cnf.name ?? cnf.method?.methodName ?? cnf.manualTaskType?.name ?? "";
    if (cnf.taskType === "PERSONAL") {
      return persTaskTitle;
    }
    return `${cnf.runName?.split("_")[0]}: ${persTaskTitle}`;
  };
  // https://fullcalendar.io/docs/event-object

  const generateBorderClassName = (taskStatus: TaskStatusEnum) => {
    let classname;
    switch (taskStatus) {
      case TaskStatusEnum.Overdue:
        classname = "overdue";
        break;
      case TaskStatusEnum.InProgress:
        classname = "inprogress";
        break;
      default:
        classname = null;
        break;
    }
    return classname;
  };

  return {
    id: cnf.id.toString(),
    title:
      currentTaskTitleMode === "engineermode"
        ? generateEngineerTitle()
        : generateTitle(),
    start: cnf.startTime,
    end: cnf.endTime,
    backgroundColor:
      cnf.status === TaskStatusEnum.Completed
        ? "#A2A2A2"
        : generateColor(COLOR_TYPE.BACKGROUND),
    textColor: generateColor(COLOR_TYPE.TEXT),
    editable: canEdit,
    resourceEditable: canEdit,
    className: generateBorderClassName(cnf.status),
    // extendedProps below
    resourceId: systemId?.toString() ?? "",
    systemId: systemId,
    systemName: cnf.systemAssignedToNavigation?.systemName ?? "",
    runId: (cnf.runId as number) ?? null,
    taskType: cnf.taskType,
    users:
      cnf.users
        ?.filter((u) => u && u.userName)
        .map((u) => u!.userName!.toString()) ?? [],
    groupIds: cnf.groups?.map((g) => g!.groupId) ?? [],
    groups: cnf.groups?.map((g) => g!.groupName!.toString()) ?? [],
    barcodes: barcodes,
    clusterId: cnf.systemAssignedToNavigation?.clusterId,
    status: cnf.status,
    priority: cnf.priority,
    lastTimeUpdated: cnf.lastTimeUpdated,
    updatedByName: cnf.updatedByName,
    methodName: cnf.method?.methodName || "N/A - Manual",
    methodId: cnf.method?.methodId ?? null,
    altTitle: currentTaskTitleMode === "engineermode" ? generateTitle() : "",
  } as EventInput;
};
