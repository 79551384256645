export enum TypeNames {
  AppSuiteUser = "AppSuiteUser",
  AppSuiteGroup = "AppSuiteGroup",
  LookupArraySystem = "LookupArraySystem",
  RunTemplate = "RunTemplate",
  Ticket = "Ticket",
  Collection = "Collection",
  CollectionTask = "CollectionTask",
  RunTemplateStage = "RunTemplateStage",
  RunTemplateTask = "RunTemplateTask",
  Run = "Run",
  RunTask = "RunTask",
  LookupTemplateTask = "LookupTemplateTask",
  LookupArrayMethod = "LookupArrayMethod",
  ProductionRunType = "ProductionRunType",
  GroupLoginState = "GroupLoginState",
  PlatesCreated = "PlatesCreated",
  FolderStructure = "FolderStructure",
  ManualTaskType = "ManualTaskType",
  Sample = "Sample",
  Container = "Container",
}
