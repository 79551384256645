import { TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { GenericFieldProps } from "./FeedGroupFormRow";

export interface NumberOfPlatesProps extends GenericFieldProps {
  handleChange: (e: ChangeEvent<any>) => void;

  value: number | undefined | null;
}

export const NumberOfPlates = (props: NumberOfPlatesProps) => {
  return (
    <TextField
      autoComplete="off"
      disabled={props.toggleDisabled}
      error={!!props.formikErrors}
      helperText={props.formikErrors}
      label="Number of Plates"
      name={`feedGroupArray[${props.index}].numberOfPlates`}
      onBlur={props.handleBlur}
      onChange={props.handleChange}
      type="number"
      value={props.value}
      variant="outlined"
    />
  );
};
