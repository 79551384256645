import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { Box, Button, TextField, Typography } from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import { TaskAttachments } from "features/Dashboard/components/Dialogs/TaskAttachments";
import {
  CollapseControls,
  Container,
  ExpandDiv,
  FieldsContainer,
} from "features/Runs/components/Forms/Create/RunTask/Form";
import { UpdateRunActivityValidationSchema } from "features/Runs/components/Forms/validation-schema";
import { FormikHelpers, useFormik } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  LookupArraySystem,
  PersonalTaskAttachment,
  RunTaskAttachment,
  RunTaskInput,
  RunTaskType,
  TaskSelectionTypeEnum,
  TaskTypeEnum,
  useGetRunTaskAttachmentsQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import produce from "immer";
import React, { useEffect, useState } from "react";
import {
  GroupField,
  PriorityField,
  RunTaskTypeField,
  StatusField,
  SystemField,
  UserField,
} from "shared-components/FormFields/FormFields";
import { MethodFormReducer } from "shared-components/FormFields/MethodFields/MethodFormReducer";
import {
  FormSubmitButton,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { MethodsWithDetails } from "constants/methodsWithDetails";
import { RunTaskTypeCompositeKey } from "features/RunTemplate/helpers";
import { useRunsModalState } from "features/Runs/state/hooks";

export function EditRunActivityForm({
  onSubmit,
  runTaskId,
  initialValues,
}: {
  onSubmit: (
    values: RunTaskInput,
    actions: FormikHelpers<RunTaskInput>
  ) => void;
  runTaskId: number;
  initialValues: RunTaskInput;
}): JSX.Element {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const props = useFormik({
    initialValues: initialValues,
    validationSchema: UpdateRunActivityValidationSchema,
    onSubmit,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setValues,
  } = props;
  const { runsModalState } = useRunsModalState();
  useEffect(() => {
    if (
      values.details &&
      (!values.details.parentId ||
        typeof values.details.runTaskDetailId !== "number")
    ) {
      setValues(
        produce(values, (draft) => {
          draft!.details!.parentId = runsModalState.selectedTasks?.[0];
          draft!.details!.runTaskDetailId = 0;
        })
      );
    }
  }, [runsModalState.selectedTasks, setFieldValue, setValues, values]);
  const [showAttachments, setShowAttachments] = useState(false);
  const {
    data: runTaskAttachments,
    startPolling,
    stopPolling,
  } = useGetRunTaskAttachmentsQuery({
    variables: {
      taskId: runTaskId,
    },
  });
  useEffect(() => {
    startPolling(2000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (!runsModalState.modalOpen) {
      stopPolling();
    }
  }, [stopPolling, runsModalState.modalOpen]);
  const transition = useTransition(showAttachments, {
    from: {
      maxWidth: "0vw",
      maxHeight: "20vh",
    },
    enter: {
      maxWidth: "60vw",
      maxHeight: "50vh",
    },
    leave: {
      maxWidth: "0vw",
      maxHeight: "0vh",
      overflow: "hidden",
    },
  });
  return (
    <StyledForm onSubmit={handleSubmit}>
      <Container>
        <FieldsContainer>
          <StyledFormControl>
            <TextField
              variant="outlined"
              margin="none"
              fullWidth
              id="activityName"
              name="activityName"
              label="Task Name"
              value={values.activityName ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors.activityName &&
                touched.activityName &&
                errors.activityName
              }
              error={
                !!(
                  errors.activityName &&
                  touched.activityName &&
                  errors.activityName
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <RunTaskTypeField
              id="taskName-combobox"
              data-testid={"taskName-combobox"}
              value={RunTaskTypeCompositeKey(values.taskTypeDetails)}
              groupBy={(taskType) => taskType?.type ?? ""}
              onChange={(e, newValue) => {
                const taskType = newValue as Maybe<RunTaskType>;
                if (taskType?.type === TaskSelectionTypeEnum.Method) {
                  setValues(
                    produce(values, (draft) => {
                      draft!.taskTypeDetails = {
                        taskType: TaskSelectionTypeEnum.Method,
                        taskTypeId: taskType.id,
                      };
                      draft!.activityName = taskType.name!;
                    })
                  );
                } else if (taskType?.type === TaskSelectionTypeEnum.Manual) {
                  setValues(
                    produce(values, (draft) => {
                      draft!.taskTypeDetails = {
                        taskType: TaskSelectionTypeEnum.Manual,
                        taskTypeId: taskType.id,
                      };
                      draft!.activityName = taskType.name!;
                    })
                  );
                }
              }}
              label="Task Type"
              variant="outlined"
              helperText={
                errors.activityName &&
                touched.activityName &&
                errors.activityName
              }
              error={
                !!(
                  errors.activityName &&
                  touched.activityName &&
                  errors.activityName
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <DatePicker
              value={values.startTimeScheduled ?? ""}
              onChange={(date) => setFieldValue("startTimeScheduled", date)}
              disabled={true}
              renderInput={(props) => (
                <TextField
                  id="start-date-picker"
                  variant="outlined"
                  label="Start Date"
                  helperText={
                    errors.startTimeScheduled &&
                    touched.startTimeScheduled &&
                    errors.startTimeScheduled
                  }
                  error={
                    !!(
                      errors.startTimeScheduled &&
                      touched.startTimeScheduled &&
                      errors.startTimeScheduled
                    )
                  }
                  fullWidth
                  {...props}
                />
              )}
            />
          </StyledFormControl>
          <StyledFormControl>
            <UserField
              id="userAssignedTo-combobox"
              defaultValue={initialValues.runTaskAssignedUsers}
              value={values.runTaskAssignedUsers}
              onChange={(e, newValue) => {
                setFieldValue(
                  "runTaskAssignedUsers",
                  (newValue as Maybe<AppSuiteUser>[])?.map(
                    (entry) => entry?.userId
                  ) ?? null
                );
              }}
              label="Users"
              variant="outlined"
              multiple
              helperText={
                errors.runTaskAssignedUsers &&
                touched.runTaskAssignedUsers &&
                errors.runTaskAssignedUsers
              }
              error={
                !!(
                  errors.runTaskAssignedUsers &&
                  touched.runTaskAssignedUsers &&
                  errors.runTaskAssignedUsers
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <SystemField
              id="systemAssignedTo-combobox"
              defaultValue={initialValues.systemAssignedTo}
              onChange={(e, newValue) =>
                setFieldValue(
                  "systemAssignedTo",
                  (newValue as Maybe<LookupArraySystem>)?.systemId ?? null
                )
              }
              label="System"
              variant="outlined"
              helperText={
                errors.systemAssignedTo &&
                touched.systemAssignedTo &&
                errors.systemAssignedTo
              }
              error={
                !!(
                  errors.systemAssignedTo &&
                  touched.systemAssignedTo &&
                  errors.systemAssignedTo
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <GroupField
              id="groupAssignedTo-combobox"
              value={values.runTaskAssignedGroups}
              onChange={(e, newValue) =>
                setFieldValue(
                  "runTaskAssignedGroups",
                  (newValue as Maybe<AppSuiteGroup>[])?.map(
                    (entry) => entry?.groupId
                  ) ?? null
                )
              }
              label="Groups"
              variant="outlined"
              multiple
              defaultValue={initialValues.runTaskAssignedGroups}
              helperText={
                errors.runTaskAssignedGroups &&
                touched.runTaskAssignedGroups &&
                errors.runTaskAssignedGroups
              }
              error={
                !!(
                  errors.runTaskAssignedGroups &&
                  touched.runTaskAssignedGroups &&
                  errors.runTaskAssignedGroups
                )
              }
            />
          </StyledFormControl>
          <StyledFormControl>
            <PriorityField
              id={"priority"}
              defaultValue={values.priority}
              label="Priority"
              onChange={(e, newValue) => setFieldValue("priority", newValue)}
              helperText={
                errors.priority && touched.priority && errors.priority
              }
              error={!!(errors.priority && touched.priority && errors.priority)}
              variant={"outlined"}
            />
          </StyledFormControl>
          <StyledFormControl>
            <StatusField
              id={"status"}
              defaultValue={values.status}
              label="Status"
              onChange={(e, newValue) => setFieldValue("status", newValue)}
              helperText={errors.status && touched.status && errors.status}
              error={!!(errors.status && touched.status && errors.status)}
              variant={"outlined"}
            />
          </StyledFormControl>
          <StyledFormControl>
            <TextField
              variant="outlined"
              multiline
              margin="none"
              fullWidth
              id="notes"
              name="notes"
              label="Notes"
              value={values.notes ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.notes && touched.notes && errors.notes}
              error={!!(errors.notes && touched.notes && errors.notes)}
            />
          </StyledFormControl>
          <CollapseControls>
            {values.taskTypeDetails?.taskType ===
              TaskSelectionTypeEnum.Method &&
            MethodsWithDetails.includes(values.taskTypeDetails!.taskTypeId!) ? (
              <ExpandDiv
                aria-label="expand"
                onClick={(e) => {
                  e.stopPropagation();
                  setCollapseOpen(!collapseOpen);
                }}
              >
                <div>Task Details</div>
                {collapseOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </ExpandDiv>
            ) : (
              <React.Fragment />
            )}
          </CollapseControls>
          <Box mt={2}>
            <Typography color={"inherit"}>
              <Button onClick={() => setShowAttachments((current) => !current)}>
                {showAttachments ? "Hide" : "View"} Attachments
              </Button>
            </Typography>
          </Box>
          {transition(
            (props, item) =>
              item && (
                <animated.div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    ...props,
                  }}
                >
                  <TaskAttachments
                    runTaskAttachments={
                      runTaskAttachments?.runTaskAttachments as RunTaskAttachment[]
                    }
                    personalTaskAttachments={[] as PersonalTaskAttachment[]}
                    taskId={runTaskId}
                    taskType={TaskTypeEnum.RunTask}
                  />
                </animated.div>
              )
          )}
          <FormSubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            data-testid={"submit"}
          >
            Preview
          </FormSubmitButton>
        </FieldsContainer>
        {values.taskTypeDetails?.taskType === TaskSelectionTypeEnum.Method &&
        MethodsWithDetails.includes(values.taskTypeDetails!.taskTypeId!) ? (
          <MethodFormReducer
            props={props}
            methodId={values.taskTypeDetails!.taskTypeId! ?? 0}
            collapseOpen={collapseOpen}
            controlled
          />
        ) : null}
      </Container>
    </StyledForm>
  );
}
