import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FeedMethods } from "constants/feedMethods";
import {
  RunTaskType,
  TaskSelectionTypeEnum,
  useGetRunTaskTypesQuery,
} from "graphql/generated/schema-types";

export interface MethodTaskComponentProps {
  setFieldValue: (field: string, value: any) => void;
  isSystemMethod: boolean;
  setAutomationMethodId: React.Dispatch<React.SetStateAction<number>>;
  touched: boolean | undefined;
  errors: string | undefined;
}

export const MethodTask = (props: MethodTaskComponentProps) => {
  const runTaskTypeQuery = useGetRunTaskTypesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const setFieldValue = (taskType: RunTaskType) => {
    if (
      taskType?.type === TaskSelectionTypeEnum.Method ||
      taskType?.type === TaskSelectionTypeEnum.Manual
    ) {
      const isMethod = taskType?.type === TaskSelectionTypeEnum.Method;
      // field names are from PersonalTaskInput
      props.setFieldValue("methodId", isMethod ? taskType.id : 0);
      props.setFieldValue("manualTaskTypeId", !isMethod ? taskType.id : 0);
      props.setAutomationMethodId(taskType.automationMethodId ?? 0);
    }
  };

  const selectedType = props.isSystemMethod
    ? TaskSelectionTypeEnum.Method
    : TaskSelectionTypeEnum.Manual;
  const byName = (a: RunTaskType, b: RunTaskType) => {
    if (!a?.name && !b?.name) return 0;
    if (!b?.name || (a?.name && a.name > b.name)) return 1;
    if (!a?.name || (b?.name && a.name < b.name)) return -1;
    return 0;
  };

  const options =
    runTaskTypeQuery.data?.runTaskTypes
      ?.filter(
        (rtt) => rtt?.type === selectedType && !FeedMethods.includes(rtt?.id)
      )
      .map((rtt) => rtt as RunTaskType)
      .sort(byName) ?? [];

  return (
    <Autocomplete
      id="methodTask"
      options={options}
      getOptionLabel={(rtt) =>
        (rtt.type === "METHOD" && !rtt.automationMethodId ? "[no ASAM] " : "") +
        rtt.name!
      }
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          label="Method Task"
          variant="outlined"
          error={props.touched && props.errors !== ""}
          helperText={props.errors}
        />
      )}
      onChange={(_e, value) => setFieldValue(value)}
    />
  );
};
