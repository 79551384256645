import { Maybe } from "graphql-tools";
import { WorklistToolTemplateContent } from "graphql/generated/schema-types";
import { PoolingNormalizationToolState } from "../../state";
import { IPoolingMethodSettings } from "../../state/initial-state";

export const parseWorklistValues = (
  worklistValues: Maybe<Maybe<WorklistToolTemplateContent>[]> | undefined
) => {
  const parsedWorklistValues = worklistValues?.map((row) => ({
    sourcePlateIndex: row!.sourcePlatePosition,
    sourceWellId: row!.sourceWellId,
    destPlateIndex: row!.destinationPlatePosition,
    destWellId: row!.destinationWellId,
    transferVol: row!.transferVol ?? "",
  }));

  return parsedWorklistValues;
};

export const parseStampTopLeftValues = (
  worklistValues: Maybe<Maybe<WorklistToolTemplateContent>[]> | undefined
) => {
  const parsedStampTopLefts = worklistValues?.map((row) => ({
    sourceWellId: row?.sourceWellId,
    destPlateIndex: row?.destinationPlatePosition,
    destWellId: row?.destinationWellId,
  }));

  return parsedStampTopLefts;
};
export const parseMethodSettingsTemplate = (
  worklist: Maybe<Maybe<WorklistToolTemplateContent>[]> | undefined
) => {
  if (!worklist) return;
  const getWorklistRowByTask = (task: string) =>
    worklist.find((e) => e!.task === task);

  const getTaskDetails_int = (task: string, nullCoalesceValue = "0") =>
    parseInt(getWorklistRowByTask(task)?.details ?? nullCoalesceValue);

  const getTaskDetails_bool = (task: string) =>
    Boolean(getTaskDetails_int(task));

  const spinParamPercent = getTaskDetails_int("SpinParameters");

  const methodSettings: IPoolingMethodSettings = {
    selectedSystem: 0,
    arraySystemAutomationMethodId: 299,
    numberOfAliquots: 1,
    aliquotVolume: getTaskDetails_int("DeadTotalPlate", "-1"),
    groupedSeedingPriority: [
      { id: "12/24W", content: "12/24W Group" },
      { id: "96/384W", content: "96/384W Group" },
    ],
    seedingPriority_12_24_Well: [],
    seedingPriority_96_384_Well: [],
    dissociationTime: getTaskDetails_int("IncubationTime"),
    spinParamGForce: Math.round(0.101 * Math.pow(spinParamPercent, 1.999)),
    spinParamPercent: spinParamPercent,
    spinTime: parseInt(
      worklist.filter((e) => e!.task === "SpinParameters")[0]!.aspirateVol ?? ""
    ),
    spinAccel: parseInt(
      worklist.filter((e) => e!.task === "SpinParameters")[0]!.dispenseVol ?? ""
    ),
    spinDecel: parseInt(
      worklist.filter((e) => e!.task === "SpinParameters")[0]!.transferVol ?? ""
    ),
    dissociationWashRGT: getTaskDetails_int("DissociationWashRGT"),
    reFeedWellsRGT: getTaskDetails_int("ReFeedWellsRGT"),
    harvestWashRGT: getTaskDetails_int("HarvestWashRGT"),
    extraPelletWash: getTaskDetails_bool("ExtraPelletWash"),
    dissociation: getTaskDetails_bool("Dissociation"),
    washBeforeDissociation: getTaskDetails_bool("DissociationWash"),
    washAfterDissociation: getTaskDetails_bool("HarvestWash"),
    stampReuseTips: getTaskDetails_bool("StampReuseTips"),
    reFeedSourceWells: getTaskDetails_bool("ReFeedWells"),
    harvestWash: getTaskDetails_bool("ReFeedWells"),
    discardSourcePlate: getTaskDetails_bool("DiscardSourcePlate"),
    stampVolume: getTaskDetails_int("StampVolume"),
    // Remove comments to add Source96HeadProcessing Logic
    // source96HeadProcessing: !!parseInt(
    //   worklist!.filter((e) => e!.task === "Source96HeadProcessing")[0]
    //     ?.details ?? ""
    // ),
    int196HeadProcessing: getTaskDetails_bool("Int196HeadProcessing"),
    reducedVolWellWash: getTaskDetails_bool("ReducedVolWellWash"),
    reducedPelResusSpeed: getTaskDetails_bool("ReducedPelResusSpeed"),
    int1CountAliquot: getTaskDetails_int("Int1CountAliquot"),
    int1SpinResuspend: getTaskDetails_int("Int1SpinResuspend"),
  };

  return methodSettings;
};

export const parseStampTopLeftTemplate = (
  stampTopLeftTemplate: Maybe<Maybe<WorklistToolTemplateContent>[]> | undefined
) => {
  const stampTopLeft = stampTopLeftTemplate!.map((row) => ({
    plateIndex: row!.sourcePlatePosition,
    wellId: row!.sourceWellId,
  }));

  return stampTopLeft;
};

// VV used to save and overwrite templates //

export const getSourcePlateInfo = (
  poolingState: PoolingNormalizationToolState
) => {
  return poolingState.sourcePlateInfo
    .filter((e) => e.labwareTypeCode !== "")
    .map((item, index) => {
      return {
        plateType: "Source",
        plateIndex: index,
        labwareTypeCode: item.labwareTypeCode,
        numberOfRows: item.rows.length,
        numberOfColumns: item.cols.length,
      };
    });
};

export const getIntPlateInfo = (
  poolingState: PoolingNormalizationToolState
) => {
  return poolingState.intPlateInfo
    .filter((e) => e.labwareTypeCode !== "")
    .map((item, index) => {
      return {
        plateType: "Int",
        plateIndex: index,
        labwareTypeCode: item.labwareTypeCode,
        numberOfRows: item.rows.length,
        numberOfColumns: item.cols.length,
        operatingVol: item.operatingVol,
        resuspensionVol: item.resuspensionVol,
      };
    });
};

export const getDestPlateInfo = (
  poolingState: PoolingNormalizationToolState
) => {
  return poolingState.destPlateInfo
    .filter((e) => e.labwareTypeCode !== "")
    .map((item, index) => {
      return {
        plateType: "Dest",
        plateIndex: index,
        labwareTypeCode: item.labwareTypeCode,
        numberOfRows: item.rows.length,
        numberOfColumns: item.cols.length,
        operatingVol: item.operatingVol,
        startingVol: item.startingVol,
        preprocess: item.preprocess,
        topup: item.topup,
      };
    });
};

export const getDeadPlateInfo = (
  poolingState: PoolingNormalizationToolState
) => {
  return poolingState.deadPlateType
    ? [
        {
          plateType: "Dead",
          plateIndex: 0,
          labwareTypeCode: poolingState.deadPlateType,
          numberOfRows: 0,
          numberOfColumns: 0,
        },
      ]
    : [];
};

export const getHarvestWells = (
  poolingState: PoolingNormalizationToolState
) => {
  return poolingState.worklistValues.harvestWells
    .map((row) => ({
      task: "HarvestWells",
      transferVol: row?.transferVol?.toString() ?? "",
      sourcePlateType:
        poolingState.sourcePlateInfo[row.sourcePlateIndex].labwareTypeCode,
      sourcePlatePosition: row.sourcePlateIndex,
      sourceWellID: row.sourceWellId,
      destinationPlateType:
        poolingState.intPlateInfo[row.destPlateIndex].labwareTypeCode,
      destinationPlatePosition: row.destPlateIndex,
      destinationWellID: row.destWellId,
    }))
    .filter((e) => e.destinationPlateType !== "");
};

export const getIntMapping = (
  worklistValues: any[],
  task: string,
  intPlateInfo: any[]
) => {
  return worklistValues
    .map((row) => ({
      task,
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[row.sourcePlateIndex].labwareTypeCode,
      sourcePlatePosition: row.sourcePlateIndex,
      sourceWellID: row.sourceWellId,
      destinationPlateType: intPlateInfo[row.destPlateIndex].labwareTypeCode,
      destinationPlatePosition: row.destPlateIndex,
      destinationWellID: row.destWellId,
    }))
    .filter((e) => e.destinationPlateType !== "");
};

export const getIntToDest = (
  intToDest: any[],
  task: string,
  intPlateInfo: any[],
  destPlateInfo: any[]
) => {
  return intToDest
    .map((row) => ({
      task,
      transferVol: row.transferVol.toString(),
      sourcePlateType: intPlateInfo[row.sourcePlateIndex].labwareTypeCode,
      sourcePlatePosition: row.sourcePlateIndex,
      sourceWellID: row.sourceWellId,
      destinationPlateType: destPlateInfo[row.destPlateIndex].labwareTypeCode,
      destinationPlatePosition: row.destPlateIndex,
      destinationWellID: row.destWellId,
    }))
    .filter((e) => e.destinationPlateType !== "");
};

export const getIntStampTopLeft = (intStampTopLeft: any[], task: string) => {
  return intStampTopLeft.map((row) => ({
    task,
    sourceWellID: row.sourceWellId,
    destinationPlatePosition: row.destPlateIndex,
    destinationWellID: row.destWellId,
  }));
};

export const getStampTopLeftTransfers = (stampTopLeftTransfers: any[]) => {
  return stampTopLeftTransfers.map((row) => ({
    task: "StampTopLeftTransfers",
    sourcePlatePosition: row.sourcePlateIndex,
    sourceWellID: row.sourceWellId,
    destinationPlatePosition: row.destPlateIndex,
    destinationWellID: row.destWellId,
  }));
};
