import _ from "lodash";
import React, { useMemo } from "react";
import {
  UpdateAppSuiteUserInput,
  useEditUserMutation,
} from "graphql/generated/schema-types";
import { useGetUser } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import { AppSuiteUserInputMapper } from "mappings/appSuiteUser";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { UserManagerActions } from "features/AdminDashboard/state/actions";
import { useUserManagerState } from "features/AdminDashboard/state/hooks";
import { EditUserForm } from "./Form";

const RequiredFields: (keyof UpdateAppSuiteUserInput)[] = ["isActive"];
export function EditUserModal(): JSX.Element {
  const windowSize = useWindowSize();
  const {
    userManagerDispatch,
    userManagerState: { selectedUsers },
  } = useUserManagerState();
  const [updateUser] = useEditUserMutation();
  const user = useGetUser(selectedUsers![0]!);
  const input = useMemo(() => AppSuiteUserInputMapper(user), [user]);

  function excludeUnchangedFields(values: UpdateAppSuiteUserInput) {
    for (const key in input) {
      const _key = key as keyof UpdateAppSuiteUserInput;
      if (
        _.isEqual(input[_key], values[_key]) &&
        !RequiredFields.includes(_key)
      )
        values[_key] = undefined;
    }
  }

  const handleSubmit = (values: UpdateAppSuiteUserInput) => {
    excludeUnchangedFields(values);
    return updateUser({
      variables: {
        inputs: [{ primaryKey: selectedUsers![0]!, set: values }],
      },
    })
      .then(() => userManagerDispatch({ type: UserManagerActions.CLOSE_MODAL }))
      .then(async () => {
        Alert({ type: AlertType.SUCCESS, message: "Changes Saved" });
      });
  };

  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <EditUserForm initialValues={input} onSubmit={handleSubmit} />
    </StyledCard>
  );
}
