import { RunTask } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React from "react";
import { ThawForm } from "./ThawForm";

export function Thaw({
  runActivity,
}: {
  runActivity: Maybe<RunTask>;
}): JSX.Element {
  return (
    <div>
      <ThawForm runActivity={runActivity!} handleSubmit={() => void 0} />
    </div>
  );
}
