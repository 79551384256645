import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import React, { FC, memo } from "react";
import { Row } from "react-table";
import {
  ReactTableContextMenuHandler,
  ReactTableRowClickHandler,
  StyledTableCell,
  StyledTableRow,
} from "shared-components/Table/ReactTable";

interface CellProps<TData extends Record<string, unknown>> {
  row: Row<TData>;
  onRowContextMenu?: ReactTableContextMenuHandler<TData>;
  onRowClick?: ReactTableRowClickHandler<TData>;
  selected: TData[];
}
function Cell<TData extends Record<string, unknown>>({
  row,
  onRowClick,
  onRowContextMenu,
  selected,
}: CellProps<TData>): JSX.Element {
  return (
    <StyledTableRow
      {...row.getRowProps()}
      selected={row?.isSelected}
      onContextMenu={
        onRowContextMenu && !row.isGrouped
          ? onRowContextMenu(selected, row)
          : undefined
      }
      clickable={(!!onRowClick).toString() as "true" | "false"}
      onClick={onRowClick ? onRowClick(row) : undefined}
    >
      {
        // Loop over the rows cells
        row.cells.map((cell, idx) => {
          // Apply the cell props
          return (
            <StyledTableCell
              {...cell.getCellProps()}
              style={{
                background: cell.isGrouped
                  ? "#0aff0082"
                  : cell.isAggregated
                  ? "#ffa50078"
                  : cell.isPlaceholder
                  ? "rgba(0,0,0,0.2)"
                  : "",
              }}
              onClick={(e) => {
                if (cell.column.id === "selection") {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              key={idx}
            >
              {cell.isGrouped ? (
                // If it's a grouped cell, add an expander and row count
                <>
                  <span {...row?.getToggleRowExpandedProps()}>
                    {row?.isExpanded ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </span>{" "}
                  {cell.render("Cell")} ({row.subRows.length})
                </>
              ) : cell?.isAggregated ? (
                // If the cell is aggregated, use the Aggregated
                // renderer for cell
                cell.render("Aggregated")
              ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                // Otherwise, just render the regular cell
                cell.render("Cell")
              )}
            </StyledTableCell>
          );
        })
      }
    </StyledTableRow>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RTRow = memo(Cell) as FC<CellProps<any>>;
