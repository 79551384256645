import { DatePicker } from "@mui/lab";
import { Autocomplete, TextField } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import {
  AppSuiteUser,
  FolderStructure,
  Maybe,
  TaskStatusEnum,
  UpdateRunInput,
} from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import {
  InsertableFolderField,
  UserField,
} from "shared-components/FormFields/FormFields";
import {
  FormSubmitButton,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";

export function EditRunForm({
  initialValues,
  onSubmit,
}: {
  initialValues: UpdateRunInput;
  onSubmit: (
    values: UpdateRunInput,
    helpers: FormikHelpers<UpdateRunInput>
  ) => void;
}): JSX.Element {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues,
    onSubmit,
  });
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <TextField
          id={"runName"}
          value={values.runName}
          onChange={handleChange}
          label={"Name"}
          variant={"outlined"}
          fullWidth
          onBlur={handleBlur}
          helperText={errors.runName && touched.runName && errors.runName}
          error={!!(errors.runName && touched.runName && errors.runName)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <UserField
          defaultValue={initialValues.runOwner}
          value={values.runOwner}
          id={"runOwner"}
          onChange={(e, newValue) => {
            setFieldValue(
              "runOwner",
              (newValue as Maybe<AppSuiteUser>)?.userId
            );
          }}
          label={"Owner"}
          variant={"outlined"}
          onBlur={handleBlur}
          helperText={errors.runOwner && touched.runOwner && errors.runOwner}
          error={!!(errors.runOwner && touched.runOwner && errors.runOwner)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <Autocomplete
          id="status-combobox"
          defaultValue={
            Object.values(TaskStatusEnum).filter(
              (status) => status === values.status
            )[0]
          }
          options={Object.values(TaskStatusEnum)}
          style={{ width: "100%" }}
          onChange={(e, newValue) => setFieldValue("status", newValue ?? null)}
          renderInput={(params) => (
            <TextField
              {...params}
              id="status"
              label="Status"
              variant="outlined"
              helperText={errors.status && touched.status && errors.status}
              error={!!(errors.status && touched.status && errors.status)}
            />
          )}
        />
      </StyledFormControl>
      <StyledFormControl>
        <DatePicker
          disabled
          value={values.runDateScheduled}
          onChange={(date) => setFieldValue("runDateScheduled", date)}
          renderInput={(props) => (
            <TextField
              {...props}
              helperText={
                "Change run start date by rescheduling first task in the run"
              }
              error={
                !!(
                  errors.runDateScheduled &&
                  touched.runDateScheduled &&
                  errors.runDateScheduled
                )
              }
              fullWidth
              variant={"outlined"}
              label="Start Date"
            />
          )}
        />
      </StyledFormControl>
      <StyledFormControl>
        <InsertableFolderField
          id="folder-combobox"
          value={values.parentFolderId}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
            setFieldValue(
              "parentFolderId",
              (newValue as Maybe<FolderStructure>)?.folderId
            )
          }
          label="Folder Location"
          variant="outlined"
          helperText={
            errors.parentFolderId &&
            touched.parentFolderId &&
            errors.parentFolderId
          }
          error={
            !!(
              errors.parentFolderId &&
              touched.parentFolderId &&
              errors.parentFolderId
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id={"comments"}
          value={values.comments}
          onChange={handleChange}
          label={"Comments"}
          variant={"outlined"}
          fullWidth
          onBlur={handleBlur}
          helperText={errors.comments && touched.comments && errors.comments}
          error={!!(errors.comments && touched.comments && errors.comments)}
        />
      </StyledFormControl>
      <FormSubmitButton
        type="submit"
        data-testid={"submit-button"}
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Save Changes
      </FormSubmitButton>
    </StyledForm>
  );
}
