import { animated } from "@react-spring/web";
import { CancelTasksForm } from "features/Dashboard/components/Table/CancelTasksForm";
import { EditRunsConfirmModal } from "features/Runs/components/Forms/Confirm/EditRunsConfirmModal";
import { CreateRunModal } from "features/Runs/components/Forms/Create/Run/Modal";
import { AddRunTaskModal } from "features/Runs/components/Forms/Create/RunTask/Modal";
import { EditRunModal } from "features/Runs/components/Forms/Edit/Run/Modal";
import { EditRunActivityModal } from "features/Runs/components/Forms/Edit/Task/Modal";
import { MarkMultipleModal } from "features/Runs/components/Forms/EditMultiple/Task/EditMultipleTasksModal";
import { EditMultipleTaskDetailsModal } from "features/Runs/components/Forms/EditMultiple/TaskDetails/EditMultipleTaskDetailsModal";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { useRunsModalState } from "features/Runs/state/hooks";
import React from "react";
import { useModalTransition } from "shared-components/ModalsAndDialogs/hooks";
import { RescheduleTasksForm } from "shared-components/Reschedule/RescheduleTasksForm";
import { CreateRunFolderModal } from "../Forms/Create/RunFolder/Modal";

export function RunsModalContent(): JSX.Element {
  const {
    runsModalState: { currentModal },
  } = useRunsModalState();
  const transition = useModalTransition(currentModal);
  return transition((props, currentModal) => {
    switch (currentModal) {
      case RunTaskModals.EditRunActivity:
        return (
          <animated.div style={props}>
            <EditRunActivityModal />
          </animated.div>
        );
      case RunTaskModals.EditMultipleRunActivityDetails:
        return (
          <animated.div style={props}>
            <EditMultipleTaskDetailsModal />
          </animated.div>
        );
      case RunTaskModals.ConfirmRunActivity:
        return (
          <animated.div style={props}>
            <EditRunsConfirmModal />
          </animated.div>
        );
      case RunTaskModals.EditMultipleRunActivity:
        return (
          <animated.div style={props}>
            <MarkMultipleModal />
          </animated.div>
        );
      case RunTaskModals.AddRunActivity:
        return (
          <animated.div style={props}>
            <AddRunTaskModal />
          </animated.div>
        );
      case RunTaskModals.CreateRun:
        return (
          <animated.div style={props}>
            <CreateRunModal />
          </animated.div>
        );
      case RunTaskModals.RescheduleTasks:
        return (
          <animated.div style={props}>
            <RescheduleTasksForm />
          </animated.div>
        );
      case RunTaskModals.CancelTasks:
        return (
          <animated.div style={props}>
            <CancelTasksForm />
          </animated.div>
        );
      case RunTaskModals.EditRun:
        return (
          <animated.div style={props}>
            <EditRunModal />
          </animated.div>
        );
      case RunTaskModals.CreateRunFolder:
        return (
          <animated.div style={props}>
            <CreateRunFolderModal />
          </animated.div>
        );
    }
  });
}
