import {
  IPersonalTaskTemplateAction,
  PersonalTaskTemplateAction,
} from "./actions";
import { IPersonalTaskTemplateState } from "./inital-state";

export function PersonalTaskTemplateReducer(
  state: IPersonalTaskTemplateState,
  action: IPersonalTaskTemplateAction
) {
  switch (action.type) {
    case PersonalTaskTemplateAction.SELECT:
      return {
        ...state,
        selectedPersonalTaskTemplateId:
          action.payload?.selectedPersonalTaskTemplateId,
        selectedPersonalTaskTemplateName:
          action.payload.selectedPersonalTaskTemplateName,
        selectedPersonalTasksTemplateTask:
          action.payload.selectedPersonalTasksTemplateTask,
        selectedPersonalTask: {},
      };
    case PersonalTaskTemplateAction.SELECT_TASK:
      return {
        ...state,
        selectedPersonalTask: action.payload?.selectedPersonalTask,
      };
  }
}
