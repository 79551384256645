import {
  ManagePersonalTask,
  PersonalTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { PersonalTaskInputStub } from "mappings/object-stubs/PersonalTaskInput";

export function ManagePersonalTaskInputMapper(
  personalTask: Maybe<ManagePersonalTask>
) {
  const _personalTask = DeepCopy(personalTask);
  return _.pick(
    _personalTask,
    _.keys(new PersonalTaskInputStub())
  ) as PersonalTaskInput;
}
