import { makeVar } from "@apollo/client";
import { debounceWithStore } from "helpers/debounce";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export enum AlertType {
  ERROR = "error",
  SUCCESS = "success",
  WARNING = "warning",
  INFORMATION = "info",
}
export type IAlert = {
  type: AlertType;
  longMessage?: string; //for store
} & (
  | {
      message: string;
      hoc?: never;
    }
  | {
      hoc: () => JSX.Element;
      message?: never;
    }
);

export const AlertStore = makeVar<[alert: IAlert][] | undefined>(undefined);
export const Alert = debounceWithStore(
  (alert: IAlert) =>
    alert?.message
      ? toast(
          (AlertType.SUCCESS === alert.type ? "🎉  " : "").concat(
            alert.message
          ),
          {
            type: alert.type,
            closeOnClick: false,
          }
        )
      : toast(alert.hoc, {
          type: alert.type,
          closeOnClick: false,
        }),
  600,
  AlertStore
).debounced;
