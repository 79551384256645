import * as Yup from "yup";
import {
  Role,
  UpdateAppSuiteUserInput,
} from "graphql/generated/schema-types";

export const UpdateUserValidationSchema =
  Yup.object().shape<UpdateAppSuiteUserInput>({
    roles: Yup.array<Role>()
      .required("Method must have role")
      .min(1, "Method must have role")
      .nullable(),
    isActive: Yup.boolean().required("Required"),
  });
