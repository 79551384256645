import produce from "immer";
import { ResetSelectedSampleTableRows } from "../components/SampleTable/SampleTable";
import { SampleActions } from "./actions";
import { ISampleAction, ISampleState } from "./initial-state";

export function sampleStateReducer(
  state: ISampleState,
  action: ISampleAction
): ISampleState {
  switch (action.type) {
    case SampleActions.OPEN_SAMPLE_DETAILS:
      return produce(state, (draft) => {
        draft.containerSummaryDialogOpen = {
          open: true,
          containerId: action.payload?.containerSummaryDialogOpen?.containerId,
        };
      });
    case SampleActions.OPEN_MODAL:
      return produce(state, (draft) => {
        draft.currentModal = action.payload?.currentModal;
        draft.modalOpen = true;
      });
    case SampleActions.CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = false;
        if (draft!.selectedContainers!.length > 0)
          ResetSelectedSampleTableRows();
      });
    case SampleActions.CLOSE_DIALOG:
      return produce(state, (draft) => {
        draft.containerSummaryDialogOpen = {
          containerId: draft?.containerSummaryDialogOpen?.containerId,
          open: false,
        };
        if (draft!.selectedContainers!.length > 0)
          ResetSelectedSampleTableRows();
      });
    case SampleActions.SELECT:
      return produce(state, (draft) => {
        draft.selectedContainers = action.payload?.selectedContainers;
      });
    default:
      return state;
  }
}
