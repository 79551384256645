import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { FeedMethods } from "constants/feedMethods";
import {
  RunTaskType,
  TaskSelectionTypeEnum,
  useGetRunTaskTypesQuery,
} from "graphql/generated/schema-types";
import { GenericFieldProps } from "./FeedGroupFormRow";

export interface FeedMethodComponentProps extends GenericFieldProps {
  setFieldValue: (field: string, value: any) => void;
  value: number | undefined | null;
}

export const FeedMethod = (props: FeedMethodComponentProps) => {
  const runTaskTypeQuery = useGetRunTaskTypesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const byName = (a: RunTaskType, b: RunTaskType) => {
    if (!a?.name && !b?.name) return 0;
    if (!b?.name || (a?.name && a.name > b.name)) return 1;
    if (!a?.name || (b?.name && a.name < b.name)) return -1;
    return 0;
  };

  const options =
    runTaskTypeQuery.data?.runTaskTypes
      ?.filter(
        (rtt) =>
          rtt?.type === TaskSelectionTypeEnum.Method &&
          FeedMethods.includes(rtt?.id)
      )
      .map((rtt) => rtt as RunTaskType)
      .sort(byName) ?? [];

  const setFieldValues = (taskType: RunTaskType) => {
    props.setFieldValue(`feedGroupArray[${props.index}].methodId`, taskType.id);
    props.setFieldValue(
      `feedGroupArray[${props.index}].automationMethodId`,
      taskType.automationMethodId
    );
    props.setFieldValue(`feedGroupArray[${props.index}].arraySystemId`, 0);
    props.setFieldValue(`feedGroupArray[${props.index}].numberOfPlates`, 0);
  };

  return runTaskTypeQuery.loading ? (
    <CircularProgress />
  ) : (
    <Autocomplete
      disableClearable
      disabled={props.toggleDisabled}
      getOptionLabel={(rtt) =>
        (!rtt.automationMethodId ? "[no ASAM] " : "") + rtt.name!
      }
      id="feedMethod"
      onBlur={props.handleBlur}
      onChange={(_e, value) => setFieldValues(value)}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Feed Method"
          variant="outlined"
          error={!!props.formikErrors}
          helperText={props.formikErrors}
        />
      )}
      value={options.find((e) => e.id === props.value)}
    />
  );
};
