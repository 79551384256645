import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import { MethodReservationForm } from "features/MethodReservations/MethodReservationContainer";
import {
  DashboardTask,
  PersonalTaskAttachment,
  RunTaskAttachment,
  TaskTypeEnum,
  useGetPersonalTaskAttachmentsQuery,
  useGetRunTaskAttachmentsQuery,
} from "graphql/generated/schema-types";
import {
  useGetGroupsById,
  useGetUser,
  useGetUsersById,
} from "hooks/cacheAccessHooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { TaskAttachments } from "./TaskAttachments";
const StyledDialogContent = styled(DialogContent)`
  margin-bottom: 20px;
`;

interface TaskDetailsDialogProp {
  isOpen: boolean;
  handleClose: () => void;
  selectedTask: DashboardTask;
}

export const TaskDetailsDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: TaskDetailsDialogProp) => {
  const history = useHistory();
  const [showAttachments, setShowAttachments] = useState(false);
  const createdByUser = useGetUser(selectedTask?.taskOwnerId);
  const users = useGetUsersById(selectedTask?.assignedUsers);
  const groups = useGetGroupsById(selectedTask?.assignedGroups);

  const goToRunFolder = () => {
    history.push(`runs/${selectedTask.taskOwnerId}`);
  };
  const {
    data: personalTaskAttachments,
    startPolling: pStartPolling,
    stopPolling: pStopPolling,
  } = useGetPersonalTaskAttachmentsQuery({
    variables: {
      taskId: selectedTask.taskId,
    },
  });

  const {
    data: runTaskAttachments,
    startPolling: rStartPolling,
    stopPolling: rStopPolling,
  } = useGetRunTaskAttachmentsQuery({
    variables: {
      taskId: selectedTask.taskId,
    },
  });
  useEffect(() => {
    pStartPolling(2000);
    return () => {
      pStopPolling();
    };
  }, [pStartPolling, pStopPolling]);

  useEffect(() => {
    if (!isOpen) {
      pStopPolling();
    }
  }, [pStopPolling, isOpen]);

  useEffect(() => {
    rStartPolling(2000);
    return () => {
      rStopPolling();
    };
  }, [rStartPolling, rStopPolling]);

  useEffect(() => {
    if (!isOpen) {
      rStopPolling();
    }
  }, [rStopPolling, isOpen]);

  const transition = useTransition(showAttachments, {
    from: {
      maxWidth: "0vw",
      maxHeight: "20vh",
    },
    enter: {
      maxWidth: "60vw",
      maxHeight: "50vh",
    },
    leave: {
      maxWidth: "0vw",
      maxHeight: "0vh",
      overflow: "hidden",
    },
  });

  function getTaskName() {
    let prefix = "";
    switch (selectedTask.taskType) {
      case TaskTypeEnum.Personal:
        break;
      case TaskTypeEnum.IncomingSample:
        if (selectedTask.project) prefix = selectedTask.project.split(":")[0];
        break;
      case TaskTypeEnum.RunTask:
        break; // TODO: add Run name prefix
    }
    const taskName = selectedTask.taskName;
    return prefix ? `${prefix}: ${taskName}` : taskName;
  }

  function getViewFolderButton() {
    if (selectedTask.taskType !== TaskTypeEnum.RunTask) return null;
    return (
      <Button
        sx={{ ml: 2.5 }}
        variant="outlined"
        onClick={() => goToRunFolder()}
      >
        View Folder
      </Button>
    );
  }

  function getCreatedBy() {
    if (!createdByUser || createdByUser.userName === "") return null;
    return (
      <div>
        <p>
          <b>Created By:</b> {createdByUser.userName}
        </p>
      </div>
    );
  }

  function getAssignedUsers() {
    if (!users || users!.length === 0) return null;
    return (
      <div>
        <b>Assigned User(s): </b>
        {users.map((u) => u?.userName).join(", ")}
      </div>
    );
  }

  function getAssignedGroups() {
    if (!groups || groups!.length === 0) return null;
    return (
      <div>
        <b>Assigned Group(s): </b>
        {groups.map((g) => g?.groupName).join(", ")}
      </div>
    );
  }

  function getProject() {
    if (!selectedTask.project) return null;
    return (
      <div>
        <b>Project: </b>
        {selectedTask.project.split(": ")[1]}
      </div>
    );
  }

  function getSampleType() {
    if (!selectedTask.sampleType) return null;
    return (
      <div>
        <b>Sample Type: </b>
        {selectedTask.sampleType.split(": ")[1]}
      </div>
    );
  }

  function getNotes() {
    if (!selectedTask.notes) return null;
    const width =
      selectedTask.notes.length > 100 ? window.outerWidth * 0.5 : "100%";
    return (
      <TextField
        style={{
          margin: "20px 0 5px 0",
          fontSize: 11,
          width: width,
        }}
        disabled
        id="Notes"
        label="Notes"
        multiline
        maxRows={10}
        fullWidth
        variant="filled"
        value={selectedTask.notes}
      />
    );
  }

  function getAttachments() {
    return transition(
      (props, item) =>
        item && (
          <animated.div
            style={{
              display: "flex",
              justifyContent: "center",
              ...props,
            }}
          >
            <TaskAttachments
              runTaskAttachments={
                runTaskAttachments?.runTaskAttachments as RunTaskAttachment[]
              }
              personalTaskAttachments={
                personalTaskAttachments?.personalTaskAttachments as PersonalTaskAttachment[]
              }
              taskId={selectedTask.taskId}
              taskType={selectedTask.taskType}
            />
          </animated.div>
        )
    );
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {getTaskName()}
            {getViewFolderButton()}
          </div>
        </DialogTitle>
        <StyledDialogContent>
          {getCreatedBy()}
          {getAssignedUsers()}
          {getAssignedGroups()}
          {getProject()}
          {getSampleType()}
          <Typography>
            <b>Scheduled Time: </b>
            {moment(selectedTask.startTime).format("MM/DD/YY hh:mm A")}
          </Typography>
          {getNotes()}
          <Box mt={2}>
            <Typography color={"inherit"}>
              <Button onClick={() => setShowAttachments((current) => !current)}>
                {showAttachments ? "Hide" : "View"} Attachments
              </Button>
            </Typography>
          </Box>
          {getAttachments()}
          <MethodReservationForm
            selectedTask={selectedTask}
          ></MethodReservationForm>
        </StyledDialogContent>
      </Dialog>
    </>
  );
};
