import { FC } from "react";
import styled from "styled-components/macro";
import { useGetUsersById } from "hooks/cacheAccessHooks";
import { Pill } from "../../styled-components";

const StyledDiv = styled.div`
  display: flex;
  gap: 5px;
`;
export const MultipleUserCell: FC<{ userIds?: (number | undefined)[] }> = ({
  userIds,
}) => {
  const users = useGetUsersById((userIds?.filter((u) => u) as number[]) ?? []);

  if (!users || users.length === 0) return null;
  return (
    <StyledDiv>
      {users.map((user) => (
        <Pill key={user!.userId} color={"grey"} label={user!.userName!} />
      ))}
    </StyledDiv>
  );
};
