import { FC } from "react";
import styled from "styled-components/macro";
import { useGetGroupsById } from "hooks/cacheAccessHooks";
import { Pill } from "../../styled-components";

const StyledDiv = styled.div`
  display: flex;
  gap: 5px;
`;
export const MultipleGroupCell: FC<{ groupIds?: (number | undefined)[] }> = ({
  groupIds,
}) => {
  const groups = useGetGroupsById(
    (groupIds?.filter((u) => u) as number[]) ?? []
  );
  if (!groups || groups.length === 0) return null;
  return (
    <StyledDiv>
      {groups.map((user) => (
        <Pill key={user!.groupId} color={"grey"} label={user!.groupName!} />
      ))}
    </StyledDiv>
  );
};
