import { Typography } from "@mui/material";
import React from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { WorklistTools } from "../interfaces";
import { getPlateWellIdFromRowAndColIndex } from "../WorklistHelpers";
import PlateWell from "./PlateWell";

const StyledStampPlate = styled.div`
  height: 100%;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0 4px -1px black;
  border-radius: 5px;
  border: 1px solid #ded7e9;
`;

interface StampPlateProps {
  worklistTool: WorklistTools;
  plateRows: number[];
  plateCols: number[];
  selectedWells: any[];
  handleDoubleClick: (wellId: string) => void;
}
const StyledStampPlateElements = styled.div<{
  cols: number[];
  rows: number[];
}>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 4px 4px;
  grid-template-columns: repeat(${(props) => props.cols.length + 1}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows.length + 1}, 1fr);
  justify-items: center;
  align-items: center;
`;

const PlateHeaderCell = styled(Typography)<{
  rowStart: number;
  colStart: number;
}>`
  display: flex;
  grid-row-start: ${(props) => `${props.rowStart}`};
  grid-column-start: ${(props) => `${props.colStart}`};
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 5px;
`;

export function StampPlate({
  worklistTool,
  plateRows,
  plateCols,
  selectedWells,
  handleDoubleClick,
}: StampPlateProps) {
  const getWellColor = (row: number, col: number) => {
    const index = selectedWells.findIndex(
      (val) => val == `${getPlateWellIdFromRowAndColIndex(row, col)}`
    );
    if (index > -1) {
      return "green";
    }
    return "";
  };

  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    menuId: string
  ) => {
    e.preventDefault();
    contextMenu.show({
      id: menuId,
      event: e,
    });
  };

  return (
    <>
      <StyledStampPlate
        onContextMenu={(e) => {
          handleContextMenu(e, `${worklistTool}_Stamp_0`);
        }}
      >
        <StyledStampPlateElements rows={plateRows} cols={plateCols}>
          <PlateHeaderCell rowStart={1} colStart={1}></PlateHeaderCell>
          {plateRows.map((row, index) => (
            <PlateHeaderCell rowStart={row + 1} colStart={1}>
              <p
                style={{
                  fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
                }}
              >
                {String.fromCharCode(64 + row)}
              </p>
            </PlateHeaderCell>
          ))}
          {plateCols.map((col, index) => (
            <PlateHeaderCell rowStart={1} colStart={col + 1}>
              <p
                style={{
                  fontSize: `${plateRows.length < 10 ? "medium" : "x-small"}`,
                }}
              >
                {index + 1}
              </p>
            </PlateHeaderCell>
          ))}
          {plateRows.map((rowItem, rowIndex) =>
            plateCols.map((colItem, colIndex) => (
              <div
                style={{ width: "100%", height: "100%" }}
                onDoubleClick={() =>
                  handleDoubleClick(
                    String.fromCharCode(65 + rowIndex) + String(colIndex + 1)
                  )
                }
              >
                <PlateWell
                  plateIndex={0}
                  platePosition="Dest"
                  plateWellId={
                    String.fromCharCode(65 + rowIndex) + String(colIndex + 1)
                  }
                  wellColor={getWellColor(rowIndex, colIndex)}
                ></PlateWell>
              </div>
            ))
          )}
        </StyledStampPlateElements>
      </StyledStampPlate>
    </>
  );
}
