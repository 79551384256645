import { Maybe } from "graphql/jsutils/Maybe";
import * as _ from "lodash";
import {
  IncomingSampleCollectionFragment,
  UpdateIncomingSampleCollectionInput,
} from "../graphql/generated/schema-types";
import { DeepCopy } from "../helpers/object-helpers";
import { UpdateIncomingSampleCollectionInputStub } from "./object-stubs/IncomingSampleCollectionInput";

export function UpdateIncomingSampleCollectionInputMapper(
  task: Maybe<IncomingSampleCollectionFragment>
) {
  const _task = DeepCopy(task);
  return _.pick(
    _task,
    _.keys(new UpdateIncomingSampleCollectionInputStub())
  ) as UpdateIncomingSampleCollectionInput;
}
