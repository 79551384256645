import { Autocomplete, Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Maybe } from "graphql/jsutils/Maybe";
import { useMemo } from "react";
import {
  SortEnumType,
  TicketFragment,
  useGetActiveTicketsLazyQuery,
} from "graphql/generated/schema-types";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { useTicketState } from "../../../state/hooks";
import { MergeTicketValidationSchema } from "../validation-schema";

export function MergeTicketForm({
  onSubmit,
}: {
  onSubmit: FormikSubmit<{ mergeInto: number }>;
}): JSX.Element {
  const { handleSubmit, errors, touched, setFieldValue, isSubmitting } =
    useFormik<{
      mergeInto: number;
    }>({
      initialValues: {
        mergeInto: 0,
      },
      onSubmit,
      validationSchema: MergeTicketValidationSchema,
    });
  const {
    ticketState: { selectedTickets },
  } = useTicketState();
  const [query, { data: activeTicketQuery, loading }] =
    useGetActiveTicketsLazyQuery();

  useMemo(() => {
    if (selectedTickets?.[0])
      query({
        variables: {
          where: {
            and: [
              {
                ticketId: {
                  neq: selectedTickets[0],
                },
              },
            ],
          },
          order: {
            ticketId: SortEnumType.Desc,
          },
        },
      });
  }, [query, selectedTickets]);
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <Autocomplete
          id="ticketCombobox"
          options={activeTicketQuery?.activeTickets ?? []}
          onChange={(e, value) => {
            setFieldValue(
              "mergeInto",
              (value as Maybe<TicketFragment>)?.ticketId
            );
          }}
          getOptionLabel={(option) =>
            option ? `#${option.ticketId} - ${option.asset?.asset}` : ""
          }
          style={{ margin: "20px 0", width: "100%" }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Merge into"
              variant="outlined"
              helperText={
                errors.mergeInto && touched.mergeInto && errors.mergeInto
              }
              error={
                !!(errors.mergeInto && touched.mergeInto && errors.mergeInto)
              }
            />
          )}
        />
      </StyledFormControl>
      <Button
        type={"submit"}
        variant={"contained"}
        color={"secondary"}
        disabled={isSubmitting}
      >
        Merge
      </Button>
    </StyledForm>
  );
}
