import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetGroupsQuery } from "graphql/generated/schema-types";

export interface AssignedGroupsProps {
  setFieldValue: (field: string, value: any) => void;
}

export const AssignedGroups = (props: AssignedGroupsProps) => {
  const groupsQuery = useGetGroupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={groupsQuery.data?.appSuiteGroups?.map((node) => node) ?? []}
      getOptionLabel={(option) => option!.groupName!}
      style={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Assigned Groups" />
      )}
      onChange={(_e, value) => props.setFieldValue("assignedGroups", value)}
    />
  );
};
