import { Info, Search } from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import React from "react";
import styled, { useTheme } from "styled-components/macro";
import { TicketFilterInput } from "graphql/generated/schema-types";
import { MutateState } from "helpers/object-helpers";
import { useTicketQueryProvider } from "../Context";

const Container = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const reducer = (search: string) => {
  const filter: TicketFilterInput = { or: [] };

  if (!_.isNaN(parseInt(search)))
    filter.or!.push({
      ticketId: { eq: parseInt(search) },
    });

  filter.or!.push({
    description: { contains: search },
  });
  filter.or!.push({
    asset: { asset: { contains: search } },
  });
  filter.or!.push({
    ticketFollowers: { some: { user: { userName: { contains: search } } } },
  });
  filter.or!.push({
    ticketAssignees: { some: { user: { userName: { contains: search } } } },
  });
  filter.or!.push({
    ticketType: { ticketType: { contains: search } },
  });
  return filter;
};
export function SearchEverywhere(): JSX.Element {
  const { refetch, loading, variables } = useTicketQueryProvider()!;
  const theme = useTheme();

  const onSubmit = async ({ search }: { search: string }) => {
    const newVariables = MutateState(variables, (draft) => {
      const filter = draft?.where?.and?.filter((entry) => !entry?.or);
      if (filter) {
        filter.push(reducer(search));
        draft!.where!.and = filter;
      }
    });

    if (newVariables.hasChanged) {
      await refetch(newVariables.newValue);
    }
  };
  const {
    values: { search },
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { search: "" },
    onSubmit,
  });
  return (
    <Container>
      <Tooltip
        title={
          "Searches id, feature, type, platform, description, followers and assignees"
        }
      >
        <Info />
      </Tooltip>
      <form onSubmit={handleSubmit}>
        <TextField
          variant={"outlined"}
          id={"search"}
          value={search}
          size={"small"}
          label={"Search Everywhere"}
          onChange={handleChange}
          autoComplete={"off"}
        />
        <IconButton size={"large"} type={"submit"} disabled={loading}>
          <Search
            color={theme.palette.mode === "dark" ? undefined : "secondary"}
          />
        </IconButton>
      </form>
    </Container>
  );
}
