import { QueryResult } from "@apollo/client";
import { useGetDefaultTicketQueryVariables } from "features/Tickets/components/Table/defaults";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import {
  GetTicketsQuery,
  GetTicketsQueryVariables,
  useGetTicketsQuery,
} from "graphql/generated/schema-types";
import { Wrapper } from "interfaces/Wrapper";
import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

const TicketQueryContext = createContext<
  QueryResult<GetTicketsQuery, GetTicketsQueryVariables> | undefined
>(undefined);

export function TicketContext({ children }: Wrapper): JSX.Element {
  const defaultTicketQueryVariables = useGetDefaultTicketQueryVariables();
  const query = useGetTicketsQuery({
    notifyOnNetworkStatusChange: true,
    variables: defaultTicketQueryVariables,
  });
  const params = useParams<{ ticketId?: string }>();

  const ticket = useMemo(() => {
    const parsed = parseInt(params?.ticketId ?? "0");
    return isNaN(parsed) ? 1 : parsed;
  }, [params?.ticketId]);

  const { ticketDispatch } = useTicketState();

  useEffect(() => {
    if (ticket)
      ticketDispatch({
        type: TicketActions.OPEN_TICKET_DETAILS,
        payload: {
          ticketSummaryDialogOpen: {
            open: true,
            ticketId: ticket,
          },
        },
      });
  }, [ticket, ticketDispatch]);

  return (
    <TicketQueryContext.Provider value={query}>
      {children}
    </TicketQueryContext.Provider>
  );
}

export const useTicketQueryProvider = () => {
  return useContext(TicketQueryContext);
};
