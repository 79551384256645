import { ApolloCache, FetchResult, MutationUpdaterFn } from "@apollo/client";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  CreateRunFolderInput,
  CreateRunFolderMutation,
  DirectoryFolder,
  DirectoryFolderFragment,
  GetAllFoldersDocument,
  GetAllFoldersQuery,
  GetFolderDirectoryDocument,
  GetFolderDirectoryQuery,
  GetInsertableFoldersDocument,
  GetInsertableFoldersQuery,
  useCreateRunFolderMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import { Alert, AlertType } from "shared-components/toast";
import { GetTreeNodeKey } from "../../FolderTree/helpers";

const insertDirectoryNode = (
  directory: Maybe<DirectoryFolder>[],
  newDirectory: DirectoryFolderFragment,
  status: { inserted: boolean }
) => {
  if (!newDirectory.parentId) {
    directory.push(newDirectory);
    return;
  }
  for (const dir of directory) {
    if (
      GetTreeNodeKey(dir) ===
      GetTreeNodeKey({
        id: newDirectory.parentId,
        type: newDirectory.parentType,
      })
    ) {
      if (dir?.subFolders) {
        dir.subFolders.push(newDirectory);
      } else {
        dir!.subFolders = [newDirectory];
      }
      status.inserted = true;
    }
    if (status.inserted) return;
    else if ((dir?.subFolders?.length ?? 0) > 0)
      insertDirectoryNode(dir?.subFolders ?? [], newDirectory, status);
  }
};

function updateDirectoryFolders(
  cache: ApolloCache<CreateRunFolderMutation>,
  mutationResult: FetchResult<CreateRunFolderMutation>
) {
  const directory = cache.readQuery<GetFolderDirectoryQuery>({
    query: GetFolderDirectoryDocument,
  });
  const copy = DeepCopy(directory?.directory ?? []);

  insertDirectoryNode(
    copy ?? [],
    mutationResult.data!.createRunFolder!.directoryFolder!,
    { inserted: false }
  );

  cache.writeQuery<GetFolderDirectoryQuery>({
    data: { directory: copy },
    query: GetFolderDirectoryDocument,
  });
}

function updateAllFolders(
  cache: ApolloCache<CreateRunFolderMutation>,
  mutationResult: FetchResult<CreateRunFolderMutation>
) {
  const allFolders = cache.readQuery<GetAllFoldersQuery>({
    query: GetAllFoldersDocument,
  });
  const copy = DeepCopy(allFolders)?.folders ?? [];

  copy.push(mutationResult.data!.createRunFolder!.folderStructure!);

  cache.writeQuery<GetAllFoldersQuery>({
    data: { folders: copy },
    query: GetAllFoldersDocument,
  });
}

function updateInsertableFolders(
  cache: ApolloCache<CreateRunFolderMutation>,
  mutationResult: FetchResult<CreateRunFolderMutation>
) {
  const insertableFolders = cache.readQuery<GetInsertableFoldersQuery>({
    query: GetInsertableFoldersDocument,
  });
  const copy = DeepCopy(insertableFolders)?.insertableFolders ?? [];

  copy.push(mutationResult.data!.createRunFolder!.folderStructure!);

  cache.writeQuery<GetInsertableFoldersQuery>({
    data: { insertableFolders: copy },
    query: GetInsertableFoldersDocument,
  });
}

const update: MutationUpdaterFn<CreateRunFolderMutation> = (
  cache,
  mutationResult
) => {
  try {
    updateDirectoryFolders(cache, mutationResult);
    updateAllFolders(cache, mutationResult);
    if (mutationResult.data?.createRunFolder?.folderStructure?.isInsertable)
      updateInsertableFolders(cache, mutationResult);
  } catch (e) {
    Alert({
      type: AlertType.ERROR,
      message: "Cache Update Error",
      longMessage: e.toString(),
    });
  }
};

export const useCreateRunFolder = () => {
  const { runsModalDispatch } = useRunsModalState();
  const [createFolder] = useCreateRunFolderMutation();

  return (values: CreateRunFolderInput) => {
    return createFolder({
      variables: {
        input: values,
      },
      update,
    }).then(() => {
      runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
    });
  };
};
