import ForumIcon from "@mui/icons-material/Forum";
import {
  Badge,
  Box,
  Button,
  CardContent,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { Dispatch, FC, SetStateAction } from "react";
import styled from "styled-components/macro";
import { GetTicketInfoQuery } from "graphql/generated/schema-types";
import { useGetUsersById } from "hooks/cacheAccessHooks";
import { TicketPriorityReducer } from "../../../Table/Columns";

const DescriptionContainer = styled.div`
  margin: 10px 0;
  overflow-y: auto;
  max-height: 300px;
`;
const TextButton = styled.div`
  display: inline-block;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
const UserIdsToUserNames: FC<{ userIds: number[] }> = ({ userIds }) => {
  const users = useGetUsersById(userIds);
  return (
    <div style={{ margin: "5px 0" }}>
      {users?.map((user) => user?.userName).join(", ")}
    </div>
  );
};

export function SummaryContent({
  data,
  loading,
  attachmentsTuple: [showAttachments, setShowAttachments],
}: {
  loading: boolean;
  data: Maybe<GetTicketInfoQuery>;
  attachmentsTuple: [boolean, Dispatch<SetStateAction<boolean>>];
}) {
  return (
    <CardContent>
      <DescriptionContainer>
        <Typography whiteSpace={"pre-wrap"}>
          {loading ? (
            <Skeleton variant={"text"} height={"100px"} />
          ) : (
            data?.ticketInfo?.description
          )}
        </Typography>
      </DescriptionContainer>
      <Divider />
      <Box mt={1} mb={1}>
        <Typography>
          <Box fontWeight={"bold"}>Priority</Box>
        </Typography>
        {loading ? (
          <Skeleton />
        ) : (
          TicketPriorityReducer(data?.ticketInfo?.priority)
        )}
      </Box>
      <Divider />
      <Box mt={1} mb={1}>
        <Typography>
          <Box fontWeight={"bold"}>Status</Box>
        </Typography>
        {loading ? <Skeleton /> : data?.ticketInfo?.status}
      </Box>
      <Divider />
      <Box mt={1} mb={1}>
        <Typography>
          <Box fontWeight={"bold"}>Assignees</Box>
        </Typography>
        <Typography>
          {loading ? (
            <Skeleton variant={"text"} />
          ) : (
            <UserIdsToUserNames
              userIds={
                (data?.ticketInfo?.ticketAssignees
                  ?.filter((assignee) => assignee?.userId)
                  .map((assignee) => assignee?.userId) as number[]) ?? []
              }
            />
          )}
        </Typography>
      </Box>
      <Divider />
      <Box mt={1} mb={1}>
        <Typography>
          <Box fontWeight={"bold"}>Followers</Box>
        </Typography>
        <Typography>
          {loading ? (
            <Skeleton variant={"text"} />
          ) : (
            <UserIdsToUserNames
              userIds={
                (data?.ticketInfo?.ticketFollowers
                  ?.filter((assignee) => assignee?.userId)
                  .map((assignee) => assignee?.userId) as number[]) ?? []
              }
            />
          )}
        </Typography>
      </Box>
      <Divider />
      {data?.ticketInfo?.slackMessageTimestamp ? (
        <Box mt={1} mb={1}>
          <Button
            href={`https://nyscf.slack.com/archives/CGAJFS9LZ/p${data?.ticketInfo?.slackMessageTimestamp}`}
            target="_blank"
            color={"inherit"}
            sx={{
              pl: 0,
              fontWeight: "bold",
              fontFamily: "inherit",
              textTransform: "Capitalize",
              fontSize: "inherit",
            }}
          >
            View Slack Thread <ForumIcon sx={{ ml: 1 }} />
          </Button>
        </Box>
      ) : null}
      <Divider />
      <Box mt={2}>
        <Typography color={"inherit"}>
          <Badge
            badgeContent={data?.ticketInfo?.ticketAttachments?.length}
            color="primary"
          >
            <TextButton
              onClick={() => setShowAttachments((current) => !current)}
            >
              {showAttachments ? "Hide" : "View"} Attachments
            </TextButton>
          </Badge>
        </Typography>
      </Box>
    </CardContent>
  );
}
