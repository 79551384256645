import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AddRackOptions } from "features/TubeLabeller/constants";
import {
  Maybe,
  RunTaskDetail,
  useGetRunTasksForTubeLabelsQuery,
} from "graphql/generated/schema-types";
import moment from "moment";

interface RunTaskSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

const getStepFromFreezeDownRackType = (runTaskDetail: Maybe<RunTaskDetail>) => {
  const rackMap = new Map<string, string>();
  rackMap.set("REPR", "REPRO");
  rackMap.set("REPO", "REPOS");

  const freezeDownRackType = runTaskDetail?.freezeDownRackType ?? "";

  if (
    runTaskDetail &&
    !runTaskDetail.freezeDownRackType &&
    runTaskDetail.createMystplate
  ) {
    return "MYST";
  }
  return rackMap.has(freezeDownRackType)
    ? rackMap.get(freezeDownRackType)
    : freezeDownRackType;
};

export const RunTaskSelector = ({
  values,
  setFieldValue,
}: RunTaskSelectorProps) => {
  const { data: runTasks } = useGetRunTasksForTubeLabelsQuery({
    variables: {
      plateBarcode: values.run,
      showCompleted: values.showCompleted,
    },
  });

  const handleRunTaskSelection = (e: SelectChangeEvent<number>) => {
    const runTaskId = e.target.value;
    const selectedRunTask = runTasks?.runTasksForTubeLabels?.find(
      (runTask) => runTask?.runTaskId === runTaskId
    );
    const step = getStepFromFreezeDownRackType(
      selectedRunTask?.runTaskDetail ?? ({} as RunTaskDetail)
    );
    setFieldValue("runTaskId", runTaskId);
    setFieldValue("step", step);
    setFieldValue("date", selectedRunTask?.startTimeScheduled);
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Run Task</InputLabel>
      <Select
        labelId="run-task-label"
        label="Run Task"
        id="runTask"
        value={values.runTaskId}
        onChange={(e) => handleRunTaskSelection(e)}
      >
        {runTasks?.runTasksForTubeLabels?.map((item) => (
          <MenuItem key={item?.runTaskId} value={item?.runTaskId}>
            {item?.activityName ?? ""} -{" "}
            {moment(item?.startTimeScheduled).format("MM/DD/YYYY") ?? ""}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
