import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormikErrors } from "formik";
import { CreateIncomingSampleTypeInput } from "graphql/generated/schema-types";
import _ from "lodash";
import { v4 as uuid } from "uuid";

interface ScheduleSampleState {
  dateOfArrival: Date | null;
  store: Array<{
    key: string;
    sample: CreateIncomingSampleTypeInput;
    error: FormikErrors<CreateIncomingSampleTypeInput>;
  }>;
}

export const scheduleSampleInitialState: ScheduleSampleState = {
  dateOfArrival: null,
  store: [],
};

export const ScheduleIncomingSampleStoreSlice = createSlice({
  name: "schedule-incoming-samples-form",
  initialState: scheduleSampleInitialState,
  reducers: {
    INIT: () => {
      return scheduleSampleInitialState;
    },
    ADDED_SAMPLE: (state) => {
      state.store.push({
        key: uuid(),
        sample: {
          collectionId: 0,
          sampleTypeId: 0,
          quantity: 1,
          dateToProcess: state.dateOfArrival,
        },
        error: {},
      });
    },
    REMOVED_SAMPLE: (state, action: PayloadAction<string>) => {
      const index = state.store.findIndex(
        (entry) => entry.key === action.payload
      );
      state.store.splice(index, 1);
    },
    SET_ERROR: (
      state,
      action: PayloadAction<
        Array<{
          key: string;
          error: FormikErrors<CreateIncomingSampleTypeInput>;
        }>
      >
    ) => {
      for (const payload of action.payload) {
        const _entry = state.store.find((entry) => entry.key === payload.key);
        if (_entry) _entry.error = payload.error;
      }
    },
    SET_DATE_OF_ARRIVAL: (state, action: PayloadAction<Date>) => {
      state.dateOfArrival = action.payload;
      for (const entry of state.store) {
        entry.sample.dateToProcess = action.payload;
      }
    },
    SET_ENTRY_VALUE: (
      state,
      action: PayloadAction<{
        key: string;
        values: CreateIncomingSampleTypeInput;
      }>
    ) => {
      const _entry = state.store.find(
        (entry) => entry.key === action.payload.key
      );
      if (_entry && !_.isEqual(_entry.sample, action.payload.values)) {
        _entry.sample = action.payload.values;
        _entry.error = {};
      }
    },
  },
});

export const ScheduleIncomingSampleFormReducer =
  ScheduleIncomingSampleStoreSlice.reducer;

export const ScheduleIncomingSampleFormActions =
  ScheduleIncomingSampleStoreSlice.actions;
