import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import { Tooltip } from "@mui/material";
import { StyledIconButton } from "features/WorklistTools/shared/components/styles/styled-components";
import { UploadWorklist } from "features/WorklistTools/shared/components/UploadWorklist";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import { useState } from "react";
import { ThawToolActions } from "../../state";
import {
  parseUploadedWorklist,
  UploadedWorklist,
} from "../handlers/HandleUploadWorklist";

export const UploadWorklistButton = () => {
  const [uploadWorklistOpen, setUploadWorklistOpen] = useState(false);
  const dispatch = useAppReduxDispatch();

  const handleUploadWorklistClose = async (
    uploadWorklist: boolean,
    worklistRows: UploadedWorklist[]
  ) => {
    setUploadWorklistOpen(false);
    if (uploadWorklist) return;

    const parsedWorklistInfo = await parseUploadedWorklist(worklistRows);
    console.log(parsedWorklistInfo);
    dispatch(
      ThawToolActions.UPLOAD_SOURCE_AND_DEST_PLATE_INFO({
        sourcePlateInfo: parsedWorklistInfo.sourcePlateInfo,
        destPlateInfo: parsedWorklistInfo.destPlateInfo,
      })
    );
    dispatch(
      ThawToolActions.UPLOAD_WORKLIST_MAPPING({
        thawTransfer: parsedWorklistInfo.thawTransfer,
      })
    );
  };

  return (
    <>
      <StyledIconButton onClick={() => setUploadWorklistOpen(true)}>
        <Tooltip title="Upload Worklist" placement="right">
          <UploadOutlinedIcon />
        </Tooltip>
      </StyledIconButton>

      <UploadWorklist
        isOpen={uploadWorklistOpen}
        handleClose={(uploadWorklist, worklistRows) =>
          handleUploadWorklistClose(uploadWorklist, worklistRows)
        }
      />
    </>
  );
};
