import _ from "lodash";
import React, { useMemo } from "react";
import {
  UpdateMethodInput,
  useUpdateMethodMutation,
} from "graphql/generated/schema-types";
import { useGetMethod } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import { MethodInputMapper } from "mappings/methodInput";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { MethodManagerActions } from "features/MethodManager/state/actions";
import { useMethodManagerState } from "features/MethodManager/state/hooks";
import { EditMethodForm } from "./Form";

const RequiredFields: (keyof UpdateMethodInput)[] = ["isActive"];
export function EditMethodModal(): JSX.Element {
  const windowSize = useWindowSize();
  const {
    methodManagerDispatch,
    methodManagerState: { selectedMethods },
  } = useMethodManagerState();
  const [updateMethod] = useUpdateMethodMutation();
  const method = useGetMethod(selectedMethods![0]!);
  const input = useMemo(() => MethodInputMapper(method), [method]);

  function excludeUnchangedFields(values: UpdateMethodInput) {
    for (const key in input) {
      const _key = key as keyof UpdateMethodInput;
      if (
        _.isEqual(input[_key], values[_key]) &&
        !RequiredFields.includes(_key)
      )
        values[_key] = undefined;
    }
  }

  const handleSubmit = (values: UpdateMethodInput) => {
    excludeUnchangedFields(values);
    return updateMethod({
      variables: {
        inputs: [{ primaryKey: selectedMethods![0]!, set: values }],
      },
    })
      .then(() =>
        methodManagerDispatch({ type: MethodManagerActions.CLOSE_MODAL })
      )
      .then(async () => {
        Alert({ type: AlertType.SUCCESS, message: "Changes Saved" });
      });
  };

  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "15vw"
          : windowSize.width! > 1500
          ? "25vw"
          : "40vw"
      }
    >
      <EditMethodForm initialValues={input} onSubmit={handleSubmit} />
    </StyledCard>
  );
}
