import { makeVar } from "@apollo/client";
import { SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { WellSelection } from "../../shared/interfaces";

export interface ITwelve24TCMethodSettings {
  selectedSystem: number;
  arraySystemAutomationMethodId: number;
  aliquotVolume: number;
  dissociationTime: number;
  spinParamGForce: number;
  spinParamPercent: number;
  spinTime: number;
  spinAccel: number;
  spinDecel: number;
  dissociationWashRGT: number;
  dissociation: boolean;
  washBeforeDissociation: boolean;
  defaultVSpinSettings: boolean;
  pelletResuspensionVol: number | null;
  pelletResuspensionRGT: number;
  manualSpin: number;
  discardSourcePlate: number;
}

export interface ITwelve24TCToolInternalState {
  destSelectionProcess: SelectionProcess;
  selectedSourceWells: WellSelection[];
  requiredWarnings: string[];
  optionalWarnings: string[];
  worklist: any[];
  worklistPreviewOpen: boolean;
  hoverWellId: string;
  methodSettings: ITwelve24TCMethodSettings;
}

export const Twelve24TCToolInternalState =
  makeVar<ITwelve24TCToolInternalState>({
    destSelectionProcess: SelectionProcess.ColWise,
    selectedSourceWells: [],
    requiredWarnings: [],
    optionalWarnings: [],
    worklist: [],
    worklistPreviewOpen: false,
    hoverWellId: "",
    methodSettings: {
      selectedSystem: 0,
      arraySystemAutomationMethodId: 391,
      aliquotVolume: 10,
      dissociationTime: 0,
      spinParamGForce: 0,
      spinParamPercent: 0,
      spinTime: 0,
      spinAccel: 0,
      spinDecel: 0,
      dissociationWashRGT: 0,
      dissociation: true,
      defaultVSpinSettings: false,
      washBeforeDissociation: false,
      pelletResuspensionVol: null,
      pelletResuspensionRGT: 0,
      manualSpin: 0,
      discardSourcePlate: -1,
    },
  });
