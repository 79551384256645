import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useGetUserSettings } from "hooks/useGetUserSettings";
import { useGetSaveNotificationSettings } from "hooks/useSaveUserSettings";
import {
  StyledCard,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components";
import { NotificationSettings } from "../Settings";
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const NotificationSettingScreens = () => {
  const userSettings = useGetUserSettings();
  const [saveNotificationSettings, loading] = useGetSaveNotificationSettings();
  const initialValues: NotificationSettings = userSettings?.notifications ?? {};
  const handleSubmit = async (
    values: NotificationSettings,
    { setSubmitting }: FormikHelpers<NotificationSettings>
  ) => {
    console.log(values);
    await saveNotificationSettings(values).then(() => {
      Alert({
        type: AlertType.SUCCESS,
        message: "Updated Notification Settings",
      });
    });
    localStorage.notifications = JSON.stringify(values);
    setSubmitting(false);
  };
  return (
    <Container>
      <StyledCard width={"350px"} height={"300px"}>
        <Formik
          style={{ padding: "0" }}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: FormikProps<NotificationSettings>) => (
            <StyledForm onSubmit={handleSubmit}>
              <Typography>Receive Notifications when: </Typography>
              <StyledFormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.methodPushedBack ?? true}
                      onChange={(e) =>
                        setFieldValue("methodPushedBack", e.target.checked)
                      }
                    />
                  }
                  label="Methods associated with me are pushed back"
                />
              </StyledFormControl>
              <StyledFormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.personalTaskAssignedToMe ?? true}
                      onChange={(e) =>
                        setFieldValue(
                          "personalTaskAssignedToMe",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Personal Tasks are Assigned To Me"
                />
              </StyledFormControl>
              <StyledFormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                >
                  Save Settings
                </Button>
              </StyledFormControl>
            </StyledForm>
          )}
        </Formik>
      </StyledCard>
    </Container>
  );
};
