import { makeStyles } from "@mui/styles";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { useAppReduxDispatch } from "hooks/reduxHooks";
import {
  DestPlateInfoState,
  DestPlateStringKeys,
  Twelve24TCToolActions,
} from "../../state";

interface TopupDestProps {
  destPlateInfo: DestPlateInfoState;
  pelletResuspensionVol: number | null;
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));
export const TopUp = ({
  destPlateInfo,
  pelletResuspensionVol,
}: TopupDestProps) => {
  const classes = useStyles();
  const dispatch = useAppReduxDispatch();
  const handleDestPlateSettingsChanged = (
    index: number,
    key: DestPlateStringKeys,
    value: string | number | boolean
  ) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO({
        key,
        value,
      })
    );
  };

  return (
    <>
      <NumberField
        size="small"
        id="top-up-vol"
        style={{ width: "100%", marginTop: 15 }}
        label={"Final Topped Up Volume"}
        value={destPlateInfo.topupVol}
        onChange={(value) => {
          handleDestPlateSettingsChanged(0, "topupVol", parseInt(value));
        }}
        minValue={0}
        maxValue={900} //Can't be hardcoded
        errorMessage={
          destPlateInfo.topupVol === 0
            ? "Top Up Volume can't be 0"
            : isNaN(destPlateInfo.topupVol)
            ? "Top Up Volume can't be empty"
            : ""
        }
        disabled={pelletResuspensionVol === 0}
      />
    </>
  );
};
