import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid } from "@mui/material";
import { FormikErrors } from "formik";
import { FeedGroupInput } from "graphql/generated/schema-types";
import { DeepCopy } from "helpers/object-helpers";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { AssignedSystem } from "./AssignedSystem";
import { FeedGroupName } from "./FeedGroupName";
import { FeedMethod } from "./FeedMethod";
import { NumberOfPlates } from "./NumberOfPlates";
import { SelectStartTime } from "./SelectStartTime";

export interface GenericFieldProps {
  formikErrors?: string | undefined;
  handleBlur: (fieldOrEvent: any) => void;
  index: number;
  toggleDisabled: boolean;
}
export interface FeedGroupFormRowProps {
  asams: any;
  deleteRowHelper: (index: number) => void;
  errorMessageObj: (index: number) => any;
  formValues: any;
  feedGroupsToDelete: FeedGroupInput[];
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (fieldOrEvent: any) => void;
  index: number;
  setFeedGroupsToDelete: Dispatch<SetStateAction<FeedGroupInput[]>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<FormikErrors<{ feedGroupArray: FeedGroupInput[] }>>;
  toggleDisabled: boolean;
}

export const FeedGroupFormRow = ({
  asams,
  deleteRowHelper,
  errorMessageObj,
  feedGroupsToDelete,
  formValues,
  handleChange,
  handleBlur,
  index,
  setFeedGroupsToDelete,
  setFieldValue,
  toggleDisabled,
}: FeedGroupFormRowProps) => {
  const deleteHandler = (indexOfDeleted: number) => {
    const deletedFeedGroup = DeepCopy(
      formValues.feedGroupArray[indexOfDeleted]
    );
    if (deletedFeedGroup.feedGroupId !== null) {
      deletedFeedGroup.isActive = false;
      setFeedGroupsToDelete([...feedGroupsToDelete, deletedFeedGroup]);
    }
    deleteRowHelper(indexOfDeleted);
  };

  return (
    <Grid container spacing={2} key={index}>
      <Grid item xs={2}>
        <FeedGroupName
          formikErrors={errorMessageObj(index)?.feedGroupName}
          handleBlur={handleBlur}
          handleChange={handleChange}
          index={index}
          toggleDisabled={toggleDisabled}
          value={formValues.feedGroupArray[index].feedGroupName}
        />
      </Grid>

      <Grid item xs={2.75}>
        <FeedMethod
          formikErrors={errorMessageObj(index)?.methodId}
          handleBlur={handleBlur}
          index={index}
          setFieldValue={setFieldValue}
          toggleDisabled={toggleDisabled}
          value={formValues.feedGroupArray[index].methodId}
        />
      </Grid>

      <Grid item xs={2}>
        <AssignedSystem
          asams={asams}
          automationMethodId={
            formValues.feedGroupArray[index].automationMethodId
          }
          formikErrors={errorMessageObj(index)?.arraySystemId}
          handleBlur={handleBlur}
          index={index}
          setFieldValue={setFieldValue}
          toggleDisabled={toggleDisabled}
          value={formValues.feedGroupArray[index].arraySystemId}
        />
      </Grid>

      <Grid item xs={1.5}>
        <NumberOfPlates
          formikErrors={errorMessageObj(index)?.numberOfPlates}
          handleBlur={handleBlur}
          handleChange={handleChange}
          index={index}
          toggleDisabled={toggleDisabled}
          value={formValues.feedGroupArray[index].numberOfPlates}
        />
      </Grid>

      <Grid item xs={2}>
        <SelectStartTime
          formikErrors={errorMessageObj(index)?.startTimeScheduled}
          handleBlur={handleBlur}
          index={index}
          setFieldValue={setFieldValue}
          toggleDisabled={toggleDisabled}
          value={formValues.feedGroupArray[index].startTimeScheduled}
        />
      </Grid>
      <Grid item xs={0.25}>
        <Button
          disabled={toggleDisabled}
          onClick={() => {
            deleteHandler(index);
          }}
          sx={{ mt: 0.8 }}
        >
          <DeleteIcon color={toggleDisabled ? "disabled" : "primary"} />
        </Button>
      </Grid>
    </Grid>
  );
};
