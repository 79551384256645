import { PreviousModal } from "features/Runs/components/Forms/Confirm/EditRunsConfirmModal";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { ResetSelectedRunTableRows } from "features/Runs/components/Table/RunsTable";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  IRunsModalAction,
  IRunsModalState,
} from "features/Runs/state/modal/initial-state";

export function runsModalReducer(
  state: IRunsModalState,
  action: IRunsModalAction
): IRunsModalState {
  switch (action.type) {
    // ------------------------------------------------------------- Modal State --------------------------------------------------------------------------------
    case RunsModalActions.SELECT:
      return { ...state, selectedTasks: action.payload!.selectedTasks };
    case RunsModalActions.OPEN_MODAL:
      if (action.payload!.currentModal !== RunTaskModals.ConfirmRunActivity)
        PreviousModal(action.payload!.currentModal);
      return {
        ...state,
        modalOpen: true,
        currentModal: action.payload!.currentModal,
        selectedTasks: action.payload?.selectedTasks ?? state.selectedTasks,
        selectedRunId: action.payload?.selectedRunId,
        selectedFolderId: action.payload?.selectedFolderId,
      };
    case RunsModalActions.SUBMITTED_EDIT_FORM:
      return {
        ...state,
        currentModal: RunTaskModals.ConfirmRunActivity,
        editedFieldValuePairs: action.payload!.editedFieldValuePairs,
      };
    case RunsModalActions.CLOSE_MODAL:
      ResetSelectedRunTableRows();
      return {
        ...state,
        modalOpen: false,
        selectedTasks: [],
      };
    case RunsModalActions.RESET_SELECTION:
      return { ...state, selectedTasks: [] };
    case RunsModalActions.CONFIRMED_CHANGES:
      return {
        ...state,
        modalOpen: false,
        selectedTasks: [],
      };
    // ------------------------------------------------------------- Dialog State --------------------------------------------------------------------------------
    case RunsModalActions.OPEN_TASK_SUMMARY_DIALOG:
      return {
        ...state,
        taskSummaryDialogOpen: action.payload?.taskSummaryDialogOpen,
      };
    case RunsModalActions.OPEN_DELETE_DIALOG:
      return {
        ...state,
        deleteDialog: action?.payload?.deleteDialog,
      };
    case RunsModalActions.CLOSE_DIALOG:
      ResetSelectedRunTableRows();
      return {
        ...state,
        deleteDialog: {
          open: false,
        },
      };
    default:
      return state;
  }
}
