import { Badge, Tab, Tabs } from "@mui/material";
import {
  GetDashboardTasksQuery,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import { ChangeEvent } from "react";
import { DashboardTypeTabs } from "../shared/interfaces";
import { DashboardActions } from "../state/action";
import { useDashboardState } from "../state/hooks";
import { IDashboardState } from "../state/initial-state";

interface DashboardTabsProps {
  refetch: any;
  data: GetDashboardTasksQuery | undefined;
}

export const DashboardTabs = ({ refetch, data }: DashboardTabsProps) => {
  const {
    dashboardDispatch,
    dashboardState: { tabIndex, dashboardTasks, queryVariables },
  } = useDashboardState();

  const TabFilters = async (currentTab: number) => {
    const callDashboardDispatch = (payload: IDashboardState) =>
      dashboardDispatch({
        type: DashboardActions.UPDATE_DASHBOARD_TASKS,
        payload: payload,
      });

    switch (currentTab) {
      case DashboardTypeTabs.OverdueTasks:
        await refetch().then((data: any) => {
          callDashboardDispatch({
            dashboardTasks: data!.data!.getOverdueTasks!,
            queryVariables: "getOverdueTasks",
          });
        });
        break;
      case DashboardTypeTabs.MyTasks:
        await refetch().then((data: any) => {
          callDashboardDispatch({
            dashboardTasks: data!.data!.getMyTasks!,
            queryVariables: "getMyTasks",
          });
        });
        break;
      case DashboardTypeTabs.GroupTasks:
        await refetch().then((data: any) => {
          callDashboardDispatch({
            dashboardTasks: data!.data!.getGroupTasks!,
            queryVariables: "getGroupTasks",
          });
        });
        break;
      case DashboardTypeTabs.LaterThisWeekTasks:
        await refetch().then((data: any) => {
          callDashboardDispatch({
            dashboardTasks: data!.data!.getLaterThisWeekTasks,
            queryVariables: "getLaterThisWeekTasks",
          });
        });
        break;
    }
  };

  const handleTabChange = (event: ChangeEvent<unknown>, tabIndex: number) => {
    TabFilters(tabIndex);
    dashboardDispatch({
      type: DashboardActions.UPDATE_TAB_INDEX,
      payload: {
        tabIndex,
      },
    });
  };
  const overdueExcludingCancelled = () => {
    const nonCancelledTasks = data?.getOverdueTasks?.filter(
      (task) => task?.status !== "CANCELLED"
    );
    return nonCancelledTasks?.length ?? 0;
  };

  return (
    <Tabs
      style={{ flex: "1 1 auto" }}
      variant="scrollable"
      value={tabIndex}
      onChange={handleTabChange}
      aria-label="simple tabs example"
      indicatorColor={"secondary"}
    >
      <Tab
        label={
          <Badge badgeContent={overdueExcludingCancelled()} color="primary">
            Overdue Tasks
          </Badge>
        }
      />
      <Tab
        label={
          <Badge
            badgeContent={
              data?.getMyTasks?.filter(
                (e) => e!.status === TaskStatusEnum.Scheduled
              ).length ?? 0
            }
            color="secondary"
          >
            My Tasks
          </Badge>
        }
      />
      <Tab
        label={
          <Badge
            badgeContent={
              data?.getGroupTasks?.filter(
                (e) => e!.status === TaskStatusEnum.Scheduled
              ).length ?? 0
            }
            color="secondary"
          >
            Group Tasks
          </Badge>
        }
      />
      <Tab
        label={
          <Badge
            badgeContent={data?.getLaterThisWeekTasks?.length ?? 0}
            color="secondary"
          >
            Later this Week
          </Badge>
        }
      />
    </Tabs>
  );
};
