import { useReactiveVar } from "@apollo/client";
import { Box, Button, Card, Paper, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useHandleTaskClick } from "features/PersonalTasks/components/PersonalTaskTemplate/components/table/handlers";
import { CardTitle } from "features/RunTemplate/components/Timeline/TaskCard/TaskCard";
import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { PersonalTaskTemplateState } from "../../state/inital-state";

interface PersonalTaskTemplateTimelineProps {
  setAddTemplateTaskOpen: Dispatch<SetStateAction<boolean>>;
}

const PlaceHolderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
const PlaceHolderTextBG = styled.div``;
const PlaceHolderText = styled.div`
  height: 100%;
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  justify-content: center;
  align-items: center;
`;

const PlaceHolder = () => (
  <PlaceHolderContainer>
    <PlaceHolderTextBG>
      <PlaceHolderText>Select Template</PlaceHolderText>
    </PlaceHolderTextBG>
  </PlaceHolderContainer>
);

export const PersonalTaskTemplateTimeline = ({
  setAddTemplateTaskOpen,
}: PersonalTaskTemplateTimelineProps) => {
  const personalTaskTemplateState = useReactiveVar(PersonalTaskTemplateState);
  console.log(personalTaskTemplateState);

  const handleTaskClick = useHandleTaskClick();

  const handleAddTaskClick = () => {
    setAddTemplateTaskOpen(true);
  };

  // const handleTaskClick = (click: any) => {
  //   console.log(click)
  // }
  return (
    <React.Fragment>
      <Timeline>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          {personalTaskTemplateState.selectedPersonalTaskTemplateName}
        </Typography>
        {personalTaskTemplateState?.selectedPersonalTaskTemplateId ? (
          <div
            style={{
              margin: "10px",
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button variant="outlined" onClick={handleAddTaskClick}>
              Add Task
            </Button>
          </div>
        ) : null}
        {personalTaskTemplateState?.selectedPersonalTaskTemplateId ? (
          personalTaskTemplateState!.selectedPersonalTasksTemplateTask!.map(
            (entry, index) => (
              <div onClick={handleTaskClick(entry)}>
                <TimelineItem style={{ width: "60%" }}>
                  <TimelineOppositeContent>
                    <Typography color="textPrimary" whiteSpace={"pre-wrap"}>
                      day {entry.dayScheduled}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3}>
                      <Card elevation={0} style={{ cursor: "pointer" }}>
                        <CardTitle margin={"20px"}>
                          <Typography
                            color={
                              personalTaskTemplateState.selectedPersonalTask
                                .personalTaskTemplateTaskId ==
                              entry.personalTaskTemplateTaskId
                                ? "primary"
                                : undefined
                            }
                          >
                            <Box fontWeight={"fontWeightBold"}>
                              {entry.personalTaskName}
                            </Box>
                          </Typography>
                        </CardTitle>
                      </Card>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </div>
            )
          )
        ) : (
          <PlaceHolder />
        )}
      </Timeline>
    </React.Fragment>
  );
};
