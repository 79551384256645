import { ClassNameMap, Grid, Switch, Tooltip } from "@mui/material";
import { FormikErrors } from "formik";
import { CalendarSettings } from "./Settings";

export interface TimeFormatToggleProps {
  classes: ClassNameMap<"container">;
  formValues: CalendarSettings;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CalendarSettings>>;
}

export const TimeFormatToggle = ({
  classes,
  formValues,
  setFieldValue,
}: TimeFormatToggleProps) => {
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      spacing={1}
      classes={{ container: classes.container }}
    >
      <Grid item>12 hr time</Grid>
      <Grid item>
        <Switch
          color={"primary"}
          checked={formValues.timeFormat === "military"}
          size={"medium"}
          onChange={(e) => {
            setFieldValue(
              "timeFormat",
              e.target.checked ? "military" : "standard"
            );
          }}
        />
      </Grid>
      <Tooltip title={"24 hour time"} arrow placement="right">
        <Grid item>Military time</Grid>
      </Tooltip>
    </Grid>
  );
};
