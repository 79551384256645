import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Tooltip } from "@mui/material";
import { StyledIconButton } from "features/WorklistTools/shared/components/styles/styled-components";
import {
  OperationNames,
  useDeleteExistingMethodReservationMutation,
} from "graphql/generated/schema-types";

export const DeleteReservationButton = ({
  methodReservationId,
}: {
  methodReservationId: number;
}) => {
  const [deleteMethodReservation] =
    useDeleteExistingMethodReservationMutation();

  const handleDeleteMethodReservationClick = () => {
    deleteMethodReservation({
      variables: {
        methodReservationId,
      },
      refetchQueries: [OperationNames.Query.getExistingMethodReservation],
    });
  };
  return (
    <>
      <StyledIconButton
        disabled={methodReservationId === 0}
        onClick={() => handleDeleteMethodReservationClick()}
      >
        <Tooltip title="Delete Existing Reservation" placement="right">
          <DeleteForeverIcon />
        </Tooltip>
      </StyledIconButton>
    </>
  );
};
