import _ from "lodash";
import React, { useMemo } from "react";
import {
  UpdateManualTaskInput,
  UpdateMethodInput,
  useUpdateManualTaskTypeMutation,
} from "graphql/generated/schema-types";
import { useGetManualTask } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import { ManualTaskInputMapper } from "mappings/manualTaskInput";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { MethodManagerActions } from "features/MethodManager/state/actions";
import { useMethodManagerState } from "features/MethodManager/state/hooks";
import { EditManualTaskForm } from "./Form";

export function EditManualTaskModal(): JSX.Element {
  const windowSize = useWindowSize();
  const {
    methodManagerDispatch,
    methodManagerState: { selectedManualTaskTypes },
  } = useMethodManagerState();
  const [updateManualTasks] = useUpdateManualTaskTypeMutation();
  const manualTask = useGetManualTask(selectedManualTaskTypes![0]!);
  const input = useMemo(() => ManualTaskInputMapper(manualTask), [manualTask]);

  function excludeUnchangedFields(values: UpdateManualTaskInput) {
    for (const key in input) {
      const _key = key as keyof UpdateManualTaskInput;
      if (_.isEqual(input[_key], values[_key])) values[_key] = undefined;
    }
  }

  const handleSubmit = (values: UpdateMethodInput) => {
    excludeUnchangedFields(values);
    return updateManualTasks({
      variables: {
        inputs: [{ primaryKey: selectedManualTaskTypes![0]!, set: values }],
      },
    })
      .then(() =>
        methodManagerDispatch({ type: MethodManagerActions.CLOSE_MODAL })
      )
      .then(async () => {
        Alert({ type: AlertType.SUCCESS, message: "Changes Saved" });
      });
  };

  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "15vw"
          : windowSize.width! > 1500
          ? "25vw"
          : "40vw"
      }
    >
      <EditManualTaskForm initialValues={input} onSubmit={handleSubmit} />
    </StyledCard>
  );
}
