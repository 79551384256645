import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ITwelve24TCMethodSettings } from "features/WorklistTools/1224TC/state/initial-state";
import { IPoolingMethodSettings } from "features/WorklistTools/PoolingNormalization/state/initial-state";
import {
  GetLabwareTypesQuery,
  useGetLabwareTypesLazyQuery,
  useGetSourcePlatesLazyQuery,
} from "graphql/generated/schema-types";
import React, { ChangeEvent, ReactNode, useEffect } from "react";

interface UploadSourcePlateDialogProps {
  isOpen: boolean;
  handleClose: any;
  handleUpload: any;
  queryVariables: any;
  title: string;
  isUploadingIntermediate?: boolean;
  subtitle?: string;
  children?: ReactNode;
  methodSettings?: IPoolingMethodSettings | ITwelve24TCMethodSettings;
  updateMethodSettings?: (key: string, value: string | number) => void;
}

export interface UploadedSourcePlate {
  plateCreatedId: number;
  plateBarcode: string;
  labwareTypeId?: number;
}

export const UploadSourcePlateDialog = ({
  isOpen,
  handleClose,
  handleUpload,
  queryVariables,
  title,
  isUploadingIntermediate,
  methodSettings,
  updateMethodSettings,
}: UploadSourcePlateDialogProps) => {
  const [selectedPlate, setSelectedPlate] =
    React.useState<UploadedSourcePlate>();
  const [getPlates, { data: sourcePlates }] = useGetSourcePlatesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getLabwareTypeInfo, { data }] = useGetLabwareTypesLazyQuery({});

  useEffect(() => {
    getPlates({
      variables: queryVariables,
    });
  }, [queryVariables]);

  const handlePlateTypeChange = (plate: UploadedSourcePlate) => {
    if (plate) {
      getLabwareTypeInfo({
        variables: {
          where: {
            labwareTypeId: { eq: plate.labwareTypeId },
          },
        },
      });
      setSelectedPlate({
        plateCreatedId: plate.plateCreatedId,
        plateBarcode: plate.plateBarcode,
        labwareTypeId: plate.labwareTypeId,
      });
    }
  };

  const handleUploadSourcePlateClose = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedPlate: UploadedSourcePlate | undefined,
    data: GetLabwareTypesQuery | undefined
  ) => {
    handleUpload(e, selectedPlate, data) //, methodSettings?.int1CountAliquot);
    setSelectedPlate(undefined);
  };

  return (
    <>
      <Dialog maxWidth="md" open={isOpen} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Autocomplete
            sx={{ margin: "10px 0" }}
            id="combo-box-demo"
            options={
              (sourcePlates?.sourcePlates ?? []) as UploadedSourcePlate[]
            }
            getOptionLabel={(option: UploadedSourcePlate) =>
              option.plateBarcode
            }
            value={selectedPlate}
            style={{ width: 300 }}
            isOptionEqualToValue={(option, value) =>
              option.plateBarcode === value.plateBarcode
            }
            onChange={(e: ChangeEvent<unknown>, value: any) =>
              handlePlateTypeChange(value)
            }
            renderInput={(params) => (
              <TextField {...params} label="Plate Barcode" variant="outlined" />
            )}
          />
          {isUploadingIntermediate ? (
            <>
              <FormControl fullWidth>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Would you like to create a new Dead Plate?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={methodSettings?.int1CountAliquot}
                  onChange={(e) =>
                    updateMethodSettings!(
                      "int1CountAliquot",
                      parseInt(e.target.value)
                    )
                  }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Yes, Create New Dead Plate"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="No, Use Existing Dead Plate"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl fullWidth>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Would you like the system to centrifuge and resuspend the
                  intermediate plate to the worklist specified resuspension
                  volume?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  // value={methodSettings?.int1SpinResuspend}
                  // onChange={(e) =>
                  //   updateMethodSettings!("int1SpinResuspend", e.target.value)
                  // }
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Yes (this requires the intermediate plate to be at its operating volume for the centrifugation)"
                  />
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="No, the plate is already resuspended to the worklist specified resupension volume"
                  />
                </RadioGroup>
              </FormControl>
            </>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={(e) =>
              handleUploadSourcePlateClose(e, selectedPlate, data)
            }
            // disabled={
            //   selectedPlate === undefined ||
            //   (isUploadingIntermediate &&
            //     (methodSettings?.int1CountAliquot === -1 ||
            //       methodSettings?.int1SpinResuspend === -1))
            // }
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
