import { Methods } from "../enums/Methods";

export const MethodsWithDetails = [
  Methods.XTY_CustomPassage,
  Methods.Thaw,
  Methods.NormalizedPassage,
  Methods.StampNormalizedPassage,
  Methods.PoolingNormalization,
  Methods.Twelve24TC
];
