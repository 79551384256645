import { gql } from "@apollo/client/core";
import { TypeNames } from "../../enums/TypeNames";
import { ManualTaskType, Method } from "../fragments/method-fragments";

export const MethodQueries = {
  remote: {
    GET_MANUAL_TASK_MANAGER_ENTRIES: gql`
      query ManualTaskManager(
        $where: ManualTaskTypeFilterInput
        $order: [ManualTaskTypeSortInput!]
      ) {
        manualTaskTypes(where: $where, order: $order) {
          ...ManualTaskType
        }
      }
      ${ManualTaskType}
    `,
    GET_METHOD_MANAGER_ENTRIES: gql`
      query MethodManager(
        $where: LookupArrayMethodFilterInput
        $order: [LookupArrayMethodSortInput!]
      ) {
        methods(where: $where, order: $order) {
          ...Method
        }
      }
      ${Method}
    `,
    GET_METHODS: gql`
      query getMethods {
        methods(where: { isActive: { eq: true } }) {
          ...Method
        }
      }
      ${Method}
    `,
    GET_MANUAL_TASK_TYPES: gql`
      query getManualTaskTypes {
        manualTaskTypes {
          ...ManualTaskType
        }
      }
      ${ManualTaskType}
    `,
  },
  local: {
    GET_METHOD_BY_ID: gql`
      query GetMethodById($methodId: Int!) {
        method(id: $methodId, typeName: "${TypeNames.LookupArrayMethod}", keyField: "methodId")  @client{
          ...Method
        }
      }
      ${Method}
    `,
    GET_MANUAL_TASK_TYPE_BY_ID: gql`
      query GetManualTaskTypeById($manualTaskTypeId: Int!) {
        manualTaskType(id: $manualTaskTypeId, typeName: "${TypeNames.ManualTaskType}", keyField: "id")  @client{
          ...ManualTaskType
        }
      }
      ${ManualTaskType}
    `,
  },
};
