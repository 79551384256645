import { AxiosResponse } from "axios";
import { GetUrl } from "helpers/get-url";
import axios from "./AxiosService";

interface RunsService {
  getActivePlatesForRunActivity(runActivityID: number): Promise<AxiosResponse>;
  getXTYPassageMethodSettings(runActivityID: number): Promise<AxiosResponse>;
  getMethodReservationByRunActivityId(
    runActivityId: number
  ): Promise<AxiosResponse>;
  getSystemReservationTimeWithWorklistID(
    systemID: number,
    methodID: number
  ): Promise<AxiosResponse>;
  getSystemReservationTimeWithWorklist(worklist: any): Promise<AxiosResponse>;
  reserveMethodAndPlates(data: any): Promise<AxiosResponse>;
  generateSystemOneFeedWorklist(data: any): Promise<AxiosResponse>;
  downloadWorklist(methodReservationId: number): void;
}
export default {
  getActivePlatesForRunActivity(runActivityID: number) {
    const params = new URLSearchParams();
    params.append("runTaskId", runActivityID.toString());
    return axios.get("/api/Runs/GetPlatesForRunActivity", { params });
  },

  getXTYPassageMethodSettings(runActivityID: number) {
    const params = new URLSearchParams();
    params.append("runTaskId", runActivityID.toString());
    return axios.get("/api/Runs/GetXTYPassageMethodSettings", { params });
  },

  getMethodReservationByRunActivityId(runActivityId: number) {
    const params = new URLSearchParams();
    params.append("runTaskId", runActivityId.toString());
    return axios.get("/api/Runs/GetMethodReservation", { params });
  },

  getSystemReservationTimeWithWorklistID(worklistID: number) {
    const params = new URLSearchParams();
    params.append("worklistID", worklistID.toString());
    return axios.get("/api/Runs/GetSystemReservationTime", { params });
  },

  getSystemReservationTimeWithWorklist(worklist: any) {
    return axios.get("api/Runs");
  },

  reserveMethodAndPlates(data: any) {
    return axios.post("/api/Runs/ReserveMethodAndPlates", data);
  },

  generateSystemOneFeedWorklist(worklistInfo: any) {
    const data = {
      dateTimeOfMethod: "2020-11-30 11:00:00.000",
      WorklistInfo: worklistInfo,
    };
    return axios.post("/api/Runs/GenerateSystemOneFeedWorklist", data);
  },
  downloadWorklist(methodReservationId: number) {
    window.location.href = `${GetUrl()}/api/Runs/DownloadWorklist?methodReservationID=${methodReservationId}`;
  },
} as RunsService;
