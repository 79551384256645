import { useReactiveVar } from "@apollo/client";
import { useMemo } from "react";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import {
  ILayoutAction,
  IModalAction,
  IPlateAction,
  IWellAction,
} from "./actions";
import {
  ILayoutState,
  IModalState,
  IPlateState,
  IWellState,
  LayoutState,
  ModalState,
  PlateState,
  WellState,
} from "./initial-state";
import {
  LayoutReducer,
  ModalReducer,
  PlateReducer,
  WellReducer,
} from "./reducer";

export function useLayoutStateProvider(): {
  layoutState: ILayoutState;
  layoutDispatch: IApolloDispatch<ILayoutAction>;
} {
  const layoutState = useReactiveVar(LayoutState);
  const layoutDispatch = useMemo(
    () => ApolloStateDispatch(LayoutState, LayoutReducer),
    []
  );
  return {
    layoutState,
    layoutDispatch,
  };
}

export function usePlateStateProvider(): {
  plateState: IPlateState;
  plateDispatch: IApolloDispatch<IPlateAction>;
} {
  const plateState = useReactiveVar(PlateState);
  const plateDispatch = useMemo(
    () => ApolloStateDispatch(PlateState, PlateReducer),
    []
  );
  return {
    plateState,
    plateDispatch,
  };
}

export function useWellStateProvider(): {
  wellState: IWellState;
  wellDispatch: IApolloDispatch<IWellAction>;
} {
  const wellState = useReactiveVar(WellState);
  const wellDispatch = useMemo(
    () => ApolloStateDispatch(WellState, WellReducer),
    []
  );
  return {
    wellState,
    wellDispatch,
  };
}

export function useModalStateProvider(): {
  modalState: IModalState;
  modalDispatch: IApolloDispatch<IModalAction>;
} {
  const modalState = useReactiveVar(ModalState);
  const modalDispatch = useMemo(
    () => ApolloStateDispatch(ModalState, ModalReducer),
    []
  );
  return {
    modalState,
    modalDispatch,
  };
}
