import {
  RunInfoFragment,
  UpdateRunInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { RunInputStub } from "mappings/object-stubs/RunInput";

export function RunInputMapper(run: Maybe<RunInfoFragment>) {
  const _run = DeepCopy(run);
  return _.pick(_run, _.keys(new RunInputStub())) as UpdateRunInput;
}
