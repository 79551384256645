import {
  ILayoutState,
  IModalState,
  IPlateState,
  IWellState,
} from "./initial-state";

export enum LayoutAction {
  TOGGLE_WELL_LIST,
}

export enum PlateAction {
  SELECT_PLATE,
}

export enum WellAction {
  TOGGLE_SHOW_INACTIVE,
  TOGGLE_SELECT_WELL,
  TOGGLE_OVERLAY,
}

export enum ModalAction {
  SELECT_CONTAINER,
  OPEN_MODAL,
  CLOSE_MODAL,
}

export interface ILayoutAction {
  payload?: ILayoutState;
  type: LayoutAction;
}

export interface IWellAction {
  payload?: IWellState;
  type: WellAction;
}

export interface IPlateAction {
  payload?: IPlateState;
  type: PlateAction;
}

export interface IModalAction {
  payload?: IModalState;
  type: ModalAction;
}
