import { LookupArraySystem } from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import { FilterProps } from "react-table";
import { SystemField } from "shared-components/FormFields/FormFields";

export function SystemFilter<TData extends Record<string, unknown>>({
  column: { filterValue, setFilter },
}: FilterProps<TData>): JSX.Element {
  return (
    <div style={{ width: "200px" }}>
      <SystemField
        id={"system-filter"}
        value={filterValue}
        onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
          const system = newValue as LookupArraySystem;
          setFilter(system?.systemId ?? null);
        }}
        helperText={false}
        autoFocus={true}
        error={false}
        variant={"standard"}
        label={"Search System"}
      />
    </div>
  );
}
