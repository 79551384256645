import {
  CollectionSampleType,
  UpdateIncomingSampleTypeInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { IncomingSampleTypeStub } from "mappings/object-stubs/IncomingSampleType";

export function UpdateIncomingSampleTypeInputMapper(
  sampleType: Maybe<CollectionSampleType>
) {
  const _sampleType = DeepCopy(sampleType);
  return _.pick(
    _sampleType,
    _.keys(new IncomingSampleTypeStub())
  ) as UpdateIncomingSampleTypeInput;
}
