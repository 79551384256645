import { DatePicker } from "@mui/lab";
import {
  Button,
  FormControlLabel,
  Switch,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import {
  CollectionSampleType,
  IncomingSampleCollectionFragment,
  LookupSampleType,
  useUpdateCollectionSampleTypeMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { UpdateIncomingSampleTypeInputMapper } from "mappings/incomingSampleType";
import React, { useState } from "react";
import { contextMenu } from "react-contexify";
import { SourceSampleTypeField } from "shared-components/FormFields/FormFields";
import styled from "styled-components/macro";

const SaveButton = styled(Button)`
  background-color: green !important;
`;

type SampleType = NonNullable<
  IncomingSampleCollectionFragment["collectionSampleTypes"]
>[number];

export function SampleRow({ sample }: { sample: NonNullable<SampleType> }) {
  const input = UpdateIncomingSampleTypeInputMapper(
    sample as CollectionSampleType
  );
  const [saveChanges] = useUpdateCollectionSampleTypeMutation();
  const { values, submitForm, errors, touched, setFieldValue } = useFormik({
    initialValues: input,
    onSubmit: (values) =>
      saveChanges({
        variables: {
          input: {
            primaryKey: sample.incomingSampleTypeId,
            set: values,
          },
        },
      }),
    enableReinitialize: true,
  });
  const [disabled, setDisabled] = useState(true);
  return (
    <TableRow
      onContextMenu={(e) => {
        e.preventDefault();
        contextMenu.show({
          id: "incoming-sample-type-context-menu",
          event: e,
          props: { collectionSampleTypeId: sample.incomingSampleTypeId },
        });
      }}
    >
      <TableCell>
        <SourceSampleTypeField
          id={`sourceSampleType-combobox-${sample.incomingSampleTypeId}`}
          disabled={disabled}
          defaultValue={sample.sampleTypeId}
          onChange={(event, newValue) =>
            setFieldValue(
              "sampleTypeId",
              (newValue as Maybe<LookupSampleType>)?.sampleTypeId
            )
          }
          variant="outlined"
          helperText={
            errors.sampleTypeId && touched.sampleTypeId && errors.sampleTypeId
          }
          error={
            !!(
              errors.sampleTypeId &&
              touched.sampleTypeId &&
              errors.sampleTypeId
            )
          }
        />
      </TableCell>
      <TableCell>
        <DatePicker
          value={values.dateToProcess ?? null}
          onChange={(date) => setFieldValue("dateToProcess", date)}
          disabled={disabled}
          renderInput={(props) => (
            <TextField
              {...props}
              disabled={disabled}
              helperText={
                errors.dateToProcess &&
                touched.dateToProcess &&
                errors.dateToProcess
              }
              error={
                !!(
                  errors.dateToProcess &&
                  touched.dateToProcess &&
                  errors.dateToProcess
                )
              }
              fullWidth
              variant={"outlined"}
            />
          )}
        />
      </TableCell>
      <TableCell>
        <TextField
          id={`sampleType-${sample.incomingSampleTypeId}`}
          variant="outlined"
          value={values.notes}
          onChange={(e) => setFieldValue("notes", e.target.value)}
          multiline
          disabled={disabled}
          helperText={errors.notes && touched.notes && errors.notes}
          error={!!(errors.notes && touched.notes && errors.notes)}
          fullWidth
        />
      </TableCell>
      <TableCell>
        <FormControlLabel
          label={""}
          labelPlacement={"start"}
          disabled={disabled}
          control={
            <Switch
              checked={values.isActive ?? false}
              disabled={disabled}
              onChange={(e) => setFieldValue("isActive", e.target.checked)}
            />
          }
        />
      </TableCell>
      <TableCell>
        {disabled ? (
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={() => setDisabled(false)}
          >
            Edit
          </Button>
        ) : (
          <SaveButton
            variant={"contained"}
            color={"secondary"}
            onClick={async () => {
              setDisabled(true);
              await submitForm();
            }}
          >
            Save
          </SaveButton>
        )}
      </TableCell>
    </TableRow>
  );
}
