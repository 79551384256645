import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { PlatePosition, WorklistTools } from "../interfaces";
import {
  getColIndexFromSelectedWell,
  getPlateWellIdFromRowAndColIndex,
  getRowIndexFromSelectedWell,
} from "../WorklistHelpers";
import PlateWell from "./PlateWell";

const StyledIntermediatePlate = styled.div<{
  borderColor: string | undefined;
}>`
  height: 100%;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 0 4px -1px black;
  // background-color: white;
  border-radius: 5px;
  border: ${(props) => (props.borderColor ? "3px" : "1px")} solid
    ${(props) => props.borderColor ?? "#ded7e9"};
`;

const StyledDestPlateElements = styled.div<{
  cols: number[];
  rows: number[];
}>`
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 4px 4px;
  grid-template-columns: repeat(${(props) => props.cols.length + 1}, 1fr);
  grid-template-rows: repeat(${(props) => props.rows.length + 1}, 1fr);
  justify-items: center;
  align-items: center;
`;

const PlateHeaderCell = styled(Typography)<{
  rowStart: number;
  colStart: number;
}>`
  display: flex;
  grid-row-start: ${(props) => `${props.rowStart}`};
  grid-column-start: ${(props) => `${props.colStart}`};
  align-items: center;
  justify-content: center;
  height: 5px;
  width: 5px;
`;

interface IntermediatePlateProps {
  worklistTool: WorklistTools;
  index: number;
  plateRows: number[];
  plateCols: number[];
  wellMapping: any[];
  borderColor?: string;
  sourcePlateInfo?: any[];
  sourcePlatePosition: PlatePosition;
  handleWellHover?: (wellId: string) => void;
}

export function IntermediatePlate({
  worklistTool,
  index,
  plateRows,
  plateCols,
  wellMapping,
  borderColor,
  sourcePlateInfo,
  sourcePlatePosition,
  handleWellHover,
}: IntermediatePlateProps) {
  //Need to use this function because of stepper component in Pooling Worklist
  const getHarvestWellsColor = (row: number, col: number) => {
    const harvestWells = wellMapping.filter(
      (e) =>
        e.destPlateIndex === 0 &&
        e.destWellId === getPlateWellIdFromRowAndColIndex(row, col)
    );

    if (harvestWells.length < 1) {
      return "";
    }

    if (harvestWells.length > 1) {
      return `multi-color ${getMultiColorHarvestWell(harvestWells, row, col)}`;
    }

    const index = sourcePlateInfo![
      harvestWells[0].sourcePlateIndex
    ].wellInfo.findIndex(
      (e: any) =>
        e.rowPos ==
          getRowIndexFromSelectedWell(harvestWells[0].sourceWellId) + 1 &&
        e.colPos ==
          getColIndexFromSelectedWell(harvestWells[0].sourceWellId) + 1
    );
    if (index === -1) return "";
    return (
      sourcePlateInfo![harvestWells[0].sourcePlateIndex].wellInfo[index]
        .color ?? ""
    );
  };

  const getMultiColorHarvestWell = (
    harvestWells: any[],
    row: number,
    col: number
  ) => {
    const colors: string[] = [];
    for (const [index, element] of harvestWells.entries()) {
      const rowIndex = getRowIndexFromSelectedWell(element.sourceWellId);
      const colIndex = getColIndexFromSelectedWell(element.sourceWellId);
      const wellInfoIndex = sourcePlateInfo![
        element.sourcePlateIndex
      ].wellInfo.findIndex(
        (e: any) =>
          e.rowPos == getRowIndexFromSelectedWell(element.sourceWellId) + 1 &&
          e.colPos == getColIndexFromSelectedWell(element.sourceWellId) + 1
      );
      colors.push(
        sourcePlateInfo![element.sourcePlateIndex].wellInfo[wellInfoIndex].color
      );
    }
    return colors.join(", ");
  };

  const getWellColor = (
    plateIndex: number,
    row: number,
    col: number
  ): string => {
    //get all rows that go into plate
    const rows = wellMapping.filter(
      (e) =>
        parseInt(e.destPlateIndex) === plateIndex &&
        e.destWellId === `${String.fromCharCode(65 + row - 1)}${col}`
    );
    if (rows.length > 1) {
      return `multi-color ${getMultiColorWell(rows)}`;
    }
    const rowIndex = wellMapping.findIndex(
      (e) =>
        parseInt(e.destPlateIndex) === plateIndex &&
        e.destWellId === `${String.fromCharCode(65 + row - 1)}${col}`
    );
    if (rowIndex > -1) {
      const wellStyle = document.getElementById(
        `${sourcePlatePosition}${wellMapping[rowIndex].sourcePlateIndex}${wellMapping[rowIndex].sourceWellId}`
      )!.style;

      if (wellStyle.backgroundColor === "") {
        const wellColor = `multi-color ${getBackgroundImageColors(
          wellStyle.backgroundImage.toString()
        )}`;

        return wellColor;
      }

      return wellStyle.backgroundColor;
    }
    return "";
  };

  const getMultiColorWell = (rows: any[]) => {
    const colors: string[] = [];
    for (const element of rows) {
      const wellStyle = document.getElementById(
        `${sourcePlatePosition}${element.sourcePlateIndex}${element.sourceWellId}`
      )!.style;

      if (wellStyle.backgroundColor === "") {
        const wellColor = getBackgroundImageColors(
          wellStyle.backgroundImage.toString()
        );

        const split = wellColor.match(/(rgb[(]\d{1,3}, \d{1,3}, \d{1,3}[)])/g);
        if (split == null) return;
        for (const val of split) {
          colors.push(val);
        }
      } else {
        const color = document.getElementById(
          `${sourcePlatePosition}${element.sourcePlateIndex}${element.sourceWellId}`
        )!.style.backgroundColor;
        colors.push(color);
      }
    }
    return colors.join(", ");
  };

  const getBackgroundImageColors = (backgroundImageColors: string) => {
    const removedLinearGradient = backgroundImageColors.substring(
      16,
      backgroundImageColors.length - 1
    );
    return removedLinearGradient;
  };

  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    menuId: string
  ) => {
    e.preventDefault();
    contextMenu.show({
      id: menuId,
      event: e,
    });
  };

  return (
    <>
      <StyledIntermediatePlate
        onContextMenu={(e) => {
          handleContextMenu(e, `${worklistTool}_Intermediate_${index}`);
        }}
        borderColor={borderColor}
      >
        <StyledDestPlateElements rows={plateRows} cols={plateCols}>
          <PlateHeaderCell rowStart={1} colStart={1}></PlateHeaderCell>
          {plateRows.map((row, index) => (
            <PlateHeaderCell rowStart={row + 1} colStart={1}>
              <p style={{ fontSize: "medium" }}>
                {String.fromCharCode(64 + row)}
              </p>
            </PlateHeaderCell>
          ))}
          {plateCols.map((col, index) => (
            <PlateHeaderCell rowStart={1} colStart={col + 1}>
              <p style={{ fontSize: "medium" }}>{index + 1}</p>
            </PlateHeaderCell>
          ))}
          {plateRows.map((rowItem, rowIndex) =>
            plateCols.map((colItem, colIndex) => (
              <div
                style={{ width: "100%", height: "100%" }}
                onMouseOver={() =>
                  handleWellHover
                    ? handleWellHover(
                        `Intermediate${index}${
                          String.fromCharCode(65 + rowIndex) +
                          String(colIndex + 1)
                        }`
                      )
                    : null
                }
              >
                <PlateWell
                  plateIndex={index}
                  platePosition={PlatePosition.Intermediate}
                  plateWellId={
                    String.fromCharCode(65 + rowIndex) + String(colIndex + 1)
                  }
                  wellColor={
                    worklistTool === WorklistTools.PoolingNormalization &&
                    index === 0
                      ? getHarvestWellsColor(rowIndex, colIndex)
                      : getWellColor(index, rowIndex + 1, colIndex + 1)
                  }
                ></PlateWell>
              </div>
            ))
          )}
        </StyledDestPlateElements>
      </StyledIntermediatePlate>
    </>
  );
}

interface WorklistPlateType {
  displayValue: string;
  worklistValue: string;
}

interface DestPlateTypeProps {
  index: number;
  intermediatePlateType: string;
  acceptablePlateTypes: WorklistPlateType[];
  handleDestPlateTypeChanged: (index: number, plateType: string) => void;
}

export function IntermediatePlateType({
  index,
  intermediatePlateType,
  acceptablePlateTypes,
  handleDestPlateTypeChanged,
}: DestPlateTypeProps) {
  return (
    <div>
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="destination-plate-type-label">
          Intermediate Plate Type
        </InputLabel>
        <Select
          labelId="destination-plate-type-label"
          id="select"
          value={intermediatePlateType}
          onChange={(event) =>
            handleDestPlateTypeChanged(index, event.target.value)
          }
        >
          {acceptablePlateTypes.map((plateType) => (
            <MenuItem value={plateType.worklistValue}>
              {plateType.displayValue}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
