import { ITubeLabellerAction, TubeLabellerAction } from "./action";
import { ITubeLabellerState } from "./initial-state";

export function TubeLabellerReducer(
  state: ITubeLabellerState,
  action: ITubeLabellerAction
) {
  switch (action.type) {
    case TubeLabellerAction.SET_RACK_INDEX:
      return {
        ...state,
        rackIndex: action.payload.rackIndex,
      };
    case TubeLabellerAction.SET_WORKLIST:
      return {
        ...state,
        worklist: action.payload.worklist,
      };
    case TubeLabellerAction.REMOVE_RACK:
      return {
        ...state,
        worklist: state.worklist.filter(
          (e) => e.dSTRack !== action.payload.rackIndex
        ),
        currentRackIndex:
          state.currentRackIndex === 1 ? 1 : state.currentRackIndex - 1,
      };
    case TubeLabellerAction.DECREMENT_RACKS:
      return {
        ...state,
        worklist: state.worklist.forEach((row) => {
          if (row.dSTRack >= action.payload.rackIndex) {
            row.dSTRack = row.dSTRack - 1;
            row.sRCRack = row.sRCRack - 1;
          }
        }),
      };
    case TubeLabellerAction.ADD_RACK_INFO:
      return {
        ...state,
      };
    case TubeLabellerAction.REMOVE_RACK_INFO:
      return {
        ...state,
      };
  }
}
