import { ApolloQueryResult } from "@apollo/client/core/types";
import { MutateState } from "helpers/object-helpers";
import produce from "immer";
import { Dispatch, SetStateAction } from "react";
import { Filters } from "react-table";
import { TableQueryVariables } from "shared-components/Table/Handlers/types";
import { ReactTablePaginationState } from "shared-components/Table/ReactTable";

export const GetFilterHandler =
  <
    TQuery,
    TVariables extends Partial<TableQueryVariables>,
    TData extends Record<string, unknown>
  >(
    variables: TVariables | undefined,
    refetch: (
      variables?: Partial<TVariables>
    ) => Promise<ApolloQueryResult<TQuery>>,
    filterReducer: (
      filters: Filters<TData>,
      draft?: TVariables
    ) => TVariables["where"],
    tuple?: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ) =>
  async (filters: Filters<TData>): Promise<void> => {
    const _state = MutateState(variables, (draft) => {
      const filter = filterReducer(filters, draft);
      if (draft) draft.where = filter;
    });
    if (refetch && _state.hasChanged) {
      await refetch(_state.newValue as TVariables);
      console.log("Tables Action: FILTER");
      if (tuple) {
        const [paginationState, setPaginationState] = tuple;
        setPaginationState(
          produce(paginationState, (draft) => {
            draft.page = 0;
          })
        );
      }
    }
  };
