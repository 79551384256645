import { makeVar } from "@apollo/client";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
} from "@mui/material";
import { useGetHandleConfirm } from "features/Runs/components/Forms/Confirm/handleSubmit";
import { DefaultPreviewTable } from "features/Runs/components/Forms/Confirm/PreviewTables/DefaultPreview";
import { DetailsPreviewTable } from "features/Runs/components/Forms/Confirm/PreviewTables/DetailsPreview";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  RunTask,
  RunTaskInput,
  useUpdateRunTasksMutation,
} from "graphql/generated/schema-types";
import { useGetRunActivitiesById } from "hooks/cacheAccessHooks";
import produce from "immer";
import _ from "lodash";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import React, { useEffect, useRef, useState } from "react";
import { StyledCard } from "shared-components/styled-components";
import styled from "styled-components/macro";

export const StyledTable = styled(Table)`
  position: relative;
`;
export const StyledTHDiv = styled.div`
  display: flex !important;
`;
export const ControlsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const PreviousModal = makeVar<RunTaskModals | undefined>(undefined);

export function EditRunsConfirmModal(): JSX.Element {
  const {
    runsModalState: { selectedTasks, editedFieldValuePairs },
    runsModalDispatch,
  } = useRunsModalState();

  const [isSubmitting, setSubmitting] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const _selectedTasks = useGetRunActivitiesById(selectedTasks!) as
    | RunTask[]
    | undefined;

  const mutationInputRef = useRef<Array<RunTaskInput>>([]);

  useEffect(() => {
    if (_selectedTasks)
      mutationInputRef.current = _selectedTasks.map((task) =>
        RunActivityInputMapper(task as RunTask)
      );

    mutationInputRef.current = produce(mutationInputRef.current, (draft) => {
      if (draft)
        for (const task of draft) {
          for (const [key, value] of editedFieldValuePairs!) {
            try {
              _.set(task, key, value);
            } catch (error) {
              console.log(error);
            }
          }
        }
    });
  }, [_selectedTasks, editedFieldValuePairs]);

  const [updateRunTasks] = useUpdateRunTasksMutation();

  const handleGoBack = () => {
    runsModalDispatch({
      type: RunsModalActions.OPEN_MODAL,
      payload: {
        currentModal: PreviousModal(),
      },
    });
  };

  const handleConfirm = useGetHandleConfirm(
    setSubmitting,
    updateRunTasks,
    mutationInputRef.current
  );

  return (
    <>
      <StyledCard min_width="50vw">
        <ControlsContainer>
          <IconButton onClick={handleGoBack} size="large">
            <ArrowBack />
          </IconButton>
          {mutationInputRef.current?.some((input) => input.details) ? (
            <FormControlLabel
              control={<Switch name="showDetails" />}
              checked={showDetails}
              onChange={() => {
                setShowDetails((value) => !value);
              }}
              label={"Show Details"}
            />
          ) : (
            <div />
          )}
        </ControlsContainer>
        {showDetails ? (
          <DetailsPreviewTable runActivityInputs={mutationInputRef.current} />
        ) : (
          <DefaultPreviewTable runActivityInputs={mutationInputRef.current} />
        )}
        <Box m={2}>
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => handleConfirm()}
          >
            Confirm
          </Button>
        </Box>
      </StyledCard>
    </>
  );
}
