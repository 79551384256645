import SaveIcon from "@mui/icons-material/Save";
import { Button, Grid } from "@mui/material";

export interface FeedGroupFooterProps {
  allFeedGroupsDisabled: boolean;
  errorsPresent: boolean;
  handleClose: () => void;
  isDirty: boolean;
  isSubmitting: boolean;
  saveFeedGroupsHandler: () => void;
}

export const FeedGroupFooter = (props: FeedGroupFooterProps) => {
  return (
    <Grid container wrap="nowrap">
      <Grid item xs={9}>
        <Button
          color="primary"
          onClick={props.handleClose}
          sx={{ mt: 3, ml: 0.75 }}
          variant="contained"
        >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={1} sx={{ mt: 3, mr: 2 }}>
        <Button
          color="primary"
          disabled={
            !props.isDirty ||
            props.isSubmitting ||
            (props.allFeedGroupsDisabled && !props.isDirty) ||
            props.errorsPresent
          }
          onClick={props.saveFeedGroupsHandler}
          variant="contained"
        >
          <SaveIcon sx={{ mr: 0.8 }} /> Save
        </Button>
      </Grid>
      <Grid item xs={2.5}>
        <Button
          color="primary"
          disabled={
            props.isDirty || props.isSubmitting || props.allFeedGroupsDisabled
          }
          fullWidth
          sx={{ mt: 3 }}
          type="submit"
          variant="contained"
        >
          Add Feed Task(s)
        </Button>
      </Grid>
    </Grid>
  );
};
