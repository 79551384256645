import React from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { useEditUserMutation } from "graphql/generated/schema-types";
import { TicketTableContextMenuHidden } from "../../Tickets/components/Table/TicketTable";
import { UserManagerActions } from "../state/actions";
import { useUserManagerState } from "../state/hooks";
import { UserManagerModals } from "./Modals/modals.enum";

export function UserManagerContextMenu(): JSX.Element {
  const {
    userManagerDispatch,
    userManagerState: { selectedUsers },
  } = useUserManagerState();

  const [updateUsers] = useEditUserMutation();

  return (
    <Menu
      id="UserManagerTableContextMenu"
      onHidden={() => {
        setTimeout(() => TicketTableContextMenuHidden(true), 200);
      }}
    >
      <Submenu label={"Mark As"}>
        <Item
          onClick={async () => {
            await updateUsers({
              variables: {
                inputs: selectedUsers?.map((id) => ({
                  primaryKey: id,
                  set: { isActive: true },
                })),
              },
            });
          }}
        >
          Active
        </Item>
        <Item
          onClick={async () => {
            await updateUsers({
              variables: {
                inputs: selectedUsers?.map((id) => ({
                  primaryKey: id,
                  set: { isActive: false },
                })),
              },
            });
          }}
        >
          Inactive
        </Item>
      </Submenu>
      {selectedUsers?.length === 1 && (
        <Item
          onClick={() => {
            userManagerDispatch({
              type: UserManagerActions.OPEN_MODAL,
              payload: {
                currentModal: UserManagerModals.EditUser,
              },
            });
          }}
        >
          Edit
        </Item>
      )}
    </Menu>
  );
}
