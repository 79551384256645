import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import { Formik } from "formik";
import {
  DayOfWeek,
  PersonalTasksRecurringTask,
  PersonalTasksRecurringTaskInput,
  useUpdateRecurringTaskMutation,
} from "graphql/generated/schema-types";
import { CapitalizeFirstLetter } from "helpers/string-helpers";
import React from "react";
import { StyledForm } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";

interface UpdateRecurringTaskInfoProps {
  selectedTask: PersonalTasksRecurringTask | undefined;
  isOpen: boolean;
  handleClose: () => void;
}

export default function UpdateRecurringTaskInfo({
  selectedTask,
  isOpen,
  handleClose,
}: UpdateRecurringTaskInfoProps) {
  const [updateRecurringTaskInfo] = useUpdateRecurringTaskMutation({});

  const initialValues: PersonalTasksRecurringTaskInput = {
    recurringTaskId: selectedTask?.recurringTaskId
      ? selectedTask?.recurringTaskId
      : 0,
    repeatingDaysOfWeek: selectedTask?.repeatingDaysOfWeek
      ? selectedTask?.repeatingDaysOfWeek.split(", ")
      : [],
    endRepeatDate: selectedTask?.endRepeatDate
      ? selectedTask?.endRepeatDate
      : "",
  };

  const daysOfWeek = [
    CapitalizeFirstLetter(DayOfWeek.Sunday.toLowerCase()),
    CapitalizeFirstLetter(DayOfWeek.Monday.toLowerCase()),
    CapitalizeFirstLetter(DayOfWeek.Tuesday.toLowerCase()),
    CapitalizeFirstLetter(DayOfWeek.Wednesday.toLowerCase()),
    CapitalizeFirstLetter(DayOfWeek.Thursday.toLowerCase()),
    CapitalizeFirstLetter(DayOfWeek.Friday.toLowerCase()),
    CapitalizeFirstLetter(DayOfWeek.Saturday.toLowerCase()),
  ];

  const handleSubmit = (values: any) => {
    console.log(values);
    updateRecurringTaskInfo({
      variables: {
        recurringTask: values,
      },
    }).then((res) => {
      Alert({
        type: AlertType.SUCCESS,
        message: "Updated Recurring Tasks",
      });
      handleClose();
    });
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Formik
        initialValues={initialValues}
        // validationSchema={AddPersonalTaskSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <React.Fragment>
            <StyledForm onSubmit={handleSubmit}>
              <DialogTitle>{`Update ${selectedTask?.taskName}`}</DialogTitle>
              <DialogContent>
                <Autocomplete
                  multiple
                  id="repeatingDaysOfWeek"
                  options={daysOfWeek}
                  value={values.repeatingDaysOfWeek ?? [""]}
                  getOptionLabel={(option) => option!}
                  style={{ margin: "10px 5px 10px 0", width: "70%" }}
                  onChange={(e, newValue: any) =>
                    setFieldValue("repeatingDaysOfWeek", newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Day of Week"
                      variant="outlined"
                    />
                  )}
                />
                <DatePicker
                  label="End Repeat Date"
                  value={values?.endRepeatDate}
                  renderInput={(props) => <TextField {...props} />}
                  onChange={(newValue: unknown) => {
                    setFieldValue("endRepeatDate", newValue);
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary">
                  Save Changes
                </Button>
              </DialogActions>
            </StyledForm>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
}
