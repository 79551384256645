import {
  CreateRunTemplateInput,
  Scalars,
  UpdateRunTemplateInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class CreateRunTemplateInputStub implements CreateRunTemplateInput {
  createdBy: Maybe<Scalars["Int"]>;
  createdByV: Maybe<Scalars["String"]>;
  folderLocationId: Scalars["Int"];
  lastUpdatedBy: Maybe<Scalars["Int"]>;
  productionRunType: Scalars["String"];
  productionRunTypeId: Maybe<Scalars["Int"]>;
  runTemplateId: Scalars["Int"];
  runTemplateName: Scalars["String"];
  groupIds: Array<Scalars["Int"]>;
  cloneTemplate: Scalars["Boolean"];
}
export class UpdateRunTemplateInputStub implements UpdateRunTemplateInput {
  assignAllTasks: Maybe<Scalars["Int"]>;
  createdBy: Maybe<Scalars["Int"]>;
  createdByV: Maybe<Scalars["String"]>;
  folderLocationId: Scalars["Int"];
  groupIds: Array<Scalars["Int"]>;

  lastUpdatedBy: Maybe<Scalars["Int"]>;
  productionRunTypeId: Maybe<Scalars["Int"]>;
  runTemplateId: Maybe<Scalars["Int"]>;
  runTemplateName: Maybe<Scalars["String"]>;
}
