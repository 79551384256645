import { QueryResult } from "@apollo/client";
import { TableName } from "enums/TableName";
import {
  GetFolderDirectoryQuery,
  GetFolderDirectoryQueryVariables,
  GetRunTasksQuery,
  GetRunTasksQueryVariables,
  NodeType,
  useGetFolderDirectoryQuery,
  useGetRunTaskMetaDataLazyQuery,
  useGetRunTasksQuery,
} from "graphql/generated/schema-types";
import { useGetDefaultTablePreset } from "hooks/useGetDefaultTablePreset";
import { createContext, ReactNode, useEffect, useMemo } from "react";
import { ALLRUNS_FOLDER_ID } from "../constants";
import { UseRunsBackgroundQuery } from "../hooks";
import {
  getOrderByClause,
  getWhereClause,
  useGetRunIdFromURL,
} from "./helpers";
import { useRunsModalState } from "./hooks";

export const RunActivityQueryContext = createContext<
  QueryResult<GetRunTasksQuery, GetRunTasksQueryVariables> | undefined
>(undefined);
export const FolderQueryContext = createContext<
  | QueryResult<GetFolderDirectoryQuery, GetFolderDirectoryQueryVariables>
  | undefined
>(undefined);

export function RunContextProvider({
  children,
}: {
  children?: ReactNode;
}): JSX.Element {
  const {
    runsModalState: { selectedTasks },
  } = useRunsModalState();

  const runId = useGetRunIdFromURL();

  const defaultPreset = useGetDefaultTablePreset(TableName.Runs);

  const pageSize = defaultPreset?.pageSize ?? 10;

  const where = useMemo(() => getWhereClause(defaultPreset), [defaultPreset]);

  const order = useMemo(() => getOrderByClause(defaultPreset), [defaultPreset]);

  const [getMetadata] = useGetRunTaskMetaDataLazyQuery();

  useEffect(() => {
    if (selectedTasks && selectedTasks.length > 0)
      getMetadata({
        variables: {
          taskIds: selectedTasks,
        },
      });
  }, [getMetadata, selectedTasks]);

  const runActivityTasksQuery = useGetRunTasksQuery({
    variables: {
      key: runId
        ? { type: NodeType.Run, id: runId }
        : {
            type: NodeType.Folder,
            id: ALLRUNS_FOLDER_ID,
          },
      where,
      order,
      pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const folderStructureQuery = useGetFolderDirectoryQuery({
    notifyOnNetworkStatusChange: true,
  });

  UseRunsBackgroundQuery();

  return (
    <FolderQueryContext.Provider value={folderStructureQuery}>
      <RunActivityQueryContext.Provider value={runActivityTasksQuery}>
        {children}
      </RunActivityQueryContext.Provider>
    </FolderQueryContext.Provider>
  );
}
