import { Avatar, IconButton, MenuItem, Tooltip } from "@mui/material";
import { googleLogout } from "@react-oauth/google";
import { GroupMemberLoginDialogOpen } from "features/Authentication/GroupAuth/GroupMemberLoginForm";
import { StyledNavButton } from "features/Navbar/NavItem";
import { NavItemPopup } from "features/Navbar/NavItemPopup";
import { useGetMeQuery } from "graphql/generated/schema-types";
import { GetInitials } from "helpers/string-helpers";
import { useMemo } from "react";
import { contextMenu, Item, Menu } from "react-contexify";
import { ShowReleaseNotes } from "shared-components/ReleaseNotes";
import { StyledLink } from "shared-components/styled-components";
import { Action } from "state-provider/actions";
import { useStateProvider } from "state-provider/hooks";
import styled from "styled-components";

const GroupLoginInfo = styled.div`
  margin: 0 10px;
`;
const GroupLoginInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: small;
`;

export function AccountNavItem(): JSX.Element {
  const { dispatch } = useStateProvider();

  const { data: claimsQuery } = useGetMeQuery({ fetchPolicy: "cache-only" });

  const initials = useMemo(
    () => GetInitials(claimsQuery?.me?.userName ?? ""),
    [claimsQuery?.me]
  );
  // const image = useMemo(
  //   () => claimsQuery?.me?.slackProfilePhoto,
  //   [claimsQuery]
  // );

  const logout = () => {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("userID");
    localStorage.setItem("staySignedIn", false.toString());
    googleLogout();
    dispatch({
      type: Action.AUTHENTICATE,
      payload: { loggedIn: false },
    });
  };

  return (
    <>
      <NavItemPopup
        id={"account-popup"}
        Button={(props) => (
          <Tooltip title={`${claimsQuery?.me?.userName}` ?? "User"}>
            <IconButton {...props} size="large">
              <Avatar alt={`${claimsQuery?.me?.userName}`}>{initials}</Avatar>
            </IconButton>
          </Tooltip>
        )}
      >
        <MenuItem>
          <StyledLink to="/admin-dashboard">
            <div>Admin Dashboard</div>
          </StyledLink>
        </MenuItem>
        <MenuItem>
          <StyledLink to="/account-settings">
            <div>Account Settings</div>
          </StyledLink>
        </MenuItem>
        {claimsQuery?.me?.isGroupAccount && (
          <MenuItem>
            <StyledLink to="/change-computer-name">
              <div>Change Computer Name</div>
            </StyledLink>
          </MenuItem>
        )}
        <MenuItem onClick={() => ShowReleaseNotes(true)}>
          Release Notes
        </MenuItem>
        <MenuItem onClick={() => logout()}>Logout</MenuItem>
      </NavItemPopup>
      {claimsQuery?.me?.isGroupAccount &&
      claimsQuery?.me.loggedInGroupMember ? (
        <StyledNavButton
          onClick={() => GroupMemberLoginDialogOpen(true)}
          onContextMenu={(e) => {
            e.preventDefault();
            contextMenu.show({
              id: "group-login-info-context-menu",
              event: e,
            });
          }}
        >
          <GroupLoginInfoContainer>
            <GroupLoginInfo>
              {claimsQuery?.me.loggedInGroupMember?.labComputer?.name}
            </GroupLoginInfo>
            <GroupLoginInfo>
              {claimsQuery?.me.loggedInGroupMember?.user?.userName ?? "unknown"}
            </GroupLoginInfo>
          </GroupLoginInfoContainer>
        </StyledNavButton>
      ) : (
        <div />
      )}
      <Menu id={"group-login-info-context-menu"}>
        <Item
          onClick={() => {
            localStorage.removeItem("labComputerId");
            GroupMemberLoginDialogOpen(true);
          }}
        >
          Change Computer Name
        </Item>
      </Menu>
    </>
  );
}
