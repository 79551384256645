import { QueryHookOptions, QueryResult } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { Exact } from "@octokit/graphql-schema";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { ChangeEvent, FC } from "react";
import { getDefaultValue, getValue } from "./helpers";
import { ComboboxFieldProps } from "./types";

export const GetLocalCombobox = <
  TOption,
  TQuery,
  TVariables = Exact<{ [p: string]: never }>
>(
  queryFn: (
    baseOptions?: QueryHookOptions<TQuery, TVariables> | undefined
  ) => QueryResult<TQuery, TVariables>,
  optionsGetter: (result: Maybe<TQuery>) => TOption[],
  idGetter: (option: TOption) => number | string,
  labelGetter: (option: TOption) => string
): FC<ComboboxFieldProps<TOption>> =>
  React.memo(
    ({
      id,
      variant,
      onChange,
      helperText,
      error,
      label,
      onBlur,
      defaultValue,
      value,
      autoFocus,
      size,
      disabled,
      multiple,
      freeSolo,
      filter,
      filterOptions,
      groupBy,
      disableClearable,
    }: ComboboxFieldProps<TOption>) => {
      const query = queryFn({
        notifyOnNetworkStatusChange: true,
      });

      const options = optionsGetter(query?.data);

      const val = getValue(value, options, idGetter, multiple);
      const defaultVal = getDefaultValue(
        defaultValue,
        value,
        options,
        idGetter,
        multiple
      );

      return (
        <Autocomplete
          id={id}
          options={optionsGetter(query?.data).filter(filter ?? ((x) => x))}
          size={size}
          value={val}
          defaultValue={defaultVal}
          disabled={disabled}
          multiple={multiple}
          getOptionLabel={labelGetter}
          groupBy={groupBy}
          fullWidth
          filterOptions={filterOptions}
          freeSolo={freeSolo}
          disableClearable={disableClearable}
          onChange={
            onChange as (event: ChangeEvent<unknown>, newValue: unknown) => void
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              disabled={disabled}
              autoFocus={autoFocus}
              onBlur={onBlur}
              variant={variant}
              helperText={helperText}
              error={error}
              data-testid={"form-field"}
            />
          )}
        />
      );
    }
  );
