import { useReactiveVar } from "@apollo/client";
import { IRunTemplateAction } from "features/RunTemplate/state/actions";
import {
  IRunTemplateState,
  RunTemplateState,
} from "features/RunTemplate/state/initial-state";
import { RunTemplateReducer } from "features/RunTemplate/state/reducer";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";

export enum InlineEdit {
  RenameStage,
}

export function useRunTemplateStateProvider(): {
  runTemplateState: IRunTemplateState;
  runTemplateDispatch: IApolloDispatch<IRunTemplateAction>;
} {
  const runTemplateState = useReactiveVar(RunTemplateState);
  const runTemplateDispatch = useMemo(
    () => ApolloStateDispatch(RunTemplateState, RunTemplateReducer),
    []
  );
  return {
    runTemplateState,
    runTemplateDispatch,
  };
}
