import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

interface NewTemplateNameDialogProps {
  isOpen: boolean;
  handleClose: (uploadWorklist: boolean, newTemplateName: string) => void;
}

export const NewTemplateNameDialog = ({
  isOpen,
  handleClose,
}: NewTemplateNameDialogProps) => {
  const [newTemplateName, setNewTemplateName] = useState("");
  return (
    <Dialog open={isOpen} onClose={() => handleClose(false, newTemplateName)}>
      <DialogTitle>New Template Name</DialogTitle>
      <DialogContent>
        <TextField
          value={newTemplateName}
          onChange={(e) => setNewTemplateName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false, newTemplateName)}>
          Cancel
        </Button>
        <Button onClick={() => handleClose(true, newTemplateName)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
