import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MethodSettingsProps } from "../shared";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
  },
}));

export const DissociationWashReagent = ({
  twelve24TCInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  const classes = useStyles();

  const hasError =
    twelve24TCInternalState.methodSettings.washBeforeDissociation &&
    twelve24TCInternalState.methodSettings.dissociationWashRGT === 0;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <FormControlLabel
        sx={{ width: "100%" }}
        classes={{
          label: classes.fullWidth,
        }}
        control={
          <Checkbox
            checked={
              twelve24TCInternalState.methodSettings.washBeforeDissociation
            }
            onChange={(e) =>
              handleMethodSettingsChanged(
                "washBeforeDissociation",
                e.target.checked
              )
            }
            name="checkedB"
            color="primary"
            size="small"
            sx={{ alignSelf: "flex-start", marginTop: 0.25 }}
          />
        }
        label={
          <FormControl variant="outlined" fullWidth error={hasError}>
            <InputLabel id="dissociation-wash-label">
              Dissociation Wash Reagent
            </InputLabel>
            <Select
              fullWidth={true}
              labelId="dissociation-wash-label"
              id="dissociation-wash-select"
              disabled={
                !twelve24TCInternalState.methodSettings.washBeforeDissociation
              }
              value={twelve24TCInternalState.methodSettings.dissociationWashRGT}
              label="Dissociation Wash Reagent"
              size="small"
              onChange={(e) =>
                handleMethodSettingsChanged(
                  "dissociationWashRGT",
                  e.target.value
                )
              }
            >
              <MenuItem value={0}></MenuItem>
              <MenuItem value={1}>Media</MenuItem>
              <MenuItem value={2}>DissociationRGT</MenuItem>
              <MenuItem value={3}>PBS</MenuItem>
            </Select>
            <FormHelperText>
              {hasError ? "Reagent must be selected" : ""}
            </FormHelperText>
          </FormControl>
        }
      />

      <Tooltip title="Wash Before Dissociation - Source wells will be rinsed with selected reagent before adding dissociation reagent, with operating volume of labware">
        <InfoIcon sx={{ alignSelf: "flex-start", marginTop: 1 }} />
      </Tooltip>
    </div>
  );
};
