import { Grid, Switch } from "@mui/material";
import { feedDayToday, feedDayTomorrow } from "../AddFeedTask.Functions";

interface DayToggleProps {
  feedDay: string;
  setFeedDay: (value: string) => void;
}

export const DayToggle = (props: DayToggleProps) => {
  return (
    <Grid
      alignItems="center"
      item
      component="label"
      container
      spacing={1}
      xs={2}
    >
      <Grid item>Today</Grid>
      <Grid item>
        <Switch
          checked={props.feedDay === feedDayTomorrow}
          color={"primary"}
          onChange={(e) => {
            props.setFeedDay(e.target.checked ? feedDayTomorrow : feedDayToday);
          }}
          size={"medium"}
        />
      </Grid>
      <Grid item>Tomorrow</Grid>
    </Grid>
  );
};
