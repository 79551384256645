import { useEffect } from "react";
import { useStateProvider } from "state-provider/hooks";
import { useAppBackgroundLazyQuery } from "../graphql/generated/schema-types";

export function useAppBackgroundQueries(): boolean[] {
  const {
    state: { loggedIn },
  } = useStateProvider();
  const [execute, { error, loading }] = useAppBackgroundLazyQuery({
    notifyOnNetworkStatusChange: true,
  });
  useEffect(() => {
    if (loggedIn) {
      execute();
    }
  }, [execute, loggedIn]);
  return [loading && !!error];
}
