import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { PoolingNormalizationToolState } from "features/WorklistTools/PoolingNormalization/state";
import {
  is24WPlate,
  is384W_CCU_Plate,
} from "features/WorklistTools/shared/PlateHelpers";
import { useEffect } from "react";
import { MethodSettingsProps } from "../shared";

interface Intermediate96HeadProcessingProps extends MethodSettingsProps {
  poolingState: PoolingNormalizationToolState;
  handleDeadPlateTypeChange: (value: string) => void;
}

export const Intermediate96HeadProcessing = ({
  poolingInternalState,
  poolingState,
  handleMethodSettingsChanged,
  handleDeadPlateTypeChange,
}: Intermediate96HeadProcessingProps) => {
  const int196HeadProcessing =
    poolingInternalState.methodSettings.int196HeadProcessing;
  useEffect(() => {
    if (
      is24WPlate(
        poolingState.intPlateInfo[0].rows.length,
        poolingState.intPlateInfo[0].cols.length
      )
    ) {
      handleMethodSettingsChanged("int196HeadProcessing", false);
    }
  }, [poolingState.intPlateInfo]);

  useEffect(() => {
    if (!int196HeadProcessing && is384W_CCU_Plate(poolingState.deadPlateType)) {
      handleDeadPlateTypeChange("");
    }
  }, [int196HeadProcessing]);

  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={is24WPlate(
              poolingState.intPlateInfo[0].rows.length,
              poolingState.intPlateInfo[0].cols.length
            )}
            checked={int196HeadProcessing}
            onChange={(e) =>
              handleMethodSettingsChanged(
                "int196HeadProcessing",
                e.target.checked
              )
            }
            name="intermediate1-96head-processing"
            color="primary"
            size="small"
          />
        }
        label={<Typography>Intermediate 1 96W Head Processing</Typography>}
      />
      <Tooltip
        title="96w head (using 300µL NTRs) will be utilized to remove supernatant, 
          resuspend the pellet, and take the count aliquot from Int1"
      >
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
