import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  DashboardState,
  IDashboardAction,
  IDashboardState,
} from "./initial-state";
import { dashboardStateReducer } from "./reducer";

export function useDashboardState(): {
  dashboardState: IDashboardState;
  dashboardDispatch: IApolloDispatch<IDashboardAction>;
} {
  const dashboardState = useReactiveVar(DashboardState);
  const dashboardDispatch = useMemo(
    () => ApolloStateDispatch(DashboardState, dashboardStateReducer),
    []
  );
  return useMemo(
    () => ({
      dashboardState,
      dashboardDispatch,
    }),
    [dashboardState, dashboardDispatch]
  );
}
