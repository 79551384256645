import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

interface WarningDialogProps {
  title: string;
  message: string[];
  isOpen: boolean;
  leftButtonText?: string;
  rightButtonText?: string;
  handleClose: (continueAction: boolean) => void;
}

export const WarningDialog = ({
  title,
  message,
  isOpen,
  leftButtonText,
  rightButtonText,
  handleClose,
}: WarningDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message.map((item) => (
          <li>{item}</li>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose(false)}>
          {leftButtonText ?? "Cancel"}
        </Button>
        <Button variant="contained" onClick={() => handleClose(true)}>
          {rightButtonText ?? "Continue"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
