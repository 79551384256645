import { gql } from "graphql-request";

export const Ticket = gql`
  fragment Ticket on Ticket {
    ticketId
    title
    priority
    status
    description
    createdByUserId
    dateCreated
    assetId
    dateEncountered
    lastUpdated
    ticketAssignees {
      userId
    }
    ticketFollowers {
      userId
    }
    asset {
      assetId
      asset
      assetType {
        assetTypeId
        assetType
      }
    }
    ticketType {
      ticketTypeId
      ticketType
      userGroup {
        groupId
        groupName
      }
    }
    percentComplete
    slackMessageTimestamp
    ticketUpdates {
      dateUpdated
      updatedByUserId
      summary
    }
  }
`;

export const TicketAttachments = gql`
  fragment TicketAttachments on Ticket {
    ticketId
    ticketAttachments {
      ticketAttachmentId
      attachmentUrl
      attachmentName
      dateUploaded
      uploadedBy
    }
  }
`;
export const TicketAndAttachments = gql`
  fragment TicketAndAttachments on Ticket {
    ...Ticket
    ...TicketAttachments
  }
  ${Ticket}
  ${TicketAttachments}
`;
