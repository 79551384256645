import {
  CollectionTaskInput,
  Maybe,
  Scalars,
  TaskPriorityEnum,
  TaskStatusEnum,
} from "graphql/generated/schema-types";

export class UpdateIncomingSampleCollectionTaskInputStub
  implements CollectionTaskInput
{
  task?: Maybe<Scalars["String"]>;
  startTimeScheduled?: Maybe<Scalars["DateTime"]>;
  endTimeScheduled?: Maybe<Scalars["DateTime"]>;
  startTimeActual?: Maybe<Scalars["DateTime"]>;
  endTimeActual?: Maybe<Scalars["DateTime"]>;
  userAssignedTo?: Maybe<Scalars["Int"]>;
  groupAssignedTo?: Maybe<Scalars["Int"]>;
  systemAssignedTo?: Maybe<Scalars["Int"]>;
  folderParentId?: Maybe<Scalars["Int"]>;
  displayInsideIncomingSamplesFolder?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<TaskStatusEnum>;
  priority?: Maybe<TaskPriorityEnum>;
}
