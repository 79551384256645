import {
  Button,
  IconButton,
  Link,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useState } from "react";
import styled from "styled-components/macro";
import * as Yup from "yup";
import {
  UpdateMethodDocumentationInput,
  useUpdateRunTaskTypeDocumentationMutation,
} from "graphql/generated/schema-types";

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const Container = styled.div`
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
const validationSchema = Yup.object().shape<{ url?: string | null }>({
  url: Yup.string().url("Must be valid url").nullable().notRequired(),
});

export function EditDocumentation({
  taskType,
}: {
  taskType: UpdateMethodDocumentationInput;
}): JSX.Element {
  const [updateDocumentation] = useUpdateRunTaskTypeDocumentationMutation();
  const [canEdit, setCanEdit] = useState(false);
  const { values, handleSubmit, isSubmitting, handleChange, errors, touched } =
    useFormik<{
      url: string;
    }>({
      initialValues: { url: taskType.documentationUrl ?? "" },
      validationSchema,
      onSubmit: (values) =>
        updateDocumentation({
          variables: {
            input: { ...taskType, documentationUrl: values.url },
          },
        }).then(() => setCanEdit(false)),
    });
  return (
    <Container>
      {canEdit ? (
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            id={"url"}
            value={values.url}
            variant={"outlined"}
            size={"small"}
            onChange={handleChange}
            helperText={errors.url && touched.url && errors.url}
            error={!!(errors.url && touched.url && errors.url)}
          />
          <Button type={"submit"} disabled={isSubmitting} variant={"contained"}>
            Save
          </Button>
        </StyledForm>
      ) : taskType.documentationUrl ? (
        <Tooltip title={taskType.documentationUrl!}>
          <Link href={taskType.documentationUrl!} target={"__blank"}>
            Link
          </Link>
        </Tooltip>
      ) : (
        <Typography>No Documentation</Typography>
      )}
      {!canEdit && (
        <IconButton size={"small"} onClick={() => setCanEdit(true)}>
          <Edit />
        </IconButton>
      )}
    </Container>
  );
}
