import { makeVar } from "@apollo/client";
import { DashboardTask } from "graphql/generated/schema-types";
import { ContextMenuDialogs } from "../shared/interfaces";
import { DashboardActions } from "./action";

export interface IDashboardState {
  tabIndex?: number;
  dashboardTasks?: DashboardTask[];
  queryVariables?: string;
  dialogOpen?: boolean;
  selectedDialog?: ContextMenuDialogs;
  selectedTasks?: DashboardTask[];
}

export interface IDashboardAction {
  type: DashboardActions;
  payload?: IDashboardState;
}

export const DashboardInitialState: IDashboardState = {
  tabIndex: 0,
  dashboardTasks: [],
  queryVariables: "getOverdueTasks",
  dialogOpen: false,
  selectedTasks: [],
};

export const DashboardState = makeVar<IDashboardState>(DashboardInitialState);
