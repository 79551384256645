import {
  CollectionSortInput,
  SortEnumType,
} from "graphql/generated/schema-types";

export const DefaultIncomingCollectionSort: CollectionSortInput[] = [
  {
    dateTimeOfArrival: SortEnumType.Desc,
  },
];
