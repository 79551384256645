import { TextField } from "@mui/material";
import {
  GetTicketInfoQuery,
  useAddTicketUpdateMutation,
} from "graphql/generated/schema-types";
import { useRef, useState } from "react";
import { ChatEntry, ChatWindow } from "shared-components/ChatWindow/ChatWindow";
import styled from "styled-components/macro";

const TicketUpdatesContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 10px;
`;
export function UpdateChatWindow({
  ticket,
  originalPersonUserId,
}: {
  originalPersonUserId: number;
  ticket: GetTicketInfoQuery["ticketInfo"];
}): JSX.Element {
  const chatEntries: ChatEntry[] =
    ticket?.ticketUpdates?.map((update) => ({
      __type:
        update?.updatedByUserId === originalPersonUserId
          ? "OriginalPerson"
          : "Reply",
      message: update?.summary ?? "",
      timestamp: update?.dateUpdated,
      userId: update?.updatedByUserId ?? 0,
    })) ?? [];
  const [addUpdate, { loading }] = useAddTicketUpdateMutation();
  const [updateText, setUpdateText] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <TicketUpdatesContainer>
      <ChatWindow height={"200px"} chat={chatEntries} />
      <TextField
        placeholder={"Add Update"}
        variant={"outlined"}
        size={"small"}
        value={updateText}
        disabled={loading}
        onChange={(e) => {
          if ((e.nativeEvent as InputEvent).inputType !== "insertLineBreak")
            setUpdateText(e.target.value);
        }}
        multiline
        inputRef={inputRef}
        inputProps={{
          onKeyUp: (e) => {
            if (updateText === "") return;
            if (e.key === "Enter" && e.shiftKey) {
              setUpdateText((current) => current + "\n");
              return;
            }
            if (e.key === "Enter" && ticket?.ticketId) {
              addUpdate({
                variables: {
                  input: {
                    ticketId: ticket.ticketId,
                    summary: updateText.trim(),
                  },
                },
              }).catch((e) => console.log(e));
              setUpdateText("");
              inputRef.current?.focus();
              return;
            }
          },
        }}
      />
    </TicketUpdatesContainer>
  );
}
