import {
  IncomingSampleActions,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import {
  CollectionTaskInput,
  useUpdateCollectionTasksMutation,
} from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import { StyledCard } from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { UpdateMultipleSampleTaskForm } from "./UpdateMultipleSampleTaskForm";

export function UpdateMultipleSampleTaskModal(): JSX.Element {
  const [updateTasks] = useUpdateCollectionTasksMutation();
  const {
    incomingSampleState: { selectedTasks },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  const handleSubmit: FormikSubmit<CollectionTaskInput> = (values) => {
    return updateTasks({
      variables: {
        inputs: selectedTasks!.map((taskId) => {
          values.incomingSampleTaskId = taskId;
          return values;
        }),
      },
    })
      .then(() =>
        incomingSampleDispatch({
          type: IncomingSampleActions.CLOSE_MODAL,
        })
      )
      .catch((err) => console.log(err));
  };
  const windowSize = useWindowSize();
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <UpdateMultipleSampleTaskForm
        initialValues={{}}
        onSubmit={handleSubmit}
      />
    </StyledCard>
  );
}
