import { animated } from "@react-spring/web";
import { CreateTicketModal } from "features/Tickets/components/Forms/Create/Ticket/Modal";
import { EditTicketModal } from "features/Tickets/components/Forms/Edit/Ticket/Modal";
import { TicketModals } from "features/Tickets/components/ModelsAndDialogs/modal.enum";
import { useTicketState } from "features/Tickets/state/hooks";
import React from "react";
import { useModalTransition } from "shared-components/ModalsAndDialogs/hooks";
import { MergeTicketModal } from "../Forms/Merge/Modal";

export function TicketModalContent(): JSX.Element {
  const {
    ticketState: { currentModal },
  } = useTicketState();
  const transition = useModalTransition(currentModal);
  return transition((props, currentModal) => {
    switch (currentModal) {
      case TicketModals.CreateTicket:
        return (
          <animated.div style={props}>
            <CreateTicketModal />
          </animated.div>
        );
      case TicketModals.MergeTicket:
        return (
          <animated.div style={props}>
            <MergeTicketModal />
          </animated.div>
        );
      case TicketModals.EditTicket:
        return (
          <animated.div style={props}>
            <EditTicketModal />
          </animated.div>
        );
      default:
        return <React.Fragment />;
    }
  });
}
