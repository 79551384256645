import {
  IncomingSampleActions,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import {
  UpdateIncomingSampleCollectionInput,
  useUpdateIncomingSampleCollectionMutation,
} from "graphql/generated/schema-types";
import { useGetIncomingSampleCollection } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import { UpdateIncomingSampleCollectionInputMapper } from "mappings/incomingSampleCollection";
import { StyledCard } from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { UpdateSampleCollectionForm } from "./UpdateSampleCollectionForm";

export function UpdateSampleCollectionModal(): JSX.Element {
  const [updateCollections] = useUpdateIncomingSampleCollectionMutation();
  const {
    incomingSampleState: { selectedCollections },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  const collection = useGetIncomingSampleCollection(selectedCollections![0]);
  const input = UpdateIncomingSampleCollectionInputMapper(collection);
  const handleSubmit: FormikSubmit<UpdateIncomingSampleCollectionInput> = (
    values
  ) => {
    return updateCollections({
      variables: {
        inputs: [
          {
            primaryKey: selectedCollections![0],
            set: values,
          },
        ],
      },
    })
      .then(() =>
        incomingSampleDispatch({
          type: IncomingSampleActions.CLOSE_MODAL,
        })
      )
      .catch((err) => console.log(err));
  };
  const windowSize = useWindowSize();
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <UpdateSampleCollectionForm
        initialValues={input}
        onSubmit={handleSubmit}
      />
    </StyledCard>
  );
}
