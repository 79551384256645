import React from "react";
import { AddRackOptions } from "../../constants";
import { DateSelector } from "../selectors/DateSelector";
import { IncludeInactiveWellsCheckbox } from "../selectors/IncludeInactiveWellsCheckbox";
import { NumberOfDuplicatesSelector } from "../selectors/NumberOfDuplicatesSelector";
import { PassageNumberSelector } from "../selectors/PassageNumberSelector";
import { RunSelector } from "../selectors/RunSelector";

interface ConsolidationProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const Consolidation = ({
  values,
  setFieldValue,
}: ConsolidationProps) => {
  return (
    <React.Fragment>
      <RunSelector values={values} setFieldValue={setFieldValue} />
      <NumberOfDuplicatesSelector
        numberOfDuplicates={[1, 2, 3]}
        values={values}
        setFieldValue={setFieldValue}
      />
      <PassageNumberSelector values={values} setFieldValue={setFieldValue} />
      <DateSelector values={values} setFieldValue={setFieldValue} />
      <IncludeInactiveWellsCheckbox
        values={values}
        setFieldValue={setFieldValue}
      />
    </React.Fragment>
  );
};
