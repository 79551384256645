import { useReactiveVar } from "@apollo/client";
import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useDecrementRacks, useRemoveRack } from "../state/handlers";
import { TubeLabellerState } from "../state/initial-state";
import { Rack } from "./Rack";
import { RackHeaderInfo } from "./RackHeaderInfo";

const StyledRacksContainer = styled.div`
  display: grid;
  height: 100%;
  overflow: auto;
  padding: 20px 40px;
  grid-template: 1fr 1fr / 1fr 1fr 1fr;
  grid-gap: 10px 50px;
  grid-auto-flow: column;
`;

const StyledRackActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr;
`;

export const RacksContainer = () => {
  const tubeLabellerState = useReactiveVar(TubeLabellerState);
  const rackCount = [1, 2, 3, 4, 5, 6];
  const removeRack = useRemoveRack();
  const decrementRack = useDecrementRacks();

  const handleRemoveRackClick = (rack: number) => {
    tubeLabellerState.tubeLabellerInfo.splice(rack - 1, 1);
    removeRack(rack);
    tubeLabellerState.worklist.forEach((row) => {
      if (row.dSTRack >= rack) {
        row.dSTRack = row.dSTRack - 1;
        row.sRCRack = row.sRCRack - 1;
      }
    });
    console.log(tubeLabellerState.worklist);
  };

  return (
    <StyledRacksContainer>
      {rackCount.map((rack) => (
        <div key={rack}>
          <RackHeaderInfo rackNumber={rack} />
          <Rack rackIndex={rack} />
          <StyledRackActions>
            <Button
              variant="outlined"
              onClick={() => handleRemoveRackClick(rack)}
              disabled={rack >= tubeLabellerState.currentRackIndex}
            >
              Remove Rack
            </Button>
          </StyledRackActions>
        </div>
      ))}
    </StyledRacksContainer>
  );
};
