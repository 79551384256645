import { WorklistValues } from "features/WorklistTools/shared/interfaces";
import { ArrayContainsDuplicateValue } from "helpers/array-helpers";
import { Alert, AlertType } from "shared-components/toast";
import {
  DestPlateInfoState,
  Twelve24TCToolActions,
  WorklistValuesState,
} from "../../state";
import { errorPRVDivDestWell, topupCalc } from "../../Twelve24TCToolHelpers";

export const addDestinationWellMapping = (
  dispatch: (action: { type: string; payload: any }) => void,
  sourcePlateIndex: number,
  wellMapping: WorklistValues[],
  destPlateInfo: DestPlateInfoState[],
  worklistValues: WorklistValuesState,
  destSelection: any,
  isStamping: boolean,
  methodSettings: any
) => {
  if (errorPRVDivDestWell(worklistValues, methodSettings)) {
    Alert({
      type: AlertType.ERROR,
      message: errorPRVDivDestWell(worklistValues, methodSettings),
    });
    return;
  }

  if (topupCalc(worklistValues, methodSettings, destPlateInfo)) {
    Alert({
      type: AlertType.ERROR,
      message: topupCalc(worklistValues, methodSettings, destPlateInfo),
    });
    return;
  }
  dispatch(
    Twelve24TCToolActions.SOURCE_TO_DEST_ADDED({
      index: sourcePlateIndex,
      selections: wellMapping,
    })
  );
};
