import { Checkbox } from "@mui/material";
import { FilterProps } from "react-table";

export function BooleanFilter<T extends Record<string, unknown>>({
  column: { filterValue, setFilter },
}: FilterProps<T>): JSX.Element {
  return (
    <Checkbox
      autoFocus={true}
      indeterminate={filterValue === null || filterValue === undefined}
      checked={filterValue === "true" ?? false}
      onChange={() => {
        console.log(filterValue);
        if (filterValue === null || filterValue === undefined)
          setFilter("true");
        else if (filterValue === "false") {
          setFilter(null);
        } else {
          setFilter("false");
        }
      }}
    />
  );
}
