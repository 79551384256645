import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useAppReduxSelector } from "hooks/reduxHooks";
import { useState } from "react";

interface StampVolDialogProps {
  isOpen: boolean;
  handleClose: (stampVol: number) => void;
}

export const StampVolDialog = ({
  isOpen,
  handleClose,
}: StampVolDialogProps) => {
  const worklistValues = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.worklistValues.thawStamp
  );
  const [stampVol, setStampVol] = useState(0);

  const submitStampVol = (close: boolean) => {
    if (stampVol > 0 && close) {
      handleClose(stampVol);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => submitStampVol(false)}>
      <DialogTitle>Stamp Volume</DialogTitle>
      <DialogContent>
        {stampVol > 200 ? (
          <TextField
            error
            type="number"
            value={stampVol}
            onFocus={(event) => {
              event.target.select();
            }}
            onChange={(e) => setStampVol(parseInt(e.target.value))}
            helperText="Stamp Vol cannot be greater than Operating Vol"
          />
        ) : (
          <TextField
            value={stampVol}
            type="number"
            onFocus={(event) => {
              event.target.select();
            }}
            onChange={(e) => setStampVol(parseInt(e.target.value))}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={stampVol > 200} onClick={() => submitStampVol(true)}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
