import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AddRackOptions } from "features/TubeLabeller/constants";
import {
  useGetActivePooling1224TcPlatesQuery,
  useGetCellNumberFromNormalizedPassagePlateLazyQuery,
  useGetResuspensionVolFromPooling1224TcPlateLazyQuery,
} from "graphql/generated/schema-types";

interface Pooling1224TCPlateSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}
export const Pooling1224TCPlateSelector = ({
  values,
  setFieldValue,
}: Pooling1224TCPlateSelectorProps) => {
  const { data: activePlates } = useGetActivePooling1224TcPlatesQuery({});
  const [getCellNumber] = useGetCellNumberFromNormalizedPassagePlateLazyQuery({
    onCompleted: (data) => {
      setFieldValue("cellNumber", data.cellNumberFromNormalizedPassagePlate);
    },
    fetchPolicy: "network-only",
  });
  const [getResuspensionVol] =
    useGetResuspensionVolFromPooling1224TcPlateLazyQuery({
      onCompleted: (data) => {
        setFieldValue(
          "resuspensionVol",
          data.resuspensionVolFromPooling1224TCPlate
        );
      },
      fetchPolicy: "network-only",
    });

  const handlePlateSelectionChange = (plateBarcode: string) => {
    setFieldValue("selectedPlate", plateBarcode);
    getCellNumber({
      variables: {
        plateBarcode: plateBarcode,
      },
    });
    getResuspensionVol({
      variables: {
        plateBarcode: plateBarcode,
      },
    });
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Plate</InputLabel>
      <Select
        labelId="plate-label"
        label="Plate"
        id="plate"
        value={values.selectedPlate}
        onChange={(e) => handlePlateSelectionChange(e.target.value)}
      >
        {activePlates?.activePooling1224TCPlates?.map((item) => (
          <MenuItem value={item ?? ""}>{item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
