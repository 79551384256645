import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  LookupArraySystem,
  useGetSystemsQuery,
} from "graphql/generated/schema-types";
import { useMemo } from "react";
import { GenericFieldProps } from "./FeedGroupFormRow";

export interface AssignedSystemProps extends GenericFieldProps {
  asams: any;
  automationMethodId: number | undefined | null;
  setFieldValue: (field: string, value: any) => void;
  value: number | undefined | null;
}

//TODO: Break out AssignedSystem into reusable component
export const AssignedSystem = (props: AssignedSystemProps) => {
  const systems = useMemo(
    () =>
      props.asams && props.automationMethodId !== 0
        ? props.asams
            ?.filter(
              (asam: any) =>
                asam?.automationMethodId === props.automationMethodId
            )
            .map(
              (asam: any) =>
                ({
                  systemId: asam?.arraySystemId,
                  systemName: `${asam?.arraySystem?.systemName} (${asam?.automationMethodCode})`,
                  arraySystemAutomationMethodId:
                    asam?.arraySystemAutomationMethodId,
                } as LookupArraySystem)
            )
        : [],
    [props.asams, props.automationMethodId]
  );

  const systemsQuery = useGetSystemsQuery({
    notifyOnNetworkStatusChange: true,
  });

  const bySystemId = (a: LookupArraySystem, b: LookupArraySystem) => {
    if (!a?.systemId && !b?.systemId) return 0;
    if (!b?.systemId || (a?.systemId && a.systemId > b.systemId)) return 1;
    if (!a?.systemId || (b?.systemId && a.systemId < b.systemId)) return -1;
    return 0;
  };

  const options =
    (systems.length
      ? systems
      : systemsQuery.data?.systems?.map((s) => s as LookupArraySystem)
    )?.sort(bySystemId) ?? [];

  return (
    <>
      <Autocomplete
        disableClearable
        disabled={props.toggleDisabled}
        getOptionLabel={(option) => option!.systemName!}
        id="arraySystemId"
        onBlur={props.handleBlur}
        onChange={(_e, value) => {
          props.setFieldValue(
            `feedGroupArray[${props.index}].arraySystemId`,
            value.systemId
          );
          props.setFieldValue(
            `feedGroupArray[${props.index}].arraySystemAutomationMethodId`,
            value.arraySystemAutomationMethodId
          );
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Assigned System"
            variant="outlined"
            error={!!props.formikErrors}
            helperText={props.formikErrors}
          />
        )}
        style={{ width: "100%" }}
        value={options.find((e: any) => e.systemId === props.value)}
      />
    </>
  );
};
