import { useReactiveVar } from "@apollo/client";
import { SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { is96WellPlate } from "features/WorklistTools/shared/WorklistHelpers";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import {
  buildThawMappingFile,
  calculateTransferVolumes,
  getMaxStampVolForPlate,
  staggerWells,
} from "../helpers";
import { ThawToolInternalAction } from "./action";
import { ThawToolInternalState } from "./initial-state";
import { useThawToolStateProvider } from "./StateProvider";

export const useHandleRandomizeClick = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (value: boolean) => () => {
    thawToolDispatch({
      type: ThawToolInternalAction.SET_RANDOMIZE_WELLS,
      payload: {
        randomizeWells: value,
      },
    });
  };
};

export const useHandleDestSelectionProcessClick = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (value: SelectionProcess) => {
    if (value !== null) {
      thawToolDispatch({
        type: ThawToolInternalAction.SET_DEST_SELECTION_PROCESS,
        payload: {
          destSelectionProcess: value,
        },
      });
    }
  };
};

export const useHandleSourceWellsSelected = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (value: any) => {
    value = value.map((well: any) => ({
      plateIndex: well.props.plateIndex,
      plateWellId: well.props.plateWellId,
      isSelectable: well.node.classList.contains("not-selectable")
        ? false
        : true,
    }));
    thawToolDispatch({
      type: ThawToolInternalAction.SET_SELECTED_SOURCE_WELLS,
      payload: {
        selectedSourceWells: value,
      },
    });
  };
};

export const useHandleMethodSettingsUpdated = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (key: string, value: any) => {
    thawToolDispatch({
      type: ThawToolInternalAction.UPDATE_METHOD_SETTINGS,
      payload: {
        key,
        value,
      },
    });
  };
};

export const useHandleMethodSettingsUpdatedByTemplate = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (methodSettings: any) => {
    thawToolDispatch({
      type: ThawToolInternalAction.UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
      payload: {
        runTaskId: methodSettings.runTaskId ?? undefined,
        destPlateCode: methodSettings.destPlateCode,
        resuspensionVol: methodSettings.resuspensionVol,
        topupVol: methodSettings.topupVol,
        gForce: methodSettings.gForce,
        rpm: methodSettings.rpm,
        spinTime: methodSettings.spinTime,
        spinAccel: methodSettings.spinAccel,
        spinDecel: methodSettings.spinDecel,
      },
    });
  };
};

export const useSetRequiredWarnings = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (value: string[]) => {
    thawToolDispatch({
      type: ThawToolInternalAction.SET_REQUIRED_WARNINGS,
      payload: {
        requiredWarnings: value,
      },
    });
  };
};

export const useSetOptionalWarnings = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (value: string[]) => {
    thawToolDispatch({
      type: ThawToolInternalAction.SET_OPTIONAL_WARNINGS,
      payload: {
        optionalWarnings: value,
      },
    });
  };
};

export const useSetWorklist = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (worklist: any[]) => {
    thawToolDispatch({
      type: ThawToolInternalAction.SET_WORKLIST,
      payload: {
        worklist: worklist,
        worklistPreviewOpen: true,
      },
    });
  };
};

export const useSetWorkistPreviewOpen = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  return (worklistPreviewOpen: boolean) => {
    thawToolDispatch({
      type: ThawToolInternalAction.SET_WORKLIST_PREVIEW_OPEN,
      payload: {
        worklistPreviewOpen: worklistPreviewOpen,
      },
    });
  };
};

export const useGenerateWorklistClick = () => {
  const { thawToolDispatch } = useThawToolStateProvider();
  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );
  const dispatch = useAppReduxDispatch();
  const thawToolInternalState = useReactiveVar(ThawToolInternalState);
  const methodSettings = thawToolInternalState.methodSettings;
  const getDestPlateInfo = (i: number) => thawWorklistToolInfo.destPlateInfo[i];
  const getThawStamp = () => thawWorklistToolInfo.worklistValues.thawStamp;

  let worklist = [
    {
      task: "Thaw",
      details: "",
      dispenseVol:
        thawToolInternalState.methodSettings.resuspensionVol.toString(),
      transferVol: "",
      sourcePlateBarcode: `${thawWorklistToolInfo.sourcePlateInfo[0].plateBarcode}`,
      sourceWellID: "",
      destinationPlateType: "",
      destinationPlateBarcode: "",
      destinationWellID: "",
    },
  ];
  //get source plateInfo
  for (const well of thawWorklistToolInfo.sourcePlateInfo[0].wellInfo) {
    worklist.push({
      task: "ThawTube",
      details: `TubeBarcode|${well.barcode}`,
      dispenseVol: "",
      transferVol: "",
      sourcePlateBarcode: `${thawWorklistToolInfo.sourcePlateInfo[0].plateBarcode}`,
      sourceWellID: `${String.fromCharCode(64 + well.rowPos)}${well.colPos}`,
      destinationPlateType: "",
      destinationPlateBarcode: "",
      destinationWellID: "",
    });
  }

  //get dest plate info
  for (const plate of thawWorklistToolInfo.destPlateInfo) {
    if (plate.rows.length > 0 && plate.cols.length > 0) {
      let transferVol = "500";
      switch (plate.plateType) {
        case "Cos_12_FL_L_3513":
          transferVol = "500";
          break;
        case "Cos_24_FL_L_3526":
          transferVol = "250";
          break;
        default:
          transferVol = "500";
      }
      const thawDetail = thawToolInternalState.methodSettings.includeTopUpVol
        ? `Preprocess|${+plate.preprocessPlate};InCytomat|${+plate.inCytomat};TopupVolume|${
            thawToolInternalState.methodSettings.topupVol
          }`
        : `Preprocess|${+plate.preprocessPlate};InCytomat|${+plate.inCytomat}`;
      worklist.push({
        task: "ThawDestination",
        details: thawDetail,
        dispenseVol: "",
        transferVol: (getThawStamp().length > 0
          ? getMaxStampVolForPlate(getThawStamp(), plate.index)
          : thawToolInternalState.methodSettings.topupVol
        ) //plate.operatingVol
          .toString(),
        sourcePlateBarcode: "",
        sourceWellID: "",
        destinationPlateType: `${plate.labwareTypeCode}`,
        destinationPlateBarcode: `${plate.plateBarcode}`,
        destinationWellID: "",
      });
    }
  }

  //get dead total plate info
  worklist.push({
    task: "DeadTotalPlate",
    details: "",
    dispenseVol: "",
    transferVol: "",
    sourcePlateBarcode: "",
    sourceWellID: "",
    destinationPlateType: `${thawWorklistToolInfo.deadPlateType}`,
    destinationPlateBarcode: `${thawWorklistToolInfo.deadPlateBarcode}`,
    destinationWellID: "",
  });
  //get well mapping if not 96W
  if (thawWorklistToolInfo.worklistValues.thawTransfer.length > 0) {
    calculateTransferVolumes(
      thawWorklistToolInfo.worklistValues.thawTransfer,
      thawToolInternalState.methodSettings.resuspensionVol,
      dispatch
    );
  }
  let thawTransfers = [...thawWorklistToolInfo.worklistValues.thawTransfer];
  thawTransfers.sort((a: any, b: any) => {
    const aDestIndex = parseInt(a["destPlateIndex"]);
    const bDestIndex = parseInt(b["destPlateIndex"]);
    const aColVal = parseInt(
      a["sourceWellId"].substring(1, a["sourceWellId"].length)
    );
    const bColVal = parseInt(
      b["sourceWellId"].substring(1, b["sourceWellId"].length)
    );

    const aRowVal = a["sourceWellId"].substring(0, 1).charCodeAt(0) - 65;
    const bRowVal = b["sourceWellId"].substring(0, 1).charCodeAt(0) - 65;
    return aDestIndex - bDestIndex || aColVal - bColVal || aRowVal - bRowVal;
  });
  if (thawTransfers.length > 2) {
    thawTransfers = staggerWells(thawTransfers);
  }
  for (const row of thawTransfers) {
    if (!is96WellPlate(getDestPlateInfo(row.destPlateIndex).labwareTypeCode)) {
      worklist.push({
        task: "ThawTransfer",
        details: "",
        dispenseVol: "",
        transferVol: (row.transferVol === ""
          ? thawToolInternalState.methodSettings.resuspensionVol
          : row.transferVol ?? 0
        ).toString(),
        sourcePlateBarcode: `${thawWorklistToolInfo.sourcePlateInfo[0].plateBarcode}`,
        sourceWellID: `${row.sourceWellId}`,
        destinationPlateType: `${
          getDestPlateInfo(row.destPlateIndex).labwareTypeCode
        }`,
        destinationPlateBarcode: `${
          getDestPlateInfo(row.destPlateIndex).plateBarcode
        }`,
        destinationWellID: `${row.destWellId}`,
      });
    }
  }
  const destinationStampIsNotA1Indexed = getThawStamp().some(
    (stamp) => stamp.wellId !== "A1"
  );

  if (
    is96WellPlate(getDestPlateInfo(0).labwareTypeCode) &&
    destinationStampIsNotA1Indexed
  ) {
    for (const row of getThawStamp()) {
      worklist.push({
        task: "ThawStamp",
        details: "",
        dispenseVol: "",
        transferVol: row.stampVol.toString(),
        sourcePlateBarcode: "",
        sourceWellID: "",
        destinationPlateType: getDestPlateInfo(row.plateIndex).labwareTypeCode,
        destinationPlateBarcode: getDestPlateInfo(row.plateIndex).plateBarcode,
        destinationWellID: row.wellId,
      });
    }
  }
  worklist.push({
    task: "MatrixRackCentrifugation",
    details: `VSpinRPM|${methodSettings.rpm};VSpinTime|${
      methodSettings.spinTime * 60
    };VSpinAccel|${methodSettings.spinAccel};VSpinDecel|${
      methodSettings.spinDecel
    };`,
    dispenseVol: "",
    transferVol: "",
    sourcePlateBarcode: "",
    sourceWellID: "",
    destinationPlateType: "",
    destinationPlateBarcode: "",
    destinationWellID: "",
  });
  const test = buildThawMappingFile(
    thawWorklistToolInfo.sourcePlateInfo,
    thawWorklistToolInfo.destPlateInfo,
    thawWorklistToolInfo.deadPlateBarcode
  );

  worklist = [...worklist, ...test];
  return () => {
    thawToolDispatch({
      type: ThawToolInternalAction.SET_WORKLIST,
      payload: {
        worklist: worklist,
        worklistPreviewOpen: true,
      },
    });
  };
};
