import React from "react";
import { Item, Menu } from "react-contexify";
import { MethodManagerActions } from "../state/actions";
import { useMethodManagerState } from "../state/hooks";
import { MethodManagerModals } from "./ModelsAndDialogs/model.enum";
import { ManualTaskTableContextMenuHidden } from "./Tables/ManualTaskTable/ManualTaskTable";

export function ManualTaskTableContextMenu(): JSX.Element {
  const {
    methodManagerDispatch,
    methodManagerState: { selectedManualTaskTypes },
  } = useMethodManagerState();

  if (selectedManualTaskTypes?.length && selectedManualTaskTypes.length > 1)
    return <React.Fragment />;
  return (
    <Menu
      id="ManualTaskTableContextMenu"
      onHidden={() => {
        setTimeout(() => ManualTaskTableContextMenuHidden(true), 200);
      }}
    >
      <Item
        onClick={() => {
          methodManagerDispatch({
            type: MethodManagerActions.OPEN_MODAL,
            payload: {
              currentModal: MethodManagerModals.EditManualTask,
            },
          });
        }}
      >
        Edit
      </Item>
    </Menu>
  );
}
