export enum TubeLabellerAction {
  SET_RACK_INDEX,
  SET_WORKLIST,
  REMOVE_RACK,
  DECREMENT_RACKS,
  ADD_RACK_INFO,
  REMOVE_RACK_INFO,
}

export interface ITubeLabellerAction {
  payload: any;
  type: TubeLabellerAction;
}
