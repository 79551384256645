import { DatePicker } from "@mui/lab";
import {
  Badge,
  Button,
  createFilterOptions,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import { UpdateTicketValidationSchema } from "features/Tickets/components/Forms/validation-schema";
import { FormikHelpers, useFormik } from "formik";
import {
  AppSuiteUser,
  GetTicketAssetsQuery,
  LookupAsset,
  LookupAssetType,
  LookupTicketType,
  TicketAttachment,
  TicketPriority,
  UpdateTicketInput,
  useGetTicketAttachmentsQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { ChangeEvent, useMemo, useState } from "react";
import {
  AssetField,
  AssetTypeField,
  TicketTypeField,
  UserField,
} from "shared-components/FormFields/FormFields";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import styled from "styled-components/macro";
import { useWindowSize } from "hooks/useWindowSize";
import { useTicketState } from "features/Tickets/state/hooks";
import { AttachmentCarousel } from "../../../ModelsAndDialogs/Summary/Attachments/AttachmentCarousel";

const TextButton = styled.div`
  border: 1px solid
    ${({ theme }) =>
      theme.palette.mode === "dark"
        ? "rgb(255, 255, 255, 0.2)"
        : "rgb(0, 0, 0, 0.2)"};
  border-radius: 5px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 20px;
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
`;

export function EditTicketForm({
  initialValues,
  onSubmit,
}: {
  initialValues: UpdateTicketInput;
  onSubmit: (
    values: UpdateTicketInput,
    helpers: FormikHelpers<UpdateTicketInput>
  ) => void;
}): JSX.Element {
  const {
    ticketState: { selectedTickets },
  } = useTicketState();
  const { loading, data } = useGetTicketAttachmentsQuery({
    variables: {
      ticketId: selectedTickets![0]! ?? 0,
    },
  });
  const ticketAttachmentInfo = useMemo(
    () => data?.ticketInfo,
    [data?.ticketInfo]
  );
  const props = useFormik<UpdateTicketInput>({
    initialValues,
    onSubmit,
    validationSchema: UpdateTicketValidationSchema,
  });
  const attachmentsTuple = useState(false);
  const [showAttachments, setShowAttachments] = attachmentsTuple;
  const transition = useTransition(showAttachments, {
    from: {
      maxWidth: "0vw",
      maxHeight: "20vh",
    },
    enter: {
      maxWidth: "60vw",
      maxHeight: "70vh",
    },
    leave: {
      maxWidth: "0vw",
      maxHeight: "0vh",
      overflow: "hidden",
    },
  });
  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;
  const windowSize = useWindowSize();
  const filter =
    createFilterOptions<
      NonNullable<GetTicketAssetsQuery["ticketAssets"]>[number]
    >();

  return (
    <>
      {!loading && (
        <Container>
          <StyledForm
            onSubmit={handleSubmit}
            min_width={
              windowSize.width! > 2000
                ? "20vw"
                : windowSize.width! > 1500
                ? "35vw"
                : "50vw"
            }
          >
            <StyledFormControl>
              <TicketTypeField
                id="ticketType-combobox"
                defaultValue={initialValues.ticketTypeId}
                value={values.ticketTypeId ?? null}
                onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
                  setFieldValue(
                    "ticketTypeId",
                    (newValue as Maybe<LookupTicketType>)?.ticketTypeId
                  )
                }
                label="Ticket Type"
                variant="outlined"
                helperText={
                  errors.ticketTypeId &&
                  touched.ticketTypeId &&
                  errors.ticketTypeId
                }
                error={
                  !!(
                    errors.ticketTypeId &&
                    touched.ticketTypeId &&
                    errors.ticketTypeId
                  )
                }
              />
            </StyledFormControl>
            <StyledFormControl>
              <DatePicker
                value={values.dateEncountered ?? null}
                onChange={(date) => setFieldValue("dateEncountered", date)}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    id="date-encountered-picker"
                    variant="outlined"
                    label="Date"
                    helperText={
                      errors.dateEncountered &&
                      touched.dateEncountered &&
                      errors.dateEncountered
                    }
                    error={
                      !!(
                        errors.dateEncountered &&
                        touched.dateEncountered &&
                        errors.dateEncountered
                      )
                    }
                    fullWidth
                  />
                )}
              />
            </StyledFormControl>
            <StyledFormControl>
              <AssetTypeField
                defaultValue={initialValues.assetTypeId}
                value={values.assetTypeId ?? null}
                id="ticketAssetType-combobox"
                onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
                  setFieldValue(
                    "assetTypeId",
                    (newValue as Maybe<LookupAssetType>)?.assetTypeId
                  )
                }
                label="Platform"
                variant="outlined"
                helperText={
                  errors.assetTypeId &&
                  touched.assetTypeId &&
                  errors.assetTypeId
                }
                error={
                  !!(
                    errors.assetTypeId &&
                    touched.assetTypeId &&
                    errors.assetTypeId
                  )
                }
              />
            </StyledFormControl>
            <StyledFormControl>
              <AssetField
                defaultValue={initialValues.assetId}
                value={values.assetId ?? null}
                id="ticketAsset-combobox"
                filter={(asset) =>
                  asset?.assetType?.assetTypeId === values.assetTypeId
                }
                filterOptions={(options, params) => {
                  const filtered = filter(options, params) as LookupAsset[];
                  return filtered;
                }}
                onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
                  setFieldValue(
                    "assetId",
                    (newValue as Maybe<LookupAsset>)?.assetId
                  )
                }
                label="Feature"
                variant="outlined"
                helperText={errors.assetId && touched.assetId && errors.assetId}
                error={!!(errors.assetId && touched.assetId && errors.assetId)}
              />
            </StyledFormControl>
            <StyledFormControl variant={"outlined"} fullWidth>
              <InputLabel>Priority</InputLabel>
              <Select
                defaultValue={initialValues.priority}
                label={"Priority"}
                fullWidth
                labelId="priority-label"
                id="priority-select"
                value={values.priority}
                onChange={(e) => {
                  setFieldValue("priority", e.target.value);
                }}
                inputProps={{
                  name: "priority",
                  id: "priority",
                }}
              >
                {Object.entries(TicketPriority).map(([_, priority], idx) => (
                  <MenuItem key={idx} value={priority}>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl>
              <UserField
                id="assignee-combobox"
                defaultValue={initialValues.ticketAssigneeIds}
                value={values.ticketAssigneeIds ?? null}
                onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
                  setFieldValue(
                    "ticketAssigneeIds",
                    (newValue as Maybe<AppSuiteUser>[])?.map(
                      (user) => user?.userId
                    )
                  )
                }
                label="Assignees"
                multiple
                variant="outlined"
                helperText={
                  errors.ticketAssigneeIds &&
                  touched.ticketAssigneeIds &&
                  errors.ticketAssigneeIds
                }
                error={
                  !!(
                    errors.ticketAssigneeIds &&
                    touched.ticketAssigneeIds &&
                    errors.ticketAssigneeIds
                  )
                }
              />
            </StyledFormControl>
            <StyledFormControl>
              <UserField
                id="follower-combobox"
                defaultValue={initialValues.ticketFollowerIds}
                value={values.ticketFollowerIds ?? null}
                onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
                  setFieldValue(
                    "ticketFollowerIds",
                    (newValue as Maybe<AppSuiteUser>[])?.map(
                      (user) => user?.userId
                    )
                  )
                }
                label="Followers"
                multiple
                variant="outlined"
                helperText={
                  errors.ticketFollowerIds &&
                  touched.ticketFollowerIds &&
                  errors.ticketFollowerIds
                }
                error={
                  !!(
                    errors.ticketFollowerIds &&
                    touched.ticketFollowerIds &&
                    errors.ticketFollowerIds
                  )
                }
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                variant="outlined"
                margin="none"
                fullWidth
                id="description"
                multiline
                label="Description"
                value={values.description ?? null}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.description &&
                  touched.description &&
                  errors.description
                }
                error={
                  !!(
                    errors.description &&
                    touched.description &&
                    errors.description
                  )
                }
              />
            </StyledFormControl>
            <TextButton
              onClick={() => setShowAttachments((current) => !current)}
            >
              <Badge
                badgeContent={ticketAttachmentInfo?.ticketAttachments?.length}
                color="primary"
              >
                {showAttachments ? "Hide" : "Edit"} Attachments
              </Badge>
            </TextButton>
            <Button
              variant={"contained"}
              type={"submit"}
              disabled={isSubmitting}
            >
              Save Changes
            </Button>
          </StyledForm>
          {!loading &&
            transition(
              (props, item) =>
                item && (
                  <animated.div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      ...props,
                    }}
                  >
                    <AttachmentCarousel
                      ticketId={ticketAttachmentInfo?.ticketId ?? 0}
                      attachments={
                        (ticketAttachmentInfo?.ticketAttachments as TicketAttachment[]) ??
                        []
                      }
                    />
                  </animated.div>
                )
            )}
        </Container>
      )}
    </>
  );
}
