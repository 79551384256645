import {
  Role,
  Scalars,
  UpdateAppSuiteUserInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class AppSuiteUserInputStub implements UpdateAppSuiteUserInput {
  roles: Role[];
  groups: number[];
  userId: Scalars["Int"];
  userName: Maybe<Scalars["String"]>;
  isActive: boolean;
}
