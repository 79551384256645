import {
  Button,
  Card,
  FormControl,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const StyledCard = styled(Card).attrs(
  (props: {
    width: string;
    height: string;
    max_width: string;
    min_width: string;
    min_height: string;
    padding: string;
    zindex: number;
  }) => props
)`
  width: ${({ width }: { width: string }) => width};
  height: ${({ height }: { height: string }) => height};
  max-width: ${({ max_width }: { max_width: string }) => max_width};
  min-width: ${({ min_width }: { min_width: string }) => min_width};
  min-height: ${({ min_height }: { min_height: string }) => min_height};
  padding: ${({ padding }: { padding: string }) => padding ?? "20px"};
  z-index: ${({ zindex }: { zindex: number }) => zindex};
  overflow-y: auto !important;
`;
export const StyledContainer = styled.div.attrs(
  ({ width, height }: { width: string; height: string }) => ({
    width,
    height,
  })
)`
  width: ${({ width }: { width: string }) => width};
  min-height: ${({ height }: { height: string }) => height};
  display: flex;
  flex-direction: column;
`;
interface StyledFormProps {
  flex?: boolean;
  background_color?: string;
  width?: string;
  min_width?: string;
}
export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: ${({ flex }: StyledFormProps) => (flex ? "wrap" : "")};
  background-color: ${({ background_color }: StyledFormProps) =>
    background_color};
  justify-content: ${({ flex }: StyledFormProps) =>
    flex ? "space-around" : ""};
  width: ${({ width }: StyledFormProps) => width ?? "100%"};
  min-width: ${({ min_width }: StyledFormProps) => min_width};
  padding: 10px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 80vh;
`;
export const StyledFormControl = styled(FormControl).attrs(
  ({
    width,
    max_width,
    flexdirection,
    margin_left,
    margin,
  }: {
    width?: string;
    flexdirection?: string;
    margin_left?: string;
    margin?: string;
    max_width?: string;
  }) => ({
    width,
    flexdirection,
    margin_left,
    max_width,
    margin,
  })
)`
  width: ${({ width }: { width?: string }) => width ?? "100%"};
  max-width: ${({ max_width }: { max_width?: string }) => max_width ?? ""};
  margin: ${({ margin }: { margin?: string }) => margin ?? "15px !important"};
  margin-left: ${({ margin_left }: { margin_left?: string }) => margin_left};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ flexdirection }) => flexdirection};
  position: relative;
`;
export const FormSubmitButton = styled(Button)`
  margin-top: 20px !important;
`;

export const StyledPageLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100vw;
  min-height: 100vh;
  overflow: ${({ disableScroll }: { disableScroll?: true | never }) =>
    disableScroll ? "hidden" : ""};
  height: ${({ disableScroll }: { disableScroll?: true | never }) =>
    disableScroll ? "100vh" : ""};
`;
export const StyledToolBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }: { justifyContent?: string }) =>
    justifyContent ?? "space-between"};
  height: 100%;
`;

export const SlideOverPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
export const StyledLink = styled(Link)`
  color: ${({
    theme: {
      palette: { mode },
    },
  }) => (mode === "light" ? "black" : "white")} !important;
  text-decoration: none;
`;

interface PillStyleProps {
  color: string;
  fontWeight?: string;
}

const StyledPill = styled.div`
  border: none;
  cursor: default;
  display: inline-flex;
  outline: 0;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  white-space: pre-wrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  overflow: hidden;
  padding: 7px 12px;
  text-overflow: ellipsis;
  color: white;
  background-color: ${({ color }: PillStyleProps) => color};
  font-weight: ${({ fontWeight }: PillStyleProps) => fontWeight ?? ""};
`;

export const Pill: FC<{
  color: string;
  label: string;
  fontWeight?: string;
}> = ({ color, label, fontWeight }) => (
  <StyledPill color={color} fontWeight={fontWeight}>
    {label}
  </StyledPill>
);
export const StyledMenu = styled.div`
  width: 250px;
  padding: 41px 0;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.3)" : ""};
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
`;
export const StyledListItem = styled(ListItem)`
  color: ${({ active }: { active?: boolean }) =>
    active ? "white !important" : ""};
  background-color: ${({ active }: { active?: boolean }) =>
    active ? "black !important" : ""};
`;
export const StyledListItemIcon = styled(ListItemIcon)`
  color: ${({ active }: { active?: boolean }) =>
    active ? "white !important" : ""};
`;
