import { Avatar, Tooltip } from "@mui/material";
import { forwardRef } from "react";
import styled from "styled-components/macro";
import { GetInitials } from "../../helpers/string-helpers";
import { useGetUser } from "../../hooks/cacheAccessHooks";
import { Pill } from "../styled-components";
import { ChatEntry } from "./ChatWindow";

const StyledChatEntryContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  gap: 2px;
  margin: 5px 0;
  justify-content: ${({ type }: { type: ChatEntry["__type"] }) =>
    type === "OriginalPerson" ? "" : "flex-end"};
`;
export const ChatBubble = forwardRef<HTMLDivElement, { entry: ChatEntry }>(
  ({ entry }, ref) => {
    const user = useGetUser(entry.userId);
    return (
      <StyledChatEntryContainer type={entry.__type} ref={ref}>
        {entry.__type === "OriginalPerson" ? (
          <>
            <Tooltip title={user?.userName ?? ""}>
              <Avatar>{GetInitials(user?.userName ?? "")}</Avatar>
            </Tooltip>
            <Pill color={"#1982FC"} label={entry.message} />
          </>
        ) : (
          <>
            <Pill color={"#1982FC"} label={entry.message} />
            <Tooltip title={user?.userName ?? ""}>
              <Avatar sizes={"small"}>
                {GetInitials(user?.userName ?? "")}
              </Avatar>
            </Tooltip>
          </>
        )}
      </StyledChatEntryContainer>
    );
  }
);
