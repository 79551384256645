import {
  RunTaskDetailInput,
  CreateRunTemplateStageInput,
  RunTemplateFragment,
  RunTemplatesRepeatDaysOfWeek,
  RunTemplateStage,
  RunTemplateTask,
  RunTemplateTaskDetail,
  TaskTypeEnum,
  TaskSelectionTypeEnum,
  UpdateRunTemplateInput,
  RunTemplatesRepeatDaysOfWeekInput,
  UpdateRunTemplateStageInput,
  RunTemplateTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { RunTaskDetailInputStub } from "mappings/object-stubs/RunActivityDetailInput";
import { CreateRunTemplateInputStub } from "mappings/object-stubs/RunTemplateInput";
import { RunTemplatesRepeatDaysOfWeekInputStub } from "mappings/object-stubs/RunTemplatesRepeatDaysOfWeekInput";
import {
  CreateRunTemplateStageInputStub,
  UpdateRunTemplateStageInputStub,
} from "mappings/object-stubs/RunTemplateStageInput";
import { RunTemplateTaskInputStub } from "mappings/object-stubs/RunTemplateTaskInput";
import { MethodsWithDetails } from "../constants/methodsWithDetails";

export function UpdateRunTemplateInputMapper(
  runTemplate: Maybe<RunTemplateFragment>
) {
  const _runTemplate = DeepCopy(runTemplate);
  if (_runTemplate?.groups) {
    (_runTemplate as unknown as UpdateRunTemplateInput).groupIds =
      _runTemplate.groups.map((entry) => entry!.groupId);
  }
  return _.pick(
    _runTemplate,
    _.keys(new CreateRunTemplateInputStub())
  ) as UpdateRunTemplateInput;
}

export function UpdateRunTemplateStageInputMapper(
  runTemplateStage: RunTemplateStage
) {
  const _runTemplateStage = DeepCopy(runTemplateStage);
  return _.pick(
    _runTemplateStage,
    _.keys(new UpdateRunTemplateStageInputStub())
  ) as UpdateRunTemplateStageInput;
}
export function CreateRunTemplateStageInputMapper(
  runTemplateStage: RunTemplateStage
) {
  const _runTemplateStage = DeepCopy(runTemplateStage);
  return _.pick(
    _runTemplateStage,
    _.keys(new CreateRunTemplateStageInputStub())
  ) as CreateRunTemplateStageInput;
}

export function UpdateRunTemplateTaskInputMapper(
  runTemplateTask: RunTemplateTask
) {
  const _runTemplateTask = DeepCopy(runTemplateTask);
  if (_runTemplateTask?.runTemplateTaskDetail)
    (_runTemplateTask as RunTemplateTaskInput).details =
      MethodsWithDetails.includes(_runTemplateTask?.method?.methodId ?? 0)
        ? runTemplateDetailInputMapper(_runTemplateTask.runTemplateTaskDetail)
        : null;
  if (_runTemplateTask?.runTemplatesRepeatDaysOfWeeks)
    (
      _runTemplateTask as RunTemplateTaskInput
    ).runTemplatesRepeatDaysOfWeeks = _runTemplateTask.runTemplatesRepeatDaysOfWeeks.map(
      (x) => runTemplateRepeatDaysOfWeekInputMapper(x!)
    );
  if (_runTemplateTask?.method)
    (_runTemplateTask as RunTemplateTaskInput).taskTypeDetails = {
      taskTypeId: _runTemplateTask.method.methodId,
      taskType: TaskSelectionTypeEnum.Method,
      documentationUrl: _runTemplateTask.method?.documentationUrl,
    };
  if (_runTemplateTask?.manualTaskType)
    (_runTemplateTask as RunTemplateTaskInput).taskTypeDetails = {
      taskTypeId: _runTemplateTask.manualTaskType.id,
      taskType: TaskSelectionTypeEnum.Manual,
      documentationUrl: _runTemplateTask.manualTaskType?.documentationUrl,
    };

  if (_runTemplateTask?.users) {
    (_runTemplateTask as unknown as RunTemplateTaskInput).userIds =
      _runTemplateTask.users.map((entry) => entry!.userId);
  }
  if (_runTemplateTask?.groups) {
    (_runTemplateTask as unknown as RunTemplateTaskInput).groupIds =
      _runTemplateTask.groups.map((entry) => entry!.groupId);
  }
  return _.pick(
    _runTemplateTask,
    _.keys(new RunTemplateTaskInputStub())
  ) as RunTemplateTaskInput;
}

export function runTemplateDetailInputMapper(
  runTemplateTaskDetail: RunTemplateTaskDetail
) {
  const _runTemplateTaskDetail = DeepCopy(runTemplateTaskDetail);
  const final = _.pick(
    _runTemplateTaskDetail,
    _.keys(new RunTaskDetailInputStub())
  ) as RunTaskDetailInput;
  final.parentId = runTemplateTaskDetail.runTemplateTaskId;
  final.runTaskDetailId = runTemplateTaskDetail.runTemplateTaskDetailsId;
  return final;
}

export function runTemplateRepeatDaysOfWeekInputMapper(
  runTemplateRepeatDaysOfWeek: RunTemplatesRepeatDaysOfWeek
) {
  const _runTemplateRepeatDaysOfWeek = DeepCopy(runTemplateRepeatDaysOfWeek);
  const final = _.pick(
    _runTemplateRepeatDaysOfWeek,
    _.keys(new RunTemplatesRepeatDaysOfWeekInputStub())
  ) as RunTemplatesRepeatDaysOfWeekInput;
  return final;
}
