// No groupIds for 1, 3, 4, 5, 13 (Software interns)
/**
 * @deprecated Replaced by `groupColors`
 */
export const colors = [
  "#4a4a4a", // 0 dark gray- Task w/ no group assigned
  "", //1 No groupId
  "#cc0052", //2 rose - Software
  "", //3 No groupId,
  "", //4 No groupId,
  "", //5 No groupId,
  "#994d00", //6 brown - QC
  "#993333", //7 red-brown - Blood Processsing
  "#005299", //8 blue - Gene Editing
  "#009933", //9 green - Engineers
  "#990099", //10 purple - Beta Cell Team
  "#e68a00", //11 orange - Facilities
  "#990000", //12 red - Project Management
  "", //13 - Software Interns
  "#cca300", //14 gold - Production
  "#5c5c3d", //15 green-gray - Differentiation
  "#262673", //16 midnight-blue - Lab Ops
  "#558000", //17 weird-green - Neural Differentiation
  "#026f73", //18 sea-green - Foresite
  "#c74516", //19 red-orange - Discovery Team
  "#826837", //20 brown - System 1
  "#2a0080", //21 blue-purple - Immuno
];

/**
 * @deprecated Replaced by `groupColors`
 */
export const lightColors = [
  "#ebebeb", // 0 light gray - Task w/ no group assigned
  "", //1 No groupId
  "#f5649e", //2 light-rose - Software
  "", //3 No groupId,
  "", //4 No groupId,
  "", //5 No groupId,
  "#faaa78", //6 light-brown/beige - QC
  "#fa3e3e", //7 light-red-brown - Blood Processsing
  "#cce7ff", //8 light-blue - Gene Editing
  "#80ffaa", //9 light-green - Engineers
  "#d102d1", //10 light-purple - Beta Cell Team
  "#f7d197", //11 light-orange - Facilities
  "#ff8080", //12 light-red - Project Management
  "", //13 - Software Interns
  "#ffe066", //14 light-gold/yellow - Production
  "#ccccb3", //15 light-green-gray - Differentiation
  "#9f9fdf", //16 light midnight blue/violet - Lab Ops
  "#ddff99", //17 light-weird-green/chartreuse - Neural Differentiation
  "#5be5eb", //18 light-sea-green - Foresite
  "#f08862", //19 light-red-orange - Discovery Team
  "#b8ad9a", //20 light-brown - System 1
  "#e3d4ff", //21 light blue-purple - Immuno
];

export const groupColors = [
  {
    groupName: "Beta Cell Team",
    dark: "#994d00",
    light: "#faaa78",
    isTeamLegend: true,
  },
  {
    groupName: "Blood Processing",
    dark: "#993333",
    light: "#fa3e3e",
    isTeamLegend: true,
  },
  {
    groupName: "FuncGen-Diff",
    dark: "#5c5c3d",
    light: "#ccccb3",
    isTeamLegend: true,
  },
  {
    groupName: "Discovery Team",
    dark: "#c74516",
    light: "#f08862",
    isTeamLegend: true,
  },
  {
    groupName: "Engineers",
    dark: "#009933",
    light: "#80ffaa",
    isTeamLegend: true,
  },
  {
    groupName: "Facilities",
    dark: "#e68a00",
    light: "#f7d197",
    isTeamLegend: false,
  },
  {
    groupName: "Foresite",
    dark: "#026f73",
    light: "#5be5eb",
    isTeamLegend: false,
  },
  {
    groupName: "FuncGen-Editing",
    dark: "#005299",
    light: "#cce7ff",
    isTeamLegend: true,
  },
  {
    groupName: "Immuno",
    dark: "#2a0080",
    light: "#e3d4ff",
    isTeamLegend: true,
  },
  {
    groupName: "Lab Ops",
    dark: "#262673",
    light: "#9f9fdf",
    isTeamLegend: false,
  },
  {
    groupName: "Neuarray",
    dark: "#558000",
    light: "#ddff99",
    isTeamLegend: true,
  },
  {
    groupName: "Production",
    dark: "#cca300",
    light: "#ffe066",
    isTeamLegend: true,
  },
  {
    groupName: "Project Management",
    dark: "#990000",
    light: "#ff8080",
    isTeamLegend: false,
  },
  {
    groupName: "QC",
    dark: "#990099",
    light: "#eb92eb",
    isTeamLegend: true,
  },
  {
    groupName: "Software",
    dark: "#cc0052",
    light: "#f5649e",
    isTeamLegend: false,
  },
  {
    groupName: "System 1",
    dark: "#826837",
    light: "#b8ad9a",
    isTeamLegend: false,
  },
  {
    groupName: "No Team Associated",
    dark: "#596b91",
    light: "#889bc2",
    isTeamLegend: true,
  },
];
