import { Divider } from "@mui/material";
import { useGetExistingMethodReservationLazyQuery } from "graphql/generated/schema-types";
import { useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect } from "react";
import { StyledToolbarSection } from "../../shared/components/styles/styled-components";
import { DeleteReservationButton } from "./GeneralOptionsButtons/DeleteReservationButton";
import { GenerateWorklistButton } from "./GeneralOptionsButtons/GenerateWorklistButton";
import { OpenTemplateButton } from "./GeneralOptionsButtons/OpenTemplateButton";
import { SaveTemplateButton } from "./GeneralOptionsButtons/SaveTemplateButton";
import { UploadWorklistButton } from "./GeneralOptionsButtons/UploadWorklistButton";

export const GeneralOptions = () => {
  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );

  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
    });

  const methodReservationId =
    (methodReservation &&
      methodReservation.existingMethodReservation &&
      methodReservation?.existingMethodReservation[0]?.methodReservationId) ||
    0;

  useEffect(() => {
    getExistingMethodReservation({
      variables: {
        where: {
          isCompleted: { eq: false },
          methodReservationsPlateBarcodes: {
            some: {
              plateBarcode: {
                eq: thawWorklistToolInfo.sourcePlateInfo[0].plateBarcode,
              },
            },
          },
        },
      },
    });
  }, [getExistingMethodReservation, thawWorklistToolInfo.sourcePlateInfo]);

  return (
    <React.Fragment>
      <StyledToolbarSection>
        <GenerateWorklistButton methodReservationId={methodReservationId} />
        <UploadWorklistButton />
        <OpenTemplateButton />
        <SaveTemplateButton />
        <DeleteReservationButton methodReservationId={methodReservationId} />
      </StyledToolbarSection>
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
    </React.Fragment>
  );
};
