import { DateRange } from "@mui/lab";
import {
  CollectionTaskFilterInput,
  IncomingSampleTaskTableEntryFragment,
} from "graphql/generated/schema-types";
import { GetDayRange } from "helpers/date-helpers";
import { Filters } from "react-table";

export const IncomingSampleTasksFilterReducer = (
  tableFilters: Filters<CollectionTaskFilterInput>
) => {
  const where: CollectionTaskFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "incomingSampleType.collection.project.projectName" as keyof IncomingSampleTaskTableEntryFragment:
        where!.and!.push({
          incomingSampleType: {
            collection: {
              project: {
                projectName: { contains: tableFilter.value },
              },
            },
          },
        });
        break;
      case "task":
        where!.and!.push({
          task: { contains: tableFilter.value },
        });
        break;
      case "status":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            status: {
              in: tableFilter.value,
            },
          });
        else where!.and!.filter((entry) => !entry.status);
        break;
      case "userAssignedTo":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            userAssignedTo: {
              in: tableFilter.value,
            },
          });
        else where!.and!.filter((entry) => !entry.userAssignedTo);
        break;
      case "groupAssignedTo":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            groupAssignedTo: {
              in: tableFilter.value,
            },
          });
        else where!.and!.filter((entry) => !entry.groupAssignedTo);
        break;
      case "startTimeScheduled":
        let range = tableFilter.value as DateRange<unknown>;
        if (range.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.startTimeScheduled);
          break;
        }
        if (tableFilter.value[1] === null) {
          range = GetDayRange(tableFilter.value[0]);
        }
        where!.and!.push({
          startTimeActual: { gt: range[0] },
        });
        where!.and!.push({
          startTimeActual: { lt: range[1] },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
