import { NotInterested } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled(NotInterested)`
  font-size: 16rem !important;
  color: red;
`;
const StyledTitle = styled.div`
  font-size: 6rem !important;
`;
function UnauthorizedPage({
  adminRestriction,
}: {
  adminRestriction?: boolean;
}): JSX.Element {
  sessionStorage.prevLocation = window.location.pathname;
  return (
    <StyledDiv>
      <StyledIcon />
      <StyledTitle>Unauthorized</StyledTitle>
      {adminRestriction ? (
        <h3>You have to be an administrator to access this resource. </h3>
      ) : (
        <h3>
          Please <a href="/login">Login</a> to access this resource
        </h3>
      )}
    </StyledDiv>
  );
}
export default UnauthorizedPage;
