import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import { CeligoScan } from "features/Dashboard/MethodDetails/CeligoScan/CeligoScan";
import { ManagePersonalTask } from "graphql/generated/schema-types";
import { useGetUser } from "hooks/cacheAccessHooks";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
// import { Methods } from "../../enums/Methods";
// import { SystemOneFeed } from "../MethodReservations/SystemOneFeed";
// import { SystemOnePassage } from "../MethodReservations/SystemOnePassage";
const StyledDialogContent = styled(DialogContent)`
  margin-bottom: 20px;
`;

const StyledBoldSpan = styled.span`
  font-weight: bold;
`;

interface TaskDetailsDialogProp {
  isOpen: boolean;
  handleClose: () => void;
  selectedTask: ManagePersonalTask;
}

export const TaskDetailsDialog = ({
  isOpen,
  handleClose,
  selectedTask,
}: TaskDetailsDialogProp) => {
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [activePlates, setActivePlates] = useState([
    {
      plateCreatedID: 0,
      plateBarcode: "",
      plateBarcodeAlias: "",
      samples: "",
      methodReservationID: 0,
    },
  ]);
  const createdByUser = useGetUser(selectedTask?.taskOwnerId);
  // const user = useGetUser(selectedTask?.userAssignedTo);
  // const group = useGetGroup(selectedTask?.groupAssignedTo);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"xl"}
      >
        <DialogTitle id="form-dialog-title">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {selectedTask.taskName}
          </div>
        </DialogTitle>
        <StyledDialogContent>
          {/* <p>{selectedTask.taskOwner?.userName}</p>
          {createdByUser ? (
            <p>
              Created By: <b>{createdByUser}</b>
            </p>
          ) : null}
          {user ? (
            <p>
              User Assigned To: <b>{user?.userName}</b>
            </p>
          ) : null}
          {group ? (
            <p>
              Group Assigned To: <b>{group?.groupName}</b>
            </p>
          ) : null} */}
          <Typography>
            <b>Scheduled Time: </b>
            {moment(selectedTask.startTimeScheduled).format("MM/DD/YY hh:mm A")}
          </Typography>
          {selectedTask.notes ? (
            <TextField
              style={{ marginBottom: "20px" }}
              disabled
              id="Notes"
              label="Notes"
              multiline
              rows={5}
              fullWidth
              variant="filled"
              value={selectedTask.notes}
            />
          ) : null}
          {/* {activePlates.length > 1 &&
          selectedTask.methodId !== Methods.CeligoScan &&
          selectedTask.methodId !== Methods.AutomatedFeed ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: collapseOpen ? "0px" : "25px",
              }}
            >
              <IconButton
                style={{ outline: 0 }}
                aria-label="expand row"
                size="small"
                onClick={() => setCollapseOpen(!collapseOpen)}
              >
                {collapseOpen ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </IconButton>
              <h6 style={{ position: "relative", top: "3px" }}>
                Active Plates
              </h6>
            </div>
          ) : null} */}
          <Collapse in={collapseOpen} unmountOnExit>
            {activePlates.length > 1 ? (
              <Table size="small" style={{ margin: "15px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>PlateBarcode</TableCell>
                    <TableCell>Sample</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activePlates.map((item) => (
                    <TableRow>
                      <TableCell>{item.plateBarcode}</TableCell>
                      <TableCell>{item.samples}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </Collapse>
          {/* <MethodReservationForm
            selectedTask={selectedTask}
            activePlates={activePlates}
          ></MethodReservationForm> */}
        </StyledDialogContent>
      </Dialog>
    </>
  );
};

// function MethodReservationForm({
//   selectedTask,
//   activePlates,
// }: {
//   selectedTask: Maybe<DashboardTask>;
//   activePlates: any;
// }): JSX.Element {
//   return <>{MethodReducer(selectedTask, activePlates)}</>;
// }

// function MethodReducer(selectedTask: any, activePlates: any): JSX.Element {
//   switch (selectedTask.methodId) {
//     case Methods.XTY_CustomPassage:
//       return (
//         <SystemOnePassage
//           selectedTask={selectedTask}
//           activePlates={activePlates}
//         />
//       );
//     case Methods.AutomatedFeed:
//       return (
//         <SystemOneFeed
//           selectedTask={selectedTask}
//           activePlates={activePlates}
//         />
//       );
//     case Methods.CeligoScan:
//       return <CeligoScan personalTask={selectedTask} />;
//     default:
//       return <React.Fragment />;
//   }
// }
