import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { BackdropOpen } from "App";
import {
  useGetArrayMethodLabwareTypesLazyQuery,
  useGetExistingMethodReservationLazyQuery,
  useGetIntermediateDeadAndDestPlateBarcodesMutation,
  useReserveMethodMutation,
} from "graphql/generated/schema-types";
import { GetUrl } from "helpers/get-url";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import { NewWorklistPreview } from "../shared/components/NewWorklistPreview";
import {
  LabwareTypeInfo,
  PoolingNormalizationStep,
  Worklist,
} from "../shared/interfaces";
import { is384W_CCU_Plate } from "../shared/PlateHelpers";
import {
  extractPlateCode,
  getDeadPlateTypeInfo,
  getDestinationPlateBarcodeSeries,
  getDestinationPlateBarcodeWithStringArray,
  getDestPlateTypeInfo,
  getIntPlateTypeInfo,
  getPlateCode,
  getRowIndexFromSelectedWell,
  getSourcePlateBarcodeFromIntermediatePlateBarcode,
  getSourcePlateTypeInfo,
  sortByCol,
} from "../shared/WorklistHelpers";
import { DestinationPlatesContainer } from "./plates/DestinationPlatesContainers";
import { IntermediatePlatesContainer } from "./plates/IntPlatesContainers";
import { SourcePlatesContainer } from "./plates/SourcePlatesContainer";
import { StampAndIntPlatesContainer } from "./plates/StampAndIntPlatesContainer";
import { StampPlatesContainer } from "./plates/StampPlatesContainer";
import { PoolingNormalizationToolSidebar } from "./sidebar/Sidebar";
import { PoolingNormalizationToolActions } from "./state";
import { PoolingNormalizationToolInternalAction } from "./state/action";
import { useSetWorkistPreviewOpen, useSetWorklist } from "./state/handlers";
import { PoolingNormalizationToolInternalState } from "./state/initial-state";
import { usePoolingNormalizationStateProvider } from "./state/StateProvider";

const steps = [
  "Select Source To Intermediate Mapping",
  "Select Stamp Pattern",
  "Select Stamp/Intermediate To Destination Mapping",
];
export const PoolingNormalization = () => {
  const { isConfirmed } = useConfirm();
  const poolingNormalizationToolInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );
  const methodSettings = poolingNormalizationToolInternalState.methodSettings;
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  const dispatch = useAppReduxDispatch();
  const [sourcePlateTypes, setSourcePlateTypes] = useState<LabwareTypeInfo[]>();
  const [destPlateTypes, setDestPlateTypes] = useState<LabwareTypeInfo[]>();
  const [intPlateTypes, setIntPlatesTypes] = useState<LabwareTypeInfo[]>();
  const [deadPlateTypes, setDeadPlatesTypes] = useState<LabwareTypeInfo[]>();
  const poolingState = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present
  );
  const sourcePlateInfo = poolingState.sourcePlateInfo;
  const destPlateInfo = poolingState.destPlateInfo;
  const intPlateInfo = poolingState.intPlateInfo;
  const deadPlateBarcode = poolingState.deadPlateBarcode;
  const deadPlateType = poolingState.deadPlateType;
  const worklistValues = poolingState.worklistValues;

  const [getAcceptablePlateTypes, { data: acceptablePlateTypes }] =
    useGetArrayMethodLabwareTypesLazyQuery({ fetchPolicy: "network-only" });
  const [getAssociatedBarcodes] =
    useGetIntermediateDeadAndDestPlateBarcodesMutation();
  const [reserveMethod, { loading }] = useReserveMethodMutation();
  const setWorklist = useSetWorklist();
  const setWorklistPreviewOpen = useSetWorkistPreviewOpen();

  useEffect(() => {
    if (
      poolingNormalizationToolInternalState.methodSettings
        .arraySystemAutomationMethodId
    ) {
      getAcceptablePlateTypes({
        variables: {
          where: {
            arraySystemAutomationMethodId: {
              eq: poolingNormalizationToolInternalState.methodSettings
                .arraySystemAutomationMethodId,
            },
          },
        },
      });
    }
  }, [
    poolingNormalizationToolInternalState.methodSettings
      .arraySystemAutomationMethodId,
  ]);

  useEffect(() => {
    if (acceptablePlateTypes) {
      setSourcePlateTypes(getSourcePlateTypeInfo(acceptablePlateTypes));
      setDestPlateTypes(getDestPlateTypeInfo(acceptablePlateTypes));
      setIntPlatesTypes(getIntPlateTypeInfo(acceptablePlateTypes));
      setDeadPlatesTypes(getDeadPlateTypeInfo(acceptablePlateTypes));
    }
  }, [acceptablePlateTypes]);

  useEffect(() => {
    const templateHasBeenLoaded =
      sourcePlateInfo[0].plateBarcode !== "" &&
      destPlateInfo.some(
        (e) => e.labwareTypeCode !== "" && e.plateBarcode === ""
      );
    if (templateHasBeenLoaded) {
      const usedPlates = destPlateInfo.filter((e) => e.labwareTypeCode !== "");
      getAssociatedBarcodes({
        variables: {
          sourcePlateBarcode: sourcePlateInfo[0].plateBarcode,
          destPlateCode:
            destPlateTypes?.find(
              (e) => e.labwareTypeCode === destPlateInfo[0].labwareTypeCode
            )?.defaultArrayPlateCode ??
            getPlateCode(destPlateInfo[0].labwareTypeCode),
          hasMultipleSourcePlateTypes: false,
          hasMultipleDestPlateTypes: false,
          has384DeadPlate: is384W_CCU_Plate(deadPlateType),
        },
      }).then((res) => {
        const barcodes = getDestinationPlateBarcodeSeries(
          res.data?.intermediateDeadAndDestPlateBarcodes
            ?.destinationPlateBarcode ?? "",
          usedPlates.length
        );
        for (let i = 0; i < usedPlates.length; i++) {
          dispatch(
            PoolingNormalizationToolActions.UPDATE_DESTINATION_PLATE_INFO({
              index: usedPlates[i].index,
              key: "plateBarcode",
              value: barcodes[i],
            })
          );
        }
      });
    }
  }, [sourcePlateInfo[0].plateBarcode]);

  useEffect(() => {
    const uniqueSourcePlateTypes = [
      ...new Set(
        sourcePlateInfo
          .map((e) => e.labwareTypeCode)
          .filter((labware) => labware !== "")
      ),
    ];
    const uniqueDestPlateTypes = [
      ...new Set(
        destPlateInfo
          .map((e) => e.labwareTypeCode)
          .filter((labware) => labware !== "")
      ),
    ];
    if (
      sourcePlateInfo[0].plateBarcode !== "" &&
      destPlateInfo[0].labwareTypeCode !== ""
    ) {
      const sourcePlateBarcode =
        methodSettings.int1CountAliquot === 1 &&
        poolingNormalizationToolInternalState.uploadedIntermediatePlate
          ? getSourcePlateBarcodeFromIntermediatePlateBarcode(
              intPlateInfo[0].plateBarcode
            )
          : sourcePlateInfo[0].plateBarcode;
      getAssociatedBarcodes({
        variables: {
          sourcePlateBarcode: sourcePlateBarcode,
          destPlateCode:
            extractPlateCode(destPlateInfo[0].plateBarcode) ??
            getPlateCode(destPlateInfo[0].labwareTypeCode),
          hasMultipleSourcePlateTypes: uniqueSourcePlateTypes.length > 1,
          hasMultipleDestPlateTypes: uniqueDestPlateTypes.length > 1,
          has384DeadPlate: is384W_CCU_Plate(deadPlateType),
        },
      }).then((res) => {
        const intPlateBarcode1 =
          res.data?.intermediateDeadAndDestPlateBarcodes
            ?.intermediatePlateBarcode1 ?? "";
        const intPlateBarcode2 =
          res.data?.intermediateDeadAndDestPlateBarcodes
            ?.intermediatePlateBarcode2 ?? "";
        if (methodSettings.int1CountAliquot === 0) return;
        if (poolingNormalizationToolInternalState.uploadedWorklist) {
          //After worklist is uploaded set state variable to false
          poolingNormalizationToolDispatch({
            type: PoolingNormalizationToolInternalAction.SET_STATE,
            payload: {
              key: "uploadedWorklist",
              value: false,
            },
          });
          return;
        }
        dispatch(
          PoolingNormalizationToolActions.UPDATE_AUX_PLATEBARCODES({
            intPlateBarcode:
              poolingNormalizationToolInternalState.uploadedIntermediatePlate
                ? intPlateInfo[0].plateBarcode
                : intPlateBarcode1,
            intPlateBarcode2: intPlateBarcode2,
            intPlateBarcode3: getDestinationPlateBarcodeWithStringArray(
              res.data!.intermediateDeadAndDestPlateBarcodes!
                .intermediatePlateBarcode2!,
              [intPlateBarcode1, intPlateBarcode2]
            ),
            deadPlateBarcode:
              res.data!.intermediateDeadAndDestPlateBarcodes!.deadPlateBarcode!,
          })
        );
      });
    }
  }, [sourcePlateInfo, destPlateInfo, deadPlateType]);

  const isCurrentSeedingPriorityEqualToNew = (
    existingSeedingPriority: string[],
    newSeedingPriority: string[]
  ) => {
    return newSeedingPriority.every((newSeeding) =>
      existingSeedingPriority.includes(newSeeding)
    );
  };

  const getUniqueDestPlateTypeWithSingleChannelSeeding = (): {
    unique_12_24_labwares: string[];
    unique_96_384_labwares: string[];
  } => {
    const destWorklistValues = [
      ...worklistValues.int1ToDest,
      ...worklistValues.int2ToDest,
      ...worklistValues.int3ToDest,
    ].map((item) => item.destPlateIndex);
    const usedIndices = destPlateInfo.filter((e) =>
      destWorklistValues.includes(e.index)
    );
    if (usedIndices.length < 1)
      return { unique_12_24_labwares: [], unique_96_384_labwares: [] };

    const grouped_12_24_labwares = usedIndices.filter((e) => e.rows.length < 6);
    const grouped_96_384_labwares = usedIndices.filter(
      (e) => e.rows.length > 7
    );

    const unique_12_24_labwares = new Set(
      grouped_12_24_labwares.map((item) => item.labwareTypeCode)
    );
    const unique_96_384_labwares = new Set(
      grouped_96_384_labwares.map((item) => item.labwareTypeCode)
    );

    return {
      unique_12_24_labwares: Array.from(unique_12_24_labwares),
      unique_96_384_labwares: Array.from(unique_96_384_labwares),
    };
  };

  useEffect(() => {
    const uniqueLabwareTypes = getUniqueDestPlateTypeWithSingleChannelSeeding();
    if (
      !isCurrentSeedingPriorityEqualToNew(
        poolingNormalizationToolInternalState.methodSettings
          .seedingPriority_12_24_Well,
        uniqueLabwareTypes.unique_12_24_labwares
      )
    ) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_12_24_SEEDING_PRIORITY,
        payload: {
          seedingPriority: uniqueLabwareTypes.unique_12_24_labwares,
        },
      });
    }

    if (
      !isCurrentSeedingPriorityEqualToNew(
        poolingNormalizationToolInternalState.methodSettings
          .seedingPriority_96_384_Well,
        uniqueLabwareTypes.unique_96_384_labwares
      )
    ) {
      poolingNormalizationToolDispatch({
        type: PoolingNormalizationToolInternalAction.SET_96_384_SEEDING_PRIORITY,
        payload: {
          seedingPriority: uniqueLabwareTypes.unique_96_384_labwares,
        },
      });
    }
  }, [
    worklistValues.int1ToDest,
    worklistValues.int2ToDest,
    worklistValues.int3ToDest,
    destPlateInfo,
    poolingNormalizationToolInternalState.methodSettings
      .seedingPriority_12_24_Well,
    poolingNormalizationToolInternalState.methodSettings
      .seedingPriority_96_384_Well,
  ]);
  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
    });
  useEffect(() => {
    BackdropOpen({
      open: loading,
      header: "Generating Worklist",
      subText: "Do not reload page",
      size: 60,
    });
  }, [loading]);
  const handleWorklistPreviewClose = (
    generateWorklist: boolean,
    worklist: Worklist[],
    reservationTime: Date
  ) => {
    const methodReservationInput = {
      methodId: 111,
      selectedSystem:
        poolingNormalizationToolInternalState.methodSettings.selectedSystem,
      sourcePlateInfo: sourcePlateInfo.map((plate) => ({
        plateBarcode: plate.plateBarcode,
        plateType: plate.labwareTypeCode,
      })),
      destPlateInfo: destPlateInfo.map((plate: any) => ({
        plateBarcode: plate.plateBarcode,
        plateType: plate.labwareTypeCode,
      })),
      intPlateBarcode: intPlateInfo[0].plateBarcode,
      intPlateBarcode2: intPlateInfo[1].plateBarcode,
      intPlateBarcode3: intPlateInfo[2].plateBarcode,
      deadPlateBarcode: deadPlateBarcode,
      worklist: worklist,
      reservationTime,
    };
    console.log("Method Reservation Data", methodReservationInput);
    if (generateWorklist) {
      reserveMethod({
        variables: {
          methodReservationInput: methodReservationInput,
        },
      }).then((res) => {
        window.location.href = `${GetUrl()}/api/ThawWorklist/DownloadWorklist?methodReservationID=${
          res.data?.reserveMethod?.methodReservationId
        }`;
        getExistingMethodReservation({
          variables: {
            where: {
              isCompleted: { eq: false },
              methodReservationsPlateBarcodes: {
                some: { plateBarcode: { eq: sourcePlateInfo[0].plateBarcode } },
              },
            },
          },
        });
      });
    }
    setWorklistPreviewOpen(false);
  };

  const isStepOptional = (step: number) => {
    return step === PoolingNormalizationStep.SelectStampMapping;
  };

  const int1PlateIsNotColSorted = () => {
    const maxRowIndex = intPlateInfo[0].rows.length;
    const intermedatePlate1Wells = worklistValues.harvestWells.map((item) => {
      return {
        plateIndex: item.destPlateIndex,
        plateWellId: item.destWellId,
        isSelectable: true,
      };
    });
    const harvestWellSorted = sortByCol(intermedatePlate1Wells);

    const distinctIntermediate1dWells = new Set(
      harvestWellSorted.map((item) => item.plateWellId)
    );
    let wellPositionIndex = 0;
    for (const well of distinctIntermediate1dWells) {
      const rowIndex = getRowIndexFromSelectedWell(well);
      if (wellPositionIndex % maxRowIndex !== rowIndex) {
        return true;
      }
      wellPositionIndex++;
    }
    return false;
  };

  const handleNext = async () => {
    if (
      poolingNormalizationToolInternalState.step ===
        PoolingNormalizationStep.SelectSourceToIntermediate &&
      int1PlateIsNotColSorted()
    ) {
      const confirmed = await isConfirmed(
        "It is recommened that your Intermediate Plate 1 is sorted by column. Would you still like to continue to the next step?"
      );

      if (!confirmed) {
        return;
      }
    }
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_NEXT_STEP,
    });
  };

  const handleBack = () => {
    poolingNormalizationToolDispatch({
      type: PoolingNormalizationToolInternalAction.SET_PREV_STEP,
    });
  };

  return (
    <React.Fragment>
      <div
        style={{
          height: "calc(100vh - 200px)",
          display: "grid",
          gridTemplate: "auto / 1fr 2fr",
        }}
      >
        <PoolingNormalizationToolSidebar deadPlateInfo={deadPlateTypes} />

        <Box sx={{ width: "95%", height: "100%" }}>
          <Stepper activeStep={poolingNormalizationToolInternalState.step}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            <div style={{ display: "flex" }}>
              {poolingNormalizationToolInternalState.step ===
              PoolingNormalizationStep.SelectSourceToIntermediate ? (
                <React.Fragment>
                  <SourcePlatesContainer sourcePlateInfo={sourcePlateTypes} />
                  <IntermediatePlatesContainer intPlateInfo={intPlateTypes} />
                </React.Fragment>
              ) : poolingNormalizationToolInternalState.step ===
                PoolingNormalizationStep.SelectStampMapping ? (
                <React.Fragment>
                  <IntermediatePlatesContainer intPlateInfo={intPlateTypes} />
                  <StampPlatesContainer destPlateInfo={destPlateTypes} />
                </React.Fragment>
              ) : poolingNormalizationToolInternalState.step ===
                PoolingNormalizationStep.SelectIntermediateToDestination ? (
                <React.Fragment>
                  <StampAndIntPlatesContainer intPlateInfo={intPlateTypes} />
                  <DestinationPlatesContainer destPlateInfo={destPlateTypes} />
                </React.Fragment>
              ) : null}
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={poolingNormalizationToolInternalState.step === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {poolingNormalizationToolInternalState.step < steps.length - 1 ? (
                <Button onClick={handleNext}>Next</Button>
              ) : null}
            </Box>
          </div>
        </Box>
      </div>
      <NewWorklistPreview
        isOpen={poolingNormalizationToolInternalState.worklistPreviewOpen}
        worklist={poolingNormalizationToolInternalState.worklist}
        setWorklist={setWorklist}
        handleClose={handleWorklistPreviewClose}
      />

      <ConfirmDialog leftButtonText="No" rightButtonText="Yes" />
    </React.Fragment>
  );
};
