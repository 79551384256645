import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik, FormikProps } from "formik";
import {
  DashboardTask,
  ManagePersonalTask,
} from "graphql/generated/schema-types";
import { shouldDisableTime } from "shared-components/DateTimePicker";

export interface DateInput {
  dialogTitle: string;
  dateInput: string;
}

interface RescheduleTaskDialogProp {
  isOpen: boolean;
  handleClose: (date: Date, updateTasks: boolean) => void;
  dateInput: DateInput;
  selectedTasks: DashboardTask[] | ManagePersonalTask[];
}

export const RescheduleTaskDialog = ({
  isOpen,
  handleClose,
  dateInput,
  selectedTasks,
}: RescheduleTaskDialogProp) => {
  const handleSubmit = (values: any) => {
    handleClose(values.dateInput, true);
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose(new Date(), false)}>
      <Formik initialValues={dateInput} onSubmit={handleSubmit}>
        {({ values, handleSubmit, setFieldValue }: FormikProps<any>) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle>{values.dialogTitle}</DialogTitle>
            <DialogContent>
              <ul>
                {selectedTasks.map((item) => (
                  <li>{item.taskName}</li>
                ))}
              </ul>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Start Time"
                  value={values.dateInput}
                  onChange={(newValue) => {
                    setFieldValue("dateInput", newValue);
                  }}
                  shouldDisableTime={shouldDisableTime}
                  minutesStep={15}
                  disablePast={true}
                />
              </LocalizationProvider>
            </DialogContent>
            <DialogActions>
              <Button type="submit">Save</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
