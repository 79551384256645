import produce from "immer";
import { IncomingSampleModals } from "../components/ModelsAndDialogs/modal.enum";
import { ResetSelectedIncomingSampleCollectionsTableRows } from "../components/Tables/Collections/IncomingSampleCollections";
import { ResetSelectedIncomingSampleTasksTableRows } from "../components/Tables/Tasks/IncomingSampleTasksTable";
import { IncomingSampleActions } from "./actions";
import { IIncomingSampleActions, IIncomingSampleState } from "./initial-state";

export function incomingStateReducer(
  state: IIncomingSampleState,
  action: IIncomingSampleActions
): IIncomingSampleState {
  switch (action.type) {
    case IncomingSampleActions.SELECT_SCREEN:
      return produce(state, (draft) => {
        draft.activeScreen = action.payload?.activeScreen;
      });
    case IncomingSampleActions.OPEN_COLLECTION_SAMPLES:
      return produce(state, (draft) => {
        draft.collectionSamplesModalState = {
          collectionId:
            action.payload?.collectionSamplesModalState?.collectionId,
        };
        draft.modalOpen = true;
        draft.currentModal = IncomingSampleModals.CollectionSamples;
      });
    case IncomingSampleActions.OPEN_CONFIRM_DIALOG:
      return produce(state, (draft) => {
        draft.confirmDialogState = {
          open: true,
          dialogType: action.payload?.confirmDialogState?.dialogType,
          typename: action.payload?.confirmDialogState?.typename,
        };
      });
    case IncomingSampleActions.OPEN_TASK_SUMMARY:
      return produce(state, (draft) => {
        draft.taskSummaryDialogState = {
          open: true,
          incomingSampleTaskId:
            action.payload?.taskSummaryDialogState?.incomingSampleTaskId,
        };
      });
    case IncomingSampleActions.OPEN_MODAL:
      return produce(state, (draft) => {
        draft.currentModal = action.payload?.currentModal;
        draft.modalOpen = true;
      });
    case IncomingSampleActions.CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = false;
        if ((draft?.selectedTasks ?? []).length > 0)
          ResetSelectedIncomingSampleTasksTableRows();
        if ((draft?.selectedCollections ?? []).length > 0)
          ResetSelectedIncomingSampleCollectionsTableRows();
      });
    case IncomingSampleActions.CLOSE_DIALOG:
      return produce(state, (draft) => {
        if (draft.taskSummaryDialogState?.open)
          draft.taskSummaryDialogState!.open = false;
        if (draft.confirmDialogState?.open)
          draft.confirmDialogState!.open = false;
        if ((draft?.selectedTasks ?? []).length > 0)
          ResetSelectedIncomingSampleTasksTableRows();
        if ((draft?.selectedCollections ?? []).length > 0)
          ResetSelectedIncomingSampleCollectionsTableRows();
      });
    case IncomingSampleActions.SELECT_TASKS:
      return produce(state, (draft) => {
        draft.selectedTasks = action.payload?.selectedTasks;
      });
    case IncomingSampleActions.SELECT_COLLECTIONS:
      return produce(state, (draft) => {
        draft.selectedCollections = action.payload?.selectedCollections;
      });
    default:
      return state;
  }
}
