import { animated } from "@react-spring/web";
import React from "react";
import { useModalTransition } from "shared-components/ModalsAndDialogs/hooks";
import { useModalStateProvider } from "../../state/StateProvider";
import { ContainerLineageModal } from "../ContainerLineage/Modal";
import { SampleModals } from "./model.enum";

export function SampleModalContent(): JSX.Element {
  const {
    modalState: { currentModal },
  } = useModalStateProvider();
  const transition = useModalTransition(currentModal);
  return transition((props, currentModal) => {
    switch (currentModal) {
      case SampleModals.LineageModal:
        return (
          <animated.div style={props}>
            <ContainerLineageModal />
          </animated.div>
        );
      default:
        return <React.Fragment />;
    }
  });
}
