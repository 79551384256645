import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ITwelve24TCToolInternalState } from "features/WorklistTools/1224TC/state/initial-state";
import { useAppReduxSelector } from "hooks/reduxHooks";

interface SelectBarcodeStringProps {
  twelve24TCInternalState: ITwelve24TCToolInternalState;
  handleMethodSettingsChanged: (index: number, key: any, value: string) => void;
  getAndSetBarcodes: (plateCode: string) => void;
}

export const SelectPlateCode = ({
  twelve24TCInternalState,
  handleMethodSettingsChanged,
  getAndSetBarcodes,
}: SelectBarcodeStringProps) => {
  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.destPlateInfo
  );
  const hasError = !destPlateInfo.plateCode;
  return (
    <FormControl variant="outlined" error={hasError} size="small">
      <InputLabel id="plate-code-label">Destination Plate Code</InputLabel>
      <Select
        labelId="plate-code-label"
        id="plate-code-select"
        label="Destination Plate Code"
        size="small"
        value={destPlateInfo.plateCode}
        onChange={(e) => {
          if (destPlateInfo.labwareTypeCode) {
            getAndSetBarcodes(e.target.value);
          }
          handleMethodSettingsChanged(0, "plateCode", e.target.value);
        }}
        error={hasError}
      >
        <MenuItem value={""}></MenuItem>
        <MenuItem value={"1TC"}>1TC</MenuItem>
        <MenuItem value={"HTC"}>HTC</MenuItem>
        <MenuItem value={"PEL"}>PEL</MenuItem>
      </Select>
      <FormHelperText>
        {hasError ? "Need Plate Code before choosing Plate Type" : ""}
      </FormHelperText>
    </FormControl>
  );
};
