import { makeVar } from "@apollo/client";
import { debounce } from "@mui/material";
import produce from "immer";
import { useEffect, useMemo, useState } from "react";
import {
  TableState,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components/macro";
import {
  SortEnumType,
  TicketFragment,
  UserFragment,
  useUserManagerQuery,
} from "graphql/generated/schema-types";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import { useInitializeData } from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { UserManagerActions } from "../../state/actions";
import { useUserManagerState } from "../../state/hooks";
import { UserManagerContextMenu } from "../ContextMenu";
import { UserManagerColumns } from "./Columns";
import { UserManagerFilterReducer } from "./filter";
import { handleContextMenu } from "./handlers";
import { Search } from "./Search";

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

//Workaround to prevent dialog from opening when the context menu loses focus
export const UserManagerTableContextMenuHidden = makeVar(true);

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedUserManagerTableRows = () =>
  toggleAllRowsSelectedStore()(false);

const DefaultState: Partial<TableState<TicketFragment>> = {
  sortBy: [{ id: "userName", desc: false }],
};

export function UserTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
    });

  const { userManagerDispatch } = useUserManagerState();

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { refetch, loading, variables, data } = useUserManagerQuery({
    variables: {
      order: { userName: SortEnumType.Asc },
    },
  })!;
  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData = useInitializeData<UserFragment>(
    data?.appSuiteUsers,
    paginationState
  );
  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const sortTable = useMemo(
    () => GetSortHandler(variables, refetch),
    [refetch, variables]
  );

  const filterTable = useMemo(
    () => GetFilterHandler(variables, refetch, UserManagerFilterReducer),
    [refetch, variables]
  );

  // const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const tableInstance = useTable(
    {
      columns: UserManagerColumns,
      data: pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState: DefaultState,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  const { toggleAllRowsSelected, selectedFlatRows, state, setFilter } =
    tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//
  useEffect(() => {
    setPaginationState((current) =>
      produce(current, (draft) => {
        draft.totalCount = data?.appSuiteUsers?.length ?? 0;
      })
    );
  }, [data?.appSuiteUsers?.length]);
  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  useEffect(() => {
    userManagerDispatch({
      type: UserManagerActions.SELECT,
      payload: {
        selectedUsers: selectedFlatRows
          .filter((row) => row.original?.userId)
          .map((row) => row.original.userId),
      },
    });
  }, [selectedFlatRows, userManagerDispatch]);

  const setUserNameFilter = debounce((id: number | null | undefined) => {
    setFilter("userId", () => id);
    setPaginationState((current) =>
      produce(current, (draft) => {
        draft.page = 0;
      })
    );
  }, 200);

  //--------------------------------------------------------------- Markup ------------------------------------------------------------//
  return (
    <>
      <Container>
        <Search setUserIdFilter={setUserNameFilter} />
        <ReactTable
          loading={loading}
          tableInstance={tableInstance}
          onFilter={filterTable}
          onSort={sortTable}
          stateChangeDebounceDuration={500}
          paginationState={paginationState}
          onRowContextMenu={handleContextMenu}
          onPageCountChange={(count) =>
            setPaginationState((current) =>
              produce(current, (draft) => {
                draft.rowsPerPage = count;
                draft.page = 0;
              })
            )
          }
          onPageChange={(e, page) =>
            setPaginationState((current) =>
              produce(current, (draft) => {
                draft.page = page;
              })
            )
          }
        />
      </Container>
      <UserManagerContextMenu />
    </>
  );
}
