import { makeVar } from "@apollo/client";
import { ALLRUNS_FOLDER_ID } from "features/Runs/constants";
import { RunsFolderTreeActions } from "features/Runs/state/folder-tree/actions";
import { NodeType } from "graphql/generated/schema-types";

export interface IRunsFolderTreeState {
  currentNode?: { id: number; type: NodeType };
  folderSearch?: string;
}

export interface IRunsFolderTreeAction {
  type: RunsFolderTreeActions;
  payload?: IRunsFolderTreeState;
}

export const RunsFolderTreeInitialState: IRunsFolderTreeState = {
  currentNode: { id: ALLRUNS_FOLDER_ID, type: NodeType.Folder },
};
export const RunsFolderTreeState = makeVar(RunsFolderTreeInitialState);
