import { AppSuiteGroup } from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import { FilterProps } from "react-table";
import { GroupField } from "shared-components/FormFields/FormFields";

export const GetGroupFilter = (multiSelect = false) =>
  (function GroupFilter<TData extends Record<string, unknown>>(
    {
      column: { filterValue, setFilter },
    }: FilterProps<TData>
  ): JSX.Element {
    return (
      <div style={{ width: "200px" }}>
        <GroupField
          id={"group-filter"}
          value={filterValue}
          multiple={multiSelect}
          autoFocus={true}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
            const group = newValue as
              | AppSuiteGroup
              | AppSuiteGroup[]
              | undefined
              | null;
            if (multiSelect) {
              const filter = (group as AppSuiteGroup[] | null | undefined)?.map(
                (g) => g.groupId
              );
              if (filter?.length) setFilter(filter);
              else setFilter(null);
            } else setFilter((group as AppSuiteGroup)?.groupId ?? null);
          }}
          helperText={false}
          error={false}
          variant={"standard"}
          label={"Search Group"}
        />
      </div>
    );
  });
