import { Autocomplete, TextField } from "@mui/material";
import _ from "lodash";
import React from "react";
import { FilterProps } from "react-table";
import { Role } from "graphql/generated/schema-types";

const RoleIdReducer = (role: Role) => {
  switch (role) {
    case Role.Admin:
      return 1;
    case Role.Engineer:
      return 2;
    case Role.Manager:
      return 3;
    case Role.User:
      return 4;
  }
};

export function RoleFilter<T extends Record<string, unknown>>({
  column: { filterValue, setFilter },
}: FilterProps<T>): JSX.Element {
  return (
    <div style={{ width: "200px" }}>
      <Autocomplete
        options={Object.entries(Role).map(([name, value]) => ({
          value,
          name,
        }))}
        value={filterValue}
        multiple
        getOptionLabel={(entry) => _.startCase(entry.name)}
        isOptionEqualToValue={(x, y) => x.value === y.value}
        fullWidth
        onChange={(e, newValue) => {
          setFilter(newValue.map((val) => RoleIdReducer(val?.value)));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Role"}
            autoFocus={true}
            variant={"outlined"}
          />
        )}
      />
    </div>
  );
}
