import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { DialogTitle, Grid } from "@mui/material";
import { FormikErrors } from "formik";
import { FeedGroupInput } from "graphql/generated/schema-types";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { StyledAddFeedGroupButton } from "./AddFeedTask.Styled";
import { DayToggle } from "./DayToggle";
import { GroupDropdown } from "./GroupDropdown";

export interface FeedGroupsHeaderProps {
  createFeedGroupRow: () => void;
  feedDay: string;
  groupId: number | undefined | null;
  setFeedDay: Dispatch<SetStateAction<string>>;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<FormikErrors<{ feedGroupArray: FeedGroupInput[] }>>;
  setGroupId: Dispatch<SetStateAction<number | null | undefined>>;
}

export const FeedGroupHeader = (props: FeedGroupsHeaderProps) => {
  const handleGroupChange = (event: ChangeEvent<unknown>, newValue: any) => {
    props.setGroupId(newValue.groupId);
    props.setFieldValue("groupId", newValue.groupId);
  };

  return (
    <Grid container spacing={0} sx={{ mb: 4, ml: 0.5 }}>
      <Grid item xs={2.4}>
        <DialogTitle>Feed Groups</DialogTitle>
      </Grid>
      <Grid item xs={1.35} sx={{ mr: 3 }}>
        <StyledAddFeedGroupButton onClick={props.createFeedGroupRow}>
          <AddCircleOutlinedIcon sx={{ mr: 0.8 }} /> Add Group
        </StyledAddFeedGroupButton>
      </Grid>
      <Grid item xs={2} sx={{ mt: 0.5, mr: 3 }}>
        <GroupDropdown
          onChange={handleGroupChange}
          setGroupId={props.setGroupId}
          value={props.groupId}
        />
      </Grid>
      <DayToggle setFeedDay={props.setFeedDay} feedDay={props.feedDay} />
    </Grid>
  );
};
