import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import {
  AuthMiddleWare,
  CleanTypeNameMiddleWare,
  GroupLoginMiddleWare,
  HttpMiddleWare,
} from "./links";
import { TypePoliciesSchema } from "./typePolicies";

export const cache = new InMemoryCache({
  typePolicies: TypePoliciesSchema.typePolicies,
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    GroupLoginMiddleWare,
    AuthMiddleWare,
    CleanTypeNameMiddleWare,
    HttpMiddleWare,
  ]),
  typeDefs: TypePoliciesSchema.typeDefs,
  cache: cache,
});
