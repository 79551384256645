import { makeVar } from "@apollo/client";
import { ConfirmDialog } from "enums/ConfirmDialog";
import { Collection, CollectionTask } from "graphql/generated/schema-types";
import { IncomingSampleModals } from "../components/ModelsAndDialogs/modal.enum";
import { IncomingSampleActions } from "./actions";

export enum IncomingSampleScreensEnum {
  Tasks,
  Collections,
  CreateCollection,
  IDF,
}
export interface IIncomingSampleState {
  activeScreen?: IncomingSampleScreensEnum;
  selectedCollections?: number[];
  selectedTasks?: number[];
  modalOpen?: boolean;
  currentModal?: IncomingSampleModals;
  collectionSamplesModalState?: {
    collectionId?: number;
  };
  taskSummaryDialogState?: {
    open: boolean;
    incomingSampleTaskId?: number;
  };
  confirmDialogState?: {
    open?: boolean;
    dialogType?: ConfirmDialog;
    typename?: Collection["__typename"] | CollectionTask["__typename"];
  };
}

export interface IIncomingSampleActions {
  type: IncomingSampleActions;
  payload?: IIncomingSampleState;
}

export const IncomingSamplesInitialState: IIncomingSampleState = {
  modalOpen: false,
  activeScreen: IncomingSampleScreensEnum.Tasks,
};

export const IncomingSampleState = makeVar<IIncomingSampleState>(
  IncomingSamplesInitialState
);
