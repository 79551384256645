import { makeVar } from "@apollo/client";
import {
  IncomingSampleActions,
  useIncomingSampleState,
} from "features/IncomingSamples/state";
import {
  GetIncomingSampleTasksQuery,
  GetIncomingSampleTasksQueryVariables,
  IncomingSampleTaskTableEntryFragment,
  useGetIncomingSampleTasksQuery,
} from "graphql/generated/schema-types";
import produce from "immer";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetPageChangeHandler } from "shared-components/Table/Handlers/HandlePageChange";
import { GetHandlePageCountChange } from "shared-components/Table/Handlers/HandlePageCountChange";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import {
  useCommonTableSideEffects,
  useInitializeConnectionData,
} from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { IncomingSampleTaskContextMenu } from "../../ContextMenu/TaskContextMenu";
import { IncomingSampleTasksTableColumns } from "../../Tables/Tasks/Columns";
import {
  DefaultIncomingSamplesFilter,
  DefaultIncomingSamplesSort,
  defaultIncomingSamplesStatusFilter,
} from "../../Tables/Tasks/defaults";
import { IncomingSampleTasksFilterReducer } from "../../Tables/Tasks/filter-factory";
import {
  handleContextMenu,
  useHandleRowClick,
} from "../../Tables/Tasks/handlers";

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedIncomingSampleTasksTableRows = () =>
  toggleAllRowsSelectedStore()(false);

export function IncomingSampleTasksTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//

  const { incomingSampleDispatch } = useIncomingSampleState();

  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
    });

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { refetch, loading, fetchMore, variables, data } =
    useGetIncomingSampleTasksQuery({
      variables: {
        where: DefaultIncomingSamplesFilter,
        order: DefaultIncomingSamplesSort,
      },
      fetchPolicy: "cache-and-network",
    });

  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData =
    useInitializeConnectionData<IncomingSampleTaskTableEntryFragment>(
      data?.incomingSampleTasks,
      paginationState
    );

  const totalCount = useMemo(
    () =>
      GetTotalCountFromConnectionType<IncomingSampleTaskTableEntryFragment>(
        data?.incomingSampleTasks
      ),
    [data?.incomingSampleTasks]
  );

  const pageInfo = useMemo(
    () =>
      GetPageInfoFromConnectionType<IncomingSampleTaskTableEntryFragment>(
        data?.incomingSampleTasks
      ),
    [data?.incomingSampleTasks]
  );

  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const paginationTuple = useMemo(
    () =>
      [paginationState, setPaginationState] as [
        ReactTablePaginationState,
        Dispatch<SetStateAction<ReactTablePaginationState>>
      ],
    [paginationState]
  );
  const sortTable = useMemo(
    () => GetSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const onPageChange = useMemo(
    () =>
      GetPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [fetchMore, pageInfo?.endCursor, paginationState, variables]
  );

  const handlePageCountChange = useMemo(
    () =>
      GetHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, variables]
  );

  const filterTable = useMemo(
    () =>
      GetFilterHandler<
        GetIncomingSampleTasksQuery,
        GetIncomingSampleTasksQueryVariables,
        IncomingSampleTaskTableEntryFragment
      >(variables, refetch, IncomingSampleTasksFilterReducer, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const skipPageResetRef = React.useRef(false);

  const _pageData = useMemo(() => {
    skipPageResetRef.current = true;
    return pageData.map((entry) =>
      produce(entry, (draft) => {
        draft.startTimeScheduled = moment(draft?.startTimeScheduled)
          .startOf("day")
          .toISOString();
      })
    );
  }, [pageData]);

  const tableInstance = useTable(
    {
      columns: IncomingSampleTasksTableColumns,
      data: _pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState: {
        filters: [
          {
            id: "status",
            value: defaultIncomingSamplesStatusFilter,
          },
        ],
        sortBy: [{ id: "startTimeScheduled", desc: true }],
      },
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  React.useEffect(() => {
    skipPageResetRef.current = false;
  });

  const {
    state: { groupBy },
    selectedFlatRows,
    toggleAllRowsSelected,
  } = tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//
  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  useCommonTableSideEffects(
    paginationTuple,
    totalCount,
    variables,
    refetch,
    groupBy
  );

  useEffect(() => {
    incomingSampleDispatch({
      type: IncomingSampleActions.SELECT_TASKS,
      payload: {
        selectedTasks: selectedFlatRows
          .filter((row) => row.original?.incomingSampleTaskId)
          .map((row) => row.original.incomingSampleTaskId),
      },
    });
  }, [incomingSampleDispatch, selectedFlatRows]);
  //--------------------------------------------------------------- Markup ------------------------------------------------------------//

  return (
    <>
      <ReactTable
        tableInstance={tableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        onRowClick={handleRowClick}
        loading={loading}
      />
      <IncomingSampleTaskContextMenu refetch={refetch} />
    </>
  );
}
