import React from "react";
import { useMethodManagerState } from "../state/hooks";
import { MethodManagerScreen } from "../state/initial-state";
import { ManualTaskScreen } from "./Screens/ManualTaskScreen";
import { MethodScreen } from "./Screens/MethodScreen";

const ScreenReducer = (screen?: MethodManagerScreen) => {
  switch (screen) {
    case MethodManagerScreen.ManualTaskTypeScreen:
      return <ManualTaskScreen />;
    case MethodManagerScreen.MethodScreen:
      return <MethodScreen />;
    default:
      return <React.Fragment />;
  }
};
export function Screens(): JSX.Element {
  const {
    methodManagerState: { activeScreen },
  } = useMethodManagerState();
  return ScreenReducer(activeScreen);
}
