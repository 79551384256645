import { Maybe } from "graphql/jsutils/Maybe";
import * as _ from "lodash";
import {
  MethodFragment,
  UpdateMethodInput,
} from "../graphql/generated/schema-types";
import { MethodInputStub } from "./object-stubs/MethodInput";

export function MethodInputMapper(method: Maybe<MethodFragment>) {
  return _.pick(method, _.keys(new MethodInputStub())) as UpdateMethodInput;
}
