import React from "react";
import { AddRackOptions } from "../../constants";
import { DateSelector } from "../selectors/DateSelector";
import { RowColSelector } from "../selectors/RowColSelector";
import { SalivaSelector } from "../selectors/SalivaSelector";

interface SalivaProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const Saliva = ({ values, setFieldValue }: SalivaProps) => {
  return (
    <React.Fragment>
      <SalivaSelector values={values} setFieldValue={setFieldValue} />
      <RowColSelector values={values} setFieldValue={setFieldValue} />
      <DateSelector values={values} setFieldValue={setFieldValue} />
    </React.Fragment>
  );
};
