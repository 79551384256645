import { Maybe } from "graphql/jsutils/Maybe";
import { useMemo } from "react";
import { useGetMeQuery } from "../graphql/generated/schema-types";
import { AppUserSettings } from "../interfaces/AppUserSettings";

export const useGetUserSettings = () => {
  const { data } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  const userSettings: Maybe<AppUserSettings> = useMemo(
    () => JSON.parse(data?.me?.setting?.settingsJson ?? "null"),
    [data?.me?.setting]
  );
  return userSettings;
};
