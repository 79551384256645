import { makeVar } from "@apollo/client";
import { TicketActions } from "features/Tickets/state/actions";
import { Maybe } from "graphql-tools";
import { TicketModals } from "../components/ModelsAndDialogs/modal.enum";

export interface ITicketState {
  modalOpen?: boolean;
  currentModal?: TicketModals;
  ticketSummaryDialogOpen?: {
    open?: boolean;
    ticketId?: number;
  };
  selectedTickets?: number[];
  selectedSlackMessageTimestamp?: Maybe<string>[];
}

export interface ITicketAction {
  type: TicketActions;
  payload?: ITicketState;
}

export const TicketInitialState: ITicketState = {
  modalOpen: false,
};

export const TicketState = makeVar<ITicketState>(TicketInitialState);
