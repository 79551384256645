import {
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { FC } from "react";
import { useGetContainerLineageQuery } from "graphql/generated/schema-types";
import { StyledTableCell } from "shared-components/Table/ReactTable";
import { ContainerLineageRow } from "./Row";

const LoadingRows: FC = () => (
  <>
    <TableRow>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
    </TableRow>
  </>
);
export function ContainerLineageTable({
  containerId,
}: {
  containerId: number;
}): JSX.Element {
  const { data: lineageQuery, loading } = useGetContainerLineageQuery({
    variables: { containerId },
  });
  return (
    <TableContainer style={{ maxHeight: "50vh" }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell>Sample</StyledTableCell>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell>Hierarchy</StyledTableCell>
            <StyledTableCell>Plate Barcode</StyledTableCell>
            <StyledTableCell>Row</StyledTableCell>
            <StyledTableCell>Column</StyledTableCell>
            <StyledTableCell>Tube Barcode</StyledTableCell>
            <StyledTableCell>Lot Number</StyledTableCell>
            <StyledTableCell>Active</StyledTableCell>
            <StyledTableCell>LN2 To SAM Activity</StyledTableCell>
            <StyledTableCell>Date Created</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            Array(10)
              .fill(0)
              .map((_, idx) => <LoadingRows key={idx} />)
          ) : (
            <>
              {lineageQuery?.containerLineage?.map((entry) => (
                <ContainerLineageRow
                  key={JSON.stringify(entry)}
                  containerLineage={entry}
                />
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
