import { TextField, Typography } from "@mui/material";
import { Autocomplete } from '@mui/material';
import { MarkMultipleRunActivityValidationSchema } from "features/Runs/components/Forms/validation-schema";
import { FormikHelpers, useFormik } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  LookupArraySystem,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import _ from "lodash";
import React from "react";
import {
  GroupField,
  SystemField,
  UserField,
} from "shared-components/FormFields/FormFields";
import {
  FormSubmitButton,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";

export interface MarkMultipleFormFields {
  runTaskAssignedUsers?: number[];
  systemAssignedTo?: number;
  runTaskAssignedGroups?: number[];
  startTimeScheduled?: Date | null;
  status?: string;
  formError?: string;
}
export function EditMultipleTasksForm({
  onSubmit,
}: {
  onSubmit: (
    values: MarkMultipleFormFields,
    actions: FormikHelpers<MarkMultipleFormFields>
  ) => void;
}): JSX.Element {
  const initialValues: MarkMultipleFormFields = {};
  const { values, errors, touched, handleSubmit, isSubmitting, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: MarkMultipleRunActivityValidationSchema,
      onSubmit,
    });
  console.log(values);
  return (
    <StyledForm onSubmit={handleSubmit}>
      {errors.formError ? (
        <Typography data-testid={"validation-error-div"} color={"primary"}>
          {errors.formError}
        </Typography>
      ) : (
        <React.Fragment />
      )}
      <StyledFormControl>
        <UserField
          id="users-combobox"
          value={values.runTaskAssignedUsers}
          onChange={(e, newValue) => {
            setFieldValue(
              "runTaskAssignedUsers",
              (newValue as Maybe<AppSuiteUser>[])?.map(
                (entry) => entry?.userId
              ) ?? null
            );
          }}
          label="Users"
          variant="outlined"
          multiple
          helperText={
            errors.runTaskAssignedUsers &&
            touched.runTaskAssignedUsers &&
            errors.runTaskAssignedUsers
          }
          error={
            !!(
              errors.runTaskAssignedUsers &&
              touched.runTaskAssignedUsers &&
              errors.runTaskAssignedUsers
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <SystemField
          id="systemAssignedTo-combobox"
          onChange={(e, newValue) =>
            setFieldValue(
              "systemAssignedTo",
              (newValue as Maybe<LookupArraySystem>)!.systemId
            )
          }
          label="System Assigned To"
          variant="outlined"
          helperText={
            errors.systemAssignedTo &&
            touched.systemAssignedTo &&
            errors.systemAssignedTo
          }
          error={
            !!(
              errors.systemAssignedTo &&
              touched.systemAssignedTo &&
              errors.systemAssignedTo
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <GroupField
          id="groupAssignedTo-combobox"
          value={values.runTaskAssignedGroups}
          onChange={(e, newValue) =>
            setFieldValue(
              "runTaskAssignedGroups",
              (newValue as Maybe<AppSuiteGroup>[])?.map(
                (entry) => entry?.groupId
              ) ?? null
            )
          }
          label="Groups"
          variant="outlined"
          multiple
          helperText={
            errors.runTaskAssignedGroups &&
            touched.runTaskAssignedGroups &&
            errors.runTaskAssignedGroups
          }
          error={
            !!(
              errors.runTaskAssignedGroups &&
              touched.runTaskAssignedGroups &&
              errors.runTaskAssignedGroups
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <Autocomplete
          id="status-combobox"
          defaultValue={
            Object.values(TaskStatusEnum).filter(
              (status) => status === values.status
            )[0]
          }
          options={Object.values(TaskStatusEnum)}
          getOptionLabel={(option) => _.startCase(option)}
          style={{ width: "100%" }}
          onChange={(e, newValue) => setFieldValue("status", newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              id="status"
              label="Status"
              variant="outlined"
              helperText={errors.status && touched.status && errors.status}
              error={!!(errors.status && touched.status && errors.status)}
            />
          )}
        />
      </StyledFormControl>
      <FormSubmitButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        data-testid={"submit-button"}
        disabled={isSubmitting}
      >
        Preview
      </FormSubmitButton>
    </StyledForm>
  );
}
