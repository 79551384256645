import { ManualTaskTypeFragment } from "graphql/generated/schema-types";
import React from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";
import { ManualTaskTableContextMenuHidden } from "./ManualTaskTable";

export const handleContextMenu =
  (selected: ManualTaskTypeFragment[], row?: Row<ManualTaskTypeFragment>) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    const _selected = selected.includes(row!.original)
      ? selected
      : [...selected, row?.original];
    contextMenu.show({
      id: "ManualTaskTableContextMenu",
      event: e,
      props: {
        selected: _selected.map((s) => s?.id),
      },
    });
    ManualTaskTableContextMenuHidden(false);
  };
