import { makeVar } from "@apollo/client";
import { DefaultTicketTableContextMenu } from "features/Tickets/components/ContextMenu";
import {
  ContainerTableEntryFragment,
  SortEnumType,
  useGetContainersQuery,
} from "graphql/generated/schema-types";
import produce from "immer";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetPageChangeHandler } from "shared-components/Table/Handlers/HandlePageChange";
import { GetHandlePageCountChange } from "shared-components/Table/Handlers/HandlePageCountChange";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import {
  useCommonTableSideEffects,
  useInitializeConnectionData,
} from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { SampleActions } from "../../state/actions";
import { useSampleState } from "../../state/hooks";
import { SampleTableContextMenu } from "../ContextMenu";
import { SampleColumns } from "./Columns";
import { SampleFilterReducer } from "./filter";
import { handleContextMenu, useHandleRowClick } from "./handlers";
import { SampleTableHeader } from "./SampleTableHeader";

//Workaround to prevent dialog from opening when the context menu loses focus
export const SampleTableContextMenuHidden = makeVar(true);

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedSampleTableRows = () =>
  toggleAllRowsSelectedStore()(false);

export function SampleTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const { sampleDispatch } = useSampleState();
  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
    });

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const query = useGetContainersQuery({
    variables: { order: [{ dateCreated: SortEnumType.Desc }] },
    notifyOnNetworkStatusChange: true,
  });
  const { refetch, loading, fetchMore, variables, data } = query;
  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData = useInitializeConnectionData<ContainerTableEntryFragment>(
    data?.containers,
    paginationState
  );

  const totalCount = useMemo(
    () =>
      GetTotalCountFromConnectionType<ContainerTableEntryFragment>(
        data?.containers
      ),
    [data?.containers]
  );

  const pageInfo = useMemo(
    () =>
      GetPageInfoFromConnectionType<ContainerTableEntryFragment>(
        data?.containers
      ),
    [data?.containers]
  );

  const paginationTuple = useMemo(
    () =>
      [paginationState, setPaginationState] as [
        ReactTablePaginationState,
        Dispatch<SetStateAction<ReactTablePaginationState>>
      ],
    [paginationState]
  );
  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const sortTable = useMemo(
    () =>
      GetSortHandler(variables, refetch, paginationTuple, {
        id: "containerId",
        desc: true,
      }),
    [paginationTuple, refetch, variables]
  );

  const onPageChange = useMemo(
    () =>
      GetPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [fetchMore, pageInfo?.endCursor, paginationState, variables]
  );

  const handlePageCountChange = useMemo(
    () =>
      GetHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, variables]
  );

  const filterTable = useMemo(
    () =>
      GetFilterHandler(
        variables,
        refetch,
        SampleFilterReducer,
        paginationTuple
      ),
    [paginationTuple, refetch, variables]
  );

  const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const skipPageResetRef = React.useRef(false);

  const _pageData = useMemo(() => {
    skipPageResetRef.current = true;
    return pageData.map((entry) =>
      produce(entry, (draft) => {
        draft.dateCreated = moment(draft?.dateCreated)
          .startOf("day")
          .toISOString();
      })
    );
  }, [pageData]);

  const tableInstance = useTable(
    {
      columns: SampleColumns,
      data: _pageData,
      manualFilters: true,
      manualSortBy: true,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  useEffect(() => {
    skipPageResetRef.current = false;
  });

  const {
    state: { groupBy },
    toggleAllRowsSelected,
    selectedFlatRows,
  } = tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//
  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  useEffect(() => {
    sampleDispatch({
      type: SampleActions.SELECT,
      payload: {
        selectedContainers: selectedFlatRows
          .filter((row) => row.original?.containerId)
          .map((row) => row.original.containerId),
      },
    });
  }, [selectedFlatRows, sampleDispatch]);

  useCommonTableSideEffects(
    paginationTuple,
    totalCount,
    variables,
    refetch,
    groupBy
  );

  //--------------------------------------------------------------- Markup ------------------------------------------------------------//
  return (
    <div>
      <SampleTableHeader tableInstance={tableInstance} loading={loading} />
      <SampleTableContextMenu />
      <ReactTable
        loading={loading}
        tableInstance={tableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        onRowClick={handleRowClick}
      />
      <DefaultTicketTableContextMenu />
    </div>
  );
}
