import { TextField } from "@mui/material";
import { ButtonGroupSelector } from "features/WorklistTools/shared/components/ButtonGroupSelector";
import { GetUserSettings } from "helpers/user-claim-helpers";
import React, { useState } from "react";
import DataGrid, { EditorProps } from "react-data-grid";
import styled from "styled-components";

const StyledWellMapping = styled(DataGrid)`
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
`;

interface WellMappingProps {
  hideCellNumber?: boolean;
  setSingleCellNumber?: (rows: unknown[]) => void;
  handleApplyCellNumber?: (option: string, cellNumber: number) => void;
  columns: {
    key: string;
    name: string;
    formatter?(props: any): JSX.Element;
    resizable: boolean;
    editor?: React.ComponentType<EditorProps<unknown, unknown>> | null;
  }[];
  worklistValues: any[];
}

export const WellMapping = ({
  hideCellNumber,
  setSingleCellNumber,
  handleApplyCellNumber,
  columns,
  worklistValues,
}: WellMappingProps) => {
  const theme = GetUserSettings()?.theme?.mode ?? "light";

  const [cellNumber, setCellNumber] = useState<number | undefined>();

  return (
    <React.Fragment>
      {!hideCellNumber ? (
        <React.Fragment>
          <TextField
            value={cellNumber}
            type="number"
            onChange={(e) => setCellNumber(parseInt(e.target.value))}
            size="small"
          />
          <ButtonGroupSelector
            handleApplyCellNumber={(option: string) =>
              handleApplyCellNumber!(option, cellNumber!)
            }
          />
        </React.Fragment>
      ) : null}
      <StyledWellMapping
        className={theme === "light" ? "rdg-light" : "rdg-dark"}
        columns={columns}
        rows={[...worklistValues]}
        onRowsChange={setSingleCellNumber}
      />
    </React.Fragment>
  );
};
