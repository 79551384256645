import { Button, TextField } from "@mui/material";
import { useGetDeleteRunFolder } from "features/Runs/components/Forms/Delete/hooks";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { Formik } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { useGetFolder } from "hooks/cacheAccessHooks";

const StyledButton = styled(Button)``;
const StyledForm = styled.form`
  margin: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 35%;
  justify-content: space-between;
`;
export function DeleteRunFolderForm({
  submitStateTuple,
}: {
  submitStateTuple: [boolean, Dispatch<SetStateAction<boolean>>];
}): JSX.Element {
  const { runsModalState, runsModalDispatch } = useRunsModalState();
  const [submitting, setSubmitting] = submitStateTuple;
  const deleteRunFolder = useGetDeleteRunFolder(setSubmitting);
  const folder = useGetFolder(runsModalState.deleteDialog?.folderId);
  return (
    <Formik
      initialValues={{ name: "" }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        if (values.name !== folder?.folderName || values.name.length === 0) {
          setFieldError("name", "Name does not match");
          return;
        }
        if (folder?.folderId)
          deleteRunFolder(folder.folderId).finally(() => setSubmitting(false));
      }}
    >
      {({ handleSubmit, handleChange, touched, errors }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <TextField
              id={"name"}
              onChange={handleChange}
              variant={"outlined"}
              size={"small"}
              label={"Enter Folder Name"}
              helperText={errors.name && touched.name && errors.name}
              error={!!(errors.name && touched.name && errors.name)}
            />
            <ButtonContainer>
              <div>
                <Button
                  onClick={() =>
                    runsModalDispatch({
                      type: RunsModalActions.CLOSE_DIALOG,
                    })
                  }
                  color="secondary"
                  variant={"contained"}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <StyledButton
                  type={"submit"}
                  variant={"contained"}
                  color={"primary"}
                  disabled={submitting}
                >
                  Delete
                </StyledButton>
              </div>
            </ButtonContainer>
          </StyledForm>
        );
      }}
    </Formik>
  );
}
