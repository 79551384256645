import { CeligoPlateMetadata } from "features/WorklistTools/CeligoScan/Dialog/state";
import { FormikHelpers } from "formik";
import {
  DashboardTaskInput,
  MethodReservation,
  OperationNames,
  TaskTypeEnum,
  useReserveCeligoPersonalTaskMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { ValidatePlateMetadata } from "helpers/validatePlateMetadata";
import RunsService from "services/RunsService";
import { Alert, AlertType } from "shared-components/toast";

export const useGetDashboardTaskHandleSubmit = (
  methodReservation: Maybe<MethodReservation>,
  personalTask: DashboardTaskInput
): ((
  values: DashboardTaskInput,
  actions: FormikHelpers<DashboardTaskInput>
) => void) => {
  const [reserveTaskPlates] = useReserveCeligoPersonalTaskMutation();
  return async (
    values: DashboardTaskInput,
    { setSubmitting, setFieldError }: FormikHelpers<DashboardTaskInput>
  ) => {
    if (methodReservation?.methodReservationId) {
      RunsService.downloadWorklist(methodReservation.methodReservationId);
      setSubmitting(false);
      return;
    }
    values.methodReservationPlateMetadata = CeligoPlateMetadata().filter(
      (metadata) => metadata.experimentSetting
    );
    values.taskId = personalTask.taskId ?? 0;
    values.taskType = personalTask.taskType;
    const errorMessage = await ValidatePlateMetadata(
      values.methodReservationPlateMetadata
    );
    if (errorMessage) {
      return setFieldError("methodReservationPlateMetadata", errorMessage);
    }
    await reserveTaskPlates({
      variables: { task: values },
      refetchQueries: [
        personalTask.taskType === TaskTypeEnum.Personal
          ? OperationNames.Query.getMethodReservationByPersonalTaskId
          : OperationNames.Query.getMethodReservationByRunActivityId,
      ],
    })
      .then(() =>
        Alert({ type: AlertType.SUCCESS, message: "Method Reserved" })
      )
      .finally(() => {
        setSubmitting(false);
      });
  };
};
