import {
  DayOfWeek,
  Role,
  TaskPriorityEnum,
  TaskStatusEnum,
  useGetActiveRunsQuery,
  useGetAllFoldersQuery,
  useGetGroupsQuery,
  useGetInsertableFoldersQuery,
  useGetLotNumbersQuery,
  useGetMethodsQuery,
  useGetPlateForAutocompleteQuery,
  useGetProjectsQuery,
  useGetRunTaskTypesQuery,
  useGetRunTemplatesForComboboxQuery,
  useGetRunTypesQuery,
  useGetSourceSampleTypesQuery,
  useGetSystemsQuery,
  useGetTicketAssetsQuery,
  useGetTicketAssetTypesQuery,
  useGetTicketTypesQuery,
  useGetTubeBarcodesForAutocompleteQuery,
  useGetUsersQuery,
} from "graphql/generated/schema-types";
import { GetLocalCombobox } from "shared-components/ComboBox/GetLocalCombobox";
import { Distinct } from "../../helpers/object-helpers";
import { GetEnumCombobox } from "../ComboBox/GetEnumCombobox";
import { GetServerSideCombobox } from "../ComboBox/GetServerSideCombobox";

export const UserField = GetLocalCombobox(
  useGetUsersQuery,
  (result) => result?.appSuiteUsers ?? [],
  (entry) => entry?.userId ?? 0,
  (entry) => entry?.userName ?? ""
);
export const GroupField = GetLocalCombobox(
  useGetGroupsQuery,
  (result) => result?.appSuiteGroups ?? [],
  (entry) => entry?.groupId ?? 0,
  (entry) => entry?.groupName ?? ""
);
export const SystemField = GetLocalCombobox(
  useGetSystemsQuery,
  (result) => result?.systems ?? [],
  (entry) => entry?.systemId ?? 0,
  (entry) => entry?.systemName ?? ""
);
export const RunTaskTypeField = GetLocalCombobox(
  useGetRunTaskTypesQuery,
  (result) => result?.runTaskTypes ?? [],
  (entry) => entry?.compositeKey ?? "",
  (entry) => entry?.name ?? ""
);
export const MethodField = GetLocalCombobox(
  useGetMethodsQuery,
  (result) => result?.methods ?? [],
  (entry) => entry?.methodId ?? 0,
  (entry) => entry?.methodName ?? ""
);
export const RunTypeField = GetLocalCombobox(
  useGetRunTypesQuery,
  (result) => result?.runTypes ?? [],
  (entry) => entry?.productionRunTypeId ?? 0,
  (entry) => entry?.typeName ?? ""
);
export const RunTemplateField = GetLocalCombobox(
  useGetRunTemplatesForComboboxQuery,
  (result) => result?.runTemplatesForCombobox ?? [],
  (entry) => entry?.runTemplateId ?? 0,
  (entry) => entry?.runTemplateName ?? ""
);
export const AssetField = GetLocalCombobox(
  useGetTicketAssetsQuery,
  (result) => result?.ticketAssets ?? [],
  (entry) => entry?.assetId ?? 0,
  (entry) => entry?.asset ?? ""
);
export const AssetTypeField = GetLocalCombobox(
  useGetTicketAssetTypesQuery,
  (result) => result?.ticketAssetTypes ?? [],
  (entry) => entry?.assetTypeId ?? 0,
  (entry) => entry?.assetType ?? ""
);
export const TicketTypeField = GetLocalCombobox(
  useGetTicketTypesQuery,
  (result) => result?.ticketTypes ?? [],
  (entry) => entry?.ticketTypeId ?? 0,
  (entry) => entry?.ticketType ?? ""
);
export const SourceSampleTypeField = GetLocalCombobox(
  useGetSourceSampleTypesQuery,
  (result) => result?.sampleTypes ?? [],
  (entry) => entry?.sampleTypeId ?? 0,
  (entry) => entry?.sampleTypeString ?? ""
);
export const FolderField = GetLocalCombobox(
  useGetAllFoldersQuery,
  (result) => result?.folders ?? [],
  (entry) => entry?.folderId ?? 0,
  (entry) => entry?.folderName ?? ""
);
export const InsertableFolderField = GetLocalCombobox(
  useGetInsertableFoldersQuery,
  (result) => result?.insertableFolders ?? [],
  (entry) => entry?.folderId ?? 0,
  (entry) => entry?.folderName ?? ""
);
export const ProjectsField = GetLocalCombobox(
  useGetProjectsQuery,
  (result) => result?.projects ?? [],
  (entry) => entry?.projectId ?? 0,
  (entry) => `${entry?.nyscfprefix} | ${entry?.projectName}` ?? ""
);
export const RunField = GetLocalCombobox(
  useGetActiveRunsQuery,
  (result) => result?.runs ?? [],
  (entry) => entry?.runId ?? 0,
  (entry) => entry?.runName ?? ""
);
export const PlateField = GetServerSideCombobox(
  useGetPlateForAutocompleteQuery,
  (result) => result?.plates?.edges?.map((edge) => edge!.node!) ?? [],
  (entry) => entry?.plateCreatedId ?? 0,
  (entry) => entry?.plateBarcode ?? ""
);
export const TubeBarcodeField = GetServerSideCombobox(
  useGetTubeBarcodesForAutocompleteQuery,
  (result) =>
    Distinct(
      result?.containers?.edges?.map((edge) => edge!.node!) ?? [],
      "barcode"
    ),
  (entry) => entry?.containerId ?? 0,
  (entry) => entry?.barcode ?? ""
);
export const LotNumberField = GetServerSideCombobox(
  useGetLotNumbersQuery,
  (result) =>
    Distinct(
      result?.lotNumbers?.edges?.map((edge) => edge!.node!) ?? [],
      "lotNumber1"
    ),
  (entry) => entry?.lotNumberId ?? 0,
  (entry) => entry?.lotNumber1 ?? ""
);
export const StatusField = GetEnumCombobox(TaskStatusEnum);
export const PriorityField = GetEnumCombobox(TaskPriorityEnum);
export const RoleField = GetEnumCombobox(Role);
export const DayOfWeekField = GetEnumCombobox(DayOfWeek);
