import { makeVar } from "@apollo/client";
import { SelectionProcess } from "features/WorklistTools/shared/interfaces";
import { WellSelection } from "../../shared/interfaces";

export interface IThawToolInternalState {
  destSelectionProcess: SelectionProcess;
  randomizeWells: boolean;
  selectedSourceWells: WellSelection[];
  requiredWarnings: string[];
  optionalWarnings: string[];
  worklist: any[];
  worklistPreviewOpen: boolean;
  methodSettings: IThawToolMethodSettings;
  sourcePlateHasTubeBarcodeScan: boolean;
}

export interface IThawToolMethodSettings {
  selectedSystem: number;
  runTaskId?: number;
  arraySystemAutomationMethodId: number;
  destPlateCode: string;
  includeTopUpVol: boolean;
  resuspensionVol: number;
  topupVol: number;
  rpm: number;
  gForce: number;
  spinTime: number;
  spinAccel: number;
  spinDecel: number;
}

export const ThawToolInternalState = makeVar<IThawToolInternalState>({
  destSelectionProcess: SelectionProcess.Stamp,
  randomizeWells: false,
  selectedSourceWells: [],
  requiredWarnings: [],
  optionalWarnings: [],
  worklist: [],
  worklistPreviewOpen: false,
  sourcePlateHasTubeBarcodeScan: false,
  methodSettings: {
    selectedSystem: 0,
    runTaskId: undefined,
    arraySystemAutomationMethodId: 105,
    destPlateCode: "",
    includeTopUpVol: false,
    resuspensionVol: 0,
    topupVol: 0,
    rpm: 40,
    gForce: 161,
    spinTime: 5,
    spinAccel: 10,
    spinDecel: 10,
  },
});
