import { Modal } from "@mui/material";
import { Wrapper } from "interfaces/Wrapper";
import React, { useRef } from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export function AppModal({
  children,
  onClose,
  open,
}: Wrapper & { open?: boolean; onClose: () => void }): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open ?? false}
    >
      <StyledDiv
        ref={ref}
        onMouseDown={(e) => {
          if (e.target === ref.current) onClose();
        }}
      >
        {children}
      </StyledDiv>
    </Modal>
  );
}
