import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";
import { Maybe } from "graphql-tools";
import { GetUrl } from "helpers/get-url";

interface IDFResultModalProps {
  isOpen: boolean;
  setModalState: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      title: string;
      message: Maybe<string>;
      traceId: Maybe<string>;
      errors: Maybe<Maybe<string>[]>;
    }>
  >;
  title: string;
  message: Maybe<string>;
  traceId: Maybe<string>;
  errors: Maybe<Maybe<string>[]> | null;
}

const IDFResultsModal = ({
  isOpen,
  setModalState,
  title,
  traceId,
  message,
  errors,
}: IDFResultModalProps) => {
  const errorList = errors?.map((result) => <ListItem>{result}</ListItem>);
  const handleCloseModal = () =>
    setModalState({
      show: false,
      title: "",
      traceId: "",
      message: "",
      errors: [],
    });
  const backendUrl = GetUrl();
  const handleDownload = () => {
    const url = `${backendUrl}/api/download/tubelocations/${traceId}`;
    window.location.href = url;
  };
  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>{message}</ListItem>
          {errorList}
        </List>
      </DialogContent>
      <DialogActions>
        {traceId && (
          <Button onClick={handleDownload}>Download Tube Locations</Button>
        )}
        <Button onClick={handleCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default IDFResultsModal;
