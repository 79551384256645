import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  IMethodManagerAction,
  IMethodManagerState,
  MethodManagerState,
} from "./initial-state";
import { methodStateReducer } from "./reducer";

export function useMethodManagerState(): {
  methodManagerState: IMethodManagerState;
  methodManagerDispatch: IApolloDispatch<IMethodManagerAction>;
} {
  const methodManagerState = useReactiveVar(MethodManagerState);
  const methodManagerDispatch = useMemo(
    () => ApolloStateDispatch(MethodManagerState, methodStateReducer),
    []
  );
  return useMemo(
    () => ({
      methodManagerState,
      methodManagerDispatch,
    }),
    [methodManagerState, methodManagerDispatch]
  );
}
