import {
  FormControl,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { getSamples } from "features/Runs/components/Forms/MethodDetails/helpers";
import { ImageExportFormats } from "features/RunTemplate/components/Forms/Create/Task/constants";
import {
  CeligoPlateMetadata,
  GetCeligoPlateMetadataByBarcode,
  updateCeligoPlateMetadata,
} from "features/WorklistTools/CeligoScan/Dialog/state";
import {
  ImageExportFormat,
  LookupExperimentSetting,
  PlateMetadataInput,
  PlateToMetadata,
  WorklistContentImaging,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { useEffect, useState } from "react";

export function PlateTableRow({
  plateToMetadata,
  worklistContent,
  experimentSettingOptions,
}: {
  plateToMetadata: Maybe<PlateToMetadata>;
  worklistContent?: Array<Maybe<WorklistContentImaging>> | null;
  experimentSettingOptions: Array<Maybe<LookupExperimentSetting>>;
}): JSX.Element {
  const plate = plateToMetadata?.platesCreated;
  const metadata = GetCeligoPlateMetadataByBarcode(plate?.plateBarcode ?? "");
  const [_metadata, setMetadata] =
    useState<Maybe<PlateMetadataInput>>(metadata);
  useEffect(() => {
    updateCeligoPlateMetadata(_metadata as PlateMetadataInput);
  }, [_metadata]);
  if (!CeligoPlateMetadata()) return <React.Fragment />;
  return (
    <TableRow>
      <TableCell align={"center"}>{_metadata?.sourcePlateBarcode}</TableCell>
      <TableCell align={"center"}>
        {[...new Set(getSamples(plate))].join(", ")}
      </TableCell>
      <TableCell align={"center"}>{plate?.containers?.length}</TableCell>
      <TableCell>
        <FormControl variant="outlined" fullWidth size={"small"}>
          <Select
            defaultValue={plateToMetadata?.metadata?.experimentSetting}
            disabled={!!worklistContent}
            value={_metadata?.experimentSetting}
            native
            onChange={(e) =>
              setMetadata({
                ..._metadata,
                experimentSetting: e.target.value as string,
              } as Maybe<PlateMetadataInput>)
            }
            inputProps={{
              name: "experimentSetting",
              id: "experimentSetting",
            }}
          >
            <option aria-label="None" value="" />
            {experimentSettingOptions.map((setting, idx) => (
              <option key={idx} value={setting?.experimentSetting ?? ""}>
                {setting?.experimentSetting}
              </option>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl>
          <TextField
            variant="outlined"
            margin="none"
            id="imageExportSetting"
            type="number"
            size={"small"}
            disabled={!!worklistContent}
            value={_metadata?.imageExportSetting ?? ""}
            onChange={(e) =>
              setMetadata({
                ..._metadata,
                imageExportSetting: parseInt(e.target.value),
              } as Maybe<PlateMetadataInput>)
            }
          />
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl variant="outlined" fullWidth size={"small"}>
          <Select
            value={_metadata?.imageExportFormat ?? ""}
            native
            disabled={!!worklistContent}
            onChange={(e) => {
              setMetadata({
                ..._metadata,
                imageExportFormat: e.target.value as ImageExportFormat,
              } as Maybe<PlateMetadataInput>);
            }}
            inputProps={{
              name: "imageExportFormat",
              id: "imageExportFormat",
            }}
          >
            <option aria-label="None" value="" />
            {ImageExportFormats.map((format) => (
              <option key={format.value} value={format.value}>
                {format.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </TableCell>
    </TableRow>
  );
}
