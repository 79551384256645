import { IntermediatePlateInfoState, SourcePlateInfoState } from "./state";

export const getSourcePlateInfoState = (): SourcePlateInfoState => {
  return {
    plateBarcode: "",
    labwareTypeCode: "",
    operatingVol: 0,
    rows: [],
    cols: [],
    wellInfo: [],
  };
};

export const getIntermediatePlateInfoState = (): IntermediatePlateInfoState => {
  return {
    plateBarcode: "",
    labwareTypeCode: "",
    operatingVol: 0,
    resuspensionVol: 0,
    rows: [],
    cols: [],
  };
};
