import produce from "immer";
import { ResetSelectedManualTaskTableRows } from "../components/Tables/ManualTaskTable/ManualTaskTable";
import { ResetSelectedMethodTableRows } from "../components/Tables/MethodTable/MethodTable";
import { MethodManagerActions } from "./actions";
import { IMethodManagerAction, IMethodManagerState } from "./initial-state";

export function methodStateReducer(
  state: IMethodManagerState,
  action: IMethodManagerAction
): IMethodManagerState {
  switch (action.type) {
    case MethodManagerActions.OPEN_METHOD_DETAILS:
      return produce(state, (draft) => {
        draft.methodSummaryDialogOpen = {
          open: true,
          methodId: action.payload?.methodSummaryDialogOpen?.methodId,
        };
      });
    case MethodManagerActions.OPEN_MODAL:
      return produce(state, (draft) => {
        draft.currentModal = action.payload?.currentModal;
        draft.modalOpen = true;
      });
    case MethodManagerActions.CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = false;
        if (
          draft?.selectedMethods?.length &&
          draft!.selectedMethods!.length > 0
        )
          ResetSelectedMethodTableRows();
        if (
          draft?.selectedManualTaskTypes?.length &&
          draft!.selectedManualTaskTypes!.length > 0
        )
          ResetSelectedManualTaskTableRows();
      });
    case MethodManagerActions.CLOSE_DIALOG:
      return produce(state, (draft) => {
        draft.methodSummaryDialogOpen = {
          methodId: draft?.methodSummaryDialogOpen?.methodId,
          open: false,
        };
        if (
          draft?.selectedMethods?.length &&
          draft!.selectedMethods!.length > 0
        )
          ResetSelectedMethodTableRows();
        if (
          draft?.selectedManualTaskTypes?.length &&
          draft!.selectedManualTaskTypes!.length > 0
        )
          ResetSelectedManualTaskTableRows();
      });
    case MethodManagerActions.SELECT_SCREEN:
      return produce(state, (draft) => {
        draft.activeScreen = action.payload?.activeScreen;
      });
    case MethodManagerActions.SELECT:
      return produce(state, (draft) => {
        draft.selectedMethods = action.payload?.selectedMethods;
        draft.selectedManualTaskTypes = action.payload?.selectedManualTaskTypes;
      });
    default:
      return state;
  }
}
