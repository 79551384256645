import { AnyAction, Dispatch, ThunkDispatch } from "@reduxjs/toolkit";
import {
  getArrayFromCount,
  getPlateWellIdFromRowAndColIndex,
  setWellColorBySample,
} from "features/WorklistTools/shared/WorklistHelpers";
import { GetPlatesInfoQuery } from "graphql/generated/schema-types";
import { Alert, AlertType } from "shared-components/toast";
import {
  Twelve24TCToolActions,
  SourcePlateInfoState,
} from "../../state";

interface IHandleUploadPlate {
  data: GetPlatesInfoQuery | undefined;
  setLabwareTypeDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  uploadIntermediatePlate: boolean;
  sourcePlateInfo: SourcePlateInfoState[];
  dispatch: ThunkDispatch<any, null, AnyAction> &
    ThunkDispatch<any, undefined, AnyAction> &
    Dispatch<AnyAction>;
  colorIndex: number;
  setColorIndex: React.Dispatch<React.SetStateAction<number>>;
  selectedPlateIndex: number;
}

export const handleUploadPlate = (params: IHandleUploadPlate) => {
  const {
    data,
    setLabwareTypeDialogOpen,
    uploadIntermediatePlate,
    sourcePlateInfo,
    dispatch,
    colorIndex,
    setColorIndex,
    selectedPlateIndex
  } = params;
  if (data && data.platesInfo == null) {
    Alert({
      type: AlertType.ERROR,
      message: "This plate doesn't have any active wells",
    });
  } else if (data?.platesInfo?.labwareType == null) {
    setLabwareTypeDialogOpen(true);
  } else if (data?.platesInfo.labwareType) {
    const wellsAlreadyUploaded = sourcePlateInfo
      .map((plate) => {
        return plate.wellInfo;
      })
      .filter((wells) => wells.length > 0);
    const sourceWellInfo = setWellColorBySample(
      data.platesInfo.containers?.filter((e) => e?.isActive === true) ?? [],
      wellsAlreadyUploaded[0] ?? [],
      colorIndex
    );
    setColorIndex(sourceWellInfo.colorIndex);
    dispatch(
      Twelve24TCToolActions.UPLOAD_SOURCE_PLATE({
        index: selectedPlateIndex,
        plateBarcode: data?.platesInfo?.plateBarcode,
        labwareTypeCode: data?.platesInfo?.labwareType?.labwareTypeCode,
        operatingVol: data?.platesInfo?.labwareType?.defaultLabwareVolume,
        rows: getArrayFromCount(
          data?.platesInfo?.labwareType?.plateLayoutType?.plateRows ?? 0
        ),
        cols: getArrayFromCount(
          data?.platesInfo?.labwareType?.plateLayoutType?.plateCols ?? 0
        ),
        wellInfo: sourceWellInfo.newWellInfo,
      })
    );
  }
};
