import { EventInput } from "@fullcalendar/common";
import FullCalendar from "@fullcalendar/react";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoIcon from "@mui/icons-material/Info";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import AddPersonalTask from "features/PersonalTasks/components/AddPersonalTask";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { RunsModalsAndDialogs } from "features/Runs/components/ModalsAndDialogs/RunModalsAndDialogs";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  RunFragment,
  useGetActiveRunsQuery,
} from "graphql/generated/schema-types";
import moment from "moment";
import { useMemo, useState } from "react";
import { GetLocalCombobox } from "shared-components/ComboBox/GetLocalCombobox";
import styled from "styled-components";
import { groupColors } from "../styles/Colors";
import { AddFeedTask } from "./AddFeedTask";

interface StyledBoxInGridProps {
  colorOptions: { light: string; dark: string };
}
const StyledBoxInGrid = styled(Box)<StyledBoxInGridProps>`
  background-color: ${({ theme, colorOptions }) =>
    theme.palette.mode === "dark" ? colorOptions.dark : colorOptions.light};
`;
const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  border: 2px solid;
  box-shadow: 24px;
  padding: 25px;
  text-align: center;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "#121212" : "#ffffff"};
`;

const renderTeamLegend = () => {
  const renderTeamLegendGridItems = () => {
    const teamsInLegend = groupColors.filter(
      (groupObj) => groupObj.isTeamLegend
    );

    return teamsInLegend.map((team, idx) => {
      return (
        <Grid item key={idx}>
          <StyledBoxInGrid
            colorOptions={{ light: team.light, dark: team.dark }}
          >
            {team.groupName}
          </StyledBoxInGrid>
        </Grid>
      );
    });
  };

  return (
    <StyledBox>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Team Colors:
      </Typography>
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <Grid container spacing={0.5} direction="column">
          {renderTeamLegendGridItems()}
        </Grid>
      </Typography>
    </StyledBox>
  );
};

interface HeaderProps {
  events: EventInput[];
  date: Date | null;
  fullCalendarRef: React.RefObject<FullCalendar>;
  totalEdits: number;
  setSaveClicked: React.Dispatch<React.SetStateAction<boolean>>;

  runId: number | undefined;
  setRunId: React.Dispatch<React.SetStateAction<number | undefined>>;
  myEventsOnly: boolean;
  extendedToggleChecked: boolean;
  setMyEventsOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setExtendedToggleChecked: React.Dispatch<React.SetStateAction<boolean>>;
  bulkReschedule: boolean;
  setBulkReschedule: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header = (props: HeaderProps) => {
  const [isAddPersonalTaskOpen, setIsAddPersonalTaskOpen] =
    useState<boolean>(false);

  const [isAddFeedTaskOpen, setIsAddFeedTaskOpen] = useState<boolean>(false);

  const eventRunIds = useMemo(() => {
    return [
      ...new Set(
        props.events.filter((e) => e.runId).map((e) => e.runId as number)
      ),
    ];
  }, [props.events]);

  const calendarDate = props.date;
  const calendarDayOfMonth = moment(calendarDate).date();
  const calendarMonth = moment(calendarDate).month();

  const RunField = GetLocalCombobox(
    useGetActiveRunsQuery,
    (result) =>
      result?.runs?.filter((r) => eventRunIds.includes(r?.runId ?? 0)) ?? [],
    (entry) => entry?.runId ?? 0,
    (entry) => entry?.runName ?? ""
  );

  const {
    runsModalDispatch,
    runsModalState: { selectedTasks },
  } = useRunsModalState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  return (
    <div style={{ width: "100%" }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <FormControlLabel
            label="My Tasks"
            control={<Switch />}
            onChange={(_event, value) => props.setMyEventsOnly(value)}
            checked={props.myEventsOnly}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label="24 hr"
            control={<Switch />}
            onChange={(_event, value) => props.setExtendedToggleChecked(value)}
            checked={props.extendedToggleChecked}
          />
        </Grid>
        <Grid item xs={2}>
          <RunField
            id="run"
            value={props.runId}
            onChange={(_event, value) =>
              props.setRunId((value as RunFragment)?.runId)
            }
            label="Run"
            variant="outlined"
          />
        </Grid>
        <Button onClick={handleModalOpen} sx={{ ml: 1.5, mt: 1 }}>
          Teams <InfoIcon sx={{ ml: 0.5, fontSize: 20 }} />
        </Button>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {renderTeamLegend()}
        </Modal>
        <Grid sx={{ flexGrow: 1 }} />

        <Grid item>
          <ButtonGroup variant="outlined">
            <Tooltip title="Toggle Bulk Edit">
              <Button
                value="toggleBulkEdit"
                onClick={() => props.setBulkReschedule(!props.bulkReschedule)}
              >
                <>
                  <EditOutlinedIcon />
                  {props.bulkReschedule ? `(${props.totalEdits})` : ""}
                </>
              </Button>
            </Tooltip>
            <Button
              disabled={!props.bulkReschedule || props.totalEdits === 0}
              onClick={() => props.setSaveClicked(true)}
            >
              <Tooltip title="Save Edits">
                <SaveOutlinedIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <ButtonGroup variant="contained">
            <Tooltip title="New Run">
              <Button
                onClick={() =>
                  runsModalDispatch({
                    type: RunsModalActions.OPEN_MODAL,
                    payload: {
                      currentModal: RunTaskModals.CreateRun,
                      modalOpen: true,
                      selectedTasks: selectedTasks,
                    },
                  })
                }
              >
                <>
                  New <DirectionsRunIcon sx={{ ml: 1 }} />
                </>
              </Button>
            </Tooltip>
            <Button onClick={() => setIsAddPersonalTaskOpen(true)}>
              <Tooltip title="New Personal Task">
                <TaskOutlinedIcon />
              </Tooltip>
            </Button>
            <Button onClick={() => setIsAddFeedTaskOpen(!isAddFeedTaskOpen)}>
              <Tooltip title="New Feed Task">
                <RestaurantIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={1.25}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Go to date"
              value={props.date}
              onChange={(date: Date | null) =>
                props.fullCalendarRef?.current?.getApi().gotoDate(date)
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <RunsModalsAndDialogs />
      <AddPersonalTask
        isOpen={isAddPersonalTaskOpen}
        handleClose={() => setIsAddPersonalTaskOpen(false)}
        calendarDayOfMonth={calendarDayOfMonth}
        calendarMonth={calendarMonth}
      />
      <AddFeedTask
        isOpen={isAddFeedTaskOpen}
        handleClose={() => setIsAddFeedTaskOpen(false)}
      />
    </div>
  );
};
