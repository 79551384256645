import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";
import { SampleActions } from "../../state/actions";
import { useSampleState } from "../../state/hooks";

export function SampleModalContainer({ children }: Wrapper): JSX.Element {
  const {
    sampleState: { modalOpen },
    sampleDispatch,
  } = useSampleState();
  return (
    <AppModal
      open={modalOpen ?? false}
      onClose={() => sampleDispatch({ type: SampleActions.CLOSE_MODAL })}
    >
      {children}
    </AppModal>
  );
}
