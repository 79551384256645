export const GetUrl = (): string => {
  if (process.env.REACT_APP_AMPLIFY_ENV === "development")
    return "https://dev-api.nyscf-websuite.org";
  if (process.env.REACT_APP_AMPLIFY_ENV === "idf")
    return "https://dev-api.nyscf-websuite.org";
  else if (process.env.NODE_ENV === "development")
    return "https://localhost:5001";
  else if (process.env.NODE_ENV === "production")
    return "https://api.nyscf-websuite.org";
  return "";
};
export const GetClientURL = (): string => {
  if (process.env.REACT_APP_AMPLIFY_ENV === "development")
    return "https://dev.nyscf-websuite.org";
  if (process.env.REACT_APP_AMPLIFY_ENV === "idf")
    return "https://idf.nyscf-websuite.org";
  else if (process.env.NODE_ENV === "development")
    return "http://localhost:3000";
  else if (process.env.NODE_ENV === "production")
    return "https://nyscf-websuite.org";
  return "";
};
export const GetVisToolUrl = (): string => {
  if (process.env.REACT_APP_AMPLIFY_ENV === "development")
    return "https://vistool-dev.nyscf-websuite.org";
  else if (process.env.NODE_ENV === "development")
    return "https://vistool-dev.nyscf-websuite.org";
  else if (process.env.NODE_ENV === "production")
    return "https://vistool.nyscf-websuite.org";
  return "";
};
