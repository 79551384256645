import { makeVar, useReactiveVar } from "@apollo/client";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ComputerId } from "features/Authentication/GroupAuth/SelectComputerForm";
import {
  AppSuiteUser,
  OperationNames,
  useGetMeQuery,
  useGroupMemberLoginMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { ChangeEvent, useEffect } from "react";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components/macro";

const StyledDialog = styled(Dialog).attrs(({ width }: { width?: string }) => ({
  width,
}))`
  div.MuiDialog-container.MuiDialog-scrollPaper > div {
    width: ${({ width }: { width?: string }) => width};
  }
`;
const StyledDialogContent = styled.div`
  display: flex;
  min-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px 50px 50px;
  width: 100%;
`;
export const GroupMemberLoginDialogOpen = makeVar<boolean>(false);
export const GroupMemberUserId = makeVar<number | undefined>(undefined);

export function GroupMemberLoginForm(): JSX.Element {
  const { data } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  const open = useReactiveVar(GroupMemberLoginDialogOpen);
  const currentUser = useReactiveVar(GroupMemberUserId);
  const labComputerId = useReactiveVar(ComputerId);

  const userOnChange = (
    event: ChangeEvent<unknown>,
    newValue: Maybe<AppSuiteUser> | null
  ) => {
    GroupMemberUserId(newValue?.userId);
  };

  useEffect(() => {
    GroupMemberUserId(data?.me?.loggedInGroupMember?.user?.userId);
  }, [data?.me?.loggedInGroupMember?.user?.userId]);
  const [execute] = useGroupMemberLoginMutation();

  return (
    <React.Fragment>
      <StyledDialog
        open={open}
        maxWidth={"xl"}
        onClose={() => GroupMemberLoginDialogOpen(false)}
      >
        <StyledDialogContent>
          <DialogTitle>Who Are You?</DialogTitle>
          <FormContainer>
            <Autocomplete
              id="member-combobox"
              value={
                (data?.me?.groupAccount?.users?.find(
                  (x) => x?.userId === currentUser
                ) as Maybe<AppSuiteUser>) ?? null
              }
              options={
                (data?.me?.groupAccount?.users as Maybe<AppSuiteUser>[]) ?? []
              }
              getOptionLabel={(option) => option?.userName ?? ""}
              style={{ margin: "20px 0", width: "100%" }}
              onChange={userOnChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for Group Member"
                  variant="outlined"
                />
              )}
            />
            <Button
              onClick={() => {
                const value = GroupMemberUserId();
                if (!GroupMemberUserId())
                  return Alert({
                    type: AlertType.ERROR,
                    message: "Please Select Method",
                  });
                if (value)
                  execute({
                    variables: {
                      computerUser: {
                        userId: GroupMemberUserId(),
                        computerId: labComputerId!,
                      },
                    },
                    refetchQueries: [OperationNames.Query.GetMe],
                    awaitRefetchQueries: true,
                  }).then(() => {
                    GroupMemberLoginDialogOpen(false);
                  });
              }}
              variant={"contained"}
            >
              Save
            </Button>
          </FormContainer>
        </StyledDialogContent>
      </StyledDialog>
    </React.Fragment>
  );
}
