import { makeVar } from "@apollo/client";
import { PlateMetadataInput } from "graphql/generated/schema-types";

export const CeligoMethodAlreadyReserved = makeVar(false);

export const CeligoPlateMetadata = makeVar<PlateMetadataInput[]>([]);

export function GetCeligoPlateMetadataByBarcode(
  barcode: string
): PlateMetadataInput | undefined {
  return CeligoPlateMetadata().find(
    (metadata) => metadata.sourcePlateBarcode === barcode
  );
}

export const updateCeligoPlateMetadata = (
  plateMetadata: PlateMetadataInput
) => {
  const metadata = [...CeligoPlateMetadata()];
  const idx = metadata.findIndex(
    (m) => m.sourcePlateBarcode === plateMetadata.sourcePlateBarcode
  );

  if (idx >= 0) metadata.splice(idx, 1, plateMetadata);
  CeligoPlateMetadata(metadata);
};
