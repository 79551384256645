import { makeVar } from "@apollo/client";
import { IApolloAction } from "interfaces/IApolloAction";
import { SampleModals } from "../components/ModelsAndDialogs/model.enum";
import { SampleActions } from "./actions";

export interface ISampleState {
  modalOpen?: boolean;
  currentModal?: SampleModals;
  containerSummaryDialogOpen?: {
    open?: boolean;
    containerId?: number;
  };
  selectedContainers?: number[];
}

export type ISampleAction = IApolloAction<SampleActions, ISampleState>;

export const SampleInitialState: ISampleState = {
  modalOpen: false,
};

export const SampleState = makeVar<ISampleState>(SampleInitialState);
