import { TicketTableContextMenuHidden } from "features/Tickets/components/Table/TicketTable";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import { TicketFragment } from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";

export const handleContextMenu =
  (selected: TicketFragment[], row?: Row<TicketFragment>) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    const _selected = selected.includes(row!.original)
      ? selected
      : [...selected, row?.original];
    contextMenu.show({
      id: "TicketTableContextMenu",
      event: e,
      props: {
        selected: _selected.map((s) => s?.ticketId),
      },
    });
    TicketTableContextMenuHidden(false);
  };

export const useHandleRowClick = (): ((
  rowClicked?: Row<TicketFragment>
) => (e: React.MouseEvent) => void) => {
  const { ticketDispatch } = useTicketState();
  return useMemo(
    () => (rowClicked?: Row<TicketFragment>) => () => {
      if (!rowClicked?.isGrouped && TicketTableContextMenuHidden()) {
        console.log("Method Tables Action: ROW_CLICKED");
        ticketDispatch({
          type: TicketActions.OPEN_TICKET_DETAILS,
          payload: {
            ticketSummaryDialogOpen: {
              ticketId: rowClicked?.original.ticketId,
            },
          },
        });
      }
    },
    [ticketDispatch]
  );
};
