import { WorklistValues } from "features/WorklistTools/shared/interfaces";
import { ArrayContainsDuplicateValue } from "helpers/array-helpers";
import { Alert, AlertType } from "shared-components/toast";
import {
  DestPlateInfoState,
  PoolingNormalizationToolActions,
  WorklistValuesState,
} from "../../state";

export const addDestinationWellMapping = (
  dispatch: (action: { type: string; payload: any }) => void,
  sourceIntIndex: number,
  wellMapping: WorklistValues[],
  destPlateInfo: DestPlateInfoState[],
  worklistValues: WorklistValuesState,
  destSelection: any,
  isStamping: boolean
) => {
  if (
    checkForSingleChannelAndStampOverflow(
      wellMapping,
      destPlateInfo,
      worklistValues,
      destSelection,
      isStamping
    )
  ) {
    Alert({
      type: AlertType.ERROR,
      message:
        "Cannot stamp and single channel seed into the same well while pre processing is disabled",
    });
    return;
  }

  if (isStamping) {
    dispatch(
      PoolingNormalizationToolActions.INT_TO_DEST_STAMP_TOP_LEFT_ADDED({
        selections: wellMapping,
        index: sourceIntIndex,
        stampTopLeft: {
          sourceWellId: wellMapping[0].sourceWellId,
          destPlateIndex: destSelection[0].plateIndex,
          destWellId: destSelection[0].plateWellId,
        },
      })
    );
    return;
  }
  if (singleChannelContainsSameIntToSameDestWell(wellMapping, worklistValues)) {
    Alert({
      type: AlertType.ERROR,
      message:
        "Cannot seed same intermediate well to the same destination well",
    });
    return;
  }
  dispatch(
    PoolingNormalizationToolActions.INT_TO_DEST_ADDED({
      index: sourceIntIndex,
      selections: wellMapping,
    })
  );
};

const singleChannelContainsSameIntToSameDestWell = (
  wellMapping: WorklistValues[],
  worklistValues: WorklistValuesState
) => {
  const getIntToDestWorklistValues = (intPlateIndex: number) => {
    switch (intPlateIndex) {
      case 0:
        return worklistValues.int1ToDest;
      case 1:
        return worklistValues.int2ToDest;
      case 2:
        return worklistValues.int3ToDest;
    }
  };

  const intPlateIndex = wellMapping[0].sourcePlateIndex;
  const destPlateIndex = wellMapping[0].destPlateIndex;

  const intToDest = getIntToDestWorklistValues(intPlateIndex);
  for (const well of wellMapping) {
    const intToDestAlreadyExists = Boolean(
      intToDest?.find(
        (e) =>
          e.sourcePlateIndex === intPlateIndex &&
          e.destPlateIndex === destPlateIndex &&
          e.sourceWellId === well.sourceWellId &&
          e.destWellId === well.destWellId
      )
    );
    if (intToDestAlreadyExists) return true;
  }

  return false;
};

const checkForSingleChannelAndStampOverflow = (
  wellMapping: WorklistValues[],
  destPlateInfo: DestPlateInfoState[],
  worklistValues: WorklistValuesState,
  destSelection: any,
  isStamping: boolean
) => {
  if (!destPlateInfo[destSelection[0].plateIndex].preprocess) {
    const allSingleChannelWells = [
      ...worklistValues.int1ToDest,
      ...worklistValues.int2ToDest,
      ...worklistValues.int3ToDest,
    ]
      .filter((e) => e.destPlateIndex === wellMapping[0].destPlateIndex)
      .map((item) => item.destWellId);
    const allStampWells =
      wellMapping.length > 0
        ? worklistValues.stampTopLeftTransfers
            .filter((e) => e.destPlateIndex === wellMapping[0].destPlateIndex)
            .map((item) => item.destWellId)
        : [];
    const destWells = wellMapping.map((item) => item.destWellId);
    const wellsToCheck = isStamping
      ? [...destWells, ...new Set(allSingleChannelWells)]
      : [...destWells, ...new Set(allStampWells)];
    if (ArrayContainsDuplicateValue(wellsToCheck)) {
      return true;
    }

    return false;
  }
};
