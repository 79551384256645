import {
  AppSuiteGroup,
  Maybe,
  Role,
  UserFragment,
} from "graphql/generated/schema-types";
import React, { FC } from "react";
import { Column } from "react-table";
import { Pill } from "shared-components/styled-components";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import styled from "styled-components/macro";
import { BooleanFilter } from "shared-components/Table/Filters/BooleanFilter";
import { GetGroupFilter } from "shared-components/Table/Filters/GroupFilter";
import { RoleFilter } from "shared-components/Table/Filters/RoleFilter";
import { BooleanCell } from "shared-components/Table/sub-components/BooleanCell";

export const UserGroupReducer = (group: AppSuiteGroup | undefined) => {
  switch (group!.groupId! % 6) {
    case 0:
      return (
        <Pill key={group!.groupId} color={"tomato"} label={group!.groupName!} />
      );
    case 1:
      return (
        <Pill key={group!.groupId} color={"orange"} label={group!.groupName!} />
      );
    case 2:
      return (
        <Pill
          key={group!.groupId}
          color={"mediumseagreen"}
          label={group!.groupName!}
        />
      );
    case 3:
      return (
        <Pill key={group!.groupId} color={"violet"} label={group!.groupName!} />
      );
    case 4:
      return (
        <Pill
          key={group!.groupId}
          color={"cadetblue"}
          label={group!.groupName!}
        />
      );
    case 5:
      return (
        <Pill key={group!.groupId} color={"coral"} label={group!.groupName!} />
      );
    case 6:
      return (
        <Pill key={group!.groupId} color={"orchid"} label={group!.groupName!} />
      );
    default:
      return null;
  }
};
export const UserRoleReducer = (role: Maybe<Role> | undefined) => {
  switch (role) {
    case Role.Admin:
      return <Pill key={role} color={"tomato"} label={"Admin"} />;
    case Role.Engineer:
      return <Pill key={role} color={"orange"} label={"Engineer"} />;
    case Role.Manager:
      return <Pill key={role} color={"green"} label={"Manager"} />;
    case Role.User:
      return <Pill key={role} color={"cadetblue"} label={"User"} />;
    default:
      return null;
  }
};

const StyledDiv = styled.div`
  display: flex;
  gap: 5px;
`;
const MultipleGroupCell: FC<{ groups: UserFragment["groups"] }> = ({
  groups,
}) => {
  if (!groups) return <React.Fragment />;
  return (
    <StyledDiv>{groups.map((group) => UserGroupReducer(group!))}</StyledDiv>
  );
};
const MultipleRoleCell: FC<{ roles: Role[] }> = ({ roles }) => {
  return <StyledDiv>{roles.map((role) => UserRoleReducer(role))}</StyledDiv>;
};

export const UserManagerColumns: Array<Column<UserFragment>> = [
  {
    Header: "ID",
    accessor: "userId",
    width: "5%",
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
  },
  {
    Header: "Username",
    accessor: "userName",
    Filter: TextFilter,
    width: "15%",
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: TextFilter,
    width: "20%",
  },
  {
    Header: "Active",
    accessor: "isActive",
    Cell: ({ value }) => <BooleanCell value={value ?? false} />,
    Filter: BooleanFilter,
    disableSortBy: true,
    width: "20%",
  },
  {
    Header: "Roles",
    accessor: "roles",
    Filter: RoleFilter,
    Cell: ({ value }) => (value ? <MultipleRoleCell roles={value} /> : null),
    width: "20%",
  },
  {
    Header: "Groups",
    accessor: "groups",
    Filter: GetGroupFilter(true),
    Cell: ({ value }) => (value ? <MultipleGroupCell groups={value} /> : null),
    width: "20%",
  },
];
