import { LookupAssetType } from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import { FilterProps } from "react-table";
import { AssetTypeField } from "shared-components/FormFields/FormFields";

export const GetAssetTypeFilter = (multiSelect = false) =>
  (function AssetTypeFilter<TData extends Record<string, unknown>>(
    {
      column: { filterValue, setFilter },
    }: FilterProps<TData>
  ): JSX.Element {
    return (
      <div style={{ width: "200px" }}>
        <AssetTypeField
          id={"assetType-filter"}
          label={"Search Platform"}
          variant={"standard"}
          multiple={multiSelect}
          value={filterValue}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
            const assetType = newValue as
              | LookupAssetType
              | LookupAssetType[]
              | undefined
              | null;
            if (multiSelect) {
              const filter = (
                assetType as LookupAssetType[] | null | undefined
              )?.map((u) => u.assetTypeId);
              if (filter?.length) setFilter(filter);
              else setFilter(null);
            } else
              setFilter((assetType as LookupAssetType)?.assetTypeId ?? null);
          }}
          helperText={false}
          error={false}
          autoFocus={true}
        />
      </div>
    );
  });
