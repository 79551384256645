import { TableCell, TableRow } from "@mui/material";
import { PlateMetadataInput } from "graphql/generated/schema-types";
import React from "react";

export function ReadOnlyPlateTableRow({
  metadata,
}: {
  metadata: PlateMetadataInput;
}): JSX.Element {
  return (
    <TableRow key={metadata.sourcePlateBarcode}>
      <TableCell align="center" width={"15%"}>
        {metadata.sourcePlateBarcode}
      </TableCell>
      <TableCell align="center" width={"15%"}>
        {metadata.platePitch}
      </TableCell>
      <TableCell align="center" width={"25%"}>
        {metadata.experimentSetting}
      </TableCell>
      <TableCell align="center" width={"15%"}>
        {metadata.imageExportSetting}
      </TableCell>
      <TableCell align="center" width={"15%"}>
        {metadata.imageExportFormat}
      </TableCell>
    </TableRow>
  );
}
