import { gql } from "@apollo/client/core";
import { ManualTaskType, Method } from "./method-fragments";

export const RunTemplateTask = gql`
  fragment RunTemplateTask on RunTemplateTask {
    runTemplateId
    runTemplateStageId
    runTemplateTaskId
    customTaskName
    users {
      userId
    }
    groups {
      groupId
    }
    systemAssignedTo
    dayScheduled
    runTemplateParentTaskId
    notes
    runTemplatesRepeatDaysOfWeeks {
      runTemplateRepeatDayOfWeekId
      dayOfWeek
      numberOfWeeks
    }
    method {
      ...Method
    }
    manualTaskType {
      ...ManualTaskType
    }
    manualTaskTypeId
    methodId
    runTemplateTaskDetail {
      runTemplateTaskId
      runTemplateTaskDetailsId
      daughterWellNumber
      seedCellCount
      freezeCellCount
      freezeDownRackType
      createMystplate
      destPlateCode
      minCryovial
      maxCryovial
      overflowVials
      imageExportFormat
      imageExportSetting
      worklistTemplateId
      intermediateLabwareTypeId
    }
  }
  ${Method}
  ${ManualTaskType}
`;
export const RunTemplateStage = gql`
  ${RunTemplateTask}
  fragment RunTemplateStage on RunTemplateStage {
    runTemplateId
    runTemplateStageId
    templateStageName
    stageSeqNo
    runTemplateTreeNodes {
      nodeId
      parentId
      dayScheduled
      child {
        runTemplateId
        runTemplateName
        numberOfSubTemplates
        numberOfTasks
      }
    }
    runTemplateTasks {
      ...RunTemplateTask
    }
  }
`;
export const RunTemplate = gql`
  ${RunTemplateStage}
  fragment RunTemplate on RunTemplate {
    runTemplateId
    runTemplateName
    productionRunTypeId
    createdByV
    createdBy
    numberOfSubTemplates
    numberOfTasks
    lastUpdatedBy
    lastTimeUpdated
    productionRunTypeNavigation {
      productionRunTypeId
      typeName
      runNumberDigits
    }
    folderLocationId
    folderLocation {
      folderId
      folderName
    }
    groups {
      groupId
    }
    runTemplateStages {
      ...RunTemplateStage
    }
  }
`;
