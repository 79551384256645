import { Error } from "@mui/icons-material";
import { ErrorToastContent } from "error/ErrorToastContent";
import { Abbreviate } from "helpers/string-helpers";
import produce from "immer";
import React, { Component, ErrorInfo } from "react";
import styled from "styled-components/macro";
import { PageWithNav } from "../features/Navbar/PageWithNav";
import { Alert, AlertType } from "../shared-components/toast";

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled(Error)`
  font-size: 16rem !important;
  color: red;
`;
const StyledTitle = styled.div`
  font-size: 6rem !important;
`;

const ErrorContainer = styled.pre`
  border-radius: 10px;
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "white" : "rgba(0,0,0,0.7)"};
  color: ${({
    theme: {
      palette: { mode },
    },
  }) => (mode === "dark" ? "black" : "white")};
  padding: 20px;
  margin-top: 50px;
`;
export class ErrorBoundary extends Component {
  state: { hasError: boolean; error?: Error };
  constructor(props: Record<string, Record<string, unknown>>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const newState = produce(this.state, (draft) => {
      draft.error = error;
    });
    this.setState(newState);
    Alert({
      type: AlertType.ERROR,
      hoc: () => (
        <ErrorToastContent
          shortErrorMessage={Abbreviate(error.message, 100)}
          longErrorMessage={error?.stack ?? error.message}
        />
      ),
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageWithNav>
          <StyledDiv>
            <StyledIcon />
            <StyledTitle>Oops</StyledTitle>
            <h3>
              Something went wrong. Please report bug in the{" "}
              <a
                href={"https://nyscf.slack.com/messages/software-development/"}
                target={"_blank"}
              >
                Software Development Channel
              </a>
            </h3>
            <ErrorContainer>{this.state?.error?.stack}</ErrorContainer>
          </StyledDiv>
        </PageWithNav>
      );
    }

    return this.props.children;
  }
}
