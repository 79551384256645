import { EditMultipleTaskDetailsForm } from "features/Runs/components/Forms/EditMultiple/TaskDetails/EditMultipleTaskDetailsForm";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { FormikHelpers } from "formik";
import {
  RunTask,
  RunTaskDetailInput,
} from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import produce from "immer";
import React from "react";
import { StyledCard } from "shared-components/styled-components";

export function EditMultipleTaskDetailsModal() {
  const windowSize = useWindowSize();
  const { runsModalState, runsModalDispatch } = useRunsModalState();
  const handleSubmit = (
    values: RunTaskDetailInput,
    { setSubmitting, setFieldError }: FormikHelpers<RunTaskDetailInput>
  ) => {
    const fieldValuePairs = Object.entries(values).filter(
      (pair) => pair[1] !== undefined && pair[1] !== 0
    );
    if (fieldValuePairs.length === 0) {
      setFieldError("formError", "Please enter a value");
      setSubmitting(false);
      return;
    }
    const pairsWithPrefixedKeyValues = fieldValuePairs.map((pair) =>
      produce(pair, (draft) => {
        draft[0] = `runActivityDetail.${draft[0]}`;
      })
    );
    runsModalDispatch({
      type: RunsModalActions.SUBMITTED_EDIT_FORM,
      payload: {
        editedFieldValuePairs: pairsWithPrefixedKeyValues as Array<
          [keyof RunTask, any]
        >,
        selectedTasks: runsModalState.selectedTasks,
      },
    });
  };
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <EditMultipleTaskDetailsForm handleSubmit={handleSubmit} />
    </StyledCard>
  );
}
