import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  OperationNames,
  useCreatePersonalTaskTemplateMutation,
} from "graphql/generated/schema-types";
import { useState } from "react";
import { Alert, AlertType } from "shared-components/toast";

interface AddNewPersonalTaskTemplateProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddNewPersonalTaskTemplate = ({
  isOpen,
  handleClose,
}: AddNewPersonalTaskTemplateProps) => {
  const [templateName, setTemplateName] = useState("");
  const [createTaskTemplate] = useCreatePersonalTaskTemplateMutation();

  const handleCreateClick = () => {
    createTaskTemplate({
      variables: {
        templateName: templateName,
      },
      refetchQueries: [OperationNames.Query.getPersonalTaskTemplates],
    }).then((data) => {
      handleClose();
      Alert({
        type: AlertType.SUCCESS,
        message: "Template Saved",
      });
    });
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add New Template</DialogTitle>
      <DialogContent>
        <TextField
          label="Template Name"
          onChange={(e: any) => setTemplateName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreateClick}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};
