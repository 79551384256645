import { TubeLabellerDestinationWorklist } from "graphql/generated/schema-types";
import { TubeLabellerInfo } from "../constants";
import { TubeLabellerAction } from "./action";
import { useTubeLabellerStateProvider } from "./StateProvider";

export const useHandleRackIndexChange = () => {
  const { tubeLabellerDispatch } = useTubeLabellerStateProvider();
  return (value: number) => {
    tubeLabellerDispatch({
      type: TubeLabellerAction.SET_RACK_INDEX,
      payload: {
        rackIndex: value,
      },
    });
  };
};

export const useHandleWorklistChange = () => {
  const { tubeLabellerDispatch } = useTubeLabellerStateProvider();
  return (value: TubeLabellerDestinationWorklist[]) => {
    tubeLabellerDispatch({
      type: TubeLabellerAction.SET_WORKLIST,
      payload: {
        worklist: value,
      },
    });
  };
};

export const useRemoveRack = () => {
  const { tubeLabellerDispatch } = useTubeLabellerStateProvider();
  return (value: number) => {
    tubeLabellerDispatch({
      type: TubeLabellerAction.REMOVE_RACK,
      payload: {
        rackIndex: value,
      },
    });
  };
};

export const useDecrementRacks = () => {
  const { tubeLabellerDispatch } = useTubeLabellerStateProvider();
  return (value: number) => {
    tubeLabellerDispatch({
      type: TubeLabellerAction.DECREMENT_RACKS,
      payload: {
        rackIndex: value,
      },
    });
  };
};

export const useAddRackInfo = () => {
  const { tubeLabellerDispatch } = useTubeLabellerStateProvider();
  return (value: TubeLabellerInfo[]) => {
    tubeLabellerDispatch({
      type: TubeLabellerAction.ADD_RACK_INFO,
      payload: {
        rackInfo: value,
      },
    });
  };
};
