import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";
import { UserManagerActions } from "../../state/actions";
import { useUserManagerState } from "../../state/hooks";

export function UserManagerModalContainer({ children }: Wrapper): JSX.Element {
  const {
    userManagerState: { modalOpen },
    userManagerDispatch,
  } = useUserManagerState();
  return (
    <AppModal
      open={modalOpen ?? false}
      onClose={() =>
        userManagerDispatch({ type: UserManagerActions.CLOSE_MODAL })
      }
    >
      {children}
    </AppModal>
  );
}
