import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export function TabPanel(props: any) {
  // TODO: fix type
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ flex: "1 1 auto" }}
    >
      {value === index && (
        <Box p={3} style={{ height: "100%" }}>
          {children}
        </Box>
      )}
    </Typography>
  );
}
