import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { PlatePosition } from "../interfaces";

export interface PlateType {
  labwareTypeCode: string;
  labwareTypeName: string;
}

interface PlateTypeProps {
  platePosition: PlatePosition;
  isDisabled: boolean;
  acceptedPlateTypes: any[];
  plateType: string;
  isRequired?: boolean;
  setPlateType?: (plateType: any) => void;
}

export const PlateType = ({
  platePosition,
  isDisabled,
  acceptedPlateTypes,
  plateType,
  isRequired = false,
  setPlateType,
}: PlateTypeProps) => {
  const handlePlateTypeChanged = (value: string) => {
    const plateTypeIndex = acceptedPlateTypes.findIndex(
      (e) => e.labwareTypeCode === value
    );
    setPlateType!(acceptedPlateTypes[plateTypeIndex]);
  };

  const containsError = isRequired && plateType === "";

  return (
    <>
      {acceptedPlateTypes ? (
        <FormControl variant="outlined" fullWidth error={containsError}>
          <InputLabel id={`${platePosition}-plate-type-label`}>
            {platePosition} Plate Type
          </InputLabel>
          <Select
            labelId={`${platePosition}-plate-type-label`}
            disabled={isDisabled}
            label={`${platePosition} Plate Type`}
            id="plate-type-select"
            value={plateType}
            onChange={(event) => handlePlateTypeChanged(event.target.value)}
          >
            {acceptedPlateTypes.map((item) => (
              <MenuItem value={item.labwareTypeCode}>
                {item.labwareTypeSummary}
              </MenuItem>
            ))}
          </Select>
          {containsError ? <FormHelperText>Required</FormHelperText> : null}
        </FormControl>
      ) : null}
    </>
  );
};
