import { useReactiveVar } from "@apollo/client";
import {
  getFilterHandler,
  getHandlePageCountChange,
  getSortHandler,
} from "features/PersonalTasks/components/PersonalTaskTemplate/components/table/handlers";
import {
  PersonalTaskTemplate,
  useGetPersonalTaskTemplatesQuery,
} from "graphql/generated/schema-types";
import produce from "immer";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { contextMenu, Item, Menu } from "react-contexify";
import {
  Row,
  useExpanded,
  useFilters,
  useGroupBy,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { useInitializeConnectionData } from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { usePersonalTaskTemplateStateProvider } from "../../state/StateProvider";
import { PersonalTaskTemplateAction } from "../../state/actions";
import { PersonalTaskTemplateState } from "../../state/inital-state";
import { PersonalTaskTemplateColumns } from "./columns";
import { getPageChangeHandler, useHandleRowClick } from "./handlers";

interface PersonalTaskTemplateTableProps {
  setStartTaskTemplateOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTemplate: Dispatch<
    SetStateAction<PersonalTaskTemplate | undefined>
  >;
}

export const PersonalTaskTemplateTable = ({
  setStartTaskTemplateOpen,
  setSelectedTemplate,
}: PersonalTaskTemplateTableProps) => {
  const personalTaskTemplateState = useReactiveVar(PersonalTaskTemplateState);
  const paginationTuple = useState<ReactTablePaginationState>({
    totalCount: 0,
    rowsPerPage: 50,
    page: 0,
  });

  const { personalTaskTemplateDispatch } =
    usePersonalTaskTemplateStateProvider();

  const { refetch, fetchMore, data, variables, loading } =
    useGetPersonalTaskTemplatesQuery({
      notifyOnNetworkStatusChange: true,
    });
  useEffect(() => {
    if (data?.personalTaskTemplates) {
      console.log(data?.personalTaskTemplates.edges);
      const index =
        data!.personalTaskTemplates!.edges?.findIndex(
          (e) =>
            e.node?.personalTaskTemplateId ==
            personalTaskTemplateState.selectedPersonalTaskTemplateId
        ) ?? 0;
      if (index > -1) {
        const template = data!.personalTaskTemplates!.edges![index]!.node!;
        personalTaskTemplateDispatch({
          type: PersonalTaskTemplateAction.SELECT,
          payload: {
            selectedPersonalTaskTemplateId: template.personalTaskTemplateId,
            selectedPersonalTaskTemplateName: template.personalTaskTemplateName,
            selectedPersonalTasksTemplateTask:
              template.personalTaskTemplateTasks,
          },
        });
      }
    }
  }, [data]);

  const [paginationState, setPaginationState] = paginationTuple;

  const pageData = useInitializeConnectionData<PersonalTaskTemplate>(
    data?.personalTaskTemplates,
    paginationState
  );

  const totalCount = useMemo(
    () =>
      GetTotalCountFromConnectionType<PersonalTaskTemplate>(
        data?.personalTaskTemplates
      ),
    [data?.personalTaskTemplates]
  );

  const pageInfo = useMemo(
    () =>
      GetPageInfoFromConnectionType<PersonalTaskTemplate>(
        data?.personalTaskTemplates
      ),
    [data?.personalTaskTemplates]
  );

  const onPageChange = useMemo(
    () =>
      getPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [
      fetchMore,
      pageInfo?.endCursor,
      paginationState,
      setPaginationState,
      variables,
    ]
  );

  const handlePageCountChange = useMemo(
    () =>
      getHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, setPaginationState, variables]
  );

  const personalTaskTemplateTableInstance = useTable(
    {
      columns: PersonalTaskTemplateColumns,
      data: pageData,
      manualFilters: true,
      manualSortBy: true,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded
  );
  const handleRowClick = useHandleRowClick();

  const sortTable = useMemo(
    () => getSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const filterTable = useMemo(
    () => getFilterHandler(variables, refetch),
    [refetch, variables]
  );

  const handleContextMenu =
    (selected: PersonalTaskTemplate[], row?: Row<PersonalTaskTemplate>) =>
    (e: React.MouseEvent<HTMLTableRowElement>) => {
      e.preventDefault();
      contextMenu.show({
        id: "personal-task-template-menu",
        event: e,
        props: {
          selected: selected.includes(row!.original)
            ? selected
            : [...selected, row?.original],
        },
      });
    };

  const handleStartTaskTemplateClick = (template: any) => {
    console.log(template.selected[0]);
    setSelectedTemplate(template.selected[0]);
    setStartTaskTemplateOpen(true);
  };

  const handleDeleteTaskTemplateClick = (template: any) => {
    setSelectedTemplate(template);
    console.log(template);
  };

  useEffect(() => {
    if (paginationState.page !== 0)
      setPaginationState(
        produce(paginationState, (draft) => {
          draft.page = 0;
        })
      );
  }, [paginationState, setPaginationState]);

  useEffect(() => {
    console.log(data);
    const _paginationState = produce(paginationState, (draft) => {
      draft.totalCount = totalCount ?? 0;
    });
    setPaginationState(_paginationState);
  }, [paginationState, setPaginationState, totalCount]);

  return (
    <React.Fragment>
      <ReactTable
        tableInstance={personalTaskTemplateTableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        onRowClick={handleRowClick}
        stateChangeDebounceDuration={500}
        size={"medium"}
        onRowContextMenu={handleContextMenu}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
      />

      <Menu id={"personal-task-template-menu"}>
        <Item onClick={({ props }) => handleStartTaskTemplateClick(props)}>
          Start Task Template
        </Item>
        {/* <Item onClick={({ props }) => handleDeleteTaskTemplateClick(props)}>Delete Task Template</Item> */}
      </Menu>
    </React.Fragment>
  );
};
