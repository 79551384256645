import { Button, FormControlLabel, Switch, TextField } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { UpdateMethodInput } from "graphql/generated/schema-types";
import React from "react";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { UpdateMethodValidationSchema } from "../../validation-schema";

export function EditMethodForm({
  initialValues,
  onSubmit,
}: {
  initialValues: UpdateMethodInput;
  onSubmit: (
    values: UpdateMethodInput,
    helpers: FormikHelpers<UpdateMethodInput>
  ) => void;
}): JSX.Element {
  const props = useFormik<UpdateMethodInput>({
    initialValues,
    onSubmit,
    validationSchema: UpdateMethodValidationSchema,
  });
  const { handleSubmit, isSubmitting, values, errors, touched, setFieldValue } =
    props;
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <FormControlLabel
          label={"Active"}
          labelPlacement={"start"}
          control={
            <Switch
              checked={values.isActive ?? false}
              onChange={(e) => setFieldValue("isActive", e.target.checked)}
            />
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id={"methodName"}
          fullWidth
          value={values.methodName}
          label={"Method Name"}
          onChange={(e) => {
            setFieldValue("methodName", e.target.value);
          }}
          variant={"outlined"}
          helperText={
            errors.methodName && touched.methodName && errors.methodName
          }
          error={
            !!(errors.methodName && touched.methodName && errors.methodName)
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id={"documentationUrl"}
          value={values.documentationUrl}
          label={"Documentation Link"}
          fullWidth
          onChange={(e) => {
            setFieldValue("documentationUrl", e.target.value);
          }}
          variant={"outlined"}
          helperText={
            errors.documentationUrl &&
            touched.documentationUrl &&
            errors.documentationUrl
          }
          error={
            !!(
              errors.documentationUrl &&
              touched.documentationUrl &&
              errors.documentationUrl
            )
          }
        />
      </StyledFormControl>
      <Button variant={"contained"} type={"submit"} disabled={isSubmitting}>
        Save Changes
      </Button>
    </StyledForm>
  );
}
