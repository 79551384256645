import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DeleteRunForm } from "features/Runs/components/Forms/Delete/Forms/DeleteRunForm";
import { DeleteRunTaskForm } from "features/Runs/components/Forms/Delete/Forms/DeleteRunTaskForm";
import { useRunsModalState } from "features/Runs/state/hooks";
import { useGetFolder, useGetRun } from "hooks/cacheAccessHooks";
import React, { useCallback, useState } from "react";
import { DeleteDialogType } from "../../state/modal/initial-state";
import { DeleteRunFolderForm } from "../Forms/Delete/Forms/DeleteRunFolderForm";

const DialogTextReducer = (typeName: DeleteDialogType["typeName"]) => {
  switch (typeName) {
    case "FolderStructure":
      return "This will delete this folder. Do you wish to continue? (Can be undone by SoftDev.)";
    case "Run":
      return "This will delete this run and all its tasks. Do you wish to continue? (Action cannot be undone)";
    case "RunTask":
      return "This will delete this tasks. Do you wish to continue? (Action cannot be undone)";
    default:
      return null;
  }
};
export function RunDeleteConfirmDialog(): JSX.Element {
  const {
    runsModalState: { deleteDialog },
  } = useRunsModalState();
  const submitStateTuple = useState(false);

  const run = useGetRun(deleteDialog?.runId ?? 0);
  const folder = useGetFolder(deleteDialog?.folderId ?? 0);

  const DialogReducer = useCallback(
    (typeName: DeleteDialogType["typeName"]) => {
      switch (typeName) {
        case "FolderStructure":
          return <DeleteRunFolderForm submitStateTuple={submitStateTuple} />;
        case "Run":
          return <DeleteRunForm submitStateTuple={submitStateTuple} />;
        case "RunTask":
          return <DeleteRunTaskForm submitStateTuple={submitStateTuple} />;
        default:
          return null;
      }
    },
    [submitStateTuple]
  );

  const DialogTitleReducer = useCallback(
    (typeName: DeleteDialogType["typeName"]) => {
      switch (typeName) {
        case "FolderStructure":
          return `Delete ${folder?.folderName}`;
        case "Run":
          return `Delete ${run?.runName}`;
        case "RunTask":
          return "Delete Task";
        default:
          return null;
      }
    },
    [folder?.folderName, run?.runName]
  );

  return (
    <Dialog open={deleteDialog?.open ?? false}>
      <div style={{ overflow: "hidden" }}>
        <DialogTitle>{DialogTitleReducer(deleteDialog?.typeName)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {DialogTextReducer(deleteDialog?.typeName)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{DialogReducer(deleteDialog?.typeName)}</DialogActions>
      </div>
    </Dialog>
  );
}
