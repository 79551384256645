import {
  fillSourcePlateWithColors,
  getRowAndColCountByLabwareType,
} from "features/WorklistTools/shared/WorklistHelpers";

export interface UploadedWorklist {
  task: string;
  details: string;
  aspiratevol: string;
  dispensevol: string;
  transfervol: string;
  sourceplatetype: string;
  sourceplatebarcode: string;
  sourcewellid: string;
  destinationplatetype: string;
  destinationplatebarcode: string;
  destinationwellid: string;
  rownumber: string;
}
interface SourcePlateInfo {
  plateBarcode: string;
  labwareTypeCode: string;
  rows: number[];
  cols: number[];
  wellInfo?: any[];
}

interface DestPlateInfo {
  plateBarcode: string;
  labwareTypeCode: string;
  operatingVol: number;
  preprocessPlate: boolean;
  inCytomat: boolean;
  rows: number[];
  cols: number[];
}

export const parseUploadedWorklist = async (
  uploadedWorklist: UploadedWorklist[]
) => {
  const sourcePlateInfo = await getSourcePlateInfoFromUploadedWorklist(
    uploadedWorklist
  );
  const destPlateInfo = getDestPlateInfoFromUploadedWorklist(uploadedWorklist);
  const thawTransfer = getThawTransferFromUploadedWorklist(
    uploadedWorklist,
    destPlateInfo
  );
  const parsedWorklistInfo = {
    sourcePlateInfo,
    destPlateInfo,
    thawTransfer,
  };

  return parsedWorklistInfo;
};

const getSourcePlateInfoFromUploadedWorklist = async (
  uploadedWorklist: UploadedWorklist[]
) => {
  const thaw = uploadedWorklist.filter((e) => e.task === "Thaw");
  const sourcePlateInfo: SourcePlateInfo[] = [
    {
      plateBarcode: "",
      labwareTypeCode: "",
      rows: [],
      cols: [],
      wellInfo: [],
    },
  ];
  const rowColCount = getRowAndColCountByLabwareType(thaw[0].sourceplatetype);
  const wellInfo = fillSourcePlateWithColors(
    rowColCount.rows,
    rowColCount.cols
  );
  //const wellInfo = await getSourcePlateInfo(0, thaw[0].sourceplatebarcode);
  sourcePlateInfo[0].plateBarcode = thaw[0].sourceplatebarcode;
  sourcePlateInfo[0].labwareTypeCode = "Matrix_96_Cryo_L_3740";
  sourcePlateInfo[0].rows = [1, 2, 3, 4, 5, 6, 7, 8];
  sourcePlateInfo[0].cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  sourcePlateInfo[0].wellInfo = wellInfo;

  return sourcePlateInfo;
};

const getDestPlateInfoFromUploadedWorklist = (
  uploadedWorklist: UploadedWorklist[]
) => {
  const destPlateInfo: DestPlateInfo[] = [
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
    {
      plateBarcode: "",
      labwareTypeCode: "",
      operatingVol: 0,
      preprocessPlate: false,
      inCytomat: false,
      rows: [],
      cols: [],
    },
  ];
  const thawDestination = uploadedWorklist.filter(
    (e) => e.task === "ThawDestination"
  );
  const uniqueDestinationPlates = [
    ...new Map(
      thawDestination.map((item) => [item["destinationplatebarcode"], item])
    ).values(),
  ];

  for (const [index, row] of uniqueDestinationPlates.entries()) {
    const rowColCount = getRowAndColCountByLabwareType(
      row.destinationplatetype
    );
    const details = row.details.split(";");
    const preprocessPlate = !!parseInt(
      details[0].substring(details[0].length - 1)
    );
    const inCytomat = !!parseInt(details[1].substring(details[1].length - 1));
    destPlateInfo[index] = {
      plateBarcode: row.destinationplatebarcode,
      labwareTypeCode: row.destinationplatetype,
      operatingVol: 200,
      inCytomat: inCytomat,
      preprocessPlate: preprocessPlate,
      rows: rowColCount.rows,
      cols: rowColCount.cols,
    };
  }

  return destPlateInfo;
};

const getThawTransferFromUploadedWorklist = (
  uploadedWorklist: UploadedWorklist[],
  destPlateInfo: DestPlateInfo[]
) => {
  const uploadedThawTransfer = uploadedWorklist.filter(
    (e) => e.task === "ThawTransfer"
  );
  const thawTransfer = uploadedThawTransfer.map((row) => ({
    sourcePlateIndex: 0,
    sourceWellId: row.sourcewellid,
    destPlateIndex: destPlateInfo.findIndex(
      (e) => e.plateBarcode === row.destinationplatebarcode
    ),
    destWellId: row.destinationwellid,
    transferVol: row.transfervol,
  }));

  return thawTransfer;
};

const getMethodSettingsFromUploadedWorklist = (
  uploadedWorklist: UploadedWorklist[]
) => {
  const uploadedMethodSettings = uploadedWorklist.filter(
    (e) => e.task === "MatrixRackCentrifugation"
  );
};
