import { useQuery } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DashboardQueries } from "graphql/queries/dashboards.queries";
import React, { useState } from "react";

interface Plate {
  plateID: number;
  plateBarcode: string;
  selectedWells: any[];
}

interface PlateMapProps {
  isOpen: boolean;
  handleClose: (selectedWells: any[]) => void;
  plate: Plate;
}
export const PlateMap = ({ isOpen, handleClose, plate }: PlateMapProps) => {
  const [plateRows, setPlateRows] = useState<number[]>();
  const [plateCols, setPlateCols] = useState<number[]>();
  const [selectedWells, setSelectedWells] = useState<any[]>([]);

  const handleOnEnter = () => {
    setSelectedWells(plate.selectedWells);
  };
  const getPlateMap = useQuery<any, any>(DashboardQueries.GET_PLATEMAP, {
    variables: {
      plateID: plate.plateID,
    },
    fetchPolicy: "network-only",
    onCompleted() {
      //   console.log(getPlateMap.data.plateMap);
      if (getPlateMap.data && getPlateMap.data.plateMap.length > 0) {
        createPlateMapLayout(getPlateMap.data.plateMap[0]);
        if (selectedWells.length == 0) {
          getActiveWells(getPlateMap.data.plateMap[0]);
        }
      }
    },
  });

  const createPlateMapLayout = (plateInfo: any) => {
    switch (plateInfo.arrayPlateType?.plateLayoutType?.plateName) {
      case "6Well":
        setPlateRows([1, 2]);
        setPlateCols([1, 2, 3]);
        break;
      case "12Well":
        setPlateRows([1, 2, 3]);
        setPlateCols([1, 2, 3, 4]);
        break;
      case "24Well":
        setPlateRows([1, 2, 3, 4]);
        setPlateCols([1, 2, 3, 4, 5, 6]);
        break;
      case "48Well":
        setPlateRows([1, 2, 3, 4, 5, 6]);
        setPlateCols([1, 2, 3, 4, 5, 6, 7, 8]);
        break;
      case "96Well":
        setPlateRows([1, 2, 3, 4, 5, 6, 7, 8]);
        setPlateCols([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
        break;
      default:
        console.log("plate type not supported");
    }
  };

  const getActiveWells = (plateInfo: any) => {
    const active = plateInfo.containers.filter((e: any) => e.isActive === true);
    console.log(active);
    setSelectedWells(active);
  };

  const getWellColor = (rowItem: number, colItem: number): string => {
    //   console.log(getPlateMap);
    //   return 'red';
    if (getPlateMap.data && getPlateMap.data.plateMap.length > 0) {
      const index = getPlateMap.data.plateMap[0].containers.findIndex(
        (x: any) => x.rowPos == rowItem && x.colPos == colItem
      );
      if (index > -1) {
        if (getPlateMap.data.plateMap[0].containers[index].isActive == true) {
          return "blue";
        }
        return "red";
      }
    }
    return "grey";
  };

  const getOpacity = (rowItem: number, colItem: number): string => {
    if (
      selectedWells.some(
        (e: any) => e.rowPos === rowItem && e.colPos === colItem
      )
    ) {
      return ".6";
    }
    return "";
  };

  const handleWellClick = (rowItem: number, colItem: number) => {
    //deselect wells
    let index = selectedWells.findIndex(
      (e: any) => e.rowPos === rowItem && e.colPos === colItem
    );
    if (index > -1) {
      const newWellSelection = [...selectedWells];
      newWellSelection.splice(index, 1);
      setSelectedWells(newWellSelection);
      console.log("over");
    } else {
      const values = getPlateMap.data.plateMap[0].containers;
      index = values.findIndex(
        (e: any) => e.rowPos === rowItem && e.colPos === colItem
      );
      if (index > -1) {
        if (values[index].isActive === true) {
          const newWellSelection: any[] = [...selectedWells];
          newWellSelection.push(values[index]);
          setSelectedWells(newWellSelection);
        } else if (values[index].isActive === false) {
          console.log(
            "this well is inactive would you like to activate and select for feed?"
          );
        }
      } else {
        console.log("what are you doing?");
      }
    }
    // else {
    //   const values = getPlateMap.data.plateMap;
    //   console.log(values);
    //   console.log("here");
    // }
  };

  return (
    <Dialog
      //   onEnter={handleOnEnter}
      open={isOpen}
      onClose={() => handleClose([])}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle>{plate.plateBarcode}</DialogTitle>
      <DialogContent>
        <table style={{ margin: "auto" }}>
          <thead>
            <tr>
              <td></td>
              {plateCols?.map((colItem, colIndex) => (
                <td style={{ textAlign: "center" }}>{colItem}</td>
              ))}
            </tr>
          </thead>
          {plateRows?.map((rowItem, rowIndex) => (
            <tr>
              <td style={{ width: "15px" }}>
                {String.fromCharCode(64 + rowItem)}
              </td>
              {plateCols?.map((colItem, colIndex) => (
                <td>
                  <div
                    onClick={() => handleWellClick(rowItem, colItem)}
                    style={{
                      backgroundColor: getWellColor(rowItem, colItem),
                      opacity: getOpacity(rowItem, colItem),
                      cursor: "pointer",
                      border: "1px solid black",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                    }}
                  ></div>
                </td>
              ))}
            </tr>
          ))}
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose([])}>Cancel</Button>
        <Button onClick={() => handleClose(selectedWells)}>Save Changes</Button>
      </DialogActions>
    </Dialog>
  );
};
