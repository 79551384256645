import { TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { AddRackOptions } from "features/TubeLabeller/constants";

interface DateSelector {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const DateSelector = ({ values, setFieldValue }: DateSelector) => {
  return (
    <DatePicker
      value={values.date}
      onChange={(date) => setFieldValue("date", date)}
      renderInput={(props) => (
        <TextField {...props} variant={"outlined"} label="Date" />
      )}
    />
  );
};
