import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import { IThawToolInternalState, ThawToolInternalState } from "./initial-state";
import { ThawToolInternalReducer } from "./reducer";

export function useThawToolStateProvider(): {
  thawToolState: IThawToolInternalState;
  thawToolDispatch: IApolloDispatch<any>;
} {
  const thawToolState = useReactiveVar(ThawToolInternalState);
  const thawToolDispatch = useMemo(
    () => ApolloStateDispatch(ThawToolInternalState, ThawToolInternalReducer),
    []
  );
  return {
    thawToolState,
    thawToolDispatch,
  };
}
