export const CapitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const Abbreviate = (s: string, maxLength: number): string => {
  return s.length > maxLength ? s.substring(0, maxLength).concat("...") : s;
};

export const GetInitials = (s: string) =>
  s
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");

export const GetAlphabet = (index: number) => {
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  return alphabet[index - 1];
};
