import { Dialog } from "@mui/material";
import { animated, useTransition } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import {
  TicketAttachment,
  useGetTicketInfoQuery,
} from "graphql/generated/schema-types";
import { GetInitials } from "helpers/string-helpers";
import { useGetUser } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import { StyledCard } from "shared-components/styled-components";
import { TicketActions } from "../../../state/actions";
import { useTicketState } from "../../../state/hooks";
import { AttachmentCarousel } from "./Attachments/AttachmentCarousel";
import { SummaryContent } from "./sub-components/SummaryContent";
import { SummaryHeader } from "./sub-components/SummaryHeader";
import { TicketUpdates } from "./TicketUpdates";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
`;

const StyledDialog = styled(Dialog).attrs(({ width }: { width?: string }) => ({
  width,
}))`
  div.MuiDialog-container.MuiDialog-scrollPaper > div {
    width: ${({ width }: { width?: string }) => width};
  }
`;

export function TicketSummaryDialog(): JSX.Element {
  const windowSize = useWindowSize();
  const {
    ticketState: { ticketSummaryDialogOpen },
    ticketDispatch,
  } = useTicketState();
  const { loading, data, startPolling, stopPolling } = useGetTicketInfoQuery({
    variables: {
      ticketId: ticketSummaryDialogOpen?.ticketId ?? 0,
    },
  });
  const user = useGetUser(data?.ticketInfo?.createdByUserId);
  const attachmentsTuple = useState(false);
  const [showAttachments, setShowAttachments] = attachmentsTuple;
  const userInitials = GetInitials(user?.userName ?? "");

  useEffect(() => {
    setTimeout(() => setShowAttachments(false), 100);
  }, [setShowAttachments, ticketSummaryDialogOpen?.open]);

  useEffect(() => {
    startPolling(2000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    if (!ticketSummaryDialogOpen?.open) {
      stopPolling();
    }
  }, [stopPolling, ticketSummaryDialogOpen]);

  const transition = useTransition(showAttachments, {
    from: {
      maxWidth: "0vw",
      maxHeight: "20vh",
    },
    enter: {
      maxWidth: "60vw",
      maxHeight: "70vh",
    },
    leave: {
      maxWidth: "0vw",
      maxHeight: "0vh",
      overflow: "hidden",
    },
  });
  return (
    <StyledDialog
      open={ticketSummaryDialogOpen?.open ?? false}
      maxWidth={"xl"}
      onClose={() =>
        ticketDispatch({
          type: TicketActions.CLOSE_DIALOG,
        })
      }
    >
      <Container>
        <StyledCard
          width={
            windowSize.width! > 2000
              ? "20vw"
              : windowSize.width! > 1500
              ? "35vw"
              : "50vw"
          }
          padding={"15px"}
          zindex={10}
        >
          <SummaryHeader
            loading={loading}
            user={user}
            avatar={userInitials}
            ticketQuery={data}
          />
          <SummaryContent
            loading={loading}
            data={data}
            attachmentsTuple={attachmentsTuple}
          />
          <TicketUpdates ticketsQuery={data} user={user} />
        </StyledCard>
        {transition(
          (props, item) =>
            item && (
              <animated.div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  ...props,
                }}
              >
                <AttachmentCarousel
                  ticketId={ticketSummaryDialogOpen?.ticketId ?? 0}
                  attachments={
                    (data?.ticketInfo
                      ?.ticketAttachments as TicketAttachment[]) ?? []
                  }
                />
              </animated.div>
            )
        )}
      </Container>
    </StyledDialog>
  );
}
