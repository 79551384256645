import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import { ITubeLabellerState, TubeLabellerState } from "./initial-state";
import { TubeLabellerReducer } from "./reducer";

export function useTubeLabellerStateProvider(): {
  tubeLabellerState: ITubeLabellerState;
  tubeLabellerDispatch: IApolloDispatch<any>;
} {
  const tubeLabellerState = useReactiveVar(TubeLabellerState);
  const tubeLabellerDispatch = useMemo(
    () => ApolloStateDispatch(TubeLabellerState, TubeLabellerReducer),
    []
  );
  return {
    tubeLabellerState,
    tubeLabellerDispatch,
  };
}
