import { TextField } from "@mui/material";
import { AddRackOptions } from "../../constants";

interface CellNumberSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const CellNumberSelector = ({
  values,
  setFieldValue,
}: CellNumberSelectorProps) => {
  return (
    <TextField
      style={{ width: "100%" }}
      label="Cell Number"
      type="number"
      value={values.cellNumber ?? ""}
      onChange={(e) => setFieldValue("cellNumber", parseInt(e.target.value))}
    />
  );
};
