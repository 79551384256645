import { PlateMetaDataValidationSchema } from "features/WorklistTools/CeligoScan/Dialog/validation-schema";
import { FormikErrors } from "formik";
import {
  PlateMetadata,
  PlateMetadataInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { ValidateObject } from "helpers/validateObject";

export const ValidatePlateMetadata = async (
  metadata: Maybe<PlateMetadata>[]
): Promise<string | undefined> => {
  const metaDataErrors: Array<
    { barcode?: string } & FormikErrors<PlateMetadataInput>
  > = [];
  for (const data of metadata) {
    const error = await ValidateObject(data, PlateMetaDataValidationSchema);
    if (error)
      metaDataErrors.push({ barcode: data?.sourcePlateBarcode, ...error });
  }
  let errorMessage = "";
  if (metaDataErrors.length > 0) {
    errorMessage = JSON.stringify(metaDataErrors);
  }
  return errorMessage ? errorMessage : undefined;
};
