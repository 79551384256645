import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {
  useDeleteWorklistToolTemplateMutation,
  useGetWorklistToolTemplateQuery,
  WorklistToolTemplate,
} from "graphql/generated/schema-types";
import moment from "moment";
import { useState } from "react";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { UserCell } from "shared-components/Table/sub-components/CacheLookupCells";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";

interface ViewWorklistTemplateProps {
  isOpen: boolean;
  handleClose: (
    loadTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => void;
  overwriteTemplateMode: boolean;
  methodId: number;
  dateWorklistLastUpdated: Date;
}

export const ViewWorklistTemplate = ({
  isOpen,
  handleClose,
  overwriteTemplateMode,
  methodId,
  dateWorklistLastUpdated,
}: ViewWorklistTemplateProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<
    WorklistToolTemplate | undefined
  >(undefined);
  const { data } = useGetWorklistToolTemplateQuery({
    variables: {
      where: { methodId: { eq: methodId } },
    },
  });
  const { isConfirmed } = useConfirm();
  const [deleteWorklistToolTemplate] = useDeleteWorklistToolTemplateMutation({
    onCompleted() {
      Alert({
        type: AlertType.SUCCESS,
        message: "Deleted Worklist Template",
      });
      handleClose(false, undefined);
    },
  });

  const handleDeleteClick = async (worklistToolTemplateId: number) => {
    const confirmed = await isConfirmed(
      "Are you sure you want to delete this template?"
    );

    if (!confirmed) return;

    deleteWorklistToolTemplate({
      variables: {
        worklistToolTemplateId: worklistToolTemplateId,
      },
    });
  };

  const isTemplateOwner = (createdBy: number) =>
    parseInt(localStorage.getItem("userID") ?? "") === createdBy;

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose(false, undefined)}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>Worklist Templates</DialogTitle>
      <DialogContent>
        <Typography>
          Selected Template: {selectedTemplate?.templateName}
        </Typography>
        <br />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Template Name</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.worklistToolTemplate?.map((item, index) => (
                <TableRow>
                  <TableCell>
                    <Radio
                      disabled={
                        !isTemplateOwner(item!.createdBy) &&
                        overwriteTemplateMode
                      }
                      checked={selectedTemplate === item}
                      onChange={() =>
                        setSelectedTemplate(item as WorklistToolTemplate)
                      }
                    />
                  </TableCell>
                  <TableCell>{item?.templateName}</TableCell>
                  <TableCell>
                    <UserCell userId={item?.createdBy} />
                  </TableCell>
                  <TableCell>
                    {moment(item?.lastUpdateByTime).format(
                      "MMM Do YYYY, h:mm A"
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(item?.lastUpdateByTime) >
                    dateWorklistLastUpdated ? (
                      <div style={{ color: "green" }}>Current</div>
                    ) : (
                      <div style={{ color: "red" }}>Outdated</div>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={
                        !isTemplateOwner(item!.createdBy) ||
                        overwriteTemplateMode
                      }
                      onClick={() =>
                        handleDeleteClick(item!.worklistToolTemplateId!)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={
            selectedTemplate === undefined
            // !isTemplateOwner(selectedTemplate.createdBy)
          }
          onClick={() => handleClose(true, selectedTemplate)}
        >
          {overwriteTemplateMode ? "Overwrite" : "Open"}
        </Button>
      </DialogActions>
      <ConfirmDialog />
    </Dialog>
  );
};
