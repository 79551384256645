import { useQuery } from "@apollo/client";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import {
  GetSystemsQuery,
  GetSystemsQueryVariables,
  ManagePersonalTask,
  Maybe,
} from "graphql/generated/schema-types";
import { LookupQueries } from "graphql/queries/lookup.queries";
import React, { ChangeEvent, useState } from "react";

interface AssignToSystemProps {
  isOpen: boolean;
  handleClose: (assignedSystem: number, assignSystem: boolean) => void;
  selectedTasks: Maybe<ManagePersonalTask[]>;
}

export const AssignToSystemDialog = ({
  isOpen,
  handleClose,
  selectedTasks,
}: AssignToSystemProps) => {
  const systemsQuery = useQuery<GetSystemsQuery, GetSystemsQueryVariables>(
    LookupQueries.remote.GET_SYSTEMS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
    }
  );

  const [selectedSystem, setSelectedSystem] = useState({ systemId: 0 });

  const handleSubmit = () => {
    handleClose(selectedSystem.systemId, true);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => handleClose(0, false)}>
        <DialogTitle>Assign Tasks to System</DialogTitle>
        <DialogContent>
          <ul>
            {selectedTasks?.map((item) => (
              <li>{item.taskName}</li>
            ))}
          </ul>
          <Autocomplete
            id="systemAssignedTo"
            disableClearable
            options={systemsQuery.data?.systems ?? []}
            getOptionLabel={(option) => option!.systemName!}
            style={{ margin: "0px 0", width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="System Assigned To"
                variant="outlined"
              />
            )}
            // value={
            //   values.systemAssignedTo ? values.systemAssignedTo : undefined
            // }
            onChange={(event: ChangeEvent<unknown>, newValue: any) =>
              setSelectedSystem(newValue)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
