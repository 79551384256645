import { Button, TextField } from "@mui/material";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { SetLabwareType } from "features/WorklistTools/shared/components/SetLabwareType";
import { SourcePlate } from "features/WorklistTools/shared/components/SourcePlate";
import "features/WorklistTools/shared/components/styles/WorklistGenerator.scss";
import { UploadSourcePlateDialog } from "features/WorklistTools/shared/components/UploadSourcePlateDialog";
import { useCtrlDown } from "features/WorklistTools/shared/hooks/hooks";
import {
  PlatePosition,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import {
  getArrayFromCount,
  getPlateWellIdFromRowAndColIndex,
  removeUnusedSourceWells,
  setWellColorBySample,
} from "features/WorklistTools/shared/WorklistHelpers";
import {
  LookupLabwareType,
  useGetPlatesInfoLazyQuery,
  usePlateHasTubeBarcodeScanLazyQuery,
  useSetLabwareTypeMutation,
} from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useState } from "react";
import { SelectableGroup } from "react-selectable-fast";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components";
import { SourcePlateInfoState, ThawToolActions } from "../state";
import { ThawToolInternalAction } from "../state/action";
import { useHandleSourceWellsSelected } from "../state/handlers";
import { useThawToolStateProvider } from "../state/StateProvider";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%;
`;

const StyledPlatePlaceholder = styled.div`
  height: 300px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
`;

const StyledPlateTypeContainer = styled.div`
  margin-top: 15px;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const SourcePlatesContainer = (sourcePlateTypeInfo: any) => {
  const [selectedPlateIndex, setSelectedPlateIndex] = useState(0);
  const [uploadPlateDialogOpen, setUploadPlateDialogOpen] = useState(false);
  const [labwareTypeDialogOpen, setLabwareTypeDialogOpen] = useState(false);
  const { thawToolState, thawToolDispatch } = useThawToolStateProvider();
  const dispatch = useAppReduxDispatch();
  const [colorIndex, setColorIndex] = useState(0);
  const [plateHasBarcodeScan, { data: hasTubeBarcodeScan }] =
    usePlateHasTubeBarcodeScanLazyQuery({
      onCompleted: () => {
        thawToolDispatch({
          type: ThawToolInternalAction.SET_PLATE_HAS_TUBE_BARCODE_SCAN,
          payload: {
            sourcePlateHasTubeBarcodeScan:
              hasTubeBarcodeScan?.plateHasTubeBarcodeScan,
          },
        });
      },
    });
  const [getPlateInfo, { data: plateInfo }] = useGetPlatesInfoLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.platesInfo == null) {
        Alert({
          type: AlertType.ERROR,
          message: "This plate doesn't have any active wells",
        });
      } else if (plateInfo?.platesInfo?.labwareType === null) {
        setLabwareTypeDialogOpen(true);
      } else if (plateInfo?.platesInfo?.labwareType) {
        const items = sourcePlateInfo
          .map((item) => {
            return item.wellInfo;
          })
          .filter((e) => e.length > 0);
        const tuple = setWellColorBySample(
          plateInfo?.platesInfo?.containers?.filter(
            (e) => e?.isActive === true
          ) ?? [],
          items[0] ?? [],
          colorIndex
        );
        setColorIndex(tuple.colorIndex);
        const sourceWellIds = plateInfo?.platesInfo?.containers?.map((e) => {
          return getPlateWellIdFromRowAndColIndex(
            e!.rowPos! - 1,
            e!.colPos - 1
          )!;
        });
        const values = removeUnusedSourceWells(
          worklistValues.thawTransfer,
          sourceWellIds!
        );
        dispatch(
          ThawToolActions.UPLOAD_WORKLIST_MAPPING({
            thawTransfer: values,
          })
        );
        dispatch(
          ThawToolActions.UPLOAD_SOURCE_PLATE({
            index: selectedPlateIndex,
            plateBarcode: plateInfo?.platesInfo?.plateBarcode,
            labwareTypeCode:
              plateInfo?.platesInfo?.labwareType?.labwareTypeCode,
            operatingVol:
              plateInfo?.platesInfo?.labwareType?.defaultLabwareVolume,
            rows: getArrayFromCount(
              plateInfo?.platesInfo?.labwareType?.plateLayoutType?.plateRows ??
                0
            ),
            cols: getArrayFromCount(
              plateInfo?.platesInfo?.labwareType?.plateLayoutType?.plateCols ??
                0
            ),
            wellInfo: tuple.newWellInfo,
          })
        );
      }
    },
  });
  const [setLabwareType] = useSetLabwareTypeMutation();
  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.sourcePlateInfo
  );
  const worklistValues = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.worklistValues
  );

  const handleSourceSelection = useHandleSourceWellsSelected();

  const handleUploadSourcePlateClose = (e: any, plate: any, data: any) => {
    setUploadPlateDialogOpen(false);
    getPlateInfo({
      variables: {
        where: {
          plateCreatedId: { eq: plate.plateCreatedId },
          containers: {
            some: {
              isActive: { eq: true },
            },
          },
        },
      },
    });
    plateHasBarcodeScan({ variables: { plateId: plate.plateCreatedId } });
  };

  const handleSetLabwareTypeClose = (
    plateId: number,
    labwareType: LookupLabwareType | undefined,
    saveChanges: boolean
  ) => {
    setLabwareTypeDialogOpen(false);
    if (saveChanges) {
      const labwareTypeId = labwareType!.labwareTypeId;
      setLabwareType({
        variables: {
          plateId,
          labwareTypeId,
        },
      }).then((res) => {
        const items = sourcePlateInfo
          .map((item) => {
            return item.wellInfo;
          })
          .filter((e) => e.length > 0);
        const tuple = setWellColorBySample(
          plateInfo?.platesInfo?.containers?.filter(
            (e) => e?.isActive === true
          ) ?? [],
          items[0] ?? [],
          colorIndex
        );
        setColorIndex(tuple.colorIndex);
        const sourceWellIds = plateInfo?.platesInfo?.containers?.map((e) => {
          return getPlateWellIdFromRowAndColIndex(
            e!.rowPos! - 1,
            e!.colPos - 1
          )!;
        });
        const matchedMapping = removeUnusedSourceWells(
          worklistValues.thawTransfer,
          sourceWellIds!
        );
        dispatch(
          ThawToolActions.UPLOAD_WORKLIST_MAPPING({
            thawTransfer: matchedMapping,
          })
        );
        dispatch(
          ThawToolActions.UPLOAD_SOURCE_PLATE({
            index: selectedPlateIndex,
            plateBarcode: plateInfo?.platesInfo?.plateBarcode,
            labwareTypeCode: labwareType?.labwareTypeCode,
            operatingVol: labwareType?.defaultLabwareVolume,
            rows: getArrayFromCount(
              labwareType!.plateLayoutType!.plateRows ?? 0
            ),
            cols: getArrayFromCount(
              labwareType!.plateLayoutType!.plateCols ?? 0
            ),
            wellInfo: tuple.newWellInfo,
          })
        );
      });
    }
  };

  const handleUploadPlateClick = (index: number) => {
    setUploadPlateDialogOpen(true);
    setSelectedPlateIndex(index);
  };

  const handleRemovePlateClick = (index: number) => {
    dispatch(
      ThawToolActions.CLEAR_SOURCE_PLATE({
        index,
      })
    );

    dispatch(ThawToolActions.CLEAR_ALL_DESTINATION_PLATES());
  };
  const ctrlDown = useCtrlDown();
  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      {sourcePlateInfo.map((item: SourcePlateInfoState, index: number) => (
        <div style={{ padding: "20px" }}>
          <React.Fragment>
            <TextField
              variant="outlined"
              style={{ width: "100%", marginBottom: "10px" }}
              disabled
              label="Source Plate Barcode"
              value={item.plateBarcode}
              InputProps={{
                startAdornment: <b>{index + 1}| </b>,
              }}
            />
            <React.Fragment>
              <StyledSelectableGroup
                onSelectionFinish={handleSourceSelection}
                resetOnStart={
                  ctrlDown.worklistToolInternalState.setCtrlDown ? false : true
                }
                allowCtrlClick={true}
              >
                <SourcePlate
                  worklistTool={WorklistTools.Thaw}
                  index={index}
                  wellInfo={item.wellInfo}
                  plateRows={item.rows}
                  plateCols={item.cols}
                />
              </StyledSelectableGroup>
              <StyledPlateActions>
                <Button
                  variant="outlined"
                  onClick={() => handleUploadPlateClick(index)}
                  disabled={thawToolState.methodSettings!.selectedSystem === 0}
                >
                  Upload Plate
                </Button>
                <Button
                  variant="outlined"
                  disabled={item.rows.length < 1}
                  onClick={() => handleRemovePlateClick(index)}
                >
                  Remove Plate
                </Button>
              </StyledPlateActions>
              {item.rows.length > 0 && item.cols.length > 0 ? (
                <StyledPlateTypeContainer>
                  <PlateType
                    platePosition={PlatePosition.Source}
                    isDisabled={false}
                    plateType={item.labwareTypeCode}
                    acceptedPlateTypes={sourcePlateTypeInfo.sourcePlateInfo}
                  />
                </StyledPlateTypeContainer>
              ) : null}
            </React.Fragment>
          </React.Fragment>
        </div>
      ))}

      <UploadSourcePlateDialog
        isOpen={uploadPlateDialogOpen}
        handleClose={() => setUploadPlateDialogOpen(false)}
        handleUpload={handleUploadSourcePlateClose}
        queryVariables={
          sourcePlateTypeInfo.sourcePlateInfo
            ? {
                where: {
                  labwareTypeId: {
                    in: sourcePlateTypeInfo.sourcePlateInfo.map((e: any) => {
                      return e.labwareTypeId;
                    }),
                  },
                },
              }
            : {}
        }
        title="Upload Plate"
      ></UploadSourcePlateDialog>

      <SetLabwareType
        isOpen={labwareTypeDialogOpen}
        handleClose={(plateId, labwareType, saveChanges) =>
          handleSetLabwareTypeClose(plateId, labwareType, saveChanges)
        }
        plateId={plateInfo?.platesInfo?.plateCreatedId ?? 0}
        plateBarcode={plateInfo?.platesInfo?.plateBarcode ?? ""}
        plateLayoutTypeId={
          plateInfo?.platesInfo?.arrayPlateType?.plateLayoutTypeId ?? 0
        }
      ></SetLabwareType>
    </div>
  );
};
