import { gql } from "@apollo/client";

export const DashboardTask = gql`
  fragment DashboardTask on DashboardTask {
    taskOwnerId
    taskOwnerName
    taskId
    taskName
    taskType
    startDate
    startTime
    endTime
    priority
    status
    assignedUsers
    assignedGroups
    systemId
    folderId
    notes
    methodId
    startTimeScheduled
    endTimeScheduled
    startTimeActual
    endTimeActual
    project
    sampleType
  }
`;

export const PersonalTask = gql`
  fragment ManagePersonalTask on ManagePersonalTask {
    taskOwnerId
    taskId
    taskName
    startTimeScheduled
    endTimeScheduled
    priority
    status
    assignedUsers
    assignedGroups
    systemAssignedTo
    notes
  }
`;
