import { Button, TextField } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { UpdateManualTaskInput } from "graphql/generated/schema-types";
import React from "react";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { UpdateManualTaskValidationSchema } from "../../validation-schema";

export function EditManualTaskForm({
  initialValues,
  onSubmit,
}: {
  initialValues: UpdateManualTaskInput;
  onSubmit: (
    values: UpdateManualTaskInput,
    helpers: FormikHelpers<UpdateManualTaskInput>
  ) => void;
}): JSX.Element {
  const props = useFormik<UpdateManualTaskInput>({
    initialValues,
    onSubmit,
    validationSchema: UpdateManualTaskValidationSchema,
  });
  const { handleSubmit, isSubmitting, values, errors, touched, setFieldValue } =
    props;
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <TextField
          id={"methodName"}
          fullWidth
          value={values.name}
          label={"Method Name"}
          onChange={(e) => {
            setFieldValue("name", e.target.value);
          }}
          variant={"outlined"}
          helperText={errors.name && touched.name && errors.name}
          error={!!(errors.name && touched.name && errors.name)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          id={"documentationUrl"}
          value={values.documentationUrl}
          label={"Documentation Link"}
          fullWidth
          onChange={(e) => {
            setFieldValue("documentationUrl", e.target.value);
          }}
          variant={"outlined"}
          helperText={
            errors.documentationUrl &&
            touched.documentationUrl &&
            errors.documentationUrl
          }
          error={
            !!(
              errors.documentationUrl &&
              touched.documentationUrl &&
              errors.documentationUrl
            )
          }
        />
      </StyledFormControl>
      <Button variant={"contained"} type={"submit"} disabled={isSubmitting}>
        Save Changes
      </Button>
    </StyledForm>
  );
}
