export enum RunsModalActions {
  SELECT,
  RESET_SELECTION,
  CONFIRMED_CHANGES,
  SUBMITTED_EDIT_FORM,
  CLOSE_MODAL,
  OPEN_MODAL,
  OPEN_TASK_SUMMARY_DIALOG,
  CLOSE_DIALOG,
  OPEN_DELETE_DIALOG,
}
