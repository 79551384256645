import {
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledTable,
  StyledTHDiv,
} from "features/Runs/components/Forms/Confirm/EditRunsConfirmModal";
import {
  GroupsCell,
  SystemCell,
  UsersCell,
} from "features/Runs/shared/TableCells";
import { RunTaskInput } from "graphql/generated/schema-types";
import moment from "moment";
import React from "react";

export function DefaultPreviewTable({
  runActivityInputs,
}: {
  runActivityInputs: RunTaskInput[];
}): JSX.Element {
  return (
    <TableContainer component={Paper} style={{ maxHeight: "70vh" }}>
      <StyledTable size="medium">
        <TableHead>
          <TableRow>
            <TableCell>
              <StyledTHDiv>Name</StyledTHDiv>
            </TableCell>
            <TableCell>
              <StyledTHDiv>StartTimeScheduled</StyledTHDiv>
            </TableCell>
            <TableCell>
              <StyledTHDiv>Users</StyledTHDiv>
            </TableCell>
            <TableCell>
              <StyledTHDiv>Groups</StyledTHDiv>
            </TableCell>
            <TableCell>
              <StyledTHDiv>System</StyledTHDiv>
            </TableCell>
            <TableCell>
              <StyledTHDiv>Status</StyledTHDiv>
            </TableCell>
            <TableCell>
              <StyledTHDiv>Priority</StyledTHDiv>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          {runActivityInputs.map((task, idx) => (
            <TableRow key={idx}>
              <TableCell>{task?.activityName}</TableCell>
              <TableCell>
                {moment(task?.startTimeScheduled).format("MMM D, YYYY")}
              </TableCell>
              <UsersCell
                userIds={(task?.runTaskAssignedUsers ?? []) as number[]}
              />
              <GroupsCell
                groupIds={(task?.runTaskAssignedGroups ?? []) as number[]}
              />
              <SystemCell systemId={task?.systemAssignedTo} />
              <TableCell data-testid={"status"}>{task?.status}</TableCell>
              <TableCell data-testid={"priority"}>{task?.priority}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
}
