import { makeVar } from "@apollo/client";
import { RunTemplateModals } from "features/RunTemplate/components/Modal/modal.enum";
import { SlideOverForms } from "features/RunTemplate/components/SlideOver/slideover.enum";
import { InlineEdit } from "features/RunTemplate/state/StateProvider";
import { RunTemplateTreeNode } from "../components/Timeline/ChildTemplateCard";

export interface IRunTemplateState {
  selectedTemplateId?: number;
  selectedStageId?: number;
  selectedChildTemplateNodeId?: number;
  selectedTaskId?: number;
  dialogOpen?: boolean;
  slideOverOpen?: boolean;
  currentSlideOver?: SlideOverForms;
  selectedTimelineNodeType?:
    | "stage"
    | "task"
    | "subtask"
    | "template"
    | "child-template";
  inlineEditOpen?: boolean;
  currentInlineEdit?: InlineEdit;
  currentModal?: RunTemplateModals;
  modalOpen?: boolean;
  templateSubTree?: RunTemplateTreeNode[];
  cloneTemplate?: boolean;
}
export const RunTemplateState = makeVar<IRunTemplateState>({
  slideOverOpen: false,
  modalOpen: false,
  cloneTemplate: false,
});
