import { makeVar } from "@apollo/client";
import { DefaultTicketTableContextMenu } from "features/Tickets/components/ContextMenu";
import { TicketColumns } from "features/Tickets/components/Table/Columns";
import { defaultStatusFilters } from "features/Tickets/components/Table/defaults";
import { TicketFilterReducer } from "features/Tickets/components/Table/filter";
import { useTicketQueryProvider } from "features/Tickets/Context";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import { CollectionTask, TicketFragment } from "graphql/generated/schema-types";
import produce from "immer";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TableState,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import {
  GetPageInfoFromConnectionType,
  GetTotalCountFromConnectionType,
} from "selectors/graphql-connection";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetPageChangeHandler } from "shared-components/Table/Handlers/HandlePageChange";
import { GetHandlePageCountChange } from "shared-components/Table/Handlers/HandlePageCountChange";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import {
  SelectedPreset,
  useCommonTableSideEffects,
  useGetTablePreset,
  useInitializeConnectionData,
} from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { TableName } from "enums/TableName";
import { TablePreset } from "interfaces/AppUserSettings";
import { GetHandlePresetChange } from "shared-components/Table/Handlers/HandlePresetChange";
import { Preset } from "shared-components/Table/sub-components/SavePresetDialog";
import { TicketPageHeader } from "../Header";
import { handleContextMenu, useHandleRowClick } from "./handlers";

//Workaround to prevent dialog from opening when the context menu loses focus
export const TicketTableContextMenuHidden = makeVar(true);

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedTicketTableRows = () =>
  toggleAllRowsSelectedStore()(false);

const DefaultState: Partial<TableState<TicketFragment>> = {
  filters: [
    {
      id: "status",
      value: defaultStatusFilters,
    },
  ],
  sortBy: [{ id: "dateCreated", desc: true }],
};

export function TicketTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const { ticketDispatch } = useTicketState();
  const tableSettings = useGetTablePreset<TicketFragment>(
    TableName.Tickets,
    DefaultState
  );

  const defaultPreset = useMemo(
    () =>
      tableSettings?.presets?.find((p) => p?.default) as
        | TablePreset
        | undefined,
    [tableSettings?.presets]
  );

  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: defaultPreset?.pageSize ?? 10,
      page: 0,
    });

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { refetch, loading, fetchMore, variables, data } =
    useTicketQueryProvider()!;
  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData = useInitializeConnectionData<TicketFragment>(
    data?.tickets,
    paginationState
  );

  const totalCount = useMemo(
    () => GetTotalCountFromConnectionType<CollectionTask>(data?.tickets),
    [data?.tickets]
  );

  const pageInfo = useMemo(
    () => GetPageInfoFromConnectionType<TicketFragment>(data?.tickets),
    [data?.tickets]
  );

  const paginationTuple = useMemo(
    () =>
      [paginationState, setPaginationState] as [
        ReactTablePaginationState,
        Dispatch<SetStateAction<ReactTablePaginationState>>
      ],
    [paginationState]
  );
  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const sortTable = useMemo(
    () => GetSortHandler(variables, refetch, paginationTuple),
    [paginationTuple, refetch, variables]
  );

  const onPageChange = useMemo(
    () =>
      GetPageChangeHandler(
        [paginationState, setPaginationState],
        fetchMore,
        pageInfo?.endCursor,
        variables
      ),
    [fetchMore, pageInfo?.endCursor, paginationState, variables]
  );

  const handlePageCountChange = useMemo(
    () =>
      GetHandlePageCountChange(variables, refetch, [
        paginationState,
        setPaginationState,
      ]),
    [paginationState, refetch, variables]
  );

  const filterTable = useMemo(
    () =>
      GetFilterHandler(
        variables,
        refetch,
        TicketFilterReducer,
        paginationTuple
      ),
    [paginationTuple, refetch, variables]
  );

  const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const skipPageResetRef = React.useRef(false);

  const _pageData = useMemo(() => {
    skipPageResetRef.current = true;
    return pageData.map((entry) =>
      produce(entry, (draft) => {
        draft.dateCreated = moment(draft?.dateCreated)
          .startOf("day")
          .toISOString();
      })
    );
  }, [pageData]);

  const tableInstance = useTable(
    {
      columns: TicketColumns,
      data: _pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState:
        (defaultPreset?.state as TableState<TicketFragment>) ?? DefaultState,
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  useEffect(() => {
    skipPageResetRef.current = false;
  });

  const {
    state: { groupBy },
    setAllFilters,
    setSortBy,
    toggleAllRowsSelected,
    selectedFlatRows,
  } = tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//
  const handlePresetChange = GetHandlePresetChange(
    tableSettings,
    setAllFilters,
    setSortBy,
    handlePageCountChange,
    paginationTuple
  );
  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  useEffect(() => {
    ticketDispatch({
      type: TicketActions.SELECT,
      payload: {
        selectedTickets: selectedFlatRows
          .filter((row) => row.original?.ticketId)
          .map((row) => row.original.ticketId),
        selectedSlackMessageTimestamp: selectedFlatRows
          .filter((row) => row.original?.ticketId)
          .map((row) => row.original.slackMessageTimestamp),
      },
    });
  }, [selectedFlatRows, ticketDispatch]);

  //clear preset on unmount
  useEffect(
    () => () => {
      SelectedPreset(null);
    },
    []
  );

  useCommonTableSideEffects(
    paginationTuple,
    totalCount,
    variables,
    refetch,
    groupBy
  );

  //--------------------------------------------------------------- Markup ------------------------------------------------------------//
  return (
    <div>
      <TicketPageHeader>
        <Preset
          tableSettings={tableSettings}
          handlePresetChange={handlePresetChange}
        />
      </TicketPageHeader>
      <ReactTable
        loading={loading}
        tableInstance={tableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onPageChange={onPageChange}
        onPageCountChange={handlePageCountChange}
        onRowContextMenu={handleContextMenu}
        onRowClick={handleRowClick}
        name={TableName.Tickets}
      />
      <DefaultTicketTableContextMenu />
    </div>
  );
}
