import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import { useGetUserSettings } from "hooks/useGetUserSettings";
import { useGetSaveCalendarSettings } from "hooks/useSaveUserSettings";
import {
  StyledCard,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components/macro";
import { CalendarSettings } from "../Settings";
import { SystemCheckboxes } from "../SystemCheckboxes";
import { TaskTitleToggle } from "../TaskTitleToggle";
import { TimeFormatToggle } from "../TimeFormatToggle";

const useStyles = makeStyles({
  container: {
    justifyContent: "center",
  },
});
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export function CalendarSettingsScreens() {
  const userSettings = useGetUserSettings();
  const [saveCalendarSettings, loading] = useGetSaveCalendarSettings();
  const classes = useStyles();
  const initialCalSettingsValues: CalendarSettings =
    userSettings?.calendar ?? {};
  const handleSubmit = async (
    values: CalendarSettings,
    { setSubmitting }: FormikHelpers<CalendarSettings>
  ) => {
    await saveCalendarSettings(values).then(() => {
      Alert({
        type: AlertType.SUCCESS,
        message: "Updated Calendar Settings",
      });
    });
    setSubmitting(false);
  };
  const formik = useFormik({
    initialValues: initialCalSettingsValues,
    onSubmit: handleSubmit,
  });
  const formValues = formik.values;
  const isSubmitting = formik.isSubmitting;
  const setFieldValue = formik.setFieldValue;
  return (
    <Container>
      <StyledCard width={"625px"} height={"625px"}>
        <FormikProvider value={formik}>
          <StyledForm onSubmit={formik.handleSubmit}>
            <StyledFormControl>
              <TimeFormatToggle
                classes={classes}
                formValues={formValues}
                setFieldValue={setFieldValue}
              />
              <TaskTitleToggle
                classes={classes}
                formValues={formValues}
                setFieldValue={setFieldValue}
              />
            </StyledFormControl>
            <Typography variant="h6">Systems shown on Calendar:</Typography>
            <SystemCheckboxes
              values={formValues}
              setFieldValue={setFieldValue}
            />
            <StyledFormControl>
              <Button
                type="submit"
                variant={"contained"}
                color={"primary"}
                disabled={loading || isSubmitting}
              >
                Save Changes
              </Button>
            </StyledFormControl>
          </StyledForm>
        </FormikProvider>
      </StyledCard>
    </Container>
  );
}
