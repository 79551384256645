import {
  Button,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useIncomingSampleState } from "features/IncomingSamples/state";
import {
  useAddCollectionSampleTypeMutation,
  useDeleteCollectionSampleTypeMutation,
} from "graphql/generated/schema-types";
import { useGetIncomingSampleCollection } from "hooks/cacheAccessHooks";
import { Item, Menu } from "react-contexify";
import { StyledCard } from "shared-components/styled-components";
import styled from "styled-components/macro";
import { SampleRow } from "./SampleRow";

const StyledCardActions = styled.div`
  display: flex;
  padding: 10px 31px;
`;

export function CollectionSamples(): JSX.Element {
  const {
    incomingSampleState: { collectionSamplesModalState },
  } = useIncomingSampleState();
  const collection = useGetIncomingSampleCollection(
    collectionSamplesModalState?.collectionId
  );

  const [addSampleType] = useAddCollectionSampleTypeMutation();
  const [deleteSampleType] = useDeleteCollectionSampleTypeMutation();
  return (
    <StyledCard>
      <CardContent>
        <TableContainer component={"div"} style={{ maxHeight: "40vh" }}>
          <Table style={{ width: "50vw" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width={"30%"} align={"center"}>
                  Sample Type
                </TableCell>
                <TableCell width={"25%"} align={"center"}>
                  Date To Process
                </TableCell>
                <TableCell width={"25%"} align={"center"}>
                  Notes
                </TableCell>
                <TableCell width={"10%"} align={"center"}>
                  Active
                </TableCell>
                <TableCell width={"10%"} align={"center"} />
              </TableRow>
            </TableHead>
            <TableBody>
              {collection?.collectionSampleTypes?.map((sample) => (
                <SampleRow key={sample!.sampleTypeId} sample={sample!} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <Menu id={"incoming-sample-type-context-menu"} style={{ zIndex: 3000 }}>
        <Item
          onClick={async ({ props }) => {
            const id = (props as { collectionSampleTypeId: number })
              ?.collectionSampleTypeId;
            if (id)
              await deleteSampleType({
                variables: {
                  input: {
                    primaryKey: id,
                  },
                },
              });
          }}
        >
          Delete
        </Item>
      </Menu>
      <StyledCardActions>
        <Button
          variant={"contained"}
          onClick={async () => {
            if (collection?.collectionId)
              await addSampleType({
                variables: {
                  input: {
                    collectionId: collection.collectionId,
                    sampleTypeId: 19, //peripheral blood
                  },
                },
              });
          }}
        >
          Add Sample Type
        </Button>
      </StyledCardActions>
    </StyledCard>
  );
}
