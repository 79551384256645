import {
  LookupExperimentSetting,
  PlatesCreated,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export const getSamples: (
  plate: null | undefined | PlatesCreated
) => (string | null | undefined)[] | undefined = (
  plate: Maybe<PlatesCreated>
) => plate?.containers?.map((container) => container?.sample?.sampleBarcode);

export const GetExperimentSettingsOptions = (
  experimentSettings: Maybe<LookupExperimentSetting>[] | undefined,
  plate: Maybe<PlatesCreated> | undefined
): Maybe<LookupExperimentSetting>[] =>
  experimentSettings?.filter(
    (x) =>
      x?.plateLayoutTypeId ===
      (plate?.labwareType?.plateLayoutTypeId ??
        plate?.arrayPlateType?.defaultLabwareType?.plateLayoutTypeId)
  ) ?? [];
