import { Button, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { INavItem } from "features/Navbar/nav-items";
import { NavItemPopup } from "features/Navbar/NavItemPopup";
import React from "react";
import { StyledLink } from "shared-components/styled-components";
import styled from "styled-components/macro";

export const StyledNavButton = styled(Button)`
  color: white !important;
  margin: 0 10px !important;
  background-color: transparent !important;
`;
function NestedNavItem({ item, idx }: { item: INavItem; idx: number }) {
  return (
    <NavItemPopup
      id={`nav-item-${idx}`}
      Button={(props: unknown) => (
        <StyledNavButton
          {...props}
          variant={"contained"}
          disableElevation
          endIcon={<KeyboardArrowDown />}
        >
          {item.displayName}
        </StyledNavButton>
      )}
    >
      {item!.subItems!.map((subItem, idx) => (
        <StyledLink
          key={idx}
          to={{ pathname: subItem.path ?? "" }}
          target={subItem?.target ?? ""}
        >
          <MenuItem>{subItem.displayName}</MenuItem>
        </StyledLink>
      ))}
    </NavItemPopup>
  );
}
export function NavItem({
  item,
  idx,
}: {
  item: INavItem;
  idx: number;
}): JSX.Element {
  return item?.subItems && item.subItems.length > 0 ? (
    <NestedNavItem item={item} idx={idx} />
  ) : (
    <StyledLink to={{ pathname: item.path ?? "" }} target={item?.target ?? ""}>
      <StyledNavButton variant={"contained"} disableElevation>
        {item.displayName}
      </StyledNavButton>
    </StyledLink>
  );
}
