import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { ScheduleIncomingSampleFormReducer } from "./features/IncomingSamples/components/Forms/ScheduleSamples/state";
import JoinedReducer from "./reducers/joined.reducer";

export const ReduxStore = configureStore({
  reducer: {
    WorklistTools: JoinedReducer,
    ScheduleIncomingSampleForm: ScheduleIncomingSampleFormReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
export type AppReduxDispatch = typeof ReduxStore.dispatch;
export type AppReduxState = ReturnType<typeof ReduxStore.getState>;
