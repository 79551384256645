import { Button, FormControlLabel, Switch, Typography } from "@mui/material";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  RunTask,
  RunTaskInput,
  TaskStatusEnum,
  useGetRunAndTasksForRescheduleLazyQuery,
  useGetRunTaskMetaDataQuery,
  useUpdateRunTasksMutation,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import { useGetRunActivitiesById } from "hooks/cacheAccessHooks";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import { useEffect, useMemo, useState } from "react";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { PreviewCancelledTasks } from "./PreviewCancelledTasks";

export function CancelTasksForm(): JSX.Element {
  const {
    runsModalState: { selectedTasks },
  } = useRunsModalState();
  const [cancelSubsequent, setCancelSubsequent] = useState(false);

  const cachedRunTasks = useGetRunActivitiesById(selectedTasks ?? []);
  const [runTask, setRunTask] = useState<any>(
    cachedRunTasks && cachedRunTasks.length ? cachedRunTasks[0] : null
  );

  useGetRunTaskMetaDataQuery({
    variables: {
      taskIds: selectedTasks ?? [],
    },
    onCompleted: (data) => {
      if (runTask || !data || !data!.runTaskMetadata) return;
      setRunTask(data.runTaskMetadata[0]);
    },
  });

  const runId = useMemo(() => runTask?.runId ?? 0, [runTask]);
  const startTimeScheduled = useMemo(
    () => runTask?.startTimeScheduled ?? new Date(),
    [runTask]
  );

  const [execute, { data, called, loading }] =
    useGetRunAndTasksForRescheduleLazyQuery({
      variables: {
        baseDateTime: startTimeScheduled,
        runId: runId,
      },
      fetchPolicy: "cache-and-network",
    });

  useEffect(() => {
    if (!called && !data) execute();
  }, [called, data, execute]);

  const _inputs: RunTaskInput[] = useMemo(() => {
    if (loading || !data?.runs) return [];

    const mapped: RunTaskInput[] =
      data?.runs?.[0]?.runTasks?.map((task) =>
        RunActivityInputMapper(task as Maybe<RunTask>)
      ) ?? [];
    if (cancelSubsequent) return mapped;

    return [mapped.find((task) => task.runActivityId === selectedTasks![0])!];
  }, [loading, data?.runs, cancelSubsequent, selectedTasks]);

  const [updateRunTasks] = useUpdateRunTasksMutation();
  const { runsModalDispatch } = useRunsModalState();

  const handleCancelTasks = () => {
    const updatedInputs = _inputs.map((input) => {
      const updatedRunTaskInput = DeepCopy(input);
      updatedRunTaskInput.status = TaskStatusEnum.Cancelled;
      return updatedRunTaskInput;
    });

    return updateRunTasks({
      variables: {
        updateInputs: updatedInputs,
      },
    }).then(() => {
      runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
      Alert({ type: AlertType.SUCCESS, message: "Task(s) Cancelled" });
    });
  };

  return (
    <StyledCard>
      <FormControlLabel
        label={"Cancel Subsequent Tasks"}
        labelPlacement={"start"}
        control={
          <Switch
            checked={cancelSubsequent}
            onChange={(e) => setCancelSubsequent(e.target.checked)}
          />
        }
      />
      <Button
        onClick={handleCancelTasks}
        color={"secondary"}
        variant={"contained"}
        style={{ marginLeft: "30px" }}
      >
        Cancel Task(s)
      </Button>
      <Typography variant={"h6"} m={3}>
        {data?.runs?.[0]?.runName}
      </Typography>
      <PreviewCancelledTasks tasks={_inputs} />
    </StyledCard>
  );
}
