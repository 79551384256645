import { ImageExportFormat } from "graphql/generated/schema-types";

export const FreezeDownRackTypes = ["REPR", "REPO", "HTC", "TRGT", "SPLT"];
export const OverflowTypes = [
  "OVERFLOW",
  "NO_OVERFLOW",
  "OVERFLOW_WELLS",
  "NO_OVERFLOW_WELLS",
];
const plates: string[] = [];
[...Array(6).keys()].map((parentIndex) => {
  [...Array(6).keys()].map((idx) => {
    plates.push(`${parentIndex + 1}T${idx + 1}`);
    return idx;
  });
  return parentIndex;
});
export const DestinationPlates: string[] = plates;
export const ImageExportFormats = [
  { name: "JPG", value: ImageExportFormat.Jpg },
  { name: "TIFF", value: ImageExportFormat.Tiff },
  { name: "BMP", value: ImageExportFormat.Bmp },
];
