import { Maybe } from "graphql/jsutils/Maybe";
import { Dispatch, SetStateAction } from "react";
import { Filters, SortingRule } from "react-table";
import { TableSetting } from "interfaces/AppUserSettings";
import { ReactTablePaginationState } from "../ReactTable";

type setAllFiltersType<D extends Record<string, unknown>> = (
  updater: Filters<D> | ((filters: Filters<D>) => Filters<D>)
) => void;

type setSortByType<D extends Record<string, unknown>> = (
  sortBy: SortingRule<D>[]
) => void;

export const GetHandlePresetChange =
  <T extends Record<string, unknown>>(
    tableSettings: Maybe<TableSetting>,
    setAllFilters: setAllFiltersType<T>,
    setSortBy: setSortByType<T>,
    handlePageCountChange: (newPageCount: number) => void,
    [paginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ) =>
  (preset: string) => {
    const tablePreset: Maybe<TableSetting["presets"][number]> =
      tableSettings?.presets.find((p) => p?.name === preset);
    setTimeout(() => {
      setAllFilters(tablePreset?.state?.filters ?? []);
      setSortBy(tablePreset?.state?.sortBy ?? []);
      if (tablePreset?.pageSize ?? 10 !== paginationState.rowsPerPage)
        handlePageCountChange(tablePreset?.pageSize ?? 10);
    }, 300);
  };
