import { gql } from "@apollo/client/core";

export const ManualTaskType = gql`
  fragment ManualTaskType on ManualTaskType {
    id
    name
    documentationUrl
  }
`;
export const Method = gql`
  fragment Method on LookupArrayMethod {
    methodId
    methodName
    documentationUrl
    isActive
    methodType {
      methodType
      methodTypeId
      runSpecific
      inactivatesSourcePlates
      excludeSourcePlatesReservedForInactivation
    }
  }
`;
