import React from "react";
import { StyledCard } from "shared-components/styled-components";
import { useModalStateProvider } from "../../state/StateProvider";
import { ContainerLineageTable } from "./Table";

export function ContainerLineageModal(): JSX.Element {
  const {
    modalState: { selectedContainer },
  } = useModalStateProvider();
  return (
    <StyledCard>
      <ContainerLineageTable containerId={selectedContainer ?? 0} />
    </StyledCard>
  );
}
