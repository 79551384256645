import React, { FC } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";

const AttachmentsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
export const AttachmentAreaText = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSize * 1.5}px;
  font-weight: bold;
  &&:hover {
    cursor: pointer;
  }
`;
export const UploadFilesArea: FC<{ onDrop: (files: File[]) => void }> = ({
  children,
  onDrop,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
  });
  return (
    <>
      <AttachmentsArea {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <AttachmentAreaText>Drop attachments here</AttachmentAreaText>
        ) : (
          children
        )}
      </AttachmentsArea>
    </>
  );
};
