import { useReactiveVar } from "@apollo/client";
import {
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SourceWellInfo } from "features/WorklistTools/shared/interfaces";
import {
  getColIndexFromSelectedWell,
  getRowIndexFromSelectedWell,
} from "features/WorklistTools/shared/WorklistHelpers";
import { useAppReduxSelector } from "hooks/reduxHooks";
import React, { useMemo, useState } from "react";
import DataGrid from "react-data-grid";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styled from "styled-components";
import { PoolingNormalizationToolInternalState } from "../../state/initial-state";

const StyledPooledWellSamples = styled(DataGrid)`
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "#0D0D0D" : ""};
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
`;

export const PooledWellSamples = () => {
  const [barcodeInfo, setBarcodeInfo] = useState<any[]>([]);
  const dispatch = useDispatch();
  const poolingInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );
  const poolingState = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present
  );
  const getWellSamples = () => {
    const destPlateIndex = poolingInternalState.hoverWellId.substring(12, 13);
    const destWellId = poolingInternalState.hoverWellId.substring(
      poolingInternalState.hoverWellId.length - 2
    );
    if (parseInt(destPlateIndex) === 0) {
      getInt1Samples(destPlateIndex, [destWellId]);
    } else if (parseInt(destPlateIndex) === 1) {
      getInt2Samples([destWellId], []);
    } else if (parseInt(destPlateIndex) === 2) {
      getInt3Samples(destPlateIndex, destWellId);
    }
  };

  const getInt1Samples = (destPlateIndex: string, destWellId: string[]) => {
    const worklistRows = poolingState.worklistValues.harvestWells.filter(
      (val) => destWellId.includes(val.destWellId)
    );
    const wellInfo: SourceWellInfo[] = [];
    for (const item of worklistRows) {
      const wellInfoIndex = poolingState.sourcePlateInfo[
        item.sourcePlateIndex
      ].wellInfo.findIndex(
        (val) =>
          val.rowPos == getRowIndexFromSelectedWell(item.sourceWellId) + 1 &&
          val.colPos == getColIndexFromSelectedWell(item.sourceWellId) + 1
      );
      if (wellInfoIndex > -1) {
        wellInfo.push(
          poolingState.sourcePlateInfo[item.sourcePlateIndex].wellInfo[
            wellInfoIndex
          ]
        );
      }
      setBarcodeInfo(wellInfo);
    }
  };

  // const getInt2Samples = (destPlateIndex: string, destWellId: string) => {

  const getInt2Samples = (destWellIds: string[], sourceWellIds: string[]) => {
    const worklistRows = poolingState.worklistValues.int1ToInt2.filter((val) =>
      destWellIds.includes(val.destWellId)
    );
    // const worklistRows = poolingState.worklistValues.int1ToInt2.filter(
    //   (val) =>
    //     val.destPlateIndex === parseInt(test.destPlateIndex) &&
    //     val.destWellId === test.destWellId
    // );
    getInt1Samples("0", [
      ...worklistRows.map((item) => item.sourceWellId),
      ...sourceWellIds,
    ]);
  };

  const getInt3Samples = (destPlateIndex: string, destWellId: string) => {
    const worklistRowsConcat = [
      ...poolingState.worklistValues.int1ToInt3,
      ...poolingState.worklistValues.int2ToInt3,
    ];
    const worklistRows = worklistRowsConcat.filter(
      (val) =>
        val.destPlateIndex === parseInt(destPlateIndex) &&
        val.destWellId === destWellId
    );
    getInt2Samples(
      worklistRows
        .filter((val) => val.sourcePlateIndex == 1)
        .map((item) => item.sourceWellId),
      worklistRows
        .filter((val) => val.sourcePlateIndex == 0)
        .map((item) => item.sourceWellId)
    );
  };
  useMemo(() => {
    getWellSamples();
  }, [poolingInternalState.hoverWellId, poolingState.worklistValues]);

  return (
    <AccordionDetails>
      <Tabs style={{ marginTop: "15px" }}>
        <TabList>
          <Tab>Pie</Tab>
          <Tab>List</Tab>
        </TabList>
        <TabPanel>
          <PieChart
            data={barcodeInfo.map((item) => ({
              title: item.sampleBarcode,
              value: 1,
              color: item.color,
            }))}
          />
        </TabPanel>
        <TabPanel>
          <List dense={true}>
            {barcodeInfo?.map((item) => (
              <ListItem>
                <ListItemIcon sx={{ height: "20px" }}>
                  <div
                    style={{
                      backgroundColor: item.color,
                      border: `1px solid ${
                        localStorage.getItem("theme")?.includes("light")
                          ? "black"
                          : "#454545"
                      }`,
                      height: "100%",
                      width: "50%",
                      borderRadius: "50%",
                    }}
                  ></div>
                </ListItemIcon>
                <ListItemText>{item.sampleBarcode}</ListItemText>
              </ListItem>
            ))}
          </List>
        </TabPanel>
      </Tabs>
    </AccordionDetails>
  );
};
