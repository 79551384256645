import ax from "services/AxiosService";
import { GoogleSignInTokens } from "./LoginWithGoogle";

export const setGoogleSignInTokenInfo = (tokens: GoogleSignInTokens) => {
  localStorage.setItem("idToken", tokens.id_token);
  localStorage.setItem("accessToken", tokens.access_token);
  localStorage.setItem("expiresIn", tokens.expires_in);
  ax.defaults.headers.common = {
    Authorization: `Bearer ${tokens.id_token}`,
  };
};
