import { ApolloQueryResult } from "@apollo/client/core";
import { ConfirmDialog } from "enums/ConfirmDialog";
import {
  GetIncomingSampleCollectionsQuery,
  GetIncomingSampleCollectionsQueryVariables,
  useDeleteIncomingSampleCollectionMutation,
} from "graphql/generated/schema-types";
import { useState } from "react";
import { Item, Menu } from "react-contexify";
import { IncomingSampleActions, useIncomingSampleState } from "../../state";
import { IncomingSamplesConfirmDialog } from "../ModelsAndDialogs/ConfirmDialog";
import { IncomingSampleModals } from "../ModelsAndDialogs/modal.enum";
import { IncomingSampleCollectionContextMenuHidden } from "../Tables/Collections/handlers";

export function IncomingSampleCollectionContextMenu({
  refetch,
}: {
  refetch: (
    variables?: Partial<GetIncomingSampleCollectionsQueryVariables>
  ) => Promise<ApolloQueryResult<GetIncomingSampleCollectionsQuery>>;
}): JSX.Element {
  const {
    incomingSampleState: { selectedCollections },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  const [deleteCollection] = useDeleteIncomingSampleCollectionMutation();
  const [loading, setLoading] = useState(false);
  return (
    <>
      <IncomingSamplesConfirmDialog
        loading={loading}
        deleteMutation={() => {
          setLoading(true);
          return deleteCollection({
            variables: {
              input: {
                primaryKey: selectedCollections![0],
              },
            },
          })
            .then(() => refetch())
            .then(() => {
              setLoading(false);
              incomingSampleDispatch({
                type: IncomingSampleActions.CLOSE_DIALOG,
              });
            });
        }}
      />
      <Menu
        id={"IncomingSampleCollectionTableContextMenu"}
        onHidden={() => {
          setTimeout(
            () => IncomingSampleCollectionContextMenuHidden(true),
            200
          );
        }}
      >
        {(selectedCollections ?? []).length === 1 && (
          <Item
            onClick={() =>
              incomingSampleDispatch({
                type: IncomingSampleActions.OPEN_MODAL,
                payload: {
                  currentModal: IncomingSampleModals.UpdateCollection,
                },
              })
            }
          >
            Edit
          </Item>
        )}
        <Item
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.OPEN_CONFIRM_DIALOG,
              payload: {
                confirmDialogState: {
                  open: true,
                  dialogType: ConfirmDialog.Delete,
                  typename: "Collection",
                },
              },
            })
          }
        >
          Delete
        </Item>
      </Menu>
    </>
  );
}
