import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useGetTubeLabellerSalivaSamplesQuery } from "graphql/generated/schema-types";
import React from "react";
import { AddRackOptions } from "../../constants";

interface SalivaSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const SalivaSelector = ({
  values,
  setFieldValue,
}: SalivaSelectorProps) => {
  const { data: salivaSamples } = useGetTubeLabellerSalivaSamplesQuery();
  return (
    <FormControl style={{ width: "100%" }} variant="outlined">
      <InputLabel>Saliva Samples</InputLabel>
      <Select
        labelId="saliva-label"
        label="Saliva Samples"
        id="saliva"
        multiple
        value={values.salivaSamples}
        onChange={(e) => setFieldValue("salivaSamples", e.target.value)}
        // input={<Input />}
        renderValue={(selected) => selected.join(", ")}
      >
        {salivaSamples?.tubeLabellerSalivaSamples?.map((sample) => (
          <MenuItem value={sample?.sampleBarcode ?? ""}>
            <Checkbox
              checked={
                values.salivaSamples!.indexOf(sample!.sampleBarcode!) > -1 ??
                false
              }
            />
            <ListItemText primary={sample?.sampleBarcode ?? ""} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
