export enum RunTaskModals {
  None,
  CreateRun,
  AddRunActivity,
  EditRunActivity,
  ConfirmRunActivity,
  EditMultipleRunActivity,
  EditMultipleRunActivityDetails,
  RescheduleTasks,
  EditRun,
  CreateRunFolder,
  CancelTasks,
}
