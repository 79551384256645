import {
  BuildBreadCrumbs,
  GetTreeNodeKey,
} from "features/Runs/components/FolderTree/helpers";
import { ExpandedFolders } from "features/Runs/components/FolderTree/Tree";
import {
  DirectoryFolder,
  DirectoryFolderKeyInput,
  RunTaskFilterInput,
} from "graphql/generated/schema-types";

export const evaluateFilter = (filter: RunTaskFilterInput) => {
  return filter.and && filter.and.length > 0
    ? filter
    : { and: [{ runActivityId: { neq: -1 } }] };
};

export const OpenFolder = (
  allFolders: DirectoryFolder[],
  key: DirectoryFolderKeyInput
) => {
  const breadcrumbs: DirectoryFolder[] = [];
  BuildBreadCrumbs(key, allFolders, breadcrumbs);
  ExpandedFolders(breadcrumbs.map((crumb) => GetTreeNodeKey(crumb)));
};
