import { useReactiveVar } from "@apollo/client";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Formik, FormikProps } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  LookupArraySystem,
  Maybe,
  OperationNames,
  TaskPriorityEnum,
  useAddPersonalTaskTemplateTaskMutation,
  useGetGroupsQuery,
  useGetUsersQuery,
} from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import { SystemField } from "shared-components/FormFields/FormFields";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { PersonalTaskTemplateState } from "../../state/inital-state";

interface AddTemplateTaskProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const AddTemplateTask = ({
  isOpen,
  handleClose,
}: AddTemplateTaskProps) => {
  const usersQuery = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const groupsQuery = useGetGroupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const [addTask] = useAddPersonalTaskTemplateTaskMutation();

  const personalTaskTemplateState = useReactiveVar(PersonalTaskTemplateState);

  const handleSubmit = (values: any) => {
    values.assignedUsers = values.assignedUsers.map((user: AppSuiteUser) => {
      return user.userId;
    });

    values.assignedGroups = values.assignedGroups.map(
      (group: AppSuiteGroup) => {
        return group.groupId;
      }
    );
    values.personalTaskTemplateId =
      personalTaskTemplateState.selectedPersonalTaskTemplateId;
    console.log(values);
    addTask({
      variables: {
        input: values,
      },
      refetchQueries: [OperationNames.Query.getPersonalTaskTemplates],
    }).then((res) => {
      handleClose();
      Alert({
        type: AlertType.SUCCESS,
        message: "Added Task",
      });
    });
  };

  const initalValues = {
    personalTaskName: "",
    dayScheduled: 0,
    assignedUsers: [],
    assignedGroups: [],
    assignedSystem: 14,
    priority: TaskPriorityEnum.Low,
    notes: "",
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initalValues}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<any>) => (
          <React.Fragment>
            <StyledForm onSubmit={handleSubmit}>
              <DialogTitle>Add Task to Template</DialogTitle>
              <DialogContent>
                <div style={{ marginTop: "10px" }}>
                  <StyledFormControl>
                    <TextField
                      fullWidth
                      id={"personalTaskName"}
                      label="Task Name"
                      size="small"
                      value={values.personalTaskName}
                      onChange={handleChange}
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <TextField
                      fullWidth
                      id={"dayScheduled"}
                      label="Day Scheduled"
                      type="number"
                      size="small"
                      value={values.dayScheduled}
                      onChange={handleChange}
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <Autocomplete
                    fullWidth
                      multiple
                      id="tags-outlined"
                      options={
                        usersQuery.data?.appSuiteUsers?.map((node) => node) ??
                        []
                      }
                      getOptionLabel={(option) => option!.userName!}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Assigned Users"
                        />
                      )}
                      onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                        setFieldValue("assignedUsers", newValue)
                      }
                      value={values.assignedUsers}
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <Autocomplete
                      fullWidth
                      multiple
                      id="tags-outlined"
                      options={
                        groupsQuery.data?.appSuiteGroups?.map((node) => node) ??
                        []
                      }
                      getOptionLabel={(option) => option!.groupName!}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Assigned Groups"
                        />
                      )}
                      onChange={(event: ChangeEvent<unknown>, newValue: any) =>
                        setFieldValue("assignedGroups", newValue)
                      }
                      value={values.assignedGroups!}
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <SystemField
                      id="system-combobox"
                      onChange={(event, newValue) =>
                        setFieldValue(
                          "assignedSystem",
                          (newValue as Maybe<LookupArraySystem>)?.systemId ??
                            null
                        )
                      }
                      value={values.assignedSystem}
                      label="System"
                      variant="outlined"
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <TextField
                      fullWidth
                      id={"notes"}
                      label="Notes"
                      multiline
                      onChange={handleChange}
                      value={values.notes}
                    />
                  </StyledFormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Add</Button>
              </DialogActions>
            </StyledForm>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
};
