import React from "react";
import { PageWithNav } from "../../Navbar/PageWithNav";
import { SampleModalContainer } from "../components/ModelsAndDialogs/SampleModalContainer";
import { SampleModalContent } from "../components/ModelsAndDialogs/SampleModalContent";
import { SampleTable } from "../components/SampleTable/SampleTable";

export function Samples(): JSX.Element {
  return (
    <PageWithNav>
      <SampleModalContainer>
        <SampleModalContent />
      </SampleModalContainer>
      <SampleTable />
    </PageWithNav>
  );
}
