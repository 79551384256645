import { ReactTablePaginationState } from "../ReactTable";

export function ApplyPagination<T>(
  data: T[] | undefined | null,
  { page, rowsPerPage }: ReactTablePaginationState
): T[] {
  if (!data) return [];
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage - 1;
  return data.filter((x, idx) => idx >= startIndex && idx <= endIndex);
}
