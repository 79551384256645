import { gql } from "@apollo/client/core";
import { TypeNames } from "enums/TypeNames";
import {
ReschedulingTask,
Run,
RunTask,
RunTaskAttachment,
RunTaskMetaData
} from "../fragments/run.fragments";

export const RunQueries = {
  remote: {
    GET_CREATE_RUN_DEFAULTS: gql`
      query getCreateRunDefaults($runTemplateId: Int!) {
        createRunDefaults(runTemplateId: $runTemplateId) {
          runFolder
          runName
        }
      }
    `,
    GET_ACTIVE_RUNS: gql`
      query GetActiveRuns {
        runs(
          where: { status: { in: [IN_PROGRESS, SCHEDULED] } }
          order: [{ runName: ASC }]
        ) {
          ...Run
        }
      }
      ${Run}
    `,
    GET_RUNS: gql`
      query getRuns($where: RunFilterInput) {
        runs(where: $where) {
          ...Run
        }
      }
      ${Run}
    `,
    GET_RUN_TASKS: gql`
      query getRunTasks(
        $key: DirectoryFolderKeyInput
        $pageSize: Int
        $after: String
        $order: [RunTaskSortInput!]
        $where: RunTaskFilterInput
      ) {
        runTasks(
          key: $key
          first: $pageSize
          after: $after
          order: $order
          where: $where
        ) {
          edges {
            node {
              ...RunTask
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
            hasPreviousPage
          }
          totalCount
        }
      }
      ${RunTask}
    `,
    GET_RUN_TASK_ATTACHMENTS: gql`
      query getRunTaskAttachments($taskId: Int!) {
        runTaskAttachments(taskId: $taskId) {
          ...RunTaskAttachment
        }
      }
      ${RunTaskAttachment}
    `,
    GET_RUN_TASK_METADATA: gql`
      query getRunTaskMetaData($taskIds: [Int!]) {
        runTaskMetadata(runTaskIds: $taskIds) {
          ...RunTask
          ...RunTaskMetaData
        }
      }
      ${RunTaskMetaData}
    `,
    GET_RUN_TYPES: gql`
      query getRunTypes {
        runTypes {
          productionRunTypeId
          typeName
        }
      }
    `,
    GET_GANTT_IFRAME_URL: gql`
      query getGanttIFrameUrl($runId: Int!) {
        ganttIFrameUrl(runId: $runId)
      }
    `,
    GET_RUN_AND_TASKS_FOR_RESCHEDULE: gql`
      query getRunAndTasksForReschedule(
        $runId: Int!
        $baseDateTime: DateTime!
      ) {
        runs(where: { runId: { eq: $runId } }) {
          runId
          runName
          runTasks(
            where: {
              and: [
                { status: { in: [SCHEDULED, IN_PROGRESS, OVERDUE] } }
                { startTimeScheduled: { gte: $baseDateTime } }
              ]
            }
            order: [{ startTimeScheduled: ASC }]
          ) {
            ...ReschedulingTask
          }
        }
      }
      ${ReschedulingTask}
    `,
    GET_ARRAY_SYSTEM_AUTOMATION_METHODS: gql`
      query getArraySystemAutomationMethods {
        arraySystemAutomationMethods {
          arraySystemAutomationMethodId
          automationMethodCode
          arraySystemId
          automationMethodId
          isActive
          averageRunTimeSeconds
          arraySystem {
            systemId
            systemName
          }
        }
      }
    `,
  },
  local: {
    GET_RUN_BY_ID: gql`
      query getRunById($runId: Int!) {
        run(id: $runId, typeName: "${TypeNames.Run}", keyField: "runId") @client {
          ...Run
        }
      }
      ${Run}
    `,
    GET_RUN_TASK_BY_ID: gql`
      query getRunTaskById($runActivityId: Int!) {
        runTask(id: $runActivityId, typeName: "${TypeNames.RunTask}", keyField: "runTaskId") @client {
          ...RunTask
          ...RunTaskMetaData
        }
      }
      ${RunTask}
      ${RunTaskMetaData}
    `,
    GET_RUN_ACTIVITIES_BY_IDs: gql`
      query getRunActivitiesByIds($runActivityIds: [Int!]!) {
        runTasksFromCache(ids: $runActivityIds, typeName: "${TypeNames.RunTask}", keyField: "runTaskId") @client {
          ...RunTask
          ...RunTaskMetaData
        }
      }
      ${RunTask}
      ${RunTaskMetaData}
    `,
    GET_RUN_TYPE_BY_ID: gql`
      query GetRunTypeById($productionRunTypeId: Int!) {
        productionRunType(id: $productionRunTypeId, typeName: "${TypeNames.ProductionRunType}", keyField: "productionRunTypeId")  @client{
          productionRunTypeId
          typeName
        }
      }
    `,
  },
};
