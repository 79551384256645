import { makeVar } from "@apollo/client";
import { Autocomplete, Button, CardHeader, TextField } from "@mui/material";
import {
  LabComputer,
  useGetLabComputersQuery,
  useGetMeQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { useState } from "react";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";

const GetLabComputerId = () => {
  const value = parseInt(localStorage?.labComputerId ?? "0");
  if (!value) return undefined;
  else return value;
};
const localLabComputerId = GetLabComputerId();
export const ComputerId = makeVar(localLabComputerId);

export function SelectComputerForm(): JSX.Element {
  const { data: computersQuery } = useGetLabComputersQuery();
  const [id, setId] = useState(ComputerId());
  const { refetch } = useGetMeQuery();

  return (
    <StyledCard width="300px">
      <CardHeader title="Select Computer" />
      <Autocomplete
        id="computer-combobox"
        defaultValue={
          computersQuery?.labComputers?.find((c) => c?.id === id) ?? null
        }
        options={(computersQuery?.labComputers as Maybe<LabComputer>[]) ?? []}
        onChange={(e, value) => {
          //set dropdown state
          setId(value?.id);
        }}
        getOptionLabel={(option) => option?.name ?? ""}
        style={{ margin: "20px 0", width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for Computer"
            variant="outlined"
          />
        )}
      />
      <Button
        onClick={() => {
          if (!id)
            return Alert({
              type: AlertType.ERROR,
              message: "Please Select Computer",
            });
          //set local storage
          localStorage.setItem("labComputerId", id?.toString() ?? "0");
          //set global state
          ComputerId(id);
          refetch();
        }}
        variant={"contained"}
      >
        Save
      </Button>
    </StyledCard>
  );
}
