import { ContainerTableEntryFragment } from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";
import { TicketTableContextMenuHidden } from "../../../Tickets/components/Table/TicketTable";
import { SampleActions } from "../../state/actions";
import { useSampleState } from "../../state/hooks";
import { SampleTableContextMenuHidden } from "./SampleTable";

export const useHandleRowClick = (): ((
  rowClicked?: Row<ContainerTableEntryFragment>
) => (e: React.MouseEvent) => void) => {
  const { sampleDispatch } = useSampleState();
  return useMemo(
    () => (rowClicked?: Row<ContainerTableEntryFragment>) => () => {
      if (!rowClicked?.isGrouped && TicketTableContextMenuHidden()) {
        console.log("Method Tables Action: ROW_CLICKED");
        sampleDispatch({
          type: SampleActions.OPEN_SAMPLE_DETAILS,
          payload: {
            containerSummaryDialogOpen: {
              containerId: rowClicked?.original.containerId,
            },
          },
        });
      }
    },
    [sampleDispatch]
  );
};

export const handleContextMenu =
  (
    selected: ContainerTableEntryFragment[],
    row?: Row<ContainerTableEntryFragment>
  ) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    const _selected = selected.includes(row!.original)
      ? selected
      : [...selected, row?.original];
    contextMenu.show({
      id: "SampleContextMenu",
      event: e,
      props: {
        selected: _selected.map((s) => s?.containerId),
      },
    });
    SampleTableContextMenuHidden(false);
  };
