import { gql } from "@apollo/client/core";
import { ImagingWorklist } from "graphql/fragments/method-reservation.fragments";
import { PlateCreated } from "graphql/fragments/plate.fragments";
import { ManualTaskType, Method } from "./method-fragments";

export const Run = gql`
  fragment Run on Run {
    runId
    runName
    runDateScheduled
    runOwner
    comments
    isActive
    status
    folderParentId
    lookupTeamGanttId {
      teamGanttId
      id
      runId
      dateCreated
      nodeType
    }
    runStages {
      runId
      runStageId
      runStageName
    }
  }
`;
export const RunTaskDetails = gql`
  fragment RunTaskDetails on RunTaskDetail {
    runTaskId
    runTaskDetailsId
    daughterWellNumber
    seedCellCount
    freezeCellCount
    freezeDownRackType
    createMystplate
    destPlateCode
    minCryovial
    maxCryovial
    overflowVials
    imageExportFormat
    imageExportSetting
    worklistTemplateId
    intermediateLabwareTypeId
  }
`;
export const SimpleRunTask = gql`
  fragment SimpleRunTask on RunTask {
    runTaskId
    runId
    runStageId
    methodId
    runTemplateTaskId
    activityName
    endTimeScheduled
    startTimeScheduled
    endTimeActual
    daysFromRunStart
    users {
      userId
    }
    groups {
      groupId
    }
    systemAssignedTo
    status
    priority
    notes
    manualTaskTypeId
    runTaskDetail {
      ...RunTaskDetails
    }
  }
  ${RunTaskDetails}
`;
export const RunTask = gql`
  fragment RunTask on RunTask {
    runTaskId
    runId
    runStageId
    methodId
    runTemplateTaskId
    activityName
    endTimeScheduled
    startTimeScheduled
    endTimeActual
    startTimeActual
    daysFromRunStart
    users {
      userId
    }
    groups {
      groupId
    }
    systemAssignedTo
    status
    priority
    notes
    manualTaskTypeId
    run {
      ...Run
    }
  }
  ${Run}
`;
export const ReschedulingTask = gql`
  fragment ReschedulingTask on RunTask {
    runTaskId
    runId
    activityName
    startTimeScheduled
    endTimeScheduled
    startTimeActual
    endTimeActual
    status
    systemAssignedTo
  }
`;
export const ProductionRun = gql`
  fragment ProductionRun on ProductionRun {
    runId
    platesCreateds {
      ...PlateCreated
    }
  }
  ${PlateCreated}
`;

export const RunInfo = gql`
  fragment RunInfo on Run {
    runId
    ...Run
    productionRunId
    productionRun {
      ...ProductionRun
    }
  }
  ${Run}
`;
export const RunTaskMetaData = gql`
  ${ProductionRun}
  fragment RunTaskMetaData on RunTask {
    runTaskId
    method {
      ...Method
    }
    manualTaskType {
      ...ManualTaskType
    }
    run {
      ...RunInfo
    }
    runTaskDetail {
      ...RunTaskDetails
    }
    methodReservations {
      methodReservationId
      date
      isCompleted
      worklist {
        worklistContentImagings {
          ...ImagingWorklist
        }
      }
    }
  }
  ${RunInfo}
  ${ImagingWorklist}
  ${Method}
  ${ManualTaskType}
  ${RunTaskDetails}
`;
export const RunTaskAttachment = gql`
  fragment RunTaskAttachment on RunTaskAttachment {
    runTaskAttachmentId
    runTaskId
    attachmentName
    attachmentUrl
    dateUploaded
    uploadedBy
    comments
  }
`;
