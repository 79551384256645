import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import { AppModal } from "shared-components/ModalsAndDialogs/AppModal";

export function TicketModalContainer({ children }: Wrapper): JSX.Element {
  const {
    ticketState: { modalOpen },
    ticketDispatch,
  } = useTicketState();
  return (
    <AppModal
      open={modalOpen ?? false}
      onClose={() => ticketDispatch({ type: TicketActions.CLOSE_MODAL })}
    >
      {children}
    </AppModal>
  );
}
