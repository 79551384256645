import { ApolloQueryResult } from "@apollo/client";
import { ContextMenuDialogs } from "features/Dashboard/shared/interfaces";
import { DashboardActions } from "features/Dashboard/state/action";
import { useDashboardState } from "features/Dashboard/state/hooks";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  DashboardTask,
  Exact,
  GetDashboardTasksQuery,
  Maybe,
  OperationNames,
  TaskPriorityEnum,
  TaskStatusEnum,
  TaskTypeEnum,
  useAssignTaskMutation,
  useUpdateDashboardTasksMutation,
} from "graphql/generated/schema-types";
import _ from "lodash";
import moment from "moment";
import { Item, Menu, Submenu } from "react-contexify";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";

interface DashboardContextMenuProps {
  loggedInUserId: number;
  refetch(
    variables?:
      | Partial<
          Exact<{
            loggedInUser?: Maybe<number> | undefined;
            status?: Maybe<TaskStatusEnum | TaskStatusEnum[]> | undefined;
          }>
        >
      | undefined
  ): Promise<ApolloQueryResult<GetDashboardTasksQuery>>;
  getTasks: (args: unknown) => DashboardTask[];
}

export const DashboardContextMenu = ({
  loggedInUserId,
  refetch,
  getTasks,
}: DashboardContextMenuProps) => {
  const [assignTask] = useAssignTaskMutation();
  const {
    dashboardDispatch,
    dashboardState: { queryVariables },
  } = useDashboardState();

  const setPriority = (priority: TaskPriorityEnum, args: unknown) => {
    const tasks = getTasks(args);
    if (!tasks.length) return;

    const updatedTasks = _.cloneDeep(tasks);
    updatedTasks.forEach((task: DashboardTask) => {
      delete task.assignedUsers;
      delete task.assignedGroups;
      task.priority = priority;
    });

    callUpdateDashboardTask(updatedTasks);
  };

  const { isConfirmed } = useConfirm();

  const assignTaskToMe = (args: unknown) => {
    const tasks = getTasks(args);
    const assignedUsers =
      tasks.length > 1 || !tasks[0].assignedUsers
        ? []
        : [...tasks[0].assignedUsers!] ?? [];
    assignedUsers.push(loggedInUserId);
    const updateTask = {
      selectedTasks: tasks.map((task) => {
        return { taskType: task.taskType, taskId: task.taskId };
      }),
      assignedUsers: assignedUsers,
    };
    assignTask({
      variables: {
        taskInput: updateTask,
      },
    }).then((res) => {
      refetch().then((data: any) => {
        dashboardDispatch({
          type: DashboardActions.UPDATE_DASHBOARD_TASKS,
          payload: {
            dashboardTasks: data!.data[queryVariables!]!,
          },
        });
      });
    });
  };

  const allTasksManual = (dashboardTasks: DashboardTask[]) => {
    if (
      dashboardTasks?.every((dt) => dt.systemId === 14 || dt.systemId === null)
    )
      return true;
    return false;
  };

  const anyTasksOverdue = (dashboardTasks: DashboardTask[]) => {
    if (dashboardTasks?.some((dt) => dt.status === TaskStatusEnum.Overdue))
      return true;
    return false;
  };
  const anyFutureTasks = (dashboardTasks: DashboardTask[]) => {
    const nowUTC = moment.utc().milliseconds(0);

    if (
      dashboardTasks?.some((dt) => {
        const dtStartTimeUTC = moment.utc(dt.startTime);
        return dtStartTimeUTC.isAfter(nowUTC);
      })
    ) {
      return true;
    }
    return false;
  };

  const markAs = async (
    status: TaskStatusEnum,
    args: unknown,
    tasks: DashboardTask[] = []
  ) => {
    if (!tasks.length) {
      tasks = getTasks(args);
    }
    if (!tasks.length) return;

    if (
      !allTasksManual(tasks) &&
      tasks.length > 1 &&
      (status === TaskStatusEnum.InProgress ||
        status === TaskStatusEnum.Completed)
    ) {
      return Alert({
        type: AlertType.ERROR,
        message:
          "You must mark automated tasks as 'inProgress' or 'Completed' individually.",
      });
    }

    if (
      !allTasksManual(tasks) &&
      anyTasksOverdue(tasks) &&
      status === TaskStatusEnum.InProgress
    ) {
      return Alert({
        type: AlertType.ERROR,
        message: "You cannot mark an 'Overdue' task 'In Progress.'",
      });
    }

    if (
      !allTasksManual(tasks) &&
      anyFutureTasks(tasks) &&
      status === TaskStatusEnum.Completed
    ) {
      return Alert({
        type: AlertType.ERROR,
        message:
          "You cannot mark a future task completed. Did you mean to cancel?",
      });
    }

    if (
      !allTasksManual(tasks) &&
      (status === TaskStatusEnum.Completed ||
        status === TaskStatusEnum.InProgress)
    ) {
      const confirmed = await isConfirmed(
        `Are you SURE you want to set task(s) to ${status}? 
        This could affect other people's tasks and cannot be undone.`
      );

      if (!confirmed) {
        return;
      }
    }

    const updatedTasks = _.cloneDeep(tasks);
    updatedTasks.forEach((task: DashboardTask) => {
      delete task.assignedUsers;
      delete task.assignedGroups;
      task.status = status;
    });

    callUpdateDashboardTask(updatedTasks);
    return Alert({
      type: AlertType.SUCCESS,
      message: "Task status updated.",
    });
  };

  const isSingleRunTask = (tasks: DashboardTask[]) => {
    return tasks.length === 1 && tasks[0].taskType === TaskTypeEnum.RunTask;
  };

  const handleCancelClick = (args: unknown) => {
    const tasks = getTasks(args);
    if (isSingleRunTask(tasks)) {
      openDialog(ContextMenuDialogs.CancelTask, args, tasks);
    } else markAs(TaskStatusEnum.Cancelled, args, tasks);
  };

  const { runsModalDispatch } = useRunsModalState();
  const openDialog = (
    dialog: ContextMenuDialogs,
    args: unknown,
    tasks: DashboardTask[] = []
  ) => {
    if (!tasks.length) tasks = getTasks(args);
    if (
      isSingleRunTask(tasks) &&
      (dialog === ContextMenuDialogs.RescheduleTask ||
        dialog === ContextMenuDialogs.CancelTask)
    ) {
      runsModalDispatch({
        type: RunsModalActions.OPEN_MODAL,
        payload: {
          currentModal:
            dialog === ContextMenuDialogs.RescheduleTask
              ? RunTaskModals.RescheduleTasks
              : RunTaskModals.CancelTasks,
          selectedTasks: [tasks[0].taskId],
        },
      });
    } else {
      dashboardDispatch({
        type: DashboardActions.SET_SELECTED_DIALOG,
        payload: {
          dialogOpen: true,
          selectedDialog: dialog,
        },
      });
      dashboardDispatch({
        type: DashboardActions.SET_SELECTED_TASKS,
        payload: {
          selectedTasks: tasks,
        },
      });
    }
  };

  const [updateDashboardTask] = useUpdateDashboardTasksMutation();
  const callUpdateDashboardTask = (tasks: DashboardTask[]) =>
    updateDashboardTask({
      variables: {
        dashboardTasks: tasks,
      },
      refetchQueries: [OperationNames.Query.getCalendarNodes],
    }).then(() => {
      refetch().then((data: any) => {
        dashboardDispatch({
          type: DashboardActions.UPDATE_DASHBOARD_TASKS,
          payload: {
            dashboardTasks: data!.data[queryVariables!]!,
          },
        });
      });
    });

  return (
    <Menu
      id={"dashboard-context-menu"}
      theme={localStorage.getItem("theme")?.includes("light") ? "" : "dark"}
      style={{ zIndex: 1 }}
    >
      <Submenu label="Mark As">
        <Item onClick={(args) => markAs(TaskStatusEnum.Scheduled, args)}>
          Scheduled
        </Item>
        <Item onClick={(args) => markAs(TaskStatusEnum.InProgress, args)}>
          In Progress
        </Item>
        <Item onClick={(args) => markAs(TaskStatusEnum.Completed, args)}>
          Completed
        </Item>

        <Item onClick={(args) => handleCancelClick(args)}>Cancelled</Item>
      </Submenu>
      <Submenu label="Set Priority">
        <Item onClick={(args) => setPriority(TaskPriorityEnum.Immediate, args)}>
          Immediate
        </Item>
        <Item onClick={(args) => setPriority(TaskPriorityEnum.High, args)}>
          High
        </Item>
        <Item onClick={(args) => setPriority(TaskPriorityEnum.Medium, args)}>
          Medium
        </Item>
        <Item onClick={(args) => setPriority(TaskPriorityEnum.Low, args)}>
          Low
        </Item>
      </Submenu>
      <Submenu label="Assign To">
        <Item onClick={(args) => assignTaskToMe(args)}>Me</Item>
        <Item
          onClick={(args) => openDialog(ContextMenuDialogs.AssignToUser, args)}
        >
          User
        </Item>
        <Item
          onClick={(args) => openDialog(ContextMenuDialogs.AssignToGroup, args)}
        >
          Group
        </Item>
        <Item
          onClick={(args) =>
            openDialog(ContextMenuDialogs.AssignToSystem, args)
          }
        >
          System
        </Item>
      </Submenu>
      <Item
        onClick={(args) => openDialog(ContextMenuDialogs.RescheduleTask, args)}
      >
        Reschedule Task
      </Item>
      <Item onClick={(args) => openDialog(ContextMenuDialogs.EditTask, args)}>
        Edit Task
      </Item>
      <Item
        onClick={(args) => openDialog(ContextMenuDialogs.ViewTaskHistory, args)}
      >
        View Task History
      </Item>
    </Menu>
  );
};
