import { useReactiveVar } from "@apollo/client";
import {
  ITicketAction,
  ITicketState,
  TicketState,
} from "features/Tickets/state/initial-state";
import { ticketStateReducer } from "features/Tickets/state/reducer";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";

export function useTicketState(): {
  ticketState: ITicketState;
  ticketDispatch: IApolloDispatch<ITicketAction>;
} {
  const ticketState = useReactiveVar(TicketState);
  const ticketDispatch = useMemo(
    () => ApolloStateDispatch(TicketState, ticketStateReducer),
    []
  );
  return useMemo(
    () => ({
      ticketState,
      ticketDispatch,
    }),
    [ticketState, ticketDispatch]
  );
}
