import { useReactiveVar } from "@apollo/client";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import { Tooltip } from "@mui/material";
import { StyledIconButton } from "features/WorklistTools/shared/components/styles/styled-components";
import { WarningDialog } from "features/WorklistTools/shared/components/WarningDialog";
import { WorklistToolWarnings } from "features/WorklistTools/shared/components/WorklistToolWarnings";
import { useAppReduxSelector } from "hooks/reduxHooks";
import { useState } from "react";
import { useGenerateWorklistClick } from "../../state/handlers";
import { ThawToolInternalState } from "../../state/initial-state";
import {
  getOptionalWorklistWarnings,
  getRequiredWorklistWarnings,
} from "../../warnings";

export const GenerateWorklistButton = ({
  methodReservationId,
}: {
  methodReservationId: number;
}) => {
  const [requiredWarnings, setRequiredWarnings] = useState([""]);
  const [optionalWarnings, setOptionalWarnings] = useState([""]);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );

  const thawToolInternalState = useReactiveVar(ThawToolInternalState);

  const generateWorklist = useGenerateWorklistClick();

  const checkForWarnings = () => {
    const requiredWarning = getRequiredWorklistWarnings(
      thawWorklistToolInfo.sourcePlateInfo,
      thawWorklistToolInfo.destPlateInfo,
      thawWorklistToolInfo.worklistValues,
      thawWorklistToolInfo.deadPlateBarcode,
      thawWorklistToolInfo.deadPlateType,
      thawToolInternalState
    );
    const optionalWarning = getOptionalWorklistWarnings(
      thawWorklistToolInfo.sourcePlateInfo,
      thawWorklistToolInfo.destPlateInfo,
      thawWorklistToolInfo.worklistValues,
      thawWorklistToolInfo.deadPlateBarcode,
      thawToolInternalState.methodSettings
    );
    console.log(requiredWarning);
    setRequiredWarnings(requiredWarning);
    setOptionalWarnings(optionalWarning);
  };

  const noRequiredWarnings = requiredWarnings.length < 1;

  const handleGenerateWorklistClick = () => {
    if (
      thawWorklistToolInfo.destPlateInfo.some(
        (e: any) =>
          e.preprocessPlate === true &&
          thawToolInternalState.methodSettings.topupVol < 0.25 * e.operatingVol
      )
    ) {
      setWarningDialogOpen(true);
    } else if (noRequiredWarnings) {
      generateWorklist();
    }
  };

  const handleWarningDialogClose = (continueAction: boolean) => {
    if (continueAction && noRequiredWarnings) {
      generateWorklist();
    }
    setWarningDialogOpen(false);
  };

  const sourcePlateAlreadyReserved = methodReservationId > 0;

  const title = sourcePlateAlreadyReserved ? (
    "Source Plate has already been reserved. Please delete the existing reservation"
  ) : (
    <WorklistToolWarnings
      requiredWarnings={requiredWarnings}
      optionalWarnings={optionalWarnings}
    />
  );

  const message =
    "The Topup volume is less than 25% of the well’s operating volume. " +
    "As you have elected to pre-process this could result in the plate sitting dry. " +
    "Only proceed if you’re comfortable with this, else assign a larger topup (pre-processing volume)";

  return (
    <>
      <Tooltip onOpen={checkForWarnings} title={title} placement="right">
        <div>
          <StyledIconButton
            onClick={() => handleGenerateWorklistClick()}
            disabled={sourcePlateAlreadyReserved}
          >
            <GetAppTwoToneIcon />
          </StyledIconButton>
        </div>
      </Tooltip>

      <WarningDialog
        title={"Warning"}
        message={[message]}
        isOpen={warningDialogOpen}
        handleClose={(continueAction) =>
          handleWarningDialogClose(continueAction)
        }
      />
    </>
  );
};
