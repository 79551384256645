import produce from "immer";
import { DashboardActions } from "./action";
import { IDashboardAction, IDashboardState } from "./initial-state";

export const dashboardStateReducer = (
  state: IDashboardState,
  action: IDashboardAction
) => {
  switch (action.type) {
    case DashboardActions.UPDATE_TAB_INDEX:
      return produce(state, (draft) => {
        draft.tabIndex = action.payload?.tabIndex ?? 0;
      });
    case DashboardActions.UPDATE_DASHBOARD_TASKS:
      return produce(state, (draft) => {
        draft.dashboardTasks = action.payload?.dashboardTasks ?? [];
        draft.queryVariables =
          action.payload?.queryVariables ?? draft.queryVariables;
      });
    case DashboardActions.SET_SELECTED_TASKS:
      return produce(state, (draft) => {
        draft.selectedTasks = action.payload?.selectedTasks ?? [];
      });
    case DashboardActions.SET_SELECTED_DIALOG:
      return produce(state, (draft) => {
        draft.dialogOpen = action.payload?.dialogOpen ?? false;
        draft.selectedDialog = action.payload?.selectedDialog ?? 0;
      });
  }
};
