import { DateRange } from "@mui/lab";
import {
  ContainerFilterInput,
  ContainerTableEntryFragment,
} from "graphql/generated/schema-types";
import _ from "lodash";
import { Filters } from "react-table";
import { GetDayRange } from "helpers/date-helpers";

export const SampleFilterReducer = (
  tableFilters: Filters<ContainerTableEntryFragment>
) => {
  const where: ContainerFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    let range = tableFilter.value as DateRange<unknown>;
    switch (tableFilter.id) {
      case "sample.sampleBarcode" as keyof ContainerTableEntryFragment:
        where!.and!.push({
          sample: { sampleBarcode: { contains: tableFilter.value } },
        });
        break;
      case "rowCol":
        where!.and!.push({
          rowCol: { eq: tableFilter.value.toUpperCase() },
        });
        break;
      case "barcode":
        where!.and!.push({
          barcode: { contains: tableFilter.value },
        });
        break;
      case "plate.plateBarcode" as keyof ContainerTableEntryFragment:
        where!.and!.push({
          plate: { plateBarcode: { contains: tableFilter.value } },
        });
        break;
      case "lotNumber.lotNumber1" as keyof ContainerTableEntryFragment:
        where!.and!.push({
          lotNumber: { lotNumber1: { contains: tableFilter.value } },
        });
        break;
      case "inactivationReason.reason" as keyof ContainerTableEntryFragment:
        where!.and!.push({
          inactivationReason: { reason: { contains: tableFilter.value } },
        });
        break;
      case "isActive":
        where!.and!.push({
          isActive: { eq: tableFilter.value === "true" },
        });
        break;
      case "dateInactivated":
        if (range.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.dateInactivated);
          break;
        }
        if (range.some((date) => !date)) {
          break;
        }
        if (_.isEqual(range[0], range[1]))
          range = GetDayRange(tableFilter.value[0]);
        where!.and!.push({
          dateInactivated: { gt: range[0] },
        });
        where!.and!.push({
          dateInactivated: { lt: range[1] },
        });
        break;
      case "dateCreated":
        if (range.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.dateCreated);
          break;
        }
        if (range.some((date) => !date)) {
          break;
        }
        if (_.isEqual(range[0], range[1]))
          range = GetDayRange(tableFilter.value[0]);
        where!.and!.push({
          dateCreated: { gt: range[0] },
        });
        where!.and!.push({
          dateCreated: { lt: range[1] },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
