import { gql } from "@apollo/client/core";
import { TypeNames } from "enums/TypeNames";
import {
  Ticket,
  TicketAndAttachments,
  TicketAttachments,
} from "graphql/fragments/ticket.fragments";

export const TicketQueries = {
  remote: {
    GET_ACTIVE_TICKETS: gql`
      query GetActiveTickets(
        $where: TicketFilterInput
        $order: [TicketSortInput!]
      ) {
        activeTickets(where: $where, order: $order) {
          ...Ticket
        }
      }
      ${Ticket}
    `,
    GET_TICKETS: gql`
      query GetTickets(
        $pageSize: Int
        $after: String
        $order: [TicketSortInput!]
        $where: TicketFilterInput
      ) {
        tickets(first: $pageSize, order: $order, after: $after, where: $where) {
          edges {
            node {
              ...Ticket
            }
          }
          pageInfo {
            hasNextPage
            startCursor
            endCursor
            hasPreviousPage
          }
          totalCount
        }
      }
      ${Ticket}
    `,
    GET_TICKET_ATTACHMENTS: gql`
      query GetTicketAttachments($ticketId: Int!) {
        ticketInfo(id: $ticketId) {
          ...TicketAttachments
        }
      }
      ${TicketAttachments}
    `,
    GET_TICKET_INFO: gql`
      query GetTicketInfo($ticketId: Int!) {
        ticketInfo(id: $ticketId) {
          ...TicketAndAttachments
        }
      }
      ${TicketAndAttachments}
    `,
    GET_TICKET_ASSET_TYPES: gql`
      query GetTicketAssetTypes {
        ticketAssetTypes {
          assetTypeId
          assetType
        }
      }
    `,
    GET_TICKET_TYPES: gql`
      query GetTicketTypes {
        ticketTypes {
          ticketTypeId
          ticketType
        }
      }
    `,
    GET_TICKET_ASSETS: gql`
      query GetTicketAssets {
        ticketAssets(order: { asset: ASC }) {
          assetId
          asset
          assetType {
            assetTypeId
            assetType
          }
        }
      }
    `,
  },
  local: {
    GET_TICKET_BY_ID: gql`
      query GetTicketById($ticketId: Int!) {
        ticket(id: $ticketId, typeName: "${TypeNames.Ticket}", keyField: "ticketId") @client{
            ...Ticket
        }
      }
      ${Ticket}
    `,
  },
};
