import { Skeleton, TableRow } from "@mui/material";
import React, { FC } from "react";
import { StyledTableCell } from "shared-components/Table/ReactTable";

export const LoadingRows: FC = () => (
  <>
    <TableRow>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell>
        <Skeleton />
      </StyledTableCell>
    </TableRow>
  </>
);
