import { Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

interface DashboardAppBarProps {
  setAddPersonalTaskState: React.Dispatch<React.SetStateAction<boolean>>;
  setManagePersonalTaskState: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DashboardAppBar({
  setAddPersonalTaskState,
  setManagePersonalTaskState,
}: DashboardAppBarProps) {
  const history = useHistory();
  const handlePersonalTaskTemplatesClicked = () => {
    history.push("/personal-task-template");
  };

  return (
    <>
      <Button
        variant={"contained"}
        color={"secondary"}
        style={{ margin: "10px 10px 0 10px" }}
        onClick={() => {
          setAddPersonalTaskState(true);
        }}
      >
        Add Personal Task
      </Button>
      <Button
        variant={"contained"}
        color={"secondary"}
        style={{ margin: "10px 10px 0 0" }}
        onClick={() => {
          setManagePersonalTaskState(true);
        }}
      >
        Manage Personal Tasks
      </Button>
      <Button
        variant={"contained"}
        color={"secondary"}
        style={{ marginTop: "10px" }}
        onClick={() => handlePersonalTaskTemplatesClicked()}
      >
        Personal Task Templates
      </Button>
    </>
  );
}
