import { Box, Card, Paper, Typography } from "@mui/material";
import { SlideOverForms } from "features/RunTemplate/components/SlideOver/slideover.enum";
import { TaskAccordion } from "features/RunTemplate/components/Timeline/TaskCard/TaskAccordion";
import { useRunTemplateStateProvider } from "features/RunTemplate/state/StateProvider";
import {
  LookupLabwareType,
  RunTemplateTask,
  RunTemplateTaskDetail,
} from "graphql/generated/schema-types";
import React from "react";
import styled, { useTheme } from "styled-components/macro";

export const CardTitle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: ${({ margin }: { margin?: string }) => `10px ${margin}`};
`;
const importantDetails: Array<keyof RunTemplateTaskDetail> = [
  "daughterWellNumber",
  "minCryovial",
  "maxCryovial",
  "createMystplate",
  "destPlateCode",
  "freezeCellCount",
  "freezeDownRackType",
  "overflowVials",
  "seedCellCount",
  "imageExportSetting",
  "imageExportFormat",
];

export function TaskCard({
  task,
  onContextMenu,
}: {
  task: RunTemplateTask;
  onContextMenu?: (e: React.MouseEvent<HTMLDivElement>) => void;
}): JSX.Element {
  let importantDetailsKeyValuePairs: [
    string,
    (
      | string
      | number
      | boolean
      | null
      | undefined
      | RunTemplateTask
      | LookupLabwareType
    )
  ][] = [];

  if (task.runTemplateTaskDetail)
    importantDetailsKeyValuePairs = Object.entries(
      task.runTemplateTaskDetail!
    ).filter(
      (keyValuePair) =>
        importantDetails.includes(
          keyValuePair[0] as keyof RunTemplateTaskDetail
        ) && keyValuePair[1] !== null
    );

  const {
    runTemplateState: { selectedTaskId, slideOverOpen, currentSlideOver },
  } = useRunTemplateStateProvider();

  const theme = useTheme();
  return importantDetailsKeyValuePairs.length === 0 && !task.notes ? (
    <Paper elevation={3} onContextMenu={onContextMenu}>
      <Card
        elevation={0}
        style={{
          backgroundColor: theme.palette.mode === "dark" ? "rgb(43,43,43)" : "",
        }}
      >
        <CardTitle margin={"20px"}>
          <Typography
            color={
              selectedTaskId === task.runTemplateTaskId &&
              slideOverOpen &&
              currentSlideOver === SlideOverForms.EditRunTemplateTasks
                ? "secondary"
                : undefined
            }
          >
            <Box fontWeight={"fontWeightBold"}>
              {!!task.customTaskName
                ? task.customTaskName
                : task.method?.methodName ?? task.manualTaskType?.name}
            </Box>
          </Typography>
        </CardTitle>
      </Card>
    </Paper>
  ) : (
    <TaskAccordion
      task={task}
      importantDetailsKeyValuePairs={importantDetailsKeyValuePairs}
      onContextMenu={onContextMenu}
    />
  );
}
