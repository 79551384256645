import { Assignment, Collections, CreateNewFolder } from "@mui/icons-material";
import { List, ListItemText } from "@mui/material";
import {
  StyledListItem,
  StyledListItemIcon,
  StyledMenu,
} from "shared-components/styled-components";
import {
  IncomingSampleActions,
  IncomingSampleScreensEnum,
  useIncomingSampleState,
} from "../state";

export function IncomingSampleSideBar(): JSX.Element {
  const {
    incomingSampleDispatch,
    incomingSampleState: { activeScreen },
  } = useIncomingSampleState();
  return (
    <StyledMenu>
      <List>
        <StyledListItem
          active={activeScreen === IncomingSampleScreensEnum.Tasks}
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.SELECT_SCREEN,
              payload: { activeScreen: IncomingSampleScreensEnum.Tasks },
            })
          }
        >
          <StyledListItemIcon
            active={activeScreen === IncomingSampleScreensEnum.Tasks}
          >
            <Assignment />
          </StyledListItemIcon>
          <ListItemText primary={"Tasks"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === IncomingSampleScreensEnum.Collections}
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.SELECT_SCREEN,
              payload: { activeScreen: IncomingSampleScreensEnum.Collections },
            })
          }
        >
          <StyledListItemIcon
            active={activeScreen === IncomingSampleScreensEnum.Collections}
          >
            <Collections />
          </StyledListItemIcon>
          <ListItemText primary={"Collections"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === IncomingSampleScreensEnum.CreateCollection}
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.SELECT_SCREEN,
              payload: {
                activeScreen: IncomingSampleScreensEnum.CreateCollection,
              },
            })
          }
        >
          <StyledListItemIcon
            active={activeScreen === IncomingSampleScreensEnum.CreateCollection}
          >
            <CreateNewFolder />
          </StyledListItemIcon>
          <ListItemText primary={"Schedule Samples"} />
        </StyledListItem>
        <StyledListItem
          active={activeScreen === IncomingSampleScreensEnum.IDF}
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.SELECT_SCREEN,
              payload: {
                activeScreen: IncomingSampleScreensEnum.IDF,
              },
            })
          }
        >
          <StyledListItemIcon
            active={activeScreen === IncomingSampleScreensEnum.IDF}
          >
            <CreateNewFolder />
          </StyledListItemIcon>
          <ListItemText primary={"Incoming Data Fields Form"} />
        </StyledListItem>
      </List>
    </StyledMenu>
  );
}
