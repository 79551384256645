import { FieldFunctionOptions } from "@apollo/client";
import { SafeReadonly } from "@apollo/client/cache/core/types/common";
import { searchFolderTree } from "features/Runs/components/FolderTree/helpers";
import {
  DirectoryFolder,
  GetFolderDirectoryDocument,
  GetFolderDirectoryQuery,
} from "graphql/generated/schema-types";

export const filterDirectoryByText = (
  existing: SafeReadonly<never> | undefined,
  { args, cache }: FieldFunctionOptions<{ search?: string }>
): unknown => {
  const allFolders = cache.readQuery<GetFolderDirectoryQuery>({
    query: GetFolderDirectoryDocument,
  });
  const directoryGraph = (allFolders?.directory ??
    []) as Array<DirectoryFolder>;
  const searchedDirGraph: Array<DirectoryFolder> = [];

  if (args?.search !== undefined)
    searchFolderTree(args?.search, directoryGraph, searchedDirGraph);

  return searchedDirGraph.slice(0, 30);
};
