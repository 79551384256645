import { useReactiveVar } from "@apollo/client";
import SaveIcon from "@mui/icons-material/Save";
import { Tooltip } from "@mui/material";
import { Methods } from "enums/Methods";
import { NewTemplateNameDialog } from "features/WorklistTools/shared/components/NewTemplateNameDialog";
import { StyledIconButton } from "features/WorklistTools/shared/components/styles/styled-components";
import { TwoOptionDialog } from "features/WorklistTools/shared/components/TwoOptionDialog";
import { ViewWorklistTemplate } from "features/WorklistTools/shared/components/WorklistTemplates/ViewWorklistTemplates";
import {
  useOverwriteExistingWorklistTemplateMutation,
  useSaveWorklistToolTemplateMutation,
  WorklistToolTemplate,
} from "graphql/generated/schema-types";
import { useAppReduxSelector } from "hooks/reduxHooks";
import { useState } from "react";
import { Alert, AlertType } from "shared-components/toast";
import { ThawToolInternalState } from "../../state/initial-state";
import {
  getDestPlateInfoForTemplate,
  getMatrixRackCentrifugation,
  getSourcePlateInfoForTemplate,
  getThawDestination,
  getThawStamp,
  getThawTask,
  getThawTransfer,
} from "../handlers/SaveTemplateHelpers";

export const SaveTemplateButton = () => {
  const [twoOptionDialogOpen, setTwoOptionDialogOpen] = useState(false);
  const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);

  const thawToolInternalState = useReactiveVar(ThawToolInternalState);
  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );

  const [saveWorklistTemplate] = useSaveWorklistToolTemplateMutation();

  const [overwriteExistingWorklistToolTemplate] =
    useOverwriteExistingWorklistTemplateMutation({
      onCompleted() {
        Alert({
          type: AlertType.SUCCESS,
          message: "Template Saved",
        });
      },
    });

  const getWorklist = () => [
    ...getThawTask(thawToolInternalState),
    ...getThawDestination(thawWorklistToolInfo, thawToolInternalState),
    ...getThawTransfer(thawWorklistToolInfo),
    ...getThawStamp(thawWorklistToolInfo),
    ...getMatrixRackCentrifugation(thawToolInternalState),
  ];

  const getTemplateInfoToSave = (worklistToolTemplateId?: number) => ({
    worklistToolTemplateId,
    undefined,
    methodId: Methods.Thaw,
    sourcePlateInfo: getSourcePlateInfoForTemplate(thawWorklistToolInfo),
    destPlateInfo: getDestPlateInfoForTemplate(thawWorklistToolInfo),
    worklistToolTemplateContentsInput: getWorklist(),
  });

  const viewWorklistTemplate_handleClose = (
    overwriteTemplate: boolean,
    templateInfo: WorklistToolTemplate | undefined
  ) => {
    setTemplateDialogOpen(false);
    if (!overwriteTemplate) return;

    overwriteExistingWorklistToolTemplate({
      variables: {
        input: getTemplateInfoToSave(templateInfo!.worklistToolTemplateId),
      },
    });
  };

  const twoOptionDialog_handleClose = (overwriteTemplate: boolean) => {
    setTwoOptionDialogOpen(false);
    if (overwriteTemplate) setTemplateDialogOpen(true);
    else setSaveTemplateDialogOpen(true);
  };

  const newTemplateNameDialog_handleClose = (
    saveTemplate: boolean,
    templateName: string
  ) => {
    setSaveTemplateDialogOpen(false);
    if (!saveTemplate) return;

    const savedTemplate = {
      templateName,
      methodId: Methods.Thaw,
      sourcePlateInfo: getSourcePlateInfoForTemplate(thawWorklistToolInfo),
      destPlateInfo: getDestPlateInfoForTemplate(thawWorklistToolInfo),
      worklistToolTemplateContentsInput: getWorklist(),
    };
    saveWorklistTemplate({
      variables: {
        input: savedTemplate,
      },
    }).then((data) => {
      console.log(data);
      Alert({
        type: AlertType.SUCCESS,
        message: "Template Saved",
      });
    });
  };

  return (
    <>
      <StyledIconButton onClick={() => setTwoOptionDialogOpen(true)}>
        <Tooltip title="Save Template" placement="right">
          <SaveIcon />
        </Tooltip>
      </StyledIconButton>

      <TwoOptionDialog
        isOpen={twoOptionDialogOpen}
        handleClose={(overwriteTemplate) =>
          twoOptionDialog_handleClose(overwriteTemplate)
        }
        title={"Do you want to overwrite an existing template?"}
        leftButtonText="No"
        rightButtonText="Yes"
      />

      <NewTemplateNameDialog
        isOpen={saveTemplateDialogOpen}
        handleClose={(saveTemplate, templateName) =>
          newTemplateNameDialog_handleClose(saveTemplate, templateName)
        }
      />

      <ViewWorklistTemplate
        isOpen={templateDialogOpen}
        handleClose={(loadTemplate, templateInfo) =>
          viewWorklistTemplate_handleClose(loadTemplate, templateInfo)
        }
        overwriteTemplateMode={true}
        methodId={Methods.Thaw}
        // Update this date whenever outputted worklist is changed
        dateWorklistLastUpdated={new Date("07/28/2021 05:00:00 PM")}
      />
    </>
  );
};
