import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import IconButton from "@mui/material/IconButton";
import { connect } from "react-redux";
import { ActionCreators as UndoActionCreators } from "redux-undo";

interface UndoRedoProps {
  canUndo?: boolean;
  canRedo?: boolean;
  onUndo?: any;
  onRedo?: any;
  method: number;
}

const UndoRedo = ({ canUndo, canRedo, onUndo, onRedo }: UndoRedoProps) => (
  <div style={{ display: "flex" }}>
    <IconButton onClick={onUndo} disabled={!canUndo} size="large">
      <UndoIcon />
    </IconButton>
    <IconButton onClick={onRedo} disabled={!canRedo} size="large">
      <RedoIcon />
    </IconButton>
  </div>
);

const methodIdToReduxTool = (methodId: number) => {
  switch (methodId) {
    case 0:
      return "ThawTool";
    case 1:
      return "PoolingNormalizationTool";
    case 2:
      return "Twelve24TCTool";
    default:
      return "PoolingNormalizationTool";
  }
};

const mapStateToProps = (state: any, ownProps: { method: number }) => ({
  canUndo:
    state.WorklistTools[methodIdToReduxTool(ownProps.method)].past.length > 0,
  canRedo:
    state.WorklistTools[methodIdToReduxTool(ownProps.method)].future.length > 0,
});

const mapDispatchToProps = {
  onUndo: UndoActionCreators.undo,
  onRedo: UndoActionCreators.redo,
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo);
