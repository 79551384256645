import {
  CollectionTaskFilterInput,
  CollectionTaskSortInput,
  SortEnumType,
  TaskStatusEnum,
} from "graphql/generated/schema-types";

export const defaultIncomingSamplesStatusFilter = [
  TaskStatusEnum.Scheduled,
  TaskStatusEnum.InProgress,
];
export const DefaultIncomingSamplesFilter: CollectionTaskFilterInput = {
  and: [
    {
      status: {
        in: defaultIncomingSamplesStatusFilter,
      },
    },
  ],
};

export const DefaultIncomingSamplesSort: CollectionTaskSortInput[] = [
  {
    startTimeScheduled: SortEnumType.Desc,
  },
];
