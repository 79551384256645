/* eslint-disable react/jsx-pascal-case */
import { animated, useTransition } from "@react-spring/web";
import { Methods } from "enums/Methods";
import { FormikProps } from "formik";
import {
  RunTaskInput,
  RunTemplateTaskInput,
} from "graphql/generated/schema-types";
import React, { FC } from "react";
import { NormalizedPassageFields } from "shared-components/FormFields/MethodFields/NormalizedPassageFields";
import { XTYCustomPassageFields } from "shared-components/FormFields/MethodFields/XTYCustomPassageFields";
import styled from "styled-components";
import { PoolingPassageFields } from "./PoolingPassageFields";
import { StampNormalizedPassageFields } from "./StampNormalizedPassageFields";
import { ThawFields } from "./ThawFields";

const DetailsFormWidth = "400px";
export const TaskDetails = styled.div`
  display: flex;
  width: ${({ collapsable }: { collapsable: boolean }) =>
    collapsable ? DetailsFormWidth : "100%"};
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 0 10px;
`;
export const CollapseSection = styled.div`
  width: 100%;
`;
export const TaskDetailsNumberFields = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  justify-content: space-between;
`;
export const NumberFieldWrapper = styled.div`
  width: 30%;
`;
export const ExpandAnimation: FC<{ collapseOpen: boolean }> = ({
  children,
  collapseOpen,
}) => {
  const transition = useTransition(collapseOpen, {
    from: { display: "none", width: "0px" },
    enter: {
      display: "flex",
      alignItems: "flex-start",
      width: DetailsFormWidth,
    },
    leave: { visibility: "hidden", width: "0px" },
    config: { duration: 200 },
  });
  return transition(
    (transitionProps, item) =>
      item && <animated.div style={transitionProps}>{children}</animated.div>
  );
};
export function MethodFormReducer({
  props,
  methodId,
  collapseOpen,
  controlled,
}: {
  props: FormikProps<RunTaskInput> | FormikProps<RunTemplateTaskInput>;
  methodId?: number | null;
  collapseOpen?: boolean;
  controlled?: true | never;
}): JSX.Element {
  return collapseOpen === undefined ? (
    <TaskDetails collapsable={false} data-testid={"details-form"}>
      {MethodReducer(props, controlled, methodId)}
    </TaskDetails>
  ) : (
    <ExpandAnimation collapseOpen={collapseOpen!}>
      <TaskDetails collapsable={true} data-testid={"details-form"}>
        {MethodReducer(props, controlled, methodId)}
      </TaskDetails>
    </ExpandAnimation>
  );
}

function MethodReducer(
  props: FormikProps<RunTaskInput> | FormikProps<RunTemplateTaskInput>,
  controlled?: true | never,
  methodId?: number | null
) {
  switch (methodId) {
    case Methods.XTY_CustomPassage:
      return <XTYCustomPassageFields props={props} controlled={controlled} />;
    case Methods.Thaw:
      return <ThawFields props={props} controlled={controlled} />;
    case Methods.NormalizedPassage:
      return <NormalizedPassageFields props={props} controlled={controlled} />;
    case Methods.StampNormalizedPassage:
      return (
        <StampNormalizedPassageFields props={props} controlled={controlled} />
      );
    case Methods.PoolingNormalization:
      return <PoolingPassageFields props={props} controlled={controlled} />;
    default:
      return <React.Fragment />;
  }
}
