import { getRowAndColCountByLabwareType } from "features/WorklistTools/shared/WorklistHelpers";
import { Maybe } from "graphql-tools";
import { WorklistToolTemplateContent } from "graphql/generated/schema-types";
import { DestPlateInfoState } from "../../state";

export const parseThawDestinationTask = (
  templateThawDestination: Maybe<WorklistToolTemplateContent>[]
) => {
  let thawDestination: DestPlateInfoState[] = [];
  let index = 0;
  if (templateThawDestination.length) {
    thawDestination = templateThawDestination.map((row) => {
      const details = row!.details!.split(";");
      const preprocessPlate = !!parseInt(
        details[0].substring(details[0].length - 1)
      );
      const inCytomat = !!parseInt(details[1].substring(details[1].length - 1));
      const plateRowCol = getRowAndColCountByLabwareType(
        row!.destinationPlateType!
      );
      return {
        index: index++,
        plateBarcode: "",
        rows: plateRowCol.rows,
        cols: plateRowCol.cols,
        labwareTypeCode: row?.destinationPlateType ?? "",
        preprocessPlate: preprocessPlate,
        inCytomat: inCytomat,
        operatingVol: plateRowCol.operatingVol,
      };
    });
  }

  const addEmptyDestinationPlates = () => {
    thawDestination.push({
      index: index,
      plateBarcode: "",
      rows: [],
      cols: [],
      labwareTypeCode: "",
      preprocessPlate: false,
      inCytomat: false,
    });
    index++;
  };

  while (index < 8) {
    addEmptyDestinationPlates();
  }

  return thawDestination;
};

export const parseThawTransferTask = (
  templateThawTransfer: Maybe<WorklistToolTemplateContent>[]
) => {
  const thawTransfer = templateThawTransfer.map((row: any) => ({
    sourcePlateIndex: row.sourcePlatePosition,
    sourceWellId: row.sourceWellId,
    destPlateIndex: row.destinationPlatePosition,
    destWellId: row.destinationWellId,
    transferVol: row.transferVol,
  }));

  return thawTransfer;
};

export const parseThawStampTask = (
  templateThawStamp: Maybe<WorklistToolTemplateContent>[]
) => {
  const thawStamp = templateThawStamp.map((row) => ({
    plateIndex: row?.destinationPlatePosition,
    wellId: row?.destinationWellId,
    stampVol: parseInt(row?.transferVol ?? "0"),
  }));

  return thawStamp;
};

export const parseMethodSettings = (
  resuspensionVol: number,
  topupVol: number,
  matrixRackCentrifugation: Maybe<WorklistToolTemplateContent>[],
  destPlateCode = "",
  runTaskId?: number
) => {
  const vSpinSettings = matrixRackCentrifugation![0]!.details!.split("|");
  const vSpinRPM = vSpinSettings[1].split(";")[0];
  const vSpinGForce = Math.round(0.101 * Math.pow(parseInt(vSpinRPM), 1.999));

  const methodSettings = {
    runTaskId,
    destPlateCode,
    resuspensionVol,
    topupVol,
    gForce: vSpinGForce,
    rpm: vSpinRPM,
    spinTime: vSpinSettings[2].split(";")[0],
    spinAccel: vSpinSettings[3].split(";")[0],
    spinDecel: vSpinSettings[4].split(";")[0],
  };

  return methodSettings;
};
