import React from "react";
import { useMergeTicketMutation } from "graphql/generated/schema-types";
import { useWindowSize } from "hooks/useWindowSize";
import { StyledCard } from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { useTicketQueryProvider } from "../../../Context";
import { TicketActions } from "../../../state/actions";
import { useTicketState } from "../../../state/hooks";
import { MergeTicketForm } from "./Form";

export function MergeTicketModal(): JSX.Element {
  const {
    ticketState: { selectedTickets },
    ticketDispatch,
  } = useTicketState();
  const { refetch } = useTicketQueryProvider()!;

  const [mergeTicket] = useMergeTicketMutation();
  const handleSubmit: FormikSubmit<{ mergeInto: number }> = (
    values,
    helpers
  ) => {
    if (selectedTickets?.[0])
      return mergeTicket({
        variables: {
          mergeInto: values.mergeInto,
          ticketToMerge: selectedTickets[0],
        },
      })
        .then(() => refetch())
        .then(() =>
          ticketDispatch({
            type: TicketActions.CLOSE_MODAL,
          })
        );
    return helpers.setSubmitting(false);
  };
  const windowSize = useWindowSize();
  return (
    <StyledCard
      width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <MergeTicketForm onSubmit={handleSubmit} />
    </StyledCard>
  );
}
