import { Button, FormControl } from "@mui/material";
import styled from "styled-components";

export const AttachmentsArea = styled.div`
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: dashed 1px
    ${({
      // TODO: add props https://styled-components.com/docs/basics#adapting-based-on-props
      theme: {
        palette: { mode },
      },
    }) => (mode === "dark" ? "rgba(255,255,255,0.2)" : "rgba(0, 0, 0, 0.2)")};
`;

export const AttachmentAreaText = styled.div`
  font-weight: bold;
  &&:hover {
    cursor: pointer;
  }
`;

export const AttachmentsList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
`;

export const StyledButton = styled(Button)`
  //margin-top: 20px !important;
`;

export const StyledContainer = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 15px;
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
