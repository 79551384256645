import { makeVar } from "@apollo/client";
import { SelectionProcess } from "../interfaces";

export interface IWorklistToolInternalState {
  setCtrlDown: boolean;
  destSelectionProcess: SelectionProcess;
  randomizeWells: boolean;
}

export const WorklistToolInternalState = makeVar<IWorklistToolInternalState>({
  setCtrlDown: false,
  destSelectionProcess: SelectionProcess.Stamp,
  randomizeWells: false,
});
