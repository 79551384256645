import { gql } from "@apollo/client/core";
import { Directory, FolderStructure } from "graphql/fragments/folder.fragments";
import { TypeNames } from "../../enums/TypeNames";

export const FolderQueries = {
  remote: {
    GET_RUN_DIRECTORY: gql`
      query getFolderDirectory {
        directory {
          ...Directory
        }
      }
      ${Directory}
    `,

    GET_INSERTABLE_FOLDERS: gql`
      query getInsertableFolders {
        insertableFolders {
          ...FolderStructure
        }
      }
      ${FolderStructure}
    `,
  },
  GET_FOLDERS: gql`
    query getFolders(
      $where: FolderStructureFilterInput
      $order: [FolderStructureSortInput!]
    ) {
      folders(where: $where, order: $order) {
        folderId
        folderName
        folderParentId
        folderParent {
          folderId
          folderName
        }
        runs {
          runId
          runName
          runDateScheduled
          runStages {
            runStageId
            runStageName
          }
        }
      }
    }
  `,
  GET_ALL_FOLDERS: gql`
    query GetAllFolders {
      folders {
        ...FolderStructure
      }
    }
    ${FolderStructure}
  `,
  local: {
    GET_FOLDER_BY_ID: gql`
      query GetFolderById($folderId: Int!) {
        folder(id: $folderId, typeName: "${TypeNames.FolderStructure}", keyField: "folderId") @client {
          folderId
          folderParentId
          folderName
        }
      }
    `,
    SEARCH_FOLDER_DIRECTORY: gql`
      query SearchDirectory($search: String!) {
        searchDirectory(search: $search) @client {
          ...Directory
        }
      }
      ${Directory}
    `,
  },
};
