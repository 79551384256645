import { makeVar } from "@apollo/client";
import { ApolloQueryResult } from "@apollo/client/core";
import produce from "immer";
import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { IdType, TableState } from "react-table";
import { TableName } from "../../enums/TableName";
import { MutateState } from "../../helpers/object-helpers";
import { useGetUserSettings } from "../../hooks/useGetUserSettings";
import { GraphQLConnection } from "../../interfaces/GraphQLConnection";
import { GetNodesFromConnectionType } from "../../selectors/graphql-connection";
import { ApplyPagination } from "./Handlers/ApplyPagination";
import { TableQueryVariables } from "./Handlers/types";
import { PageSizeOptions, ReactTablePaginationState } from "./ReactTable";

export const useInitializeData = <TData>(
  data: Array<TData | null> | undefined | null,
  paginationState: ReactTablePaginationState
): TData[] => {
  return useMemo(
    () => ApplyPagination(data, paginationState) as TData[],
    [data, paginationState]
  );
};

export const useInitializeConnectionData = <TData>(
  connection: unknown,
  paginationState: ReactTablePaginationState
): TData[] => {
  const nodes: Array<TData> = useMemo(
    () =>
      GetNodesFromConnectionType<TData>(connection as GraphQLConnection<TData>),
    [connection]
  );
  return useMemo(
    () => ApplyPagination(nodes, paginationState) as TData[],
    [nodes, paginationState]
  );
};

export const useInitPaginationState = (
  initialState: ReactTablePaginationState
) => useState<ReactTablePaginationState>(initialState);

export function useCommonTableSideEffects<
  TQuery,
  TVariables extends Partial<TableQueryVariables>,
  TData extends Record<string, unknown>
>(
  [paginationState, setPaginationState]: [
    ReactTablePaginationState,
    Dispatch<SetStateAction<ReactTablePaginationState>>
  ],
  totalCount: number | undefined | null,
  variables: TVariables | undefined,
  refetch: (
    variables?: Partial<TVariables>
  ) => Promise<ApolloQueryResult<TQuery>>,
  groupBy: Array<IdType<TData>>
) {
  // update totalCount on totalCount change
  useEffect(() => {
    const _paginationState = produce(paginationState, (draft) => {
      draft.totalCount = totalCount ?? 0;
    });
    setPaginationState(_paginationState);
  }, [paginationState, setPaginationState, totalCount]);

  // set page size to 500 on groupBy
  useEffect(() => {
    const _variableState = MutateState(variables, (draft) => {
      if (draft?.pageSize) draft.pageSize = _.max(PageSizeOptions)!;
      else return { ...draft, pageSize: 500 } as TVariables;
    });
    if (groupBy.length > 0 && _variableState.hasChanged)
      refetch(_variableState.newValue as TVariables).then(() =>
        setPaginationState((_state) =>
          produce(_state, (draft) => {
            draft.rowsPerPage = _.max(PageSizeOptions)!;
            draft.page = 0;
          })
        )
      );
  }, [groupBy, refetch, setPaginationState, variables]);
}

export function useGetTablePreset<T extends Record<string, unknown>>(
  tableName: TableName,
  defaultState: Partial<TableState<T>>
) {
  const userSettings = useGetUserSettings();
  return useMemo(() => {
    let tableSettings = userSettings?.tables?.find(
      (_table) => _table.name === tableName
    );
    if (tableSettings?.presets)
      tableSettings.presets.push({
        name: "General",
        state: defaultState,
        pageSize: 10,
      });
    else
      tableSettings = {
        name: tableName,
        presets: [
          {
            name: "General",
            state: defaultState,
            pageSize: 10,
          },
        ],
      };
    return tableSettings;
  }, [defaultState, tableName, userSettings?.tables]);
}

export const SelectedPreset = makeVar<string | null>(null);
