import {
  DashboardTask,
  DashboardTaskInput,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { DeepCopy } from "helpers/object-helpers";
import * as _ from "lodash";
import { PersonalTaskInputStub } from "mappings/object-stubs/PersonalTaskInput";
import { PlateMetaDataInputMapper } from "mappings/plateMetadataInput";
import { runActivityDetailInputMapper } from "mappings/runActivityInput";

export function PersonalTaskInputMapper(personalTask: Maybe<DashboardTask>) {
  const _personalTask = DeepCopy(personalTask);
  if (_personalTask?.methodReservationPlateMetadata)
    _personalTask.methodReservationPlateMetadata =
      _personalTask.methodReservationPlateMetadata.map((metadata) =>
        PlateMetaDataInputMapper(metadata)
      );

  if (_personalTask?.taskSettings)
    (_personalTask as DashboardTaskInput).taskSettings =
      runActivityDetailInputMapper(_personalTask.taskSettings);

  return _.pick(
    _personalTask,
    _.keys(new PersonalTaskInputStub())
  ) as DashboardTaskInput;
}
