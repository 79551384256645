import { useReactiveVar } from "@apollo/client";
import { Box } from "@mui/material";
import { BackdropOpen } from "App";
import {
  useGetArrayMethodLabwareTypesLazyQuery,
  useGetExistingMethodReservationLazyQuery,
  useGetIntermediateDeadAndDestPlateBarcodesMutation,
  useReserveMethodMutation,
} from "graphql/generated/schema-types";
import { GetUrl } from "helpers/get-url";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import { NewWorklistPreview } from "../shared/components/NewWorklistPreview";
import { LabwareTypeInfo, Worklist } from "../shared/interfaces";
import { is384W_CCU_Plate } from "../shared/PlateHelpers";
import {
  extractPlateCode,
  getDeadPlateTypeInfo,
  getDestinationPlateBarcodeSeries,
  getDestPlateTypeInfo,
  getPlateCode,
  getSourcePlateTypeInfo,
} from "../shared/WorklistHelpers";
import { DestinationPlatesContainer } from "./plates/DestinationPlatesContainers";
import { SourcePlatesContainer } from "./plates/SourcePlatesContainer";
import { Twelve24TCToolSidebar } from "./sidebar/Sidebar";
import { Twelve24TCToolActions } from "./state";
import { useSetWorkistPreviewOpen, useSetWorklist } from "./state/handlers";
import { Twelve24TCToolInternalState } from "./state/initial-state";
import { useTwelve24TCStateProvider } from "./state/StateProvider";

export const Twelve24TC = () => {
  const { isConfirmed } = useConfirm();
  const twelve24TCToolInternalState = useReactiveVar(
    Twelve24TCToolInternalState
  );
  const methodSettings = twelve24TCToolInternalState.methodSettings;
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  const dispatch = useAppReduxDispatch();
  const [sourcePlateTypes, setSourcePlateTypes] = useState<LabwareTypeInfo[]>();
  const [destPlateTypes, setDestPlateTypes] = useState<LabwareTypeInfo[]>();
  const [deadPlateTypes, setDeadPlatesTypes] = useState<LabwareTypeInfo[]>();
  const twelve24TCState = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present
  );
  const sourcePlateInfo = twelve24TCState.sourcePlateInfo;
  const destPlateInfo = twelve24TCState.destPlateInfo;
  const deadPlateBarcode = twelve24TCState.deadPlateBarcode;
  const deadPlateType = twelve24TCState.deadPlateType;
  const worklistValues = twelve24TCState.worklistValues;

  const [getAcceptablePlateTypes, { data: acceptablePlateTypes }] =
    useGetArrayMethodLabwareTypesLazyQuery({ fetchPolicy: "network-only" });
  const [getAssociatedBarcodes] =
    useGetIntermediateDeadAndDestPlateBarcodesMutation();
  const [reserveMethod, { loading }] = useReserveMethodMutation();
  const setWorklist = useSetWorklist();
  const setWorklistPreviewOpen = useSetWorkistPreviewOpen();

  useEffect(() => {
    if (
      twelve24TCToolInternalState.methodSettings.arraySystemAutomationMethodId
    ) {
      getAcceptablePlateTypes({
        variables: {
          where: {
            arraySystemAutomationMethodId: {
              eq: twelve24TCToolInternalState.methodSettings
                .arraySystemAutomationMethodId,
            },
          },
        },
      });
    }
  }, [
    twelve24TCToolInternalState.methodSettings.arraySystemAutomationMethodId,
  ]);

  useEffect(() => {
    if (acceptablePlateTypes) {
      setSourcePlateTypes(getSourcePlateTypeInfo(acceptablePlateTypes));
      setDestPlateTypes(getDestPlateTypeInfo(acceptablePlateTypes));
      setDeadPlatesTypes(getDeadPlateTypeInfo(acceptablePlateTypes));
    }
  }, [acceptablePlateTypes]);

  useEffect(() => {
    const templateHasBeenLoaded =
      sourcePlateInfo[0].plateBarcode !== "" &&
      destPlateInfo.labwareTypeCode !== "";
    if (templateHasBeenLoaded) {
      getAssociatedBarcodes({
        variables: {
          sourcePlateBarcode: sourcePlateInfo[0].plateBarcode,
          destPlateCode:
            destPlateTypes?.find(
              (e) => e.labwareTypeCode === destPlateInfo.labwareTypeCode
            )?.defaultArrayPlateCode ??
            getPlateCode(destPlateInfo.labwareTypeCode),
          hasMultipleSourcePlateTypes: false,
          hasMultipleDestPlateTypes: false,
          has384DeadPlate: is384W_CCU_Plate(deadPlateType),
        },
      }).then((res) => {
        const barcodes = getDestinationPlateBarcodeSeries(
          res.data?.intermediateDeadAndDestPlateBarcodes
            ?.destinationPlateBarcode ?? "",
          1
        );
        dispatch(
          Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO({
            index: 0,
            key: "plateBarcode",
            value: barcodes[0],
          })
        );
      });
    }
  }, [sourcePlateInfo[0].plateBarcode]);

  useEffect(() => {
    const uniqueSourcePlateTypes = [
      ...new Set(
        sourcePlateInfo
          .map((e) => e.labwareTypeCode)
          .filter((labware) => labware !== "")
      ),
    ];
    if (
      sourcePlateInfo[0].plateBarcode !== "" &&
      destPlateInfo.labwareTypeCode !== ""
    ) {
      const sourcePlateBarcode = sourcePlateInfo[0].plateBarcode;
      getAssociatedBarcodes({
        variables: {
          sourcePlateBarcode: sourcePlateBarcode,
          destPlateCode: extractPlateCode(destPlateInfo.plateBarcode),
          hasMultipleSourcePlateTypes: uniqueSourcePlateTypes.length > 1,
          hasMultipleDestPlateTypes: false,
          has384DeadPlate: is384W_CCU_Plate(deadPlateType),
        },
      }).then((res) => {
        dispatch(
          Twelve24TCToolActions.UPDATE_AUX_PLATEBARCODES({
            deadPlateBarcode:
              res.data!.intermediateDeadAndDestPlateBarcodes!.deadPlateBarcode!,
          })
        );
      });
    }
  }, [sourcePlateInfo, destPlateInfo, deadPlateType]);

  const [getExistingMethodReservation, { data: methodReservation }] =
    useGetExistingMethodReservationLazyQuery({
      fetchPolicy: "network-only",
    });
  useEffect(() => {
    BackdropOpen({
      open: loading,
      header: "Generating Worklist",
      subText: "Do not reload page",
      size: 60,
    });
  }, [loading]);
  const handleWorklistPreviewClose = (
    generateWorklist: boolean,
    worklist: Worklist[],
    reservationTime: Date
  ) => {
    const methodReservationInput = {
      methodId: 145,
      selectedSystem: twelve24TCToolInternalState.methodSettings.selectedSystem,
      sourcePlateInfo: sourcePlateInfo.map((plate) => ({
        plateBarcode: plate.plateBarcode,
        plateType: plate.labwareTypeCode,
      })),
      destPlateInfo: [
        {
          plateBarcode: destPlateInfo.plateBarcode,
          plateType: destPlateInfo.labwareTypeCode,
        },
      ],
      intPlateBarcode: "",
      intPlateBarcode2: "",
      intPlateBarcode3: "",
      deadPlateBarcode: deadPlateBarcode,
      worklist: worklist,
      reservationTime,
    };
    if (generateWorklist) {
      reserveMethod({
        variables: {
          methodReservationInput: methodReservationInput,
        },
      }).then((res) => {
        window.location.href = `${GetUrl()}/api/ThawWorklist/DownloadWorklist?methodReservationID=${
          res.data?.reserveMethod?.methodReservationId
        }`;
        getExistingMethodReservation({
          variables: {
            where: {
              isCompleted: { eq: false },
              methodReservationsPlateBarcodes: {
                some: { plateBarcode: { eq: sourcePlateInfo[0].plateBarcode } },
              },
            },
          },
        });
      });
    }
    setWorklistPreviewOpen(false);
  };

  return (
    <React.Fragment>
      <div
        style={{
          height: "calc(100vh - 200px)",
          display: "grid",
          gridTemplate: "auto / 1fr 2fr",
        }}
      >
        <Twelve24TCToolSidebar deadPlateInfo={deadPlateTypes} />

        <Box sx={{ width: "95%", height: "100%" }}>
          <div style={{ display: "flex" }}>
            <React.Fragment>
              <SourcePlatesContainer sourcePlateInfo={sourcePlateTypes} />
              <DestinationPlatesContainer destPlateInfo={destPlateTypes} />
            </React.Fragment>
          </div>
        </Box>
      </div>
      <NewWorklistPreview
        isOpen={twelve24TCToolInternalState.worklistPreviewOpen}
        worklist={twelve24TCToolInternalState.worklist}
        setWorklist={setWorklist}
        handleClose={handleWorklistPreviewClose}
      />

      <ConfirmDialog leftButtonText="No" rightButtonText="Yes" />
    </React.Fragment>
  );
};
