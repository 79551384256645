import { DateRange } from "@mui/lab";
import {
  TicketFilterInput,
  TicketFragment,
} from "graphql/generated/schema-types";
import { GetDayRange } from "helpers/date-helpers";
import _ from "lodash";
import { Filters } from "react-table";

export const TicketFilterReducer = (tableFilters: Filters<TicketFragment>) => {
  const where: TicketFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "ticketId":
        if (parseInt(tableFilter.value) > 0)
          where!.and!.push({
            ticketId: { eq: parseInt(tableFilter.value) },
          });
        break;
      case "title":
        where!.and!.push({
          title: { contains: tableFilter.value },
        });
        break;
      case "priority":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            priority: {
              or: tableFilter.value.map((val: string) => ({ contains: val })),
            },
          });
        else where!.and!.filter((entry) => !entry.priority);
        break;
      case "status":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            status: { in: tableFilter.value },
          });
        else where!.and!.filter((entry) => !entry.status);
        break;
      case "createdByUserId":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            createdByUserId: {
              in: tableFilter.value,
            },
          });
        else where!.and!.filter((entry) => !entry.createdByUserId);
        break;
      case "ticketType.userGroup.groupName" as keyof TicketFragment:
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            asset: {
              assetType: {
                userGroupId: {
                  in: tableFilter.value,
                },
              },
            },
          });
        else
          where!.and!.filter((entry) => !entry.asset?.assetType?.userGroupId);
        break;
      case "ticketType.ticketType" as keyof TicketFragment:
        where!.and!.push({
          ticketType: {
            ticketType: { contains: tableFilter.value },
          },
        });
        break;
      case "asset.asset" as keyof TicketFragment:
        where!.and!.push({
          asset: {
            asset: { contains: tableFilter.value },
          },
        });
        break;
      case "ticketAssignees":
        where!.and!.push({
          ticketAssignees: {
            some: {
              userId: { in: tableFilter.value },
            },
          },
        });
        break;
      case "asset.assetType.assetType" as keyof TicketFragment:
        where!.and!.push({
          asset: {
            assetType: {
              assetTypeId: { eq: tableFilter.value },
            },
          },
        });
        break;
      case "dateCreated":
        let range = tableFilter.value as DateRange<unknown>;
        if (range.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.dateCreated);
          break;
        }
        if (range.some((date) => !date)) {
          break;
        }
        if (_.isEqual(range[0], range[1]))
          range = GetDayRange(tableFilter.value[0]);
        where!.and!.push({
          dateCreated: { gt: range[0] },
        });
        where!.and!.push({
          dateCreated: { lt: range[1] },
        });
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
