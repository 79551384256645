import { Button, Menu, MenuItem, Paper } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import {
  RowLetterMapping,
  wellListFieldsArray,
  wellListFieldsMapping,
} from "./constants";
import { IWell, Plate } from "./helpers";

const ButtonContainer = styled(Button)`
  border: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? "1px solid white"
      : "1px solid rgba(204, 40, 40)"} !important;
  color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "white" : "rgba(204, 40, 40)"} !important;

  &:focus {
    outline: none !important;
  }
`;
const Link = styled(CSVLink)`
  color: #000;
  text-decoration: none;
`;

const ExportContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function ExportCSVButton({ plate }: { plate: Plate }) {
  const { plateBarcode, run, lastScanDate, numCols, wells } = plate;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const linkRefs = useRef<
    Array<null | (CSVLink & { link?: HTMLAnchorElement })>
  >([null, null]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (idx: number) => () => {
    linkRefs.current[idx]?.link?.click();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columnHeader = () => {
    const header: string[] = [];
    for (let i = 1; i <= numCols; i++) {
      header.push("" + i);
    }
    return header;
  };

  const header = (format: string) =>
    format === "Plate Map"
      ? ["Row/Col"].concat(columnHeader())
      : [
          "Plate Barcode",
          ...wellListFieldsArray.map((field) => wellListFieldsMapping[field]),
          "Run",
          "Last Scan Date",
        ];

  let data: (string | null)[][] = [];

  const formatData = (format: string) => {
    if (format === "Plate Map") {
      data = wells.map((row: IWell[], idx) => [
        RowLetterMapping[idx],
        ...row.map((well) => well.sampleBarcode),
      ]);
    } else {
      const transposed = wells[0].map((_, colIdx) =>
        wells.map((row) => row[colIdx])
      );
      data = transposed
        .flat()
        .filter((well) => !well.empty)
        .map((well) => [
          plateBarcode,
          ...wellListFieldsArray.map((field) => "" + (well[field] ?? "")),
          run,
          lastScanDate,
        ]);
    }
    data.unshift(header(format));
    return data;
  };

  return (
    <ExportContainer>
      <Paper elevation={2}>
        <ButtonContainer aria-haspopup="true" onClick={handleClick}>
          Export Plate to CSV
          <ArrowDropDown />
        </ButtonContainer>
      </Paper>
      <Menu
        aria-controls="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleMenuClick(0)}
          style={{ justifyContent: "start" }}
        >
          as Plate Map
          <Link
            filename={"" + plateBarcode + "PlateMap.csv"}
            target={"Downloads"}
            data={formatData("Plate Map")}
            ref={(e: CSVLink) => (linkRefs.current[0] = e)}
            style={{ display: "none" }}
          ></Link>
        </MenuItem>
        <MenuItem
          onClick={handleMenuClick(1)}
          style={{ justifyContent: "start" }}
        >
          as List
          <Link
            filename={"" + plateBarcode + "List.csv"}
            target={"Downloads"}
            data={formatData("List")}
            ref={(e: CSVLink) => (linkRefs.current[1] = e)}
            style={{ display: "none" }}
          ></Link>
        </MenuItem>
      </Menu>
    </ExportContainer>
  );
}
