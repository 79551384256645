import { CssBaseline } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AppThemeProvider } from "App.theme";
import { CustomApolloProvider } from "CustomApolloProvider";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import AuthService from "services/AuthService";
import App from "./App";
import "./index.scss";
import { ReduxStore } from "./redux-store";

ReactDOM.render(
  <Provider store={ReduxStore}>
    <React.StrictMode>
      <CustomApolloProvider>
        <AppThemeProvider>
          <CssBaseline>
            <GoogleOAuthProvider clientId={AuthService.getGoogleClientId()}>
              <App />
            </GoogleOAuthProvider>
          </CssBaseline>
        </AppThemeProvider>
      </CustomApolloProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
