import { makeVar } from "@apollo/client";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { FolderStructure, Run, RunTask } from "graphql/generated/schema-types";

export type DeleteDialogType =
  | {
      typeName?: Run["__typename"];
      runId?: number;
      folderId?: never;
      open?: boolean;
    }
  | {
      typeName?: RunTask["__typename"];
      runId?: never;
      folderId?: never;
      open?: boolean;
    }
  | {
      typeName?: FolderStructure["__typename"];
      runId?: never;
      folderId?: number;
      open?: boolean;
    };

export interface IRunsModalState {
  selectedTasks?: Array<number>;
  currentModal?: RunTaskModals;
  selectedRunId?: number | null;
  selectedFolderId?: number | null;
  modalOpen?: boolean;
  editedFieldValuePairs?: [string, unknown][];
  taskSummaryDialogOpen?: {
    runActivityId?: number;
    open?: boolean;
  };
  deleteDialog?: DeleteDialogType;
}

export interface IRunsModalAction {
  type: RunsModalActions;
  payload?: IRunsModalState;
}

export const RunsModalInitialState: IRunsModalState = {
  selectedTasks: [],
  currentModal: RunTaskModals.None,
  taskSummaryDialogOpen: { open: false },
};

export const RunsModalState = makeVar(RunsModalInitialState);
