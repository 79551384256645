import { ApolloQueryResult } from "@apollo/client/core";
import { ConfirmDialog } from "enums/ConfirmDialog";
import {
  GetIncomingSampleTasksQuery,
  GetIncomingSampleTasksQueryVariables,
  TaskStatusEnum,
  TaskTypeEnum,
  useDeleteTasksMutation,
  useUpdateCollectionTasksMutation,
} from "graphql/generated/schema-types";
import { useState } from "react";
import { Item, Menu, Submenu } from "react-contexify";
import { IncomingSampleActions, useIncomingSampleState } from "../../state";
import { IncomingSamplesConfirmDialog } from "../ModelsAndDialogs/ConfirmDialog";
import { IncomingSampleModals } from "../ModelsAndDialogs/modal.enum";
import { IncomingSampleTaskContextMenuHidden } from "../Tables/Tasks/handlers";

export function IncomingSampleTaskContextMenu({
  refetch,
}: {
  refetch: (
    variables?: Partial<GetIncomingSampleTasksQueryVariables>
  ) => Promise<ApolloQueryResult<GetIncomingSampleTasksQuery>>;
}): JSX.Element {
  const {
    incomingSampleState: { selectedTasks },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  const [deleteTasks] = useDeleteTasksMutation();
  const [updateTasks] = useUpdateCollectionTasksMutation();
  const [loading, setLoading] = useState(false);

  const setStatus = (status: TaskStatusEnum) =>
    updateTasks({
      variables: {
        inputs: selectedTasks!.map((taskId) => ({
          incomingSampleTaskId: taskId,
          status: status,
        })),
      },
    });
  return (
    <>
      <IncomingSamplesConfirmDialog
        loading={loading}
        deleteMutation={() => {
          setLoading(true);
          return deleteTasks({
            variables: {
              taskIDs: selectedTasks!,
              taskType: TaskTypeEnum.IncomingSample,
            },
          })
            .then(() => refetch())
            .then(() => {
              setLoading(false);
              incomingSampleDispatch({
                type: IncomingSampleActions.CLOSE_DIALOG,
              });
            });
        }}
      />
      <Menu
        id={"IncomingSampleTaskTableContextMenu"}
        onHidden={() => {
          setTimeout(() => IncomingSampleTaskContextMenuHidden(true), 200);
        }}
      >
        <Submenu label={"Mark as"} disabled>
          <Item onClick={() => setStatus(TaskStatusEnum.Completed)}>
            Complete
          </Item>
          <Item onClick={() => setStatus(TaskStatusEnum.Scheduled)}>
            Scheduled
          </Item>
          <Item onClick={() => setStatus(TaskStatusEnum.InProgress)}>
            In Progress
          </Item>
          <Item onClick={() => setStatus(TaskStatusEnum.Overdue)}>Overdue</Item>
          <Item onClick={() => setStatus(TaskStatusEnum.Cancelled)}>
            Cancelled
          </Item>
        </Submenu>
        <Item
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.OPEN_MODAL,
              payload: {
                currentModal:
                  selectedTasks!.length === 1
                    ? IncomingSampleModals.UpdateTask
                    : IncomingSampleModals.UpdateMultipleTasks,
              },
            })
          }
        >
          Edit
        </Item>
        <Item
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.OPEN_CONFIRM_DIALOG,
              payload: {
                confirmDialogState: {
                  open: true,
                  dialogType: ConfirmDialog.Delete,
                  typename: "CollectionTask",
                },
              },
            })
          }
        >
          Delete
        </Item>
      </Menu>
    </>
  );
}
