import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import {
  FreezeDownRackTypes,
  OverflowTypes,
} from "features/RunTemplate/components/Forms/Create/Task/constants";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { getIntPlateTypeInfo } from "features/WorklistTools/shared/WorklistHelpers";
import { FormikProps } from "formik";
import {
  RunTaskDetailInput,
  RunTaskInput,
  RunTemplateTaskInput,
  useGetArrayMethodLabwareTypesQuery,
} from "graphql/generated/schema-types";
import React from "react";
import { RunActivityDetailFormikTypeParser } from "shared-components/FormFields/MethodFields/helpers";
import {
  CollapseSection,
  NumberFieldWrapper,
  TaskDetailsNumberFields,
} from "shared-components/FormFields/MethodFields/MethodFormReducer";

export function XTYCustomPassageFields({
  props,
  controlled,
  disabled,
}: {
  props:
    | FormikProps<RunTaskInput>
    | FormikProps<RunTemplateTaskInput>
    | FormikProps<RunTaskDetailInput>;
  controlled?: true | never;
  disabled?: boolean;
}): JSX.Element {
  const { setFieldValue } = props;

  const {
    assertedErrors,
    assertedValues,
    setFieldValuePrefix,
    assertedTouched,
  } = RunActivityDetailFormikTypeParser(props);

  const { data: acceptablePlateTypes } = useGetArrayMethodLabwareTypesQuery({
    fetchPolicy: "network-only",
    variables: {
      where: {
        arraySystemAutomationMethodId: {
          eq: 131, //XTY Passage ASAM ID
        },
      },
    },
  });

  const acceptableIntPlates =
    acceptablePlateTypes && getIntPlateTypeInfo(acceptablePlateTypes);

  return (
    <CollapseSection>
      <TaskDetailsNumberFields>
        <NumberFieldWrapper>
          <TextField
            variant="outlined"
            margin="none"
            id="daughterWellNumber"
            fullWidth
            label="Daughter Well No."
            type="number"
            disabled={disabled}
            inputProps={{
              "data-testid": "daughterWellNumber",
            }}
            value={
              controlled ? assertedValues?.daughterWellNumber ?? "" : undefined
            }
            onChange={(e) => {
              setFieldValue(
                setFieldValuePrefix + "daughterWellNumber",
                e.target.value ? parseInt(e.target.value) : null
              );
            }}
            helperText={
              assertedErrors?.daughterWellNumber &&
              assertedTouched?.daughterWellNumber &&
              assertedErrors?.daughterWellNumber
            }
            error={
              !!(
                assertedErrors?.daughterWellNumber &&
                assertedTouched?.daughterWellNumber &&
                assertedErrors?.daughterWellNumber
              )
            }
          />
        </NumberFieldWrapper>
        <NumberFieldWrapper>
          <TextField
            variant="outlined"
            fullWidth
            margin="none"
            id="maxCryovial"
            inputProps={{
              "data-testid": "maxCryovial",
            }}
            label="Max Cryovial"
            disabled={disabled}
            value={controlled ? assertedValues?.maxCryovial ?? "" : undefined}
            type="number"
            onChange={(e) => {
              setFieldValue(
                setFieldValuePrefix + "maxCryovial",
                e.target.value ? parseInt(e.target.value) : null
              );
            }}
            helperText={
              assertedErrors?.maxCryovial &&
              assertedTouched?.maxCryovial &&
              assertedErrors?.maxCryovial
            }
            error={
              !!(
                assertedErrors?.maxCryovial &&
                assertedTouched?.maxCryovial &&
                assertedErrors?.maxCryovial
              )
            }
          />
        </NumberFieldWrapper>
        <NumberFieldWrapper>
          <TextField
            variant="outlined"
            margin="none"
            fullWidth
            id="minCryovial"
            inputProps={{
              "data-testid": "minCryovial",
            }}
            label="Min Cryovial"
            disabled={disabled}
            type="number"
            value={controlled ? assertedValues?.minCryovial ?? "" : undefined}
            onChange={(e) => {
              setFieldValue(
                setFieldValuePrefix + "minCryovial",
                e.target.value ? parseInt(e.target.value) : null
              );
            }}
            helperText={
              assertedErrors?.minCryovial &&
              assertedTouched?.minCryovial &&
              assertedErrors?.minCryovial
            }
            error={
              !!(
                assertedErrors?.minCryovial &&
                assertedTouched?.minCryovial &&
                assertedErrors?.minCryovial
              )
            }
          />
        </NumberFieldWrapper>
      </TaskDetailsNumberFields>
      <TaskDetailsNumberFields style={{ marginTop: "30px" }}>
        <NumberFieldWrapper>
          <TextField
            variant="outlined"
            fullWidth
            margin="none"
            id="seedCellCount"
            inputProps={{
              "data-testid": "seedCellCount",
            }}
            disabled={disabled}
            label="Seeding Cell Count"
            type="number"
            value={controlled ? assertedValues?.seedCellCount ?? "" : undefined}
            onChange={(e) => {
              setFieldValue(
                setFieldValuePrefix + "seedCellCount",
                e.target.value ? parseInt(e.target.value) : null
              );
            }}
            helperText={
              assertedErrors?.seedCellCount &&
              assertedTouched?.seedCellCount &&
              assertedErrors?.seedCellCount
            }
            error={
              !!(
                assertedErrors?.seedCellCount &&
                assertedTouched?.seedCellCount &&
                assertedErrors?.seedCellCount
              )
            }
          />
        </NumberFieldWrapper>
        <NumberFieldWrapper>
          <TextField
            fullWidth
            variant="outlined"
            margin="none"
            disabled={disabled}
            id="freezeCellCount"
            inputProps={{
              "data-testid": "freezeCellCount",
            }}
            label="Freezing Cell Count"
            type="number"
            value={
              controlled ? assertedValues?.freezeCellCount ?? "" : undefined
            }
            onChange={(e) => {
              setFieldValue(
                setFieldValuePrefix + "freezeCellCount",
                e.target.value ? parseInt(e.target.value) : null
              );
            }}
            helperText={
              assertedErrors?.freezeCellCount &&
              assertedTouched?.freezeCellCount &&
              assertedErrors?.freezeCellCount
            }
            error={
              !!(
                assertedErrors?.freezeCellCount &&
                assertedTouched?.freezeCellCount &&
                assertedErrors?.freezeCellCount
              )
            }
          />
        </NumberFieldWrapper>
        <FormControl style={{ width: "30%" }} variant="outlined">
          <InputLabel htmlFor="overflowVialInput">Overflow Vials</InputLabel>
          <Select
            native
            disabled={disabled}
            value={assertedValues?.overflowVials ?? ""}
            onChange={(e) =>
              setFieldValue(
                setFieldValuePrefix + "overflowVials",
                e.target.value
              )
            }
            label="Overflow Vials"
            inputProps={{
              name: "overflowVialType",
              id: "overflowVialsInput",
              "data-testid": "overflowVials",
            }}
          >
            <option aria-label="None" value="" />
            {OverflowTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormControl>
      </TaskDetailsNumberFields>
      <TaskDetailsNumberFields style={{ marginTop: "30px" }}>
        <FormControl style={{ width: "30%" }} variant="outlined">
          <InputLabel htmlFor="freezeDownRackTypeInput">
            Freeze Down Rack
          </InputLabel>
          <Select
            native
            disabled={disabled}
            value={assertedValues?.freezeDownRackType ?? ""}
            onChange={(e) =>
              setFieldValue(
                setFieldValuePrefix + "freezeDownRackType",
                e.target.value
              )
            }
            label="Freeze Down Rack"
            inputProps={{
              name: "freezeDownRackType",
              id: "freezeDownRackTypeInput",
              "data-testid": "freezeDownRackType",
            }}
          >
            <option aria-label="None" value="" />
            {FreezeDownRackTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: "30%" }} variant="outlined">
          <TextField
            disabled={disabled}
            value={assertedValues?.destPlateCode ?? ""}
            onChange={(e) =>
              setFieldValue(
                setFieldValuePrefix + "destPlateCode",
                e.target.value
              )
            }
            label="Destination Plate"
            inputProps={{
              name: "destinationPlate",
              id: "destinationPlateInput",
              "data-testid": "destPlateCode",
            }}
            helperText={
              assertedErrors?.destPlateCode &&
              assertedTouched?.destPlateCode &&
              assertedErrors?.destPlateCode
            }
            error={
              !!(
                assertedErrors?.destPlateCode &&
                assertedTouched?.destPlateCode &&
                assertedErrors?.destPlateCode
              )
            }
          />
        </FormControl>
        <FormControl style={{ width: "30%" }} variant="outlined">
          <PlateType
            platePosition={PlatePosition.Intermediate}
            isDisabled={false}
            acceptedPlateTypes={
              (acceptablePlateTypes &&
                getIntPlateTypeInfo(acceptablePlateTypes)) ??
              []
            }
            plateType={
              acceptableIntPlates?.find(
                (e) =>
                  e.labwareTypeId === assertedValues?.intermediateLabwareTypeId
              )?.labwareTypeCode ?? ""
            }
            setPlateType={(plateType) =>
              setFieldValue(
                setFieldValuePrefix + "intermediateLabwareTypeId",
                plateType.labwareTypeId
              )
            }
          />
        </FormControl>
        <NumberFieldWrapper>
          <FormControlLabel
            control={
              <Switch
                name="createMystplate"
                onChange={(e) => {
                  setFieldValue(
                    setFieldValuePrefix + "createMystplate",
                    e.target.checked
                  );
                }}
              />
            }
            data-testid="createMystplate"
            disabled={disabled}
            checked={assertedValues?.createMystplate ?? false}
            label={"Create Myst Plate"}
          />
        </NumberFieldWrapper>
      </TaskDetailsNumberFields>
    </CollapseSection>
  );
}
