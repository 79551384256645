import {
  Maybe,
  Scalars,
  UpdateIncomingSampleCollectionInput,
} from "../../graphql/generated/schema-types";

export class UpdateIncomingSampleCollectionInputStub
  implements UpdateIncomingSampleCollectionInput
{
  projectId: Scalars["Int"];
  dateTimeOfArrival?: Maybe<Scalars["DateTime"]>;
  dateTimeOfCollection?: Maybe<Scalars["DateTime"]>;
}
