import { ApolloQueryResult } from "@apollo/client";
import {
  GetRepeatingTasksSummaryQuery,
  GetRepeatingTasksSummaryQueryVariables,
  PersonalTasksRecurringTask,
  SortEnumType,
} from "graphql/generated/schema-types";
import { MutateState } from "helpers/object-helpers";
import { FetchMore } from "interfaces/FetchMore";
import React, { Dispatch, SetStateAction } from "react";
import { Filters, SortingRule } from "react-table";
import { ReactTablePaginationState } from "shared-components/Table/ReactTable";
import { RepeatingTaskSummaryFilterReducer } from "./filter";

export const getPageChangeHandler =
  (
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ],
    fetchMore?: FetchMore<
      GetRepeatingTasksSummaryQueryVariables,
      GetRepeatingTasksSummaryQuery
    >,
    endCursor?: string | null,
    variables?: GetRepeatingTasksSummaryQueryVariables
  ): ((
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void) =>
  (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (paginationState.page < newPage && fetchMore) {
      console.log(newPage);
      fetchMore({
        variables: {
          ...variables,
          after: endCursor,
        },
      })
        .then((datas: any) => {
          setPaginationState({ ...paginationState, page: newPage });
          console.log(datas);
        })
        .catch((err) => console.log(err));
    } else if (paginationState.page > newPage) {
      setPaginationState({ ...paginationState, page: newPage });
    }
  };

export const getFilterHandler =
  (
    variables: GetRepeatingTasksSummaryQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetRepeatingTasksSummaryQueryVariables>
        ) => Promise<ApolloQueryResult<GetRepeatingTasksSummaryQuery>>)
      | undefined
  ) =>
  async (filters: Filters<PersonalTasksRecurringTask>): Promise<void> => {
    const _state = MutateState(variables, (draft) => {
      draft!.where = RepeatingTaskSummaryFilterReducer(filters);
    });
    if (refetch && _state.hasChanged) {
      await refetch(_state.newValue);
    }
  };

export const getSortHandler =
  (
    variables: GetRepeatingTasksSummaryQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetRepeatingTasksSummaryQueryVariables>
        ) => Promise<ApolloQueryResult<GetRepeatingTasksSummaryQuery>>)
      | undefined,
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ) =>
  async (sorts: SortingRule<PersonalTasksRecurringTask>[]): Promise<void> => {
    if (sorts.length > 0 && refetch) {
      await refetch({
        ...variables,
        order_by: sorts.map((sortRule) => ({
          [sortRule.id]: sortRule.desc ? SortEnumType.Desc : SortEnumType.Asc,
        })),
      });
    } else if (refetch) {
      const _state = MutateState(variables, (draft) => {
        draft!.order_by = undefined;
      });
      if (_state.hasChanged) await refetch(_state.newValue);
    }
    if (paginationState.page !== 0)
      setPaginationState({ ...paginationState, page: 0 });
  };
