import {
  Maybe,
  Scalars,
  UpdateIncomingSampleTypeInput,
} from "graphql/generated/schema-types";

export class IncomingSampleTypeStub implements UpdateIncomingSampleTypeInput {
  dateToProcess?: Maybe<Scalars["DateTime"]>;
  notes?: Maybe<Scalars["String"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  sampleTypeId?: Maybe<Scalars["Int"]>;
}
