import { AxiosResponse } from "axios";
import axios from "./AxiosService";

interface ThawWorklistService {
  getRackInfo(
    plateId: number,
    plateBarcode: string,
    onlyActiveWells: boolean
  ): Promise<AxiosResponse>;
  getLabwareTypeInfo(labwareTypeCode: string): Promise<AxiosResponse>;
}

export default {
  async getRackInfo(
    plateId: number,
    plateBarcode: string,
    onlyActiveWells: boolean
  ) {
    const params = new URLSearchParams();
    params.append("plateId", plateId.toString());
    params.append("plateBarcode", plateBarcode.toString());
    params.append("onlyActiveWells", onlyActiveWells.toString());

    return axios.get("api/ThawWorklist/GetRackInfo", { params });
  },

  async getLabwareTypeInfo(labwareTypeCode: string) {
    const params = new URLSearchParams();
    params.append("labwareTypeCode", labwareTypeCode);

    return axios.get("api/ThawWorklist/GetLabwareTypeInfo", { params });
  },
} as ThawWorklistService;
