import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { AddRackOptions } from "../../constants";
interface FillRackSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const FillRackSelector = ({
  values,
  setFieldValue,
}: FillRackSelectorProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.fillRack}
            onChange={(e) => setFieldValue("fillRack", e.target.checked)}
          />
        }
        label="Fill Rack"
      />
    </FormGroup>
  );
};
