import { Confirm_Action } from "./actions";
import { IConfirmAction, IConfirmState } from "./initial-state";

export function ConfirmDialogReducer(
  state: IConfirmState,
  action: IConfirmAction
): IConfirmState {
  switch (action.type) {
    case Confirm_Action.SET_CONFIRM:
      return {
        ...state,
        prompt: action.payload!.prompt,
        isOpen: action.payload!.isOpen,
        proceed: action.payload!.proceed,
        cancel: action.payload!.cancel,
        bullets: action.payload!.bullets
      };
    case Confirm_Action.SET_OPEN:
      return {
        ...state,
        isOpen: action.payload!.isOpen,
      };
    default:
      return state;
  }
}
