import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface TwoOptionDialogProps {
  title: string;
  isOpen: boolean;
  rightButtonText: string;
  leftButtonText: string;
  handleClose: (continueAction: boolean) => void;
}

export const TwoOptionDialog = ({
  title,
  isOpen,
  rightButtonText,
  leftButtonText,
  handleClose,
}: TwoOptionDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={() => handleClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent></DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>{leftButtonText}</Button>
        <Button onClick={() => handleClose(true)}>{rightButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};
