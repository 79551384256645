import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import {
  IWorklistToolInternalState,
  WorklistToolInternalState,
} from "./initial-state";
import { WorklistToolInternalReducer } from "./reducer";

export function useWorklistToolStateProvider(): {
  worklistToolInternalState: IWorklistToolInternalState;
  worklistToolDispatch: IApolloDispatch<any>;
} {
  const worklistToolInternalState = useReactiveVar(WorklistToolInternalState);
  const worklistToolDispatch = useMemo(
    () =>
      ApolloStateDispatch(
        WorklistToolInternalState,
        WorklistToolInternalReducer
      ),
    []
  );
  return {
    worklistToolInternalState,
    worklistToolDispatch,
  };
}
