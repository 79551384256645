import { Button } from "@mui/material";
import { CloudDownload, Delete } from "@mui/icons-material";
import { useRunsModalState } from "features/Runs/state/hooks";
import { CeligoPlateMetadata } from "features/WorklistTools/CeligoScan/Dialog/state";
import { useDeleteRunActivityMethodReservationMutation } from "graphql/generated/schema-types";
import { useGetRunTask } from "hooks/cacheAccessHooks";
import React, { useCallback, useState } from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;
export const Buttons = ({
  alreadyReserved,
  hasActivePlates,
  isSubmitting,
}: {
  alreadyReserved: boolean;
  hasActivePlates: boolean;
  isSubmitting: boolean;
}) => {
  const [deleteReservation] = useDeleteRunActivityMethodReservationMutation({
    awaitRefetchQueries: true,
  });
  const {
    runsModalState: { taskSummaryDialogOpen },
  } = useRunsModalState();
  const runActivity = useGetRunTask(taskSummaryDialogOpen?.runActivityId);
  const [deleting, setDeleting] = useState(false);
  const metadata = CeligoPlateMetadata();

  const handleDelete = useCallback(() => {
    setDeleting(true);
    deleteReservation({
      variables: {
        deleteInput: {
          primaryKey:
            runActivity?.methodReservations?.[0]?.methodReservationId ?? 0,
        },
      },
    })
      .then(() => CeligoPlateMetadata([...metadata]))
      .finally(() => setDeleting(false));
  }, [deleteReservation, metadata, runActivity?.methodReservations]);
  return (
    <>
      {hasActivePlates ? (
        <ButtonContainer>
          {alreadyReserved ? (
            <Button
              onClick={handleDelete}
              disabled={deleting}
              variant={"contained"}
              color={"secondary"}
              endIcon={<Delete />}
            >
              Delete Reservation
            </Button>
          ) : (
            <React.Fragment />
          )}
          <Button
            variant={"contained"}
            color={"primary"}
            type={"submit"}
            disabled={isSubmitting || deleting}
            endIcon={
              alreadyReserved ? <CloudDownload fontSize={"large"} /> : undefined
            }
          >
            {alreadyReserved ? "Download Worklist" : "Create Reservation"}
          </Button>
        </ButtonContainer>
      ) : (
        <React.Fragment />
      )}
    </>
  );
};
