import { Accordion, Paper, Typography } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { StageCard } from "features/RunTemplate/components/Timeline/StageCard";
import { TaskCard } from "features/RunTemplate/components/Timeline/TaskCard/TaskCard";
import {
  DayOfWeek,
  RunTemplateStage,
  RunTemplateTask,
  RunTemplateTreeNode,
} from "graphql/generated/schema-types";
import React from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { RunTemplateAction } from "../../state/actions";
import { useRunTemplateStateProvider } from "../../state/StateProvider";
import { ChildTemplateCard } from "./ChildTemplateCard";

const CardWidth = "560px";
export const TimelineCard = styled(Paper)<{
  color?: string;
}>`
  padding: 6px 6px;
  background-color: ${({ color, theme }) =>
    color ?? theme.palette.primary.main} !important;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: ${CardWidth};
`;
export const StyledAccordion = styled(Accordion)`
  max-width: ${CardWidth};
`;
export const TimelineContentHeader = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
`;
export const TimelineContentText = styled.div`
  color: white;
`;
export const StyledTimelineItem = styled(TimelineItem)`
  &&:before {
    display: none !important;
  }
`;
export const StyledTimelineDot = styled(TimelineDot)`
  background-color: ${({ theme }) => theme.palette.secondary.main} !important;
`;
export const StyledTimelineConnector = styled(TimelineConnector)`
  background-color: ${({ theme }) => theme.palette.secondary.main} !important;
`;
export const dayOfWeekReducer = (dayOfWeek?: DayOfWeek | null) => {
  switch (dayOfWeek) {
    case DayOfWeek.Sunday:
      return "Sun";
    case DayOfWeek.Monday:
      return "Mon";
    case DayOfWeek.Tuesday:
      return "Tue";
    case DayOfWeek.Wednesday:
      return "Wed";
    case DayOfWeek.Thursday:
      return "Thu";
    case DayOfWeek.Friday:
      return "Fri";
    case DayOfWeek.Saturday:
      return "Sat";
    default:
      return "";
  }
};
export const TimelineNode: React.FC<{
  entry: RunTemplateTask | RunTemplateStage | RunTemplateTreeNode;
}> = ({ entry }) => {
  const getWeekScheduleInfo = (task: RunTemplateTask) => {
    const duration = Math.floor(
      task.runTemplatesRepeatDaysOfWeeks?.[0]?.numberOfWeeks ?? 0
    );
    return `day ${task.dayScheduled} (${task.runTemplatesRepeatDaysOfWeeks
      ?.map((dow) => dayOfWeekReducer(dow?.dayOfWeek))
      .join(", ")})\n${duration} weeks`;
  };
  const { runTemplateDispatch } = useRunTemplateStateProvider();
  return (
    <StyledTimelineItem>
      {entry.__typename === "RunTemplateStage" ? (
        <>
          <TimelineOppositeContent>
            <StageCard entry={entry} />
          </TimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineDot color={"secondary"}>
              <AssignmentIcon />
            </StyledTimelineDot>
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContent />
        </>
      ) : entry.__typename === "RunTemplateTask" ? (
        <>
          <TimelineOppositeContent>
            <Typography color="textPrimary" whiteSpace={"pre-wrap"}>
              {(entry?.runTemplatesRepeatDaysOfWeeks?.length ?? 0) > 0
                ? getWeekScheduleInfo(entry)
                : `day ${entry.dayScheduled}`}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineDot />
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <TaskCard
              task={entry}
              onContextMenu={(e) => {
                e.preventDefault();
                runTemplateDispatch({
                  type: RunTemplateAction.SELECT,
                  payload: { selectedTaskId: entry.runTemplateTaskId },
                });
                contextMenu.show({
                  id: "task-node-context-menu",
                  event: e,
                  props: entry,
                });
              }}
            />
          </TimelineContent>
        </>
      ) : entry.__typename === "RunTemplateTreeNode" ? (
        <>
          <TimelineOppositeContent>{`day ${entry.dayScheduled}`}</TimelineOppositeContent>
          <TimelineSeparator>
            <StyledTimelineDot />
            <StyledTimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <ChildTemplateCard node={entry} />
          </TimelineContent>
        </>
      ) : (
        <React.Fragment />
      )}
    </StyledTimelineItem>
  );
};
