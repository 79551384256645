import { ApolloQueryResult } from "@apollo/client/core";
import _ from "lodash";
import { Dispatch, SetStateAction } from "react";
import { SortingRule } from "react-table";
import {
  IncomingSampleCollectionFragment,
  SortEnumType,
} from "graphql/generated/schema-types";
import { MutateState } from "helpers/object-helpers";
import { ReactTablePaginationState } from "../ReactTable";
import { TableQueryVariables } from "./types";

export async function ApplySort<
  TQuery,
  TVariables extends Partial<TableQueryVariables>,
  TData
>(
  variables: TVariables | undefined,
  sorts: SortingRule<TData>[],
  refetch: (
    variables?: Partial<TVariables>
  ) => Promise<ApolloQueryResult<TQuery>>,
  persistedSort?: SortingRule<TData>
) {
  const _variables = MutateState(variables, (draft) => {
    let _sorts = sorts;
    if (persistedSort) {
      _sorts = _sorts.filter((entry) => entry.id !== persistedSort.id);
      _sorts.push(persistedSort);
    }
    draft!.order = _sorts.map((sortRule) => {
      const sortObject = {};
      _.set(
        sortObject,
        sortRule.id,
        sortRule.desc ? SortEnumType.Desc : SortEnumType.Asc
      );
      return sortObject;
    });
  });
  if (_variables.hasChanged) {
    console.log("Tables Action: SORT");
    await refetch(_variables.newValue as TVariables);
  }
}

export async function ClearSort<
  TQuery,
  TVariables extends Partial<TableQueryVariables>
>(
  variables: TVariables | undefined,
  refetch: (
    variables?: Partial<TVariables>
  ) => Promise<ApolloQueryResult<TQuery>>
) {
  const _variables = MutateState(variables, (draft) => {
    draft!.order = undefined;
  });
  if (_variables.hasChanged) {
    console.log("Tables Action: CLEAR_SORT");
    await refetch(_variables.newValue as TVariables);
  }
}

export const GetSortHandler =
  <TQuery, TVariables extends Partial<TableQueryVariables>>(
    variables: TVariables | undefined,
    refetch: (
      variables?: Partial<TVariables>
    ) => Promise<ApolloQueryResult<TQuery>>,
    tuple?: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ],
    persistedSort?: SortingRule<unknown>
  ) =>
  async (
    sorts: SortingRule<IncomingSampleCollectionFragment>[]
  ): Promise<void> => {
    if (sorts.length > 0 || persistedSort) {
      await ApplySort(variables, sorts, refetch, persistedSort);
    } else {
      await ClearSort(variables, refetch);
    }
    if (tuple) {
      const [paginationState, setPaginationState] = tuple;
      if (paginationState.page !== 0)
        setPaginationState({ ...paginationState, page: 0 });
    }
  };
