import { ResetSelectedTicketTableRows } from "features/Tickets/components/Table/TicketTable";
import { TicketActions } from "features/Tickets/state/actions";
import {
  ITicketAction,
  ITicketState,
} from "features/Tickets/state/initial-state";
import produce from "immer";

export function ticketStateReducer(
  state: ITicketState,
  action: ITicketAction
): ITicketState {
  switch (action.type) {
    case TicketActions.OPEN_TICKET_DETAILS:
      return produce(state, (draft) => {
        draft.ticketSummaryDialogOpen = {
          open: true,
          ticketId: action.payload?.ticketSummaryDialogOpen?.ticketId,
        };
      });
    case TicketActions.OPEN_MODAL:
      return produce(state, (draft) => {
        draft.currentModal = action.payload?.currentModal;
        draft.modalOpen = true;
      });
    case TicketActions.CLOSE_MODAL:
      return produce(state, (draft) => {
        draft.modalOpen = false;
        if (draft!.selectedTickets!.length > 0) ResetSelectedTicketTableRows();
      });
    case TicketActions.CLOSE_DIALOG:
      return produce(state, (draft) => {
        draft.ticketSummaryDialogOpen = {
          ticketId: draft?.ticketSummaryDialogOpen?.ticketId,
          open: false,
        };
        if (draft!.selectedTickets!.length > 0) ResetSelectedTicketTableRows();
      });
    case TicketActions.SELECT:
      return produce(state, (draft) => {
        draft.selectedTickets = action.payload?.selectedTickets;
        draft.selectedSlackMessageTimestamp =
          action.payload?.selectedSlackMessageTimestamp;
      });
    default:
      return state;
  }
}
