import { FieldFunctionOptions, Reference } from "@apollo/client";
import { SafeReadonly } from "@apollo/client/cache/core/types/common";

const getById = (
  existing: SafeReadonly<never> | undefined,
  {
    toReference,
    args,
  }: FieldFunctionOptions<{
    keyField?: string;
    id?: number | string;
    typeName?: string;
  }>
): unknown => {
  let ref: null | unknown = null;

  if (args?.keyField)
    ref = toReference({
      __typename: args?.typeName,
      [args.keyField]: args?.id,
    });
  return existing ?? ref;
};

const getMultipleById = (
  existing: SafeReadonly<never> | undefined,
  {
    toReference,
    args,
  }: FieldFunctionOptions<{
    keyField?: string;
    ids?: number[];
    typeName?: string;
  }>
): unknown => {
  const refs: Reference[] = [];
  for (const id of args?.ids ?? []) {
    if (args?.keyField) {
      const ref = toReference({
        __typename: args?.typeName,
        [args?.keyField]: id,
      });
      if (ref) refs.push(ref);
    }
  }
  return existing ?? refs;
};

export const getByIdFields = {
  manualTaskType: {
    read: getById,
  },
  user: {
    read: getById,
  },
  usersFromCache: {
    read: getMultipleById,
  },
  groupsFromCache: {
    read: getMultipleById,
  },
  group: {
    read: getById,
  },
  system: {
    read: getById,
  },
  run: {
    read: getById,
  },
  runTask: {
    read: getById,
  },
  runTasksFromCache: {
    read: getMultipleById,
  },
  runTemplate: {
    read: getById,
  },
  runTemplateStage: {
    read: getById,
  },
  runTemplateTask: {
    read: getById,
  },
  method: {
    read: getById,
  },
  productionRunType: {
    read: getById,
  },
  ticket: {
    read: getById,
  },
  incomingSamplesCollection: {
    read: getById,
  },
  incomingSamplesTask: {
    read: getById,
  },
  incomingSamplesTasksFromCache: {
    read: getMultipleById,
  },
  folder: {
    read: getById,
  },
  container: {
    read: getById,
  },
  plate: {
    read: getById,
  },
  sample: {
    read: getById,
  },
};
