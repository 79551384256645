import { useMutation, useQuery } from "@apollo/client";
import { CloudDownload } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { DeleteButton } from "features/Dashboard/MethodDetails/CeligoScan/Buttons/DeleteButton";
import {
  DashboardTask,
  Maybe,
  MethodReservation,
  OperationNames,
  TaskTypeEnum,
  useGetMethodReservationByPersonalTaskIdLazyQuery,
  useGetMethodReservationByRunActivityIdLazyQuery,
} from "graphql/generated/schema-types";
import { DashboardsMutations } from "graphql/mutations/dashboards.mutations";
import { DashboardQueries } from "graphql/queries/dashboards.queries";
import { GetUrl } from "helpers/get-url";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { PlateMap } from "./PlateMap";

const StyledRunHeader = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-right: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

interface SystemOneFeedProp {
  selectedTask: DashboardTask;
}

interface SystemOneFeedRun {
  runName: string;
  reason: string;
  plates: Plate[];
}

interface Plate {
  plateID: number;
  plateBarcode: string;
}

interface IncludedWorklistGroup {
  runName: any;
  plateWorklistInfo: PlateWorklistInfo[];
}

interface PlateWorklistInfo {
  selected: boolean;
  aspirateLC: string;
  dispenseLC: string;
  plateBarcode: string;
  aspirateVol: number;
  dispenseVol: number;
}
//TODO: Split into at least 3 different components
export const SystemOneFeed = ({ selectedTask }: SystemOneFeedProp) => {
  const [disabledCreateReservation, setDisabledCreateReservation] =
    useState(false);
  const [plateMapOpen, setPlateMapOpen] = useState(false);
  const [selectedPlate, setSelectedPlate] = useState<any>({
    plateID: 0,
    plateBarcode: "",
    selectedWells: [],
  });
  const [plateWorklistInfo, setPlateWorklistInfo] = useState<any[]>([]);
  const [includedGroupWorklistInfo, setIncludedGroupWorklistInfo] = useState<
    IncludedWorklistGroup[]
  >([]);
  const [excludedPlates, setExcludedPlates] = useState<any[]>([]);
  const deleteStateTuple = useState(false);
  const [deleting] = deleteStateTuple;

  const getIncludedPlates = useQuery<any, any>(
    DashboardQueries.GET_INCLUDED_FEED_PLATES,
    {
      variables: {
        dateScheduled: selectedTask.startTime,
      },
      fetchPolicy: "network-only",
      onCompleted() {
        setPlateValues(getIncludedPlates.data.includedFeedPlates);
      },
    }
  );
  const getExcludedPlates = useQuery<any, any>(
    DashboardQueries.GET_EXCLUDED_FEED_PLATES,
    {
      variables: {
        dateScheduled: selectedTask.startTime,
      },
      fetchPolicy: "network-only",
      onCompleted() {
        setExcludedPlateValues(getExcludedPlates.data.excludedFeedPlates);
      },
    }
  );

  const [
    getMethodReservationByPersonalTaskId,
    { data: methodReservationByPersonalTask },
  ] = useGetMethodReservationByPersonalTaskIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });
  const [
    getMethodReservationByRunActivityTaskId,
    { data: methodReservationByRunActivity },
  ] = useGetMethodReservationByRunActivityIdLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [methodReservation, setMethodReservation] = useState<
    Maybe<MethodReservation> | undefined
  >();

  const [reserveFeed] = useMutation<any, any>(
    DashboardsMutations.CREATE_FEED_RESERVATION
  );

  useEffect(() => {
    if (selectedTask.taskType === TaskTypeEnum.Personal)
      setMethodReservation(
        methodReservationByPersonalTask
          ?.methodReservation?.[0] as Maybe<MethodReservation>
      );
    else if (selectedTask.taskType === TaskTypeEnum.RunTask)
      setMethodReservation(
        methodReservationByRunActivity
          ?.methodReservation?.[0] as Maybe<MethodReservation>
      );
  }, [
    methodReservationByPersonalTask?.methodReservation,
    methodReservationByRunActivity?.methodReservation,
    selectedTask.taskType,
  ]);

  useEffect(() => {
    if (selectedTask.taskType === TaskTypeEnum.Personal)
      getMethodReservationByPersonalTaskId({
        variables: {
          personalTaskId: selectedTask.taskId,
        },
      });
    else if (selectedTask.taskType === TaskTypeEnum.RunTask)
      getMethodReservationByRunActivityTaskId({
        variables: {
          runActivityId: selectedTask.taskId,
        },
      });
  }, [
    getMethodReservationByPersonalTaskId,
    getMethodReservationByRunActivityTaskId,
    selectedTask.taskId,
    selectedTask.taskType,
  ]);

  const createReservation = () => {
    const includedPlatesArray = plateWorklistInfo.map((nest) => {
      return nest.plates.map((newNest: any) => {
        return {
          plateID: newNest.plateID,
          plateBarcode: newNest.plateBarcode,
          activeWellCount: newNest.activeWellCount,
          aspirateLC: newNest.aspirateLC,
          aspirateVol: newNest.aspirateVol,
          dispenseLC: newNest.dispenseLC,
          dispenseVol: newNest.dispenseVol,
          selectedWells: newNest.selectedWells,
        };
      });
    });

    const excludedPlatesArray = excludedPlates.map((nest) => {
      return nest.plates.map((newNest: any) => {
        return {
          plateID: newNest.plateID,
          plateBarcode: newNest.plateBarcode,
          activeWellCount: newNest.activeWellCount,
        };
      });
    });
    setDisabledCreateReservation(true);
    reserveFeed({
      variables: {
        plates: {
          includedPlates: includedPlatesArray.flat(),
          excludedPlates: excludedPlatesArray.flat(),
          personalTaskId: selectedTask.taskId,
        },
        refetchQueries: [
          selectedTask.taskType === TaskTypeEnum.Personal
            ? OperationNames.Query.getMethodReservationByPersonalTaskId
            : OperationNames.Query.getMethodReservationByRunActivityId,
        ],
      },
    }).then((res: any) => {
      setDisabledCreateReservation(false);
      getMethodReservationByPersonalTaskId({
        variables: {
          personalTaskId: selectedTask.taskId,
        },
      });
      window.location.href = `${GetUrl()}/api/Dashboards/DownloadWorklist?methodReservationID=${
        res.data.reserveFeedWorklist.methodReservationID
      }`;
    });
  };

  const downloadWorklist = () => {
    window.location.href = `${GetUrl()}/api/Dashboards/DownloadWorklist?methodReservationID=${
      methodReservation?.methodReservationId
    }`;
  };

  const updatePlateWorklistInfo = (
    key: string,
    value: string,
    runIndex: number,
    plateIndex: number
  ) => {
    console.log(`${key} ${value} ${plateIndex}`);
    const newArr = [...plateWorklistInfo];
    if (key === "aspirateVol" || key === "dispenseVol") {
      newArr[runIndex].plates[plateIndex][key] = value;
      if (!isNaN(parseInt(value))) {
        parseInt(newArr[runIndex].plates[plateIndex][key]);
      }
    } else {
      newArr[runIndex].plates[plateIndex][key] = value;
    }
    setPlateWorklistInfo(newArr);
  };

  const updateIncludedRunSelection = async (runIndex: number) => {
    const plateWorklistCopy = JSON.parse(JSON.stringify(plateWorklistInfo));
    plateWorklistCopy[runIndex].selected = false;
    plateWorklistCopy[runIndex].reason = "Removed from Included Group";

    const excludedFeedsCopy = JSON.parse(JSON.stringify(excludedPlates));
    const excludedRunIndex = await getExcludedRunIndex(
      plateWorklistCopy[runIndex].runName
    );
    if (excludedRunIndex > -1) {
      plateWorklistCopy[runIndex].plates.forEach((plate: any) => {
        plate.selected = false;
        excludedFeedsCopy[excludedRunIndex].plates.push(plate);
      });
    } else {
      const removedGroup = plateWorklistCopy.splice(runIndex, 1)[0];
      excludedFeedsCopy.push(removedGroup);
    }
    setPlateWorklistInfo(plateWorklistCopy);
    setExcludedPlates(excludedFeedsCopy);
  };

  const getExcludedRunIndex = async (runName: string): Promise<number> => {
    const index = excludedPlates.findIndex((e: any) => e.runName === runName);
    return index;
  };

  const updateExcludedRunSelection = (groupIndex: number) => {
    const excludedRunsCopy = JSON.parse(JSON.stringify(excludedPlates));
    excludedRunsCopy[groupIndex].selected = true;
    excludedRunsCopy[groupIndex].reason = "";
    const removedGroup = excludedRunsCopy.splice(groupIndex, 1)[0];

    const plateWorklistCopy = JSON.parse(JSON.stringify(plateWorklistInfo));
    plateWorklistCopy.push(removedGroup);
    setPlateWorklistInfo(plateWorklistCopy);
    setExcludedPlates(excludedRunsCopy);
  };

  const updateIncludedPlateSelection = (
    groupIndex: number,
    plateIndex: number
  ) => {
    const includedPlateCopy = JSON.parse(JSON.stringify(plateWorklistInfo));
    const excludedGroupCopy = JSON.parse(JSON.stringify(excludedPlates));
    const removedPlate = includedPlateCopy[groupIndex].plates.splice(
      plateIndex,
      1
    )[0];
    removedPlate.selected = false;
    const arr = removedPlate.plateBarcode.split("_");
    let excludedGroupIndex = -1;
    setPlateWorklistInfo(includedPlateCopy);
    if (excludedGroupCopy.length > 0) {
      excludedGroupIndex = excludedGroupCopy.findIndex(
        (e: any) => e.runName === arr[0]
      );
    }
    if (excludedGroupIndex > -1) {
      excludedGroupCopy[excludedGroupIndex].plates.push(removedPlate);
      setExcludedPlates(excludedGroupCopy);
    } else {
      excludedGroupCopy.push({
        runName: arr[0],
        selected: false,
        reason: "Removed from Included Group",
        plates: [removedPlate],
      });
      setExcludedPlates(excludedGroupCopy);
    }
  };

  const updateExcludedPlateSelection = (
    groupIndex: number,
    plateIndex: number
  ) => {
    const includedPlateCopy = JSON.parse(JSON.stringify(plateWorklistInfo));
    const excludedGroupCopy = JSON.parse(JSON.stringify(excludedPlates));
    const removedPlate = excludedGroupCopy[groupIndex].plates.splice(
      plateIndex,
      1
    )[0];
    removedPlate.selected = true;
    const arr = removedPlate.plateBarcode.split("_");
    let includedGroupIndex = -1;
    setExcludedPlates(excludedGroupCopy);
    if (includedPlateCopy.length > 0) {
      includedGroupIndex = includedPlateCopy.findIndex(
        (e: any) => e.runName === arr[0]
      );
    }
    if (includedGroupIndex > -1) {
      includedPlateCopy[includedGroupIndex].plates.push(removedPlate);
      setPlateWorklistInfo(includedPlateCopy);
    } else {
      includedPlateCopy.push({
        runName: arr[0],
        selected: true,
        reason: "Removed from Included Group",
        plates: [removedPlate],
      });
      setPlateWorklistInfo(includedPlateCopy);
    }
  };

  const setPlateValues = (includedRuns: any[]) => {
    //TODO: Find a better way to do this. Nest run/plates in frontend instead?
    let values: any[] = [];
    values = JSON.parse(JSON.stringify(includedRuns));

    values.forEach((run: any, index: number) => {
      run.selected = true;
      run.plates.forEach((plate: any, plateIndex: number) => {
        plate.selected = true;
        plate.aspirateVol =
          plate.plateBarcode.includes("6W") ||
          plate.plateBarcode.includes("BPY")
            ? 2000
            : 1000;
        plate.dispenseVol =
          plate.plateBarcode.includes("6W") ||
          plate.plateBarcode.includes("BPY")
            ? 2000
            : 1000;
        plate.aspirateLC = "DEFAULT";
        plate.dispenseLC = "NOT DEFAULT";
        plate.selectedWells = [];
      });
    });
    console.log(values);
    setPlateWorklistInfo(values);
  };

  const setExcludedPlateValues = (excludedRuns: any[]) => {
    let values: any[] = [];
    values = JSON.parse(JSON.stringify(excludedRuns));
    values.forEach((run: any, index: number) => {
      run.selected = false;
      run.plates.forEach((plate: any, plateIndex: number) => {
        plate.selected = false;
        plate.aspirateVol =
          plate.plateBarcode.includes("6W") ||
          plate.plateBarcode.includes("BPY")
            ? 2000
            : 1000;
        plate.dispenseVol =
          plate.plateBarcode.includes("6W") ||
          plate.plateBarcode.includes("BPY")
            ? 2000
            : 1000;
        plate.aspirateLC = "DEFAULT";
        plate.dispenseLC = "NOT DEFAULT";
        plate.selectedWells = [];
      });
    });
    setExcludedPlates(values);
  };

  const handlePlateMapClose = (selectedWells: any[]) => {
    if (selectedWells.length > 0) {
      console.log(selectedWells);
      const arr = selectedPlate.plateBarcode.split("_");
      const runIndex = plateWorklistInfo.findIndex((e) => e.runName === arr[0]);
      const plateIndex = plateWorklistInfo[runIndex].plates.findIndex(
        (e: any) => e.plateBarcode === selectedPlate.plateBarcode
      );
      const plateWorklistCopy = JSON.parse(JSON.stringify(plateWorklistInfo));
      selectedWells = selectedWells.map((well: any) => {
        console.log(well);
        return {
          containerID: well.containerId,
          rowPos: well.rowPos,
          colPos: well.colPos,
          isActive: well.isActive,
        };
      });
      plateWorklistCopy[runIndex].plates[plateIndex].selectedWells =
        selectedWells;
      plateWorklistCopy[runIndex].plates[plateIndex].activeWellCount =
        selectedWells.length;
      setPlateWorklistInfo(plateWorklistCopy);
    }
    setSelectedPlate({ plateID: 0, plateBarcode: "", selectedWells: [] });
    setPlateMapOpen(false);
  };

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        {plateWorklistInfo.length > 0 ? (
          <>
            <h6>Active Groups Included</h6>
            <div>
              {plateWorklistInfo.map((run: any, runIndex: number) => (
                <IncludedFeedRun
                  setPlateMapOpen={setPlateMapOpen}
                  setSelectedPlate={setSelectedPlate}
                  run={run}
                  runIndex={runIndex}
                  plateWorklistInfo={plateWorklistInfo[runIndex]}
                  updateIncludedRunSelection={updateIncludedRunSelection}
                  updateIncludedPlateSelection={updateIncludedPlateSelection}
                  updatePlateWorklistInfo={updatePlateWorklistInfo}
                ></IncludedFeedRun>
              ))}
            </div>
          </>
        ) : null}
        {excludedPlates.length > 0 ? (
          <>
            <h6>Active Groups Excluded</h6>
            <div>
              {excludedPlates.map((run: any, runIndex: number) => (
                <ExcludedFeedRun
                  run={run}
                  runIndex={runIndex}
                  plateWorklistInfo={excludedPlates[runIndex]}
                  updateExcludedRunSelection={updateExcludedRunSelection}
                  updateExcludedPlateSelection={updateExcludedPlateSelection}
                  updatePlateWorklistInfo={updatePlateWorklistInfo}
                ></ExcludedFeedRun>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <ButtonContainer>
        {methodReservation?.methodReservationId ? (
          <DeleteButton
            methodReservationId={methodReservation?.methodReservationId}
            deleteStateTuple={deleteStateTuple}
          />
        ) : (
          <React.Fragment />
        )}
        {methodReservation?.methodReservationId ? (
          <Button
            variant={"contained"}
            color={"secondary"}
            disabled={deleting}
            onClick={downloadWorklist}
            endIcon={<CloudDownload fontSize={"large"} />}
          >
            Download Worklist
          </Button>
        ) : (
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={createReservation}
            disabled={disabledCreateReservation}
          >
            Create Reservation
          </Button>
        )}
        {/* <Button
          variant={"contained"}
          color={"secondary"}
          type={"submit"}
          disabled={isSubmitting || deleting}
          endIcon={
            methodReservation?.methodReservationId ? (
              <CloudDownload fontSize={"large"} />
            ) : undefined
          }
        >
          {isReserved ? "Download Worklist" : "Create Reservation"}
        </Button> */}
      </ButtonContainer>
      {/* <Button
        fullWidth
        variant="outlined"
        color="primary"
        disabled={disabledCreateReservation}
        onClick={createReservation}
      >
        Create Reservation
      </Button> */}

      <PlateMap
        isOpen={plateMapOpen}
        handleClose={(selectedWells) => handlePlateMapClose(selectedWells)}
        plate={selectedPlate}
      ></PlateMap>
    </>
  );
};

interface IncludedFeedRunProps {
  setPlateMapOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPlate: Dispatch<SetStateAction<any>>;
  run: SystemOneFeedRun;
  runIndex: number;
  plateWorklistInfo: any;
  // {
  //   selected: boolean;
  //   aspirateLC: string;
  //   dispenseLC: string;
  //   plateBarcode: string;
  //   aspirateVol: number;
  //   dispenseVol: number;
  // }[];
  updateIncludedPlateSelection: (runIndex: number, plateIndex: number) => void;
  updateIncludedRunSelection: (runIndex: number) => void;
  updatePlateWorklistInfo: (
    key: string,
    value: string,
    runIndex: number,
    plateIndex: number
  ) => void;
}

const IncludedFeedRun = ({
  setPlateMapOpen,
  setSelectedPlate,
  runIndex,
  plateWorklistInfo,
  updateIncludedRunSelection,
  updateIncludedPlateSelection,
  updatePlateWorklistInfo,
}: IncludedFeedRunProps) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  return (
    <>
      <StyledRunHeader>
        <div>
          <IconButton
            style={{ outline: 0 }}
            aria-label="expand row"
            size="small"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            {collapseOpen ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </div>
        <div>
          <Checkbox
            checked={plateWorklistInfo.selected}
            onChange={(e) => updateIncludedRunSelection(runIndex)}
          />
        </div>
        <div>{plateWorklistInfo.runName}</div>
      </StyledRunHeader>
      {plateWorklistInfo.plates && collapseOpen ? (
        <div>
          <Collapse in={collapseOpen} unmountOnExit>
            <table
              className="table-sm"
              style={{ position: "relative", left: "50px" }}
            >
              {/* <tr> */}
              <thead>
                <td></td>
                <td style={{ minWidth: "175px" }}>
                  <b>Plate Barcode</b>
                </td>
                <td style={{ minWidth: "50px" }}>
                  <b>Wells</b>
                </td>
                <td style={{ minWidth: "100px" }}>
                  <b>Aspirate Vol</b>
                </td>
                <td style={{ minWidth: "100px" }}>
                  <b>Dispense Vol</b>
                </td>
                <td style={{ minWidth: "125px" }}>
                  <b>Liquid Class</b>
                </td>
                <td style={{ minWidth: "125px" }}>
                  <b>Liquid Class</b>
                </td>
                {/* <div style={{ display: "table-cell", textAlign: "justify" }}>
              Liquid Class
            </div> */}
              </thead>
              {/* </tr> */}
              {plateWorklistInfo.plates.map(
                (plate: any, plateIndex: number) => (
                  <IncludedFeedPlate
                    runIndex={runIndex}
                    plateIndex={plateIndex}
                    plateWorklistInfo={plateWorklistInfo.plates[plateIndex]}
                    updatePlateWorklistInfo={updatePlateWorklistInfo}
                    updateIncludedPlateSelection={updateIncludedPlateSelection}
                    setPlateMapOpen={setPlateMapOpen}
                    setSelectedPlate={setSelectedPlate}
                  ></IncludedFeedPlate>
                )
              )}
            </table>
          </Collapse>
        </div>
      ) : null}
    </>
  );
};

interface IncludedFeedPlateProps {
  //plate: Plate;
  runIndex: number;
  plateIndex: number;
  plateWorklistInfo: {
    selected: boolean;
    activeWellCount: number;
    plateBarcode: string;
    plateID: number;
    selectedWells: any[];
    aspirateVol: number;
    dispenseVol: number;
    aspirateLC: string;
    dispenseLC: string;
  };
  updateIncludedPlateSelection: (runIndex: number, plateIndex: number) => void;
  updatePlateWorklistInfo: (
    key: string,
    value: string,
    runIndex: number,
    plateIndex: number
  ) => void;
  setPlateMapOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPlate: Dispatch<SetStateAction<any>>;
}
const IncludedFeedPlate = ({
  // plate,
  runIndex,
  plateIndex,
  plateWorklistInfo,
  updateIncludedPlateSelection,
  updatePlateWorklistInfo,
  setPlateMapOpen,
  setSelectedPlate,
}: IncludedFeedPlateProps) => {
  const handlePlateActiveWellClick = () => {
    setSelectedPlate({
      plateID: plateWorklistInfo.plateID,
      plateBarcode: plateWorklistInfo.plateBarcode,
      selectedWells: plateWorklistInfo.selectedWells,
    });
    setPlateMapOpen(true);
  };

  return (
    <tr>
      <td>
        <Checkbox
          checked={plateWorklistInfo.selected}
          onChange={(e) => updateIncludedPlateSelection(runIndex, plateIndex)}
        />
      </td>
      <td>{plateWorklistInfo.plateBarcode}</td>
      <td
        onClick={() => handlePlateActiveWellClick()}
        style={{ cursor: "pointer" }}
      >
        {plateWorklistInfo.activeWellCount}
      </td>
      <td>
        <input
          style={{ width: "85px" }}
          value={plateWorklistInfo.aspirateVol}
          onChange={(e) =>
            updatePlateWorklistInfo(
              "aspirateVol",
              e.target.value,
              runIndex,
              plateIndex
            )
          }
        />
        {/* <TextField
            style={{ marginRight: "10px" }}
            label="Aspirate Vol"
            variant="outlined"
            value={worklistInfo.aspirateVol}
            onChange={(e) =>
              setPlateWorklistInfo("aspirateVol", e.target.value, index)
            }
          /> */}
      </td>
      <td>
        <input
          style={{ width: "85px" }}
          value={plateWorklistInfo.dispenseVol}
          onChange={(e) =>
            updatePlateWorklistInfo(
              "dispenseVol",
              e.target.value,
              runIndex,
              plateIndex
            )
          }
        />
        {/* <TextField
            label="Dispense Vol"
            variant="outlined"
            value={worklistInfo.dispenseVol}
            onChange={(e) =>
              setPlateWorklistInfo("dispenseVol", e.target.value, index)
            }
          /> */}
      </td>
      <td>
        <input value={plateWorklistInfo.aspirateLC} />
      </td>
      <td>
        <input disabled value={plateWorklistInfo.dispenseLC} />
      </td>
    </tr>
  );
};

interface ExcludedFeedRunProps {
  run: SystemOneFeedRun;
  runIndex: number;
  plateWorklistInfo: any;
  // {
  //   selected: boolean;
  //   aspirateLC: string;
  //   dispenseLC: string;
  //   plateBarcode: string;
  //   aspirateVol: number;
  //   dispenseVol: number;
  // }[];
  updateExcludedRunSelection: (groupIndex: number) => void;
  updateExcludedPlateSelection: (
    groupIndex: number,
    plateIndex: number
  ) => void;
  updatePlateWorklistInfo: (
    key: string,
    value: string,
    runIndex: number,
    plateIndex: number
  ) => void;
}

const ExcludedFeedRun = ({
  run,
  runIndex,
  plateWorklistInfo,
  updateExcludedRunSelection,
  updateExcludedPlateSelection,
  updatePlateWorklistInfo,
}: ExcludedFeedRunProps) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  return (
    <>
      <StyledRunHeader>
        <div>
          <IconButton
            style={{ outline: 0 }}
            aria-label="expand row"
            size="small"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            {collapseOpen ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </div>
        <div>
          <Checkbox
            checked={plateWorklistInfo.selected}
            onChange={() => updateExcludedRunSelection(runIndex)}
          />
        </div>
        <div style={{ width: "75px" }}>{run.runName}</div>
        <div
          style={{
            color: run.reason === "Removed from Included Group" ? "red" : "",
          }}
        >
          {run.reason}
        </div>
      </StyledRunHeader>
      {run.plates && collapseOpen ? (
        <div>
          <Collapse in={collapseOpen} unmountOnExit>
            <table
              className="table-sm"
              style={{ position: "relative", left: "50px" }}
            >
              {/* <tr> */}
              <thead>
                <td></td>
                <td>
                  <b>Plate Barcode</b>
                </td>
                <td>
                  <b>Wells</b>
                </td>
              </thead>
              {/* </tr> */}
              {run.plates.map((plate, plateIndex) => (
                <ExcludedFeedPlate
                  runIndex={runIndex}
                  plateIndex={plateIndex}
                  plateWorklistInfo={plateWorklistInfo.plates[plateIndex]}
                  updateExcludedPlateSelection={updateExcludedPlateSelection}
                ></ExcludedFeedPlate>
              ))}
            </table>
          </Collapse>
        </div>
      ) : null}
    </>
  );
};

interface ExcludedFeedPlateProps {
  runIndex: number;
  plateIndex: number;
  plateWorklistInfo: {
    selected: boolean;
    activeWellCount: number;
    plateBarcode: string;
    plateID: number;
    aspirateVol: number;
    dispenseVol: number;
    aspirateLC: string;
    dispenseLC: string;
  };
  updateExcludedPlateSelection: (
    groupIndex: number,
    plateIndex: number
  ) => void;
}
const ExcludedFeedPlate = ({
  runIndex,
  plateIndex,
  plateWorklistInfo,
  updateExcludedPlateSelection,
}: ExcludedFeedPlateProps) => {
  return (
    <tr>
      <td>
        <Checkbox
          checked={plateWorklistInfo.selected}
          onChange={(e) => updateExcludedPlateSelection(runIndex, plateIndex)}
        />
      </td>
      <td>{plateWorklistInfo.plateBarcode}</td>
      <td>{plateWorklistInfo.activeWellCount}</td>
    </tr>
  );
};
