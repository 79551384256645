import { Link } from "@mui/material";
import { ManualTaskTypeFragment } from "graphql/generated/schema-types";
import React from "react";
import { Column } from "react-table";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";

export const MethodColumns: Array<Column<ManualTaskTypeFragment>> = [
  {
    Header: "Name",
    accessor: "name",
    Filter: TextFilter,
    width: "50%",
  },
  {
    Header: "Documentation Url",
    accessor: "documentationUrl",
    width: "50%",
    Cell: ({ value }) =>
      value ? (
        <Link href={value} target={"__blank"}>
          {value}
        </Link>
      ) : (
        <React.Fragment />
      ),
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
  },
];
