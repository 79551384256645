import { DefaultTheme } from "styled-components/macro";

export const GetTableCellBorder = ({
  theme,
}: {
  theme?: DefaultTheme;
}): string => {
  return theme?.palette.mode === "light" || theme?.palette?.mode === undefined
    ? "rgba(224, 224, 224, 1) !important"
    : "rgba(81, 81, 81, 1) !important";
};
