import { Maybe } from "graphql-tools";
import { WorklistToolTemplateContent } from "graphql/generated/schema-types";
import { Twelve24TCToolState } from "../../state";
import { ITwelve24TCMethodSettings } from "../../state/initial-state";

export const parseWorklistValues = (
  worklistValues: Maybe<Maybe<WorklistToolTemplateContent>[]> | undefined
) => {
  const parsedWorklistValues = worklistValues?.map((row) => ({
    sourcePlateIndex: row!.sourcePlatePosition,
    sourceWellId: row!.sourceWellId,
    destPlateIndex: row!.destinationPlatePosition,
    destWellId: row!.destinationWellId,
    transferVol: row!.transferVol ?? "",
  }));

  return parsedWorklistValues;
};

export const parseMethodSettingsTemplate = (
  worklist: Maybe<Maybe<WorklistToolTemplateContent>[]> | undefined
) => {
  const spinParamPercent = parseInt(
    worklist!.filter((e) => e!.task === "SpinParameters")[0]!.details ?? ""
  );

  const methodSettings: ITwelve24TCMethodSettings = {
    selectedSystem: 0,
    arraySystemAutomationMethodId: 391,
    aliquotVolume: parseInt(
      worklist!.filter((e) => e!.task === "DeadTotalPlate")[0]?.details ?? "-1"
    ),
    dissociationTime: parseInt(
      worklist!.filter((e) => e!.task === "IncubationTime")[0]!.details ?? ""
    ),
    spinParamGForce: Math.round(0.101 * Math.pow(spinParamPercent, 1.999)),
    spinParamPercent: spinParamPercent,
    spinTime: parseInt(
      worklist!.filter((e) => e!.task === "SpinParameters")[0]!.aspirateVol ??
        ""
    ),
    spinAccel: parseInt(
      worklist!.filter((e) => e!.task === "SpinParameters")[0]!.dispenseVol ??
        ""
    ),
    spinDecel: parseInt(
      worklist!.filter((e) => e!.task === "SpinParameters")[0]!.transferVol ??
        ""
    ),
    dissociationWashRGT: parseInt(
      worklist!.filter((e) => e!.task === "DissociationWashRGT")[0]!.details ??
        ""
    ),
    dissociation: !!parseInt(
      worklist!.filter((e) => e!.task === "Dissociation")[0]!.details!
    ),
    defaultVSpinSettings: !!parseInt(
      worklist!.filter((e) => e!.task === "defaultVSpinSettings")[0]!.details!
    ),
    washBeforeDissociation: !!parseInt(
      worklist!.filter((e) => e!.task === "DissociationWash")[0]!.details!
    ),
    pelletResuspensionVol: parseInt(
      worklist!.filter((e) => e!.task === "SpinParameters")[0]!.transferVol ??
        ""
    ),
    pelletResuspensionRGT: parseInt(
      worklist!.filter((e) => e!.task === "PelletResuspensionRGT")[0]!
        .details ?? ""
    ),
    manualSpin: parseInt(
      worklist!.filter((e) => e!.task === "ManualSpin")[0]!.details ?? ""
    ),
    discardSourcePlate: parseInt(
      worklist!.filter((e) => e!.task === "DiscardSourcePlate")[0]!.details ??
        ""
    ),
  };

  return methodSettings;
};

// VV used to save and overwrite templates //

export const getSourcePlateInfo = (twelve24TCState: Twelve24TCToolState) => {
  return twelve24TCState.sourcePlateInfo
    .filter((e) => e.labwareTypeCode !== "")
    .map((item, index) => {
      return {
        plateType: "Source",
        plateIndex: index,
        labwareTypeCode: item.labwareTypeCode,
        numberOfRows: item.rows.length,
        numberOfColumns: item.cols.length,
      };
    });
};

export const getDestPlateInfo = (twelve24TCState: Twelve24TCToolState) => {
  //TODO: Fix this function.
  return twelve24TCState.destPlateInfo;
};

export const getDeadPlateInfo = (twelve24TCState: Twelve24TCToolState) => {
  return twelve24TCState.deadPlateType
    ? [
        {
          plateType: "Dead",
          plateIndex: 0,
          labwareTypeCode: twelve24TCState.deadPlateType,
          numberOfRows: 0,
          numberOfColumns: 0,
        },
      ]
    : [];
};

export const getHarvestWells = (twelve24TCState: Twelve24TCToolState) => {
  return twelve24TCState.worklistValues.harvestWells
    .map((row) => ({
      task: "HarvestWells",
      transferVol: row?.transferVol?.toString() ?? "",
      sourcePlateType:
        twelve24TCState.sourcePlateInfo[row.sourcePlateIndex].labwareTypeCode,
      sourcePlatePosition: row.sourcePlateIndex,
      sourceWellID: row.sourceWellId,
      destinationPlateType: twelve24TCState.destPlateInfo.labwareTypeCode,
      destinationPlatePosition: row.destPlateIndex,
      destinationWellID: row.destWellId,
    }))
    .filter((e) => e.destinationPlateType !== "");
};
