import { Maybe } from "graphql/jsutils/Maybe";
import styled from "styled-components/macro";
import { AppSuiteUser } from "graphql/generated/schema-types";
import { UserField } from "shared-components/FormFields/FormFields";

const Container = styled.div`
  display: flex;
  margin: 20px;
  gap: 10px;
  align-items: center;
  width: 300px;
`;
export function Search({
  setUserIdFilter,
}: {
  setUserIdFilter: (id: number | null | undefined) => void;
}) {
  return (
    <Container>
      <UserField
        id={"user-search"}
        label={"UserName"}
        onChange={(e, newValue) => {
          setUserIdFilter((newValue as Maybe<AppSuiteUser>)?.userId);
        }}
        variant={"outlined"}
      />
    </Container>
  );
}
