import { ClassNameMap, Grid, Switch, Tooltip } from "@mui/material";
import { FormikErrors } from "formik";
import { CalendarSettings } from "./Settings";

export interface TaskTitleToggleProps {
  classes: ClassNameMap<"container">;
  formValues: CalendarSettings;
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<CalendarSettings>>;
}

export const TaskTitleToggle = ({
  classes,
  formValues,
  setFieldValue,
}: TaskTitleToggleProps) => {
  return (
    <Grid
      component="label"
      container
      alignItems="center"
      spacing={1}
      classes={{ container: classes.container }}
    >
      <Grid item>Standard Task Titles</Grid>
      <Grid item>
        <Switch
          color={"primary"}
          checked={formValues.taskTitles === "engineermode"}
          size={"medium"}
          onChange={(e) => {
            setFieldValue(
              "taskTitles",
              e.target.checked ? "engineermode" : "standard"
            );
          }}
        />
      </Grid>
      <Tooltip
        title={"Tasks titled with Method Name + User Name"}
        arrow
        placement="right"
      >
        <Grid item>Engineer Mode</Grid>
      </Tooltip>
    </Grid>
  );
};
