import { makeVar, useReactiveVar } from "@apollo/client";
import { FileCopy } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import {
  Button,
  Chip,
  createFilterOptions,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { CreateTicketValidationSchema } from "features/Tickets/components/Forms/validation-schema";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  CreateTicketInput,
  GetTicketAssetsQuery,
  LookupAsset,
  LookupAssetType,
  LookupTicketType,
  TicketPriority,
  useGetMeQuery,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import produce from "immer";
import React, { ChangeEvent } from "react";
import { useDropzone } from "react-dropzone";
import {
  AssetField,
  AssetTypeField,
  GroupField,
  TicketTypeField,
  UserField,
} from "shared-components/FormFields/FormFields";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import styled from "styled-components/macro";
import { FormName } from "enums/FormName";
import { DistinctPrimitive } from "helpers/object-helpers";
import { Abbreviate } from "helpers/string-helpers";
import { usePersistForm } from "hooks/usePersistForm";
import { AddOptionDialog } from "./AddOptionDialog";

const AttachmentsArea = styled.div`
  border-radius: 3px;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: dashed 1px
    ${({
      theme: {
        palette: { mode },
      },
    }) => (mode === "dark" ? "rgba(255,255,255,0.2)" : "rgba(0, 0, 0, 0.2)")};
`;

const AttachmentAreaText = styled.div`
  font-weight: bold;
  &&:hover {
    cursor: pointer;
  }
`;
const AttachmentsList = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5px;
`;
export const TicketAttachments = makeVar<File[]>([]);
export type AddTicketOption = {
  dialogOpen: boolean;
  entry?: LookupAsset | LookupAssetType | LookupTicketType;
  setFieldOriginalFormValue?: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<CreateTicketInput>> | Promise<void>;
};
export const AddOptionState = makeVar<AddTicketOption>({
  dialogOpen: false,
});
export function CreateTicketForm({
  initialValues,
  onSubmit,
}: {
  initialValues: CreateTicketInput;
  onSubmit: (
    values: CreateTicketInput,
    helpers: FormikHelpers<CreateTicketInput>
  ) => void;
}): JSX.Element {
  const props = useFormik<CreateTicketInput>({
    initialValues,
    onSubmit,
    validationSchema: CreateTicketValidationSchema,
  });
  const {
    handleSubmit,
    isSubmitting,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = props;
  const attachments = useReactiveVar(TicketAttachments);
  const { data: meQuery } = useGetMeQuery({
    fetchPolicy: "cache-only",
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      TicketAttachments(
        produce(attachments, (draft) => {
          return draft.concat(acceptedFiles);
        })
      );
    },
  });
  const filter =
    createFilterOptions<
      NonNullable<GetTicketAssetsQuery["ticketAssets"]>[number]
    >();
  const addOptionState = useReactiveVar(AddOptionState);
  const openAddOptionDialog = (
    entry: LookupAsset | LookupAssetType | LookupTicketType
  ) => {
    AddOptionState(
      produce(addOptionState, (draft) => {
        draft.entry = entry;
        draft.dialogOpen = true;
        draft.setFieldOriginalFormValue = setFieldValue;
      })
    );
  };
  usePersistForm(FormName.CreateTicket, props);
  return (
    <>
      <AddOptionDialog />
      <StyledForm onSubmit={handleSubmit}>
        <StyledFormControl>
          <TextField
            variant="outlined"
            margin="none"
            fullWidth
            id="description"
            multiline
            label="Description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errors.description && touched.description && errors.description
            }
            error={
              !!(
                errors.description &&
                touched.description &&
                errors.description
              )
            }
          />
        </StyledFormControl>
        <StyledFormControl>
          <TicketTypeField
            id="ticketType-combobox"
            value={values.ticketTypeId}
            onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
              setFieldValue(
                "ticketTypeId",
                (newValue as Maybe<LookupTicketType>)?.ticketTypeId
              )
            }
            label="Ticket Type"
            variant="outlined"
            helperText={
              errors.ticketTypeId && touched.ticketTypeId && errors.ticketTypeId
            }
            error={
              !!(
                errors.ticketTypeId &&
                touched.ticketTypeId &&
                errors.ticketTypeId
              )
            }
          />
        </StyledFormControl>
        <StyledFormControl>
          <DatePicker
            value={values.dateEncountered}
            onChange={(date) => setFieldValue("dateEncountered", date)}
            renderInput={(props) => (
              <TextField
                {...props}
                id="date-encountered-picker"
                variant="outlined"
                label="Date"
                helperText={
                  errors.dateEncountered &&
                  touched.dateEncountered &&
                  errors.dateEncountered
                }
                error={
                  !!(
                    errors.dateEncountered &&
                    touched.dateEncountered &&
                    errors.dateEncountered
                  )
                }
                fullWidth
              />
            )}
          />
        </StyledFormControl>
        <StyledFormControl>
          <AssetTypeField
            id="ticketAssetType-combobox"
            onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
              setFieldValue(
                "assetTypeId",
                (newValue as Maybe<LookupAssetType>)?.assetTypeId
              )
            }
            label="Platform"
            variant="outlined"
            helperText={
              errors.assetTypeId && touched.assetTypeId && errors.assetTypeId
            }
            error={
              !!(
                errors.assetTypeId &&
                touched.assetTypeId &&
                errors.assetTypeId
              )
            }
          />
        </StyledFormControl>
        <StyledFormControl>
          <AssetField
            freeSolo={meQuery?.me?.isAdmin}
            value={values.assetId}
            id="ticketAsset-combobox"
            filter={(asset) =>
              asset?.assetType?.assetTypeId === values.assetTypeId
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params) as LookupAsset[];
              if (params.inputValue !== "") {
                filtered.push({
                  __typename: "LookupAsset",
                  assetId: -1,
                  assetTypeId: values.assetTypeId ?? 0,
                  asset: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
              const value = (newValue as Maybe<LookupAsset>)?.assetId;
              if (value === -1 && newValue)
                return openAddOptionDialog(newValue as LookupAsset);
              setFieldValue("assetId", value);
            }}
            label="Feature"
            variant="outlined"
            helperText={errors.assetId && touched.assetId && errors.assetId}
            error={!!(errors.assetId && touched.assetId && errors.assetId)}
          />
        </StyledFormControl>
        <StyledFormControl variant={"outlined"} fullWidth>
          <InputLabel>Priority</InputLabel>
          <Select
            label={"Priority"}
            fullWidth
            labelId="priority-label"
            id="priority-select"
            value={values.priority}
            onChange={(e) => {
              setFieldValue("priority", e.target.value);
            }}
            inputProps={{
              name: "releaseDate",
              id: "releaseDate",
            }}
          >
            {Object.entries(TicketPriority).map(([_, priority], idx) => (
              <MenuItem key={idx} value={priority}>
                {priority}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <StyledFormControl>
          <UserField
            id="assignee-combobox"
            value={values.ticketAssigneeIds}
            onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
              setFieldValue(
                "ticketAssigneeIds",
                (newValue as Maybe<AppSuiteUser>[])?.map((user) => user?.userId)
              )
            }
            label="Assignees"
            multiple
            variant="outlined"
            helperText={
              errors.ticketAssigneeIds &&
              touched.ticketAssigneeIds &&
              errors.ticketAssigneeIds
            }
            error={
              !!(
                errors.ticketAssigneeIds &&
                touched.ticketAssigneeIds &&
                errors.ticketAssigneeIds
              )
            }
          />
        </StyledFormControl>
        <StyledFormControl>
          <GroupField
            id="group-combobox"
            onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
              const currentUsers = values.ticketFollowerIds ?? [];
              const groupMembers =
                (newValue as Maybe<AppSuiteGroup>)?.users?.map(
                  (user) => user?.userId
                ) ?? [];
              const followers = DistinctPrimitive([
                ...currentUsers,
                ...groupMembers,
              ]);
              setFieldValue("ticketFollowerIds", followers);
            }}
            label="Group Followers"
            variant="outlined"
          />
        </StyledFormControl>
        <StyledFormControl>
          <UserField
            id="followers-combobox"
            value={values.ticketFollowerIds}
            onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
              setFieldValue(
                "ticketFollowerIds",
                (newValue as Maybe<AppSuiteUser>[])?.map((user) => user?.userId)
              )
            }
            label="Followers"
            multiple
            variant="outlined"
            helperText={
              errors.ticketFollowerIds &&
              touched.ticketFollowerIds &&
              errors.ticketFollowerIds
            }
            error={
              !!(
                errors.ticketFollowerIds &&
                touched.ticketFollowerIds &&
                errors.ticketFollowerIds
              )
            }
          />
        </StyledFormControl>
        <StyledFormControl>
          <AttachmentsArea {...getRootProps()}>
            <AttachmentsList>
              {attachments.map((file, index) => (
                <Chip
                  key={file.lastModified}
                  color={"primary"}
                  icon={<FileCopy />}
                  label={Abbreviate(file.name, 15)}
                  onDelete={() =>
                    TicketAttachments(
                      produce(attachments, (draft) => {
                        draft.splice(index, 1);
                      })
                    )
                  }
                />
              ))}
            </AttachmentsList>
            <input {...getInputProps()} />
            {isDragActive ? (
              <AttachmentAreaText>Drop attachments here ...</AttachmentAreaText>
            ) : (
              <AttachmentAreaText>
                Drop attachments, or click to select files
              </AttachmentAreaText>
            )}
          </AttachmentsArea>
        </StyledFormControl>
        <Button variant={"contained"} type={"submit"} disabled={isSubmitting}>
          Submit
        </Button>
      </StyledForm>
    </>
  );
}
