import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import { IncomingSampleActions, useIncomingSampleState } from "../../state";

export function IncomingSamplesConfirmDialog({
  deleteMutation,
  loading,
}: {
  deleteMutation: () => Promise<unknown>;
  loading?: boolean;
}): JSX.Element {
  const {
    incomingSampleState: { confirmDialogState },
    incomingSampleDispatch,
  } = useIncomingSampleState();
  return (
    <Dialog open={confirmDialogState?.open ?? false}>
      <DialogTitle>
        {_.startCase(confirmDialogState?.dialogType?.toString())}{" "}
        {_.startCase(confirmDialogState?.typename)}(s)
      </DialogTitle>
      <DialogContent>
        {confirmDialogState?.typename === "Collection" &&
          "This will delete this collection, it's tasks and all its samples, do you wish to continue?"}
        {confirmDialogState?.typename === "CollectionTask" &&
          "This will delete these tasks, do you wish to continue?"}
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          onClick={() =>
            incomingSampleDispatch({
              type: IncomingSampleActions.CLOSE_DIALOG,
            })
          }
        >
          Cancel
        </Button>
        <Button
          color={"secondary"}
          variant={"contained"}
          onClick={deleteMutation}
          disabled={loading}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
