import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import { ISampleAction, ISampleState, SampleState } from "./initial-state";
import { sampleStateReducer } from "./reducer";

export function useSampleState(): {
  sampleState: ISampleState;
  sampleDispatch: IApolloDispatch<ISampleAction>;
} {
  const sampleState = useReactiveVar(SampleState);
  const sampleDispatch = useMemo(
    () => ApolloStateDispatch(SampleState, sampleStateReducer),
    []
  );
  return useMemo(
    () => ({
      sampleState,
      sampleDispatch,
    }),
    [sampleState, sampleDispatch]
  );
}
