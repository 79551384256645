import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  SizeMapping,
  wellListFieldsArray,
  wellListFieldsMapping,
} from "features/Plates/components/PlateInfo/constants";
import { useLayoutStateProvider } from "features/Plates/state/StateProvider";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React from "react";
import styled from "styled-components";
import { Plate } from "../helpers";
import { WellListContextMenu } from "./ContextMenu";
import { WellListRow } from "./WellListRow";

const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${GetTableCellBorder};
  width: 106px;
  padding: 7px 10px !important;
`;

const HeaderCell = styled(StyledTableCell)`
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "black" : "#d6d6d6"} !important;
  border-bottom: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? "solid 1px white"
      : "solid 1px #000"} !important;
  text-align: center;
`;

const WellList = styled(TableContainer)<{
  rows: number;
  maxheight: string;
}>`
  border: 1px solid rgba(0, 0, 0, 0.3);
  grid-row: 2 / 3;
  max-height: ${(props) => props.maxheight};
  max-width: ${(props) =>
    props.rows >= 8 ? (props.rows === 16 ? `26vw` : `30vw`) : `35vw`};
  min-width: 386px;
`;

export function WellListTable({
  selectedPlate,
}: {
  selectedPlate: Plate;
}): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const {
    layoutState: { wellListOpen },
  } = useLayoutStateProvider();

  //--------------------------------------------------------------- Initialize WellListTable ------------------------------------------------------------//
  const data = selectedPlate ? selectedPlate.wells.flat() : [];

  return (
    <React.Fragment>
      <WellListContextMenu />
      <Paper elevation={3} style={{ margin: "0px 5px" }}>
        {selectedPlate && wellListOpen ? (
          <WellList
            rows={
              selectedPlate && selectedPlate.numRows ? selectedPlate.numRows : 0
            }
            maxheight={
              selectedPlate.layout !== ""
                ? SizeMapping[selectedPlate.layout].height
                : ""
            }
          >
            <Table stickyHeader style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  {wellListFieldsArray.map((field, idx) => (
                    <HeaderCell key={idx}>
                      {wellListFieldsMapping[field]}
                    </HeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((well, idx) =>
                  well.empty ? null : (
                    <WellListRow
                      well={well}
                      selectedPlate={selectedPlate}
                      key={idx}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </WellList>
        ) : (
          <React.Fragment />
        )}
      </Paper>
    </React.Fragment>
  );
}
