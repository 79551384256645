import { FetchResult } from "@apollo/client";
import produce from "immer";
import { TableName } from "../enums/TableName";
import {
  SaveUserSettingsMutation,
  useGetMeQuery,
  useSaveUserSettingsMutation,
} from "../graphql/generated/schema-types";
import { AppUserSettings, TableSetting } from "../interfaces/AppUserSettings";

export const useGetSaveCalendarSettings = (): [
  (
    newSetting: AppUserSettings["calendar"]
  ) => Promise<FetchResult<SaveUserSettingsMutation>>,
  boolean
] => {
  const rawSettings = useGetMeQuery().data?.me?.setting?.settingsJson;
  const [saveSettings, { loading }] = useSaveUserSettingsMutation();
  return [
    (newSetting: AppUserSettings["calendar"]) => {
      const currentSettings: AppUserSettings = JSON.parse(
        rawSettings ?? "null"
      );
      const calendarSetting = newSetting ?? currentSettings.calendar;
      const input: AppUserSettings = {
        tables: currentSettings?.tables,
        theme: currentSettings?.theme,
        notifications: currentSettings?.notifications,
        calendar: calendarSetting,
      };
      return saveSettings({
        variables: {
          settingsJson: JSON.stringify(input),
        },
      });
    },
    loading,
  ];
};

export const useGetSaveThemeSettings = (): [
  (
    newSetting: AppUserSettings["theme"]
  ) => Promise<FetchResult<SaveUserSettingsMutation>>,
  boolean
] => {
  const rawSettings = useGetMeQuery().data?.me?.setting?.settingsJson;
  const [saveSettings, { loading }] = useSaveUserSettingsMutation();
  return [
    (newSetting: AppUserSettings["theme"]) => {
      const currentSettings: AppUserSettings = JSON.parse(
        rawSettings ?? "null"
      );
      const themeSetting = newSetting ?? currentSettings.theme;
      const input: AppUserSettings = {
        tables: currentSettings?.tables,
        theme: themeSetting,
        notifications: currentSettings?.notifications,
        calendar: currentSettings?.calendar,
      };
      return saveSettings({
        variables: {
          settingsJson: JSON.stringify(input),
        },
      });
    },
    loading,
  ];
};
export const useGetSaveNotificationSettings = (): [
  (
    newSetting: AppUserSettings["notifications"]
  ) => Promise<FetchResult<SaveUserSettingsMutation>>,
  boolean
] => {
  const rawSettings = useGetMeQuery().data?.me?.setting?.settingsJson;
  const [saveSettings, { loading }] = useSaveUserSettingsMutation();
  return [
    (newSetting: AppUserSettings["notifications"]) => {
      const currentSettings: AppUserSettings = JSON.parse(
        rawSettings ?? "null"
      );
      const notificationsSetting = newSetting ?? currentSettings.notifications;
      const input: AppUserSettings = {
        tables: currentSettings?.tables,
        theme: currentSettings?.theme,
        notifications: notificationsSetting,
        calendar: currentSettings?.calendar,
      };
      return saveSettings({
        variables: {
          settingsJson: JSON.stringify(input),
        },
      });
    },
    loading,
  ];
};
export const useGetSaveTableSettings = () => {
  const rawSettings = useGetMeQuery().data?.me?.setting?.settingsJson;
  const [saveSettings, { loading }] = useSaveUserSettingsMutation();

  const saveTableSetting = (
    tableName: TableName,
    preset: TableSetting["presets"][number]
  ) => {
    const currentSettings: AppUserSettings = JSON.parse(rawSettings ?? "null");

    const mergedTableSettings: AppUserSettings["tables"] = produce(
      currentSettings?.tables ?? [],
      (draft) => {
        let output: Array<TableSetting> = currentSettings?.tables ?? [];
        if (!draft) {
          output = [{ name: tableName, presets: [preset] }];
        } else if (draft.length === 0)
          output.push({ name: tableName, presets: [preset] });
        else {
          const table = output.find((entry) => entry.name === tableName);

          const newPresets =
            table?.presets.filter((p) => p.name !== preset.name) ?? [];

          if (preset.default)
            for (const entry of newPresets) {
              entry.default = false;
            }

          newPresets.push(preset);

          if (table) table.presets = newPresets;
          else output.push({ name: tableName, presets: newPresets });
        }
        return output;
      }
    );
    const input: AppUserSettings = {
      theme: currentSettings?.theme,
      notifications: currentSettings?.notifications,
      tables: mergedTableSettings,
      calendar: currentSettings?.calendar,
    };
    return saveSettings({
      variables: {
        settingsJson: JSON.stringify(input),
      },
    });
  };
  return [saveTableSetting, loading] as [
    (
      tableName: string,
      preset: TableSetting["presets"][number]
    ) => Promise<FetchResult<SaveUserSettingsMutation>>,
    boolean
  ];
};
