import { ContainerTableEntryFragment } from "graphql/generated/schema-types";
import moment from "moment";
import { Column } from "react-table";
import {
  EqualsTextFilter,
  TextFilter,
} from "shared-components/Table/Filters/TextFilter";
import { GetAlphabet } from "helpers/string-helpers";
import { BooleanFilter } from "shared-components/Table/Filters/BooleanFilter";
import { DateRangeFilter } from "shared-components/Table/Filters/DateRangeFilter";
import { BooleanCell } from "shared-components/Table/sub-components/BooleanCell";

export const SampleColumns: Array<Column<ContainerTableEntryFragment>> = [
  {
    Header: "Sample ID",
    accessor: "sample.sampleBarcode" as keyof ContainerTableEntryFragment,
    Filter: TextFilter,
    width: "10%",
  },
  {
    Header: "Plate Barcode",
    accessor: "plate.plateBarcode" as keyof ContainerTableEntryFragment,
    Filter: TextFilter,
    width: "10%",
  },
  {
    Header: "Row/Col",
    accessor: "rowCol",
    width: "6%",
    Filter: EqualsTextFilter,
  },
  {
    Header: "Row",
    accessor: "rowPos",
    width: "5%",
    Cell: ({ value }) => (value ? GetAlphabet(value) : null),
    disableFilters: true,
    disableGroupBy: true,
    disableSortBy: true,
  },
  {
    Header: "Column",
    accessor: "colPos",
    width: "5%",
    disableFilters: true,
    disableGroupBy: true,
    disableSortBy: true,
  },
  {
    Header: "Lot Number",
    accessor: "lotNumber.lotNumber1" as keyof ContainerTableEntryFragment,
    Filter: TextFilter,
    width: "12%",
  },
  {
    Header: "Tube Barcode",
    accessor: "barcode",
    Filter: TextFilter,
    width: "12%",
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    width: "10%",
  },
  {
    Header: "Date Seeded",
    accessor: "dateCreated",
    Filter: DateRangeFilter,
    Cell: ({ value }) =>
      value ? moment(value).format("dddd, MMMM Do YYYY") : null,
    width: "10%",
  },
  {
    Header: "Date Inactivated",
    accessor: "dateInactivated",
    Filter: DateRangeFilter,
    Cell: ({ value }) =>
      value ? moment(value).format("dddd, MMMM Do YYYY") : null,
    width: "10%",
  },
  {
    Header: "Inactivation Reason",
    accessor: "inactivationReason.reason" as keyof ContainerTableEntryFragment,
    Filter: TextFilter,
    width: "10%",
  },
];
