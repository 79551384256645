import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GetUserSettings } from "helpers/user-claim-helpers";
import React, { useState } from "react";
import DataGrid, { TextEditor } from "react-data-grid";

interface WorklistPreviewProps {
  isOpen: boolean;
  worklist: any[];
  setWorklist: any;
  handleClose: (
    generateWorklist: boolean,
    worklist: any[],
    reservationDate: Date
  ) => void;
}

const columns = [
  { key: "task", name: "Task", resizable: true },
  { key: "details", name: "Details", editor: TextEditor, resizable: true },
  {
    key: "aspirateVol",
    name: "AspirateVol",
    editor: TextEditor,
    resizable: true,
  },
  {
    key: "dispenseVol",
    name: "DispenseVol",
    editor: TextEditor,
    resizable: true,
  },
  {
    key: "transferVol",
    name: "TransferVol",
    editor: TextEditor,
    resizable: true,
  },
  {
    key: "sourcePlateType",
    name: "SourcePlateType",
    editor: TextEditor,
    resizable: true,
  },
  { key: "sourcePlateBarcode", name: "SourcePlateBarcode", resizable: true },
  {
    key: "sourceWellID",
    name: "SourceWellID",
    editor: TextEditor,
    resizable: true,
  },
  {
    key: "destinationPlateType",
    name: "DestinationPlateType",
    editor: TextEditor,
    resizable: true,
  },
  {
    key: "destinationPlateBarcode",
    name: "DestinationPlateBarcode",
    resizable: true,
  },
  {
    key: "destinationWellID",
    name: "DestinationWellID",
    editor: TextEditor,
    resizable: true,
  },
];

export function NewWorklistPreview({
  isOpen,
  worklist,
  setWorklist,
  handleClose,
}: WorklistPreviewProps) {
  const theme = GetUserSettings()?.theme?.mode ?? "light";
  const [reservationTime, setReservationTime] = useState(new Date());
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={() => handleClose(false, worklist, reservationTime)}
    >
      <div style={{ display: "flex" }}>
        <IconButton
          onClick={() => handleClose(false, worklist, reservationTime)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <Button
          style={{ margin: "10px" }}
          variant="outlined"
          onClick={() => handleClose(true, worklist, reservationTime)}
        >
          Generate Worklist
        </Button>
        <div style={{ margin: "10px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Reservation Date"
              value={reservationTime}
              onChange={(newValue) => {
                setReservationTime(newValue ?? new Date());
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div style={{ width: "100vw", height: "100vh" }}>
        <DataGrid
          className={theme === "light" ? "rdg-light" : "rdg-dark"}
          style={{ height: "100%" }}
          columns={columns}
          rows={worklist}
          onRowsChange={setWorklist}
        />
      </div>
    </Dialog>
  );
}
