import { FormLabel } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

export interface IsSystemMethodProps {
  setFieldValue: (field: string, value: any) => void;
  taskTypeSelection: number;
}

export const IsSystemMethod = (props: IsSystemMethodProps) => {
  const onChange = (value: number) => {
    props.setFieldValue("taskTypeSelection", value);
    if (value === 1) props.setFieldValue("systemAssignedTo", 14);
  };

  const getFormControlLabel = (label: string, value: number) => (
    <FormControlLabel
      name="taskTypeSelection"
      style={{ margin: 0, padding: 0 }}
      onChange={() => onChange(value)}
      value={value}
      control={<Radio color="primary" />}
      label={label}
    />
  );

  return (
    <>
      <FormLabel component="legend">
        Do you want to run a method on a system?
      </FormLabel>
      <RadioGroup row defaultValue={0} value={props.taskTypeSelection}>
        {getFormControlLabel("Yes", 0)}
        {getFormControlLabel("No", 1)}
      </RadioGroup>
    </>
  );
};
