import {
  ImageExportFormat,
  PlateMetadataInput,
  Scalars,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";

export class PlateMetadataInputStub implements PlateMetadataInput {
  analysisSetting: Maybe<Scalars["String"]>;
  experimentSetting: Maybe<Scalars["String"]>;
  imageExportFormat: Maybe<ImageExportFormat>;
  imageExportSetting: Maybe<Scalars["Int"]>;
  platePitch: Maybe<Scalars["String"]>;
  sourcePlateBarcode: Scalars["String"];
}
