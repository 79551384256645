import { Delete } from "@mui/icons-material";
import { FormControl, IconButton, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import {
  AppSuiteGroup,
  CreateIncomingSampleTypeInput,
  LookupSampleType,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import {
  GroupField,
  SourceSampleTypeField,
} from "shared-components/FormFields/FormFields";
import styled from "styled-components/macro";
import { CreateIncomingSampleTypeValidationSchema } from "../validation-schema";
import { FormSection } from "./CreateCollectionForm";
import { ScheduleIncomingSampleFormActions } from "./state";

const StyledFormControl = styled(FormControl)`
  width: 300px;
  margin: 20px 10px !important;
`;

export function CreateSampleTypeForm({
  indexer,
}: {
  indexer: string;
}): JSX.Element {
  const state = useAppReduxSelector(
    (state) => state.ScheduleIncomingSampleForm
  );
  const { setFieldValue, errors, values, setErrors, setTouched } =
    useFormik<CreateIncomingSampleTypeInput>({
      initialValues: {
        collectionId: 0,
        sampleTypeId: 0,
        dateToProcess: new Date(state.dateOfArrival ?? ""),
        quantity: 1,
      },
      validationSchema: CreateIncomingSampleTypeValidationSchema,
      onSubmit: () => void 0,
    });

  const dispatch = useAppReduxDispatch();

  const deleteFormEntry = useMemo(
    () => (index: string) => {
      dispatch(ScheduleIncomingSampleFormActions.REMOVED_SAMPLE(index));
    },
    [dispatch]
  );

  useEffect(() => {
    const currentValue = state.store.find(
      (entry) => entry.key === indexer
    )?.sample;
    if (!_.isEqual(currentValue, values))
      dispatch(
        ScheduleIncomingSampleFormActions.SET_ENTRY_VALUE({
          key: indexer,
          values,
        })
      );
  }, [dispatch, indexer, state, values]);

  useEffect(() => {
    const error = state.store.find((entry) => entry.key === indexer)?.error;
    if (error) {
      setErrors(error);
    }
  }, [indexer, setErrors, setTouched, state.store]);

  useEffect(() => {
    setFieldValue("dateToProcess", state.dateOfArrival);
  }, [setFieldValue, state.dateOfArrival]);

  return (
    <FormSection key={indexer}>
      <StyledFormControl>
        <SourceSampleTypeField
          label={"Sample Type"}
          id={`sourceSampleType-combobox-${indexer}`}
          onChange={(event, newValue) =>
            setFieldValue(
              `sampleTypeId`,
              (newValue as Maybe<LookupSampleType>)?.sampleTypeId
            )
          }
          variant="outlined"
          helperText={errors.sampleTypeId}
          error={!!errors.sampleTypeId}
        />
      </StyledFormControl>
      <StyledFormControl>
        <GroupField
          label={"Group Assigned To"}
          id={`group-combobox-${indexer}`}
          onChange={(event, newValue) =>
            setFieldValue(`group`, (newValue as Maybe<AppSuiteGroup>)?.groupId)
          }
          variant="outlined"
          helperText={errors.group}
          error={!!errors.group}
        />
      </StyledFormControl>
      <StyledFormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label={"Date to Process"}
            value={values?.dateToProcess ?? null}
            onChange={(date) => setFieldValue("dateToProcess", date)}
            minutesStep={15}
            renderInput={(props) => (
              <TextField
                {...props}
                helperText={errors.dateToProcess}
                error={!!errors.dateToProcess}
                fullWidth
                variant={"outlined"}
              />
            )}
          />
        </LocalizationProvider>
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          variant={"outlined"}
          value={values.notes}
          label={"Notes"}
          multiline
          onChange={(e) => setFieldValue(`notes`, e.target.value)}
          helperText={errors.notes}
          error={!!errors.notes}
        />
      </StyledFormControl>
      <StyledFormControl>
        <TextField
          variant={"outlined"}
          value={values.quantity}
          type={"number"}
          label={"Quantity"}
          onChange={(e) => setFieldValue(`quantity`, Number(e.target.value))}
          helperText={errors.quantity}
          error={!!errors.quantity}
        />
      </StyledFormControl>
      <div>
        <IconButton onClick={() => deleteFormEntry(indexer)} size="large">
          <Delete />
        </IconButton>
      </div>
    </FormSection>
  );
}
