import { useGoogleLogin } from "@react-oauth/google";
import { AppTheme } from "App.theme";
import axios from "axios";
import { GetClientURL, GetUrl } from "helpers/get-url";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoginService from "services/AuthService";
import { Action } from "state-provider/actions";
import { useStateProvider } from "state-provider/hooks";
import styled from "styled-components";
import Loading from "../../shared-components/Loading";
import { setGoogleSignInTokenInfo } from "./helpers";

const ButtonContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(0, 0, 0, 0.7);
`;
const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;
interface LogoTextProps {
  subtext?: boolean;
}
const LogoText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: ${(props: LogoTextProps) => (props.subtext ? "3rem" : "6rem")};
  color: white;
  font-weight: 300;
  line-height: 1;
`;
const RedSpan = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;
const GoogleSignInButton = styled.div`
  margin: 10px;
  display: inline-block;
  width: 240px;
  height: 50px;
  background-color: #4285f4;
  color: #fff;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  &&:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
  }
  &&:active {
    background-color: #3367d6;
    transition: background-color 0.2s;
  }
`;
const GoogleImage = styled.img`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImageWrapper = styled.div`
  padding: 15px;
  background: #fff;
  width: 48px;
  height: 100%;
  border-radius: 1px;
  display: inline-block;
`;
const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
`;
const LoginButtonTextContainer = styled.span`
  font-family: Roboto, arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.21px;
  font-size: 16px;
  line-height: 48px;
  vertical-align: top;
  border: none;
  display: inline-block;
  text-align: center;
  width: 180px;
`;

export interface GoogleSignInTokens {
  id_token: string;
  access_token: string;
  expires_in: string;
  refresh_token?: string;
}

export const LoginWithGoogle = () => {
  const {
    state: { loggingIn, loggedIn },
    dispatch,
  } = useStateProvider();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(
      !!(
        (!loggedIn && localStorage.staySignedIn === true.toString()) ||
        loggingIn
      )
    );
  }, [loggedIn, loggingIn]);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [loading]);

  useEffect(() => {
    if (loggedIn && sessionStorage.prevLocation !== "/login")
      history.push(sessionStorage.prevLocation ?? "/");
    else if (loggedIn && window.location.pathname === "/login")
      history.push("/");
  }, [loggedIn]);

  const signIn = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      await axios
        .post(`${GetUrl()}/auth/google`, {
          authorizationCode: codeResponse.code,
          redirectURI: GetClientURL(),
        })
        .then((response) => {
          console.log(response);
          const tokens = response.data as GoogleSignInTokens;
          localStorage.setItem("refreshToken", tokens.refresh_token!);
          setGoogleSignInTokenInfo(tokens);
          LoginService.authCheck().then((response) => {
            if (response.data.me?.userId)
              dispatch({
                type: Action.AUTHENTICATE,
                payload: {
                  loggedIn: true,
                },
              });
          });
        });
    },
    onError: (errorResponse) => console.log(errorResponse),
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
  });
  return (
    <ButtonContainer>
      <Logo>
        <LogoText>
          NY<RedSpan>SC</RedSpan>F
        </LogoText>
      </Logo>
      <div>
        {loading ? (
          <Loading
            name="ball-spin-fade-loader"
            color={AppTheme.palette.primary.main}
          />
        ) : (
          <GoogleSignInButton onClick={() => (loggingIn ? void 0 : signIn())}>
            <ContentWrapper>
              <ImageWrapper>
                <GoogleImage src="https://developers.google.com/identity/images/g-logo.png" />
              </ImageWrapper>
              <LoginButtonTextContainer>
                <span>Sign in with Google</span>
              </LoginButtonTextContainer>
            </ContentWrapper>
          </GoogleSignInButton>
        )}
      </div>
    </ButtonContainer>
  );
};
