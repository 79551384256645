import { useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import DataGrid from "react-data-grid";
import { useDispatch } from "react-redux";
import { ThawToolActions } from "../state";

export const StampMapping = () => {
  const stampMapping = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.worklistValues.thawStamp
  );

  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.destPlateInfo
  );
  const dispatch = useDispatch();
  const columns = [
    {
      key: "plateIndex",
      name: "Destination Plate",
      resizable: true,
      formatter(props: any) {
        return <>{destPlateInfo[props.row.plateIndex].plateBarcode}</>;
      },
    },
    { key: "wellId", name: "Dest Pos", resizable: true },
    { key: "stampVol", name: "Stamp Vol", resizable: true },
  ];
  return (
    <DataGrid
      columns={columns}
      rows={stampMapping}
      onRowsChange={(rows, data) =>
        dispatch(
          ThawToolActions.UPDATE_THAW_STAMP_WORKLIST({
            thawStamp: rows,
          })
        )
      }
    />
  );
};
