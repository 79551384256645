import { PageWithNav } from "features/Navbar/PageWithNav";
import React from "react";
import styled from "styled-components";
import { AddRackInfo } from "../components/AddRackInfo";
import { RacksContainer } from "../components/RacksContainer";

const StyledTubeLabellerLayout = styled.div`
  display: flex;
`;

export const TubeLabeller = () => {
  return (
    <React.Fragment>
      <PageWithNav>
        <StyledTubeLabellerLayout style={{ height: "100%" }}>
          <AddRackInfo />
          <RacksContainer />
        </StyledTubeLabellerLayout>
      </PageWithNav>
    </React.Fragment>
  );
};
