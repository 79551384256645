import { useReactiveVar } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import { PlatePosition } from "features/WorklistTools/shared/interfaces";
import { is384W_CCU_Plate } from "features/WorklistTools/shared/PlateHelpers";
import {
  getDestinationPlateBarcode,
  getLargestPlateBarcodeSeries,
} from "features/WorklistTools/shared/WorklistHelpers";
import { useGetIntermediateDeadAndDestPlateBarcodesMutation } from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import styled from "styled-components";
import { DestPlateStringKeys, Twelve24TCToolActions } from "../../state";
import { useHandleMethodSettingsUpdated } from "../../state/handlers";
import { Twelve24TCToolInternalState } from "../../state/initial-state";
import { AliquotSettings } from "./components/AliquotSettings";
import {
  DefaultVSpinSettings,
  DefaultVSpinSettingsDict,
} from "./components/DefaultVSpinSettings";
import { DissociationWashReagent } from "./components/DissociationWashReagent";
import { PelletResuspensionVolume } from "./components/PelletResuspensionVolume";
import { SelectedSystem } from "./components/SelectedSystem";
import { SelectPlateCode } from "./components/SelectPlateCode";

const StyledMethodSettingsContainer = styled.div`
  height: 500px;
  overflow: auto;
`;

const StyledMethodSettingsContentContainer = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 15px;
  margin-top: 5px;
  margin-right: 15px;
`;

export function MethodSettings({ deadPlateTypeInfo }: any) {
  const twelve24TCInternalState = useReactiveVar(Twelve24TCToolInternalState);
  const twelve24TCState = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present
  );
  const dispatch = useAppReduxDispatch();
  const updateMethodSettings = useHandleMethodSettingsUpdated();

  const handleMethodSettingsChanged = async (key: string, value: any) => {
    switch (key) {
      case "spinParamGForce":
        handleGForceChange(value);
        break;
      case "spinParamPercent":
        handleSpinParamChange(value);
        break;
      case "selectedSystem":
        handleSelectedSystemChange(value);
        break;
      case "dissociation":
        updateMethodSettings("dissociation", value);
        updateMethodSettings("dissociationTime", 0);
        break;
      case "defaultVSpinSettings":
        handleDefaultVSpinSettings(value);
        break;
    }
    updateMethodSettings(key, value);
  };

  const handleDefaultVSpinSettings = (value: boolean) => {
    if (value) {
      DefaultVSpinSettingsDict.forEach((i, val) => {
        updateMethodSettings(val, DefaultVSpinSettingsDict.get(val));
      });
    } else {
      DefaultVSpinSettingsDict.forEach((i, val) => {
        updateMethodSettings(val, 0);
      });
    }
  };

  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.sourcePlateInfo
  );

  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.destPlateInfo
  );

  const deadPlateType = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.deadPlateType
  );

  const handleDestPlateSettingsChanged = (
    index: number,
    key: DestPlateStringKeys,
    value: string | number | boolean
  ) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DESTINATION_PLATE_INFO({
        key,
        value,
      })
    );
  };

  const [getAssociatedBarcodes] =
    useGetIntermediateDeadAndDestPlateBarcodesMutation();

  const getAndSetBarcodes = (plateCode: string) => {
    getAssociatedBarcodes({
      variables: {
        sourcePlateBarcode: getLargestPlateBarcodeSeries(sourcePlateInfo),
        destPlateCode: plateCode,
        hasMultipleSourcePlateTypes: false,
        hasMultipleDestPlateTypes: false,
        has384DeadPlate: is384W_CCU_Plate(deadPlateType),
      },
    }).then((data: any) => {
      const destPlate = getDestinationPlateBarcode(
        data.data.intermediateDeadAndDestPlateBarcodes.destinationPlateBarcode,
        [destPlateInfo]
      );

      handleDestPlateSettingsChanged(0, "plateBarcode", destPlate);
    });
  };

  const handleSpinParamChange = (value: string) => {
    const GForceValue = Math.round(0.101 * Math.pow(parseInt(value), 1.999));
    updateMethodSettings("spinParamGForce", GForceValue);
  };

  const handleGForceChange = (value: string) => {
    const quotient = parseInt(value) / 0.101;
    const spinRPM = Math.round(Math.pow(quotient, 1 / 1.999));
    updateMethodSettings("spinParamPercent", spinRPM);
  };

  const handleSelectedSystemChange = (value: number) => {
    let automationMethodId = 0;
    switch (value) {
      case 3:
        automationMethodId = 391;
        break;
      case 4:
        automationMethodId = 391;
        break;
      case 10:
        automationMethodId = 391;
        break;
    }
    updateMethodSettings("arraySystemAutomationMethodId", automationMethodId);
  };

  const handleDeadPlateBarcodeChange = (value: string) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DEAD_PLATEBARCODE({
        value,
      })
    );
  };

  const handleDeadPlateTypeChange = (value: string) => {
    dispatch(
      Twelve24TCToolActions.UPDATE_DEAD_PLATETYPE({
        value,
      })
    );
  };

  return (
    <StyledMethodSettingsContainer>
      <StyledMethodSettingsContentContainer>
        <SelectedSystem
          twelve24TCInternalState={twelve24TCInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />

        <PelletResuspensionVolume
          twelve24TCInternalState={twelve24TCInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />
        <SelectPlateCode
          twelve24TCInternalState={twelve24TCInternalState}
          handleMethodSettingsChanged={handleDestPlateSettingsChanged}
          getAndSetBarcodes={getAndSetBarcodes}
        />
        <TextField
          size="small"
          value={twelve24TCState.deadPlateBarcode}
          label="Dead PlateBarcode"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleDeadPlateBarcodeChange(e.target.value.toString())
          }
        />
        <PlateType
          platePosition={PlatePosition.Dead}
          isDisabled={
            twelve24TCInternalState.methodSettings!.selectedSystem === 0
          }
          acceptedPlateTypes={deadPlateTypeInfo}
          plateType={twelve24TCState.deadPlateType}
          setPlateType={(plateTypeInfo) =>
            handleDeadPlateTypeChange(plateTypeInfo.labwareTypeCode)
          }
          isRequired={true}
        />
        {twelve24TCState.deadPlateType === "" ? null : (
          <AliquotSettings
            numberOfAliquots={10}
            aliquotVolume={twelve24TCInternalState.methodSettings.aliquotVolume}
            handleMethodSettingsChanged={handleMethodSettingsChanged}
            is96WellPlate={[
              "PE_96_FL_L_6005182",
              "PE_96_FL_L_6055300",
            ].includes(twelve24TCState.deadPlateType)}
          />
        )}
        <DissociationWashReagent
          twelve24TCInternalState={twelve24TCInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={twelve24TCInternalState.methodSettings.dissociation}
              onChange={(e) =>
                handleMethodSettingsChanged("dissociation", e.target.checked)
              }
              name="checkedB"
              color="primary"
              size="small"
            />
          }
          label="Dissociation"
        />
        <NumberField
          size={"small"}
          id="dissociate-time"
          label="Dissociation Time (Minutes)"
          value={twelve24TCInternalState.methodSettings.dissociationTime!}
          onChange={(value) =>
            handleMethodSettingsChanged("dissociationTime", value)
          }
          minValue={twelve24TCInternalState.methodSettings.dissociation ? 1 : 0}
          maxValue={60}
          hasError={
            twelve24TCInternalState.methodSettings.dissociation &&
            !twelve24TCInternalState.methodSettings.dissociationTime
          }
          errorMessage={
            twelve24TCInternalState.methodSettings.dissociation &&
            !twelve24TCInternalState.methodSettings.dissociationTime
              ? "Value can't be undefined"
              : ""
          }
          disabled={!twelve24TCInternalState.methodSettings.dissociation}
        />
        <DefaultVSpinSettings
          twelve24TCInternalState={twelve24TCInternalState}
          handleMethodSettingsChanged={handleMethodSettingsChanged}
        />
        <NumberField
          size={"small"}
          id="spin-gforce"
          label="Spin Params G Force (RCF)"
          value={twelve24TCInternalState.methodSettings.spinParamGForce}
          onChange={(value) =>
            handleMethodSettingsChanged("spinParamGForce", value)
          }
        />
        <NumberField
          size={"small"}
          id="spin-percentage"
          label="Spin Params (%)"
          value={twelve24TCInternalState.methodSettings.spinParamPercent}
          onChange={(value) =>
            handleMethodSettingsChanged("spinParamPercent", value)
          }
          minValue={10}
          maxValue={100}
        />
        <NumberField
          size={"small"}
          id="spin-time"
          label="Spin Time (Minutes)"
          value={twelve24TCInternalState.methodSettings.spinTime}
          onChange={(value) => handleMethodSettingsChanged("spinTime", value)}
          minValue={4}
          maxValue={30}
        />
        <NumberField
          size={"small"}
          id="spin-acceleration"
          label="Acceleration Settings (%)"
          value={twelve24TCInternalState.methodSettings.spinAccel}
          onChange={(value) => handleMethodSettingsChanged("spinAccel", value)}
          minValue={1}
          maxValue={100}
        />
        <NumberField
          size={"small"}
          id="spin-deceleration"
          label="Deceleration Settings (%)"
          value={twelve24TCInternalState.methodSettings.spinDecel}
          onChange={(value) => handleMethodSettingsChanged("spinDecel", value)}
          minValue={1}
          maxValue={100}
        />
      </StyledMethodSettingsContentContainer>
    </StyledMethodSettingsContainer>
  );
}
