export const RowLetterMapping: string[] = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
];

export type OverlayField =
  | "sampleBarcode"
  | "lotNumber"
  | "passageNumber"
  | "contaminatedMarking"
  | "differentiatedMarking"
  | "noCellsMarking"
  | "clonalityMarking"
  | "qcPassFailMarking"
  | "colonyMarking"
  | "sangerSeqMarking"
  | "conReviewMarking"
  | "lastDataPointValue"
  | "nyscfid"
  | "collaboratorId"
  | "sourceCellLine"
  | "fibroblastLotNumber"
  | "spitLotNumber"
  | "bloodProcessingLotNumber"
  | "reprogrammingLotNumber"
  | "consolidationLotNumber"
  | "monoclonalizationLotNumber"
  | "expansionLotNumber"
  | "geneEditingLotNumber"
  | "dnaExtractionLotNumber"
  | "externalLotNumber"
  | "otherLotNumber";

export type WellListField =
  | "sampleBarcode"
  | "sampleBarcodeAliases"
  | "barcode"
  | "lotNumber"
  | "passageNumber"
  | "RowCol"
  | "RowPos"
  | "ColPos"
  | "lastDataPointValue"
  | "lastDataPointSummary"
  | "markingSummary"
  | "nyscfid"
  | "collaboratorId"
  | "sourceCellLine";

export const wellListFieldsMapping = {
  RowCol: "RowCol",
  RowPos: "RowPos",
  ColPos: "ColPos",
  sampleBarcode: "Sample Barcode",
  sampleBarcodeAliases: "Sample Barcode Aliases",
  barcode: "Barcode",
  lotNumber: "Lot Number",
  passageNumber: "Passage Number",
  LastScanDate: "Last Scan Date",
  lastDataPointValue: "Last Data Point Value",
  lastDataPointSummary: "Last Data Point Summary",
  markingSummary: "Marking Summary",
  nyscfid: "NYSCFID",
  collaboratorId: "Collaborator ID",
  sourceCellLine: "Source Cell Line",
  contaminatedMarking: "Contamination Marking",
  differentiatedMarking: "Differentiation Marking",
  noCellsMarking: "No Cells Marking",
  clonalityMarking: "Clonality Marking",
  qcPassFailMarking: "QC Pass/Fail Marking",
  colonyMarking: "Colony Marking",
  sangerSeqMarking: "Sanger Seq Marking",
  conReviewMarking: "Con Review Marking",
  fibroblastLotNumber: "Fibroblast Lot Number",
  spitLotNumber: "Spit Lot Number",
  bloodProcessingLotNumber: "Blood Processing Lot Number",
  reprogrammingLotNumber: "Reprogramming Lot Number",
  consolidationLotNumber: "Consolidation Lot Number",
  monoclonalizationLotNumber: "Monoclonalization Lot Number",
  expansionLotNumber: "Expansion Lot Number",
  geneEditingLotNumber: "Gene Editing Lot Number",
  dnaExtractionLotNumber: "DNA Extraction Lot Number",
  externalLotNumber: "External Lot Number",
  otherLotNumber: "Other Lot Number",
};

export const wellListFieldsArray: WellListField[] = [
  "RowCol",
  "RowPos",
  "ColPos",
  "sampleBarcode",
  "sampleBarcodeAliases",
  "barcode",
  "lotNumber",
  "passageNumber",
  "lastDataPointValue",
  "lastDataPointSummary",
  "markingSummary",
  "nyscfid",
  "collaboratorId",
  "sourceCellLine",
];

export const overlayFieldsArray: OverlayField[] = [
  "sampleBarcode",
  "lotNumber",
  "passageNumber",
  "nyscfid",
  "collaboratorId",
  "sourceCellLine",
  "lastDataPointValue",
  "contaminatedMarking",
  "differentiatedMarking",
  "noCellsMarking",
  "clonalityMarking",
  "qcPassFailMarking",
  "colonyMarking",
  "sangerSeqMarking",
  "conReviewMarking",
  "fibroblastLotNumber",
  "spitLotNumber",
  "bloodProcessingLotNumber",
  "reprogrammingLotNumber",
  "consolidationLotNumber",
  "monoclonalizationLotNumber",
  "expansionLotNumber",
  "geneEditingLotNumber",
  "dnaExtractionLotNumber",
  "externalLotNumber",
  "otherLotNumber",
];

export const SizeMapping = {
  "1Well": {
    width: "650px",
    height: "300px",
    sectionWidth: "1225px",
  },
  "6Well": {
    width: "650px",
    height: "400px",
    sectionWidth: "1225px",
  },
  "12Well": {
    width: "650px",
    height: "400px",
    sectionWidth: "1225px",
  },
  "24Well": {
    width: "650px",
    height: "400px",
    sectionWidth: "1225px",
  },
  "48Well": {
    width: "650px",
    height: "450px",
    sectionWidth: "1225px",
  },
  "96Well": {
    width: "720px",
    height: "480px",
    sectionWidth: "1225px",
  },
  "384Well": {
    width: "815px",
    height: "533px",
    sectionWidth: "1250px",
  },
};

const Palette12Colors = [
  "#F44336",
  "#9C27B0",
  "#3F51B5",
  "#00BCD4",
  "#4CAF50",
  "#FFEB3B",
  "#FF9800",
  "#795548",
  "#607D8B",
  "#626e8a",
  "#Fabed4",
  "#827717",
];

const Palette24Colors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#607D8B",
  "#626e8a",
  "#827717",
  "#Fabed4",
  "#800000",
  "#fffac8",
  "#aaffc3",
  "#dcbeff",
];

const Palette96Colors = [
  "#696969",
  "#808080",
  "#a9a9a9",
  "#dcdcdc",
  "#2f4f4f",
  "#556b2f",
  "#8b4513",
  "#6b8e23",
  "#a0522d",
  "#a52a2a",
  "#2e8b57",
  "#7f0000",
  "#191970",
  "#006400",
  "#808000",
  "#483d8b",
  "#b22222",
  "#5f9ea0",
  "#778899",
  "#008000",
  "#3cb371",
  "#bc8f8f",
  "#663399",
  "#008080",
  "#b8860b",
  "#bdb76b",
  "#cd853f",
  "#4682b4",
  "#d2691e",
  "#9acd32",
  "#20b2aa",
  "#cd5c5c",
  "#00008b",
  "#4b0082",
  "#32cd32",
  "#daa520",
  "#8fbc8f",
  "#8b008b",
  "#b03060",
  "#d2b48c",
  "#48d1cc",
  "#66cdaa",
  "#9932cc",
  "#ff0000",
  "#ff4500",
  "#ff8c00",
  "#ffa500",
  "#ffd700",
  "#6a5acd",
  "#ffff00",
  "#c71585",
  "#0000cd",
  "#40e0d0",
  "#7fff00",
  "#00ff00",
  "#9400d3",
  "#ba55d3",
  "#00fa9a",
  "#00ff7f",
  "#4169e1",
  "#e9967a",
  "#dc143c",
  "#00ffff",
  "#00bfff",
  "#f4a460",
  "#9370db",
  "#0000ff",
  "#a020f0",
  "#f08080",
  "#adff2f",
  "#ff6347",
  "#da70d6",
  "#d8bfd8",
  "#b0c4de",
  "#ff7f50",
  "#ff00ff",
  "#1e90ff",
  "#db7093",
  "#f0e68c",
  "#fa8072",
  "#eee8aa",
  "#ffff54",
  "#6495ed",
  "#dda0dd",
  "#add8e6",
  "#87ceeb",
  "#ff1493",
  "#7b68ee",
  "#ffa07a",
  "#afeeee",
  "#98fb98",
  "#7fffd4",
  "#ffdead",
  "#ff69b4",
  "#ffe4c4",
  "#ffb6c1",
];

const Palette280Colors = [
  "#63b598",
  "#ce7d78",
  "#ea9e70",
  "#a48a9e",
  "#c6e1e8",
  "#648177",
  "#0d5ac1",
  "#f205e6",
  "#1c0365",
  "#14a9ad",
  "#4ca2f9",
  "#a4e43f",
  "#d298e2",
  "#6119d0",
  "#d2737d",
  "#c0a43c",
  "#f2510e",
  "#651be6",
  "#79806e",
  "#61da5e",
  "#cd2f00",
  "#9348af",
  "#01ac53",
  "#c5a4fb",
  "#996635",
  "#b11573",
  "#4bb473",
  "#75d89e",
  "#2f3f94",
  "#2f7b99",
  "#da967d",
  "#34891f",
  "#b0d87b",
  "#ca4751",
  "#7e50a8",
  "#c4d647",
  "#e0eeb8",
  "#11dec1",
  "#289812",
  "#566ca0",
  "#ffdbe1",
  "#2f1179",
  "#935b6d",
  "#916988",
  "#513d98",
  "#aead3a",
  "#9e6d71",
  "#4b5bdc",
  "#0cd36d",
  "#250662",
  "#cb5bea",
  "#228916",
  "#ac3e1b",
  "#df514a",
  "#539397",
  "#880977",
  "#f697c1",
  "#ba96ce",
  "#679c9d",
  "#c6c42c",
  "#5d2c52",
  "#48b41b",
  "#e1cf3b",
  "#5be4f0",
  "#57c4d8",
  "#a4d17a",
  "#225b8",
  "#be608b",
  "#96b00c",
  "#088baf",
  "#f158bf",
  "#e145ba",
  "#ee91e3",
  "#05d371",
  "#5426e0",
  "#4834d0",
  "#802234",
  "#6749e8",
  "#0971f0",
  "#8fb413",
  "#b2b4f0",
  "#c3c89d",
  "#c9a941",
  "#41d158",
  "#fb21a3",
  "#51aed9",
  "#5bb32d",
  "#807fb",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#1c271",
  "#21538e",
  "#89d534",
  "#d36647",
  "#7fb411",
  "#0023b8",
  "#3b8c2a",
  "#986b53",
  "#f50422",
  "#983f7a",
  "#ea24a3",
  "#79352c",
  "#521250",
  "#c79ed2",
  "#d6dd92",
  "#e33e52",
  "#b2be57",
  "#fa06ec",
  "#1bb699",
  "#6b2e5f",
  "#64820f",
  "#1c271",
  "#9cb64a",
  "#996c48",
  "#9ab9b7",
  "#06e052",
  "#e3a481",
  "#0eb621",
  "#fc458e",
  "#b2db15",
  "#aa226d",
  "#792ed8",
  "#73872a",
  "#520d3a",
  "#cefcb8",
  "#a5b3d9",
  "#7d1d85",
  "#c4fd57",
  "#f1ae16",
  "#8fe22a",
  "#ef6e3c",
  "#243eeb",
  "#1dc18",
  "#dd93fd",
  "#3f8473",
  "#e7dbce",
  "#421f79",
  "#7a3d93",
  "#635f6d",
  "#93f2d7",
  "#9b5c2a",
  "#15b9ee",
  "#0f5997",
  "#409188",
  "#911e20",
  "#1350ce",
  "#10e5b1",
  "#fff4d7",
  "#cb2582",
  "#ce00be",
  "#32d5d6",
  "#17232",
  "#608572",
  "#c79bc2",
  "#00f87c",
  "#77772a",
  "#6995ba",
  "#fc6b57",
  "#f07815",
  "#8fd883",
  "#060e27",
  "#96e591",
  "#21d52e",
  "#d00043",
  "#b47162",
  "#1ec227",
  "#4f0f6f",
  "#1d1d58",
  "#947002",
  "#bde052",
  "#e08c56",
  "#28fcfd",
  "#bb09b",
  "#36486a",
  "#d02e29",
  "#1ae6db",
  "#3e464c",
  "#a84a8f",
  "#911e7e",
  "#3f16d9",
  "#0f525f",
  "#ac7c0a",
  "#b4c086",
  "#c9d730",
  "#30cc49",
  "#3d6751",
  "#fb4c03",
  "#640fc1",
  "#62c03e",
  "#d3493a",
  "#88aa0b",
  "#406df9",
  "#615af0",
  "#4be47",
  "#2a3434",
  "#4a543f",
  "#79bca0",
  "#a8b8d4",
  "#00efd4",
  "#7ad236",
  "#7260d8",
  "#1deaa7",
  "#06f43a",
  "#823c59",
  "#e3d94c",
  "#dc1c06",
  "#f53b2a",
  "#b46238",
  "#2dfff6",
  "#a82b89",
  "#1a8011",
  "#436a9f",
  "#1a806a",
  "#4cf09d",
  "#c188a2",
  "#67eb4b",
  "#b308d3",
  "#fc7e41",
  "#af3101",
  "#ff065",
  "#71b1f4",
  "#a2f8a5",
  "#e23dd0",
  "#d3486d",
  "#00f7f9",
  "#474893",
  "#3cec35",
  "#1c65cb",
  "#5d1d0c",
  "#2d7d2a",
  "#ff3420",
  "#5cdd87",
  "#a259a4",
  "#e4ac44",
  "#1bede6",
  "#8798a4",
  "#d7790f",
  "#b2c24f",
  "#de73c2",
  "#d70a9c",
  "#25b67",
  "#88e9b8",
  "#c2b0e2",
  "#86e98f",
  "#ae90e2",
  "#1a806b",
  "#436a9e",
  "#0ec0ff",
  "#f812b3",
  "#b17fc9",
  "#8d6c2f",
  "#d3277a",
  "#2ca1ae",
  "#9685eb",
  "#8a96c6",
  "#dba2e6",
  "#76fc1b",
  "#608fa4",
  "#20f6ba",
  "#07d7f6",
  "#dce77a",
  "#77ecca",
];

export const OverlayColors = {
  12: Palette12Colors,
  24: Palette24Colors,
  96: Palette96Colors,
  280: Palette280Colors,
};
