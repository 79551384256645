import { RouteWithErrorBoundary } from "App.routes";
import { Nav } from "features/Navbar/Nav";
import { PlatesTable } from "features/Plates/components/Table/Table";
import { useLayoutStateProvider } from "features/Plates/state/StateProvider";
import React, { FC, useMemo } from "react";
import { useRouteMatch } from "react-router";
import styled from "styled-components/macro";
import { SampleModalContainer } from "../components/ModalsAndDialogs/SampleModalContainer";
import { SampleModalContent } from "../components/ModalsAndDialogs/SampleModalContent";
import { PlateContext } from "../components/PlateInfo/PlateContext";
import { PlateInfoSection } from "../components/PlateInfo/PlateInfoSection";

export const StyledPageLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100vw;
  min-height: 100vh;
  overflow-y: ${({ disableScroll }: { disableScroll?: true | never }) =>
    disableScroll ? "hidden" : ""};
  height: ${({ disableScroll }: { disableScroll?: true | never }) =>
    disableScroll ? "100vh" : ""};
`;

const DashboardLayout = styled.div<{
  wellListOpen: boolean;
}>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.wellListOpen
      ? `minmax(224px, 16vw) auto`
      : `minmax(224px, 20vw) 1fr`};
  grid-template-rows: minmax(50px, 8vh) minmax(71px, 12vh) minmax(500px, 73vh);
`;

const TableSection = styled.div`
  min-width: 10vw;
  grid-column: 1 / 2;
  grid-row: 1 / 4;
`;

export const PlateRouteContext: FC = ({ children }) => {
  const match = useRouteMatch();
  const path = `${match.url}/:plateBarcode?`;
  return (
    <RouteWithErrorBoundary path={path}>{children}</RouteWithErrorBoundary>
  );
};

export function Plates(): JSX.Element {
  const {
    layoutState: { wellListOpen },
  } = useLayoutStateProvider();

  const renderPage = useMemo(() => {
    console.log("rendering page.", "well list open?", wellListOpen);
    return (
      <PlateRouteContext>
        <PlateContext>
          <StyledPageLayout disableScroll={true}>
            <Nav>
              <React.Fragment />
            </Nav>
            <SampleModalContainer>
              <SampleModalContent />
            </SampleModalContainer>
            <DashboardLayout wellListOpen={wellListOpen ?? false}>
              <TableSection>
                <PlatesTable />
              </TableSection>
              <PlateInfoSection />
            </DashboardLayout>
          </StyledPageLayout>
        </PlateContext>
      </PlateRouteContext>
    );
  }, [wellListOpen]);

  return <React.Fragment>{renderPage}</React.Fragment>;
}
