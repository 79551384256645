import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { FormikHelpers } from "formik";
import {
  RunTaskInput,
  TaskStatusEnum,
  useUpdateRunTasksMutation,
} from "graphql/generated/schema-types";
import produce from "immer";
import { Alert, AlertType } from "../toast";
import { DateShift, ShiftDate } from "./Rescheduler";

export const useGetRescheduleHandleSubmit = (inputs: RunTaskInput[]) => {
  const { runsModalDispatch } = useRunsModalState();
  const [updateRunTasks] = useUpdateRunTasksMutation();
  return (values: DateShift, { setSubmitting }: FormikHelpers<DateShift>) => {
    if (Object.entries(values).every((entry) => entry[1] === 0)) {
      Alert({
        type: AlertType.ERROR,
        message: "Please enter new start time",
        longMessage: "Please enter new start time",
      });
      setSubmitting(false);
      return;
    }

    // GetRunAndTasksForReschedule in parent returns [undefined] if single completed or cancelled task
    if (inputs?.[0] === undefined) {
      Alert({
        type: AlertType.ERROR,
        message:
          "You cannot reschedule a task marked 'Completed' or 'Cancelled'.",
      });

      runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
      setSubmitting(false);
      return;
    }

    const tasksInProgressAlert = () => {
      const noTasksInProgress = () =>
        inputs?.every((rt) => rt.status !== TaskStatusEnum.InProgress);

      if (noTasksInProgress()) return false;

      Alert({
        type: AlertType.ERROR,
        message: `You cannot reschedule a task marked 'In Progress'.`,
      });

      setSubmitting(false);
      runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
      return true;
    };

    if (tasksInProgressAlert()) {
      return;
    }

    const updateInputs = inputs.map((entry, index) =>
      produce(entry, (draft) => {
        if (!draft) return;
        const startTimeScheduled = draft.startTimeScheduled;
        const endTimeActual = draft.endTimeActual;
        const endTimeScheduled = draft.endTimeScheduled;
        const startTimeActual = draft.startTimeActual;
        const dateOnly = index !== 0;
        draft.startTimeScheduled = ShiftDate(
          startTimeScheduled,
          values,
          dateOnly
        ).toISOString();
        draft.startTimeActual = ShiftDate(
          startTimeActual,
          values,
          dateOnly
        ).toISOString();
        draft.endTimeActual = ShiftDate(
          endTimeActual,
          values,
          dateOnly
        ).toISOString();
        draft.endTimeScheduled = ShiftDate(
          endTimeScheduled,
          values,
          dateOnly
        ).toISOString();
      })
    );
    return updateRunTasks({
      variables: {
        updateInputs,
      },
    }).then(() => {
      runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
      Alert({ type: AlertType.SUCCESS, message: "Task(s) Rescheduled" });
    });
  };
};
