import { useReactiveVar } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { DestinationPlate } from "features/WorklistTools/shared/components/DestinationPlate";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import {
  PlatePosition,
  SelectionProcess,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import { is96WPlate } from "features/WorklistTools/shared/PlateHelpers";
import {
  getDestinationPlateBarcode,
  getPlateCodeForSameLabwareType,
  setDestPlateType,
} from "features/WorklistTools/shared/WorklistHelpers";
import { useGetPlateBarcodePreviewMutation } from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React, { useState } from "react";
import { SelectableGroup } from "react-selectable-fast";
import styled from "styled-components";
import { addAllStampVolumes, getMaxStampVol } from "../helpers";
import { StampVolDialog } from "../StampVolDialog";
import { DestPlateInfoState, ThawToolActions } from "../state";
import { ThawToolInternalAction } from "../state/action";
import { useHandleMethodSettingsUpdated } from "../state/handlers";
import { ThawToolInternalState } from "../state/initial-state";
import { useThawToolStateProvider } from "../state/StateProvider";
import { ThawStampContainer } from "../ThawStampContainer";
import { selectDestWells } from "./handlers/SelectDestinationWells";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  max-width: 100%;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr;
`;

const StyledPlateSettingsContainer = styled.div`
  margin-top: 10px;
`;

export const DestinationPlatesContainer = (destPlateTypeInfo: any) => {
  const thawInternalState = useReactiveVar(ThawToolInternalState);
  const { thawToolDispatch } = useThawToolStateProvider();
  const dispatch = useAppReduxDispatch();
  const [stampVolOpen, setStampVolOpen] = useState(false);
  const [getPlateBarcodePreview] = useGetPlateBarcodePreviewMutation();
  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.sourcePlateInfo
  );
  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.destPlateInfo
  );

  const worklistValues = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.worklistValues
  );
  const updateMethodSettings = useHandleMethodSettingsUpdated();
  const handleDestinationWellSelection = (destSelection: any[]) => {
    selectDestWells(
      thawInternalState,
      sourcePlateInfo[0],
      destPlateInfo,
      worklistValues.thawTransfer,
      dispatch,
      destSelection,
      setStampVolOpen
    );
  };

  const handleStampVolClose = (vol: number) => {
    setStampVolOpen(false);
    dispatch(
      ThawToolActions.UPDATE_STAMP_VOL({
        index: worklistValues.thawStamp.length - 1,
        stampVol: vol,
      })
    );
    const topupVol =
      destPlateInfo[0].operatingVol -
      getMaxStampVol(worklistValues.thawStamp, vol);
    updateMethodSettings("topupVol", topupVol < 0 ? 0 : topupVol);
    const resuspVol = addAllStampVolumes(worklistValues.thawStamp, vol);
    updateMethodSettings("resuspensionVol", resuspVol);
  };

  const handlePlateTypeChange = (index: number, plateTypeInfo: any) => {
    if (
      is96WPlate(plateTypeInfo.plateRows.length, plateTypeInfo.plateCols.length)
    ) {
      thawToolDispatch({
        type: ThawToolInternalAction.SET_DEST_SELECTION_PROCESS,
        payload: {
          destSelectionProcess: SelectionProcess.Stamp,
        },
      });
    }
    plateTypeInfo = setDestPlateType(plateTypeInfo);
    dispatch(
      ThawToolActions.CLEAR_DESTINATION_WORKLIST_VALUES({
        index,
      })
    );
    getPlateBarcodePreview({
      variables: {
        methodId: 102,
        sourcePlateBarcodes: [sourcePlateInfo[0].plateBarcode],
        destPlateCode:
          getPlateCodeForSameLabwareType(
            plateTypeInfo.labwareTypeCode,
            destPlateInfo.map((item: any) => ({
              plateBarcode: item.plateBarcode,
              labwareTypeCode: item.labwareTypeCode,
            }))
          ) ?? plateTypeInfo.labwarePlateType,
      },
    }).then((data) => {
      const plateBarcode = getDestinationPlateBarcode(
        data!.data!.plateBarcodePreview![2]!.plateBarcode!,
        destPlateInfo
      );
      dispatch(
        ThawToolActions.SET_DESTINATION_PLATE({
          index,
          rows: plateTypeInfo.plateRows,
          cols: plateTypeInfo.plateCols,
          labwareTypeCode: plateTypeInfo.labwareTypeCode,
          plateBarcode: plateBarcode,
          operatingVol: plateTypeInfo.maxLabwareVolume,
        })
      );

      dispatch(
        ThawToolActions.SET_DEAD_PLATEBARCODE({
          deadPlateBarcode: data!.data!.plateBarcodePreview![1]!.plateBarcode!,
        })
      );
    });
  };

  const handleDestPlateSettingsChanged = (
    index: number,
    key: string,
    value: string | number | boolean
  ) => {
    dispatch(
      ThawToolActions.UPDATE_DESTINATION_PLATE_INFO({
        index,
        key,
        value,
      })
    );
  };

  const handleRemovePlateClick = (index: number) => {
    dispatch(
      ThawToolActions.CLEAR_DESTINATION_PLATE({
        index,
      })
    );
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplate: "1fr 1fr 1fr 1fr / 50% 50%",
        height: "calc(100vh - 100px)",
        overflowY: "auto",
      }}
    >
      {destPlateInfo.map((item: DestPlateInfoState, index: number) => (
        <div style={{ padding: "20px" }}>
          <PlateBarcodeField
            index={index}
            label={"Destination Plate Barcode"}
            plateBarcode={item.plateBarcode}
            handlePlateBarcodeChange={handleDestPlateSettingsChanged}
          />
          <StyledSelectableGroup
            onSelectionFinish={handleDestinationWellSelection}
            resetOnStart={true}
          >
            <DestinationPlate
              worklistTool={WorklistTools.Thaw}
              index={index}
              plateRows={item.rows}
              plateCols={item.cols}
              sourcePlateInfo={sourcePlateInfo}
              wellMapping={worklistValues.thawTransfer}
              sourcePlatePosition={PlatePosition.Source}
            />
          </StyledSelectableGroup>
          <StyledPlateActions>
            <Button
              variant="outlined"
              disabled={item.rows.length < 1}
              onClick={() => handleRemovePlateClick(index)}
            >
              Remove Plate
            </Button>
          </StyledPlateActions>
          <StyledPlateSettingsContainer>
            <PlateType
              platePosition={PlatePosition.Destination}
              isDisabled={
                (index > 0 && destPlateInfo[index - 1].rows.length === 0) ||
                thawInternalState.methodSettings!.selectedSystem === 0
              }
              acceptedPlateTypes={destPlateTypeInfo.destPlateInfo}
              plateType={item.labwareTypeCode}
              setPlateType={(plateTypeInfo) =>
                handlePlateTypeChange(index, plateTypeInfo)
              }
            />
            <ThawStampContainer index={index} />
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Preprocess Plate"
                    checked={destPlateInfo[index].preprocessPlate}
                    onChange={(e) =>
                      handleDestPlateSettingsChanged(
                        index,
                        "preprocessPlate",
                        e.target.checked
                      )
                    }
                  />
                }
                label="Preprocess Plate"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="In Cytomat"
                    checked={destPlateInfo[index].inCytomat}
                    onChange={(e) =>
                      handleDestPlateSettingsChanged(
                        index,
                        "inCytomat",
                        e.target.checked
                      )
                    }
                  />
                }
                label="In Cytomat"
              />
            </FormGroup>
          </StyledPlateSettingsContainer>
        </div>
      ))}
      <StampVolDialog
        isOpen={stampVolOpen}
        handleClose={(vol) => handleStampVolClose(vol)}
      />
    </div>
  );
};
