import { useReactiveVar } from "@apollo/client";
import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { assetInputMapper } from "mappings/assetInput";
import { CreateAssetForm } from "./Asset/Form";
import { AddOptionState, AddTicketOption } from "./Form";
const FormReducer = (entry: AddTicketOption["entry"]) => {
  switch (entry?.__typename) {
    case "LookupAsset":
      const input = assetInputMapper(entry);
      input.asset = input.asset?.match(/"([^']+)"/)?.[1];
      return <CreateAssetForm initialValues={input} />;
    default:
      return null;
  }
};
export function AddOptionDialog(): JSX.Element {
  const { dialogOpen, entry } = useReactiveVar(AddOptionState);
  return (
    <Dialog
      open={dialogOpen}
      onClose={() => AddOptionState({ dialogOpen: false, entry: undefined })}
    >
      <DialogContent>{FormReducer(entry)}</DialogContent>
    </Dialog>
  );
}
