import { useReactiveVar } from "@apollo/client";
import { Worklist } from "features/WorklistTools/shared/interfaces";
import { useAppReduxSelector } from "hooks/reduxHooks";
import {
  buildAuxPlateBarcodeWorklist,
  buildDiscardSourcePlateWorklist,
  //buildAuxPlateBarcodeWorklist,
  buildHarvestWellsWorklist,
  buildMappingFileWorklist,
  buildMethodSettingsWorklist,
  buildOperatingVolumeWorklist,
  buildTopUpPostSplitWorklist,
} from "../Twelve24TCToolHelpers";
import { Twelve24TCToolInternalAction } from "./action";
import { Twelve24TCToolInternalState } from "./initial-state";
import { useTwelve24TCStateProvider } from "./StateProvider";

export const useHandleMethodSettingsUpdated = () => {
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  return (key: string, value: any) => {
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.UPDATE_METHOD_SETTINGS,
      payload: {
        key,
        value,
      },
    });
  };
};

export const useSetRequiredWarnings = () => {
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  return (value: string[]) => {
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_REQUIRED_WARNINGS,
      payload: {
        requiredWarnings: value,
      },
    });
  };
};

export const useSetOptionalWarnings = () => {
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  return (value: string[]) => {
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_OPTIONAL_WARNINGS,
      payload: {
        optionalWarnings: value,
      },
    });
  };
};

export const useSetWorklist = () => {
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  return (worklist: any[]) => {
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_WORKLIST,
      payload: {
        worklist: worklist,
        worklistPreviewOpen: true,
      },
    });
  };
};

export const useSetWorkistPreviewOpen = () => {
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  return (worklistPreviewOpen: boolean) => {
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_WORKLIST_PREVIEW_OPEN,
      payload: {
        worklistPreviewOpen: worklistPreviewOpen,
      },
    });
  };
};

export const useGenerateWorklistClick = () => {
  const twelve24TCInternalState = useReactiveVar(Twelve24TCToolInternalState);
  const { twelve24TCToolDispatch } = useTwelve24TCStateProvider();
  const worklistValues = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.worklistValues
  );
  const sourcePlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.sourcePlateInfo
  );
  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.destPlateInfo
  );

  const deadPlateBarcode = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.deadPlateBarcode
  );

  const deadPlateType = useAppReduxSelector(
    (state) => state.WorklistTools.Twelve24TCTool.present.deadPlateType
  );

  //Exclude harvest wells if intermediate plate was uploaded
  const harvestWells = false
    ? []
    : buildHarvestWellsWorklist(
        worklistValues.harvestWells,
        sourcePlateInfo,
        [destPlateInfo],
        twelve24TCInternalState.methodSettings
      );

  const labwareOperatingVolumes = buildOperatingVolumeWorklist([destPlateInfo]);

  const discardSourcePlate = buildDiscardSourcePlateWorklist(sourcePlateInfo);

  const methodSettingsWorklist = buildMethodSettingsWorklist(
    twelve24TCInternalState,
    worklistValues,
    true
  );

  const topUpPostSplit = buildTopUpPostSplitWorklist(
    [destPlateInfo]
  );

  const auxPlateBarcodes = buildAuxPlateBarcodeWorklist(
    sourcePlateInfo, //intPlateInfo,
    deadPlateBarcode,
    deadPlateType,
    twelve24TCInternalState.methodSettings
  );

  const mappingFileKeywords = buildMappingFileWorklist(
    sourcePlateInfo,
    [destPlateInfo]
  );

  let worklist: Worklist[] = [];

  worklist = [
    ...harvestWells,
    ...discardSourcePlate,
    ...labwareOperatingVolumes,
    ...methodSettingsWorklist,
    ...topUpPostSplit,
    ...auxPlateBarcodes,
    ...mappingFileKeywords,
  ];

  return () => {
    twelve24TCToolDispatch({
      type: Twelve24TCToolInternalAction.SET_WORKLIST,
      payload: {
        worklist,
        worklistPreviewOpen: true,
      },
    });
  };
};
