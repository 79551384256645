import { ModalAction } from "features/Plates/state/actions";
import { useModalStateProvider } from "features/Plates/state/StateProvider";
import React from "react";
import { Item, Menu } from "react-contexify";
import { SampleModals } from "../ModalsAndDialogs/model.enum";

export function PlateMapContextMenu(): JSX.Element {
  const { modalDispatch } = useModalStateProvider();

  return (
    <Menu id="PlateMapContextMenu">
      <Item
        onClick={() => {
          modalDispatch({
            type: ModalAction.OPEN_MODAL,
            payload: {
              currentModal: SampleModals.LineageModal,
              modalOpen: true,
            },
          });
        }}
      >
        View Container Lineage
      </Item>
    </Menu>
  );
}
