import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import { IAction, IState, State } from "state-provider/initial-state";
import { Reducer } from "state-provider/reducer";

export function useStateProvider(): {
  state: IState;
  dispatch: IApolloDispatch<IAction>;
} {
  const state = useReactiveVar(State);
  const dispatch = useMemo(() => ApolloStateDispatch(State, Reducer), []);
  return useMemo(
    () => ({
      state,
      dispatch,
    }),
    [dispatch, state]
  );
}
