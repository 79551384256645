import { FetchResult, MutationFunctionOptions } from "@apollo/client";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  OperationNames,
  RunTaskInput,
  UpdateRunTasksMutation,
  UpdateRunTasksMutationVariables,
} from "graphql/generated/schema-types";
import { useMemo } from "react";
import { Alert, AlertType } from "shared-components/toast";

export function useGetHandleConfirm(
  setSubmitting: (value: ((prevState: boolean) => boolean) | boolean) => void,
  updateRunActivityTasks: (
    options?: MutationFunctionOptions<
      UpdateRunTasksMutation,
      UpdateRunTasksMutationVariables
    >
  ) => Promise<FetchResult<UpdateRunTasksMutation>>,
  updateInputs: RunTaskInput[]
) {
  const { runsModalDispatch } = useRunsModalState();
  return useMemo(
    () => () => {
      setSubmitting(true);
      updateRunActivityTasks({
        variables: {
          updateInputs,
        },
        refetchQueries: [OperationNames.Query.getFolderDirectory],
      })
        .catch((e) => {
          setSubmitting(false);
          throw e;
        })
        .then(() => {
          runsModalDispatch({
            type: RunsModalActions.CONFIRMED_CHANGES,
          });
          setSubmitting(false);
          Alert({ type: AlertType.SUCCESS, message: "Changes Saved" });
        });
    },
    [runsModalDispatch, setSubmitting, updateInputs, updateRunActivityTasks]
  );
}
