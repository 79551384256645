import { ApolloQueryResult } from "@apollo/client";
import {
  AppSuiteGroup,
  AppSuiteUser,
  GetMyPersonalTasksQuery,
  GetPersonalTaskTemplatesQuery,
  GetPersonalTaskTemplatesQueryVariables,
  PersonalTaskTemplate,
  SortEnumType,
} from "graphql/generated/schema-types";
import { MutateState } from "helpers/object-helpers";
import { FetchMore } from "interfaces/FetchMore";
import { Dispatch, SetStateAction } from "react";
import { Filters, Row, SortingRule } from "react-table";
import { ReactTablePaginationState } from "shared-components/Table/ReactTable";
import { usePersonalTaskTemplateStateProvider } from "../../state/StateProvider";
import { PersonalTaskTemplateAction } from "../../state/actions";
import { PersonalTaskTemplateFilterReducer } from "./filter";

export const useHandleRowClick = () => {
  const { personalTaskTemplateDispatch } =
    usePersonalTaskTemplateStateProvider();
  return (rowClickeddd?: Row<PersonalTaskTemplate>) => () => {
    if (rowClickeddd?.original?.personalTaskTemplateId) {
      personalTaskTemplateDispatch({
        type: PersonalTaskTemplateAction.SELECT,
        payload: {
          selectedPersonalTaskTemplateId:
            rowClickeddd.original.personalTaskTemplateId,
          selectedPersonalTaskTemplateName:
            rowClickeddd.original.personalTaskTemplateName,
          selectedPersonalTasksTemplateTask:
            rowClickeddd.original.personalTaskTemplateTasks,
        },
      });
    }
  };
};

export const useHandleTaskClick = () => {
  const { personalTaskTemplateDispatch } =
    usePersonalTaskTemplateStateProvider();
  return (rowClick: any) => () => {
    console.log(rowClick);
    const value = {
      assignedUsers: rowClick.users.map((user: AppSuiteUser) => {
        return user.userId;
      }),
      assignedGroups: rowClick.groups.map((group: AppSuiteGroup) => {
        return group.groupId;
      }),
      assignedSystem: rowClick.assignedSystem,
      dayScheduled: rowClick.dayScheduled,
      notes: rowClick.notes,
      priority: rowClick.priority,
      personalTaskName: rowClick.personalTaskName,
      personalTaskTemplateTaskId: rowClick.personalTaskTemplateTaskId,
    };
    personalTaskTemplateDispatch({
      type: PersonalTaskTemplateAction.SELECT_TASK,
      payload: {
        selectedPersonalTask: value,
      },
    });
  };
};

export const getHandlePageCountChange =
  (
    variables: GetPersonalTaskTemplatesQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetPersonalTaskTemplatesQueryVariables>
        ) => Promise<ApolloQueryResult<GetMyPersonalTasksQuery>>)
      | undefined,
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ): ((newPageCount: number) => void) =>
  (newPageCount: number) => {
    if (refetch)
      refetch({
        ...variables,
        pageSize: newPageCount,
      })
        .then(() =>
          setPaginationState({
            ...paginationState,
            rowsPerPage: newPageCount,
            page: 0,
          })
        )
        .catch((error) => console.log(error));
  };

export const getPageChangeHandler =
  (
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ],
    fetchMore?: FetchMore<
      GetPersonalTaskTemplatesQueryVariables,
      GetPersonalTaskTemplatesQuery
    >,
    endCursor?: string | null,
    variables?: GetPersonalTaskTemplatesQueryVariables
  ): ((
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => void) =>
  (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    if (paginationState.page < newPage && fetchMore) {
      fetchMore({
        variables: {
          ...variables,
          after: endCursor,
        },
      })
        .then(() => setPaginationState({ ...paginationState, page: newPage }))
        .catch((err) => console.log(err));
    } else if (paginationState.page > newPage) {
      setPaginationState({ ...paginationState, page: newPage });
    }
  };

export const getFilterHandler =
  (
    variables: GetPersonalTaskTemplatesQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetPersonalTaskTemplatesQueryVariables>
        ) => Promise<ApolloQueryResult<GetPersonalTaskTemplatesQuery>>)
      | undefined
  ) =>
  async (filters: Filters<PersonalTaskTemplate>): Promise<void> => {
    const _state = MutateState(variables, (draft) => {
      draft!.where = PersonalTaskTemplateFilterReducer(filters);
    });
    console.log(_state);
    if (refetch && _state.hasChanged) {
      await refetch(_state.newValue);
    }
  };

export const getSortHandler =
  (
    variables: GetPersonalTaskTemplatesQueryVariables | undefined,
    refetch:
      | ((
          variables?: Partial<GetPersonalTaskTemplatesQueryVariables>
        ) => Promise<ApolloQueryResult<GetPersonalTaskTemplatesQuery>>)
      | undefined,
    [paginationState, setPaginationState]: [
      ReactTablePaginationState,
      Dispatch<SetStateAction<ReactTablePaginationState>>
    ]
  ) =>
  async (sorts: SortingRule<PersonalTaskTemplate>[]): Promise<void> => {
    if (sorts.length > 0 && refetch) {
      await refetch({
        ...variables,
        order_by: sorts.map((sortRule) => ({
          [sortRule.id]: sortRule.desc ? SortEnumType.Desc : SortEnumType.Asc,
        })),
      });
    } else if (refetch) {
      const _state = MutateState(variables, (draft) => {
        draft!.order_by = undefined;
      });
      if (_state.hasChanged) await refetch(_state.newValue);
    }
    if (paginationState.page !== 0)
      setPaginationState({ ...paginationState, page: 0 });
  };
