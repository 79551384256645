/* eslint-disable react-hooks/exhaustive-deps */
import { TableCell, TableRow } from "@mui/material";
import { ModalAction, WellAction } from "features/Plates/state/actions";
import {
  useModalStateProvider,
  useWellStateProvider,
} from "features/Plates/state/StateProvider";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React, { useEffect, useMemo, useRef } from "react";
import { contextMenu } from "react-contexify";
import styled from "styled-components";
import { wellListFieldsArray } from "../constants";
import { getWellAsString, IWell, Plate } from "../helpers";

export const StyledTableRow = styled(TableRow)<{
  $selected: boolean;
}>`
  scroll-margin-top: 81px;
  background-color: ${(props) =>
    props.$selected ? `rgba(204, 40, 40, 0.2)` : `rgba(255, 255, 255, 0)`};
  &:hover {
    background-color: rgba(204, 40, 40, 0.2);
    cursor: pointer;
  }
`;

export const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${GetTableCellBorder};
  width: 100%;
`;

export function WellListRow({
  well,
  selectedPlate,
}: {
  well: IWell;
  selectedPlate: Plate;
}): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const {
    wellState: { currentWell, fromPlateMap },
    wellDispatch,
  } = useWellStateProvider();

  const { modalDispatch } = useModalStateProvider();

  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//

  const handleHover = (well: IWell, enter: boolean) => () => {
    // console.log("hovering");
    if (enter) {
      wellDispatch({
        type: WellAction.TOGGLE_SELECT_WELL,
        payload: {
          currentWell: well,
          fromPlateMap: false,
        },
      });
    } else
      wellDispatch({
        type: WellAction.TOGGLE_SELECT_WELL,
        payload: {
          currentWell: undefined,
          fromPlateMap: undefined,
        },
      });
  };

  const handleContextMenu =
    (well: IWell) => (e: React.MouseEvent<HTMLTableRowElement>) => {
      console.log("context menu");
      e.preventDefault();
      modalDispatch({
        type: ModalAction.SELECT_CONTAINER,
        payload: {
          selectedContainer: well.containerId ?? undefined,
        },
      });
      contextMenu.show({
        id: "WellListContextMenu",
        event: e,
      });
    };

  const rowRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    if (
      rowRef &&
      currentWell &&
      fromPlateMap &&
      getWellAsString(currentWell) === getWellAsString(well)
    ) {
      // console.log("scrolling into view", rowRef.current);
      rowRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [
    currentWell !== undefined &&
      getWellAsString(currentWell) === getWellAsString(well),
  ]);

  const createRow = useMemo(() => {
    // if (currentWell == undefined) {
    //     console.log(
    //         "initial rendering of row",
    //         `row${well.RowPos} col${well.ColPos}`
    //     );
    // } else {
    //     console.log(
    //         "rerender of row",
    //         `row${well.RowPos} col${well.ColPos}`,
    //         `chosen well is row${currentWell?.RowPos} col${currentWell?.ColPos}`
    //     );
    // }
    return (
      <StyledTableRow
        $selected={getWellAsString(well) === getWellAsString(currentWell)}
        onMouseEnter={handleHover(well, true)}
        onMouseLeave={handleHover(well, false)}
        onContextMenu={handleContextMenu(well)}
        ref={rowRef}
      >
        {wellListFieldsArray.map((field, idx) => (
          <StyledTableCell key={idx}>{well[field]}</StyledTableCell>
        ))}
      </StyledTableRow>
    );
  }, [
    selectedPlate,
    currentWell !== undefined &&
      getWellAsString(currentWell) === getWellAsString(well) &&
      fromPlateMap,
  ]);

  return <React.Fragment>{createRow}</React.Fragment>;
}
