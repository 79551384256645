import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import {
  PersonalTaskTemplate,
  useStartPersonalTaskTemplateMutation,
} from "graphql/generated/schema-types";
import { useState } from "react";
import { Alert, AlertType } from "shared-components/toast";

interface StartTaskTemplateProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedTemplate: PersonalTaskTemplate | undefined;
}

export const StartTaskTemplate = ({
  isOpen,
  handleClose,
  selectedTemplate,
}: StartTaskTemplateProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [startTemplate] = useStartPersonalTaskTemplateMutation();

  const handleStartTaskTemplateClick = () => {
    const obj = {
      personalTaskTemplateId: selectedTemplate!.personalTaskTemplateId,
      startDate,
    };
    console.log(obj);
    startTemplate({
      variables: {
        input: obj,
      },
    }).then((res) => {
      if (res.data) {
        Alert({
          type: AlertType.SUCCESS,
          message: "Template Started",
        });
        handleClose();
      }
    });
  };
  console.log(selectedTemplate);
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{`Start '${
        selectedTemplate ? selectedTemplate!.personalTaskTemplateName! : "Task"
      }' Template`}</DialogTitle>
      <DialogContent>
        <div style={{ marginTop: "10px" }}>
          <DatePicker
            label="Start Date"
            renderInput={(props) => <TextField {...props} />}
            value={startDate}
            onChange={(newValue: any) => setStartDate(newValue)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleStartTaskTemplateClick}>Start</Button>
      </DialogActions>
    </Dialog>
  );
};
