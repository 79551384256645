import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface WarningPopupProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const WarningPopup = ({ isOpen, handleClose }: WarningPopupProps) => {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>WARNING</DialogTitle>
      <DialogContent>
        This tool is in development. If you have any questions or concerns about
        the worklist, slack Geoff
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
