import styled from "styled-components/macro";
import { CreateCollectionForm } from "../../Forms/ScheduleSamples/CreateCollectionForm";
import { useGetHandleCreateCollectionSubmit } from "./handleSubmit";

const Layout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.08);
  max-height: 94vh;
`;

export function ScheduleSamplesScreen(): JSX.Element {
  const handleSubmit = useGetHandleCreateCollectionSubmit();
  return (
    <Layout>
      <CreateCollectionForm onSubmit={handleSubmit} />
    </Layout>
  );
}
