import { Skeleton } from "@mui/material";
import { PlateGroupAccordion } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/PlateGroupAccordion";
import { PossibleComponents } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/PlateGroupAccordionContainer";
import { ReadOnlyPlateGroupAccordion } from "features/WorklistTools/CeligoScan/Dialog/PlatesTable/ReadOnlyPlateGroupAccordion";
import { CeligoPlateMetadata } from "features/WorklistTools/CeligoScan/Dialog/state";
import {
  GetCeligoPlateMetadataQuery,
  GroupedPlateMetadata,
  MethodReservation,
} from "graphql/generated/schema-types";
import React from "react";
import styled from "styled-components";

const NoPlatesAvailable = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  color: ${({ theme }) => (theme.palette.mode === "dark" ? "white" : "black")};
`;

export function getComponent(
  groupedPlateMetadataLoading: boolean,
  alreadyReserved: boolean,
  accordionDataTuple: [
    (GroupedPlateMetadata & { expanded: boolean })[],
    React.Dispatch<
      React.SetStateAction<(GroupedPlateMetadata & { expanded: boolean })[]>
    >
  ],
  methodReservation: null | undefined | MethodReservation,
  data?: GetCeligoPlateMetadataQuery
) {
  let component: PossibleComponents | undefined;
  const loading = groupedPlateMetadataLoading && !data?.celigoScanPlateMetadata;
  const noPlatesAvailable = CeligoPlateMetadata().length === 0;

  if (loading) component = PossibleComponents.LoadingComponent;
  else if (noPlatesAvailable)
    component = PossibleComponents.NoPlatesAvailableComponent;
  else if (alreadyReserved)
    component = PossibleComponents.ReadOnlyPlateGroupAccordionComponent;
  else component = PossibleComponents.PlateGroupAccordionComponent;

  const Reducer = (component: PossibleComponents) => {
    switch (component) {
      case PossibleComponents.LoadingComponent:
        return (
          <>
            {new Array(8).fill(0).map((_, idx) => (
              <Skeleton key={idx} height={"70px"} />
            ))}
          </>
        );
      case PossibleComponents.NoPlatesAvailableComponent:
        return <NoPlatesAvailable>No Plates Available</NoPlatesAvailable>;
      case PossibleComponents.ReadOnlyPlateGroupAccordionComponent:
        return <ReadOnlyPlateGroupAccordion />;
      case PossibleComponents.PlateGroupAccordionComponent:
        return (
          <PlateGroupAccordion
            accordionDataTuple={accordionDataTuple}
            methodReservation={methodReservation}
          />
        );
    }
  };
  return { component, Reducer };
}
