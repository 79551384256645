import { Brightness2, WbSunny } from "@mui/icons-material";
import { Button, Grid, Switch, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik, FormikHelpers, FormikProps } from "formik";
import styled from "styled-components/macro";
import { useGetUserSettings } from "hooks/useGetUserSettings";
import { useGetSaveThemeSettings } from "hooks/useSaveUserSettings";
import {
  StyledCard,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { ThemeSettings } from "../Settings";

const useStyles = makeStyles({
  root: {
    width: "90px",
    height: "50px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  track: {
    width: "40px",
    height: "20px",
    borderRadius: "10px",
  },
  checked: {
    width: "90px",
    height: "50px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  thumb: {
    width: "32px",
    height: "32px",
  },
  container: {
    justifyContent: "center",
  },
});
const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
export function ThemeSettingScreens() {
  const userSettings = useGetUserSettings();
  const [saveThemeSettings, loading] = useGetSaveThemeSettings();
  const classes = useStyles();
  const initialValues: ThemeSettings = userSettings?.theme ?? {};
  const handleSubmit = async (
    values: ThemeSettings,
    { setSubmitting }: FormikHelpers<ThemeSettings>
  ) => {
    await saveThemeSettings(values);
    localStorage.theme = JSON.stringify(values);
    setSubmitting(false);
  };
  return (
    <Container>
      <StyledCard width={"300px"} height={"300px"}>
        <Formik
          style={{ padding: "0" }}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
          }: FormikProps<ThemeSettings>) => (
            <StyledForm onSubmit={handleSubmit}>
              <StyledFormControl>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                  classes={{ container: classes.container }}
                >
                  <Grid item>
                    <WbSunny fontSize={"large"} />
                  </Grid>
                  <Grid item>
                    <Switch
                      color={"primary"}
                      checked={values.mode === "dark"}
                      size={"medium"}
                      classes={{
                        root: classes.root,
                        checked: classes.checked,
                        track: classes.track,
                        thumb: classes.thumb,
                      }}
                      onChange={(e) => {
                        setFieldValue(
                          "mode",
                          e.target.checked ? "dark" : "light"
                        );
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Brightness2 fontSize={"large"} />
                  </Grid>
                </Grid>
              </StyledFormControl>
              <StyledFormControl>
                <TextField
                  id="fontSize"
                  fullWidth
                  defaultValue={userSettings?.theme?.fontSize}
                  value={values.fontSize}
                  variant={"outlined"}
                  type={"number"}
                  label={"Font Size"}
                  onChange={handleChange}
                  helperText={
                    (values?.fontSize ?? 5) < 4
                      ? "Font Size must be at least 4"
                      : ""
                  }
                  error={(values?.fontSize ?? 5) < 4}
                />
              </StyledFormControl>
              <StyledFormControl>
                <Button
                  type="submit"
                  fullWidth
                  variant={"contained"}
                  color={"primary"}
                  disabled={loading || (values?.fontSize ?? 5) < 4}
                >
                  Save Changes
                </Button>
              </StyledFormControl>
            </StyledForm>
          )}
        </Formik>
      </StyledCard>
    </Container>
  );
}
