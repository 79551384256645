import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useGetTubeLabellerRunsQuery } from "graphql/generated/schema-types";
import { AddRackOptions, Process } from "../../constants";
interface RunSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const RunSelector = ({ values, setFieldValue }: RunSelectorProps) => {
  const { data: runs } = useGetTubeLabellerRunsQuery({
    variables: {
      method:
        values.step == "" || values.process !== Process.Fibroblast
          ? values.process
          : values.step,
    },
  });
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Run</InputLabel>
      <Select
        labelId="run-label"
        label="Run"
        id="run"
        value={values.run}
        onChange={(e) => setFieldValue("run", e.target.value)}
      >
        {runs?.tubeLabellerRuns?.map((item) => (
          <MenuItem value={item ?? ""}>{item ?? ""}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
