import { Maybe } from "graphql-tools";
import { AppSuiteGroup } from "graphql/generated/schema-types";
import { useGetUser } from "hooks/cacheAccessHooks";
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { GroupField } from "shared-components/FormFields/FormFields";

interface GroupDropdownProps {
  onChange: (event: ChangeEvent<unknown>, newValue: any) => void;
  setGroupId: Dispatch<SetStateAction<number | null | undefined>>;
  value: number | null | undefined;
}

export const GroupDropdown = (gdp: GroupDropdownProps) => {
  const userId = parseInt(localStorage.getItem("userID") ?? "");
  const userGroups = useGetUser(userId)?.groups;
  const userGroupIds = userGroups?.map(
    (userGroup: Maybe<AppSuiteGroup>) => userGroup?.groupId
  );
  const initialGroupId = userGroups && userGroups[0]?.groupId;

  useEffect(() => {
    if (gdp.value) {
      return;
    }
    gdp.setGroupId(initialGroupId);
  }, [initialGroupId]);

  return (
    <GroupField
      disableClearable={true}
      disabled={(userGroups && userGroups.length === 1) ?? false}
      filter={(group) => userGroupIds?.includes(group?.groupId)}
      id="group-combobox"
      label="Group"
      onChange={gdp.onChange}
      variant="outlined"
      value={gdp.value}
    />
  );
};
