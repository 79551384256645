import { Methods } from "enums/Methods";
import { CeligoScan } from "features/Runs/components/Forms/MethodDetails/CeligoScan/CeligoScan";
import { XTYCustomPassage } from "features/Runs/components/Forms/MethodDetails/XTYCustomPassage/XTYCustomPassage";
import { RunTask } from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import React from "react";
import { Thaw } from "./Thaw/Thaw";

function MethodReducer(methodId: number, runActivity: Maybe<RunTask>) {
  switch (methodId) {
    case Methods.XTY_CustomPassage:
      return <XTYCustomPassage runActivity={runActivity} />;
    case Methods.CeligoScan:
      return <CeligoScan runActivity={runActivity} />;
    case Methods.Thaw:
      return <Thaw runActivity={runActivity} />;
    default:
      return <React.Fragment />;
  }
}

export function MethodReservationForm({
  methodId,
  runActivity,
}: {
  methodId: number;
  runActivity: Maybe<RunTask>;
}): JSX.Element {
  return <>{MethodReducer(methodId, runActivity)}</>;
}
