import { EditTicketForm } from "features/Tickets/components/Forms/Edit/Ticket/Form";
import { useTicketQueryProvider } from "features/Tickets/Context";
import { TicketActions } from "features/Tickets/state/actions";
import { useTicketState } from "features/Tickets/state/hooks";
import {
  UpdateTicketInput,
  useUpdateTicketsMutation,
} from "graphql/generated/schema-types";
import { useGetTicket } from "hooks/cacheAccessHooks";
import { useWindowSize } from "hooks/useWindowSize";
import _ from "lodash";
import { TicketInputMapper } from "mappings/ticketInput";
import React, { useMemo } from "react";
import { StyledCard } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";

const RequiredFields: (keyof UpdateTicketInput)[] = ["status"];
export function EditTicketModal(): JSX.Element {
  const windowSize = useWindowSize();
  const {
    ticketDispatch,
    ticketState: { selectedTickets },
  } = useTicketState();
  const [updateTickets] = useUpdateTicketsMutation();
  const { refetch } = useTicketQueryProvider()!;
  const ticket = useGetTicket(selectedTickets![0]!);
  const input = useMemo(() => TicketInputMapper(ticket), [ticket]);

  function excludeUnchangedFields(values: UpdateTicketInput) {
    for (const key in input) {
      const _key = key as keyof UpdateTicketInput;
      if (
        _.isEqual(input[_key], values[_key]) &&
        !RequiredFields.includes(_key)
      )
        values[_key] = undefined;
    }
  }

  const handleSubmit = (values: UpdateTicketInput) => {
    excludeUnchangedFields(values);
    return updateTickets({
      variables: {
        inputs: [{ primaryKey: selectedTickets![0]!, set: values }],
      },
    })
      .then(() => ticketDispatch({ type: TicketActions.CLOSE_MODAL }))
      .then(async () => {
        Alert({ type: AlertType.SUCCESS, message: "Changes Saved" });
        await refetch();
      });
  };

  return (
    <StyledCard
      min_width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      <EditTicketForm initialValues={input} onSubmit={handleSubmit} />
    </StyledCard>
  );
}
