import { makeVar } from "@apollo/client";
import {
  ManualTaskTypeFragment,
  SortEnumType,
  useManualTaskManagerQuery,
} from "graphql/generated/schema-types";
import produce from "immer";
import React, { useEffect, useMemo, useState } from "react";
import {
  TableState,
  useExpanded,
  useFilters,
  useGroupBy,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { GetFilterHandler } from "shared-components/Table/Handlers/HandleFilter";
import { GetSortHandler } from "shared-components/Table/Handlers/HandleSort";
import { useInitializeData } from "shared-components/Table/hooks";
import {
  ReactTable,
  ReactTablePaginationState,
} from "shared-components/Table/ReactTable";
import { SelectHookCallback } from "shared-components/Table/sub-components/SelectHookCallback";
import { MethodManagerActions } from "../../../state/actions";
import { useMethodManagerState } from "../../../state/hooks";
import { ManualTaskTableContextMenu } from "../../ManualTaskContextMenu";
import { MethodColumns } from "./Columns";
import { ManualTaskFilterReducer } from "./filter";
import { handleContextMenu } from "./handlers";

//Workaround to prevent dialog from opening when the context menu loses focus
export const ManualTaskTableContextMenuHidden = makeVar(true);

const toggleAllRowsSelectedStore = makeVar<(value: boolean) => void>(
  () => void 0
);

export const ResetSelectedManualTaskTableRows = () =>
  toggleAllRowsSelectedStore()(false);

const DefaultState: Partial<TableState<ManualTaskTypeFragment>> = {
  sortBy: [{ id: "name", desc: false }],
};

export function ManualTaskTable(): JSX.Element {
  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const [paginationState, setPaginationState] =
    useState<ReactTablePaginationState>({
      totalCount: 0,
      rowsPerPage: 25,
      page: 0,
    });

  const { methodManagerDispatch } = useMethodManagerState();

  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { refetch, loading, variables, data } = useManualTaskManagerQuery({
    variables: {
      order: [{ name: SortEnumType.Asc }],
    },
  });
  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  const pageData = useInitializeData<ManualTaskTypeFragment>(
    data?.manualTaskTypes,
    paginationState
  );
  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//
  const sortTable = useMemo(
    () => GetSortHandler(variables, refetch),
    [refetch, variables]
  );

  const filterTable = useMemo(
    () => GetFilterHandler(variables, refetch, ManualTaskFilterReducer),
    [refetch, variables]
  );

  // const handleRowClick = useHandleRowClick();

  //--------------------------------------------------------------- Initialize Tables ------------------------------------------------------------//
  const tableInstance = useTable(
    {
      columns: MethodColumns,
      data: pageData,
      manualFilters: true,
      manualSortBy: true,
      initialState: DefaultState,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    SelectHookCallback()
  );

  const { toggleAllRowsSelected, selectedFlatRows } = tableInstance;

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//
  useEffect(() => {
    setPaginationState((current) =>
      produce(current, (draft) => {
        draft.totalCount = data?.manualTaskTypes?.length ?? 0;
      })
    );
  }, [data?.manualTaskTypes?.length]);
  useEffect(() => {
    toggleAllRowsSelectedStore(toggleAllRowsSelected);
  }, [toggleAllRowsSelected]);

  useEffect(() => {
    methodManagerDispatch({
      type: MethodManagerActions.SELECT,
      payload: {
        selectedManualTaskTypes: selectedFlatRows
          .filter((row) => row.original?.id)
          .map((row) => row.original.id),
      },
    });
  }, [selectedFlatRows, methodManagerDispatch]);

  //--------------------------------------------------------------- Markup ------------------------------------------------------------//
  return (
    <>
      <ReactTable
        loading={loading}
        tableInstance={tableInstance}
        onFilter={filterTable}
        onSort={sortTable}
        stateChangeDebounceDuration={500}
        paginationState={paginationState}
        onRowContextMenu={handleContextMenu}
        onPageCountChange={(count) =>
          setPaginationState((current) =>
            produce(current, (draft) => {
              draft.rowsPerPage = count;
              draft.page = 0;
            })
          )
        }
        onPageChange={(e, page) =>
          setPaginationState((current) =>
            produce(current, (draft) => {
              draft.page = page;
            })
          )
        }
      />
      <ManualTaskTableContextMenu />
    </>
  );
}
