import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { FormName } from "../enums/FormName";

/**
 * Persists form state to local storage, each form's state is indexed by the FormName enum
 * @param name
 * @param formikProps
 */

export const usePersistForm = <TValue>(
  name: FormName,
  formikProps: FormikProps<TValue>
) => {
  const { setValues, values, submitCount } = formikProps;
  const [submitAttempts, setSubmitAttempts] = useState(0);
  useEffect(() => {
    const storeValues = localStorage.getItem(name);
    if (storeValues) {
      const values: TValue = JSON.parse(storeValues);
      setValues(values);
    }
  }, [name, setValues]);

  useEffect(() => {
    localStorage.setItem(name, JSON.stringify(values));
  }, [values, name]);

  useEffect(() => {
    if (submitCount > submitAttempts) {
      localStorage.removeItem(name);
      setSubmitAttempts(submitCount);
    }
  }, [name, submitAttempts, submitCount]);
};
