import { MutationUpdaterFn, useReactiveVar } from "@apollo/client";
import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Maybe } from "graphql/jsutils/Maybe";
import produce from "immer";
import React from "react";
import {
  AddAssetMutation,
  AssetInput,
  GetTicketAssetsQuery,
  LookupAsset,
  useAddAssetMutation,
} from "graphql/generated/schema-types";
import { TicketQueries } from "graphql/queries/ticket.queries";
import { AssetTypeField } from "shared-components/FormFields/FormFields";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { CreateAssetValidationSchema } from "../../../validation-schema";
import { AddOptionState } from "../Form";

export function CreateAssetForm({
  initialValues,
}: {
  initialValues: AssetInput;
}): JSX.Element {
  const [addAsset] = useAddAssetMutation();
  const { setFieldOriginalFormValue } = useReactiveVar(AddOptionState);

  const updateAssetCache: MutationUpdaterFn<AddAssetMutation> = (
    cache,
    response
  ) => {
    //-------------------------------------- Grab existing entries from cache -------------------------------------//
    const assets = cache.readQuery<GetTicketAssetsQuery>({
      query: TicketQueries.remote.GET_TICKET_ASSETS,
    });

    //----------------------------------------------- Add new entry ------------------------------------------------//
    const assetsUpdated = produce(assets, (draft) => {
      if (response.data?.addAsset && draft?.ticketAssets) {
        draft.ticketAssets.push(response.data.addAsset!);
      }
    });

    //--------------------------------------------- Overwrite with updated ------------------------------------------------//
    cache.writeQuery({
      query: TicketQueries.remote.GET_TICKET_ASSETS,
      data: assetsUpdated,
    });
  };

  const onSubmit = (values: AssetInput) =>
    addAsset({
      variables: {
        input: values,
      },
      update: updateAssetCache,
    })
      .then((response) => {
        let added = 0;
        if (response.data?.addAsset) added = response.data.addAsset!.assetId;
        if (setFieldOriginalFormValue) {
          setFieldOriginalFormValue("assetId", added);
        }
      })
      .then(() => {
        AddOptionState({ dialogOpen: false });
        Alert({ message: "Added Feature", type: AlertType.SUCCESS });
      });

  const { handleSubmit, setFieldValue, errors, touched, isSubmitting } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: CreateAssetValidationSchema,
    });
  return (
    <StyledForm onSubmit={handleSubmit}>
      <AssetTypeField
        id={"assetTypeId"}
        label={"Platform"}
        defaultValue={initialValues.assetTypeId}
        onChange={(e, newValue) =>
          setFieldValue(
            "assetTypeId",
            (newValue as Maybe<LookupAsset>)?.assetTypeId
          )
        }
        helperText={
          errors.assetTypeId && touched.assetTypeId && errors.assetTypeId
        }
        error={
          !!(errors.assetTypeId && touched.assetTypeId && errors.assetTypeId)
        }
        variant={"outlined"}
      />
      <StyledFormControl>
        <TextField
          label={"Feature"}
          defaultValue={initialValues.asset ?? ""}
          variant={"outlined"}
          fullWidth
          onChange={(e) => setFieldValue("asset", e.target.value)}
          helperText={errors.asset && touched.asset && errors.asset}
          error={!!(errors.asset && touched.asset && errors.asset)}
        />
      </StyledFormControl>
      <StyledFormControl>
        <Button
          type={"submit"}
          variant={"contained"}
          color={"secondary"}
          disabled={isSubmitting}
        >
          Add Feature
        </Button>
      </StyledFormControl>
    </StyledForm>
  );
}
