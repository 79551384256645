import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CarouselTransition } from "features/Tickets/components/ModelsAndDialogs/Summary/Attachments/CarouselTransition";
import { Maybe } from "graphql-tools";
import {
  PersonalTaskAttachment,
  RunTaskAttachment,
  TaskAttachmentInput,
  TaskTypeEnum,
  useCreateTaskAttachmentsMutation,
  useDeleteTaskAttachmentMutation,
} from "graphql/generated/schema-types";
import { useMemo, useState } from "react";
import { AttachmentActions } from "shared-components/AttachmentActions";
import { Alert, AlertType } from "shared-components/toast";
import { UploadFilesArea } from "shared-components/UploadFilesArea";
import { ButtonContainer, Carousel, Container } from "./TaskAttachments.styles";

interface TaskAttachmentsProps {
  runTaskAttachments: Maybe<RunTaskAttachment>[];
  personalTaskAttachments: Maybe<PersonalTaskAttachment>[];
  taskId: number;
  taskType: TaskTypeEnum;
}

export const TaskAttachments = ({
  runTaskAttachments,
  personalTaskAttachments,
  taskId,
  taskType,
}: TaskAttachmentsProps) => {
  const [createTaskAttachments, { loading: createTaskAttachmentsLoading }] =
    useCreateTaskAttachmentsMutation();
  const [deleteTaskAttachment, { loading: deleteTaskAttachmentLoading }] =
    useDeleteTaskAttachmentMutation();

  const indexTuple = useState(0);
  const [index, setIndex] = indexTuple;
  const [reverse, setReverse] = useState(false);

  const handleUpload = async (files: File[]) => {
    const input: TaskAttachmentInput[] = [];
    const twentyMB = 1024 * 1024 * 20;
    for (const file of files) {
      if (file.size > twentyMB)
        return Alert({
          type: AlertType.ERROR,
          message: `${file.name} is too large (20MB max)`,
        });
      input.push({ name: file.name, file: file });
    }

    await createTaskAttachments({
      variables: {
        inputs: input,
        taskId: taskId,
        taskType: taskType,
      },
    }).then(() => {
      if (taskType === TaskTypeEnum.RunTask && runTaskAttachments.length > 0)
        setIndex((current) => current + 1);
      if (
        taskType === TaskTypeEnum.Personal &&
        personalTaskAttachments.length > 0
      )
        setIndex((current) => current + 1);
    });
  };

  const handleDelete = () => {
    let taskAttachmentId: number | undefined;
    switch (taskType) {
      case TaskTypeEnum.Personal:
        taskAttachmentId =
          personalTaskAttachments[index]?.personalTaskAttachmentId ?? undefined;
        break;
      case TaskTypeEnum.RunTask:
        taskAttachmentId =
          runTaskAttachments[index]?.runTaskAttachmentId ?? undefined;
        break;
    }
    if (!taskAttachmentId) return;

    deleteTaskAttachment({
      variables: {
        taskAttachmentId: taskAttachmentId,
        taskType: taskType,
      },
    })
      .then(() => setIndex(0))
      .catch((error) => console.log(error));
  };

  const leftRight_onClick = (isRight: boolean) => {
    setReverse(!isRight);
    setIndex((current) => current + (isRight ? 1 : -1));
  };

  const disabled = (isRight: boolean) =>
    isRight ? index === attachments.length - 1 : index === 0;

  const attachments = useMemo(
    () =>
      taskType === TaskTypeEnum.RunTask
        ? (runTaskAttachments as RunTaskAttachment[])
        : (personalTaskAttachments as PersonalTaskAttachment[]),
    [taskType, runTaskAttachments, personalTaskAttachments]
  );

  return (
    <Container>
      <ButtonContainer>
        <IconButton
          onClick={() => leftRight_onClick(false)}
          disabled={disabled(false)}
          size="large"
        >
          <KeyboardArrowLeft />
        </IconButton>
      </ButtonContainer>
      <div>
        <Carousel>
          <UploadFilesArea onDrop={handleUpload}>
            <CarouselTransition
              attachments={attachments}
              index={index}
              reverse={reverse}
            />
          </UploadFilesArea>
        </Carousel>
        <AttachmentActions
          loading={createTaskAttachmentsLoading}
          deleteAttachmentLoading={deleteTaskAttachmentLoading}
          indexTuple={indexTuple}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
          attachments={attachments}
        />
      </div>
      <ButtonContainer>
        <IconButton
          onClick={() => leftRight_onClick(true)}
          disabled={disabled(true)}
          size="large"
        >
          <KeyboardArrowRight />
        </IconButton>
      </ButtonContainer>
    </Container>
  );
};
