import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import { Methods } from "enums/Methods";
import { FormikProps } from "formik";
import {
  RunTaskInput,
  RunTaskDetailInput,
  RunTemplateTaskInput,
  useGetWorklistToolTemplateQuery,
} from "graphql/generated/schema-types";
import React from "react";
import { RunActivityDetailFormikTypeParser } from "shared-components/FormFields/MethodFields/helpers";
import {
  CollapseSection,
  TaskDetailsNumberFields,
} from "shared-components/FormFields/MethodFields/MethodFormReducer";

export function NormalizedPassageFields({
  props,
  controlled,
  disabled,
}: {
  props:
    | FormikProps<RunTaskInput>
    | FormikProps<RunTemplateTaskInput>
    | FormikProps<RunTaskDetailInput>;
  controlled?: true | never;
  disabled?: boolean;
}): JSX.Element {
  const { data } = useGetWorklistToolTemplateQuery({
    variables: {
      where: { methodId: { eq: Methods.NormalizedPassage } },
    },
  });
  const { setFieldValue } = props;

  const {
    assertedErrors,
    assertedValues,
    setFieldValuePrefix,
    assertedTouched,
  } = RunActivityDetailFormikTypeParser(props);
  console.log(assertedValues);
  return (
    <CollapseSection>
      <FormControl style={{ width: "30%" }} variant="outlined">
        <InputLabel htmlFor="worklistTemplateInput">
          Worklist Template
        </InputLabel>
        <Select
          native
          disabled={disabled}
          value={assertedValues?.worklistTemplateId ?? null}
          onChange={(e) =>
            setFieldValue(
              setFieldValuePrefix + "worklistTemplateId",
              parseInt(e.target.value!.toString())
            )
          }
          label="Worklist Template"
          inputProps={{
            name: "worklistTemplate",
            id: "worklistTemplateInput",
            "data-testid": "worklistTemplate",
          }}
        >
          <option aria-label="None" value="" />
          {data?.worklistToolTemplate?.map((type) => (
            <option
              key={type?.worklistToolTemplateId}
              value={type!.worklistToolTemplateId}
            >
              {type?.templateName}
            </option>
          ))}
        </Select>
      </FormControl>
      <TaskDetailsNumberFields style={{ marginTop: "30px" }}>
        <FormControl style={{ width: "30%" }} variant="outlined">
          <TextField
            disabled={disabled}
            value={assertedValues?.destPlateCode ?? ""}
            onChange={(e) =>
              setFieldValue(
                setFieldValuePrefix + "destPlateCode",
                e.target.value
              )
            }
            label="Destination Plate"
            inputProps={{
              name: "destinationPlate",
              id: "destinationPlateInput",
              "data-testid": "destPlateCode",
            }}
            helperText={
              assertedErrors?.destPlateCode &&
              assertedTouched?.destPlateCode &&
              assertedErrors?.destPlateCode
            }
            error={
              !!(
                assertedErrors?.destPlateCode &&
                assertedTouched?.destPlateCode &&
                assertedErrors?.destPlateCode
              )
            }
          />
        </FormControl>
      </TaskDetailsNumberFields>
    </CollapseSection>
  );
}
