import { Checkbox } from "@mui/material";
import React from "react";

export function BooleanCell({ value }: { value?: boolean }): JSX.Element {
  return (
    <Checkbox
      color={"secondary"}
      indeterminate={value === null || value === undefined}
      checked={value ?? false}
      onChange={() => void 0}
    />
  );
}
