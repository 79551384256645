import { ApolloLink, ApolloProvider } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { client } from "cache/cache";
import { GetErrorHandler } from "error/handlers/apollo-error-handler";
import { Wrapper } from "interfaces/Wrapper";
import React, { useEffect, useMemo } from "react";

export function CustomApolloProvider({ children }: Wrapper): JSX.Element {
  const ErrorHandler = useMemo(() => GetErrorHandler(), []);

  useEffect(() => {
    const ErrorLink = onError(ErrorHandler);
    const currentLinks = client.link;
    client.setLink(ApolloLink.from([ErrorLink, currentLinks]));
  }, [ErrorHandler]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
