import { useReactiveVar } from "@apollo/client";
import { Button, Tooltip } from "@mui/material";
import { BackdropOpen } from "App";
import { Formik } from "formik";
import {
  Maybe,
  TubeLabellerDestinationWorklist,
  useGetBloodProcessingRackInfoLazyQuery,
  useGetConsolidationRackInfoLazyQuery,
  useGetDnarnaRackInfoLazyQuery,
  useGetEbCollectionRackInfoLazyQuery,
  useGetFibroblastRackInfoLazyQuery,
  useGetIpscExpansionRackInfoLazyQuery,
  useGetManualPlateRackInfoLazyQuery,
  useGetMonoclonalizationRackInfoLazyQuery,
  useGetPooling1224TcRackInfoLazyQuery,
  useGetQcRackInfoLazyQuery,
  useGetSalivaRackInfoLazyQuery,
} from "graphql/generated/schema-types";
import moment from "moment";
import React, { useEffect } from "react";
import CsvDownloader from "react-csv-downloader";
import { StyledForm } from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import styled from "styled-components";
import * as Yup from "yup";
import { AddRackOptions, LotNumber, Process } from "../constants";
import { useHandleWorklistChange } from "../state/handlers";
import { TubeLabellerState } from "../state/initial-state";
import { BloodProcessing } from "./processes/BloodProcessing";
import { Consolidation } from "./processes/Consolidation";
import { DNARNAQC } from "./processes/DNARNAQC";
import { EBCollection } from "./processes/EBCollection";
import { Fibroblast } from "./processes/Fibroblast";
import { IPSCExpansion } from "./processes/iPSCExpansion";
import { ManualPlate } from "./processes/ManualPlate";
import { Monoclonalization } from "./processes/Monoclonalization";
import { Pooling1224TC } from "./processes/Pooling1224TC";
import { QC } from "./processes/QC";
import { Saliva } from "./processes/Saliva";
import { ProcessSelector } from "./selectors/ProcessSelector";
import ShowCompletedTasksSwitch from "./selectors/ShowCompletedTasksSwitch";

const StyledFormContainer = styled.div`
  width: 300px;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.3)" : ""};
`;

const StyledAddRackContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template: 1fr 1fr 1fr 1fr 1fr / 1fr;
  grid-gap: 15px 0px;
`;

export const AddRackInfo = () => {
  const setTubeLabellerWorklist = useHandleWorklistChange();
  const Schema = Yup.object().shape({
    process: Yup.string().required("Required"),
  });
  const initialValues: AddRackOptions = {
    process: "",
    step: "",
    run: "",
    selectedPlate: "",
    runTaskId: undefined,
    salivaSamples: [],
    numberOfDuplicates: 1,
    incrementPassageNumber: 1,
    cellNumber: undefined,
    colWise: true,
    fillRack: false,
    includeInactiveWells: true,
    date: new Date(),
    lotNumberType: LotNumber.CurrentActive,
    showCompleted: false,
  };
  const tubeLabellerState = useReactiveVar(TubeLabellerState);

  const [getFibroblastRackInfo, { data: fibroblastRackInfo }] =
    useGetFibroblastRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.fibroblastRackInfo);
      },
    });
  const [getMonoclonalizationRackInfo, { data: monoclonalizationRackInfo }] =
    useGetMonoclonalizationRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.monoclonalizationRackInfo);
      },
    });
  const [getBloodProcessingRackInfo, { data: bloodProcessingRackInfo }] =
    useGetBloodProcessingRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.bloodProcessingRackInfo);
      },
      onError: (error) => {
        const resetBloodProcessingRackInfo = () => {
          tubeLabellerState.tubeLabellerInfo.splice(
            tubeLabellerState.currentRackIndex - 4,
            3
          );
          tubeLabellerState.currentRackIndex -= 3;
        };

        resetBloodProcessingRackInfo();
      },
    });
  const [getSalivaRackInfo, { data: salivaRackInfo }] =
    useGetSalivaRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.salivaRackInfo);
      },
    });
  const [getConsolidationRackInfo, { data: consolidationRackInfo }] =
    useGetConsolidationRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.consolidationRackInfo);
      },
    });
  const [getIPSCExpansionRackInfo, { data: ipscExpansionRackInfo }] =
    useGetIpscExpansionRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.iPSCExpansionRackInfo);
      },
    });
  const [getEBCollectionRackInfo, { data: ebCollectionRackInfo }] =
    useGetEbCollectionRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.eBCollectionRackInfo);
      },
    });
  const [getDNARNARackInfo, { data: dnaRnaRackInfo }] =
    useGetDnarnaRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.dNARNARackInfo);
      },
    });
  const [getQCRackInfo, { data: qcRackInfo }] = useGetQcRackInfoLazyQuery({
    onCompleted: (data) => {
      handleRackInfoLoad(data.qCRackInfo);
    },
  });
  const [getPooling1224TCRackInfo, { data: pnToMatrixRackInfo, loading }] =
    useGetPooling1224TcRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.pooling1224TCRackInfo);
      },
    });
  const [getManualPlateRackInfo, { data: manualRackInfo }] =
    useGetManualPlateRackInfoLazyQuery({
      onCompleted: (data) => {
        handleRackInfoLoad(data.manualPlateRackInfo);
      },
    });

  useEffect(() => {
    BackdropOpen({
      open: loading,
      header: "Adding Rack",
      subText: "Do not reload page",
      size: 60,
    });
  }, [loading]);

  const headers = [
    { displayName: "~DSTRack", id: "dSTRack" },
    { displayName: "DSTPosition", id: "dSTPosition" },
    { displayName: "SRCRack", id: "sRCRack" },
    { displayName: "SRCPosition", id: "sRCPosition" },
    { displayName: "DSTRackBarcode", id: "dSTRackBarcode" },
    { displayName: "DSTLinePosition", id: "dSTLinePosition" },
    { displayName: "FactoryBarcode", id: "factoryBarcode" },
    { displayName: "Field1", id: "field1" },
    { displayName: "Field2", id: "field2" },
    { displayName: "Field3", id: "field3" },
    { displayName: "Field4", id: "field4" },
    { displayName: "Field5", id: "field5" },
    { displayName: "Field6", id: "field6" },
    { displayName: "Field7", id: "field7" },
  ];

  const srcHeaders = [
    { displayName: "SRCRack", id: "sRCRack" },
    { displayName: "SRCPosition", id: "sRCPosition" },
  ];

  const getRunHeaderInfo = (values: AddRackOptions) => {
    if (values.run != "") {
      return values.run;
    } else if (values.selectedPlate != "") {
      return values.selectedPlate;
    } else if (values!.salivaSamples!.length !== 0) {
      return values!.salivaSamples!.join(", ");
    }
    return "";
  };

  const handleRackInfoLoad = (
    rackInfo:
      | Maybe<
          Maybe<
            { __typename?: "TubeLabellerDestinationWorklist" | undefined } & {
              __typename?: "TubeLabellerDestinationWorklist" | undefined;
            } & Pick<
                TubeLabellerDestinationWorklist,
                | "dSTRack"
                | "dSTPosition"
                | "sRCRack"
                | "sRCPosition"
                | "dSTRackBarcode"
                | "dSTLinePosition"
                | "factoryBarcode"
                | "field1"
                | "field2"
                | "field3"
                | "field4"
                | "field5"
                | "field6"
                | "field7"
              >
          >[]
        >
      | undefined
  ) => {
    const values = rackInfo!.map((item: any) => {
      return {
        dSTRack: item!.dSTRack,
        dSTPosition: item?.dSTPosition,
        sRCRack: item!.sRCRack,
        sRCPosition: item!.sRCPosition,
        dSTRackBarcode: item!.dSTRackBarcode,
        dSTLinePosition: item!.dSTLinePosition,
        factoryBarcode: item!.factoryBarcode,
        field1: item!.field1,
        field2: item!.field2,
        field3: item!.field3,
        field4: item!.field4,
        field5: item!.field5,
        field6: item!.field6,
        field7: item!.field7,
      };
    });
    setTubeLabellerWorklist(values);
  };

  const handleSubmit = (values: AddRackOptions, { resetForm }: any) => {
    const addRackInput = {
      worklist: tubeLabellerState.worklist,
      currentRackNumber: tubeLabellerState.currentRackIndex,
      plateBarcode: values.run === "" ? values.selectedPlate : values.run,
      salivaSamples: values.salivaSamples,
      method: values.step,
      numberOfDuplicates: values.numberOfDuplicates,
      passageNumberIncrement: values.incrementPassageNumber,
      cellNumber: values.cellNumber,
      resuspensionVol: values.resuspensionVol,
      colWise: values.colWise,
      fillRack: values.fillRack,
      includeInactiveWells: values.includeInactiveWells,
      date: values.date,
      lotNumberType: values.lotNumberType,
      runTaskId: values.runTaskId,
      isAutomated: values.isAutomated,
    };
    if (tubeLabellerState.currentRackIndex > 6) {
      Alert({
        type: AlertType.ERROR,
        message: "Cannot fit any more racks",
      });
      return;
    }
    switch (values.process) {
      case Process.Fibroblast:
        getFibroblastRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.Monoclonalization:
        getMonoclonalizationRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.BloodProcessing:
        values.step = "Plasma";
        getBloodProcessingRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.Saliva:
        getSalivaRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.Consolidation:
        getConsolidationRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.iPSCExpansion:
        getIPSCExpansionRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.EBCollection:
        getEBCollectionRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.DNARNAQC:
        getDNARNARackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.QC:
        getQCRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.PoolingOr1224TC:
        getPooling1224TCRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
      case Process.ManualPlate:
        getManualPlateRackInfo({
          variables: {
            input: addRackInput,
          },
        });
        break;
    }
    const rackInfo = {
      process: values.process,
      step: values.step,
      run: getRunHeaderInfo(values),
    };
    for (let i = 0; i < values.numberOfDuplicates; i++) {
      tubeLabellerState.tubeLabellerInfo.push(rackInfo);
      tubeLabellerState.currentRackIndex += 1;
    }

    if (values.process === Process.BloodProcessing) {
      tubeLabellerState.currentRackIndex += 1;
      tubeLabellerState.tubeLabellerInfo.push({
        process: values.process,
        step: "PBC",
        run: values.run || values.selectedPlate,
      });
      tubeLabellerState.currentRackIndex += 1;
      tubeLabellerState.tubeLabellerInfo.push({
        process: values.process,
        step: "HTC",
        run: values.run || values.selectedPlate,
      });
    }
    resetForm();
  };

  const getData = () => {
    return Promise.resolve(
      tubeLabellerState.worklist.sort((a, b) => a.dSTRack - b.dSTRack)
    );
  };
  return (
    <StyledFormContainer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={Schema}
      >
        {({
          values,
          touched,
          errors,
          resetForm,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            <StyledAddRackContainer>
              {values.process === "Fibroblast" ? (
                <ShowCompletedTasksSwitch
                  values={values}
                  setFieldValue={setFieldValue}
                />
              ) : null}
              <ProcessSelector values={values} setFieldValue={setFieldValue} />
              <ProcessMenu
                process={values.process}
                values={values}
                setFieldValue={setFieldValue}
              />
              <Button
                type="submit"
                variant="outlined"
                disabled={
                  (values.run === "" &&
                    values.selectedPlate === "" &&
                    values!.salivaSamples!.length === 0) ||
                  tubeLabellerState.currentRackIndex > 6
                }
              >
                Add Rack
              </Button>
              <CsvDownloader
                filename={`TubeLabels_${moment(new Date()).format(
                  "YYYY-MM-DD_hh_mm_ss_A"
                )}_${sessionStorage.getItem("userName")}_${
                  tubeLabellerState.tubeLabellerInfo[0]?.run
                }_DST.csv`}
                extension=".csv"
                separator=","
                wrapColumnChar=""
                bom={false}
                columns={headers}
                datas={getData}
                text="Generate Destination"
              >
                <Button variant="outlined" fullWidth>
                  Generate Destination
                </Button>
              </CsvDownloader>
              <CsvDownloader
                filename={`TubeLabels_${moment(new Date()).format(
                  "YYYY-MM-DD_hh_mm_ss_A"
                )}_${sessionStorage.getItem("userName")}_${
                  tubeLabellerState.tubeLabellerInfo[0]?.run
                }_SRC.csv`}
                extension=".csv"
                separator=","
                wrapColumnChar=""
                bom={false}
                columns={srcHeaders}
                datas={getData}
                text="Generate Source"
              >
                <Tooltip title="Source File not required for Tube Labeller 2.0">
                  <Button variant="outlined" fullWidth>
                    Generate Source
                  </Button>
                </Tooltip>
              </CsvDownloader>
            </StyledAddRackContainer>
          </StyledForm>
        )}
      </Formik>
    </StyledFormContainer>
  );
};

interface ProcessMenuProps {
  process: string;
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

function ProcessMenu({ process, values, setFieldValue }: ProcessMenuProps) {
  return <>{ProcessReducer(process, values, setFieldValue)}</>;
}

function ProcessReducer(
  process: string,
  values: AddRackOptions,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
) {
  switch (process) {
    case Process.Fibroblast:
      return <Fibroblast values={values} setFieldValue={setFieldValue} />;
    case Process.Monoclonalization:
      return (
        <Monoclonalization values={values} setFieldValue={setFieldValue} />
      );
    case Process.BloodProcessing:
      return <BloodProcessing values={values} setFieldValue={setFieldValue} />;
    case Process.Saliva:
      return <Saliva values={values} setFieldValue={setFieldValue} />;
    case Process.Consolidation:
      return <Consolidation values={values} setFieldValue={setFieldValue} />;
    case Process.iPSCExpansion:
      return <IPSCExpansion values={values} setFieldValue={setFieldValue} />;
    case Process.EBCollection:
      return <EBCollection values={values} setFieldValue={setFieldValue} />;
    case Process.DNARNAQC:
      return <DNARNAQC values={values} setFieldValue={setFieldValue} />;
    case Process.QC:
      return <QC values={values} setFieldValue={setFieldValue} />;
    case Process.PoolingOr1224TC:
      return <Pooling1224TC values={values} setFieldValue={setFieldValue} />;
    case Process.ManualPlate:
      return <ManualPlate values={values} setFieldValue={setFieldValue} />;
    default:
      return <React.Fragment />;
  }
}
