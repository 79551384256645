import { gql } from "@apollo/client/core";

export const FolderStructure = gql`
  fragment FolderStructure on FolderStructure {
    folderId
    folderName
    folderParentId
    isInsertable
    folderParent {
      folderId
      folderName
    }
    lookupTeamGanttId {
      folderId
      nodeType
      teamGanttId
    }
    runs {
      runId
      ...Run
      runStages {
        runStageId
        runStageName
      }
    }
  }
`;
export const DirectoryFolder = gql`
  fragment DirectoryFolder on DirectoryFolder {
    id
    parentId
    parentType
    name
    type
  }
`;
export const Directory = gql`
  ${DirectoryFolder}
  fragment Directory on DirectoryFolder {
    ...DirectoryFolder
    subFolders {
      ...DirectoryFolder
      subFolders {
        ...DirectoryFolder
        subFolders {
          ...DirectoryFolder
          subFolders {
            ...DirectoryFolder
          }
        }
      }
    }
  }
`;
