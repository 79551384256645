import { css } from "@emotion/react";
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Maybe, RunTaskInput } from "graphql/generated/schema-types";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React, { useMemo } from "react";
import { useTheme } from "styled-components/macro";
import { DateShift, ShiftDate } from "./Rescheduler";

const StyledCell = ({
  changed,
  ...others
}: TableCellProps & { changed: boolean }) => {
  const theme = useTheme();
  const border = useMemo(() => GetTableCellBorder({ theme }), [theme]);
  return (
    <TableCell
      {...others}
      // @ts-ignore
      css={css`
        border-right: 1px solid ${border};
        color: ${changed
          ? theme.palette.mode === "dark"
            ? "pink"
            : "blue"
          : theme.palette.mode === "dark"
          ? "white"
          : "black"}!important;
      `}
    />
  );
};
export function PreviewScheduleChanges({
  shift,
  tasks,
}: {
  shift: DateShift;
  tasks: (Maybe<RunTaskInput> | undefined)[];
}): JSX.Element {
  return (
    <TableContainer style={{ maxHeight: "50vh" }}>
      <Table component={Paper} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Task Name</TableCell>
            <TableCell>Date Scheduled</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        {tasks.length > 0 ? (
          <TableBody>
            {tasks.map((task, index) => (
              <TableRow key={JSON.stringify(task)}>
                <TableCell>{task?.activityName}</TableCell>
                <StyledCell
                  changed={
                    !Object.entries(shift).every((entry) => entry[1] === 0)
                  }
                >
                  {ShiftDate(
                    task?.startTimeScheduled,
                    shift,
                    index !== 0
                  ).format("LLLL")}
                </StyledCell>
                <TableCell>{task?.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <div>No Tasks to Reschedule</div>
        )}
      </Table>
    </TableContainer>
  );
}
