import { FormControlLabel, Switch, TextField } from "@mui/material";
import { useFormik } from "formik";
import { Maybe } from "graphql/jsutils/Maybe";
import React, { ChangeEvent } from "react";
import {
  CreateRunFolderInput,
  FolderStructure,
} from "graphql/generated/schema-types";
import { FolderField } from "shared-components/FormFields/FormFields";
import {
  FormSubmitButton,
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { FormikSubmit } from "types/types";
import { CreateRunFolderValidationSchema } from "../../validation-schema";

export function CreateRunFolderForm({
  initialValues,
  onSubmit,
}: {
  initialValues: CreateRunFolderInput;
  onSubmit: FormikSubmit<CreateRunFolderInput>;
}): JSX.Element {
  const props = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: CreateRunFolderValidationSchema,
    onSubmit,
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = props;
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <TextField
          variant="outlined"
          margin="none"
          fullWidth
          id="folderName"
          label="Folder Name"
          value={values.folderName}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors.folderName && touched.folderName && errors.folderName
          }
          error={
            !!(errors.folderName && touched.folderName && errors.folderName)
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <FolderField
          id="folder-combobox"
          value={values.folderParentId}
          defaultValue={initialValues.folderParentId}
          groupBy={(entry) => entry?.folderParent?.folderName ?? ""}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) =>
            setFieldValue(
              "folderParentId",
              (newValue as Maybe<FolderStructure>)?.folderId
            )
          }
          label="Folder Location"
          variant="outlined"
          helperText={
            errors.folderParentId &&
            touched.folderParentId &&
            errors.folderParentId
          }
          error={
            !!(
              errors.folderParentId &&
              touched.folderParentId &&
              errors.folderParentId
            )
          }
        />
      </StyledFormControl>
      <StyledFormControl>
        <FormControlLabel
          control={
            <Switch
              name="isInsertable"
              onChange={(e) => {
                setFieldValue("isInsertable", e.target.checked);
              }}
            />
          }
          checked={values?.isInsertable ?? false}
          label={"Is Run Folder"}
        />
      </StyledFormControl>
      <FormSubmitButton
        type="submit"
        data-testid={"submit-button"}
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting}
      >
        Create Folder
      </FormSubmitButton>
    </StyledForm>
  );
}
