import { useReactiveVar } from "@apollo/client";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { DestinationPlate } from "features/WorklistTools/shared/components/DestinationPlate";
import { NumberField } from "features/WorklistTools/shared/components/NumberField";
import { PlateBarcodeField } from "features/WorklistTools/shared/components/PlateBarcodeField";
import { PlateType } from "features/WorklistTools/shared/components/PlateType";
import {
  LabwareTypeInfo,
  PlatePosition,
  WorklistTools,
} from "features/WorklistTools/shared/interfaces";
import {
  is384WPlate,
  is384W_CCU_Plate,
  is96WPlate,
  isMatrixRack,
} from "features/WorklistTools/shared/PlateHelpers";
import {
  getDestinationPlateBarcode,
  getLargestPlateBarcodeSeries,
  setDestPlateType,
} from "features/WorklistTools/shared/WorklistHelpers";
import { useGetIntermediateDeadAndDestPlateBarcodesMutation } from "graphql/generated/schema-types";
import { useAppReduxDispatch, useAppReduxSelector } from "hooks/reduxHooks";
import React from "react";
import { SelectableGroup } from "react-selectable-fast";
import { ConfirmDialog } from "shared-components/ModalsAndDialogs/ConfirmDialog";
import { Alert, AlertType } from "shared-components/toast";
import { useConfirm } from "state-provider/ConfirmDialogProvider/hooks";
import styled from "styled-components";
import {
  DestPlateInfoState,
  DestPlateStringKeys,
  IntermediatePlateInfoState,
  PoolingNormalizationToolActions,
} from "../state";
import { PoolingNormalizationToolInternalState } from "../state/initial-state";
import { usePoolingNormalizationStateProvider } from "../state/StateProvider";
import { selectDestWells } from "./handlers/SelectDestinationWells";

const StyledSelectableGroup = styled(SelectableGroup)`
  height: 320px;
  width: 100%; //425px;;
`;

const StyledPlateSettingsContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const StyledPlateActions = styled.div`
  width: 100%;
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

export const DestinationPlatesContainer = (destPlateTypeInfo: any) => {
  const { isConfirmed } = useConfirm();
  const poolingInternalState = useReactiveVar(
    PoolingNormalizationToolInternalState
  );
  const { poolingNormalizationToolDispatch } =
    usePoolingNormalizationStateProvider();
  const dispatch = useAppReduxDispatch();
  const [getAssociatedBarcodes] =
    useGetIntermediateDeadAndDestPlateBarcodesMutation();
  const sourcePlateInfo = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.sourcePlateInfo
  );
  const intPlateInfo: IntermediatePlateInfoState[] = useAppReduxSelector(
    (state) => state.WorklistTools.PoolingNormalizationTool.present.intPlateInfo
  );
  const deadPlateType = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.deadPlateType
  );
  const destPlateInfo: DestPlateInfoState[] = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.destPlateInfo
  );

  const worklistValues = useAppReduxSelector(
    (state) =>
      state.WorklistTools.PoolingNormalizationTool.present.worklistValues
  );

  const handleDestinationWellSelection = (destSelection: any[]) => {
    selectDestWells(
      poolingInternalState,
      intPlateInfo,
      destPlateInfo,
      worklistValues,
      dispatch,
      destSelection,
      isConfirmed
    );
  };

  const handlePlateTypeChange = (
    index: number,
    plateTypeInfo: LabwareTypeInfo
  ) => {
    plateTypeInfo = setDestPlateType(plateTypeInfo);
    if (
      destPlateInfo.some(
        (plate) => plate.labwareTypeCode === "Matrix_96_Cryo_L_3740"
      ) &&
      isMatrixRack(plateTypeInfo.labwareTypeCode ?? "")
    ) {
      Alert({
        type: AlertType.ERROR,
        message: "You can only use one Matrix Rack",
      });
      return;
    }
    getAssociatedBarcodes({
      variables: {
        sourcePlateBarcode: getLargestPlateBarcodeSeries(sourcePlateInfo),
        destPlateCode:
          plateTypeInfo.defaultArrayPlateCode ?? plateTypeInfo.labwarePlateType,
        hasMultipleSourcePlateTypes: false,
        hasMultipleDestPlateTypes: false,
        has384DeadPlate: is384W_CCU_Plate(deadPlateType),
      },
    }).then((data: any) => {
      const destPlate = getDestinationPlateBarcode(
        data.data.intermediateDeadAndDestPlateBarcodes.destinationPlateBarcode,
        destPlateInfo
      );

      const getMatrixRackOperatingVol = () => {
        const intResuspensionVols = intPlateInfo.map(
          (item) => item.resuspensionVol
        );
        const largestVol = Math.max(...intResuspensionVols);
        return largestVol > 900 ? 900 : largestVol;
      };

      dispatch(
        PoolingNormalizationToolActions.SET_DESTINATION_PLATE({
          index,
          rows: plateTypeInfo.plateRows,
          cols: plateTypeInfo.plateCols,
          operatingVol: isMatrixRack(plateTypeInfo.labwareTypeCode ?? "")
            ? getMatrixRackOperatingVol()
            : plateTypeInfo.defaultLabwareVolume,
          labwareTypeCode: plateTypeInfo.labwareTypeCode,
          plateBarcode: destPlate,
          minOperatingVol: plateTypeInfo.minLabwareVolume,
          topup: isMatrixRack(plateTypeInfo.labwareTypeCode ?? "")
            ? true
            : false,
        })
      );
    });
  };

  const handleDestPlateSettingsChanged = (
    index: number,
    key: DestPlateStringKeys,
    value: string | number | boolean
  ) => {
    dispatch(
      PoolingNormalizationToolActions.UPDATE_DESTINATION_PLATE_INFO({
        index,
        key,
        value,
      })
    );
  };

  const handleAddDestinationPlateClick = () => {
    dispatch(PoolingNormalizationToolActions.ADD_DESTINATION_PLATE({}));
  };

  const handleRemovePlateClick = (index: number) => {
    dispatch(
      PoolingNormalizationToolActions.CLEAR_DESTINATION_PLATE({
        index,
      })
    );
  };

  const handleClonePlateClick = async (index: number) => {
    if (
      destPlateInfo.length > index + 1 &&
      destPlateInfo[index + 1].labwareTypeCode !== ""
    ) {
      const confirmed = await isConfirmed(
        `Are you sure you want to clone the plate? This will overwrite the plate info in destination position ${
          index + 2
        }`
      );

      if (!confirmed) {
        return;
      }
    }

    if (destPlateInfo.length <= index + 1) {
      dispatch(PoolingNormalizationToolActions.ADD_DESTINATION_PLATE({}));
    }
    const labwareTypeInfo = {
      plateRows: destPlateInfo[index].rows,
      plateCols: destPlateInfo[index].cols,
      labwareTypeCode: destPlateInfo[index].labwareTypeCode,
      defaultLabwareVolume: destPlateInfo[index].operatingVol,
    };
    handlePlateTypeChange(index + 1, labwareTypeInfo);
    dispatch(
      PoolingNormalizationToolActions.CLONE_DESTINATION_PLATE({
        destinationIndex: index,
      })
    );
  };

  const getStartingVolErrorMessage = (plate: DestPlateInfoState) => {
    const labwareTypesAndStartingVolsMatch = (plate: DestPlateInfoState) => {
      const matchingLabwareTypes = destPlateInfo.filter(
        (e) => e.labwareTypeCode === plate.labwareTypeCode
      );
      return matchingLabwareTypes.every(
        (e) => e.startingVol === plate.startingVol
      );
    };

    const startingVolIsGreaterThanOperatingVol = (plate: DestPlateInfoState) =>
      plate.startingVol > plate.operatingVol;

    if (startingVolIsGreaterThanOperatingVol(plate))
      return "Starting Vol cannot be greater than Operating Vol";
    else if (!labwareTypesAndStartingVolsMatch(plate))
      return "Same Labware Types must have the same Starting Vols";

    return "";
  };

  return (
    <div
      style={{
        height: "calc(100vh - 300px)",
        width: "600px",
        // width: "50%",
        overflow: "auto",
      }}
    >
      {destPlateInfo.map((item: DestPlateInfoState, index: number) => (
        <div style={{ padding: "20px", width: "100%" }}>
          <PlateBarcodeField
            index={index}
            label={"Destination Plate Barcode"}
            plateBarcode={item.plateBarcode}
            handlePlateBarcodeChange={handleDestPlateSettingsChanged}
          />
          <StyledSelectableGroup
            onSelectionFinish={handleDestinationWellSelection}
            resetOnStart={true}
          >
            <DestinationPlate
              worklistTool={WorklistTools.PoolingNormalization}
              index={index}
              plateRows={item.rows}
              plateCols={item.cols}
              wellMapping={
                [
                  ...worklistValues.int1ToDest,
                  ...worklistValues.int2ToDest,
                  ...worklistValues.int3ToDest,
                  ...worklistValues.stampTopLeftTransfers,
                ] ?? []
              }
              sourcePlatePosition={PlatePosition.Intermediate}
              sourcePlateInfo={sourcePlateInfo}
            />
          </StyledSelectableGroup>
          <StyledPlateActions>
            <Button
              variant="outlined"
              disabled={item.rows.length < 1}
              onClick={() => handleRemovePlateClick(index)}
            >
              Remove Plate
            </Button>
            <Button
              variant="outlined"
              disabled={item.rows.length < 1}
              onClick={() => handleClonePlateClick(index)}
            >
              Clone Plate
            </Button>
          </StyledPlateActions>
          <StyledPlateSettingsContainer>
            <PlateType
              platePosition={PlatePosition.Destination}
              isDisabled={
                (index > 0 && destPlateInfo[index - 1].rows.length === 0) ||
                poolingInternalState.methodSettings!.selectedSystem === 0
              }
              acceptedPlateTypes={destPlateTypeInfo.destPlateInfo}
              plateType={item.labwareTypeCode}
              setPlateType={(plateTypeInfo) =>
                handlePlateTypeChange(index, plateTypeInfo)
              }
            />
            <div style={{ display: "flex", width: "100%" }}>
              <NumberField
                style={{ width: "50%", marginTop: "10px", marginRight: "5px" }}
                id="operating-vol"
                label="Operating Vol (µL)"
                value={item.operatingVol}
                onChange={(value) =>
                  handleDestPlateSettingsChanged(
                    index,
                    "operatingVol",
                    parseInt(value)
                  )
                }
                hasError={
                  isMatrixRack(item.labwareTypeCode) && item.operatingVol > 900
                }
                errorMessage="Must be less than 900"
              />
              {is96WPlate(item.rows.length, item.cols.length) ||
              is384WPlate(item.rows.length, item.cols.length) ? (
                <NumberField
                  style={{
                    width: "50%",
                    marginTop: "10px",
                    marginRight: "5px",
                  }}
                  id="preprocess-min-vol"
                  label="PreProcess Min Vol (µL)"
                  value={item.minOperatingVol}
                  onChange={(value) =>
                    handleDestPlateSettingsChanged(
                      index,
                      "minOperatingVol",
                      parseInt(value)
                    )
                  }
                  disabled={true}
                />
              ) : null}
              {/* If a matrix rack is being used change the label to resuspension vol but place the volume in the same location as starting vol in the Worklist */}
              {isMatrixRack(item.labwareTypeCode) ? (
                <NumberField
                  style={{ width: "50%", marginTop: "10px" }}
                  id="resuspension-vol"
                  label="Resuspension Vol (µL)"
                  value={item.startingVol}
                  onChange={(value) => {
                    handleDestPlateSettingsChanged(
                      index,
                      "startingVol",
                      parseInt(value)
                    );
                  }}
                  hasError={item.startingVol > 500 || item.startingVol <= 0}
                  errorMessage="Must be between 1 and 500"
                />
              ) : (
                <NumberField
                  style={{ width: "50%", marginTop: "10px" }}
                  id="starting-vol"
                  label="Starting Vol (µL)"
                  value={item.startingVol}
                  onChange={(value) =>
                    handleDestPlateSettingsChanged(
                      index,
                      "startingVol",
                      parseInt(value)
                    )
                  }
                  disabled={item.preprocess}
                  hasError={getStartingVolErrorMessage(item) !== ""}
                  errorMessage={getStartingVolErrorMessage(item)}
                />
              )}
            </div>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    name="Preprocess Plate"
                    checked={destPlateInfo[index].preprocess}
                    onChange={(e) => {
                      handleDestPlateSettingsChanged(
                        index,
                        "preprocess",
                        e.target.checked
                      );
                    }}
                  />
                }
                label="Preprocess Plate"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="Topup"
                    checked={destPlateInfo[index].topup}
                    onChange={(e) =>
                      handleDestPlateSettingsChanged(
                        index,
                        "topup",
                        e.target.checked
                      )
                    }
                  />
                }
                label="Topup"
              />
            </FormGroup>
          </StyledPlateSettingsContainer>
        </div>
      ))}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="outlined"
          onClick={() => handleAddDestinationPlateClick()}
        >
          Add Plate
        </Button>
      </div>
      <ConfirmDialog />
    </div>
  );
};
