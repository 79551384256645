import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  LookupLabwareType,
  useGetLabwareTypesLazyQuery,
} from "graphql/generated/schema-types";
import { useEffect, useState } from "react";

interface SetLabwareTypeProps {
  isOpen: boolean;
  handleClose: (
    plateId: number,
    labwareType: LookupLabwareType | undefined,
    saveChanges: boolean
  ) => void;
  plateId: number;
  plateBarcode: string;
  plateLayoutTypeId: number;
}

export const SetLabwareType = ({
  isOpen,
  handleClose,
  plateId,
  plateBarcode,
  plateLayoutTypeId,
}: SetLabwareTypeProps) => {
  const [getLabwareTypeInfo, { data }] = useGetLabwareTypesLazyQuery();
  const [selectedLabwareType, setSelectedLabwareType] = useState<
    LookupLabwareType | undefined
  >();

  useEffect(() => {
    getLabwareTypeInfo({
      variables: {
        where: {
          plateLayoutTypeId: { eq: plateLayoutTypeId },
        },
      },
    });
  }, [plateLayoutTypeId]);

  const handleListItemClick = (labwareType: any) => {
    setSelectedLabwareType(labwareType);
  };

  const handleDialogClose = (saveChanges: boolean) => {
    handleClose(plateId, selectedLabwareType, saveChanges);
    setSelectedLabwareType(undefined);
  };

  return (
    <Dialog open={isOpen} onClose={() => handleDialogClose(false)}>
      <DialogTitle>
        Labware type not found for {plateBarcode}. Please select a labware type
      </DialogTitle>
      <DialogContent>
        {data && data.labwareTypes && data.labwareTypes.length > 0 ? (
          <List>
            {data!.labwareTypes!.map((item) => (
              <ListItemButton
                selected={selectedLabwareType === item}
                onClick={() => handleListItemClick(item!)}
              >
                <ListItemText
                  primary={item?.labwareTypeName}
                  secondary={`${item?.manufacturer} - ${item?.description}`}
                />
              </ListItemButton>
            ))}
          </List>
        ) : (
          <Typography>
            ERROR: Plate Layout Type not found in Lookup_ArrayPlateTypeStrings
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
        <Button
          disabled={selectedLabwareType === undefined}
          onClick={() => handleDialogClose(true)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
