export interface AddRackOptions {
  process: string;
  step: string;
  run: string;
  selectedPlate: string;
  runTaskId?: number;
  salivaSamples?: string[];
  numberOfDuplicates: number;
  incrementPassageNumber: number;
  cellNumber?: number;
  resuspensionVol?: number;
  colWise: boolean;
  fillRack: boolean;
  includeInactiveWells: boolean;
  date: Date;
  lotNumberType: string;
  isAutomated?: boolean;
  showCompleted: boolean;
}

export enum Process {
  Fibroblast = "Fibroblast",
  Monoclonalization = "Monoclonalization",
  BloodProcessing = "Blood Processing",
  Saliva = "Saliva",
  Consolidation = "Consolidation",
  iPSCExpansion = "iPSC Expansion",
  EBCollection = "EB Collection",
  DNARNAQC = "DNA QC",
  QC = "QC",
  PoolingOr1224TC = "Pooling/1224TC",
  ManualPlate = "Manual Plate",
}

export enum FibroblastProcessStep {
  MYST = "MYST",
  REPRO = "REPRO",
  REPOS = "REPOS",
  TRGT = "TRGT",
  SPLT = "SPLT",
  HTC = "HTC",
  Refreeze = "Refreeze",
}

export interface TubeLabellerInfo {
  process: string;
  step: string;
  run: string;
}

export enum LotNumber {
  CurrentActive = "CurrentActive",
  Blank = "Blank",
  ExpansionLotNumber = "Expansion",
  FibroblastLotNumber = "Fibroblast",
  SpitLotNumber = "Spit",
  ReprogrammingLotNumber = "Reprogramming",
  ConsolidationLotNumber = "Consolidation",
  MonoclonalizationLotNumber = "Monoclonalization",
  GeneEditingLotNumber = "GeneEditing",
  BloodProcessingLotNumber = "BloodProcessing",
  DNAExtractionLotNumber = "DNAExtraction",
  ExternalLotNumber = "External",
  RPELotNumber = "RPE",
  OtherLotNumber = "Other",
}
