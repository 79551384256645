import {
  DashboardTask,
  Maybe,
  TaskPriorityEnum,
  TaskStatusEnum,
} from "graphql/generated/schema-types";
import moment from "moment";
import React from "react";
import { Column } from "react-table";
import { Pill } from "shared-components/styled-components";
import { GetGroupFilter } from "shared-components/Table/Filters/GroupFilter";
import { GetMultiSelectFilter } from "shared-components/Table/Filters/MultiCheckboxFilter";
import { SystemFilter } from "shared-components/Table/Filters/SystemFilter";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { GetUserFilter } from "shared-components/Table/Filters/UserFilter";
import {
  GroupsCell,
  SystemCell,
  UsersCell,
} from "shared-components/Table/sub-components/CacheLookupCells";

export const TaskPriorityReducer = (
  priority: Maybe<TaskPriorityEnum> | undefined
) => {
  switch (priority) {
    case TaskPriorityEnum.Immediate:
      return <Pill color={"red"} label={"IMMEDIATE"} />;
    case TaskPriorityEnum.High:
      return <Pill color={"orange"} label={"HIGH"} />;
    case TaskPriorityEnum.Medium:
      return <Pill color={"green"} label={"MEDIUM"} />;
    case TaskPriorityEnum.Low:
      return <Pill color={"gray"} label={"LOW"} />;
    default:
      return null;
  }
};

export const TaskStatusReducer = (
  status: Maybe<TaskStatusEnum> | undefined
) => {
  switch (status) {
    case TaskStatusEnum.Scheduled:
      return <div style={{ color: "#0073f7" }}>Scheduled</div>;
    case TaskStatusEnum.InProgress:
      return <div style={{ color: "orange" }}>In Progress</div>;
    case TaskStatusEnum.Completed:
      return <div style={{ color: "green" }}>Completed</div>;
    case TaskStatusEnum.Overdue:
      return <div style={{ color: "red" }}>Overdue</div>;
    case TaskStatusEnum.Cancelled:
      return <div style={{ color: "gray" }}>Cancelled</div>;
    default:
      return <div></div>;
  }
};

const dropdownOptions = Object.values(TaskStatusEnum).map((value) => ({
  value,
  name: value,
}));
export const DashboardTasksTableColumns: Array<Column<DashboardTask>> = [
  {
    Header: "Run",
    accessor: "taskOwnerName",
    Filter: TextFilter,
    width: "10%",
  },
  {
    Header: "Task",
    accessor: "taskName",
    Filter: TextFilter,
    width: "30%",
  },
  {
    Header: "Priority",
    accessor: "priority",
    Filter: GetMultiSelectFilter(
      Object.entries(TaskPriorityEnum).map(([name, value]) => ({
        value,
        name,
      }))
    ),
    Cell: ({ value }) => TaskPriorityReducer(value),
    width: "10%",
  },
  {
    Header: "Status",
    accessor: "status",
    Filter: GetMultiSelectFilter(dropdownOptions),
    Cell: ({ value }) => TaskStatusReducer(value),
    width: "10%",
  },
  {
    Header: "User",
    accessor: "assignedUsers",
    Filter: GetUserFilter(true),
    Cell: ({ value }) => (value ? <UsersCell userIds={value} /> : null),
    width: "10%",
  },
  {
    Header: "Group",
    accessor: "assignedGroups",
    Filter: GetGroupFilter(true),
    Cell: ({ value }) => (value ? <GroupsCell groupIds={value} /> : null),
    width: "10%",
  },
  {
    Header: "System",
    accessor: "systemId",
    Filter: SystemFilter,
    Cell: ({ value }) => (value ? <SystemCell systemId={value} /> : null),
    width: "10%",
  },
  {
    Header: "Date",
    accessor: "startDate",
    disableFilters: true,
    Cell: ({ value }) => (value ? moment(value).utc().format("MM/DD ddd") : null),
    width: "5%",
  },
  {
    Header: "Time",
    accessor: "startTime",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => (value ? moment(value).format("h:mma") : null),
    width: "5%",
  },
];
