import { TicketTableContextMenuHidden } from "features/Tickets/components/Table/TicketTable";
import React from "react";
import { Item, Menu } from "react-contexify";
import { useHistory } from "react-router-dom";
import { useGetContainer } from "hooks/cacheAccessHooks";
import { SampleActions } from "../state/actions";
import { useSampleState } from "../state/hooks";
import { SampleModals } from "./ModelsAndDialogs/model.enum";

export function SampleTableContextMenu(): JSX.Element {
  const {
    sampleDispatch,
    sampleState: { selectedContainers },
  } = useSampleState();
  const history = useHistory();
  const container = useGetContainer(selectedContainers?.[0]);

  return (
    <Menu
      id="SampleContextMenu"
      onHidden={() => {
        setTimeout(() => TicketTableContextMenuHidden(true), 200);
      }}
    >
      <Item onClick={() => document.execCommand("copy")}>Copy</Item>
      {selectedContainers?.length === 1 && (
        <Item
          onClick={() => {
            sampleDispatch({
              type: SampleActions.OPEN_MODAL,
              payload: {
                currentModal: SampleModals.LineageModal,
              },
            });
          }}
        >
          View Container Lineage
        </Item>
      )}
      {selectedContainers?.length === 1 && (
        <Item
          onClick={() => {
            history.push(`/plates/${container?.plate?.plateBarcode}`);
          }}
        >
          View Plate Map
        </Item>
      )}
    </Menu>
  );
}
