import { IRunTemplateState } from "features/RunTemplate/state/initial-state";

export enum RunTemplateAction {
  SELECT,
  DELETE,
  TEMPLATE_ADDED,
  CLOSE_DIALOG,
  OPEN_SLIDE_OVER,
  CLOSE_SLIDE_OVER,
  OPEN_INLINE_EDIT,
  CLOSE_INLINE_EDIT,
  CLOSE_MODAL,
  OPEN_MODAL,
  TEMPLATE_DELETED,
  TRAVERSE_TEMPLATE_TREE,
  CLOSE_CHILD_TEMPLATES,
}

export interface IRunTemplateAction {
  payload?: IRunTemplateState;
  type: RunTemplateAction;
}
