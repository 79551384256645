import { ReactiveVar } from "@apollo/client";

type IReducer<TState, TAction> = (state: TState, action: TAction) => TState;
export const ApolloStateDispatch =
  <TState, TAction>(
    reactiveVar: ReactiveVar<TState>,
    reducer: IReducer<TState, TAction>,
    callback?: () => void
  ) =>
  (action: TAction): void => {
    const state = reactiveVar();
    const newState = reducer(state, action);
    reactiveVar(newState);
    if (callback) callback();
  };
