import { useCallback } from "react";
import { WorklistToolsInternalAction } from "../state/action";
import { useWorklistToolStateProvider } from "../state/StateProvider";

export const useCtrlDown = () => {
  const { worklistToolInternalState, worklistToolDispatch } =
    useWorklistToolStateProvider();
  const ctrlFunction = useCallback(
    (event) => {
      if (event.keyCode === 17) {
        if (event.type === "keyup" && worklistToolInternalState.setCtrlDown) {
          worklistToolDispatch({
            type: WorklistToolsInternalAction.SET_CTRL_DOWN,
            payload: {
              setCtrlDown: false,
            },
          });
        } else if (
          event.type === "keydown" &&
          !worklistToolInternalState.setCtrlDown
        ) {
          worklistToolDispatch({
            type: WorklistToolsInternalAction.SET_CTRL_DOWN,
            payload: {
              setCtrlDown: true,
            },
          });
        }
      }
    },
    [worklistToolInternalState.setCtrlDown]
  );

  return {
    worklistToolInternalState,
    ctrlFunction,
  };
};
