import { useReactiveVar } from "@apollo/client";
import { Autocomplete, Button, TextField } from "@mui/material";
import { RepeatedTaskInfo } from "features/RunTemplate/components/Forms/Create/Task/SlideOver";
import { Formik, FormikProps } from "formik";
import {
  AppSuiteGroup,
  AppSuiteUser,
  LookupArraySystem,
  Maybe,
  OperationNames,
  PersonalTaskTemplateTaskInput,
  TaskTypeEnum,
  useDeleteTemplateTaskMutation,
  useGetGroupsQuery,
  useGetUsersQuery,
  useUpdatePersonalTaskTemplateTaskMutation,
} from "graphql/generated/schema-types";
import { useGetGroupsById, useGetUsersById } from "hooks/cacheAccessHooks";
import React, { ChangeEvent, useState } from "react";
import { SystemField } from "shared-components/FormFields/FormFields";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { Alert, AlertType } from "shared-components/toast";
import { usePersonalTaskTemplateStateProvider } from "../../state/StateProvider";
import { PersonalTaskTemplateState } from "../../state/inital-state";

const generateRepeatingTasks = (
  repeatTaskInfo: RepeatedTaskInfo,
  task: PersonalTaskTemplateTaskInput,
  outputArray: Array<PersonalTaskTemplateTaskInput>
) => {
  if (repeatTaskInfo.day) {
    for (
      let i = 0;
      i < repeatTaskInfo.day.endDay!;
      i += repeatTaskInfo.day.dayInterval!
    ) {
      outputArray.push({
        ...task,
        dayScheduled: repeatTaskInfo.startDate! + i,
      });
    }
  }
  if (outputArray.length === 0) outputArray.push(task);
  return outputArray;
};

export const TaskInfo = () => {
  const personalTaskTemplateState = useReactiveVar(PersonalTaskTemplateState);
  console.log(personalTaskTemplateState);
  const [selectedAssignedUsers, setSelectedAssignedUsers] =
    useState<AppSuiteUser[]>();
  const [selectedAssignedGroups, setSelectedAssignedGroups] =
    useState<AppSuiteGroup[]>();
  const assignedUsers = useGetUsersById(
    personalTaskTemplateState.selectedPersonalTask.assignedUsers
  );
  const assignedGroups = useGetGroupsById(
    personalTaskTemplateState.selectedPersonalTask.assignedGroups
  );

  const [repeatTaskInfo, setRepeatTaskInfo] = useState<RepeatedTaskInfo>({});
  const usersQuery = useGetUsersQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const groupsQuery = useGetGroupsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });

  const [savePersonalTaskTemplateTask] =
    useUpdatePersonalTaskTemplateTaskMutation();

  const { personalTaskTemplateDispatch } =
    usePersonalTaskTemplateStateProvider();

  const [deleteTemplateTask] = useDeleteTemplateTaskMutation();
  const handleDeleteClick = () => {
    console.log(personalTaskTemplateState);
    deleteTemplateTask({
      variables: {
        templateId:
          personalTaskTemplateState.selectedPersonalTask
            .personalTaskTemplateTaskId,
        taskType: TaskTypeEnum.Personal,
      },
      refetchQueries: [OperationNames.Query.getPersonalTaskTemplates],
    }).then((res) => {
      Alert({
        type: AlertType.SUCCESS,
        message: "Template Deleted",
      });
    });
  };

  const handleFieldValueChange = (field: string, value: any) => {
    switch (field) {
      case "assignedUsers":
        setSelectedAssignedUsers(value);
        break;
      case "assignedGroups":
        setSelectedAssignedGroups(value);
        break;
    }
  };

  const handleSubmit = (values: any) => {
    let assignedUserIds = values.assignedUsers;
    let assignedGroupIds = values.assignedGroups;
    // const arr: any[] = [];
    // generateRepeatingTasks(repeatTaskInfo, values, arr);
    // console.log(values);
    // console.log(repeatTaskInfo)
    // console.log(arr);

    if (selectedAssignedUsers) {
      assignedUserIds = selectedAssignedUsers?.map((user: AppSuiteUser) => {
        return user.userId;
      });
    }
    if (selectedAssignedGroups) {
      assignedGroupIds = selectedAssignedGroups?.map((group: AppSuiteGroup) => {
        return group.groupId;
      });
    }
    const newValue = {
      personalTaskTemplateTaskId: values.personalTaskTemplateTaskId,
      personalTaskName: values.personalTaskName,
      dayScheduled: values.dayScheduled,
      assignedUsers: assignedUserIds,
      assignedGroups: assignedGroupIds,
      assignedSystem: values.assignedSystem,
      priority: values.priority,
      notes: values.notes,
    };
    console.log(newValue);
    savePersonalTaskTemplateTask({
      variables: {
        input: newValue,
      },
      refetchQueries: [OperationNames.Query.getPersonalTaskTemplates],
    }).then((res) => {
      console.log(res);
      Alert({
        type: AlertType.SUCCESS,
        message: "Task Saved",
      });
    });
  };

  return (
    <React.Fragment>
      {personalTaskTemplateState.selectedPersonalTask
        .personalTaskTemplateTaskId ? (
        <div>
          <Button onClick={handleDeleteClick}>Delete Task</Button>
          <Formik
            onSubmit={handleSubmit}
            initialValues={personalTaskTemplateState.selectedPersonalTask}
            enableReinitialize
          >
            {({
              values,
              handleChange,
              handleSubmit,
              setFieldValue,
            }: FormikProps<any>) => (
              <StyledForm onSubmit={handleSubmit}>
                <StyledFormControl>
                  <TextField
                    id={"personalTaskName"}
                    label="Task Name"
                    style={{ width: "100%" }}
                    size="small"
                    value={values.personalTaskName}
                    onChange={handleChange}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  {/* <ScheduleFields
                    setFieldValue={setFieldValue}
                    repeatTaskInfoState={[repeatTaskInfo, setRepeatTaskInfo]}
                  /> */}
                  <TextField
                    id={"dayScheduled"}
                    label="Day Scheduled"
                    style={{ width: "100%" }}
                    type="number"
                    size="small"
                    value={values.dayScheduled}
                    onChange={handleChange}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <Autocomplete
                    multiple
                    style={{ width: "100%" }}
                    id="tags-outlined"
                    options={
                      usersQuery.data?.appSuiteUsers?.map((node) => node) ?? []
                    }
                    getOptionLabel={(option) => option!.userName!}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Assigned Users"
                      />
                    )}
                    onChange={
                      (event: ChangeEvent<unknown>, newValue: any) =>
                        handleFieldValueChange("assignedUsers", newValue)
                      // setFieldValue("assignedUsers", newValue)
                    }
                    value={selectedAssignedUsers ?? assignedUsers}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <Autocomplete
                    multiple
                    style={{ width: "100%" }}
                    id="tags-outlined"
                    options={
                      groupsQuery.data?.appSuiteGroups?.map((node) => node) ??
                      []
                    }
                    getOptionLabel={(option) => option!.groupName!}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Assigned Groups"
                      />
                    )}
                    onChange={
                      (event: ChangeEvent<unknown>, newValue: any) =>
                        handleFieldValueChange("assignedGroups", newValue)
                      // setFieldValue("assignedGroups", newValue)
                    }
                    value={selectedAssignedGroups ?? assignedGroups!}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <SystemField
                    id="system-combobox"
                    onChange={(event, newValue) =>
                      setFieldValue(
                        "assignedSystem",
                        (newValue as Maybe<LookupArraySystem>)?.systemId ?? null
                      )
                    }
                    value={values.assignedSystem}
                    label="System"
                    variant="outlined"
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <TextField
                    id={"notes"}
                    style={{ width: "100%" }}
                    label="Notes"
                    multiline
                    onChange={handleChange}
                    value={values.notes}
                  />
                </StyledFormControl>

                <Button type="submit" variant="contained">
                  Save Changes
                </Button>
              </StyledForm>
            )}
          </Formik>
        </div>
      ) : null}
    </React.Fragment>
  );
};
