export enum PoolingNormalizationToolInternalAction {
  SET_STATE,
  SET_UPLOADED_INTERMEDIATE_PLATE,
  SET_DEST_SELECTION_PROCESS,
  SET_RANDOMIZE_WELLS,
  SET_ENABLE_POOLING,
  SET__SELECTED_INTERMEDIATE_STAMP_POSITION,
  SET_SELECTED_SOURCE_WELLS,
  SET_SELECTED_INTERMEDIATE_WELLS,
  SET_SELECTED_INT1_WELLS,
  SET_SELECTED_INT2_WELLS,
  SET_SELECTED_INT3_WELLS,
  SET_HOVER_WELL_ID,
  SET_NEXT_STEP,
  SET_PREV_STEP,
  SET_SOURCE_INT_INDEX,
  SET_DESTINATION_INT_INDEX,
  SET_SELECTED_DESTINATION_INDEX,
  SET_REQUIRED_WARNINGS,
  SET_OPTIONAL_WARNINGS,
  SET_WORKLIST,
  SET_WORKLIST_PREVIEW_OPEN,
  UPDATE_METHOD_SETTINGS,
  UPDATE_STAMP_ROWCOLVOL,
  UPDATE_METHOD_SETTINGS_BY_TEMPLATE,
  SET_GROUPED_SEEDING_PRIORITY,
  SET_12_24_SEEDING_PRIORITY,
  SET_96_384_SEEDING_PRIORITY,
  SET_STAMP_SINGLE_CHANNEL_PRIORITY,
}

export interface IPoolingNormalizationToolInternalAction {
  payload: any;
  type: PoolingNormalizationToolInternalAction;
}
