import { MutationTuple, MutationUpdaterFn } from "@apollo/client";
import { FormikHelpers } from "formik";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import {
  AddRunTaskMutation,
  AddRunTaskMutationVariables,
  RunTaskInput,
  GetRunTasksDocument,
  GetRunTasksQuery,
  GetRunTasksQueryVariables,
  RunTask,
  RunTasksEdge,
} from "graphql/generated/schema-types";
import { DeepCopy } from "helpers/object-helpers";
import { Alert, AlertType } from "shared-components/toast";
import { RepeatedTaskInfo } from "features/RunTemplate/components/Forms/Create/Task/SlideOver";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { IRunsModalAction } from "features/Runs/state/modal/initial-state";

const generateRepeatingTasks = (
  repeatTaskInfo: RepeatedTaskInfo,
  task: RunTaskInput,
  outputArray: Array<RunTaskInput>
) => {
  if (repeatTaskInfo.day) {
    for (
      let i = 0;
      i < repeatTaskInfo.day.endDay!;
      i += repeatTaskInfo.day.dayInterval!
    ) {
      outputArray.push({
        ...task,
        startTimeScheduled: moment().add(i, "days"),
      });
    }
  }
  if (outputArray.length === 0) outputArray.push(task);
};

export function getHandleSubmit(
  repeatTaskInfo: RepeatedTaskInfo,
  addTasks: MutationTuple<AddRunTaskMutation, AddRunTaskMutationVariables>[0],
  runsModalDispatch: (value: IRunsModalAction) => void
) {
  return (
    values: RunTaskInput,
    { setFieldError }: FormikHelpers<RunTaskInput>
  ) => {
    const tasks: Array<RunTaskInput> = [];
    generateRepeatingTasks(repeatTaskInfo, values, tasks);
    if (!values.taskTypeDetails?.taskTypeId)
      return setFieldError("activityName", "Required");
    return addTasks({
      variables: {
        tasks: tasks,
      },
    }).then(() => {
      Alert({ type: AlertType.SUCCESS, message: "Task Added" });
      runsModalDispatch({ type: RunsModalActions.CLOSE_MODAL });
    });
  };
}

export const getCacheUpdaterFunction =
  (
    variables: GetRunTasksQueryVariables | undefined
  ): MutationUpdaterFn<AddRunTaskMutation> =>
  (cache, mutationResult) => {
    const tasks = DeepCopy(
      cache.readQuery<GetRunTasksQuery, GetRunTasksQueryVariables>({
        query: GetRunTasksDocument,
        variables,
      })?.runTasks
    );
    if (tasks?.edges && mutationResult.data?.addRunTasks) {
      const edges: RunTasksEdge[] = mutationResult.data.addRunTasks.map(
        (task) => {
          return {
            cursor: "",
            __typename: "RunTasksEdge",
            node: task as Maybe<RunTask>,
          };
        }
      );
      tasks.edges = tasks.edges.concat(edges);
    }
    cache.writeQuery<GetRunTasksQuery, GetRunTasksQueryVariables>({
      query: GetRunTasksDocument,
      data: { runTasks: tasks },
      variables,
    });
  };
