import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormikErrors, FormikTouched } from "formik";
import { DayOfWeek, PersonalTaskInput } from "graphql/generated/schema-types";
import moment from "moment";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { shouldDisableTime } from "shared-components/DateTimePicker";

export interface DateRadioGroupProps {
  setFieldValue: (field: string, value: any) => void;
  personalTaskInput: PersonalTaskInput;
  averageRunTime: number;
  touched: FormikTouched<PersonalTaskInput>;
  errors: FormikErrors<PersonalTaskInput>;
}

export const SelectOrRepeatDate = (props: DateRadioGroupProps) => {
  const pti = props.personalTaskInput;
  const touched = props.touched;
  const errors = props.errors;

  const updateField = (field: string, value: any) =>
    props.setFieldValue(field, value);

  const getFormControlLabel = (label: string, value: number) => (
    <FormControlLabel
      name="dateSelection"
      style={{ margin: 0, padding: 0 }}
      onChange={() => updateField("dateSelection", value)}
      value={value}
      control={<Radio color="primary" />}
      label={label}
    />
  );

  const getDateFields = (value: number) => {
    const slotSize = 15;
    const minutesToAdd =
      props.averageRunTime > 0
        ? Math.ceil(props.averageRunTime / slotSize) * slotSize
        : slotSize;

    const getSingleDateField = () => {
      const onChange = (value: Date | null) => {
        const endTime = moment(value).add(minutesToAdd, "minutes").format();
        updateField("startTimeScheduled", value);
        updateField("endTimeScheduled", endTime);
      };
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            renderInput={(props) => (
              <TextField
                {...props}
                error={
                  touched.startTimeScheduled && errors.startTimeScheduled !== ""
                }
                helperText={errors.startTimeScheduled}
              />
            )}
            label="Start Time"
            value={pti.startTimeScheduled}
            minutesStep={slotSize}
            onChange={onChange}
            disablePast={true}
            shouldDisableTime={shouldDisableTime}
          />
        </LocalizationProvider>
      );
    };
    const getMultipleDateField = () => (
      <div style={{ width: "100%" }}>
        <DatePicker
          name="Dates"
          placeholder="Dates"
          title="Dates"
          format="MM/DD/YY"
          calendarPosition={`top-center`}
          className="red bg-dark"
          style={{
            width: "100%",
            boxSizing: "border-box",
            height: "50px",
          }}
          containerStyle={{
            width: "100%",
          }}
          multiple
          currentDate={new DateObject(new Date(pti.startTimeScheduled))} // new Date() is needed because multi-date picker is not compatable with moment
          plugins={[<DatePanel />]}
          onChange={(newValue: any) => updateField("selectedDates", newValue)}
        />
      </div>
    );
    const getRepeatDateField = () => {
      const daysOfWeek = [
        DayOfWeek.Sunday,
        DayOfWeek.Monday,
        DayOfWeek.Tuesday,
        DayOfWeek.Wednesday,
        DayOfWeek.Thursday,
        DayOfWeek.Friday,
        DayOfWeek.Saturday,
      ];
      const onChange = (value: Date | null) => {
        const endTime = moment(value).add(minutesToAdd, "minutes").format();
        updateField("endRepeatDate", value);
        updateField("endTimeScheduled", endTime);
      };
      return (
        <Grid container spacing={1}>
          <Grid item xs={5.1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="End Repeat Date"
                value={pti.endRepeatDate}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={touched.endRepeatDate && errors.endRepeatDate !== ""}
                    helperText={errors.endRepeatDate}
                  />
                )}
                onChange={onChange}
                minutesStep={slotSize}
                disablePast={true}
                shouldDisableTime={shouldDisableTime}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6.9}>
            <Autocomplete
              multiple
              id="repeatDaysOfWeek"
              options={daysOfWeek}
              value={pti!.repeatDaysOfWeek!}
              getOptionLabel={(option) => option!}
              onChange={(_e, value) => updateField("repeatDaysOfWeek", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Day of Week"
                  variant="outlined"
                  error={
                    touched.repeatDaysOfWeek && errors.repeatDaysOfWeek !== ""
                  }
                  helperText={errors.repeatDaysOfWeek}
                />
              )}
            />
          </Grid>
        </Grid>
      );
    };

    switch (value) {
      case 0:
        return getSingleDateField();
      case 1:
        return getRepeatDateField();
      case 2:
        return getMultipleDateField();
    }
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <RadioGroup row defaultValue={0} value={pti.dateSelection}>
          {getFormControlLabel("Single Date", 0)}
          {getFormControlLabel("Multiple Dates", 2)}
          {getFormControlLabel("Repeat Date", 1)}
        </RadioGroup>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: "76px",
          justifyContent: "space-between",
        }}
      >
        {getDateFields(pti.dateSelection)}
      </div>
    </>
  );
};
