export function getValue<TOption>(
  value: number | (number | string)[] | string | null | undefined,
  options: TOption[],
  idGetter: (option: TOption) => number | string,
  multiple: boolean | undefined
) {
  const value_primitive =
    typeof value === "number" || typeof value === "string"
      ? options?.find((e) => idGetter(e) === value) ?? null
      : value === null
      ? null
      : undefined;

  const value_array =
    value instanceof Array
      ? (value
          .map((id) => options?.find((e) => idGetter(e) === id))
          .filter((entry) => entry) as TOption[])
      : value === null
      ? null
      : undefined;

  return multiple ? value_array ?? [] : value_primitive;
}

export function getDefaultValue<TOption>(
  defaultValue: number | string | Array<number | string> | null | undefined,
  value: number | (number | string)[] | string | null | undefined,
  options: TOption[],
  idGetter: (option: TOption) => number | string,
  multiple: boolean | undefined
) {
  const defaultValue_primitive =
    typeof defaultValue === "number" || typeof value === "string"
      ? options?.find((e) => idGetter(e) === defaultValue) ?? null
      : defaultValue === null
      ? null
      : undefined;

  const defaultValue_array =
    defaultValue instanceof Array
      ? (defaultValue
          .map((id) => options?.find((e) => idGetter(e) === id))
          .filter((entry) => entry) as TOption[])
      : defaultValue === null
      ? null
      : undefined;

  return multiple ? defaultValue_array ?? [] : defaultValue_primitive;
}
