import { FilterState } from "features/Plates/components/Table/Table";
import { PlatesCreatedFilterInput } from "graphql/generated/schema-types";

export const PlatesCreatedFilterReducer = (
  tableFilters: Array<FilterState>
) => {
  const where: PlatesCreatedFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.field) {
      case "Plate Barcode":
        where!.and!.push({
          plateBarcode: { contains: tableFilter.value },
        });
        break;
      case "Status":
        if (tableFilter.value === "active") {
          where!.and!.filter((entry) => !entry.isActive);
          where!.and!.push({
            isActive: { eq: true },
          });
        } else if (tableFilter.value === "inactive") {
          where!.and!.filter((entry) => entry.isActive);
          where!.and!.push({
            isActive: { eq: false },
          });
        } else {
          where!.and!.filter((entry) => !entry.isActive);
          where!.and!.filter((entry) => entry.isActive);
        }
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
