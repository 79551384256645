import { Confirm_Action } from "./actions";
import { useConfirmationDialogStateProvider } from "./StateProvider";

export const useConfirm = () => {
  const { confirmState, confirmStateDispatch } =
    useConfirmationDialogStateProvider();
  const isConfirmed = (prompt: string, bullets?: string[]) => {
    const getDispatchValue = (
      isOpen: boolean,
      prompt: string | null = null,
      proceed: ((value: unknown) => void) | null = null,
      cancel: ((reason?: any) => void) | null = null,
      bullets: string[] = []
    ) => {
      const payload =
        prompt && proceed && cancel
          ? {
              isOpen: isOpen,
              prompt: prompt,
              proceed: proceed,
              cancel: cancel,
              bullets: bullets,
            }
          : { isOpen: isOpen };
      return {
        type: Confirm_Action.SET_CONFIRM,
        payload: payload,
      };
    };

    const promise = new Promise((resolve, reject) => {
      confirmStateDispatch(
        getDispatchValue(true, prompt, resolve, reject, bullets)
      );
    });

    const getPromiseHandler = (isFulfilled: boolean) => () => {
      confirmStateDispatch(getDispatchValue(false));
      return isFulfilled;
    };

    return promise.then(getPromiseHandler(true), getPromiseHandler(false));
  };
  return {
    ...confirmState,
    isConfirmed,
  };
};
