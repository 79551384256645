import { PoolingNormalizationTool } from "features/WorklistTools/PoolingNormalization/state";
import { ThawTool } from "features/WorklistTools/ThawWorklistTool/state";
import { Twelve24TCTool } from "features/WorklistTools/1224TC/state"; 
import { combineReducers } from "redux";

const JoinedReducer = combineReducers({
  ThawTool,
  PoolingNormalizationTool,
  Twelve24TCTool,
});

export default JoinedReducer;
