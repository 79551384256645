import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AddRackOptions } from "features/TubeLabeller/constants";
import { useGetTubeLabellerRunsByProcessQuery } from "graphql/generated/schema-types";

interface FibroBlastRunSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const FibroblastRunSelector = ({
  values,
  setFieldValue,
}: FibroBlastRunSelectorProps) => {
  const { data } = useGetTubeLabellerRunsByProcessQuery({
    variables: {
      process: values.process,
    },
  });

  const handleRunSelection = (e: SelectChangeEvent<string>) => {
    const selectedRun = data?.tubeLabellerRunsByProcess?.find(
      (x) => x?.runName == e.target.value
    );
    const arrayPlateCode = selectedRun?.platesCreateds?.find(
      (x) =>
        x?.arrayPlateType?.arrayPlateCode?.arrayPlateCode?.toLowerCase() ==
        "rtc"
    )?.arrayPlateType?.arrayPlateCode?.arrayPlateCode;
    if (arrayPlateCode?.toLowerCase() == "rtc") {
      setFieldValue("step", "Refreeze");
    }
    setFieldValue("run", selectedRun?.runName);
  };
  return (
    <FormControl style={{ width: "100%" }}>
      <InputLabel>Run</InputLabel>
      <Select
        labelId="run-label"
        label="Run"
        id="run"
        value={values.run}
        onChange={(e) => handleRunSelection(e)}
      >
        {data?.tubeLabellerRunsByProcess?.map((item) => (
          <MenuItem key={item?.runId} value={item?.runName ?? ""}>
            {item?.runName ?? ""}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
