import { AxiosError, AxiosResponse } from "axios";
import { ErrorToastContent } from "error/ErrorToastContent";
import { Abbreviate } from "helpers/string-helpers";
import React, { useEffect, useMemo } from "react";
import ax from "services/AxiosService";
import { Alert, AlertType } from "shared-components/toast";
import { Action } from "state-provider/actions";
import { useStateProvider } from "state-provider/hooks";

export const useAxiosInterceptor = (): void => {
  const { dispatch } = useStateProvider();
  const interceptors = useMemo(
    () => ({
      response: (response: AxiosResponse) => response,
      error: (error: AxiosError) => {
        const status = error.response?.status;
        if (
          status === 401 &&
          window.location.pathname !== "/login" &&
          localStorage.staySignedIn === true.toString()
        ) {
          sessionStorage.prevLocation = window.location.pathname;
          dispatch({
            type: Action.AUTHENTICATE,
            payload: { loggedIn: false },
          });
        } else {
          Alert({
            type: AlertType.ERROR,
            hoc: () => (
              <ErrorToastContent
                shortErrorMessage={Abbreviate(
                  error.response?.statusText ?? "Server not responding",
                  100
                )}
                longErrorMessage={
                  error.response?.statusText ?? "Server not responding"
                }
              />
            ),
            longMessage: error.response?.statusText ?? "Server not responding",
          });
        }
        return Promise.reject(error);
      },
    }),
    [dispatch]
  );
  useEffect(() => {
    ax.interceptors.response.use(interceptors.response, interceptors.error);
  }, [interceptors]);
};
