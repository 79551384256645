import { Avatar, CardHeader, Skeleton, Tooltip } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import React from "react";
import styled from "styled-components/macro";
import {
  GetTicketInfoQuery,
  UserFragment,
} from "graphql/generated/schema-types";
import { TicketSummaryActionsMenu } from "./SummaryActionsMenu";

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between !important;
  width: 100% !important;
`;
const StyledAvatar = styled(Avatar)`
  height: 55px !important;
  width: 55px !important;
`;

export function SummaryHeader({
  avatar,
  loading,
  ticketQuery,
  user,
}: {
  loading: boolean;
  user: Maybe<UserFragment>;
  avatar: string;
  ticketQuery: Maybe<GetTicketInfoQuery>;
}) {
  return (
    <StyledCardHeader
      avatar={
        loading ? (
          <Skeleton variant={"circular"} height={"42px"} width={"42px"} />
        ) : (
          <Tooltip title={user?.userName ?? ""}>
            <StyledAvatar
              // src={user?.slackProfilePhoto}
              alt={user?.userName ?? ""}
            >
              {avatar}
            </StyledAvatar>
          </Tooltip>
        )
      }
      titleTypographyProps={{ variant: "h6" }}
      title={
        loading ? (
          <Skeleton variant={"text"} />
        ) : (
          `${ticketQuery?.ticketInfo?.asset?.asset} ${
            ticketQuery?.ticketInfo?.ticketType?.ticketType?.split(" ")[1]
          } (#${ticketQuery?.ticketInfo?.ticketId})`
        )
      }
      subheader={
        loading ? (
          <Skeleton variant={"text"} />
        ) : (
          moment(ticketQuery?.ticketInfo?.dateCreated).format("L")
        )
      }
      action={
        <TicketSummaryActionsMenu
          ticketId={ticketQuery?.ticketInfo?.ticketId ?? 0}
        />
      }
    />
  );
}
