import { makeVar, useReactiveVar } from "@apollo/client";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import {
  DirectoryFolderKeyInput,
  NodeType,
} from "graphql/generated/schema-types";
import React from "react";
import { Item, Menu } from "react-contexify";

export const FolderTreeContextMenuState = makeVar<
  DirectoryFolderKeyInput | undefined
>(undefined);
export function FolderContextMenu(): JSX.Element {
  const { runsModalState, runsModalDispatch } = useRunsModalState();
  const treeNode = useReactiveVar(FolderTreeContextMenuState);
  return (
    <Menu id={"folder-context-menu"}>
      {treeNode?.type === NodeType.Run && (
        <>
          <Item
            onClick={() => {
              runsModalDispatch({
                type: RunsModalActions.OPEN_MODAL,
                payload: {
                  currentModal: RunTaskModals.EditRun,
                  selectedTasks: runsModalState.selectedTasks,
                  selectedRunId: treeNode?.id,
                },
              });
            }}
          >
            Edit
          </Item>
          <Item
            onClick={() =>
              runsModalDispatch({
                type: RunsModalActions.OPEN_MODAL,
                payload: {
                  currentModal: RunTaskModals.AddRunActivity,
                  selectedTasks: runsModalState.selectedTasks,
                  selectedRunId: treeNode?.id,
                },
              })
            }
          >
            Add Task
          </Item>
          <Item
            onClick={() => {
              if (treeNode?.id) {
                runsModalDispatch({
                  type: RunsModalActions.OPEN_DELETE_DIALOG,
                  payload: {
                    deleteDialog: {
                      typeName: "Run",
                      runId: treeNode.id,
                      open: true,
                    },
                  },
                });
              }
            }}
          >
            Delete Run
          </Item>
        </>
      )}
      {treeNode?.type === NodeType.Folder && (
        <>
          <Item
            onClick={() => {
              runsModalDispatch({
                type: RunsModalActions.OPEN_MODAL,
                payload: {
                  currentModal: RunTaskModals.CreateRunFolder,
                  selectedFolderId: treeNode?.id,
                },
              });
            }}
          >
            Create Folder
          </Item>
          <Item
            onClick={() => {
              if (treeNode?.id) {
                runsModalDispatch({
                  type: RunsModalActions.OPEN_DELETE_DIALOG,
                  payload: {
                    deleteDialog: {
                      typeName: "FolderStructure",
                      folderId: treeNode.id,
                      open: true,
                    },
                  },
                });
              }
            }}
          >
            Delete Folder
          </Item>
        </>
      )}
    </Menu>
  );
}
