import { useReactiveVar } from "@apollo/client";
import { ApolloStateDispatch } from "helpers/apollo-state-dispatch";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { useMemo } from "react";
import { ICalendarStateAction, IInstrumentAction } from "./actions";
import {
  CalendarState,
  ICalendarState,
  IInstrumentState,
  InstrumentState,
} from "./initial-state";
import { CalendarStateReducer, InstrumentReducer } from "./reducer";

export function useInstrumentStateProvider(): {
  instrumentState: IInstrumentState;
  instrumentDispatch: IApolloDispatch<IInstrumentAction>;
} {
  const instrumentState = useReactiveVar(InstrumentState);
  const instrumentDispatch = useMemo(
    () => ApolloStateDispatch(InstrumentState, InstrumentReducer),
    []
  );
  return {
    instrumentState,
    instrumentDispatch,
  };
}

export function useCalendarStateProvider(): {
  calendarState: ICalendarState;
  calendarStateDispatch: IApolloDispatch<ICalendarStateAction>;
} {
  const calendarState = useReactiveVar(CalendarState);
  const calendarStateDispatch = useMemo(
    () => ApolloStateDispatch(CalendarState, CalendarStateReducer),
    []
  );
  return {
    calendarState,
    calendarStateDispatch,
  };
}
