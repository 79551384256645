import InfoIcon from "@mui/icons-material/Info";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { MethodSettingsProps } from "../shared";

export const ReducedPelletResuspensionMixSpeed = ({
  poolingInternalState,
  handleMethodSettingsChanged,
}: MethodSettingsProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={poolingInternalState.methodSettings.reducedPelResusSpeed}
            onChange={(e) =>
              handleMethodSettingsChanged(
                "reducedPelResusSpeed",
                e.target.checked
              )
            }
            name="checkedB"
            color="primary"
            size="small"
          />
        }
        label="Reduced Pellet Resuspension Mix Speed"
      />
      <Tooltip title="Reduces pellet resuspension mix speed to 100 µl/s (instead of 400 µL/s) - useful if you have very bubbly media. Tested with blood reprogramming">
        <InfoIcon />
      </Tooltip>
    </div>
  );
};
