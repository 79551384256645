import { TypedTypePolicies } from "graphql/generated/schema-types";

export const keyFields: Omit<TypedTypePolicies, "Query"> = {
  AppSuiteGroup: {
    keyFields: ["groupId"],
  },
  DashboardTask: {
    keyFields: ["taskId", "taskType"],
  },
  Run: {
    keyFields: ["runId"],
  },
  ProductionRun: {
    keyFields: ["runId"],
  },
  RunTask: {
    keyFields: ["runTaskId"],
  },
  AppSuiteUser: {
    keyFields: ["userId"],
  },
  LookupArraySystem: {
    keyFields: ["systemId"],
  },
  RunTemplate: {
    keyFields: ["runTemplateId"],
  },
  RunTemplateStage: {
    keyFields: ["runTemplateStageId"],
  },
  RunTemplateTask: {
    keyFields: ["runTemplateTaskId"],
  },
  RunTemplateTaskDetail: {
    keyFields: ["runTemplateTaskDetailsId"],
  },
  RunTemplatesRepeatDaysOfWeek: {
    keyFields: ["runTemplateRepeatDayOfWeekId"],
  },
  LookupTemplateTask: {
    keyFields: ["templateTaskId"],
  },
  LookupArrayMethod: {
    keyFields: ["methodId"],
  },
  ProductionRunType: {
    keyFields: ["productionRunTypeId"],
  },
  Ticket: {
    keyFields: ["ticketId"],
  },
  LookupAsset: {
    keyFields: ["assetId"],
  },
  LookupAssetType: {
    keyFields: ["assetTypeId"],
  },
  FolderStructure: {
    keyFields: ["folderId"],
  },
  LookupTicketType: {
    keyFields: ["ticketTypeId"],
  },
  Collection: {
    keyFields: ["collectionId"],
  },
  CollectionTask: {
    keyFields: ["incomingSampleTaskId"],
  },
  LookupSampleType: {
    keyFields: ["sampleTypeId"],
  },
  UserSetting: {
    keyFields: ["id"],
  },
  RunTaskType: {
    keyFields: ["compositeKey"],
  },
  ManualTaskType: {
    keyFields: ["id"],
  },
  Sample: {
    keyFields: ["sampleId"],
  },
  Container: {
    keyFields: ["containerId"],
  },
  LotNumber: {
    keyFields: ["lotNumberId"],
  },
  PlatesCreated: {
    keyFields: ["plateBarcode"],
  },
  RunTemplateOption: {
    keyFields: ["runTemplateId"],
  },
  RunTemplateTreeNodes: {
    keyFields: ["nodeId"],
  },
  DirectoryFolder: {
    keyFields: ["type", "id"],
  },
};
