import { Link } from "@mui/material";
import { MethodFragment } from "graphql/generated/schema-types";
import React from "react";
import { Column } from "react-table";
import { TextFilter } from "shared-components/Table/Filters/TextFilter";
import { BooleanFilter } from "shared-components/Table/Filters/BooleanFilter";
import { BooleanCell } from "shared-components/Table/sub-components/BooleanCell";

export const MethodColumns: Array<Column<MethodFragment>> = [
  {
    Header: "Name",
    accessor: "methodName",
    Filter: TextFilter,
    width: "33%",
  },
  {
    Header: "Is Active",
    accessor: "isActive",
    Filter: BooleanFilter,
    Cell: BooleanCell,
    width: "33%",
  },
  {
    Header: "Documentation Url",
    accessor: "documentationUrl",
    Cell: ({ value }) =>
      value ? (
        <Link href={value} target={"__blank"}>
          {value}
        </Link>
      ) : (
        <React.Fragment />
      ),
    width: "33%",
    disableFilters: true,
    disableSortBy: true,
    disableGroupBy: true,
  },
];
