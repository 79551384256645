import { Grid, Switch, Typography } from "@mui/material";
import React from "react";
import { AddRackOptions } from "../../constants";
interface RowColSelectorProps {
  values: AddRackOptions;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const RowColSelector = ({
  values,
  setFieldValue,
}: RowColSelectorProps) => {
  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>Row Wise</Grid>
        <Grid item>
          <Switch
            checked={values.colWise}
            onChange={(e) => setFieldValue("colWise", e.target.checked)}
            name="checkedC"
          />
        </Grid>
        <Grid item>Col Wise</Grid>
      </Grid>
    </Typography>
  );
};
