import { makeVar } from "@apollo/client";
import { TubeLabellerInfo } from "../constants";

export interface ITubeLabellerState {
  currentRackIndex: number;
  worklist: any[];
  tubeLabellerInfo: TubeLabellerInfo[];
}

export const TubeLabellerState = makeVar<ITubeLabellerState>({
  currentRackIndex: 1,
  worklist: [],
  tubeLabellerInfo: [],
});
