import { AppSuiteUser } from "graphql/generated/schema-types";
import React, { ChangeEvent } from "react";
import { FilterProps } from "react-table";
import { UserField } from "shared-components/FormFields/FormFields";

export const GetUserFilter = (multiSelect = false) =>
  (function UserFilter<TData extends Record<string, unknown>>(
    {
      column: { filterValue, setFilter },
    }: FilterProps<TData>
  ): JSX.Element {
    return (
      <div style={{ width: "200px" }}>
        <UserField
          id={"u-filter"}
          label={"Search Users"}
          variant={"standard"}
          multiple={multiSelect}
          value={filterValue}
          onChange={(event: ChangeEvent<unknown>, newValue: unknown) => {
            const user = newValue as
              | AppSuiteUser
              | AppSuiteUser[]
              | undefined
              | null;
            if (multiSelect) {
              const filter = (user as AppSuiteUser[] | null | undefined)?.map(
                (u) => u.userId
              );
              if (filter?.length) setFilter(filter);
              else setFilter(null);
            } else setFilter((user as AppSuiteUser)?.userId ?? null);
          }}
          helperText={false}
          error={false}
          autoFocus={true}
        />
      </div>
    );
  });
