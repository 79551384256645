import { Button, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useFormik } from "formik";
import { UpdateIncomingSampleCollectionInput } from "graphql/generated/schema-types";
import {
  StyledForm,
  StyledFormControl,
} from "shared-components/styled-components";
import { FormikSubmit } from "types/types";

export function UpdateSampleCollectionForm({
  onSubmit,
  initialValues,
}: {
  initialValues: UpdateIncomingSampleCollectionInput;
  onSubmit: FormikSubmit<UpdateIncomingSampleCollectionInput>;
}): JSX.Element {
  const { values, isSubmitting, errors, touched, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      onSubmit,
    });
  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={values.dateTimeOfArrival ?? null}
            onChange={(date) => setFieldValue("dateTimeOfArrival", date)}
            label={"Date of Arrival"}
            minutesStep={15}
            renderInput={(props) => (
              <TextField
                {...props}
                helperText={
                  errors.dateTimeOfArrival &&
                  touched.dateTimeOfArrival &&
                  errors.dateTimeOfArrival
                }
                error={
                  !!(
                    errors.dateTimeOfArrival &&
                    touched.dateTimeOfArrival &&
                    errors.dateTimeOfArrival
                  )
                }
                fullWidth
                variant={"outlined"}
              />
            )}
          />
        </LocalizationProvider>
      </StyledFormControl>
      <StyledFormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={values.dateTimeOfCollection ?? null}
            onChange={(date) => setFieldValue("dateTimeOfCollection", date)}
            label={"Date of Collection"}
            minutesStep={15}
            renderInput={(props) => (
              <TextField
                {...props}
                helperText={
                  errors.dateTimeOfCollection &&
                  touched.dateTimeOfCollection &&
                  errors.dateTimeOfCollection
                }
                error={
                  !!(
                    errors.dateTimeOfCollection &&
                    touched.dateTimeOfCollection &&
                    errors.dateTimeOfCollection
                  )
                }
                fullWidth
                variant={"outlined"}
              />
            )}
          />
        </LocalizationProvider>
      </StyledFormControl>
      <Button disabled={isSubmitting} variant={"contained"} type={"submit"}>
        Save Changes
      </Button>
    </StyledForm>
  );
}
