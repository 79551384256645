import { gql } from "@apollo/client/core";

export const GithubQueries = {
  GET_RELEASE_NOTES: gql`
    query GetReleaseNotes {
      frontend: repository(owner: "NYSCF", name: "NYSCF.Websuite.Frontend") {
        releases(first: 5, orderBy: { field: CREATED_AT, direction: DESC }) {
          nodes {
            createdAt
            tagName
            descriptionHTML
            tagCommit {
              associatedPullRequests(
                first: 10
                orderBy: { field: CREATED_AT, direction: DESC }
              ) {
                nodes {
                  commits(first: 100) {
                    nodes {
                      commit {
                        id
                        message
                        authoredDate
                        committer {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
            description
          }
        }
      }
      backend: repository(owner: "NYSCF", name: "NYSCF.Websuite.Backend") {
        ref(qualifiedName: "master") {
          target {
            ... on Commit {
              history(first: 50) {
                nodes {
                  id
                  message
                  authoredDate
                  committer {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};
