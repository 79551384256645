import { TextField } from "@mui/material";
import { useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { is96WellPlate } from "../shared/WorklistHelpers";
import { addAllStampVolumes, getMaxStampVol } from "./helpers";
import { ThawToolActions } from "./state";
import { useHandleMethodSettingsUpdated } from "./state/handlers";

interface ThawStampContainerProps {
  index: number;
}

const StyledBox = styled.div`
  width: 120px;
  min-width: 120px;
  border: 1px solid
    ${({ theme }) => (theme.palette.mode === "dark" ? "#494949" : "#494949")};
  padding: 5px;
  border-radius: 5px;
`;

export const ThawStampContainer = ({ index }: ThawStampContainerProps) => {
  const updateMethodSettings = useHandleMethodSettingsUpdated();
  const thawWorklistToolInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present
  );
  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.destPlateInfo
  );
  const dispatch = useDispatch();
  const stampMapping = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.worklistValues.thawStamp
  ).filter((e) => e.plateIndex === index);
  const allStamps = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.worklistValues.thawStamp
  );

  const handleStampVolChange = (vol: number, wellId: string) => {
    if (vol > 0) {
      const rowIndex = allStamps.findIndex(
        (e) => e.plateIndex === index && e.wellId === wellId
      );
      dispatch(
        ThawToolActions.UPDATE_THAW_STAMP_WORKLIST({
          index: rowIndex,
          stampVol: vol,
        })
      );
    }
  };

  useEffect(() => {
    if (is96WellPlate(thawWorklistToolInfo.destPlateInfo[0].labwareTypeCode)) {
      const topupVol =
        destPlateInfo[0].operatingVol - getMaxStampVol(allStamps, 0);
      updateMethodSettings("topupVol", topupVol < 0 ? 0 : topupVol);
      const resuspVol = addAllStampVolumes(allStamps, 0);
      updateMethodSettings("resuspensionVol", resuspVol);
    }
  }, [allStamps]);
  return (
    <div
      style={{ display: "flex", gap: "8px", width: "425px", overflowY: "auto" }}
    >
      {stampMapping.map((item) => (
        <div
          style={{ margin: "10px 0 5px 0", width: "120px", minWidth: "120px" }}
        >
          {/* <div style={{ textAlign: "center" }}>{item.wellId} Stamp Vol</div> */}
          <TextField
            size="small"
            type="number"
            label={`${item.wellId} Stamp Vol`}
            onFocus={(event) => {
              event.target.select();
            }}
            value={item.stampVol}
            onChange={(e) =>
              handleStampVolChange(parseInt(e.target.value), item.wellId)
            }
          />
          {/* <input
            value={item.stampVol}
            style={{ width: "50%", backgroundColor: "black" }}
          /> */}
        </div>
      ))}
    </div>
  );
};
