import {
  GroupedPlateMetadata,
  PlateMetadataInput,
  PlatesCreated,
} from "graphql/generated/schema-types";
import { Maybe } from "graphql/jsutils/Maybe";
import { Distinct } from "helpers/object-helpers";

export const GetPassagePlateMetaDataInputFromPlatesCreated = (
  plates: Maybe<PlatesCreated>[]
): PlateMetadataInput[] => {
  const metadata = plates
    .map(
      (plate) =>
        ({
          sourcePlateBarcode: plate?.plateBarcode ?? "",
        } as PlateMetadataInput)
    )
    .filter((p) => p.sourcePlateBarcode);
  return Distinct(metadata, "sourcePlateBarcode");
};

export const GetPlateMetadataInputFromGroupedPlateMetadata = (
  groupedPlateMetadata: Maybe<GroupedPlateMetadata>[]
): PlateMetadataInput[] =>
  groupedPlateMetadata
    .map((groupedMetadata) =>
      groupedMetadata?.plates?.map(
        (plateToMetadata) => plateToMetadata?.metadata
      )
    )
    .flat() as PlateMetadataInput[];
