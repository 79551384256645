import { EditRunActivityForm } from "features/Runs/components/Forms/Edit/Task/Form";
import { RunTaskModals } from "features/Runs/components/ModalsAndDialogs/modal.enum";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { RunTaskInput } from "graphql/generated/schema-types";
import { FlattenObject } from "helpers/object-helpers";
import { useWindowSize } from "hooks/useWindowSize";
import _ from "lodash";
import { RunActivityInputMapper } from "mappings/runActivityInput";
import React from "react";
import { StyledCard } from "shared-components/styled-components";
import { AppTheme } from "App.theme";
import { useGetRunTask } from "hooks/cacheAccessHooks";
import Loading from "shared-components/Loading";
import { FormikSubmit } from "types/types";

export function EditRunActivityModal(): JSX.Element {
  const windowSize = useWindowSize();
  const { runsModalDispatch } = useRunsModalState();
  const handleSubmit: FormikSubmit<RunTaskInput> = (
    taskInput: RunTaskInput,
    { setFieldError }
  ) => {
    const flattenedObject = FlattenObject(taskInput);
    const keyValuePairs = _.toPairs(flattenedObject);
    if (!taskInput.taskTypeDetails?.taskTypeId)
      return setFieldError("activityName", "Required");
    runsModalDispatch({
      type: RunsModalActions.SUBMITTED_EDIT_FORM,
      payload: {
        currentModal: RunTaskModals.ConfirmRunActivity,
        editedFieldValuePairs: keyValuePairs,
      },
    });
  };
  const { runsModalState } = useRunsModalState();
  const task = useGetRunTask(runsModalState!.selectedTasks![0] ?? 0);
  const initialValue = RunActivityInputMapper(task);
  return (
    <StyledCard
      min_width={
        windowSize.width! > 2000
          ? "20vw"
          : windowSize.width! > 1500
          ? "35vw"
          : "50vw"
      }
    >
      {!task ? (
        <div
          style={{
            height: "60vh",
          }}
        >
          <Loading
            name={"rotating-plane"}
            color={AppTheme.palette.primary.main}
          />
        </div>
      ) : (
        <EditRunActivityForm
          initialValues={initialValue}
          runTaskId={task.runTaskId}
          onSubmit={handleSubmit}
        />
      )}
    </StyledCard>
  );
}
