import { useState } from "react";
import styled from "styled-components/macro";
import { PageWithNav } from "../Navbar/PageWithNav";
import { CalendarSettingsScreens } from "./Screens/CalendarSettingsScreens";
import { NotificationSettingScreens } from "./Screens/NotificationSettings";
import { TablePresetSettings } from "./Screens/TablePresetSettings";
import { ThemeSettingScreens } from "./Screens/ThemeSettings";
import { SideBar } from "./SideBar";

const Layout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;
export interface ThemeSettings {
  mode?: "light" | "dark";
  fontSize?: number;
}

export interface CalendarSettings {
  timeFormat?: "standard" | "military";
  taskTitles?: "standard" | "engineermode";
  systemsSettings?: SystemSettings;
}

export interface SystemSettings {
  [key: string]: boolean;
}

export interface NotificationSettings {
  methodPushedBack?: boolean;
  personalTaskAssignedToMe?: boolean;
}

export enum AccountSettingsScreen {
  Theme,
  Notifications,
  TablePresets,
  Calendar,
}

export function AccountSettings(): JSX.Element {
  const stateTuple = useState(AccountSettingsScreen.Theme);
  const [screen] = stateTuple;

  const renderScreen = () => {
    switch (screen) {
      case AccountSettingsScreen.Theme:
        return <ThemeSettingScreens />;
      case AccountSettingsScreen.Notifications:
        return <NotificationSettingScreens />;
      case AccountSettingsScreen.TablePresets:
        return <TablePresetSettings />;
      case AccountSettingsScreen.Calendar:
        return <CalendarSettingsScreens />;
      default:
        return <ThemeSettingScreens />;
    }
  };

  return (
    <PageWithNav>
      <Layout>
        <SideBar stateTuple={stateTuple} />
        {renderScreen()}
      </Layout>
    </PageWithNav>
  );
}
