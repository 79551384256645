import {
  Button,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tooltip,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import produce from "immer";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { useSaveUserSettingsMutation } from "graphql/generated/schema-types";
import { useGetUserSettings } from "hooks/useGetUserSettings";
import { StyledCard } from "shared-components/styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
`;
export function TablePresetSettings(): JSX.Element {
  const settings = useGetUserSettings();
  const [selectedTableName, setSelectedTableName] = useState("");
  const selectedTable = useMemo(
    () => settings?.tables?.find((t) => t?.name === selectedTableName),
    [settings?.tables, selectedTableName]
  );
  const [saveSettings, { loading }] = useSaveUserSettingsMutation();
  const [deletedPresets, setDeletedPresets] = useState<string[]>([]);

  const [defaultTablePreset, setDefaultTablePreset] = useState<
    string | undefined
  >();

  useEffect(() => {
    setDefaultTablePreset(
      selectedTable?.presets?.find((p) => p?.default)?.name
    );
  }, [selectedTable?.presets]);

  const presetHasChanged = useMemo(
    () =>
      deletedPresets.length > 0 ||
      defaultTablePreset !==
        selectedTable?.presets?.find((p) => p?.default)?.name,
    [defaultTablePreset, deletedPresets.length, selectedTable?.presets]
  );

  return (
    <Container>
      <StyledCard width={"400px"}>
        <CardContent>
          <FormControl
            variant={"outlined"}
            fullWidth
            disabled={deletedPresets.length > 0}
          >
            <InputLabel>Table</InputLabel>
            <Select
              label={"Table"}
              value={selectedTableName}
              inputProps={{
                name: "table",
                id: "table",
              }}
              onChange={(e) => setSelectedTableName(e.target.value)}
            >
              {settings?.tables?.map((p) => (
                <MenuItem key={p.name} value={p.name}>
                  {p.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <List
            style={{
              margin: "20px 0",
            }}
          >
            {selectedTable?.presets
              ?.filter((entry) => !deletedPresets.includes(entry.name))
              ?.map((preset) => (
                <ListItem key={preset.name}>
                  <ListItemAvatar>
                    <Tooltip title={"Set Default"}>
                      <Switch
                        onChange={(e) => {
                          if (e.target.checked)
                            setDefaultTablePreset(preset.name);
                          else setDefaultTablePreset(undefined);
                        }}
                        checked={preset.name === defaultTablePreset}
                      />
                    </Tooltip>
                  </ListItemAvatar>
                  <ListItemText primary={preset.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setDeletedPresets(
                          produce(deletedPresets, (draft) => {
                            draft.push(preset.name);
                          })
                        );
                      }}
                      size="large">
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
          <ButtonContainer>
            {presetHasChanged && (
              <>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    setDefaultTablePreset(
                      selectedTable?.presets?.find((p) => p?.default)?.name
                    );
                    setDeletedPresets([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  disabled={loading}
                  onClick={() => {
                    const newSetting = produce(settings, (draft) => {
                      if (draft?.tables) {
                        const _table = draft.tables.find(
                          (x) => x.name === selectedTableName
                        );
                        if (_table?.presets) {
                          _table.presets = _table.presets.filter(
                            (p) => !deletedPresets.includes(p.name)
                          );
                          for (const entry of _table.presets)
                            entry.default = false;
                          const _preset = _table.presets.find(
                            (p) => p.name === defaultTablePreset
                          );
                          if (_preset) _preset.default = true;
                        }
                      }
                    });
                    return saveSettings({
                      variables: {
                        settingsJson: JSON.stringify(newSetting),
                      },
                    }).then(() => setDeletedPresets([]));
                  }}
                >
                  Save Changes
                </Button>
              </>
            )}
          </ButtonContainer>
        </CardContent>
      </StyledCard>
    </Container>
  );
}
