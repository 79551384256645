import { DateRange } from "@mui/lab/DateRangePicker/RangeTypes";
import {
  CollectionFilterInput,
  IncomingSampleCollectionFragment,
} from "graphql/generated/schema-types";
import { GetDayRange } from "helpers/date-helpers";
import { Filters } from "react-table";

export const IncomingSampleCollectionsFilterReducer = (
  tableFilters: Filters<CollectionFilterInput>
) => {
  const where: CollectionFilterInput = { and: [] };
  for (const tableFilter of tableFilters) {
    switch (tableFilter.id) {
      case "project.projectName" as keyof IncomingSampleCollectionFragment:
        where!.and!.push({
          project: {
            projectName: { contains: tableFilter.value },
          },
        });
        break;
      case "dateTimeOfArrival":
        let rangeArrival = tableFilter.value as DateRange<unknown>;
        if (rangeArrival.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.dateTimeOfArrival);
          break;
        }
        if (tableFilter.value[1] === null) {
          rangeArrival = GetDayRange(tableFilter.value[0]);
        }
        where!.and!.push({
          dateTimeOfArrival: { gt: rangeArrival[0] },
        });
        where!.and!.push({
          dateTimeOfArrival: { lt: rangeArrival[1] },
        });
        break;
      case "dateTimeOfCollection":
        let rangeTimeOfCollection = tableFilter.value as DateRange<unknown>;
        if (rangeTimeOfCollection.every((date) => !date)) {
          where!.and = where!.and!.filter((x) => !x.dateTimeOfCollection);
          break;
        }
        if (tableFilter.value[1] === null) {
          rangeTimeOfCollection = GetDayRange(tableFilter.value[0]);
        }
        where!.and!.push({
          dateTimeOfCollection: { gt: rangeTimeOfCollection[0] },
        });
        where!.and!.push({
          dateTimeOfCollection: { lt: rangeTimeOfCollection[1] },
        });
        break;
      case "project.nyscfprefix" as keyof IncomingSampleCollectionFragment:
        where!.and!.push({
          project: {
            nyscfprefix: { contains: tableFilter.value },
          },
        });
        break;
      case "createdBy":
        if (tableFilter.value?.length > 0)
          where!.and!.push({
            createdBy: {
              in: tableFilter.value,
            },
          });
        else where!.and!.filter((entry) => !entry.createdBy);
        break;
    }
  }
  if (where!.and!.length === 0) return undefined;
  return where;
};
