import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import produce from "immer";
import { Wrapper } from "interfaces/Wrapper";
import React from "react";
import {
  createGlobalStyle,
  DefaultTheme,
  ThemeProvider,
} from "styled-components";
import { useGetUserSettings } from "./hooks/useGetUserSettings";

export const AppTheme: DefaultTheme = {
  typography: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    mode: "light",
    primary: {
      main: "#cc2828",
    },
    secondary: {
      main: "#000000",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    action: {
      hover: "rgba(0, 0, 0, 0.2)",
    },
  },
};
export const GlobalStyleOverrides = createGlobalStyle`
  .sk-rotating-plane > div {
    width: 100px !important;
    height: 100px !important;
  }
.MuiFormLabel-root.Mui-focused{
    color: ${({
      theme: {
        palette: { mode },
      },
    }) => (mode === "dark" ? "white" : "black")}!important;
}
.MuiFormLabel-root.Mui-error{
    color: ${({ theme }) => theme.palette.primary.main}!important;
}
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: ${({
      theme: {
        palette: { mode },
      },
    }) => (mode === "dark" ? "white" : "black")}!important;
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: ${({
      theme,
    }: {
      mode?: "light" | "dark";
      theme: DefaultTheme;
    }) => theme.palette.primary.main}!important;
  .MuiInput-root.Mui-focused .MuiInput-notchedOutline{
    border-color: ${({ mode }: { mode?: "light" | "dark" }) =>
      mode === "dark" ? "white" : "black"}!important;
  }
  .MuiInput-root.Mui-error .MuiInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primary.main} !important;
  }

`;

export function AppThemeProvider({ children }: Wrapper): JSX.Element {
  const settings = useGetUserSettings();
  const Theme = produce(AppTheme, (draft) => {
    draft.typography.fontSize = settings?.theme?.fontSize ?? 14;
    draft.palette.mode = settings?.theme?.mode ?? "light";
  });
  const MuiTheme = createTheme(adaptV4Theme(Theme));

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={MuiTheme}>
        <ThemeProvider theme={Theme}>
          <GlobalStyleOverrides mode={settings?.theme?.mode} />
          <LocalizationProvider dateAdapter={DateFnsAdapter}>
            {children}
          </LocalizationProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
