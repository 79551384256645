import { Box, Button, TextField, Typography } from "@mui/material";
import { SlideOverForms } from "features/RunTemplate/components/SlideOver/slideover.enum";
import { TimelineCard } from "features/RunTemplate/components/Timeline/Node";
import { RunTemplateAction } from "features/RunTemplate/state/actions";
import {
  InlineEdit,
  useRunTemplateStateProvider,
} from "features/RunTemplate/state/StateProvider";
import { Formik } from "formik";
import {
  RunTemplateStage,
  useUpdateRunTemplateStagesMutation,
} from "graphql/generated/schema-types";
import { useOnClickOutside } from "hooks/useClickOutside";
import React, { useRef } from "react";
import { contextMenu } from "react-contexify";
import styled, { DefaultTheme } from "styled-components";
import { UpdateRunTemplateStageInputMapper } from "mappings/runTemplateInput";

const StyledTextField = styled(TextField)`
  input {
    color: ${({ theme }) => theme.palette.primary.contrastText} !important;
  }
  fieldset {
    border-color: ${({ theme }) =>
      theme.palette.primary.contrastText} !important;
  }
  label {
    color: ${({ theme }) => theme.palette.primary.contrastText} !important;
  }
`;
const StyledButton = styled(Button)`
  margin: 0 5px 0 10px;
  background-color: ${({ theme }) => theme.palette.primary.main} !important;
  border-width: 2px;
  border-color: white;
  color: white !important;
`;
const StyledTypography = styled(Typography)`
  color: ${({
    selected,
    theme,
  }: {
    selected?: boolean;
    theme?: DefaultTheme;
  }) => (selected ? theme!.palette.secondary.main : "white")};
`;
const StyledForm = styled.form`
  margin: 10px 0;
`;
export function StageCard({ entry }: { entry: RunTemplateStage }): JSX.Element {
  const {
    runTemplateState: {
      selectedStageId,
      currentSlideOver,
      slideOverOpen,
      inlineEditOpen,
      currentInlineEdit,
    },
    runTemplateDispatch,
  } = useRunTemplateStateProvider();
  const [updateStage] = useUpdateRunTemplateStagesMutation();
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () =>
    runTemplateDispatch({
      type: RunTemplateAction.CLOSE_INLINE_EDIT,
    })
  );
  return (
    <TimelineCard
      elevation={3}
      onContextMenu={(e) => {
        e.preventDefault();
        contextMenu.show({
          id: "stage-card-context-menu",
          event: e,
          props: entry,
        });
      }}
    >
      <div ref={selectedStageId === entry.runTemplateStageId ? ref : undefined}>
        <StyledTypography
          variant={"h5"}
          selected={
            !!(
              selectedStageId === entry.runTemplateStageId &&
              currentSlideOver === SlideOverForms.CreateRunTemplateTasks &&
              slideOverOpen
            )
          }
        >
          <Box fontWeight="fontWeightBold">{`Stage ${entry.stageSeqNo}`}</Box>
        </StyledTypography>
        {inlineEditOpen &&
        currentInlineEdit === InlineEdit.RenameStage &&
        selectedStageId === entry.runTemplateStageId ? (
          <Formik
            initialValues={UpdateRunTemplateStageInputMapper(entry)}
            onSubmit={(values, { setSubmitting }) => {
              updateStage({
                variables: {
                  updateInputs: {
                    primaryKey: entry.runTemplateStageId,
                    set: values,
                  },
                },
              })
                .then(() =>
                  runTemplateDispatch({
                    type: RunTemplateAction.CLOSE_INLINE_EDIT,
                  })
                )
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              handleChange,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <StyledTextField
                    size={"small"}
                    label={"Stage Name"}
                    variant={"outlined"}
                    id={"templateStageName"}
                    onChange={handleChange}
                    defaultValue={entry.templateStageName}
                    helperText={
                      errors.templateStageName &&
                      touched.templateStageName &&
                      errors.templateStageName
                    }
                    error={
                      !!(
                        errors.templateStageName &&
                        touched.templateStageName &&
                        errors.templateStageName
                      )
                    }
                  />
                  <StyledButton
                    variant={"outlined"}
                    type={"submit"}
                    disabled={isSubmitting}
                  >
                    Save
                  </StyledButton>
                </StyledForm>
              );
            }}
          </Formik>
        ) : (
          <StyledTypography>{entry.templateStageName}</StyledTypography>
        )}
      </div>
    </TimelineCard>
  );
}
