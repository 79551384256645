import { TextField } from "@mui/material";
import { CollectionTask } from "graphql/generated/schema-types";
import React from "react";
import { FilterProps } from "react-table";

export function NumberFilter({
  column: { filterValue, setFilter },
}: FilterProps<CollectionTask>): JSX.Element {
  return (
    <TextField
      size={"small"}
      label={"Equals"}
      type={"number"}
      autoFocus={true}
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value)}
    />
  );
}
