/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { AppTheme } from "App.theme";
import { PlatesCreatedFilterReducer } from "features/Plates/components/Table/filter";
import { GetPlatesFilterHandler } from "features/Plates/components/Table/HandlePlateFilter";
import {
  MultiSelectFilter,
  TextFilter,
} from "features/Plates/components/Table/PlateTableFilters";
import { PlateAction } from "features/Plates/state/actions";
import { usePlateStateProvider } from "features/Plates/state/StateProvider";
import { Maybe, PlatesCreated } from "graphql/generated/schema-types";
import { GetTableCellBorder } from "helpers/get-tablecell-boarder";
import React, { useEffect, useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { useInitializeConnectionData } from "shared-components/Table/hooks";
import styled, { DefaultTheme } from "styled-components";
import { usePlateQueryProvider } from "../PlateInfo/PlateContext";

export interface FilterState {
  field: string;
  value: string;
}

export const StyledTableRow = styled(TableRow)`
  background-color: ${({
    $inactive,
    theme,
  }: {
    theme: DefaultTheme;
    $inactive: boolean;
  }) =>
    theme.palette.mode === "dark"
      ? $inactive
        ? "rgb(10,10,10)"
        : "rgba(255, 255, 255, 0)"
      : $inactive
      ? "rgba(0, 0, 0, .1)"
      : "rgba(255, 255, 255, 0)"};
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

export const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${GetTableCellBorder};
  width: 100%;
  color: ${({
    $inactive,
    theme,
  }: {
    theme: DefaultTheme;
    $inactive: boolean;
  }) =>
    theme.palette.mode === "dark"
      ? $inactive
        ? "rgb(100, 100, 100)"
        : "white"
      : "black"} !important;
`;

const HeaderContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: minmax(223.2px, 25vh);
  margin-bottom: 2px;
`;

const FilterContainer = styled("div")`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

const ButtonContainer = styled(Button)`
  width: 100%;
  padding: 0% !important;
  margin: 2% 3% 4% 3% !important;
  font-size: 0.87rem !important;
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? "white"
      : AppTheme.palette.primary.main} !important;
`;

export function PlatesTable(): JSX.Element {
  //------------------------------------------------------------- Query --------------------------------------------------------------//

  const { data, variables, refetch } = usePlateQueryProvider()!;

  //-------------------------------------------------------------- State ---------------------------------------------------------------//
  const { plateDispatch } = usePlateStateProvider();

  const paginationStateTuple = {
    totalCount: 0,
    rowsPerPage: 50,
    page: 0,
  };

  const [filters, setFilters] = useState<Array<FilterState>>([
    { field: "Plate Barcode", value: "" },
    { field: "Status", value: "all" },
  ]);

  //--------------------------------------------------------------- Selectors ------------------------------------------------------------//

  // Grab first 15 results.
  const pageData = useInitializeConnectionData<PlatesCreated>(
    data?.plates,
    paginationStateTuple
  );

  //--------------------------------------------------------------- Handlers ------------------------------------------------------------//

  const filterTable = useMemo(
    () =>
      GetPlatesFilterHandler(variables, refetch, PlatesCreatedFilterReducer),
    [refetch, variables]
  );

  const handleFilterChange = useMemo(
    () => (filterField: string, filterVal: string) => {
      const updatedFilters = filters.map((filter) =>
        filter.field === filterField
          ? { field: filterField, value: filterVal }
          : filter
      );
      setFilters(updatedFilters);
    },
    [filters]
  );

  const handleRowClick = (barcode: Maybe<string> | undefined) => () => {
    console.log("clicked on", barcode);
    plateDispatch({
      type: PlateAction.SELECT_PLATE,
      payload: {
        selectedPlateBarcode: barcode,
      },
    });
  };

  const handleButtonClick = () => {
    setFilters([
      { field: "Plate Barcode", value: "" },
      { field: "Status", value: "all" },
    ]);
  };

  //--------------------------------------------------------------- Side Effects ------------------------------------------------------------//

  const filterTableDebounced = useAsyncDebounce(filterTable, 500);

  useEffect(() => {
    filterTableDebounced(filters);
  }, [filters]);

  const makeTable = useMemo(() => {
    console.log("rendering plates table");
    return (
      <Table style={{ tableLayout: "fixed" }}>
        <TableBody>
          {pageData.map((plate, idx) => (
            <StyledTableRow $inactive={!plate.isActive} key={idx}>
              <StyledTableCell
                $inactive={!plate.isActive}
                onClick={handleRowClick(plate.plateBarcode)}
              >
                {plate.plateBarcode}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    );
  }, [data]);

  return (
    <Paper elevation={1}>
      <HeaderContainer elevation={3}>
        <FilterContainer>
          <TextFilter filter={filters[0]} handler={handleFilterChange} />
        </FilterContainer>
        <FilterContainer>
          <MultiSelectFilter filter={filters[1]} handler={handleFilterChange} />
        </FilterContainer>
        <ButtonContainer onClick={handleButtonClick}>
          Recently Uploaded Plates
        </ButtonContainer>
      </HeaderContainer>
      <TableContainer style={{ maxHeight: "67vh", overflow: "auto" }}>
        {makeTable}
      </TableContainer>
    </Paper>
  );
}
