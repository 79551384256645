import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

interface AutoSignoutWarningProps {
  isOpen: boolean;
  timeRemaining: number;
}
export const AutoSignoutWarningDialog = ({
  isOpen,
  timeRemaining,
}: AutoSignoutWarningProps) => {
  // CircularProgress component value should be a number between 1 and 100
  // Multiply timeRemaining (15) by 6.6667 for it to equal 100
  const TIME_REMAINING_OUT_OF_100 = 6.66667;
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            variant="determinate"
            value={timeRemaining * TIME_REMAINING_OUT_OF_100}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >{`${timeRemaining}`}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h5">You're about to be signed out</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Move your mouse or press a key to stay signed in
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
