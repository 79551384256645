import { gql } from "@apollo/client";
import { DashboardTask } from "graphql/fragments/dashboard.fragments";

export const DashboardsMutations = {
  UPDATE_DASHBOARD_TASKS: gql`
    mutation updateDashboardTasks($dashboardTasks: [UpdateDashboardTaskInput]) {
      updateDashboardTasks(dashboardTasks: $dashboardTasks)
    }
  `,
  CREATE_FEED_RESERVATION: gql`
    mutation createFeedReservation($plates: SystemOneFeedReservationInfoInput) {
      reserveFeedWorklist(plates: $plates) {
        worklistID
        methodReservationID
      }
    }
  `,
  RESERVE_CELIGO_TASK_METHOD: gql`
    mutation ReserveCeligoPersonalTask($task: DashboardTaskInput) {
      reserveCeligoPersonalTaskMethod(task: $task) {
        ...DashboardTask
      }
    }
    ${DashboardTask}
  `,
};
