import { RunPageContextMenuHidden } from "features/Runs/components/Table/RunsTable";
import { useRunsModalState } from "features/Runs/state/hooks";
import { RunsModalActions } from "features/Runs/state/modal/actions";
import { RunTask } from "graphql/generated/schema-types";
import React, { useMemo } from "react";
import { contextMenu } from "react-contexify";
import { Row } from "react-table";

export const handleContextMenu =
  (selected: RunTask[], row?: Row<RunTask>) =>
  (e: React.MouseEvent<HTMLTableRowElement>) => {
    e.preventDefault();
    row?.toggleRowSelected(true);
    const _selected = selected.includes(row!.original)
      ? selected
      : [...selected, row?.original];
    contextMenu.show({
      id:
        _selected.length === 1
          ? "RunTaskTableContextMenu"
          : "MarkMultipleRunTasksContextMenu",
      event: e,
      props: {
        selected: _selected.map((s) => s?.runTaskId),
      },
    });
    RunPageContextMenuHidden(false);
  };

export const useHandleRowClick = (): ((
  rowClicked?: Row<RunTask>
) => (e: React.MouseEvent) => void) => {
  const { runsModalDispatch } = useRunsModalState();
  return useMemo(
    () => (rowClicked?: Row<RunTask>) => () => {
      if (!rowClicked?.isGrouped && RunPageContextMenuHidden())
        runsModalDispatch({
          type: RunsModalActions.OPEN_TASK_SUMMARY_DIALOG,
          payload: {
            taskSummaryDialogOpen: {
              runActivityId: rowClicked?.original.runTaskId,
              open: true,
            },
          },
        });
    },
    [runsModalDispatch]
  );
};
