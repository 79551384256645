import {
  PlatePosition,
  PoolingNormalizationStep,
  ReactSelectableFast,
  SelectionProcess,
  SelectionType,
  WorklistValues,
} from "features/WorklistTools/shared/interfaces";
import {
  arrangeColWise,
  arrangeRowWise,
  checkForDoubleSelectionWithMultipleDestSelection,
  getWellSelectionType,
  randomizeSelectedWells,
  sortByCol,
  stampSourceToDestination,
} from "features/WorklistTools/shared/WorklistHelpers";
import { IApolloDispatch } from "interfaces/IApolloDispatch";
import { Alert, AlertType } from "shared-components/toast";
import { getWorklistRows } from "../../PoolingNormalizationToolHelpers";
import {
  IntermediatePlateInfoState,
  PoolingNormalizationToolActions,
  SourcePlateInfoState,
  WorklistValuesState,
} from "../../state";
import { PoolingNormalizationToolInternalAction } from "../../state/action";
import { IPoolingNormalizationToolInternalState } from "../../state/initial-state";

export const selectIntermediateWells = async (
  poolingNormalizationToolDispatch: IApolloDispatch<any>,
  poolingInternalState: IPoolingNormalizationToolInternalState,
  dispatch: (action: { type: string; payload: any }) => void,
  worklistValues: WorklistValuesState,
  selectedWells: ReactSelectableFast[],
  intPlateInfo: IntermediatePlateInfoState[],
  sourcePlateInfo: SourcePlateInfoState[]
) => {
  if (
    poolingInternalState.step ===
    PoolingNormalizationStep.SelectIntermediateToDestination
  ) {
    return;
  }
  if (!selectedWells.length) return;
  let intSelection = selectedWells.map((well) => ({
    plateIndex: well.props.plateIndex,
    plateWellId: well.props.plateWellId,
    isSelectable: well.node.classList.contains("not-selectable") ? false : true,
  }));
  if (
    poolingInternalState.sourceIntIndex === undefined &&
    poolingInternalState.destinationIntIndex === undefined &&
    intSelection[0].plateIndex !== 0
  ) {
    Alert({
      type: AlertType.ERROR,
      message:
        "You must double click this plate to set it as a source or destination int",
    });
    return;
  }
  intSelection = sortByCol(intSelection);
  if (
    poolingInternalState.step ===
      PoolingNormalizationStep.SelectSourceToIntermediate &&
    poolingInternalState.sourceIntIndex === intSelection[0].plateIndex
  ) {
    switch (poolingInternalState.sourceIntIndex) {
      case 0:
        poolingNormalizationToolDispatch({
          type: PoolingNormalizationToolInternalAction.SET_SELECTED_INT1_WELLS,
          payload: {
            selectedInt1Wells: intSelection,
          },
        });
        return;
      case 1:
        poolingNormalizationToolDispatch({
          type: PoolingNormalizationToolInternalAction.SET_SELECTED_INT2_WELLS,
          payload: {
            selectedInt2Wells: intSelection,
          },
        });
        return;
    }
  }
  const selectedSourceWells =
    poolingInternalState.sourceIntIndex === undefined
      ? poolingInternalState.selectedSourceWells
      : poolingInternalState.sourceIntIndex === 0
      ? poolingInternalState.selectedInt1Wells
      : poolingInternalState.sourceIntIndex === 1
      ? poolingInternalState.selectedInt2Wells
      : [];
  let randomizedWells = [...selectedSourceWells];
  if (poolingInternalState.randomizeWells) {
    randomizedWells = await randomizeSelectedWells(randomizedWells);
  }
  const selectionType = getWellSelectionType(selectedSourceWells, intSelection);
  const worklistRows = getWorklistRows(
    worklistValues,
    poolingInternalState.destinationIntIndex!
  );
  let wellMapping: any[] = [];
  switch (selectionType) {
    case SelectionType.SelectMultipleSourceAndOneDestWell:
      switch (poolingInternalState.destSelectionProcess) {
        case SelectionProcess.Stamp:
          wellMapping = stampSourceToDestination(
            worklistRows,
            selectedSourceWells,
            intSelection[0],
            PlatePosition.Intermediate,
            poolingInternalState.enablePooling
          );
          if (wellMapping.length) {
            dispatchWellMapping(
              dispatch,
              poolingInternalState,
              wellMapping,
              worklistValues,
              sourcePlateInfo,
              intPlateInfo
            );
          }
          break;
        case SelectionProcess.RowWise:
          wellMapping = await arrangeRowWise(
            worklistRows,
            selectedSourceWells,
            intSelection[0],
            intPlateInfo[0].rows,
            intPlateInfo[0].cols,
            PlatePosition.Intermediate,
            poolingInternalState.enablePooling
          );
          if (wellMapping.length) {
            dispatchWellMapping(
              dispatch,
              poolingInternalState,
              wellMapping,
              worklistValues,
              sourcePlateInfo,
              intPlateInfo
            );
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
        case SelectionProcess.ColWise:
          wellMapping = await arrangeColWise(
            worklistRows,
            selectedSourceWells,
            intSelection[0],
            intPlateInfo[0].rows,
            intPlateInfo[0].cols,
            PlatePosition.Intermediate,
            poolingInternalState.enablePooling
          );
          if (wellMapping.length) {
            dispatchWellMapping(
              dispatch,
              poolingInternalState,
              wellMapping,
              worklistValues,
              sourcePlateInfo,
              intPlateInfo
            );
          } else {
            Alert({
              type: AlertType.ERROR,
              message: "Selection doesn't fit plate",
            });
          }
          break;
        case SelectionProcess.Pool:
          for (const row of selectedSourceWells) {
            if (row.isSelectable) {
              wellMapping.push({
                sourcePlateIndex: row.plateIndex,
                sourceWellId: row.plateWellId,
                destPlateIndex: intSelection[0].plateIndex,
                destWellId: intSelection[0].plateWellId,
                transferVol: "",
              });
            }
          }

          if (wellMapping.length > 0) {
            dispatchWellMapping(
              dispatch,
              poolingInternalState,
              wellMapping,
              worklistValues,
              sourcePlateInfo,
              intPlateInfo
            );
          }
          break;
      }
      break;
    case SelectionType.SelectOneSourceAndOneDestWell:
      {
        const error = poolingInternalState.enablePooling
          ? false
          : checkForDoubleSelectionWithMultipleDestSelection(worklistRows, [
              {
                plateIndex: intSelection[0].plateIndex,
                plateWellId: intSelection[0].plateWellId,
                isSelectable: true,
              },
            ]);
        if (error) {
          Alert({
            type: AlertType.ERROR,
            message: "Well has already been selected",
          });
          break;
        }
        if (selectedSourceWells[0].isSelectable) {
          wellMapping.push({
            sourcePlateIndex: selectedSourceWells[0].plateIndex,
            sourceWellId: selectedSourceWells[0].plateWellId,
            destPlateIndex: intSelection[0].plateIndex,
            destWellId: intSelection[0].plateWellId,
            transferVol: "",
          });
          dispatchWellMapping(
            dispatch,
            poolingInternalState,
            wellMapping,
            worklistValues,
            sourcePlateInfo,
            intPlateInfo
          );
        }
      }
      break;
    case SelectionType.SelectOneSourceAndMultipleDestWells: {
      const error = poolingInternalState.enablePooling
        ? false
        : checkForDoubleSelectionWithMultipleDestSelection(worklistRows, [
            {
              plateIndex: intSelection[0].plateIndex,
              plateWellId: intSelection[0].plateWellId,
              isSelectable: true,
            },
          ]);
      if (error) {
        Alert({
          type: AlertType.ERROR,
          message: "Well has already been selected",
        });
        break;
      }
      for (const row of intSelection) {
        if (selectedSourceWells[0].isSelectable) {
          wellMapping.push({
            sourcePlateIndex: selectedSourceWells[0].plateIndex,
            sourceWellId: selectedSourceWells[0].plateWellId,
            destPlateIndex: row.plateIndex,
            destWellId: row.plateWellId,
            transferVol: "",
          });
        }
      }
      if (wellMapping.length > 0) {
        dispatchWellMapping(
          dispatch,
          poolingInternalState,
          wellMapping,
          worklistValues,
          sourcePlateInfo,
          intPlateInfo
        );
      }
      break;
    }
  }
};

const dispatchWellMapping = (
  dispatch: (action: { type: string; payload: any }) => void,
  poolingInternalState: IPoolingNormalizationToolInternalState,
  wellMapping: any[],
  worklistValues: any,
  sourcePlateInfo: any,
  intPlateInfo: any
) => {
  const sourceIntIndex = poolingInternalState.sourceIntIndex;
  const destIntIndex = poolingInternalState.destinationIntIndex;

  const harvestingWellsToInt1 =
    sourceIntIndex === undefined &&
    destIntIndex === undefined &&
    wellMapping[0].destPlateIndex === 0;
  if (harvestingWellsToInt1) {
    const multipleWellsFromSource = () => {
      if (
        worklistValues.harvestWells.filter((e: any) =>
          wellMapping.some(
            (t) =>
              e.sourcePlateIndex === t.sourcePlateIndex &&
              e.sourceWellId === t.sourceWellId
          )
        ).length > 0
      ) {
        Alert({
          type: AlertType.ERROR,
          message:
            "Source well(s) can only be harvested once into Intermediate 1",
        });
        return true;
      }
      return false;
    }
    const sourceVolumesExceedsIntOperatingVol = () => {
      const uniqueSet = new Set<number>(
        worklistValues.harvestWells.map(
          (item: WorklistValues) => item.sourcePlateIndex
        )
      );
      uniqueSet.add(wellMapping[0].sourcePlateIndex);
      const sourcePlateIndexes = [...uniqueSet];

      const harvestWashVolume = 200;
      let totalSourceVolume = poolingInternalState.methodSettings
        .washAfterDissociation
        ? sourcePlateIndexes.length * harvestWashVolume
        : 0;

      for (const item of sourcePlateIndexes) {
        totalSourceVolume += sourcePlateInfo[item].operatingVol;
      }
      if (totalSourceVolume > intPlateInfo[0].operatingVol) {
        Alert({
          type: AlertType.ERROR,
          message: "Total source volume exceeds int plate's operating volume",
        });
        return true;
      }
      return false;
    };
    if (multipleWellsFromSource()) return;
    // Remove comments to add Source96HeadProcessing Logic
    // if (sourceVolumesExceedsIntOperatingVol()) return;
    dispatch(
      PoolingNormalizationToolActions.SRC_TO_INT_MANUALLY_ADDED({
        selections: wellMapping,
      })
    );
    return;
  }
  switch (destIntIndex) {
    case 1:
      dispatch(
        PoolingNormalizationToolActions.INT1_TO_INT2_MANUALLY_ADDED({
          selections: wellMapping,
        })
      );
      break;
    case 2:
      if (sourceIntIndex === 0) {
        dispatch(
          PoolingNormalizationToolActions.INT1_TO_INT3_MANUALLY_ADDED({
            selections: wellMapping,
          })
        );
      } else if (sourceIntIndex === 1) {
        dispatch(
          PoolingNormalizationToolActions.INT2_TO_INT3_MANUALLY_ADDED({
            selections: wellMapping,
          })
        );
      }
      break;
  }
};
