import { useReactiveVar } from "@apollo/client";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { LabwareTypeInfo } from "features/WorklistTools/shared/interfaces";
import { getDeadPlateTypeInfo } from "features/WorklistTools/shared/WorklistHelpers";
import { useGetArrayMethodLabwareTypesLazyQuery } from "graphql/generated/schema-types";
import { useAppReduxSelector } from "hooks/reduxHooks";
import React, { useEffect, useState } from "react";
import { ThawToolInternalState } from "../state/initial-state";
import { MethodSettings } from "./MethodSettings";
import { StampMapping } from "./StampMapping";

export const ThawToolSideBar = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const destPlateInfo = useAppReduxSelector(
    (state) => state.WorklistTools.ThawTool.present.destPlateInfo
  );
  const thawToolInternalState = useReactiveVar(ThawToolInternalState);
  const [deadPlateTypes, setDeadPlatesTypes] = useState<LabwareTypeInfo[]>();
  const [getAcceptablePlateTypes, { data: acceptablePlateTypes }] =
    useGetArrayMethodLabwareTypesLazyQuery({ fetchPolicy: "network-only" });

  useEffect(() => {
    if (thawToolInternalState.methodSettings.arraySystemAutomationMethodId) {
      getAcceptablePlateTypes({
        variables: {
          where: {
            arraySystemAutomationMethodId: {
              eq: thawToolInternalState.methodSettings
                .arraySystemAutomationMethodId,
            },
          },
        },
      });
    }
  }, [thawToolInternalState.methodSettings.arraySystemAutomationMethodId]);

  useEffect(() => {
    if (acceptablePlateTypes) {
      setDeadPlatesTypes(getDeadPlateTypeInfo(acceptablePlateTypes));
    }
  }, [acceptablePlateTypes]);
  return (
    <React.Fragment>
      <div style={{ padding: "20px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography>Method Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <MethodSettings deadPlateTypeInfo={deadPlateTypes} />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography>Well Mapping</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <StampMapping />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  );
};
